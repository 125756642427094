import PropTypes from 'prop-types'
import React, { Fragment, useState } from 'react'
import injectSheet from 'react-jss'

import { SearchInput, TreeSelect, Notification } from 'components'
import { ModalHeader } from 'styling/components'
import { StateHolder } from 'utils'

import BrowseItemsModal from './BrowseItemsModal'

const styles = theme => ({
  categoryFilter: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing.md,
  },
  categoryFilterInput: {
    width: 230,
  },
  infoBlock: {
    marginBottom: theme.spacing.md,
  },
  treeSelectContainer: {
    border: `1px solid ${theme.borderGreyLight}`,
    padding: theme.spacing.md,
  },
})

const BrowseCategoriesModalContent = injectSheet(styles)(
  ({
    classes,
    categories,
    categoriesById,
    initialSelectedCategories,
    updateSelectedItems,
    onChangeCheckedStrategy,
    disabledCategories,
    renderInfoBlock,
    error,
  }) => {
    return (
      <div>
        <ModalHeader positionAbsolutely>Browse Categories</ModalHeader>
        <StateHolder
          debounce
          initialValue={''}
          render={({
            handleChange: onFilterChange,
            value: filterInputValue,
            debouncedValue: debouncedFilterValue,
          }) => (
            <Fragment>
              <div className={classes.categoryFilter}>
                <SearchInput
                  className={classes.categoryFilterInput}
                  id="categories-filter"
                  onChange={onFilterChange}
                  placeholder="Search for a category"
                  value={filterInputValue}
                />
              </div>
              {renderInfoBlock && <div className={classes.infoBlock}>{renderInfoBlock()}</div>}
              {error && <Notification className={classes.infoBlock} message={error} kind="error" />}
              <div className={classes.treeSelectContainer}>
                <TreeSelect
                  defaultValue={initialSelectedCategories.map(category => category.id)}
                  filterValue={debouncedFilterValue}
                  onChange={selectedCategoryIds => {
                    updateSelectedItems(selectedCategoryIds.map(id => categoriesById[id]))
                  }}
                  disabledKeys={disabledCategories}
                  onChangeCheckedStrategy={onChangeCheckedStrategy}
                  treeData={categories}
                />
              </div>
            </Fragment>
          )}
        />
      </div>
    )
  }
)

BrowseCategoriesModalContent.propTypes = {
  categories: PropTypes.array.isRequired,
  categoriesById: PropTypes.object.isRequired,
  initialSelectedCategories: PropTypes.array.isRequired,
  updateSelectedItems: PropTypes.func.isRequired,
  onChangeCheckedStrategy: PropTypes.string,
  disabledCategories: PropTypes.array,
  renderInfoBlock: PropTypes.func,
}

BrowseCategoriesModalContent.defaultProps = {
  onChangeCheckedStrategy: 'SHOW_ALL',
  disabledCategories: [],
}

const BrowseCategoriesModal = props => {
  const { validateSelection, ...newProps } = props

  const [error, setError] = useState(null)
  newProps.contentProps.error = error

  if (validateSelection) {
    newProps.beforeSave = params => {
      const err = validateSelection(params)
      if (err) {
        setError(err)
        return false
      }

      setError(null)

      return true
    }
  }

  return <BrowseItemsModal {...newProps} ModalContent={BrowseCategoriesModalContent} />
}

BrowseCategoriesModal.propTypes = {
  /**
   * Validates the currently-selected categories and then returns an error
   * string if there is an error identified
   */
  validateSelection: PropTypes.func,
}

export default BrowseCategoriesModal
