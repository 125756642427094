import memoize from 'memoize-one'
import cx from 'classnames'
import React, { Component, Fragment } from 'react'
import injectSheet from 'react-jss'
import { connect } from 'react-redux'
import { Value } from 'react-values'
import { bindActionCreators } from 'redux'

import { fetchSegments, updateSegment, updateSegments } from 'api'
import {
  Button,
  ButtonToggle,
  DataTable,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  Modal,
  PageHeader,
  SearchInput,
  ShowIfAuthorized,
  StatusIndicatorDot,
} from 'components'
import config from 'config'
import { createToast } from 'modules/toasts'
import { getUserPermissionKeys } from 'modules/user'
import { generateBackButton, getRoutePathname, getRouteSearch } from 'routing'
import Styling from 'styling/components'
import { formatDatetime, ItemsFetcherWithParams } from 'utils'

import SegmentRuleDetails from './SegmentRuleDetails'
import CreateManualSegmentForm from './CreateManualSegmentForm'
import UploadSegmentCSVForm from './UploadSegmentCSVForm'

const styles = theme => ({
  rightOfHeaderContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    width: 575,

    '& > *': {
      marginBottom: 0,
      marginLeft: theme.spacing.md,
    },
  },
  bulkActions: {
    '& > *': {
      marginLeft: theme.spacing.lg,
    },
  },
  filterStatus: {
    flexBasis: '90px',
  },
  filterSearch: {
    flexBasis: '320px',
  },
})

const PAGE_ID = 'segments'

const DEFAULT_ITEMS_LIMIT = 20

const tableParams = {
  paging: {
    type: 'multi',
    multiParams: { limit: { key: 'limit' }, offset: { key: 'offset' } },
  },
  search: { key: 'search' },
  sorting: {
    type: 'multi',
    multiParams: { direction: { key: 'direction' }, orderBy: { key: 'order_by' } },
  },
  status: { key: 'status' },
}

const filterOptions = {
  status: [
    { id: 'clear', label: 'Any Type', value: null },
    { id: 'active', label: 'Active', value: 'true' },
    { id: 'archived', label: 'Archived', value: 'false' },
  ],
}

const tableHeaders = [
  {
    key: 'status',
    disableSorting: true,
  },
  {
    key: 'name',
    header: 'Name',
  },
  {
    key: 'polytype',
    header: 'Source',
  },
  {
    key: 'created',
    header: 'Created',
  },
  {
    key: 'userCount',
    header: '# of Matching Users',
    disableSorting: true,
  },
]

const generateSegmentActionDropdown = ({
  createToast,
  history,
  location,
  refetchSegments,
  segment,
  updateSelectedRowIds,
  userPermissions,
}) => {
  return (
    <DropdownMenu
      ariaLabel="Actions"
      menuAlignment="right"
      triggerRender={({ description, isOpen, onClick }) => (
        <ButtonToggle
          icon="more"
          id={`segment-${segment.id}-action`}
          isToggled={isOpen}
          onClick={onClick}
          untoggledDescription={description}
        />
      )}
    >
      {segment.polytype === 'rules' && userPermissions.includes('segments.create') && (
        <DropdownMenuItem
          icon="copy"
          kind="primary"
          onClick={() => {
            const duplicateId = segment.id
            history.push({
              pathname: getRoutePathname('segments.createSegment'),
              state: { backButton: generateBackButton('segments', location), duplicateId },
            })
          }}
        >
          Duplicate
        </DropdownMenuItem>
      )}
      <DropdownMenuItem
        disabled={segment.processingState === 'processing'}
        icon="export"
        kind="primary"
        renderButton={({ buttonProps: { disabled, ...restButtonProps }, iconEl }) => (
          // Link that will fetch a csv of the segment's matched users
          <a
            {...restButtonProps}
            href={
              disabled ? undefined : `${config.env.apiPath}/customer_segments/${segment.id}/users`
            }
            rel="noopener noreferrer"
            target="_blank"
            download
          >
            {iconEl}
            Export Contact List
          </a>
        )}
      />
      {userPermissions.includes('segments.edit') && (
        <DropdownMenuItem
          icon={segment.status ? 'delete' : null}
          kind={segment.status ? 'danger' : 'primary'}
          hasDivider
          renderButton={({ buttonProps, iconEl }) => {
            const buttonText = segment.status ? 'Archive' : 'Restore'
            return (
              <Modal.Confirmation
                handleCloseModal={({ wasConfirmed }) => {
                  if (wasConfirmed) {
                    const newSegment = { ...segment, status: !segment.status }
                    updateSegment(segment.id, newSegment)
                      .then(updatedSegment => {
                        createToast({
                          kind: 'success',
                          message: `Segment successfully ${
                            updatedSegment.status ? 'restored' : 'archived'
                          }.`,
                        })
                        refetchSegments()
                        updateSelectedRowIds([])
                        // TODO: Ensure user isn't going to land on an empty last page
                      })
                      .catch(({ message }) => {
                        createToast({ kind: 'error', message })
                      })
                  }
                }}
                triggerRender={({ openModal }) => (
                  <button
                    {...buttonProps}
                    // Overwrite buttonProps' `onClick`, we don't want to close the dropdown menu
                    // because the confirmation modal would be unmounted
                    onClick={() => {
                      openModal()
                    }}
                  >
                    {iconEl}
                    {buttonText}
                  </button>
                )}
                contentProps={{
                  actionText: segment.status ? 'archive this segment' : 'restore this segment',
                  confirmButtonText: buttonText,
                }}
              />
            )
          }}
        />
      )}
    </DropdownMenu>
  )
}

class Segments extends Component {
  state = {
    csvModalIsOpen: false,
    manualModalIsOpen: false,
    selectedRowIds: [],
  }

  setModalIsOpenState = (modal, isOpen) => {
    if (modal === 'csv') {
      this.setState({ csvModalIsOpen: isOpen })
    } else if (modal === 'manual') {
      this.setState({ manualModalIsOpen: isOpen })
    }
  }

  openModal = modal => {
    this.setModalIsOpenState(modal, true)
  }

  closeModal = modal => {
    this.setModalIsOpenState(modal, false)
  }

  formatTableRows = memoize((segments, refetchSegments) => {
    const { createToast, history, location, userPermissions } = this.props

    return segments.map(segment => {
      const { id, status, name, polytype, created, processingState, userCount } = segment

      const sourceText =
        polytype === 'manual'
          ? 'Manually Entered'
          : polytype === 'csv'
          ? 'CSV Upload'
          : polytype === 'rules'
          ? 'Rule Based'
          : ''

      return {
        rowAction: generateSegmentActionDropdown({
          createToast,
          history,
          location,
          refetchSegments,
          segment,
          updateSelectedRowIds: this.updateSelectedRowIds,
          userPermissions,
        }),
        rowDetails: polytype === 'rules' ? <SegmentRuleDetails segmentId={id} /> : null,
        id: `${id}`,
        resourceName: 'segment',
        status: <StatusIndicatorDot compact isActive={status} />,
        name,
        polytype: sourceText,
        created: formatDatetime(created),
        userCount:
          processingState === 'processing'
            ? 'Processing CSV...'
            : processingState === 'failed'
            ? 'Processing error'
            : userCount, // # of users in the segment
      }
    })
  })

  updateSelectedRowIds = newSelectedRowIds => {
    this.setState({ selectedRowIds: newSelectedRowIds })
  }

  render() {
    const { csvModalIsOpen, manualModalIsOpen, selectedRowIds } = this.state
    const { classes, createToast, history, location, userPermissions } = this.props

    return (
      <ItemsFetcherWithParams
        queryKey="segments"
        defaultItemsLimit={DEFAULT_ITEMS_LIMIT}
        fetchItems={fetchSegments}
        includeItemsById
        routingParams={{
          location,
          history,
        }}
        paramDefinitions={tableParams}
        render={({
          params: tableParams,
          items: segments,
          itemsById: segmentsById,
          itemCount: segmentCount,
          isLoadingItems: isFetchingSegments,
          error: errorFetchingSegments,
          refetch: refetchSegments,
        }) => {
          const tableRows = this.formatTableRows(segments, refetchSegments)

          // Reset page to its default sort/filter state when a new csv or manual segment has been created
          const resetSegmentsPage = () => {
            const currentPathname = location.pathname
            const currentQuery = location.search
            const defaultPageQuery = getRouteSearch('segments')

            if (currentQuery === defaultPageQuery) {
              // Already on the default segments page, simply fetch the newest segments (`history.push` won't fetch them if the search query is the same)
              refetchSegments()
            } else {
              // Redirect to default segments page using history (`refetchSegments` won't update table filters and sort state)
              history.push({
                pathname: currentPathname,
                search: '?status=true&order_by=created&direction=desc',
              })
            }
          }

          // Determine whether or not to show Archive/Restore for Bulk actions on selected segments
          let shouldShowArchiveForBulkAction
          let hasArchivedSegments
          // Ensure the selectedRowIds represent rows that are currently being displayed on the page
          const selectedRows = selectedRowIds
            .map(selectedRowId => segmentsById[selectedRowId])
            .filter(selectedRow => !!selectedRow)
          const numSelectedRows = selectedRows.length
          if (numSelectedRows > 0) {
            // Show Archive bulk action unless ALL of the selected segments are archived
            // (i.e. show Archive bulk action if even just one of the selected segments is active)
            const numActiveSegments = selectedRows.filter(selectedRow => selectedRow.status).length
            const hasActiveSegments = numActiveSegments > 0
            hasArchivedSegments = numActiveSegments < numSelectedRows
            shouldShowArchiveForBulkAction = hasActiveSegments
          }

          // Paging props
          const resultsPerPage =
            parseInt(tableParams.paging.multiParams.limit.value, 10) || DEFAULT_ITEMS_LIMIT
          const pageNumber = (tableParams.paging.multiParams.offset.value || 0) / resultsPerPage + 1

          return (
            <div>
              <PageHeader headerTitle="Customer User Segments">
                {selectedRowIds.length === 0 && (
                  <div className={classes.rightOfHeaderContainer}>
                    <Dropdown
                      className={classes.filterStatus}
                      id={`${PAGE_ID}-status`}
                      items={filterOptions.status}
                      onChange={tableParams.status.onChange}
                      placeholder="Status"
                      selectedValue={tableParams.status.value}
                    />
                    <Value defaultValue={tableParams.search.value || ''}>
                      {({ set: handleChange, value }) => (
                        <SearchInput
                          className={classes.filterSearch}
                          id={`${PAGE_ID}-search`}
                          onClear={oldInputValue => {
                            // If the user clears the search input containing the current search param,
                            // reload the page with the search param cleared
                            if (oldInputValue === tableParams.search.value) {
                              tableParams.search.onChange(null)
                            }
                          }}
                          onChange={handleChange}
                          onSubmit={tableParams.search.onChange}
                          placeholder="Search by segment name"
                          value={value}
                        />
                      )}
                    </Value>
                    <ShowIfAuthorized requiredPermission="segments.create">
                      <Fragment>
                        <DropdownMenu
                          menuAlignment="right"
                          triggerRender={({ description, onClick }) => (
                            <Button
                              aria-label={description}
                              id={`${PAGE_ID}-create`}
                              onClick={onClick}
                            >
                              Create new
                            </Button>
                          )}
                        >
                          <DropdownMenuItem
                            id={`${PAGE_ID}-create-rule-based`}
                            onClick={() =>
                              history.push({
                                pathname: getRoutePathname('segments.createSegment'),
                                state: { backButton: generateBackButton('segments', location) },
                              })
                            }
                          >
                            Rule Based
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            onClick={() => {
                              this.openModal('csv')
                            }}
                          >
                            Upload a CSV
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            onClick={() => {
                              this.openModal('manual')
                            }}
                          >
                            Manual Entry
                          </DropdownMenuItem>
                        </DropdownMenu>
                        <Modal
                          handleCloseModal={({ requiresRefetch, openUploadCSV }) => {
                            this.closeModal('manual')
                            if (openUploadCSV) {
                              this.openModal('csv')
                            }
                            if (requiresRefetch) {
                              createToast({
                                kind: 'success',
                                message: 'Segment successfully created.',
                              })
                              resetSegmentsPage()
                            }
                          }}
                          isOpen={manualModalIsOpen}
                          size="large"
                          render={modalProps => <CreateManualSegmentForm {...modalProps} />}
                        />
                        <Modal
                          handleCloseModal={({ requiresRefetch }) => {
                            this.closeModal('csv')
                            if (requiresRefetch) {
                              createToast({
                                kind: 'success',
                                message: 'CSV successfully uploaded.',
                              })
                              resetSegmentsPage()
                            }
                          }}
                          isOpen={csvModalIsOpen}
                          size="large"
                          render={modalProps => <UploadSegmentCSVForm {...modalProps} />}
                        />
                      </Fragment>
                    </ShowIfAuthorized>
                  </div>
                )}
                {selectedRowIds.length > 0 && (
                  <div className={cx(classes.rightOfHeaderContainer, classes.bulkActions)}>
                    <Styling.H5>{selectedRowIds.length} Selected</Styling.H5>
                    <Modal.Confirmation
                      handleCloseModal={({ wasConfirmed }) => {
                        if (wasConfirmed) {
                          const newSegments = selectedRowIds.map(segmentId => ({
                            id: segmentId,
                            // Set `status` to `false` to archive, `true` to restore all selected segments
                            status: !shouldShowArchiveForBulkAction,
                          }))
                          updateSegments(newSegments)
                            .then(() => {
                              createToast({
                                kind: 'success',
                                message: `Segments successfully ${
                                  shouldShowArchiveForBulkAction ? 'archive' : 'restore'
                                }d.`,
                              })
                              refetchSegments()
                              this.updateSelectedRowIds([])
                              // TODO: Ensure user isn't going to land on an empty last page
                            })
                            .catch(({ message }) => {
                              createToast({ kind: 'error', message })
                            })
                        }
                      }}
                      triggerRender={({ openModal }) => (
                        <Button
                          kind={shouldShowArchiveForBulkAction ? 'danger' : 'primary'}
                          onClick={openModal}
                        >
                          {shouldShowArchiveForBulkAction ? 'Archive' : 'Restore'}
                        </Button>
                      )}
                      contentProps={{
                        actionText: `${
                          shouldShowArchiveForBulkAction ? 'archive' : 'restore'
                        } the selected segments`,
                        details:
                          shouldShowArchiveForBulkAction && hasArchivedSegments
                            ? 'If any archived segments were selected, they will simply remain archived.'
                            : null,
                        confirmButtonText: shouldShowArchiveForBulkAction ? 'Archive' : 'Restore',
                      }}
                    />
                  </div>
                )}
              </PageHeader>
              <DataTable
                error={errorFetchingSegments}
                headers={tableHeaders}
                id={`${PAGE_ID}-table`}
                isLoadingNewRows={isFetchingSegments}
                isSelectable={userPermissions.includes('segments.edit')}
                onSelectedRowsChange={this.updateSelectedRowIds}
                onSortBy={({ nextSortHeaderKey, nextSortDirection }) => {
                  // Clear selected row ids on sort change
                  this.updateSelectedRowIds([])

                  tableParams.sorting.onChange({
                    orderBy: nextSortHeaderKey,
                    direction: nextSortDirection,
                  })
                }}
                rows={tableRows}
                sortDirection={tableParams.sorting.multiParams.direction.value}
                sortHeaderKey={tableParams.sorting.multiParams.orderBy.value}
                pagingProps={{
                  pageNumber,
                  resultsPerPage,
                  onPagingChange: ({ pageNumber, resultsPerPage }) => {
                    // Clear selected row ids on page change
                    this.updateSelectedRowIds([])

                    tableParams.paging.onChange({
                      limit: resultsPerPage,
                      offset: (pageNumber - 1) * resultsPerPage || undefined,
                    })
                  },
                  resultsTotal: segmentCount,
                }}
              />
            </div>
          )
        }}
      />
    )
  }
}

const mapStateToProps = state => ({
  userPermissions: getUserPermissionKeys(state),
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createToast,
    },
    dispatch
  )

export default injectSheet(styles)(connect(mapStateToProps, mapDispatchToProps)(Segments))
