import memoize from 'memoize-one'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Button, DataTableWithState } from 'components'
import { renderWithFilter } from 'components/DataTable/utils/rendering'
import Styling, { ModalHeader } from 'styling/components'
import { capitalize } from 'utils'

import Modal from './Modal'

class BrowseItemsModalContent extends Component {
  static propTypes = {
    /**
     * Will default to `Filter ${itemNamePlural}` if not provided
     */
    filterInputPlaceholder: PropTypes.string,
    /**
     * Passed in if the modal table requires more than just one column
     */
    formatTableRows: PropTypes.func,
    /**
     * Will default to `Add ${capitalize({phrase: itemNamePlural})}` if not provided
     */
    headerText: PropTypes.string,
    itemName: PropTypes.string.isRequired,
    itemNamePlural: PropTypes.string.isRequired,
    /**
     * Each item in `items` must have an `id`!
     */
    items: PropTypes.array.isRequired,
    itemsById: PropTypes.object.isRequired,
    initialSelectedItems: PropTypes.array.isRequired,
    /**
     * Will default to 1 column header with `key` equal to `name`, and `header` equal to "${itemName} Name"
     */
    tableHeaders: PropTypes.array,
    updateSelectedItems: PropTypes.func.isRequired,
    /**
     * Whether the internal DataTable component will show select boxes regardless
     * of checked state on the internal items
     */
    showSelectBoxes: PropTypes.bool,
    /**
     * Optional rendering function to add additional information to this modal between the filter and the list
     */
    renderInfoBlock: PropTypes.func,
  }

  constructor(props) {
    super(props)
    const {
      filterInputPlaceholder,
      formatTableRows,
      headerText,
      itemName,
      itemNamePlural,
      tableHeaders,
    } = this.props

    this.itemsTableHeaders = tableHeaders || [
      {
        key: 'name',
        header: `${itemName} Name`,
      },
    ]

    // Set default values for `headerText`, `filterInputPlaceholder` and `formatTableRows`
    // which depend on the value of `itemName` and `itemNamePlural`
    this.formatTableRows = formatTableRows
      ? memoize(formatTableRows)
      : memoize(items =>
          items.map(({ id, name }) => ({
            id: `${id}`,
            resourceName: itemName,
            name,
          }))
        )

    this.headerText = headerText || `Add ${capitalize({ phrase: itemNamePlural })}`
    this.filterInputPlaceholder = filterInputPlaceholder || `Search for a ${itemName}`
  }

  render() {
    const {
      itemNamePlural,
      items,
      itemsById,
      initialSelectedItems,
      updateSelectedItems,
      renderInfoBlock,
    } = this.props

    return (
      <div>
        <ModalHeader positionAbsolutely>{this.headerText}</ModalHeader>
        <DataTableWithState
          headers={this.itemsTableHeaders}
          id={`${itemNamePlural}-table`}
          initialSelectedRowIds={initialSelectedItems.map(({ id }) => `${id}`)}
          isSelectable
          showSelectBoxes
          onSelectedRowsChange={newSelectedRowIds => {
            updateSelectedItems(newSelectedRowIds.map(itemId => itemsById[itemId]))
          }}
          rows={this.formatTableRows(items)}
          render={renderWithFilter}
          renderProps={{
            filterInputId: `${itemNamePlural}-filter`,
            filterInputPlaceholder: this.filterInputPlaceholder,
            renderInfoBlock,
          }}
        />
      </div>
    )
  }
}

/**
 * A modal which allows the user to select from a large list of items.
 * Expects `selectedItems` to be passed when `openModal` is called.
 *  (Note: Seems to still work without it, but seems clearer to initialize
 *   it in the Modal instance's `contentState`)
 * Passes `newSelectedItems` to `handleCloseModal` on close.
 */
const BrowseItemsModal = ({ contentProps, ModalContent, beforeSave, ...modalProps }) => {
  const render = ModalContent
    ? ({ setContentState }) => (
        <ModalContent
          updateSelectedItems={newSelectedItems => {
            setContentState({ selectedItems: newSelectedItems })
          }}
          {...contentProps}
        />
      )
    : ({ setContentState }) => (
        <BrowseItemsModalContent
          updateSelectedItems={newSelectedItems => {
            setContentState({ selectedItems: newSelectedItems })
          }}
          {...contentProps}
        />
      )

  return (
    <Modal
      {...modalProps}
      size="large"
      render={render}
      renderStickyFooter={({ closeModal, contentState: { selectedItems } }) => (
        <Styling.LineOfButtons withoutTopMargin>
          <Button
            onClick={() => {
              const closeParams = { newSelectedItems: selectedItems }
              if (!beforeSave || (beforeSave && beforeSave(closeParams))) {
                closeModal(closeParams)
              }
            }}
          >
            Save
          </Button>
          <Button kind="link" onClick={closeModal}>
            Cancel
          </Button>
        </Styling.LineOfButtons>
      )}
    />
  )
}

BrowseItemsModal.propTypes = {
  /**
   * Props that are passed into the component that renders the modal's contents
   */
  contentProps: PropTypes.object,
  /**
   * Will overwrite the default render function for the modal's contents
   * (is passed in the modal props and `updateSelectedItems`)
   */
  ModalContent: PropTypes.func,
  /**
   * Validation function to check that the current selection is valid; called
   * before the modal closes
   */
  beforeSave: PropTypes.func,
}

export default BrowseItemsModal
