import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import injectSheet from 'react-jss'

import styles from './ListBox.styles'

/**
 * `ListBox` is a generic container component that handles creating the
 * container class name in response to certain props.
 */
const ListBox = ({
  children,
  classes,
  className: containerClassName,
  isDisabled,
  setRef,
  size,
  theme,
  ...restProps
}) => {
  const className = cx(containerClassName, classes.listBox, {
    [classes.listBoxDisabled]: isDisabled,
    [classes.small]: size === 'small',
  })
  return (
    <div className={className} {...(setRef ? { ref: setRef } : {})} {...restProps}>
      {children}
    </div>
  )
}

ListBox.propTypes = {
  /**
   * `children` should be any of: ListBoxField, ListBoxMenu
   */
  children: PropTypes.node,
  className: PropTypes.string,
  /**
   * Function for consumer component to access a reference to the container node
   */
  setRef: PropTypes.func,
  /**
   * Style can differ by size provided
   */
  size: PropTypes.oneOf(['small', 'regular']),
  isDisabled: PropTypes.bool,
}

ListBox.defaultProps = {
  size: 'regular',
}

export default injectSheet(styles)(ListBox)
