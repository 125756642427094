const CREATE_PERMISSION_ACTION = { key: 'create', labelText: 'Create' }
const EDIT_PERMISSION_ACTION = { key: 'edit', labelText: 'Edit' }
const VIEW_PERMISSION_ACTION = { key: 'view', labelText: 'View' }
const VIEW_ALL_PERMISSION_ACTION = { key: 'view_all', labelText: 'View All' }
const VIEW_CREATE_EDIT_PERMISSION_ACTIONS = [
  VIEW_PERMISSION_ACTION,
  CREATE_PERMISSION_ACTION,
  EDIT_PERMISSION_ACTION,
]

const getPermission = (featureKey, actionKey, actionLabel) => ({
  key: `${featureKey}.${actionKey}`,
  labelText: actionLabel,
})

const getFeature = (
  featureKey,
  featureLabel,
  permissionActions = VIEW_CREATE_EDIT_PERMISSION_ACTIONS
) => ({
  key: featureKey,
  labelText: featureLabel,
  permissions: permissionActions.map(({ key: actionKey, labelText: actionLabel }) =>
    getPermission(featureKey, actionKey, actionLabel)
  ),
})

const PERMISSION_SECTIONS = [
  {
    labelText: 'Marketing',
    features: [
      getFeature('content', 'Content'),
      getFeature('segments', 'Customer Segments'),
      getFeature('email_templates', 'Email Templates', [
        VIEW_PERMISSION_ACTION,
        { key: 'publish', labelText: 'Publish' },
        EDIT_PERMISSION_ACTION,
        { key: 'preview', labelText: 'Preview' },
      ]),
      getFeature('offers', 'Offers'),
      getFeature('recipes', 'Recipes'),
      getFeature('store_groups', 'Store Groups'),
    ],
  },
  {
    labelText: 'Search',
    features: [
      getFeature('search_keywords_redirects', 'Search Keyword Redirects'),
      getFeature('search_rules', 'Search Rules'),
      getFeature('synonyms', 'Synonyms'),
    ],
  },
  {
    labelText: 'Customers & Orders',
    features: [
      getFeature('customers', 'Customers'),
      getFeature('misc', 'Customer Credits', [{ key: 'create_credits', labelText: 'Create' }]),
      getFeature('misc', 'Customer Passwords', [
        { key: 'password_reset', labelText: 'Send Reset Email' },
      ]),
      getFeature('orders', 'Orders', [VIEW_PERMISSION_ACTION, EDIT_PERMISSION_ACTION]),
      getFeature('payments', 'Payment Info'),
      getFeature('users', 'Users', [
        VIEW_PERMISSION_ACTION,
        VIEW_ALL_PERMISSION_ACTION,
        CREATE_PERMISSION_ACTION,
        EDIT_PERMISSION_ACTION,
      ]),
    ],
  },
  {
    labelText: 'Product & Collections',
    features: [
      getFeature('collections', 'Collections'),
      getFeature('products', 'Products', [VIEW_PERMISSION_ACTION, EDIT_PERMISSION_ACTION]),
      getFeature('pricing_models', 'Pricing Models'),
    ],
  },
  {
    labelText: 'Platform Settings',
    features: [
      getFeature('blackouts', 'Blackouts'),
      getFeature('delivery_companies', 'Delivery Companies', [VIEW_PERMISSION_ACTION]),
      getFeature('delivery_fees', 'Delivery Fees'),
      getFeature('fees', 'Fees', [VIEW_PERMISSION_ACTION, EDIT_PERMISSION_ACTION]),
      getFeature('monitoring', 'Integration Monitoring', [VIEW_PERMISSION_ACTION]),
      getFeature('misc', 'Platform Settings', [{ key: 'settings_view', labelText: 'View' }]),
      getFeature('plu', 'PLUs'),
      getFeature('stores', 'Stores', [VIEW_PERMISSION_ACTION, EDIT_PERMISSION_ACTION]),
      getFeature('subscriptions', 'Subscriptions'),
      getFeature('timeslots', 'Timeslots'),
    ],
  },
  {
    labelText: 'Admin',
    features: [
      getFeature('permissions', 'Permissions', [
        VIEW_PERMISSION_ACTION,
        VIEW_ALL_PERMISSION_ACTION,
      ]),
      getFeature('roles', 'Roles', [
        VIEW_PERMISSION_ACTION,
        VIEW_ALL_PERMISSION_ACTION,
        CREATE_PERMISSION_ACTION,
        EDIT_PERMISSION_ACTION,
        { key: 'assign', labelText: 'Assign' },
      ]),
    ],
  },
  {
    labelText: 'Other',
    features: [
      getFeature('misc', 'Demo Page', [{ key: 'demo', labelText: 'View' }]),
      getFeature('facts', 'Facts', [VIEW_PERMISSION_ACTION, EDIT_PERMISSION_ACTION]),
      getFeature('dotcom', '.COM Module', [VIEW_PERMISSION_ACTION, CREATE_PERMISSION_ACTION]),
    ],
  },
]

export const getSectionsInPermissionList = permissionList => {
  const permissionsListKeys = permissionList.map(permission => permission.key)

  return PERMISSION_SECTIONS.map(section => {
    const sectionFeaturesInList = section.features
      .map(sectionFeature => {
        const featurePermissionsInList = sectionFeature.permissions.filter(
          ({ key: permissionKey }) => permissionsListKeys.includes(permissionKey)
        )

        return featurePermissionsInList.length > 0
          ? { ...sectionFeature, permissions: featurePermissionsInList }
          : null
      })
      .filter(x => x) // filter out `null` section features whose permissions are not present in `permissionList`

    return sectionFeaturesInList.length > 0 ? { ...section, features: sectionFeaturesInList } : null
  }).filter(x => x) // filter out `null` sections whose permissions are not present in `permissionList`
}
