import { Field, FieldArray, Formik } from 'formik'
import omit from 'lodash/omit'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import injectSheet from 'react-jss'

import { fetchBrands, fetchCollections, fetchStoreGroups, getSegmentUserCountEstimate } from 'api'
import {
  AddCategories,
  AddItems,
  Button,
  ButtonIconAction,
  Dropdown,
  DropdownFilterable,
  DropdownDate,
  FormFieldItem,
  FormItem,
  FormRequirement,
  LoadingIndicator,
  MinMax,
  MultiSelect,
  Notification,
  RadioButtons,
  ReadOnlyValue,
  TextInput,
} from 'components'
import Styling from 'styling/components'
import { capitalize, generateGetNewInstanceId, get, ItemsFetcher } from 'utils'

import config from 'config'

import AddProducts from './AddProducts'
import { removeRuleIdsFromFormValues } from './utils'

const styles = theme => ({
  formContainer: {
    width: 850,
  },
  nameInput: {
    width: 290,
  },
  rulesOperator: {
    width: 210,
  },
  estimateCount: {
    alignItems: 'center',
    display: 'flex',
  },
  estimateCountDisplay: {
    width: 80,
  },
})

const BASE_ID = 'create-rule-based-segment'

const generateHandleRangeFieldValueChangeGenerator = ({
  ruleValues,
  ruleFieldPrefix,
  setFieldValue,
}) => ({ ruleField, rangePropPrefix }) => ({ from, to }) => {
  const {
    [`${rangePropPrefix}From`]: ignore1,
    [`${rangePropPrefix}To`]: ignore2,
    ...otherProps
  } = ruleValues[ruleField]

  /**
   * `testEmptyOp` = 'any'|'all'
   *   `all`: if both values are empty, the range is considered empty.
   *   `any`: if either values is empty, the range is considered empty.
   */
  const testEmptyOp = rangePropPrefix === 'date' ? 'any' : 'all' // dates are considered "empty" if not a full range

  // Check if the range is considered "empty"
  if ((testEmptyOp === 'all' && !from && !to) || (testEmptyOp === 'any' && (!from || !to))) {
    // Clear range props if range is "empty"
    setFieldValue(`${ruleFieldPrefix}.${ruleField}`, otherProps)
  } else {
    // If range is not "empty", update the range values
    setFieldValue(`${ruleFieldPrefix}.${ruleField}`, {
      ...otherProps,
      [`${rangePropPrefix}From`]: from,
      [`${rangePropPrefix}To`]: to,
    })
  }
}

/**
 * Since brands come back to us as just strings, we need to make them into
 * objects with constructed ids and their value under a `name` prop so we can
 * use them with the `DataTable` inside the "Browse Brands" modal.
 * We need to then supply the selected brands under this same scheme.
 */
const transformBrandStrings = brandStrings =>
  brandStrings.map((brandString, index) => ({
    id: `${index}`,
    name: brandString,
  }))

/**
 * Since selected brands need to take on the form of an object with just `name`, this helper
 * function finds these selected brands in the array of brand objects `brands`.
 */
const getSelectedBrands = (selectedBrands, brands) => {
  const selectedBrandStrings = selectedBrands.map(({ name }) => name)
  return brands.filter(({ name: brandName }) => selectedBrandStrings.includes(brandName))
}

/**
 * Detects and displays an error for a field if an error exists in the corresponding `errorPath`.
 * `id` should be the field's `id`.
 */
const FieldErrorMsg = ({ id, errorPath, msg = 'This field is required' }) => (
  <Field
    name={errorPath}
    render={({ form }) => {
      const hasError = get(form.errors, errorPath)
      return hasError ? <FormRequirement id={`${id}-error-msg`} invalidText={msg} /> : null
    }}
  />
)

const getNewRuleId = generateGetNewInstanceId()

const RulesTable = injectSheet(theme => ({
  table: {
    tableLayout: 'fixed',
    width: '100%',

    '& > tbody > tr': {
      border: `1px solid ${theme.borderGreyLight}`,

      '& > td': {
        padding: `${theme.spacing.sm} ${theme.spacing.md}`,
        verticalAlign: 'top',
      },
    },
  },
  segmentRuleDelete: {
    textAlign: 'center',
    width: 50,
    '$table > tbody > tr > td&': {
      paddingTop: theme.spacing.md, // to better align with fields in the first row of a rule
    },
  },
  segmentRuleType: {
    maxWidth: 210,
  },
  segmentRuleTypeMenu: {
    maxHeight: 'none',
  },
  segmentRuleDropdownField: {
    maxWidth: 140,
  },
  segmentRuleMultiSelectField: {
    maxWidth: 165,
  },
  segmentRuleRangeField: {
    maxWidth: 220,
  },
  addProductGroup: {
    backgroundColor: theme.bgGreyExtraLight,
    marginTop: theme.spacing.xs,
    padding: `${theme.spacing.md}`,
  },
  storeGroupAutocompleteItem: {
    display: 'flex',
    lineHeight: theme.baseLineHeight,
    minHeight: 20,
    minWidth: 280,
    width: '100%',
  },
  storeGroupAutocompleteItemLeft: {
    flexBasis: 200,
    flexShrink: 0,
  },
  selectedStoreGroup: {
    display: 'flex',
    alignItems: 'center',
  },
}))(({ classes, fieldArrayProps, formikProps }) => {
  // TODO: Only fetch categories/brands/collections/store_groups once

  const { setValues, setFieldValue, values } = formikProps
  const { push: addRule, remove: removeRule } = fieldArrayProps

  const { rules } = values.rules
  const { dynamic } = values
  const rolling_date_dropdown_items = [
    { id: 'rolling-period-7', label: 'Past 7 days', value: 7 },
    { id: 'rolling-period-30', label: 'Past 30 days', value: 30 },
    {
      id: 'no-rolling-period',
      label: 'Custom date range',
      value: 0,
    },
  ]
  const rollingDateOnChange = (ruleFieldPrefix, ruleType) => newValue => {
    setValues({
      ...values,
      [`${ruleFieldPrefix}.${ruleType}.showDatePicker`]: newValue === 0,
    })
    setFieldValue(`${ruleFieldPrefix}.${ruleType}.dateRolling`, newValue)
    setFieldValue(
      `${ruleFieldPrefix}.${ruleType}.rolling_time_period`,
      newValue === 0 ? null : newValue
    )
  }

  const showUserEventsOptions = config.dashboard.showUserEventsOptions

  const mainDropdownItems = [
    ...(showUserEventsOptions
      ? [
          {
            id: 'rule-type-group-demographic',
            label: 'Demographic Info',
            isGroupHeading: true,
          },
          { id: 'rule-type-userAge', label: 'Age', value: 'userAge' },
          { id: 'rule-type-gender', label: 'Gender', value: 'gender' },
          { id: 'rule-type-loyalty', label: 'Loyalty', value: 'loyalty' },
        ]
      : []),
    {
      id: 'rule-type-group-online-product-activity',
      label: 'Online Product Activity',
      isGroupHeading: true,
    },
    {
      id: 'rule-type-anyOnlineActivity',
      label: 'Any Product',
      value: 'onlineProductActivity-any',
    },
    {
      id: 'rule-type-onlineProductActivity',
      label: 'Specific Products',
      value: 'onlineProductActivity-products',
    },
    {
      id: 'rule-type-onlineCategoryActivity',
      label: 'Categories',
      value: 'onlineProductActivity-categories',
    },
    {
      id: 'rule-type-onlineBrandActivity',
      label: 'Brands',
      value: 'onlineProductActivity-brands',
    },
    {
      id: 'rule-type-onlineCollectionActivity',
      label: 'Collections',
      value: 'onlineProductActivity-collections',
    },
    {
      id: 'rule-type-group-other',
      label: 'Other',
      isGroupHeading: true,
    },
    {
      id: 'rule-type-onlineSpend',
      label: 'Online Spend',
      value: 'onlineSpend',
    },
    {
      id: 'rule-type-totalOnlineSpend',
      label: 'Total Online Spend',
      value: 'totalOnlineSpend',
    },
    ...(showUserEventsOptions
      ? [
          {
            id: 'rule-type-onlineStoreActivity',
            label: 'Online Store Activity',
            value: 'onlineStoreActivity',
          },
        ]
      : []),
  ]
  return (
    <table className={classes.table}>
      <tbody>
        {rules.map((ruleValues, index) => {
          const ruleIdPrefix = `${BASE_ID}-rules-${index}`
          const ruleFieldPrefix = `rules.rules[${index}]`

          const generateHandleRangeFieldValueChange = generateHandleRangeFieldValueChangeGenerator({
            ruleValues,
            ruleFieldPrefix,
            setFieldValue,
          })

          const productGroupType = get(ruleValues, 'onlineProductActivity.productGroup.type') // may or may not exist

          return (
            <tr id={ruleIdPrefix} key={ruleValues.id ? `rule-${ruleValues.id}` : index}>
              <td>
                <Styling.LineOfItems>
                  <FormFieldItem
                    className={classes.segmentRuleType}
                    id={`${ruleIdPrefix}-type`}
                    name={`${ruleFieldPrefix}.type`}
                    orientation="horizontal"
                    render={({ id, isInvalid, value }) => (
                      <Dropdown
                        id={id}
                        isInvalid={isInvalid}
                        items={mainDropdownItems}
                        menuClassName={classes.segmentRuleTypeMenu}
                        onChange={newValue => {
                          let ruleType
                          let productGroupType
                          if (newValue.startsWith('onlineProductActivity-')) {
                            ruleType = 'onlineProductActivity'
                            productGroupType = newValue.replace('onlineProductActivity-', '')
                          } else {
                            ruleType = newValue
                          }

                          setFieldValue(`${ruleFieldPrefix}`, { id: ruleValues.id, type: ruleType })

                          // Set default values depending on the rule
                          // (Will also clear existing rule values if they select a new rule type)
                          const defaultValue = {}
                          switch (ruleType) {
                            case 'gender':
                              defaultValue.gender = 'male'
                              break
                            case 'loyalty':
                              defaultValue.joined = true
                              break
                            case 'onlineStoreActivity':
                              defaultValue.visited = true
                              break
                            case 'onlineProductActivity':
                              defaultValue.have = true
                              defaultValue.activityTypes = []
                              defaultValue.productGroup = { type: productGroupType }
                              if (productGroupType !== 'any') {
                                defaultValue.productGroup[productGroupType] = []
                              }
                              break
                            default:
                              break
                          }
                          setFieldValue(`${ruleFieldPrefix}.${ruleType}`, defaultValue)
                        }}
                        placeholder="Select a Rule"
                        selectedValue={
                          value === 'onlineProductActivity'
                            ? `onlineProductActivity-${get(
                                ruleValues,
                                'onlineProductActivity.productGroup.type'
                              )}`
                            : value
                        }
                      />
                    )}
                  />
                  {ruleValues.type === 'userAge' && (
                    <FormFieldItem
                      className={classes.segmentRuleRangeField}
                      id={`${ruleIdPrefix}-userAge-age`}
                      isRangeField
                      name={`${ruleFieldPrefix}.userAge.age`}
                      orientation="horizontal"
                      render={({ id, isInvalid, value }) => (
                        <MinMax
                          id={id}
                          isInvalid={isInvalid}
                          onChange={generateHandleRangeFieldValueChange({
                            rangePropPrefix: 'age',
                            ruleField: 'userAge',
                          })}
                          placeholder="Enter an age range"
                          value={value}
                        />
                      )}
                    />
                  )}
                  {ruleValues.type === 'gender' && (
                    <FormFieldItem
                      className={classes.segmentRuleDropdownField}
                      id={`${ruleIdPrefix}-gender`}
                      name={`${ruleFieldPrefix}.gender`}
                      orientation="horizontal"
                      render={({ id, isInvalid, value }) => (
                        <Dropdown
                          id={id}
                          isInvalid={isInvalid}
                          items={[
                            { id: 'gender-male', label: 'Male', value: 'male' },
                            { id: 'gender-female', label: 'Female', value: 'female' },
                          ]}
                          onChange={newValue => {
                            setFieldValue(`${ruleFieldPrefix}.gender.gender`, newValue)
                          }}
                          placeholder="Select a gender"
                          selectedValue={get(value, 'gender')}
                        />
                      )}
                    />
                  )}
                  {ruleValues.type === 'loyalty' && (
                    <Fragment>
                      <FormFieldItem
                        className={classes.segmentRuleDropdownField}
                        id={`${ruleIdPrefix}-loyalty-joined`}
                        name={`${ruleFieldPrefix}.loyalty.joined`}
                        orientation="horizontal"
                        render={({ id, isInvalid, value }) => (
                          <Dropdown
                            id={id}
                            isInvalid={isInvalid}
                            items={[
                              { id: 'loyalty-joined-true', label: 'Has Joined', value: true },
                              { id: 'loyalty-joined-false', label: 'Has Not Joined', value: false },
                            ]}
                            onChange={newValue => {
                              setFieldValue(`${ruleFieldPrefix}.loyalty.joined`, newValue)
                            }}
                            placeholder="Joined Status"
                            selectedValue={value}
                          />
                        )}
                      />
                      {dynamic && (
                        <FormFieldItem
                          className={classes.segmentRuleRangeField}
                          id={`${ruleIdPrefix}-loyalty-date-rolling`}
                          name={`${ruleFieldPrefix}.loyalty.dateRolling`}
                          orientation="horizontal"
                          render={({ id, isInvalid, value }) => (
                            <Dropdown
                              id={id}
                              isInvalid={isInvalid}
                              items={rolling_date_dropdown_items}
                              onChange={rollingDateOnChange(ruleFieldPrefix, 'loyalty')}
                              placeholder="Rolling date period"
                              selectedValue={value}
                            />
                          )}
                        />
                      )}
                      <FormFieldItem
                        className={classes.segmentRuleRangeField}
                        id={`${ruleIdPrefix}-loyalty-date`}
                        isRangeField
                        name={`${ruleFieldPrefix}.loyalty.date`}
                        orientation="horizontal"
                        render={({ id, isInvalid, value }) =>
                          (!dynamic || values[`${ruleFieldPrefix}.loyalty.showDatePicker`]) && (
                            <DropdownDate
                              id={id}
                              isInvalid={isInvalid}
                              onChange={generateHandleRangeFieldValueChange({
                                rangePropPrefix: 'date',
                                ruleField: 'loyalty',
                              })}
                              placeholder="Joined Date Range"
                              defaultValue={value}
                            />
                          )
                        }
                      />
                    </Fragment>
                  )}
                  {ruleValues.type === 'onlineSpend' && (
                    <Fragment>
                      <FormFieldItem
                        className={classes.segmentRuleRangeField}
                        id={`${ruleIdPrefix}-onlineSpend-spend`}
                        isRangeField
                        name={`${ruleFieldPrefix}.onlineSpend.spend`}
                        orientation="horizontal"
                        render={({ id, isInvalid, value }) => (
                          <MinMax
                            id={id}
                            isInvalid={isInvalid}
                            onChange={generateHandleRangeFieldValueChange({
                              rangePropPrefix: 'spend',
                              ruleField: 'onlineSpend',
                            })}
                            placeholder="Enter spending range"
                            type="currency"
                            value={value}
                          />
                        )}
                      />
                      {dynamic && (
                        <FormFieldItem
                          className={classes.segmentRuleRangeField}
                          id={`${ruleIdPrefix}-onlineSpend-date-rolling`}
                          name={`${ruleFieldPrefix}.onlineSpend.dateRolling`}
                          orientation="horizontal"
                          render={({ id, isInvalid, value }) => (
                            <Dropdown
                              id={id}
                              isInvalid={isInvalid}
                              items={rolling_date_dropdown_items}
                              onChange={rollingDateOnChange(ruleFieldPrefix, 'onlineSpend')}
                              placeholder="Rolling date period"
                              selectedValue={value}
                            />
                          )}
                        />
                      )}
                      <FormFieldItem
                        className={classes.segmentRuleRangeField}
                        id={`${ruleIdPrefix}-onlineSpend-date`}
                        name={`${ruleFieldPrefix}.onlineSpend.date`}
                        orientation="horizontal"
                        isRangeField
                        render={({ id, isInvalid, value }) =>
                          (!dynamic || values[`${ruleFieldPrefix}.onlineSpend.showDatePicker`]) && (
                            <DropdownDate
                              id={id}
                              isInvalid={isInvalid}
                              onChange={generateHandleRangeFieldValueChange({
                                rangePropPrefix: 'date',
                                ruleField: 'onlineSpend',
                              })}
                              placeholder="Spending Period"
                              defaultValue={value}
                            />
                          )
                        }
                      />
                    </Fragment>
                  )}
                  {ruleValues.type === 'totalOnlineSpend' && (
                    <Fragment>
                      <FormFieldItem
                        className={classes.segmentRuleRangeField}
                        id={`${ruleIdPrefix}-totalOnlineSpend-spend`}
                        isRangeField
                        name={`${ruleFieldPrefix}.totalOnlineSpend.spend`}
                        orientation="horizontal"
                        render={({ id, isInvalid, value }) => (
                          <MinMax
                            id={id}
                            isInvalid={isInvalid}
                            onChange={generateHandleRangeFieldValueChange({
                              rangePropPrefix: 'spend',
                              ruleField: 'totalOnlineSpend',
                            })}
                            placeholder="Enter spending range"
                            type="currency"
                            value={value}
                          />
                        )}
                      />
                      {dynamic && (
                        <FormFieldItem
                          className={classes.segmentRuleRangeField}
                          id={`${ruleIdPrefix}-totalOnlineSpend-date-rolling`}
                          name={`${ruleFieldPrefix}.totalOnlineSpend.dateRolling`}
                          orientation="horizontal"
                          render={({ id, isInvalid, value }) => (
                            <Dropdown
                              id={id}
                              isInvalid={isInvalid}
                              items={rolling_date_dropdown_items}
                              onChange={rollingDateOnChange(ruleFieldPrefix, 'totalOnlineSpend')}
                              placeholder="Rolling date period"
                              selectedValue={value}
                            />
                          )}
                        />
                      )}
                      <FormFieldItem
                        id={`${ruleIdPrefix}-totalOnlineSpend-date`}
                        name={`${ruleFieldPrefix}.totalOnlineSpend.date`}
                        orientation="horizontal"
                        isRangeField
                        render={({ id, isInvalid, value }) =>
                          (!dynamic ||
                            values[`${ruleFieldPrefix}.totalOnlineSpend.showDatePicker`]) && (
                            <DropdownDate
                              id={id}
                              isInvalid={isInvalid}
                              onChange={generateHandleRangeFieldValueChange({
                                rangePropPrefix: 'date',
                                ruleField: 'totalOnlineSpend',
                              })}
                              placeholder="Spending Period"
                              defaultValue={value}
                            />
                          )
                        }
                      />
                    </Fragment>
                  )}
                  {ruleValues.type === 'onlineStoreActivity' && (
                    <Fragment>
                      <FormFieldItem
                        className={classes.segmentRuleDropdownField}
                        id={`${ruleIdPrefix}-onlineStoreActivity-visited`}
                        name={`${ruleFieldPrefix}.onlineStoreActivity.visited`}
                        orientation="horizontal"
                        render={({ id, isInvalid, value }) => (
                          <Dropdown
                            id={id}
                            isInvalid={isInvalid}
                            items={[
                              {
                                id: 'onlineStoreActivity-visited-true',
                                label: 'Has Visited',
                                value: true,
                              },
                              {
                                id: 'onlineStoreActivity-visited-false',
                                label: 'Has Not Visited',
                                value: false,
                              },
                            ]}
                            onChange={newValue => {
                              setFieldValue(
                                `${ruleFieldPrefix}.onlineStoreActivity.visited`,
                                newValue
                              )
                            }}
                            placeholder="Visited Status"
                            selectedValue={value}
                          />
                        )}
                      />
                      <ItemsFetcher
                        queryKey="storeGroups"
                        queryParams={{ status: true }}
                        fetchItems={config => fetchStoreGroups(`?status=true`, config)}
                        render={({ items: storeGroups, isLoadingItems: isFetchingStoreGroups }) => (
                          <FormFieldItem
                            id={`${ruleIdPrefix}-onlineStoreActivity-storeGroup`}
                            name={`${ruleFieldPrefix}.onlineStoreActivity.storeGroup`}
                            orientation="horizontal"
                            render={({ id, isInvalid, value }) => (
                              <DropdownFilterable
                                id={id}
                                isInvalid={isInvalid}
                                isLoading={isFetchingStoreGroups}
                                items={storeGroups.map(({ id, name }) => ({
                                  id,
                                  label: name,
                                  value: id,
                                }))}
                                onChange={newStoreGroupId => {
                                  setFieldValue(
                                    `${ruleFieldPrefix}.onlineStoreActivity.storeGroup`,
                                    {
                                      id: newStoreGroupId,
                                    }
                                  )
                                }}
                                placeholder="Select Store Group"
                                selectedValue={get(value, 'id')}
                              />
                            )}
                          />
                        )}
                      />
                      {dynamic && (
                        <FormFieldItem
                          className={classes.segmentRuleRangeField}
                          id={`${ruleIdPrefix}-onlineStoreActivity-date-rolling`}
                          name={`${ruleFieldPrefix}.onlineStoreActivity.dateRolling`}
                          orientation="horizontal"
                          render={({ id, isInvalid, value }) => (
                            <Dropdown
                              id={id}
                              isInvalid={isInvalid}
                              items={rolling_date_dropdown_items}
                              onChange={rollingDateOnChange(ruleFieldPrefix, 'onlineStoreActivity')}
                              placeholder="Rolling date period"
                              selectedValue={value}
                            />
                          )}
                        />
                      )}
                      <FormFieldItem
                        className={classes.segmentRuleRangeField}
                        id={`${ruleIdPrefix}-onlineStoreActivity-date`}
                        isRangeField
                        name={`${ruleFieldPrefix}.onlineStoreActivity.date`}
                        orientation="horizontal"
                        render={({ id, isInvalid, value }) =>
                          (!dynamic ||
                            values[`${ruleFieldPrefix}.onlineStoreActivity.showDatePicker`]) && (
                            <DropdownDate
                              id={id}
                              isInvalid={isInvalid}
                              onChange={generateHandleRangeFieldValueChange({
                                rangePropPrefix: 'date',
                                ruleField: 'onlineStoreActivity',
                              })}
                              placeholder="Visit Period"
                              defaultValue={value}
                            />
                          )
                        }
                      />
                    </Fragment>
                  )}
                  {ruleValues.type === 'onlineProductActivity' && (
                    <Fragment>
                      <FormFieldItem
                        id={`${ruleIdPrefix}-onlineProductActivity-have`}
                        name={`${ruleFieldPrefix}.onlineProductActivity.have`}
                        className={classes.segmentRuleDropdownField}
                        orientation="horizontal"
                        render={({ id, isInvalid, value }) => (
                          <Dropdown
                            id={id}
                            isInvalid={isInvalid}
                            items={[
                              { id: 'online-activity-have', label: 'Have', value: true },
                              { id: 'online-activity-have-not', label: 'Have Not', value: false },
                            ]}
                            onChange={newValue => {
                              setFieldValue(
                                `${ruleFieldPrefix}.onlineProductActivity.have`,
                                newValue
                              )
                            }}
                            placeholder="Activity Status"
                            selectedValue={value}
                          />
                        )}
                      />
                      <FormFieldItem
                        id={`${ruleIdPrefix}-onlineProductActivity-activityTypes`}
                        name={`${ruleFieldPrefix}.onlineProductActivity.activityTypes`}
                        className={classes.segmentRuleMultiSelectField}
                        orientation="horizontal"
                        render={({ id, isInvalid, value }) => (
                          <MultiSelect
                            id={id}
                            isInvalid={isInvalid}
                            items={[
                              {
                                id: 'online-activity-type-purchased',
                                label: 'Purchased',
                                value: 'purchased',
                              },
                              ...(showUserEventsOptions
                                ? [
                                    {
                                      id: 'online-activity-type-viewed',
                                      label: 'Viewed',
                                      value: 'viewed',
                                    },
                                    {
                                      id: 'online-activity-type-scanned',
                                      label: 'Scanned',
                                      value: 'scanned',
                                    },
                                    {
                                      id: 'online-activity-type-searched',
                                      label: 'Searched',
                                      value: 'searched',
                                    },
                                    {
                                      id: 'online-activity-type-addedToList',
                                      label: 'Added to List',
                                      value: 'addedToList',
                                    },
                                  ]
                                : []),
                            ]}
                            onChange={newValues => {
                              setFieldValue(
                                `${ruleFieldPrefix}.onlineProductActivity.activityTypes`,
                                newValues.map(newValue => ({ type: newValue }))
                              )
                            }}
                            placeholder="Activities"
                            selectedValues={value ? value.map(({ type }) => type) : []}
                          />
                        )}
                      />
                      {dynamic && (
                        <FormFieldItem
                          className={classes.segmentRuleRangeField}
                          id={`${ruleIdPrefix}-onlineProductActivity-date-rolling`}
                          name={`${ruleFieldPrefix}.onlineProductActivity.dateRolling`}
                          orientation="horizontal"
                          render={({ id, isInvalid, value }) => (
                            <Dropdown
                              id={id}
                              isInvalid={isInvalid}
                              items={rolling_date_dropdown_items}
                              onChange={rollingDateOnChange(
                                ruleFieldPrefix,
                                'onlineProductActivity'
                              )}
                              placeholder="Rolling date period"
                              selectedValue={value}
                            />
                          )}
                        />
                      )}
                      <FormFieldItem
                        className={classes.segmentRuleRangeField}
                        id={`${ruleIdPrefix}-online-activity-date`}
                        isRangeField
                        name={`${ruleFieldPrefix}.onlineProductActivity.date`}
                        orientation="horizontal"
                        render={({ id, isInvalid, value }) =>
                          (!dynamic ||
                            values[`${ruleFieldPrefix}.onlineProductActivity.showDatePicker`]) && (
                            <DropdownDate
                              id={id}
                              isInvalid={isInvalid}
                              onChange={generateHandleRangeFieldValueChange({
                                rangePropPrefix: 'date',
                                ruleField: 'onlineProductActivity',
                              })}
                              placeholder="Activity Date Range"
                              defaultValue={value}
                            />
                          )
                        }
                      />
                    </Fragment>
                  )}
                </Styling.LineOfItems>
                {ruleValues.type === 'onlineProductActivity' && productGroupType !== 'any' && (
                  <div className={classes.addProductGroup}>
                    {productGroupType === 'products' && (
                      <AddProducts
                        id={`${ruleIdPrefix}-onlineProductActivity-products`}
                        selectedProducts={get(
                          ruleValues,
                          'onlineProductActivity.productGroup.products'
                        )}
                        onSelectedProductsChange={newSelectedProducts => {
                          setFieldValue(
                            `${ruleFieldPrefix}.onlineProductActivity.productGroup.products`,
                            newSelectedProducts
                          )
                        }}
                      />
                    )}
                    {productGroupType === 'categories' && (
                      <AddCategories
                        id={`${ruleIdPrefix}-onlineCategoryActivity-select-categories`}
                        onChange={newSelectedCategories => {
                          setFieldValue(
                            `${ruleFieldPrefix}.onlineProductActivity.productGroup.categories`,
                            newSelectedCategories
                          )
                        }}
                        selectedCategories={get(
                          ruleValues,
                          'onlineProductActivity.productGroup.categories'
                        )}
                      />
                    )}
                    {productGroupType === 'brands' && (
                      <AddItems
                        id={`${ruleIdPrefix}-onlineBrandActivity-select-brands`}
                        fetchItems={config =>
                          // Transform `brands` to allow us to use them in the Autocomplete and Browse modal.
                          fetchBrands(config).then(({ brands: brandStrings }) => ({
                            items: transformBrandStrings(brandStrings),
                            itemCount: brandStrings.length,
                          }))
                        }
                        itemName="brand"
                        onChange={newSelectedBrands => {
                          setFieldValue(
                            `${ruleFieldPrefix}.onlineProductActivity.productGroup.brands`,
                            newSelectedBrands.map(brand => omit(brand, 'id')) // remove man-made id from brands form value (we don't want to pass those to the API)
                          )
                        }}
                        getSelectedItems={({ items: brands }) =>
                          getSelectedBrands(
                            get(ruleValues, 'onlineProductActivity.productGroup.brands'),
                            brands
                          )
                        }
                        removeSelectedItemsFromBox
                      />
                    )}
                    {productGroupType === 'collections' && (
                      <AddItems
                        id={`${ruleIdPrefix}-onlineCollectionActivity-select-collections`}
                        fetchItems={config => fetchCollections('', config)}
                        itemName="collection"
                        onChange={newSelectedCollections => {
                          setFieldValue(
                            `${ruleFieldPrefix}.onlineProductActivity.productGroup.collections`,
                            newSelectedCollections
                          )
                        }}
                        selectedItems={get(
                          ruleValues,
                          'onlineProductActivity.productGroup.collections'
                        )}
                        removeSelectedItemsFromBox
                      />
                    )}
                    <FieldErrorMsg
                      id={`${ruleIdPrefix}-onlineProductActivity-productGroup`}
                      errorPath={`${ruleFieldPrefix}.onlineProductActivity.productGroup`}
                      msg={`${capitalize({ phrase: productGroupType })} are required`}
                    />
                  </div>
                )}
              </td>
              <td className={classes.segmentRuleDelete}>
                {rules.length > 1 && (
                  <ButtonIconAction
                    description="Remove Rule"
                    icon="delete"
                    onClick={() => {
                      removeRule(index)
                    }}
                  />
                )}
              </td>
            </tr>
          )
        })}
        <tr>
          <td colSpan="2">
            <Button
              icon="plus"
              iconPosition="before"
              kind="link"
              onClick={() => {
                // Add an id to provide unique keys for each rule <tr>'s `key` prop
                addRule({ id: getNewRuleId() })
              }}
            >
              Add More Rules
            </Button>
          </td>
        </tr>
      </tbody>
    </table>
  )
})

const RuleBasedSegmentForm = ({ cancelDestination, classes, formikProps, validationFunc }) => {
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    validateForm,
  } = formikProps

  return (
    <div className={classes.formContainer}>
      <FormFieldItem
        id={`${BASE_ID}-name`}
        labelText="Segment Name"
        name="name"
        render={({ id, isInvalid, name, value }) => (
          <TextInput
            autoFocus
            className={classes.nameInput}
            id={id}
            isInvalid={isInvalid}
            name={name}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Enter Segment Name"
            type="text"
            value={value}
          />
        )}
      />

      <FormFieldItem
        id={`${BASE_ID}-rules-operator`}
        labelText="Dynamic"
        name="dynamic"
        render={({ id, value }) => (
          <RadioButtons
            className={classes.rulesOperator}
            id={id}
            onChange={newType => {
              setFieldValue('dynamic', newType)
            }}
            orientation="horizontal"
            radioButtonProps={[
              {
                id: 'static',
                labelText: 'Static',
                value: false,
                hasTooltip: true,
                tooltipText: 'Segment populations do not change after creation',
              },
              {
                id: 'dynamic',
                labelText: 'Dynamic',
                value: true,
                hasTooltip: true,
                tooltipText: 'Segment populations updated daily',
              },
            ]}
            selectedValue={value || false}
          />
        )}
      />

      <FormFieldItem
        id={`${BASE_ID}-rules-operator`}
        labelText="Rule(s)"
        name="rules.rulesOperator"
        render={({ id, value }) => (
          <RadioButtons
            className={classes.rulesOperator}
            endLabel="rule(s) below"
            id={id}
            onChange={newType => {
              setFieldValue('rules.rulesOperator', newType)
            }}
            orientation="horizontal"
            radioButtonProps={[
              {
                id: 'all',
                labelText: 'All',
                value: 'AND',
              },
              {
                id: 'any',
                labelText: 'Any',
                value: 'OR',
              },
            ]}
            selectedValue={value}
          />
        )}
      />

      <FormItem>
        <FieldArray
          name="rules.rules"
          render={fieldArrayProps => (
            <RulesTable fieldArrayProps={fieldArrayProps} formikProps={formikProps} />
          )}
          validateOnChange={false}
        />
      </FormItem>

      <Formik
        initialValues={{
          estimate: 'N/A',
        }}
        validate={() => {
          validateForm()
          return validationFunc(values) // validate the enclosing form
        }}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(_ignore, form) => {
          /**
           * `id`s were added to each segment's rule so that they had unique keys for each
           * rule <tr>'s `key` prop in RuleBasedSegmentForm. We need to strip them out here.
           */
          getSegmentUserCountEstimate(removeRuleIdsFromFormValues(values))
            .then(({ userCount }) => {
              // use enclosing form values
              form.setSubmitting(false)
              form.setValues({ estimate: userCount })
            })
            .catch(() => {
              form.setSubmitting(false)
              form.setErrors({
                estimate:
                  'Error encountered calculating estimate. Please double check your segment values above.',
              })
            })
        }}
        render={({
          values,
          errors: estimateErrors,
          handleSubmit,
          isSubmitting: isCalculatingEstimate,
        }) => (
          <FormItem
            fieldId={`${BASE_ID}-estimate`}
            labelText="Estimate Count"
            /* Hide estimate error while estimating, or if there's an error in the surrounding form */
            errorMsg={!isCalculatingEstimate && !errors.global ? estimateErrors.estimate : null}
          >
            <div className={classes.estimateCount}>
              <div className={classes.estimateCountDisplay}>
                {isCalculatingEstimate && <LoadingIndicator.Dots />}
                {!isCalculatingEstimate && (
                  <ReadOnlyValue id={`${BASE_ID}-estimate`} value={values.estimate} />
                )}
              </div>
              <ButtonIconAction
                description="Calculate Estimate"
                disabled={isCalculatingEstimate}
                icon="subFrom"
                id={`${BASE_ID}-estimate-calculate`}
                onClick={handleSubmit}
              />
            </div>
          </FormItem>
        )}
      />

      {!isSubmitting && errors.global && <Notification kind="error" message={errors.global} />}
      <Styling.LineOfButtons>
        <Button disabled={isSubmitting} id={`${BASE_ID}-save`} onClick={handleSubmit}>
          Save
        </Button>
        <Button kind="link" href={cancelDestination} id={`${BASE_ID}-cancel`}>
          Cancel
        </Button>
      </Styling.LineOfButtons>
    </div>
  )
}

RuleBasedSegmentForm.propTypes = {
  /**
   * Where the "cancel" button should send the user back to.
   */
  cancelDestination: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  formikProps: PropTypes.object.isRequired,
  /**
   * Used for the "Estimate" user count button in order to validate the form before attempting
   * to call the BE with the segment data.
   */
  validationFunc: PropTypes.func.isRequired,
}

export default injectSheet(styles)(RuleBasedSegmentForm)
