import React from 'react'
import { connect } from 'react-redux'
import { Route, withRouter } from 'react-router-dom'

import SideNav from './SideNav'

const SidebarView = ({ navLinks }) => (
  <div>
    {navLinks.map(
      (link, index) =>
        link.links &&
        /**
         * Top-level Navbar items (those that appear as the show/collapse triggers in the Navbar)
         * will only have <Route>s rendered for them in the Sidebar if they contain a `routePath`.
         * (So the "Help" dropdown which has no `routePath` (because it has no links that navigate
         * to any part of the app) will not have <Route>s rendered here.)
         */
        link.routePath && (
          <Route
            key={index}
            path={link.routePath}
            render={({ location }) => (
              <SideNav heading={link.label} links={link.links} location={location} />
            )}
          />
        )
    )}
  </div>
)

const mapStateToProps = state => ({
  navLinks: [...state.navigation.links.left, ...state.navigation.links.right],
})

// Wrap withRouter to ensure links re-render when link params (:id/:slug) change
export default withRouter(connect(mapStateToProps)(SidebarView))
