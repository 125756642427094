import Modal from './Modal'
import ConfirmationModal from './ConfirmationModal'
import BrowseItemsModal from './BrowseItemsModal'
import BrowseItemsAsyncModal from './BrowseItemsAsyncModal'
import BrowseCategoriesModal from './BrowseCategoriesModal'
import PrintOrdersModal from './PrintOrdersModal'

/**
 * Act like <Modal>s, but with set sizes and render templates
 */
Modal.BrowseItems = BrowseItemsModal // Can be used for all simple item resources
Modal.BrowseItemsAsync = BrowseItemsAsyncModal
Modal.BrowseCategories = BrowseCategoriesModal
Modal.Confirmation = ConfirmationModal
Modal.PrintOrdersModal = PrintOrdersModal

export default Modal
