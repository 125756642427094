import { Formik } from 'formik'
import React, { Component } from 'react'
import injectSheet from 'react-jss'

import { fetchProduct, updateProduct, uploadProductImage } from 'api'
import {
  Button,
  FormFieldItem,
  Icon,
  ImageUpload,
  LoadingIndicator,
  Notification,
  TextArea,
  TextInput,
} from 'components'
import Styling, { ModalHeader } from 'styling/components'
import { ItemFetcher } from 'utils'

const styles = theme => ({
  form: {
    marginRight: 50,
  },
  formContent: {
    display: 'flex',
    flexDirection: 'row',
  },
  formLeft: {
    flex: '0 1 auto',
    marginRight: theme.spacing.xxlg,
    width: 200,
  },
  formRight: {
    flex: 1,
    width: '100%',
  },
  warningMessage: {
    color: theme.text01,
    fontSize: theme.fontSize.p,
    marginTop: theme.spacing.xlg,
    marginBottom: theme.spacing.md,
  },
  warningMessageIcon: {
    fill: theme.support03,
    height: 16,
    marginRight: theme.spacing.xs,
    verticalAlign: 'middle',
    width: 16,
  },
  warningMessageText: {
    verticalAlign: 'middle',
  },
})

const PAGE_ID = 'temporary-product'

class TemporaryEdit extends Component {
  updateProductTemporarily = (values, form, originalProduct) => {
    const { closeModal, productId } = this.props

    const updatedProduct = Object.assign(originalProduct, values)

    /*
     * To update a product image, an imageId property must be passed (which does not exist in the response)
     * This is a weird hack since product image uploads don't behave the same as regular uploads,
     * A workaround is to pass the imageId from the imageUrl object and add it to the payload
     */
    if (values.imageUrl && values.imageUrl.imageId) {
      updatedProduct.imageId = values.imageUrl.imageId
    }

    return updateProduct(productId, updatedProduct)
      .then(() => {
        form.setSubmitting(false)
        closeModal({ requiresRefetch: true })
      })
      .catch(error => {
        form.setSubmitting(false)
        form.setErrors({ global: error.message })
      })
  }

  render() {
    const { classes, closeModal, productId } = this.props

    return (
      <div>
        <ModalHeader>Temporary Update</ModalHeader>
        <ItemFetcher
          queryKey="product"
          queryParams={{ productId }}
          fetchItem={config => fetchProduct(productId, config)}
          render={({
            error: errorFetchingProduct,
            isLoadingItem: isFetchingProduct,
            item: product,
            refetch: refetchCollection,
          }) => {
            if (isFetchingProduct) {
              return <LoadingIndicator withTopMargin />
            }
            if (errorFetchingProduct) {
              return (
                <Styling.Center maxWidth={500} withTopMargin>
                  <Notification kind="error" message={errorFetchingProduct.message} />
                </Styling.Center>
              )
            }
            if (product) {
              const initialValues = {
                description: product.description || '',
                imageUrl: product.imageUrl || {},
                name: product.name || '',
              }

              return (
                <Formik
                  initialValues={initialValues}
                  validate={values => {
                    const errors = {}

                    if (!values.imageUrl) {
                      errors.imageUrl = 'Image is a required field'
                    }

                    if (!values.name) {
                      errors.name = 'Product Name is a required field'
                    }

                    return errors
                  }}
                  validateOnBlur={false}
                  validateOnChange={false}
                  onSubmit={(values, form) => this.updateProductTemporarily(values, form, product)}
                  render={({
                    values,
                    errors,
                    handleSubmit,
                    isSubmitting,
                    setFieldError,
                    setFieldValue,
                  }) => {
                    return (
                      <form className={classes.form} onSubmit={handleSubmit}>
                        <div className={classes.formContent}>
                          <div className={classes.formLeft}>
                            <FormFieldItem
                              className={classes.formInput}
                              id={`${PAGE_ID}-imageUrl`}
                              labelText="Image"
                              name="imageUrl"
                              render={({ id, value }) => (
                                <ImageUpload
                                  altText=""
                                  fileRequirementsText="Dimensions - 600x600"
                                  height={600}
                                  id={id}
                                  initialImage={{ imageUrl: value }}
                                  onImageUpload={uploadProductImage}
                                  onImageUploadComplete={image => {
                                    setFieldValue('imageUrl', image)
                                  }}
                                  onUploadError={error => {
                                    setFieldError('imageUrl', error)
                                  }}
                                  removable={false}
                                  uploadOptions={{ id: productId }}
                                  // work around for the current product image async process
                                  // as the FE and BE doesn't know when the image processing has been complete
                                  // we are going to use the encoded data from the upload for the preview rather
                                  // than the returned url for the BE as the file may not exist yet.
                                  useEncodedforPreview
                                  vertical
                                  width={600}
                                />
                              )}
                            />
                          </div>
                          <div className={classes.formRight}>
                            <FormFieldItem
                              id={`${PAGE_ID}-product-name`}
                              labelText="Product Name"
                              name="name"
                              render={renderProps => (
                                <TextInput {...renderProps} autoFocus type="text" />
                              )}
                            />
                            <FormFieldItem
                              id={`${PAGE_ID}-product-description`}
                              labelText="Description"
                              name="description"
                              render={renderProps => <TextArea {...renderProps} isResizable />}
                            />
                          </div>
                        </div>
                        <div className={classes.warningMessage}>
                          <Icon className={classes.warningMessageIcon} name="warning" />
                          <span className={classes.warningMessageText}>
                            This update is temporary and will be overwritten by the integration
                            chain.
                          </span>
                        </div>
                        {!isSubmitting && errors.global && (
                          <Notification kind="error" message={errors.global} />
                        )}
                        <Styling.LineOfButtons withoutTopMargin>
                          <Button disabled={isSubmitting} type="submit">
                            Save
                          </Button>
                          <Button kind="link" onClick={closeModal}>
                            Cancel
                          </Button>
                        </Styling.LineOfButtons>
                      </form>
                    )
                  }}
                />
              )
            }
          }}
        />
      </div>
    )
  }
}

export default injectSheet(styles)(TemporaryEdit)
