const commandLineArgs = require('command-line-args')

const LOCAL_DEV_MODE = 'local'

const PRD_MODE = 'prd'

const UAT_MODE = 'uat'

const optionDefinitions = [
  {
    name: 'client',
    alias: 'c',
    type: String,
    typeLabel: '[qa2|cc1|new|...]',
    description: 'Client Short Code',
    defaultValue: 'gar',
  },
  {
    name: 'mode',
    alias: 'm',
    type: String,
    typeLabel: `[${LOCAL_DEV_MODE}|dev|${UAT_MODE}|${PRD_MODE}]`,
    description: 'Server environment',
    defaultValue: UAT_MODE,
  },
  {
    name: 'variant',
    alias: 'v',
    type: String,
    typeLabel: '[custom|master|preview]',
    description: 'Kubernetes Variant',
    defaultValue: 'master',
  },
]

module.exports.LOCAL_DEV_MODE = LOCAL_DEV_MODE

module.exports.UAT_MODE = UAT_MODE

module.exports.V1_DEV_PORT = 2999

module.exports.getAPIPath = mode => ([UAT_MODE, LOCAL_DEV_MODE].includes(mode) ? '/api-qa' : '/api')

module.exports.parseOptions = () => commandLineArgs(optionDefinitions)
