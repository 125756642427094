import isEmpty from 'lodash/isEmpty'
import moment from 'moment'

import {
  MLP_BANNER,
  HERO_BANNERS,
  SLIM_BANNERS,
  MOBILE_ONLY,
  DESKTOP_ONLY,
} from 'constants/placementTypes'
import { createPlacement, updatePlacement } from 'api'
import config from 'config'

import { daysOfWeekArray, userSessionTargeting } from './PromotedPlacementsFormSchema'

import {
  PlacementActiveDays,
  PlacementActivityPeriod,
  PlacementAlignment,
  PlacementCategories,
  PlacementCollections,
  PlacementDisplayContent,
  PlacementItemListConfig,
  PlacementLinkDestination,
  PlacementCTA,
  PlacementCustomColor,
  PlacementSearchKeywords,
  PlacementStoreVisibility,
  PlacementColor,
  PlacementContentId,
  PlacementComponentTitle,
  PlacementText,
  PlacementStyling,
  PlacementTitle,
  PlacementViewAll,
  PlacementUserSegment,
  PlacementUserVisibility,
  Platform,
  UserVisibility,
  UserShoppingContext,
  ExpressMembershipVisibility,
} from './components'

import {
  mlpBannerUploadDimensions,
  heroBannerUploadDimensions,
  useCellWidthDesktopMobileUpload,
} from './components/utils/placementImageUploader'

const PAGE_ID = 'promoted-placements-form'

// Options maps
const linkDestinationMap = {
  categories: {
    id: 'categories',
    labelText: 'Categories',
    value: 'categories',
  },
  collection: {
    id: 'collection',
    labelText: 'Collection',
    value: 'collection',
  },
  none: {
    id: 'image',
    labelText: 'None',
    value: 'none',
  },
  product: {
    id: 'product',
    labelText: 'Product',
    value: 'product',
  },
  products: {
    id: 'products',
    labelText: 'Products',
    value: 'products',
  },
  url: {
    id: 'url',
    labelText: 'URL',
    value: 'url',
  },
}

const displayContentMap = {
  collection: {
    id: 'collection',
    labelText: 'Collection',
    value: 'collection',
  },
  image: {
    id: 'image',
    labelText: 'Image',
    value: 'image',
  },
  none: {
    id: 'image',
    labelText: 'Image',
    value: 'none',
  },
  product: {
    id: 'product',
    labelText: 'Product',
    value: 'product',
  },
  text: {
    id: 'text',
    labelText: 'Text',
    value: 'text',
  },
  url: {
    id: 'url',
    labelText: 'URL',
    value: 'url',
  },
}

// Component maps

const contentIdObjectMap = {
  component: PlacementContentId,
  props: {
    componentId: `${PAGE_ID}-content-id`,
    labelText: 'Content ID',
  },
}

const placementTitleObjectMap = {
  component: PlacementTitle,
  props: {
    componentId: `${PAGE_ID}-placement-title`,
    labelText: 'Placement Title',
    placeholder: 'Enter placement title',
  },
}

const placementComponentTitleObjectMap = {
  component: PlacementComponentTitle,
  props: {
    componentId: `${PAGE_ID}-cs-placement-title`,
    required: true,
  },
}

const PlacementComponentTitleOptionalObjectMap = {
  component: PlacementComponentTitle,
  props: {
    componentId: `${PAGE_ID}-cs-placement-title-optional`,
    required: false,
  },
}

const placementComponentBannerTitleObjectMap = {
  component: PlacementComponentTitle,
  props: {
    componentId: `${PAGE_ID}-cs-placement-banner-title`,
    labelText: 'Title on Banner',
    tooltip: 'This title will be visible on the Banner.',
    required: true,
  },
}

const placementBackgroundCustomColorMap = {
  component: PlacementCustomColor,
  props: {
    componentId: `${PAGE_ID}-placement-background-color`,
    fieldItemName: 'config.backgroundColor',
    labelText: 'Background Color',
  },
}

const placementTitleColorMap = {
  component: PlacementColor,
  props: {
    componentId: `${PAGE_ID}-placement-title-color`,
    fieldItemName: 'config.titleColor',
    labelText: 'Title Color',
  },
}

const placementBodyColorMap = {
  component: PlacementColor,
  props: {
    componentId: `${PAGE_ID}-placement-body-color`,
    fieldItemName: 'config.bodyColor',
    labelText: 'Body Text Color',
  },
}

const placementBodyColorWithBrandMap = {
  component: PlacementColor,
  props: {
    componentId: `${PAGE_ID}-placement-body-color`,
    enableBrandColor: true,
    fieldItemName: 'config.bodyColor',
    labelText: 'Body Text Color',
  },
}

const placementTitleAlignmentMap = {
  component: PlacementAlignment,
  props: {
    componentId: `${PAGE_ID}-placement-title-alignment`,
    fieldItemName: 'config.titleAlignment',
    labelText: 'Title Alignment',
  },
}

const placementAlignmentMap = {
  component: PlacementAlignment,
  props: {
    componentId: `${PAGE_ID}-placement-alignment`,
    fieldItemName: 'config.alignment',
    labelText: 'Text Alignment',
  },
}

const placementBodyMap = {
  component: PlacementText,
  props: {
    componentId: `${PAGE_ID}-placement-body`,
    fieldItemName: 'config.body',
    labelText: 'Body Text (Optional)',
    placeholder: 'Enter body text',
  },
}

const placementContainerTypeMap = {
  component: PlacementStyling,
  props: {
    componentId: `${PAGE_ID}-placement-container-type`,
    fieldItemName: 'config.style',
    labelText: 'Type',
  },
}

const placementViewAllMap = {
  component: PlacementViewAll,
  props: {
    componentId: `${PAGE_ID}-placement-view-all`,
  },
}

const searchKeywordObjectMap = {
  component: PlacementSearchKeywords,
  props: {
    componentId: `${PAGE_ID}-search-keywords`,
    labelText: 'Search Keywords',
    placeholder: 'Search keyword',
    tooltip:
      'Enter comma separate keywords. (Keywords can include spaces - e.g. "apple pie, apple" would count as 2 keywords: "apple pie" and "apple".)',
  },
}

const linkDestinationObjectMap = placement => {
  // Map over `targetTypes` (Link Destination section) from placement to build options
  const linkDestinationRadioProps = placement.targetTypes
    .map(targetType => linkDestinationMap[targetType])
    .filter(targetType => targetType)

  return {
    component: PlacementLinkDestination,
    noOptions: linkDestinationRadioProps.length === 0,
    props: {
      componentId: `${PAGE_ID}-link-destination`,
      radioButtonProps: linkDestinationRadioProps,
    },
  }
}

const displayContentObjectMap = placement => {
  // Map over `mediaTypes` (Display Content section) from placement to build options
  const displayContentRadioProps = placement.mediaTypes
    .map(mediaType => displayContentMap[mediaType])
    .filter(mediaType => mediaType)

  // TODO: hacky solution to use placement's generic DB dimensions or assign custom MLP banner dimensions
  let uploadDimensions = !isEmpty(placement.specs) ? { generic: placement.specs.image.size } : null
  if (placement.key === MLP_BANNER) {
    uploadDimensions = mlpBannerUploadDimensions
  } else if (placement.key === HERO_BANNERS) {
    uploadDimensions = heroBannerUploadDimensions
  } else if (placement.key === SLIM_BANNERS) {
    uploadDimensions = config.components.slimBanner.dimensions
  }

  // We have to manually check if the placement is not `Catalog/Search Placement` nor `Collection/MLP Banner`
  // and if `mediaTypes: ['none']` as only the above 4 placement types should show
  // "Display Content" if mediaTypes are set to "none" only
  const hideDisplayContent =
    placement.mediaTypes.length === 1 &&
    placement.mediaTypes[0] === 'none' &&
    !useCellWidthDesktopMobileUpload.includes(placement.key)

  return {
    component: PlacementDisplayContent,
    noOptions: displayContentRadioProps.length === 0 || hideDisplayContent,
    props: {
      componentId: `${PAGE_ID}-display-content`,
      customUploadDimensions: uploadDimensions,
      radioButtonProps: displayContentRadioProps,
    },
  }
}

const segmentationObjectMap = {
  name: 'Segmentation',
  components: [
    {
      component: PlacementUserSegment,
      props: {
        componentId: `${PAGE_ID}-user-segment`,
        labelText: 'Target User Segment',
        placeholder: 'Select User Segment',
      },
    },
  ],
}

const basicTargetingComponets = [
  {
    component: UserShoppingContext,
    props: {
      componentId: `${PAGE_ID}-userShoppingContext`,
      labelText: 'Shopping Context Visibility',
      checkboxOptions: userSessionTargeting.userShoppingContext,
    },
  },
  {
    component: Platform,
    props: {
      componentId: `${PAGE_ID}-platform`,
      labelText: 'Platform Visibility',
      checkboxOptions: userSessionTargeting.platform,
    },
  },
  {
    component: PlacementStoreVisibility,
    props: {
      componentId: `${PAGE_ID}-store-visibility`,
      labelText: 'Store Visibility',
      placeholder: 'Select Store Group',
    },
  },
  {
    component: UserVisibility,
    props: {
      componentId: `${PAGE_ID}-trial-visibility`,
      labelText: 'Login State Visibility',
      radioButtonProps: [
        {
          id: 'trial-everyone',
          labelText: 'Everyone',
          value: null,
        },
        {
          id: 'trial-true',
          labelText: 'Logged Out Only',
          value: true,
        },
        {
          id: 'trial-false',
          labelText: 'Logged In Only',
          value: false,
        },
      ],
    },
  },
  {
    component: PlacementUserVisibility,
    props: {
      componentId: `${PAGE_ID}-user-visibility`,
      labelText: 'Loyalty Status Targeting',
      radioButtonProps: [
        {
          id: 'loyalty-everyone',
          labelText: 'Everyone',
          value: null,
        },
        {
          id: 'loyalty-true',
          labelText: 'Loyalty Only',
          value: true,
        },
        {
          id: 'loyalty-false',
          labelText: 'Non Loyalty Only',
          value: false,
        },
      ],
    },
  },
  {
    component: ExpressMembershipVisibility,
    props: {
      componentId: `${PAGE_ID}-express-visibility`,
      labelText: 'Instacart+ Membership Targeting',
      radioButtonProps: [
        {
          id: 'express-everyone',
          labelText: 'Everyone',
          value: null,
        },
        {
          id: 'express-true',
          labelText: 'Instacart+ Membership Only',
          value: true,
        },
        {
          id: 'express-false',
          labelText: 'Non Instacart+ Membership Only',
          value: false,
        },
      ],
    },
  },
]

const TargetsObjectMap = {
  name: 'Visibility & Targeting',
  components: basicTargetingComponets,
}

const desktopTargetingComponets = [
  ...basicTargetingComponets.slice(0, 1),
  {
    component: Platform,
    props: {
      componentId: `${PAGE_ID}-platform`,
      labelText: 'Platform Visibility',
      checkboxOptions: userSessionTargeting.platform.filter(
        platform => platform.tag === DESKTOP_ONLY
      ),
      disabled: true,
    },
  },
  ...basicTargetingComponets.slice(2),
]
const DesktopTargetsObjectMap = {
  name: 'Visibility & Targeting',
  components: desktopTargetingComponets,
}

const mobileTargetingComponets = [
  ...basicTargetingComponets.slice(0, 1),
  {
    component: Platform,
    props: {
      componentId: `${PAGE_ID}-platform`,
      labelText: 'Platform Visibility',
      checkboxOptions: userSessionTargeting.platform.filter(
        platform => platform.tag === MOBILE_ONLY
      ),
    },
  },
  ...basicTargetingComponets.slice(2),
]
const MobileTargetsObjectMap = {
  name: 'Visibility & Targeting',
  components: mobileTargetingComponets,
}

const scheduleObjectMap = {
  name: 'Schedule',
  components: [
    {
      component: PlacementActivityPeriod,
      props: {
        componentId: `${PAGE_ID}-activity-period`,
        labelText: 'Activity Period',
      },
    },
    {
      component: PlacementActiveDays,
      props: {
        componentId: `${PAGE_ID}-active-days`,
        labelText: 'Active Days',
        checkboxOptions: daysOfWeekArray,
      },
    },
  ],
}

// Exported map object

export const PromotedPlacementsFormSectionsMap = ({ slug, placement }) => {
  const linkDestinationComponent = linkDestinationObjectMap(placement)
  const displayContentComponent = displayContentObjectMap(placement)

  const imageCarouselImageItemComponent = {
    component: PlacementItemListConfig,
    props: {
      componentId: `${PAGE_ID}-image-item`,
      enableAltText: true,
      requirementsConfig: {
        validateSize: true,
        height: 350,
        width: 350,
      },
      placement,
    },
  }

  const tileCarouselImageItemComponent = {
    component: PlacementItemListConfig,
    props: {
      componentId: `${PAGE_ID}-image-tile-item`,
      enableAltText: true,
      requirementsConfig: {
        validateSize: true,
        height: 90,
        width: 90,
      },
      placement,
    },
  }

  const linkCarouselImageItemComponent = {
    component: PlacementItemListConfig,
    props: {
      componentId: `${PAGE_ID}-image-link-item`,
      enableAltText: false,
      requirementsConfig: {
        validateSize: true,
        height: 22,
        width: 22,
      },
      placement,
    },
  }

  const primaryCTAComponent = {
    component: PlacementCTA,
    props: {
      componentId: `${PAGE_ID}-primary-cta`,
      placement,
    },
  }

  const secondaryCTAComponent = {
    component: PlacementCTA,
    props: {
      componentId: `${PAGE_ID}-secondary-cta`,
      fieldItemParentKey: 'cta2',
      placement,
    },
  }

  const sectionsMap = {
    'catalog-banners': {
      name: 'Catalog Banners',
      sections: [
        {
          name: 'Placement Detail',
          components: [contentIdObjectMap, placementTitleObjectMap],
        },
        {
          name: 'Link Destination',
          components: [linkDestinationComponent],
          hideComponent: linkDestinationComponent.noOptions,
        },
        {
          name: 'Display Content',
          components: [displayContentComponent],
          hideComponent: displayContentComponent.noOptions,
        },
        segmentationObjectMap,
        TargetsObjectMap,
        scheduleObjectMap,
      ],
    },
    'catalog-collections': {
      name: 'Catalog Collections',
      sections: [
        {
          name: 'Placement Detail',
          components: [contentIdObjectMap, placementTitleObjectMap],
        },
        {
          name: 'Link Destination',
          components: [linkDestinationComponent],
          hideComponent: linkDestinationComponent.noOptions,
        },
        {
          name: 'Display Content',
          components: [displayContentComponent],
          hideComponent: displayContentComponent.noOptions,
        },
        segmentationObjectMap,
        TargetsObjectMap,
        scheduleObjectMap,
      ],
    },
    'catalog-placement': {
      name: 'Catalog Placement',
      sections: [
        {
          name: 'Placement Detail',
          components: [
            contentIdObjectMap,
            placementTitleObjectMap,
            {
              component: PlacementCategories,
              props: {
                componentId: `${PAGE_ID}-placement-categories`,
                componentName: 'categories',
                labelText: 'Select Categories',
              },
            },
          ],
        },
        {
          name: 'Link Destination',
          components: [linkDestinationComponent],
          hideComponent: linkDestinationComponent.noOptions,
        },
        {
          name: 'Display Content',
          components: [displayContentComponent],
          hideComponent: displayContentComponent.noOptions,
        },
        segmentationObjectMap,
        TargetsObjectMap,
        scheduleObjectMap,
      ],
    },
    'checkout-upsell': {
      name: 'Checkout Upsell',
      sections: [
        {
          name: 'Placement Detail',
          components: [contentIdObjectMap, placementTitleObjectMap],
        },
        {
          name: 'Display Content',
          components: [displayContentComponent],
          hideComponent: displayContentComponent.noOptions,
        },
        segmentationObjectMap,
        TargetsObjectMap,
        scheduleObjectMap,
      ],
    },
    'collection-banner': {
      name: 'Collection Banner',
      sections: [
        {
          name: 'Placement Detail',
          components: [
            contentIdObjectMap,
            placementTitleObjectMap,
            {
              component: PlacementCollections,
              props: {
                componentId: `${PAGE_ID}-collections`,
                componentName: 'collections',
                labelText: 'Select Collections',
              },
            },
          ],
        },
        {
          name: 'Link Destination',
          components: [linkDestinationComponent],
          hideComponent: linkDestinationComponent.noOptions,
        },
        {
          name: 'Display Content',
          components: [displayContentComponent],
          hideComponent: displayContentComponent.noOptions,
        },
        segmentationObjectMap,
        TargetsObjectMap,
        scheduleObjectMap,
      ],
    },
    'flyer-pdf': {
      name: 'Flyder PDF',
      sections: [
        {
          name: 'Placement Detail',
          components: [contentIdObjectMap, placementTitleObjectMap],
        },
        {
          name: 'Link Destination',
          components: [linkDestinationComponent],
          hideComponent: linkDestinationComponent.noOptions,
        },
        segmentationObjectMap,
        TargetsObjectMap,
        scheduleObjectMap,
      ],
    },
    'header-banner': {
      name: 'Header Banner',
      sections: [
        {
          name: 'Placement Detail',
          components: [contentIdObjectMap, placementTitleObjectMap],
        },
        {
          name: 'Link Destination',
          components: [linkDestinationComponent],
          hideComponent: linkDestinationComponent.noOptions,
        },
        {
          name: 'Display Content',
          components: [displayContentComponent],
          hideComponent: displayContentComponent.noOptions,
        },
        segmentationObjectMap,
        DesktopTargetsObjectMap,
        scheduleObjectMap,
      ],
    },
    'homepage-banners': {
      name: 'Homepage Banner',
      sections: [
        {
          name: 'Placement Detail',
          components: [contentIdObjectMap, placementTitleObjectMap],
        },
        {
          name: 'Link Destination',
          components: [linkDestinationComponent],
          hideComponent: linkDestinationComponent.noOptions,
        },
        {
          name: 'Display Content',
          components: [displayContentComponent],
          hideComponent: displayContentComponent.noOptions,
        },
        segmentationObjectMap,
        TargetsObjectMap,
        scheduleObjectMap,
      ],
    },
    'homepage-collections': {
      name: 'Homepage Collections',
      sections: [
        {
          name: 'Placement Detail',
          components: [contentIdObjectMap, placementTitleObjectMap],
        },
        {
          name: 'Link Destination',
          components: [linkDestinationComponent],
          hideComponent: linkDestinationComponent.noOptions,
        },
        {
          name: 'Display Content',
          components: [displayContentComponent],
          hideComponent: displayContentComponent.noOptions,
        },
        segmentationObjectMap,
        TargetsObjectMap,
        scheduleObjectMap,
      ],
    },
    'homepage-hero': {
      name: 'Homepage Hero',
      sections: [
        {
          name: 'Placement Detail',
          components: [contentIdObjectMap, placementTitleObjectMap],
        },
        {
          name: 'Link Destination',
          components: [linkDestinationComponent],
          hideComponent: linkDestinationComponent.noOptions,
        },
        {
          name: 'Display Content',
          components: [displayContentComponent],
          hideComponent: displayContentComponent.noOptions,
        },
        segmentationObjectMap,
        DesktopTargetsObjectMap,
        scheduleObjectMap,
      ],
    },
    'mobile-header-banner': {
      name: 'Mobile Header Banner',
      sections: [
        {
          name: 'Placement Detail',
          components: [contentIdObjectMap, placementTitleObjectMap],
        },
        {
          name: 'Link Destination',
          components: [linkDestinationComponent],
          hideComponent: linkDestinationComponent.noOptions,
        },
        {
          name: 'Display Content',
          components: [displayContentComponent],
          hideComponent: displayContentComponent.noOptions,
        },
        segmentationObjectMap,
        MobileTargetsObjectMap,
        scheduleObjectMap,
      ],
    },
    'mobile-homepage-hero': {
      name: 'Mobile Header Banner',
      sections: [
        {
          name: 'Placement Detail',
          components: [contentIdObjectMap, placementTitleObjectMap],
        },
        {
          name: 'Link Destination',
          components: [linkDestinationComponent],
          hideComponent: linkDestinationComponent.noOptions,
        },
        {
          name: 'Display Content',
          components: [displayContentComponent],
          hideComponent: displayContentComponent.noOptions,
        },
        segmentationObjectMap,
        MobileTargetsObjectMap,
        scheduleObjectMap,
      ],
    },
    'mobile-order-confirmation': {
      name: 'Mobile Order Confirmation',
      sections: [
        {
          name: 'Placement Detail',
          components: [contentIdObjectMap, placementTitleObjectMap],
        },
        {
          name: 'Link Destination',
          components: [linkDestinationComponent],
          hideComponent: linkDestinationComponent.noOptions,
        },
        {
          name: 'Display Content',
          components: [displayContentComponent],
          hideComponent: displayContentComponent.noOptions,
        },
        segmentationObjectMap,
        MobileTargetsObjectMap,
        scheduleObjectMap,
      ],
    },
    'order-confirmation': {
      name: 'Order Confirmation',
      sections: [
        {
          name: 'Placement Detail',
          components: [contentIdObjectMap, placementTitleObjectMap],
        },
        {
          name: 'Link Destination',
          components: [linkDestinationComponent],
          hideComponent: linkDestinationComponent.noOptions,
        },
        {
          name: 'Display Content',
          components: [displayContentComponent],
          hideComponent: displayContentComponent.noOptions,
        },
        segmentationObjectMap,
        TargetsObjectMap,
        scheduleObjectMap,
      ],
    },
    'search-placement': {
      name: 'Search Placement',
      sections: [
        {
          name: 'Placement Detail',
          components: [contentIdObjectMap, placementTitleObjectMap, searchKeywordObjectMap],
        },
        {
          name: 'Link Destination',
          components: [linkDestinationComponent],
          hideComponent: linkDestinationComponent.noOptions,
        },
        {
          name: 'Display Content',
          components: [displayContentComponent],
          hideComponent: displayContentComponent.noOptions,
        },
        segmentationObjectMap,
        TargetsObjectMap,
        scheduleObjectMap,
      ],
    },
    'shop-collections': {
      name: 'Shop Collections',
      sections: [
        {
          name: 'Placement Detail',
          components: [contentIdObjectMap, placementTitleObjectMap],
        },
        {
          name: 'Link Destination',
          components: [linkDestinationComponent],
          hideComponent: linkDestinationComponent.noOptions,
        },
        {
          name: 'Display Content',
          components: [displayContentComponent],
          hideComponent: displayContentComponent.noOptions,
        },
        segmentationObjectMap,
        TargetsObjectMap,
        scheduleObjectMap,
      ],
    },
    'sponsored-cart-suggestions': {
      name: 'Sponsored Cart Suggestion',
      sections: [
        {
          name: 'Placement Detail',
          components: [contentIdObjectMap, placementTitleObjectMap],
        },
        {
          name: 'Display Content',
          components: [displayContentComponent],
          hideComponent: displayContentComponent.noOptions,
        },
        segmentationObjectMap,
        TargetsObjectMap,
        scheduleObjectMap,
      ],
    },
    'sponsored-product-suggestions': {
      name: 'Sponsored Product Suggestion',
      sections: [
        {
          name: 'Placement Detail',
          components: [contentIdObjectMap, placementTitleObjectMap, linkDestinationComponent],
        },
        {
          name: 'Display Content',
          components: [displayContentComponent],
          hideComponent: displayContentComponent.noOptions,
        },
        segmentationObjectMap,
        scheduleObjectMap,
      ],
    },
    'sponsored-top-deals': {
      name: 'Sponsored Top Deals',
      sections: [
        {
          name: 'Placement Detail',
          components: [contentIdObjectMap, placementTitleObjectMap],
        },
        {
          name: 'Display Content',
          components: [displayContentComponent],
          hideComponent: displayContentComponent.noOptions,
        },
        segmentationObjectMap,
        TargetsObjectMap,
        scheduleObjectMap,
      ],
    },
    'free-delivery-header-banner': {
      name: 'Free Delivery Header Banner',
      sections: [
        {
          name: 'Placement Detail',
          components: [contentIdObjectMap, placementTitleObjectMap],
        },
        {
          name: 'Link Destination',
          components: [linkDestinationComponent],
          hideComponent: linkDestinationComponent.noOptions,
        },
        {
          name: 'Display Content',
          components: [displayContentComponent],
          hideComponent: displayContentComponent.noOptions,
        },
        segmentationObjectMap,
        DesktopTargetsObjectMap,
        scheduleObjectMap,
      ],
    },
    'free-delivery-mobile-header-banner': {
      name: 'Free Delivery Mobile Header Banner',
      sections: [
        {
          name: 'Placement Detail',
          components: [contentIdObjectMap, placementTitleObjectMap],
        },
        {
          name: 'Link Destination',
          components: [linkDestinationComponent],
          hideComponent: linkDestinationComponent.noOptions,
        },
        {
          name: 'Display Content',
          components: [displayContentComponent],
          hideComponent: displayContentComponent.noOptions,
        },
        segmentationObjectMap,
        MobileTargetsObjectMap,
        scheduleObjectMap,
      ],
    },
    'mlp-banner': {
      name: 'Merchandised Landing Page Banner',
      sections: [
        {
          name: 'Placement Detail',
          components: [contentIdObjectMap, placementTitleObjectMap],
        },
        {
          name: 'Link Destination',
          components: [linkDestinationComponent],
          hideComponent: linkDestinationComponent.noOptions,
        },
        {
          name: 'Display Content',
          components: [displayContentComponent],
          hideComponent: displayContentComponent.noOptions,
        },
        segmentationObjectMap,
        TargetsObjectMap,
        scheduleObjectMap,
      ],
    },
    'banner-hero': {
      name: 'Banner - Hero',
      sections: [
        {
          name: 'Placement Detail',
          components: [
            contentIdObjectMap,
            placementTitleObjectMap,
            placementComponentBannerTitleObjectMap,
            placementTitleColorMap,
            placementBodyMap,
            placementBodyColorMap,
          ],
        },
        {
          name: 'Banner Media',
          components: [displayContentComponent],
          hideComponent: displayContentComponent.noOptions,
        },
        {
          name: 'Button 1',
          components: [primaryCTAComponent],
        },
        {
          name: 'Button 2',
          components: [secondaryCTAComponent],
        },
        {
          name: 'Link Destination',
          components: [linkDestinationComponent],
          hideComponent: linkDestinationComponent.noOptions,
        },
        segmentationObjectMap,
        TargetsObjectMap,
        scheduleObjectMap,
      ],
    },
    'banner-slim': {
      name: 'Banner - Slim',
      sections: [
        {
          name: 'Placement Detail',
          components: [
            contentIdObjectMap,
            placementTitleObjectMap,
            // placementComponentBannerTitleObjectMap,
            // placementTitleColorMap,
            // placementBodyMap,
            // placementBodyColorWithBrandMap,
            // placementBackgroundCustomColorMap,
          ],
        },
        {
          name: 'Banner Media',
          components: [displayContentComponent],
          hideComponent: displayContentComponent.noOptions,
        },
        {
          name: 'Link Destination',
          components: [linkDestinationComponent],
          hideComponent: linkDestinationComponent.noOptions,
        },
        segmentationObjectMap,
        TargetsObjectMap,
        scheduleObjectMap,
      ],
    },
    'carousel-images': {
      name: 'Carousel - Images',
      sections: [
        {
          name: 'Placement Detail',
          components: [
            contentIdObjectMap,
            placementTitleObjectMap,
            placementComponentTitleObjectMap,
            placementTitleAlignmentMap,
          ],
        },
        {
          name: 'Config',
          components: [imageCarouselImageItemComponent],
        },
        segmentationObjectMap,
        TargetsObjectMap,
        scheduleObjectMap,
      ],
    },
    'banner-text': {
      name: 'Banner - Text',
      sections: [
        {
          name: 'Placement Detail',
          components: [
            contentIdObjectMap,
            placementTitleObjectMap,
            placementComponentBannerTitleObjectMap,
            placementBodyMap,
            placementAlignmentMap,
          ],
        },
        {
          name: 'Container Style',
          components: [placementContainerTypeMap],
        },
        {
          name: 'Button',
          components: [primaryCTAComponent],
        },
        segmentationObjectMap,
        TargetsObjectMap,
        scheduleObjectMap,
      ],
    },
    'carousel-tiles': {
      name: 'Carousel - Tiles',
      sections: [
        {
          name: 'Placement Detail',
          components: [
            contentIdObjectMap,
            placementTitleObjectMap,
            placementComponentTitleObjectMap,
          ],
        },
        {
          name: 'Display Content',
          components: [displayContentComponent],
          hideComponent: displayContentComponent.noOptions,
        },
        {
          name: 'Configuration',
          components: [tileCarouselImageItemComponent, placementViewAllMap],
        },
        {
          name: 'Link Destination',
          components: [linkDestinationComponent],
          hideComponent: linkDestinationComponent.noOptions,
        },
        segmentationObjectMap,
        TargetsObjectMap,
        scheduleObjectMap,
      ],
    },
    'carousel-links': {
      name: 'Carousel - Links',
      sections: [
        {
          name: 'Placement Detail',
          components: [
            contentIdObjectMap,
            placementTitleObjectMap,
            PlacementComponentTitleOptionalObjectMap,
          ],
        },
        {
          name: 'Display Content',
          components: [displayContentComponent],
          hideComponent: displayContentComponent.noOptions,
        },
        {
          name: 'Configuration',
          components: [linkCarouselImageItemComponent],
        },
        segmentationObjectMap,
        TargetsObjectMap,
        scheduleObjectMap,
      ],
    },
  }

  return sectionsMap[slug]
}

export const onPromotedPlacementsFormSubmit = (edit, formValues) => {
  formValues.startDate =
    formValues.activityPeriod && formValues.activityPeriod.from
      ? moment(formValues.activityPeriod.from).format()
      : null
  formValues.endDate =
    formValues.activityPeriod && formValues.activityPeriod.to
      ? moment(formValues.activityPeriod.to).format()
      : null
  formValues.promotedSearchKeywords =
    formValues.promotedSearchKeywords && formValues.promotedSearchKeywords.length
      ? formValues.promotedSearchKeywords.split(', ')
      : null
  formValues.target.openIn = formValues.target.openIn ? 'new_tab' : 'same_page'
  formValues.target.image.openIn = formValues.target.openIn ? 'new_tab' : 'same_page'

  delete formValues.activityPeriod

  formValues.userSessionTargeting = formValues.userSessionTargeting ?? {}
  formValues.userSessionTargeting.userShoppingContext = formValues.userShoppingContext
  delete formValues.userShoppingContext
  formValues.userSessionTargeting.platform = formValues.platform
  delete formValues.platform
  formValues.userSessionTargeting.isTrialUser = formValues.isTrialUser
  delete formValues.isTrialUser
  formValues.userSessionTargeting.storegroupId = formValues.storeGroup?.id
  formValues.userSessionTargeting.hasLoyalty = formValues.hasLoyalty
  formValues.userSessionTargeting.hasExpressMembership = formValues.hasExpressMembership
  delete formValues.hasExpressMembership

  if (formValues.config?.cta && !formValues.config?.cta?.enabled) {
    delete formValues.config.cta
  }

  if (formValues.config?.cta2 && !formValues.config?.cta2?.enabled) {
    delete formValues.config.cta2
  }

  // If user does not want to see Featured Departments Marketing section, set status 'hidden' and media.type 'none'
  // Since it will be hidden from navigation and does not require an image
  if (!formValues.showMarketingSection && formValues.placement.key === 'featured_department') {
    formValues.status = 'hidden'
    formValues.media.type = 'none'
  }

  if (edit) {
    // As per v1, we need to send `action: 'edit'` in payload so the API knows to maintain search keywords as opposed to
    // ignoring them for a 'reorder' PUT (MERC-89)
    formValues.action = 'edit'
    return updatePlacement(formValues.id, formValues)
  }
  return createPlacement(formValues)
}
