import flatten from 'lodash/flatten'
import React, { Fragment } from 'react'
import Highlighter from 'react-highlight-words'
import injectSheet from 'react-jss'
import { connect } from 'react-redux'
import { NumberValue } from 'react-values'
import { bindActionCreators } from 'redux'

import { deleteRole, fetchRole, fetchRoleUsers, fetchUsers, updateRoleUsers } from 'api'
import {
  AddItemsAsync,
  Button,
  LoadingIndicator,
  Modal,
  Notification,
  PageHeader,
  ReadOnlyValueCard,
  ShowIfAuthorized,
  Tab,
  Tabs,
} from 'components'
import { getUserPermissionKeys } from 'modules/user'
import { createToast } from 'modules/toasts'
import { getBackButton, getRouteTo } from 'routing'
import Styling from 'styling/components'
import { formatDate, get, getPersonName, ItemFetcher, ItemsFetcher } from 'utils'

import { getSectionsInPermissionList } from './utils'

const styles = theme => ({
  underHeaderContainer: {
    display: 'flex',
  },
  leftSide: {
    flexShrink: 0,
    marginRight: theme.spacing.xxlg,
    width: 272,
  },
  rightSide: {
    flexGrow: 1,
    flexShrink: 1,
  },
  selectedUser: {
    alignItems: 'center',
    display: 'flex',
    overflow: 'hidden',
    width: '100%',
  },
  selectedUserName: {
    flexBasis: '40%',
    flexShrink: 1,
  },
  selectedUserEmail: {
    flexBasis: '40%',
    flexShrink: 1,
    marginLeft: theme.spacing.lg,
  },
  selectedUserNumRoles: {
    flexBasis: '20%',
    flexShrink: 0,
    marginLeft: theme.spacing.lg,
  },
})

const PAGE_ID = 'role'

const updateRoleUsersAndCreateToast = ({
  createToast,
  newSelectedUsers,
  refetchRoleUsers,
  roleId,
}) =>
  updateRoleUsers(
    roleId,
    newSelectedUsers.map(({ id }) => ({ id }))
  )
    .then(() => {
      createToast({
        kind: 'success',
        message: `Role's assigned users successfully updated.`,
      })
      refetchRoleUsers()
    })
    .catch(({ message }) => {
      createToast({ kind: 'error', message })
    })

const Role = ({
  classes,
  createToast,
  history,
  location,
  match: {
    params: { roleId },
  },
  userPermissions,
}) => (
  <ItemFetcher
    queryKey="role"
    queryParams={{ roleId }}
    fetchItem={config => fetchRole(roleId, config)}
    render={({ item: role, isLoadingItem: isFetchingRole, error: errorFetchingRole, refetch }) => {
      if (isFetchingRole) {
        return <LoadingIndicator withTopMargin />
      }
      if (errorFetchingRole) {
        return (
          <Styling.Center maxWidth={500} withTopMargin>
            <Notification kind="error" message={errorFetchingRole.message} />
          </Styling.Center>
        )
      }
      if (role) {
        return (
          <div>
            <PageHeader
              backButton={get(location, 'state.backButton') || getBackButton('roles')}
              headerTitle={role.name}
            >
              <Styling.LineOfItems alignCenter>
                <Fragment>
                  <ShowIfAuthorized requiredPermission="roles.edit">
                    <Button href={getRouteTo('roles.editRole', { id: role.id })}>Edit</Button>
                  </ShowIfAuthorized>

                  <ShowIfAuthorized requiredPermission="roles.edit">
                    <Modal.Confirmation
                      handleCloseModal={({ wasConfirmed }) => {
                        if (wasConfirmed) {
                          deleteRole(role.id)
                            .then(() => {
                              createToast({
                                kind: 'success',
                                message: 'Role successfully deleted.',
                              })

                              // Send the user back to the Roles page
                              history.push(getRouteTo('roles'))
                            })
                            .catch(({ message }) => {
                              createToast({ kind: 'error', message })
                            })
                        }
                      }}
                      triggerRender={({ openModal }) => (
                        <Button kind="danger" onClick={openModal}>
                          Delete
                        </Button>
                      )}
                      contentProps={{
                        actionText: 'delete this role',
                        confirmButtonText: 'Delete',
                      }}
                    />
                  </ShowIfAuthorized>
                </Fragment>
              </Styling.LineOfItems>
            </PageHeader>
            <div className={classes.underHeaderContainer}>
              <div className={classes.leftSide}>
                <ReadOnlyValueCard
                  detailValues={[
                    {
                      id: `${PAGE_ID}-name`,
                      labelText: 'Name',
                      value: role.name,
                    },
                    {
                      id: `${PAGE_ID}-created`,
                      labelText: 'Created',
                      value: formatDate(role.created),
                    },
                  ]}
                />
              </div>
              <div className={classes.rightSide}>
                <NumberValue defaultValue={0}>
                  {({ value: selectedTab, set: selectTab }) => (
                    <Tabs
                      className={classes.rightSide}
                      selectedTab={selectedTab}
                      onSelectTab={selectTab}
                    >
                      <Tab label="Assigned Users">
                        <ItemsFetcher
                          queryKey="roleUsers"
                          queryParams={{ roleId }}
                          fetchItems={config => fetchRoleUsers(roleId, config)}
                          render={({
                            items: roleUsers,
                            isLoadingItems: isFetchingRoleUsers,
                            error: errorFetchingRoleUsers,
                            refetch: refetchRoleUsers,
                          }) => {
                            if (isFetchingRoleUsers) {
                              return <LoadingIndicator />
                            }
                            return (
                              <Modal.Confirmation
                                handleCloseModal={({ wasConfirmed, newSelectedUsers }) => {
                                  if (wasConfirmed) {
                                    updateRoleUsersAndCreateToast({
                                      createToast,
                                      newSelectedUsers,
                                      refetchRoleUsers,
                                      roleId,
                                    })
                                  }
                                }}
                                triggerRender={({ openModal }) => (
                                  <AddItemsAsync
                                    autocompleteLabelKey="label"
                                    autocompleteRenderMenuItem={({ item: user, inputValue }) => {
                                      const userFullName = getPersonName({
                                        person: user,
                                      })
                                      return (
                                        <div>
                                          <Highlighter
                                            searchWords={[inputValue]}
                                            autoEscape
                                            textToHighlight={userFullName}
                                          />
                                        </div>
                                      )
                                    }}
                                    errorMsg={errorFetchingRoleUsers}
                                    fetchItems={(queryString, config) =>
                                      fetchUsers(queryString, config).then(
                                        ({ items: users, itemCount }) => ({
                                          // Add `label` for Autocomplete to use for filtering
                                          items: users.map(user => ({
                                            ...user,
                                            label: getPersonName({
                                              person: user,
                                            }),
                                          })),
                                          itemCount,
                                        })
                                      )
                                    }
                                    fetchItemsAutocomplete={inputValue =>
                                      fetchUsers(
                                        `?search=${inputValue}&order_by=firstName&direction=asc`
                                      )
                                    }
                                    id={`${PAGE_ID}-assigned-users`}
                                    itemName="user"
                                    modalProps={{
                                      formatTableRows: users =>
                                        users.map(
                                          ({ id, email, firstName, lastName, roleCount }) => ({
                                            id: `${id}`,
                                            resourceName: 'user',
                                            firstName,
                                            lastName,
                                            email,
                                            roleCount,
                                          })
                                        ),
                                      tableHeaders: [
                                        {
                                          key: 'firstName',
                                          header: 'First Name',
                                        },
                                        {
                                          key: 'lastName',
                                          header: 'Last Name',
                                        },
                                        {
                                          key: 'email',
                                          header: 'Email',
                                        },
                                        {
                                          key: 'roleCount',
                                          header: '# of Roles',
                                          disableSorting: true,
                                        },
                                      ],
                                    }}
                                    onChange={newSelectedUsers => {
                                      const currentRoleUsersIds = roleUsers.map(({ id }) => id)
                                      const newRoleUsersIds = newSelectedUsers.map(({ id }) => id)
                                      const unassignedFromRoleUsersIds = currentRoleUsersIds.filter(
                                        id => !newRoleUsersIds.includes(id)
                                      )

                                      if (unassignedFromRoleUsersIds.length > 0) {
                                        // Open confirmation modal if any users are being unassigned from the role
                                        openModal({ newSelectedUsers })
                                      } else {
                                        // Otherwise, proceed with the addition of 1 or more assigned users to the role
                                        updateRoleUsersAndCreateToast({
                                          createToast,
                                          newSelectedUsers,
                                          refetchRoleUsers,
                                          roleId,
                                        })
                                      }
                                    }}
                                    readOnly={!userPermissions.includes('roles.edit')}
                                    restrictAutocompleteWidth
                                    selectedItems={roleUsers}
                                    selectedItemsRenderItem={({ item: user }) => (
                                      <div className={classes.selectedUser}>
                                        <div className={classes.selectedUserName}>
                                          {getPersonName({ person: user })}
                                        </div>
                                        <div className={classes.selectedUserEmail}>
                                          {user.email}
                                        </div>
                                        <div className={classes.selectedUserNumRoles}>
                                          {user.roleCount
                                            ? `${user.roleCount} role${
                                                user.roleCount !== 1 ? 's' : ''
                                              } assigned`
                                            : 'N/A'}
                                        </div>
                                      </div>
                                    )}
                                  />
                                )}
                                contentProps={{
                                  actionText: `proceed with this update to the role's assigned users`,
                                  confirmButtonText: 'Proceed',
                                  details: `This update involves users being removed from the list of assigned users for this role.`,
                                }}
                              />
                            )
                          }}
                        />
                      </Tab>
                      <Tab label="Permissions">
                        <ReadOnlyValueCard
                          detailValues={flatten(
                            getSectionsInPermissionList(role.permissions).map(
                              ({ labelText, features }) => [
                                {
                                  type: 'groupHeading',
                                  labelText,
                                },
                                ...features.map(({ key, labelText, permissions }) => ({
                                  id: `${PAGE_ID}-permission-feature-${key}`,
                                  labelText,
                                  small: true,
                                  value: permissions
                                    .map(permission => permission.labelText)
                                    .join(', '),
                                  wideLabel: true,
                                })),
                              ]
                            )
                          )}
                        />
                      </Tab>
                    </Tabs>
                  )}
                </NumberValue>
              </div>
            </div>
          </div>
        )
      }
    }}
  />
)

const mapStateToProps = state => ({
  userPermissions: getUserPermissionKeys(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createToast,
    },
    dispatch
  )

export default injectSheet(styles)(connect(mapStateToProps, mapDispatchToProps)(Role))
