import React, { Component, Fragment } from 'react'
import injectSheet from 'react-jss'

import * as placementAlignments from 'constants/placementAlignments'
import * as placementStyling from 'constants/placementStyles'

import { deletePlacement, fetchPlacementContentById, updatePlacement } from 'api'
import {
  Button,
  DataTableWithState,
  LoadingIndicator,
  Modal,
  Notification,
  PageHeader,
  ReadOnlyValueCard,
  ShowIfAuthorized,
} from 'components'
import { createToast } from 'modules/toasts'
import { getBackButton, getRoutePathname } from 'routing'
import {
  HERO_BANNERS,
  IMAGE_CAROUSELS,
  RICH_TEXT_CONTAINERS,
  SLIM_BANNERS,
  TILE_CAROUSELS,
  QUICK_LINKS,
} from 'constants/placementTypes'
import Styling from 'styling/components'
import {
  absoluteUrl,
  capitalize,
  createSlugFromName,
  formatDate,
  formatDatetime,
  get,
  ItemFetcher,
} from 'utils'

import getContentId from './getContentId'

const PAGE_ID = 'promoted-placement-content'

const styles = theme => ({
  imageWidth: {
    verticalAlign: 'top',
    width: '100%',
  },
  leftSide: {
    flexShrink: 0,
    marginRight: theme.spacing.xxlg,
    width: 272,
  },
  rightSide: {
    flexGrow: 1,
    flexShrink: 1,
  },
  flexItem: {
    marginBottom: theme.spacing.md,
  },
  underHeaderContainer: {
    display: 'flex',
  },
  itemImage: {
    maxHeight: 100,
  },
})

const alignmentMap = {
  [placementAlignments.LEFT]: 'Left',
  [placementAlignments.CENTER]: 'Center',
  [placementAlignments.RIGHT]: 'Right',
}

const stylingMap = {
  [placementStyling.DARK]: 'Dark',
  [placementStyling.LIGHT]: 'Light',
  [placementStyling.PRIMARY]: 'Primary',
  [placementStyling.SECONDARY]: 'Secondary',
  [placementStyling.NONE]: 'None',
}

const statusMap = {
  readable: {
    approved: {
      confirm: 'approve',
      trigger: 'approve',
    },
    canceled: {
      confirm: 'confirm',
      trigger: 'cancel',
    },
    deleted: {
      confirm: 'archive',
      trigger: 'archive',
    },
    pending: {
      confirm: 'submit',
      trigger: 'submit',
    },
    rejected: {
      confirm: 'reject',
      trigger: 'reject',
    },
  },
  buttonStyle: {
    approved: 'secondary',
    canceled: 'danger',
    deleted: 'danger',
    pending: 'primary',
    rejected: 'secondary',
  },
}

const userShoppingContextMap = {
  delivery: 'Delivery',
  pickup: 'Pickup',
  instore: 'In-store',
  catering: 'Catering',
}

const platformMap = {
  desktop: 'Desktop',
  ios_web: 'iOS Web',
  ios_app: 'iOS App',
  android_web: 'Android Web',
  android_app: 'Android App',
}

const userShoppingContextDefault = {
  catering: true,
  delivery: true,
  instore: true,
  pickup: true,
}

const platformDefault = {
  android_app: true,
  android_web: true,
  desktop: true,
  ios_app: true,
  ios_web: true,
}

const yesOrNo = value => (value ? 'Yes' : 'No')

export const initializeMediaContent = ({ classes, media, promotedMedia }) => {
  const mediaContent = []

  // create generic media content
  let genericContentBody
  if (media.type === 'image' && media.image) {
    genericContentBody = (
      <img src={media.image.imageUrl} className={classes.imageWidth} alt={media.image.altText} />
    )
  } else if (media.type === 'text' && media.text) {
    genericContentBody = <span>{media.text}</span>
  }
  const genericContent = genericContentBody && {
    id: `${PAGE_ID}-content-generic`,
    labelText: 'Content',
    useCustomValueEl: true,
    value: genericContentBody,
  }
  genericContent && mediaContent.push(genericContent)

  // create promoted media content
  ;['desktop', 'mobile'].forEach(platform => {
    const media = promotedMedia[platform]
    if (!media?.fileUrl && !media?.imageUrl) {
      return
    }

    const value = media.fileUrl ? (
      <Button
        href={media.fileUrl}
        kind="link"
        icon="external"
        iconPosition="inline"
        linkIsExternal
        linkIconShowOnHover
      >
        Video
      </Button>
    ) : (
      <img src={media.imageUrl} className={classes.imageWidth} alt={media.altText || ''} />
    )
    mediaContent.push({
      id: `${PAGE_ID}-content-${platform}`,
      labelText: `Content (${platform})`,
      useCustomValueEl: true,
      value,
    })
  })

  return mediaContent
}

export const initializeAltText = ({ classes, media, promotedMedia }) => {
  const contentAltText = []
  if (media.type === 'image' && media.image) {
    contentAltText.push({
      id: `${PAGE_ID}-content-alt-text`,
      labelText: 'Alt Text',
      value: media.image.altText || 'n/a',
    })
  }

  ;['desktop', 'mobile'].forEach(platform => {
    if (promotedMedia[platform] && promotedMedia[platform].altText) {
      contentAltText.push({
        id: `${PAGE_ID}-alt-text-${platform}`,
        labelText: `Alt Text (${platform})`,
        value: promotedMedia[platform].altText || 'n/a',
      })
    }
  })

  return contentAltText
}

class PromotedPlacementsDetailsFields extends Component {
  formatModalConfirmationButton = buttonStatus => {
    const { history, location, placementContent } = this.props
    const { id } = placementContent
    const goBackRoute = get(location, 'to') || '/marketing/promoted-placements'

    const modalConfirmationProps = {
      handleCloseModal: ({ wasConfirmed }) => {
        const updatedPromotedPlacement = { ...placementContent, status: buttonStatus }

        const successToast = () => {
          createToast({
            kind: 'success',
            message: `Promoted placement successfully ${updatedPromotedPlacement.status}.`,
          })
        }

        if (wasConfirmed) {
          if (buttonStatus === 'deleted' || buttonStatus === 'canceled') {
            deletePlacement(id)
              .then(() => {
                successToast()
                history.push(goBackRoute)
              })
              .catch(({ message }) => {
                createToast({ kind: 'error', message })
              })
          } else {
            updatePlacement(id, updatedPromotedPlacement)
              .then(() => {
                successToast()
                this.props.refetchPlacementContent()
              })
              .catch(({ message }) => {
                createToast({ kind: 'error', message })
              })
          }
        }
      },
      triggerRender: ({ openModal }) => (
        <Button kind={statusMap.buttonStyle[buttonStatus]} onClick={openModal}>
          {capitalize({ phrase: statusMap.readable[buttonStatus].trigger })}
        </Button>
      ),
      contentProps: {
        actionText: `${statusMap.readable[buttonStatus].trigger} this promoted placement`,
        confirmButtonText: capitalize({ phrase: statusMap.readable[buttonStatus].confirm }),
      },
    }

    return <Modal.Confirmation {...modalConfirmationProps} />
  }

  formatDestinationValue = targetData => {
    let destinationHref
    let destinationName
    let buttonProps

    destinationName =
      targetData.type === 'url' || targetData.type === 'none'
        ? targetData[targetData.type] || 'none'
        : targetData[targetData.type].name

    switch (targetData.type) {
      case 'categories':
        destinationName =
          targetData.type === 'categories' && targetData.categories.length > 0
            ? targetData.categories.map(category => category.name).join(', ')
            : 'n/a'
        break
      case 'collection':
        destinationHref = getRoutePathname('collections.collection', {
          id: targetData.collection.id,
        })
        break
      case 'offer':
        destinationHref = getRoutePathname('offers.offer', { id: targetData.offer.id })
        break
      case 'product':
        destinationHref = getRoutePathname('products.product', {
          id: targetData.product.id,
        })
        break
      case 'url':
        destinationHref = absoluteUrl(targetData.url)
        buttonProps = {
          icon: 'external',
          iconPosition: 'inline',
          linkIsExternal: true,
          linkIconShowOnHover: true,
        }
        break
      default:
        break
    }

    return targetData.type !== 'none' ? (
      <Button href={destinationHref} kind="link" {...buttonProps}>
        {destinationName}
      </Button>
    ) : (
      destinationName
    )
  }

  render() {
    const { classes, location, placementContent } = this.props

    const {
      categories,
      collections,
      created,
      customerSegment,
      endDate,
      id,
      media,
      modified,
      name,
      placement: { id: placementId, name: placementName, key: placementKey },
      promotedMedia,
      promotedSearchKeywords,
      startDate,
      status,
      storeGroup,
      target,
      config,
      userSessionTargeting,
    } = placementContent

    const destinationValue = this.formatDestinationValue(target)
    const slug = createSlugFromName(placementName)

    // Initialize "Content" information for Value Card
    const contentArray = initializeMediaContent({ classes, media, promotedMedia })
    const altTextArray = initializeAltText({ classes, media, promotedMedia })
    const searchKeywordsString =
      promotedSearchKeywords && promotedSearchKeywords.length > 0
        ? promotedSearchKeywords.join(', ')
        : 'n/a'
    const categoriesString =
      categories && categories.length > 0
        ? categories.map(category => category.name).join(', ')
        : 'n/a'
    const collectionsString =
      collections && collections.length > 0
        ? collections.map(collection => collection.name).join(', ')
        : 'n/a'
    const contexts = userSessionTargeting?.userShoppingContext ?? userShoppingContextDefault
    const userShoppingContextString = Object.keys(contexts)
      .filter(key => contexts[key])
      .map(key => userShoppingContextMap[key])
      .join(', ')
    const platforms = userSessionTargeting?.platform || platformDefault
    const platformString = Object.keys(platforms)
      .filter(key => platforms[key])
      .map(key => platformMap[key])
      .join(', ')
    const trialUserVisibility =
      userSessionTargeting?.isTrialUser == null
        ? 'Everyone'
        : userSessionTargeting?.isTrialUser
        ? 'Logged Out Only'
        : 'Logged In Only'
    const expressMembershipVisibility =
      userSessionTargeting?.hasExpressMembership == null
        ? 'Everyone'
        : userSessionTargeting?.hasExpressMembership
        ? 'Instacart+ Membership Only'
        : 'Non Instacart+ Membership Only'
    const dynamicUserVisibility =
      userSessionTargeting?.hasLoyalty == null
        ? 'Everyone'
        : userSessionTargeting?.hasLoyalty
        ? 'Loyalty Only'
        : 'Non Loyalty Only'

    const generatedBackButton =
      get(location, 'state.backButton') ||
      getBackButton('promotedPlacements', { label: 'Merchandising' })

    return (
      <Fragment>
        <PageHeader
          backButton={generatedBackButton}
          headerTitle={name}
          statusIsActive={status === 'approved' || false}
          statusText={capitalize({ phrase: status })}
        >
          <Styling.LineOfItems alignCenter>
            <ShowIfAuthorized requiredPermission="content.edit">
              {status === 'pending' ? this.formatModalConfirmationButton('approved') : null}
              {status === 'pending' ? this.formatModalConfirmationButton('rejected') : null}

              {status !== 'canceled' && status !== 'completed' && (
                <Button
                  href={{
                    pathname: getRoutePathname('promotedPlacements.edit', {
                      id,
                      slug,
                    }),
                    state: {
                      currentPlacementName: placementName,
                    },
                  }}
                  kind="secondary"
                >
                  Edit
                </Button>
              )}

              {status === 'draft' ? this.formatModalConfirmationButton('pending') : null}
              {status === 'approved' ? this.formatModalConfirmationButton('canceled') : null}

              <Button
                href={{
                  pathname: getRoutePathname('promotedPlacements.create', {
                    id: placementId,
                    slug,
                  }),
                  state: {
                    backButton: generatedBackButton,
                    currentPlacementName: placementName,
                    duplicateId: id,
                  },
                }}
                kind="secondary"
              >
                Duplicate
              </Button>

              {status === 'draft' || status === 'rejected' || status === 'pending'
                ? this.formatModalConfirmationButton('deleted')
                : null}
            </ShowIfAuthorized>
          </Styling.LineOfItems>
        </PageHeader>
        <div className={classes.underHeaderContainer}>
          <div className={classes.leftSide}>
            <ReadOnlyValueCard
              detailValues={[
                {
                  id: `${PAGE_ID}-name`,
                  labelText: 'Title',
                  value: name,
                },
                ...(config?.title
                  ? [
                      {
                        id: `${PAGE_ID}-external-title`,
                        labelText: 'External Title',
                        value: config.title,
                      },
                    ]
                  : []),
                {
                  id: `${PAGE_ID}-content-id`,
                  labelText: 'Content ID',
                  value: getContentId(placementKey, id),
                },
                {
                  id: `${PAGE_ID}-created`,
                  labelText: 'Created',
                  value: formatDatetime(created),
                },
                {
                  id: `${PAGE_ID}-modified`,
                  labelText: 'Last Edit',
                  value: formatDatetime(modified),
                },
                {
                  id: `${PAGE_ID}-status`,
                  labelText: 'Status',
                  value: capitalize({ phrase: status }),
                },
                {
                  id: `${PAGE_ID}-destination`,
                  labelText: 'Destination',
                  useCustomValueEl: true,
                  value: destinationValue,
                },
                ...contentArray,
                ...altTextArray,
                ...(placementKey === HERO_BANNERS
                  ? [
                      {
                        id: `${PAGE_ID}-body`,
                        labelText: 'Body Text',
                        value: config.body,
                      },
                      {
                        id: `${PAGE_ID}-title-color`,
                        labelText: 'Title Color',
                        value: config.titleColor,
                      },
                      {
                        id: `${PAGE_ID}-text-color`,
                        labelText: 'Text Color',
                        value: config.bodyColor,
                      },
                      ...['cta', 'cta2']
                        .map((key, index) =>
                          key in config && config[key].enabled
                            ? [
                                {
                                  id: `${PAGE_ID}-${key}-text`,
                                  labelText: `Button ${index + 1} Title`,
                                  value: config[key].text,
                                },
                                {
                                  id: `${PAGE_ID}-${key}-style`,
                                  labelText: `Button ${index + 1} Style`,
                                  value: stylingMap[config[key].style],
                                },
                                {
                                  id: `${PAGE_ID}-${key}-url`,
                                  labelText: `Button ${index + 1} URL`,
                                  value: config[key].url,
                                },
                              ]
                            : [
                                {
                                  id: `${PAGE_ID}-${key}-disabled`,
                                  labelText: `Button ${index + 1}`,
                                  value: 'Disabled',
                                },
                              ]
                        )
                        .flat(),
                    ]
                  : []),
                ...(placementKey === SLIM_BANNERS
                  ? [
                      // We can refactor these later
                      {
                        id: `${PAGE_ID}-body`,
                        labelText: 'Body Text',
                        value: config.body,
                      },
                      {
                        id: `${PAGE_ID}-title-color`,
                        labelText: 'Title Color',
                        value: config.titleColor,
                      },
                      {
                        id: `${PAGE_ID}-text-color`,
                        labelText: 'Text Color',
                        value: config.bodyColor,
                      },
                    ]
                  : []),
                ...(placementKey === IMAGE_CAROUSELS
                  ? [
                      {
                        id: `${PAGE_ID}-title-alignment`,
                        labelText: 'Title Alignment',
                        value: alignmentMap[config.titleAlignment],
                      },
                      {
                        id: `${PAGE_ID}-item-count`,
                        labelText: '# of Items',
                        value: config.items.length,
                      },
                    ]
                  : []),
                ...(placementKey === RICH_TEXT_CONTAINERS
                  ? [
                      {
                        id: `${PAGE_ID}-content`,
                        labelText: 'Content',
                        value: config.body,
                      },
                      {
                        id: `${PAGE_ID}-alignment`,
                        labelText: 'Alignment',
                        value: alignmentMap[config.alignment],
                      },
                      {
                        id: `${PAGE_ID}-cta-enabled`,
                        labelText: 'Button Enabled',
                        value: yesOrNo(config.cta?.enabled),
                      },

                      {
                        id: `${PAGE_ID}-cta-style`,
                        labelText: 'Button Style',
                        value: stylingMap[config.cta?.style],
                      },
                      {
                        id: `${PAGE_ID}-cta-text`,
                        labelText: 'Button Text',
                        value: config.cta?.text,
                      },
                      {
                        id: `${PAGE_ID}-cta-url`,
                        labelText: 'Button URL',
                        value: config.cta?.url,
                      },
                    ]
                  : []),
                ...(placementKey === TILE_CAROUSELS
                  ? [
                      {
                        id: `${PAGE_ID}-view-all-title`,
                        labelText: '"View All" Title',
                        value: config.viewAllTitle || '(default)',
                      },
                    ]
                  : []),
              ]}
            />
          </div>
          <div className={classes.rightSide}>
            <div className={classes.flexItem}>
              <ReadOnlyValueCard
                detailValues={[
                  {
                    id: `${PAGE_ID}-placement-type`,
                    labelText: 'Placement Type',
                    value: placementName,
                  },
                  {
                    id: `${PAGE_ID}-active-period`,
                    labelText: 'Active Period',
                    value: `${formatDate(startDate)} - ${formatDate(endDate)}`,
                  },
                  {
                    id: `${PAGE_ID}-store-group`,
                    labelText: 'Store Group',
                    useCustomValueEl: true,
                    value: storeGroup ? (
                      <Button
                        href={{
                          pathname: getRoutePathname('storeGroups.storeGroup', {
                            id: storeGroup.id,
                          }),
                        }}
                        kind="link"
                      >
                        {storeGroup.name}
                      </Button>
                    ) : (
                      'All Stores'
                    ),
                  },
                  {
                    id: `${PAGE_ID}-segment`,
                    labelText: 'User Segment',
                    value: customerSegment ? customerSegment.name : 'Everyone',
                  },
                  {
                    id: `${PAGE_ID}-search-keywords`,
                    labelText: 'Search Keywords',
                    value: searchKeywordsString,
                  },
                  {
                    id: `${PAGE_ID}-categories`,
                    labelText: 'Categories',
                    value: categoriesString,
                  },
                  {
                    id: `${PAGE_ID}-collections`,
                    labelText: 'Collections',
                    value: collectionsString,
                  },
                ]}
              />
            </div>

            <div className={classes.flexItem}>
              <ReadOnlyValueCard
                detailValues={[
                  {
                    id: `${PAGE_ID}-userShoppingContext`,
                    labelText: 'Shopping Context Visibility',
                    value: userShoppingContextString,
                  },
                  {
                    id: `${PAGE_ID}-platform`,
                    labelText: 'Platform Visibility',
                    value: platformString,
                  },
                  {
                    id: `${PAGE_ID}-trialVisibility`,
                    labelText: 'Login State Visibility',
                    value: trialUserVisibility,
                  },
                  {
                    id: `${PAGE_ID}-dynamicVisibility`,
                    labelText: 'Loyalty Status Targeting',
                    value: dynamicUserVisibility,
                  },
                  {
                    id: `${PAGE_ID}-expressMembershipVisibility`,
                    labelText: 'Instacart+ Membership Targeting',
                    value: expressMembershipVisibility,
                  },
                ]}
              />
            </div>

            {[QUICK_LINKS, TILE_CAROUSELS, IMAGE_CAROUSELS].includes(placementKey) && (
              <div className={classes.flexItem}>
                <DataTableWithState
                  id="promoted-placements-details-items-table"
                  kind="default"
                  emptyMsg="No items"
                  headers={[
                    {
                      key: 'image',
                      header: 'Image',
                    },
                    {
                      key: 'label',
                      header: 'Label',
                    },
                    {
                      key: 'url',
                      header: 'URL',
                    },
                  ]}
                  rows={placementContent.config.items.map(({ image, label, url }, index) => ({
                    id: index.toString(),
                    image: image && (
                      <img className={classes.itemImage} alt={label} src={image.image_url} />
                    ),
                    label,
                    url: (
                      <Button kind="link" href={url}>
                        {url}
                      </Button>
                    ),
                  }))}
                />
              </div>
            )}
          </div>
        </div>
      </Fragment>
    )
  }
}

const PromotedPlacementsDetails = ({
  match: {
    params: { id: placementContentId },
  },
  ...restProps
}) => {
  return placementContentId ? (
    <ItemFetcher
      queryKey="placementContentById"
      queryParams={{ id: placementContentId }}
      fetchItem={itemConfig => fetchPlacementContentById(placementContentId, itemConfig)}
      render={({
        item: placementContent,
        isLoadingItem: isFetchingPlacementContent,
        error: errorFetchingPlacementContent,
        refetch: refetchPlacementContent,
      }) => {
        if (errorFetchingPlacementContent) {
          return (
            <Styling.Center maxWidth={500} withTopMargin>
              <Notification kind="error" message={errorFetchingPlacementContent.message} />
            </Styling.Center>
          )
        }
        if (isFetchingPlacementContent) {
          return <LoadingIndicator withTopMargin />
        }
        if (!placementContent) return null
        return (
          <PromotedPlacementsDetailsFields
            {...restProps}
            placementContent={placementContent}
            refetchPlacementContent={refetchPlacementContent}
          />
        )
      }}
    />
  ) : null
}

export default injectSheet(styles)(PromotedPlacementsDetails)
