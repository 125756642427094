export default theme => ({
  nameWithImage: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: theme.spacing.md,
    width: 30,
    '& > img': {
      maxHeight: 30,
      maxWidth: 30,
    },
  },
})
