import { mixins } from 'styling'

export default theme => {
  const checkbox = mixins.checkbox({ theme })

  return {
    checkboxWrapper: {},
    checkbox: {
      ...mixins.hidden,
    },
    checkboxLabelContainer: {
      ...checkbox.label.default,
      alignItems: 'center',
      display: 'flex',
      paddingLeft: theme.spacing.lg,
      position: 'relative',

      // Style Checkbox square when when input is checked/indeterminate
      '$checkbox:checked + & > $checkboxSquare::before': {
        ...checkbox.square.checked,
      },
      '$checkbox$indeterminate + & > $checkboxSquare::before': {
        ...checkbox.square.indeterminate,
      },
      // Style Checkbox square when input is focused
      '$checkbox:focus + & > $checkboxSquare::before': {
        ...checkbox.square.focus,
      },

      // Style Checkbox checkmark when input is checked/indeterminate
      '$checkbox:checked + & > $checkboxSquare::after': {
        ...checkbox.checkmark.checked,
      },
      '$checkbox$indeterminate + & > $checkboxSquare::after': {
        ...checkbox.checkmark.indeterminate,
      },
    },
    checkboxSquare: {
      // Checkbox square
      '&::before': {
        ...checkbox.square.default,
        left: '0',
        position: 'absolute',
        top: 'calc(50% - 8px)',
      },
      '$checkboxLabelContainer:not($inDropdown):not($disabled):hover > &::before': {
        ...checkbox.square.hover,
      },

      // Checkmark
      '&::after': {
        ...checkbox.checkmark.default,
      },
    },
    hiddenLabel: {
      paddingLeft: 0,
    },
    inDropdown: {},
    indeterminate: {},
    disabled: {
      ...checkbox.label.disabled,

      '&, $checkbox:checked + &': {
        '& > $checkboxSquare::before': {
          ...checkbox.square.disabled,
        },
        // Style Disabled Checkbox checkmark when input is checked (probably shouldn't happen but just in case)
        '& > $checkboxSquare::after': {
          ...checkbox.checkmark.disabled,
        },
      },
    },
    highlightedInDropdown: {
      color: theme.inverse01,
    },
  }
}
