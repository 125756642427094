import React from 'react'
import injectSheet from 'react-jss'

import { FormFieldItem, TextInput } from 'components'

import getContentId from '../../getContentId'
import styles from '../PromotedPlacementsForm.styles'

const PlacementContentId = ({ classes, componentId, labelText, values }) => {
  if (!values.id) {
    return null
  }

  return (
    <FormFieldItem
      className={classes.formRow}
      id={componentId}
      labelText={labelText}
      name="contentId"
      render={() => (
        <div className={classes.contentId}>{getContentId(values.placement.key, values.id)}</div>
      )}
    />
  )
}

export default injectSheet(styles)(PlacementContentId)
