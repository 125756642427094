import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import injectSheet from 'react-jss'

import styles from './ListBoxMenuItem.styles'

/**
 * `ListBoxMenuItem` is a helper component for managing the container class
 * name, alongside any classes for any corresponding states, for a generic list
 * box menu item.
 */
const ListBoxMenuItem = ({
  children,
  classes,
  className,
  hideOverflow,
  isActive,
  isGroupChild,
  isGroupHeading,
  isHighlighted,
  isSelected,
  isDisabled,
  disabledText,
  isNoItemsMsg,
  theme,
  ...restProps
}) => {
  const listBoxMenuItemClasses = cx(className, classes.listBoxMenuItem, {
    [classes.listBoxMenuItemHideOverflow]: hideOverflow,
    [classes.listBoxMenuItemActive]: isActive,
    [classes.listBoxMenuItemHighlighted]: isHighlighted,
    [classes.listBoxMenuItemSelected]: isSelected || isDisabled,
    [classes.listBoxMenuItemGroupHeading]: isGroupHeading,
    [classes.listBoxMenuItemGroupChild]: isGroupChild,
    [classes.listBoxMenuItemNoItemsMsg]: isNoItemsMsg,
  })

  return (
    <div className={listBoxMenuItemClasses} {...restProps}>
      <span className={classes.itemContents}>{children}</span>
      {isSelected && <span className={classes.selectedText}>Selected</span>}
      {isDisabled && <span className={classes.selectedText}>{disabledText || ''}</span>}
    </div>
  )
}

ListBoxMenuItem.propTypes = {
  /**
   * Specify any children nodes that should be rendered inside of the ListBox
   * Menu Item
   */
  children: PropTypes.node,
  isActive: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  disabledText: PropTypes.string,
  isNoItemsMsg: PropTypes.bool,
}

ListBoxMenuItem.displayName = 'ListBoxMenuItem'

export default injectSheet(styles)(ListBoxMenuItem)
