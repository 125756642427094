export default {
  baseFontSize: 16,
  baseLineHeight: 1.4,
  bgGreyExtraLight: '#F7F9FA', // "Grey-Extra Light"
  bgSelected: '#EDF0F2', // "Selected Row"
  bgModal: 'rgba(28,47,62,0.9)',
  bgHeaderBanner: '#F2F8FF',
  borderGreyLight: '#DBDBDB', // "Grey-Light"
  borderExtraGreyLight: '#EEF0F2', // "Extra-Grey Light"
  borderGreyDark: '#757575', // "Grey-Regular"
  brand00: '#007FC0', // "Blue-Primary"
  brand00op50: '#8AC7E3',
  brand01: '#3498DB', // "Blue-Light"
  brand01op50: '#A3D1EF',
  brand02: '#1F5A8C', // "Blue-Dark"
  brand02op50: '#9AB5CC',
  brand03: '#A3D1E3', // "Blue-Extra Light"
  buttonBorderRadius: 5,
  buttonBorderWidth: 1,
  buttonFontSize: 13,
  buttonFontWeight: 400,
  buttonHeight: 32,
  buttonIconActionSize: 24,
  containerWidth: 1136,
  contentWidth: 528,
  dropdownMenuBoxShadow: '0 2px 4px 0 rgba(0,0,0,0.3)', // used for things
  fontFamilySansSerif: 'Open Sans',
  inputBorder: '1px solid #DBDBDB', // "Grey-Light"
  inputBorderRadius: 2,
  inputLabelWeight: 700,
  inputPlaceholderWeight: 400,
  inputValueWeight: 600,
  inverse01: '#FFFFFF',
  loginPageBackground: '#4A4A4A',
  modalBorderRadius: 5,
  support01: '#D0021B', // error
  support01Hover: '#E74847',
  support01InputBackground: '#FEFCE8',
  support02: '#66A12E', // success
  support02Hover: '#77BC35',
  support03: '#F5A623', // warning
  text01: '#606E76', // "Grey-Text"
  text02: '#757575', // "Grey-Regular"
  text03: '#DBDBDB', // "Grey-Light"

  fontSize: {
    h1: '28px',
    h2: '22px',
    h3: '18px',
    body: '14px',
    p: '13px',
    small: '12px',
    label: '11px',
  },
  spacing: {
    xxs: '4px',
    xs: '8px',
    sm: '12px',
    md: '16px',
    lg: '24px',
    xlg: '32px',
    xxlg: '40px',
  },
  zIndex: {
    modal: 9000,
    overlay: 8000,
    dropdown: 7000,
    hidden: -1,
  },
}
