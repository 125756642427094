import { useRef, useEffect, useState } from 'react'

/**
 * Hook to keep track of a value from the previous render.
 *
 * @param {*} value - The value to track
 * @returns {*} The previous value
 */
export const usePrevious = value => {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  })

  return ref.current
}

/**
 * Hook to handle loading states for 2+ items.
 *
 * @param {array} initialState - Array of IDs that are already loading
 * @returns {Object} hook
 * @returns {array} hook.loadingIds - Array of items currently loading
 * @returns {func} hook.setItemLoading - Method to set an item as loading
 * @returns {func} hook.setItemResolved - Method to set an item as resolved
 * @returns {func} hook.isItemLoading - Method to check whether and item is loading
 */
export const useLoadingState = (initialState = []) => {
  const [loadingIds, setLoadingIds] = useState(initialState)

  const setItemLoading = id => setLoadingIds(ids => [...ids, id])
  const setItemResolved = id => setLoadingIds(ids => ids.filter(itemId => itemId !== id))
  const isItemLoading = id => loadingIds.some(itemId => itemId === id)

  return {
    loadingIds,
    setItemLoading,
    setItemResolved,
    isItemLoading,
  }
}

/**
 * Hook to store and apply data from updated responses, useful for merging new data on old data for UI display
 * purposes when it doesn't make sense to refect the entire query.
 *
 * @param {array} initialState - Array of IDs that are already loading
 * @returns {Object} hook
 * @returns {Object} hook.dataCache - Object with cache data, keyed by ID
 * @returns {Object} hook.setCacheItem - Set a cache item (using ID as key)
 * @returns {Object} hook.getItemsWithCache - Apply the update cache on top of existing items and merge any new items
 */
export const useUpdatedDataCache = (initialState = {}) => {
  const [dataCache, setDataCache] = useState(initialState)

  const setCacheItem = data =>
    setDataCache(cache => ({
      ...cache,
      [data.id]: data,
    }))

  const getItemsWithCache = items =>
    items.map(item => {
      const cacheItem = dataCache[item.id]

      if (cacheItem)
        return {
          ...item,
          ...cacheItem,
        }

      return item
    })

  return {
    dataCache,
    setCacheItem,
    getItemsWithCache,
  }
}
