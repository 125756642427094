import { mixins } from 'styling'

export default theme => ({
  sectionHeader: {
    color: '#606E76',
    fontSize: theme.fontSize.h2,
    lineHeight: 1.4,
    margin: `${theme.spacing.lg} 0 10px 0px`,
  },
  sectionHeaderTop: {
    marginTop: 0,
  },
  flexContainer: {
    display: 'flex',
  },
  rowFlexContainer: {
    display: 'flex',
    width: 684,
  },
  createUserButton: {
    marginLeft: theme.spacing.lg,
  },
  productInput: {
    marginBottom: theme.spacing.md,
    width: 684,
  },
  searchInput: {
    display: 'inline-block',
    marginBottom: theme.spacing.md,
    width: 684,
  },
  smallInput: {
    width: 212,
  },
  footerContainer: {
    alignItems: 'center',
    paddingBottom: theme.spacing.sm,
    paddingTop: theme.spacing.sm,
  },
  footerErrors: {
    alignSelf: 'center',
    flex: 1,
    marginLeft: theme.spacing.xxlg,
  },
  formBody: {
    minWidth: 668,
  },
  formFooter: {
    background: '#FFFFFF',
    borderTop: `2px solid ${theme.bgGreyExtraLight}`,
    bottom: 0,
    width: '100%',
    position: 'fixed',
  },
  customerContainer: {
    paddingBottom: theme.spacing.md,
  },
  selectedCustomerContainer: {
    backgroundColor: theme.bgGreyExtraLight,
    color: theme.text01,
    fontSize: theme.fontSize.body,
    lineHeight: 1.5,
    padding: theme.spacing.xs,
  },
  contactCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:not(:last-of-type)': {
      paddingLeft: theme.spacing.xs,
    },
  },
  contactCellPhone: {
    flex: '0 1 120px',
  },
  contactCellEmail: {
    flex: '0 1 40%',
  },
  contactCellName: {
    flex: '1 1 0',
  },
  fieldContainer: {
    flex: 1,
    paddingBottom: theme.spacing.sm,
    '&:not(:first-child)': {
      paddingLeft: theme.spacing.xxs,
    },
    '&:not(:last-child)': {
      paddingRight: theme.spacing.xxs,
    },
  },
  itemContainer: {
    padding: `${theme.spacing.sm} 0`,
  },
  fieldAction: {
    alignSelf: 'center',
  },
  formContainer: {
    paddingRight: 210,
    marginBottom: theme.spacing.lg,
  },
  priceTotalContainer: {
    backgroundColor: '#FFFFFF', // in case of overlapping elements, TODO: Handle < 1280
    border: `1px solid ${theme.borderGreyLight}`,
    position: 'fixed',
    right: 52, // margin of the page container (not currently a global variable)
    bottom: 76, // combination of the fixed footer + padding (not currently a global variable)
    padding: theme.spacing.md,
    width: 180,
  },
  priceTotalRow: {
    color: theme.text02,
    display: 'flex',
    fontSize: theme.fontSize.p,
    marginBottom: theme.spacing.sm,

    '&:last-child': {
      marginBottom: 0,
    },
  },
  priceTotalColumn: {
    flex: 1,
  },
  priceTotalColumnLabel: {
    fontWeight: 'bold',
  },
  modalContainer: {
    textAlign: 'left',
  },
  modalHeading: {
    fontSize: theme.fontSize.h2,
    color: theme.text01,
    fontWeight: 400,
    marginBottom: theme.spacing.md,
  },
  modalFormTitles: {
    ...mixins.labelText({ theme }),
    marginBottom: theme.spacing.xs,
  },
  configurableProductSections: {
    marginBottom: theme.spacing.lg,
    color: '#606E76',
  },
  newCustomerContainer: {
    backgroundColor: theme.bgGreyExtraLight,
    padding: `${theme.spacing.md} ${theme.spacing.md} ${theme.spacing.xs} ${theme.spacing.md}`,
    display: 'inline-flex',
    flexDirection: 'column',
  },
  customerContainerSplitColumn: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing.xs,
  },
})
