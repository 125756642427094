import { Formik } from 'formik'
import React from 'react'
import injectSheet from 'react-jss'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as yup from 'yup'

import { Button, FormFieldItem, MultiInput, Notification, PageHeader, TextInput } from 'components'
import { createToast } from 'modules/toasts'
import { fetchCurrentUser, updateUser } from 'modules/user'
import { getBackButton, getRouteTo } from 'routing'
import Styling from 'styling/components'

const styles = theme => ({
  formContainer: {
    width: 368,
  },
})

const MyProfileEdit = ({ classes, createToast, fetchCurrentUser, history, updateUser, user }) => {
  const { email, firstName, lastName } = user

  return (
    <div>
      <PageHeader hasDivider headerTitle="Update Profile" backButton={getBackButton('myProfile')} />
      <div className={classes.formContainer}>
        <Formik
          initialValues={{
            firstName,
            lastName,
            email,
          }}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={() =>
            yup.object().shape({
              firstName: yup
                .string()
                .label('First Name')
                .required(),
              lastName: yup
                .string()
                .label('Last Name')
                .required(),
              email: yup
                .string()
                .email()
                .label('Email')
                .required(),
            })
          }
          onSubmit={(values, form) => {
            const userId = user.id
            const payload = { ...user, ...values }

            return updateUser(userId, payload)
              .then(fetchCurrentUser)
              .then(() => {
                form.setSubmitting(false)
                createToast({ kind: 'success', message: 'User update succeeded.' })
                history.push(getRouteTo('myProfile'))
              })
              .catch(error => {
                form.setSubmitting(false)
                form.setErrors({ global: error.message })
              })
          }}
          render={({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <MultiInput
                errorId="my-profile-name-error-msg"
                invalidText={errors.firstName || errors.lastName}
                labelText="Name"
              >
                <TextInput
                  autoFocus
                  id="my-profile-first-name"
                  isInvalid={!!errors.firstName}
                  name="firstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="First Name"
                  type="text"
                  value={values.firstName}
                />
                <TextInput
                  id="my-profile-last-name"
                  isInvalid={!!errors.lastName}
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Last Name"
                  type="text"
                  value={values.lastName}
                />
              </MultiInput>
              <FormFieldItem
                id="my-profile-email"
                labelText="Email"
                name="email"
                render={renderProps => (
                  <TextInput {...renderProps} placeholder="Email" type="email" />
                )}
              />
              {!isSubmitting && errors.global && (
                <Notification kind="error" message={errors.global} />
              )}
              <Styling.LineOfButtons>
                <Button type="submit" disabled={isSubmitting}>
                  Save Changes
                </Button>
                <Button kind="link" href={getRouteTo('myProfile')}>
                  Cancel
                </Button>
              </Styling.LineOfButtons>
            </form>
          )}
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  user: state.user.data,
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createToast,
      fetchCurrentUser,
      updateUser,
    },
    dispatch
  )

export default injectSheet(styles)(connect(mapStateToProps, mapDispatchToProps)(MyProfileEdit))
