import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import injectSheet from 'react-jss'

import { FormLabel, FormRequirement } from 'components'

import styles from './FormItem.styles'

const FormItem = ({ children, classes, className, errorMsg, fieldId, labelText, orientation }) => {
  const classNames = cx(className, classes.formItem, {
    [classes.horizontal]: orientation === 'horizontal',
    [classes.vertical]: orientation === 'vertical',
  })

  return (
    <div className={classNames}>
      {labelText && <FormLabel htmlFor={fieldId} labelText={labelText} />}
      {children}
      {errorMsg && <FormRequirement id={`${fieldId}-error-msg`} invalidText={errorMsg} />}
    </div>
  )
}

FormItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  /**
   * If provided, a <FormRequirement> with id equal to `${fieldId}-error-msg`
   * displaying the error message will be shown.
   */
  errorMsg: PropTypes.string,
  /**
   * The `id` of the corresponding field component (for the error message and label to associate with).
   */
  fieldId: PropTypes.string,
  /**
   * If provided, a <FormLabel> will be shown displaying the label text.
   */
  labelText: PropTypes.string,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']).isRequired,
}

FormItem.defaultProps = {
  orientation: 'vertical',
}

export default injectSheet(styles)(FormItem)
