const FIRST_COLUMN_BORDER = [true, true, false, true]
const COLUMN_BORDER = [false, true, false, true]
const LAST_COLUMN_BORDER = [false, true, true, true]

export default function cateringOrderPrintContent(
  documentHeaders = [],
  orderTableHeaders,
  orderTableRows,
  logoImageBase64,
  pageHeaderPrefix
) {
  const printTableHeaders = orderTableHeaders.filter(header => header.print)
  const tableHeaders = printTableHeaders.map(obj => ({ text: obj.header, bold: true }))
  const columnWidths = printTableHeaders.map(header => {
    // Set a fixed width to improve text wrapping of long words.
    if (header.key === 'notes') return 200
    return 'auto'
  })
  const tableRows = orderTableRows.map(row => {
    const formattedRow = []

    printTableHeaders.forEach((header, index) => {
      const column = {
        text: row[header.key],
      }

      if (index === 0) {
        column.border = [...FIRST_COLUMN_BORDER]
      } else if (index === tableHeaders.length - 1) {
        column.border = [...LAST_COLUMN_BORDER]
      } else {
        column.border = [...COLUMN_BORDER]
      }

      formattedRow.push(column)
    })

    return formattedRow
  })

  return {
    pageOrientation: 'landscape',
    pageMargins: [50, 50, 50, 50],
    header(currentPage, pageCount, pageSize) {
      return [
        {
          text: [
            {
              text: pageHeaderPrefix ? `${pageHeaderPrefix} — ` : '',
            },
            {
              text: 'PAGE ',
              bold: true,
            },
            {
              text: `${currentPage}/${pageCount}`,
            },
          ],
          alignment: 'right',
          margin: [0, 30, 50, 0],
        },
      ]
    },
    content: [
      {
        columns: [
          {
            width: 250,
            columns: [
              ...(logoImageBase64
                ? [
                    {
                      image: logoImageBase64,
                      fit: [80, 80],
                      width: 'auto',
                      margin: [0, 0, 16, 10],
                    },
                  ]
                : []),
              {
                margin: [0, 0, 16, 0],
                table: {
                  widths: ['auto', '*'],
                  body: documentHeaders.map(header => [
                    { text: header.title.toUpperCase(), bold: true, noWrap: true },
                    header.content,
                  ]),
                },
                layout: 'noBorders',
              },
            ],
          },
          {
            layout: 'cateringOrdersLayout',
            table: {
              headerRows: 1,
              widths: columnWidths,
              body: [tableHeaders, ...tableRows],
            },
          },
        ],
      },
    ],
  }
}
