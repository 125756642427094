import React from 'react'

import { fetchKeywordRedirect } from 'api'
import { LoadingIndicator, Notification, ReadOnlyValueDetails } from 'components'
import Styling from 'styling/components'
import { ItemFetcher } from 'utils'

const getKeywordRedirectDetails = keywordRedirect => {
  const fields = [
    { key: 'keywords', label: 'Keywords' },
    { key: 'url', label: 'Destination' },
    { key: 'isExternalUrl', label: ' ' },
  ]

  const items = fields.map(({ key, label: labelText }, index) => {
    let value

    switch (key) {
      case 'keywords':
        value = keywordRedirect[key].join(', ')
        break
      case 'url':
        value = keywordRedirect[key] && <a href={keywordRedirect[key]}>{keywordRedirect[key]}</a>
        break
      case 'isExternalUrl':
        value = keywordRedirect[key] && 'Show as external'
        break
      default:
        value = keywordRedirect[key]
    }

    return {
      id: `keyword-redirect-${keywordRedirect.id}-detail-${index}`,
      labelText,
      value,
      useCustomValueEl: typeof value !== 'string',
    }
  })

  return items.filter(item => !!item.value)
}

const KeywordRedirectDetails = ({ keywordRedirectId }) => (
  <ItemFetcher
    queryKey="keywordRedirect"
    queryParams={{ id: keywordRedirectId }}
    fetchItem={config => fetchKeywordRedirect(keywordRedirectId, config)}
    render={({
      item: keywordRedirect,
      isLoadingItem: isFetchingKeywordRedirect,
      error: errorFetchingKeywordRedirect,
    }) => {
      if (isFetchingKeywordRedirect) {
        return <LoadingIndicator.Dots centerBlock withText />
      }
      if (errorFetchingKeywordRedirect) {
        return (
          <Styling.Center maxWidth={500}>
            <Notification kind="error" message={errorFetchingKeywordRedirect.message} />
          </Styling.Center>
        )
      }
      if (keywordRedirect) {
        return (
          <div>
            <ReadOnlyValueDetails
              detailValues={getKeywordRedirectDetails(keywordRedirect)}
              wideLabel
            />
          </div>
        )
      }
    }}
  />
)

export default KeywordRedirectDetails
