import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import cx from 'classnames'

import { Icon } from 'components'

const styles = theme => ({
  disabledFeatureHeader: {
    fontWeight: 700,
    fontSize: theme.fontSize.body,
    color: theme.text01,
  },
  disabledFeatureIcon: {
    fill: theme.support03,
    verticalAlign: 'middle',
  },
})

const DisabledFeatureWarning = ({ classes, className, enablingLink }) => (
  <span className={cx(classes.disabledFeatureHeader, className)}>
    <Icon className={classes.disabledFeatureIcon} name="warning" /> This feature is currently
    disabled. {enablingLink && enablingLink}
  </span>
)

DisabledFeatureWarning.propTypes = {
  enablingLink: PropTypes.node,
}

export default injectSheet(styles)(DisabledFeatureWarning)
