import PropTypes from 'prop-types'
import React from 'react'
import injectSheet from 'react-jss'

import { TableView } from 'layouts'
import { formatDatetime } from 'utils'
import { fetchFactHistory } from 'api'

import RawLogModal from './RawLogModal'

import FactDetailsModal from '../FactDetails/FactDetailsModal'
import styles from '../Fact.styles'

const tableHeaders = [
  { header: 'Time', key: 'created' },
  { header: 'Old Value', key: 'old', disableSorting: true },
  { header: 'New Value', key: 'new', disableSorting: true },
  { header: 'Raw', key: 'raw', disableSorting: true },
]

const FactHistory = props => {
  const { classes, factKey } = props
  const formatTableRows = ({ items }) => {
    return items.map(item => {
      const created = formatDatetime(item.created)
      return {
        id: item.id.toString(),
        created,
        old: (
          <FactDetailsModal
            title={`Old Value (${created})`}
            schema={item.oldSchema}
            value={item.oldValue}
          />
        ),
        new: (
          <FactDetailsModal
            title={`New Value (${created})`}
            schema={item.newSchema}
            value={item.newValue}
          />
        ),
        raw: <RawLogModal classes={classes} title={`Raw (${created})`} {...item} />,
      }
    })
  }
  return (
    <TableView
      {...props}
      queryKey="factHistory"
      fetchFunction={queryString => fetchFactHistory(factKey, queryString)}
      pageId={factKey}
      tableHeaders={tableHeaders}
      tableRows={formatTableRows}
      hideHeader
    />
  )
}

FactHistory.propTypes = {
  classes: PropTypes.object,
  factKey: PropTypes.string,
}

export default injectSheet(styles)(FactHistory)
