import memoize from 'memoize-one'
import React, { Fragment } from 'react'
import injectSheet from 'react-jss'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { fetchStoreGroup, updateStoreGroup } from 'api'
import {
  Button,
  DataTableWithState,
  LoadingIndicator,
  Modal,
  Notification,
  PageHeader,
  ReadOnlyValueCard,
  ShowIfAuthorized,
} from 'components'
import { createToast } from 'modules/toasts'
import { getBackButton, getRoutePathname, getRouteTo } from 'routing'
import Styling from 'styling/components'
import { formatDate, get, ItemFetcher } from 'utils'

const styles = theme => ({
  headerLeftContainer: {
    alignItems: 'flex-start',
    display: 'flex',
  },
  underHeaderContainer: {
    display: 'flex',
  },
  leftSide: {
    flexShrink: 0,
    marginRight: theme.spacing.xxlg,
    width: 272,
  },
  rightSide: {
    flexGrow: 1,
    flexShrink: 1,
  },
})

const storeTableHeaders = [
  {
    key: 'storeId',
    header: 'Store ID',
  },
  {
    key: 'name',
    header: 'Store Name',
  },
]

const formatStoreTableRows = memoize(stores =>
  stores.map(({ id, extId, name }) => ({
    id: `store-${id}`,
    storeId: `Store #${extId}`,
    name,
  }))
)

const StoreGroup = ({
  classes,
  createToast,
  location,
  match: {
    params: { storeGroupId },
  },
}) => (
  <ItemFetcher
    queryKey="storeGroup"
    queryParams={{ id: storeGroupId }}
    fetchItem={config => fetchStoreGroup(storeGroupId, config)}
    render={({
      item: storeGroup,
      isLoadingItem: isFetchingStoreGroup,
      error: errorFetchingStoreGroup,
      refetch,
    }) => {
      if (isFetchingStoreGroup) {
        return <LoadingIndicator withTopMargin />
      }
      if (errorFetchingStoreGroup) {
        return (
          <Styling.Center maxWidth={500} withTopMargin>
            <Notification kind="error" message={errorFetchingStoreGroup.message} />
          </Styling.Center>
        )
      }
      if (storeGroup) {
        return (
          <div>
            <PageHeader
              backButton={get(location, 'state.backButton') || getBackButton('storeGroups')}
              headerTitle={storeGroup.name}
              statusIsActive={storeGroup.status}
            >
              <Styling.LineOfItems alignCenter>
                <Fragment>
                  {storeGroup.status && ( // Only allow unarchived store groups to be edited
                    <ShowIfAuthorized requiredPermission="store_groups.edit">
                      <Button
                        href={getRouteTo('storeGroups.editStoreGroup', { id: storeGroup.id })}
                      >
                        Edit
                      </Button>
                    </ShowIfAuthorized>
                  )}

                  <ShowIfAuthorized requiredPermission="store_groups.create">
                    <Button
                      href={{
                        pathname: getRoutePathname('storeGroups.createStoreGroup'),
                        state: { duplicateId: storeGroup.id },
                      }}
                      kind="secondary"
                    >
                      Duplicate
                    </Button>
                  </ShowIfAuthorized>

                  <ShowIfAuthorized requiredPermission="store_groups.edit">
                    <Modal.Confirmation
                      handleCloseModal={({ wasConfirmed }) => {
                        if (wasConfirmed) {
                          const newStoreGroup = { ...storeGroup, status: !storeGroup.status }
                          updateStoreGroup(storeGroup.id, newStoreGroup)
                            .then(() => {
                              createToast({
                                kind: 'success',
                                message: `Store group successfully ${
                                  newStoreGroup.status ? 'restored' : 'archived'
                                }.`,
                              })
                              refetch()
                            })
                            .catch(({ message }) => {
                              createToast({ kind: 'error', message })
                            })
                        }
                      }}
                      triggerRender={({ openModal }) => (
                        <Button kind={storeGroup.status ? 'danger' : 'primary'} onClick={openModal}>
                          {storeGroup.status ? 'Archive' : 'Restore'}
                        </Button>
                      )}
                      contentProps={{
                        actionText: `${storeGroup.status ? 'archive' : 'restore'} this store group`,
                        confirmButtonText: storeGroup.status ? 'Archive' : 'Restore',
                      }}
                    />
                  </ShowIfAuthorized>
                </Fragment>
              </Styling.LineOfItems>
            </PageHeader>
            <div className={classes.underHeaderContainer}>
              <div className={classes.leftSide}>
                <ReadOnlyValueCard
                  detailValues={[
                    { id: 'store-group-name', labelText: 'Name', value: storeGroup.name },
                    {
                      id: 'store-group-created',
                      labelText: 'Created',
                      value: formatDate(storeGroup.created),
                    },
                    {
                      id: 'store-group-num-stores',
                      labelText: '# of Stores',
                      value: storeGroup.count,
                    },
                  ]}
                />
              </div>
              <div className={classes.rightSide}>
                <DataTableWithState
                  headers={storeTableHeaders}
                  id="store-group-stores-table"
                  kind="light"
                  rows={formatStoreTableRows(storeGroup.stores || [])}
                />
              </div>
            </div>
          </div>
        )
      }
    }}
  />
)

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createToast,
    },
    dispatch
  )

export default injectSheet(styles)(connect(null, mapDispatchToProps)(StoreGroup))
