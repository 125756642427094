import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import injectSheet from 'react-jss'
import { Link } from 'react-router-dom'

import { Icon, Tooltip } from 'components'

import styles from './Button.styles'

const Button = ({
  block,
  children,
  classes,
  className,
  disabled,
  href,
  icon,
  iconDescription,
  iconPosition,
  id,
  kind,
  linkIconShowOnHover,
  linkIsDownload,
  linkIsExternal,
  onClick,
  size,
  tabIndex,
  toolTipText,
  toolTipWrap,
  type,
  ...rest
}) => {
  const isLinkButton = kind === 'link' || kind === 'link-danger'

  const buttonClasses = cx(className, classes.button, {
    [classes.buttonXs]: size === 'x-small',
    [classes.buttonLg]: size === 'large',
    [classes.buttonPrimary]: kind === 'primary',
    [classes.buttonSecondary]: kind === 'secondary',
    [classes.buttonDanger]: kind === 'danger',
    [classes.buttonConfirmation]: kind === 'confirmation',
    [classes.buttonConfirmationSecondary]: kind === 'confirmation-secondary',
    [classes.buttonLink]: isLinkButton,
    [classes.buttonLinkPrimary]: kind === 'link',
    [classes.buttonLinkDanger]: kind === 'link-danger',
    [classes.block]: block,
  })

  const commonProps = {
    tabIndex,
    className: buttonClasses,
    id,
  }

  const hasChildren = !!children

  const buttonIcon = icon ? (
    <Icon
      name={icon}
      description={iconDescription}
      className={cx(classes.buttonIcon, {
        [classes.buttonLinkIconShowOnHover]: isLinkButton && linkIconShowOnHover,
        [classes.buttonIconBefore]: hasChildren && iconPosition === 'before',
        [classes.buttonIconAfter]: hasChildren && iconPosition === 'after',
        [classes.buttonIconInline]: hasChildren && iconPosition === 'inline',
      })}
    />
  ) : null

  let buttonContent = (
    <Fragment>
      {buttonIcon && iconPosition === 'before' && buttonIcon}
      {hasChildren && (
        <span
          className={cx({
            [classes.buttonIconSibling]: !!buttonIcon,
            [classes.buttonIconHasInlineIcon]: iconPosition === 'inline',
          })}
        >
          <span>{children}</span>
          {buttonIcon && iconPosition === 'inline' && buttonIcon}
        </span>
      )}
      {buttonIcon && iconPosition === 'after' && buttonIcon}
    </Fragment>
  )

  if (toolTipText) {
    buttonContent = (
      <Tooltip text={toolTipText} wrap={toolTipWrap}>
        {buttonContent}
      </Tooltip>
    )
  }

  if (href) {
    // When links are inside tables, we need to ensure the hover/click actions do not affect the containing elements
    const mouseAndClickHanlders = {
      onClick: event => {
        event.stopPropagation()
      },
      onMouseOver: event => {
        event.stopPropagation()
      },
    }

    if (linkIsExternal || linkIsDownload) {
      const linkProps = {
        ...commonProps,
        ...mouseAndClickHanlders,
        href,
      }
      if (linkIsExternal) {
        linkProps.target = '_blank'
      } else if (linkIsDownload) {
        linkProps.download = true
      }
      return <a {...linkProps}>{buttonContent}</a>
    }
    return (
      <Link {...commonProps} {...mouseAndClickHanlders} to={href}>
        {buttonContent}
      </Link>
    )
  }
  return (
    <button {...commonProps} {...rest} disabled={disabled} onClick={onClick} type={type}>
      {buttonContent}
    </button>
  )
}

Button.propTypes = {
  block: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.string,
  iconDescription: PropTypes.string,
  iconPosition: PropTypes.oneOf(['before', 'after', 'inline']),
  kind: PropTypes.oneOf([
    'primary',
    'secondary',
    'danger',
    'confirmation',
    'confirmation-secondary',
    'link',
    'link-danger',
  ]).isRequired,
  linkIconShowOnHover: PropTypes.bool,
  linkIsDownload: PropTypes.bool,
  linkIsExternal: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['x-small', 'regular', 'large']).isRequired,
  tabIndex: PropTypes.number,
  toolTipText: PropTypes.string,
  toolTipWrap: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
}

Button.defaultProps = {
  block: false,
  disabled: false,
  iconPosition: 'after',
  kind: 'primary',
  linkIconShowOnHover: false,
  linkIsDownload: false,
  linkIsExternal: false,
  size: 'regular',
  tabIndex: 0,
  toolTipWrap: false,
  type: 'button',
}
export { Button as ButtonUnStyled }
export default injectSheet(styles)(Button)
