import debounce from 'lodash/debounce'
import React, { Component, Fragment } from 'react'
import injectSheet from 'react-jss'

import { fetchCollections, fetchProducts, uploadImage, uploadVideo } from 'api'
import { HERO_BANNERS, HOMEPAGE_BANNERS } from 'constants/placementTypes'
import {
  DropdownFilterable,
  FormFieldItem,
  ImageUploadWithAltText,
  RadioButtons,
  TextInput,
} from 'components'
import { get } from 'utils'

import {
  defaultPromotedMediaDimensions as promotedMediaSpans,
  useCellWidthDesktopMobileUpload,
} from './utils/placementImageUploader'

import styles from '../PromotedPlacementsForm.styles'

const findAndSetSelectedObject = ({
  filteredArray,
  formValue,
  originalArray,
  selectedItem,
  setFieldValue,
}) => {
  let selectedObject = originalArray.find(item => item.id === selectedItem)

  // If value doesn't exist in initial list, check the filtered items
  if (!selectedObject && filteredArray) {
    selectedObject = filteredArray.find(item => item.id === selectedItem)
  }
  setFieldValue(formValue, selectedObject)
}

const imageUploadComponentBuilder = ({ ...parentComponentProps }) => {
  const {
    componentId,
    height,
    index,
    labelText,
    platformType,
    setFieldError,
    setFieldValue,
    values: {
      placement: { key: placementKey },
      promotedMedia,
    },
    width,
  } = parentComponentProps

  // Determines if image should be set to `promotedMedia` or `media` objects
  // depending if the placement is Catalog/Search Placement or Collection/MLP Banner
  let fieldName = ''
  if (useCellWidthDesktopMobileUpload.includes(placementKey)) {
    fieldName = `promotedMedia.${platformType}`
  } else {
    fieldName = 'media.image'
  }

  const shouldValidateImageSize = !['featured_department', HOMEPAGE_BANNERS].includes(placementKey)
  let fileRequirementsText = `Dimensions - Width: ${width} x Height: ${height}`

  if (!shouldValidateImageSize) fileRequirementsText = `Recommended ${fileRequirementsText}`

  return (
    <FormFieldItem
      className={parentComponentProps.classes.formInput}
      id={`${componentId}-image-upload`}
      key={index}
      labelText={labelText}
      name={fieldName}
      render={({ id, name, value, onChange: altTextOnChange }) => (
        <ImageUploadWithAltText
          name={fieldName}
          fieldId={componentId}
          altText=""
          fileRequirementsText={fileRequirementsText}
          validateSize={shouldValidateImageSize}
          height={parseInt(height, 10)}
          id={id}
          initialImage={value}
          image={value}
          onImageUpload={(file, ...args) =>
            file.type.startsWith('video/') ? uploadVideo(file) : uploadImage(file, ...args)
          }
          onImageUploadComplete={image => {
            const newImageValue = image || {}
            newImageValue.span = get(promotedMedia, `${platformType}.span`)
            setFieldValue(name, newImageValue)
          }}
          onUploadError={error => {
            setFieldError(name, error)
          }}
          width={parseInt(width, 10)}
          altTextOnChange={altTextOnChange}
          allowVideo={placementKey === HERO_BANNERS}
        />
      )}
    />
  )
}

class PlacementDisplayContent extends Component {
  state = {
    collections: [],
    products: [],
    isFetchingCollections: false,
    isFetchingProducts: false,
    filteredCollections: null,
    filteredProducts: null,
  }

  componentDidMount() {
    this.loadCollectionsDropdown()
    this.loadProductsDropdown()
  }

  formatDropdown(dropdownItems) {
    const formattedDropdownItems = dropdownItems.map(({ id, name, ...restProps }) => ({
      id,
      label: name,
      name,
      value: id,
      ...restProps,
    }))

    return formattedDropdownItems
  }

  getDebouncedCollections = debounce(inputValue => {
    this.setState({ isFetchingCollectionsAsync: true })

    fetchCollections(`?status=true&search=${inputValue}`)
      .then(({ items }) => {
        this.setState({ filteredCollections: items })
      })
      .finally(() => {
        this.setState({ isFetchingCollectionsAsync: false })
      })
  }, 250)

  getDebouncedProducts = debounce(inputValue => {
    this.setState({ isFetchingProductsAsync: true })

    fetchProducts(`?status=true&search=${inputValue}`)
      .then(({ items }) => {
        this.setState({ filteredProducts: items })
      })
      .finally(() => {
        this.setState({ isFetchingProductsAsync: false })
      })
  }, 250)

  loadCollectionsDropdown() {
    const {
      values: {
        media: { collection },
      },
    } = this.props
    const existingCollectionQuery = collection && collection.name ? collection.name : ''

    this.setState({ isFetchingCollections: true })

    fetchCollections(`?status=true&search=${existingCollectionQuery}`)
      .then(({ items }) => {
        this.setState({
          collections: this.formatDropdown(items),
        })
      })
      .finally(() => {
        this.setState({ isFetchingCollections: false })
      })
  }

  loadProductsDropdown() {
    const {
      values: {
        media: { product },
      },
    } = this.props
    const existingProductQuery = product && product.name ? product.name : ''

    this.setState({ isFetchingProducts: true })

    fetchProducts(`?status=true&search=${existingProductQuery}`)
      .then(({ items }) => {
        this.setState({
          products: this.formatDropdown(items),
        })
      })
      .finally(() => {
        this.setState({ isFetchingProducts: false })
      })
  }

  render() {
    const {
      classes,
      componentId,
      customUploadDimensions,
      radioButtonProps,
      setFieldValue,
    } = this.props
    const {
      collections,
      filteredCollections,
      filteredProducts,
      products,
      isFetchingCollections,
      isFetchingCollectionsAsync,
      isFetchingProducts,
      isFetchingProductsAsync,
    } = this.state

    return (
      <FormFieldItem
        className={classes.formRow}
        id={componentId}
        labelText={radioButtonProps.length > 1 ? 'Type' : ''}
        name="media.type"
        render={({ id, name, value }) => (
          <div>
            {radioButtonProps.length > 1 && (
              <RadioButtons
                className={classes.formInput}
                id={id}
                name={name}
                onChange={newType => {
                  setFieldValue(name, newType)
                }}
                orientation="horizontal"
                selectedValue={value}
                type="text"
                radioButtonProps={radioButtonProps}
              />
            )}

            {value === 'collection' && (
              <FormFieldItem
                id={`${componentId}-collection`}
                labelText="Collection"
                name="media.collection"
                render={collectionDropdownFilterProps => (
                  <DropdownFilterable
                    className={classes.formInput}
                    id={`${componentId}-collection`}
                    initialValue={collectionDropdownFilterProps.value}
                    isLoading={isFetchingCollections}
                    isLoadingAsync={isFetchingCollectionsAsync}
                    items={collections}
                    filteredItems={
                      filteredCollections ? this.formatDropdown(filteredCollections) : null
                    }
                    onChange={selectedItem =>
                      findAndSetSelectedObject({
                        filteredArray: filteredCollections,
                        formValue: `media.${value}`,
                        originalArray: collections,
                        selectedItem,
                        setFieldValue,
                      })
                    }
                    onInputChange={value => this.getDebouncedCollections(value)}
                    placeholder="Collections"
                    selectedValue={get(collectionDropdownFilterProps.value, 'id')}
                  />
                )}
              />
            )}

            {value === 'product' && (
              <FormFieldItem
                id={`${componentId}-product`}
                labelText="Product"
                name="media.product"
                render={productDropdownFilterProps => (
                  <DropdownFilterable
                    className={classes.formInput}
                    id={`${componentId}-product`}
                    initialValue={productDropdownFilterProps.value}
                    isLoading={isFetchingProducts}
                    isLoadingAsync={isFetchingProductsAsync}
                    items={products}
                    filteredItems={filteredProducts ? this.formatDropdown(filteredProducts) : null}
                    onChange={selectedItem =>
                      findAndSetSelectedObject({
                        filteredArray: filteredProducts,
                        formValue: `media.${value}`,
                        originalArray: products,
                        selectedItem,
                        setFieldValue,
                      })
                    }
                    onInputChange={value => this.getDebouncedProducts(value)}
                    placeholder="Products"
                    selectedValue={get(productDropdownFilterProps.value, 'id')}
                  />
                )}
              />
            )}

            {value === 'text' && (
              <FormFieldItem
                id={`${componentId}-text`}
                labelText="Text"
                name="media.text"
                render={renderProps => (
                  <TextInput
                    {...renderProps}
                    className={classes.formInput}
                    placeholder="Enter display text"
                    type="text"
                  />
                )}
              />
            )}

            {value === 'none' && !customUploadDimensions && (
              <Fragment>
                <FormFieldItem
                  id={`${componentId}-desktop-image-span`}
                  labelText="Desktop"
                  name="promotedMedia.desktop.span"
                  render={radioButtonRenderProps => {
                    const { id, name, value = 1 } = radioButtonRenderProps

                    return (
                      <Fragment>
                        <RadioButtons
                          className={classes.formInput}
                          id={id}
                          name={name}
                          onChange={newType => {
                            setFieldValue('promotedMedia.desktop.imageUrl', '')
                            setFieldValue(name, newType)
                          }}
                          orientation="horizontal"
                          selectedValue={value}
                          type="text"
                          radioButtonProps={promotedMediaSpans.desktop.radioButtonProps}
                        />

                        {value &&
                          imageUploadComponentBuilder({
                            labelText: 'Desktop',
                            height:
                              promotedMediaSpans.desktop.dimensions[value - 1].fullSize.height,
                            platformType: 'desktop',
                            width: promotedMediaSpans.desktop.dimensions[value - 1].fullSize.width,
                            ...this.props,
                          })}
                      </Fragment>
                    )
                  }}
                />

                <FormFieldItem
                  id={`${componentId}-mobile-image-span`}
                  labelText="Mobile"
                  name="promotedMedia.mobile.span"
                  render={radioButtonRenderProps => {
                    const { id, name, value = 1 } = radioButtonRenderProps

                    return (
                      <Fragment>
                        <RadioButtons
                          className={classes.formInput}
                          id={id}
                          name={name}
                          onChange={newType => {
                            setFieldValue('promotedMedia.mobile.imageUrl', '')
                            setFieldValue(name, newType)
                          }}
                          orientation="horizontal"
                          selectedValue={value}
                          type="text"
                          radioButtonProps={promotedMediaSpans.mobile.radioButtonProps}
                        />

                        {value &&
                          imageUploadComponentBuilder({
                            labelText: 'Mobile',
                            height: promotedMediaSpans.mobile.dimensions[value - 1].fullSize.height,
                            platformType: 'mobile',
                            width: promotedMediaSpans.mobile.dimensions[value - 1].fullSize.width,
                            ...this.props,
                          })}
                      </Fragment>
                    )
                  }}
                />
              </Fragment>
            )}

            {value === 'image' &&
              customUploadDimensions?.generic &&
              imageUploadComponentBuilder({
                index: 0,
                height: customUploadDimensions.generic.height,
                width: customUploadDimensions.generic.width,
                ...this.props,
              })}

            {(value === 'image' || value === 'none') &&
              customUploadDimensions?.desktop &&
              imageUploadComponentBuilder({
                index: 0,
                labelText: 'Desktop',
                height: customUploadDimensions.desktop.height,
                platformType: 'desktop',
                width: customUploadDimensions.desktop.width,
                ...this.props,
              })}

            {(value === 'image' || value === 'none') &&
              customUploadDimensions?.mobile &&
              imageUploadComponentBuilder({
                index: 1,
                labelText: 'Mobile',
                height: customUploadDimensions.mobile.height,
                platformType: 'mobile',
                width: customUploadDimensions.mobile.width,
                ...this.props,
              })}
          </div>
        )}
      />
    )
  }
}

export default injectSheet(styles)(PlacementDisplayContent)
