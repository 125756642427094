export const CATALOG_BANNERS = 'catalog_banners'
export const CATALOG_COLLECTIONS = 'catalog_collections'
export const CATALOG_PLACEMENT = 'catalog_placement'
export const COLLECTION_BANNER = 'collection_banner'
export const EXTERNAL_PAGES = 'external_pages'
export const FEATURED_DEPARTMENTS = 'featured_department'
export const FEATURED_DEPARTMENTS_HEADER = 'featured_department_header'
export const FLYER_FEATURED_TILES = 'flyer_featured_tiles'
export const FLYER_PDF = 'flyer_pdf'
export const FREE_DELIVERY_HEADER_BANNER = 'free_delivery_header_banner'
export const FREE_DELIVERY_MOBILE_HEADER_BANNER = 'free_delivery_mobile_header_banner'
export const HEADER_BANNER = 'header_banner'
export const HERO_BANNERS = 'hero_banners'
export const HOMEPAGE_BANNERS = 'homepage_banners'
export const HOMEPAGE_COLLECTIONS = 'homepage_collections'
export const HOMEPAGE_HERO = 'homepage_hero'
export const MANUAL_UPSELL = 'manual_upsell'
export const MLP_BANNER = 'mlp_banner'
export const MOBILE_CATALOG_BANNER = 'mobile_catalog_banner'
export const MOBILE_HEADER_BANNER = 'mobile_header_banner'
export const MOBILE_HOMEPAGE_HERO = 'mobile_homepage_hero'
export const MOBILE_SEARCH_BANNER = 'mobile_search_banner'
export const ORDER_CONFIRMATION = 'order_confirmation'
export const QUICK_LINKS = 'quick_links'
export const SEARCH_PLACEMENT = 'search_placement'
export const SHOP_COLLECTIONS = 'shop_collections'
export const SLIM_BANNERS = 'slim_banners'
export const SPONSORED_CART_SUGGESTIONS = 'sponsored_cart_suggestions'
export const SPONSORED_PRODUCT_SUGGESTIONS = 'sponsored_product_suggestions'
export const SPONSORED_TOP_DEALS = 'sponsored_top_deals'
export const TILE_CAROUSELS = 'tile_carousels'
export const IMAGE_CAROUSELS = 'image_carousels'
export const RICH_TEXT_CONTAINERS = 'rich_text_containers'
export const MOBILE_ONLY = 'mobile_only'
export const DESKTOP_ONLY = 'desktop_only'
export const DESKTOP_ONLY_PLACEMENTS = [HOMEPAGE_HERO, HEADER_BANNER, FREE_DELIVERY_HEADER_BANNER]
export const MOBILE_ONLY_PLACEMENTS = [
  MOBILE_HOMEPAGE_HERO,
  MOBILE_HEADER_BANNER,
  FREE_DELIVERY_MOBILE_HEADER_BANNER,
]
export const COMPOSABLE_STOREFRONT_PLACEMENTS = [
  HERO_BANNERS,
  RICH_TEXT_CONTAINERS,
  SLIM_BANNERS,
  IMAGE_CAROUSELS,
  QUICK_LINKS,
  TILE_CAROUSELS,
]

export const REQUIRE_DESKTOP_MOBILE_IMAGES = [HERO_BANNERS, MLP_BANNER, SLIM_BANNERS]
