import React from 'react'
import injectSheet from 'react-jss'

import { FormFieldItem, RadioButtons } from 'components'

import styles from '../PromotedPlacementsForm.styles'

const PlacementColor = ({
  classes,
  componentId,
  enableBrandColor = false,
  fieldItemName = 'color',
  labelText,
  setFieldValue,
}) => (
  <FormFieldItem
    className={classes.formRow}
    id={componentId}
    labelText={labelText}
    name={fieldItemName}
    render={({ id, name, value }) => (
      <RadioButtons
        className={classes.formInput}
        id={id}
        name={name}
        onChange={newTheme => {
          setFieldValue(name, newTheme)
        }}
        orientation="horizontal"
        selectedValue={value}
        type="text"
        radioButtonProps={[
          ...(enableBrandColor
            ? [
                {
                  id: `${fieldItemName}-color-brand`,
                  labelText: 'Brand',
                  value: 'brand',
                },
              ]
            : []),
          {
            id: `${fieldItemName}-color-white`,
            labelText: 'White',
            value: 'white',
          },
          {
            id: `${fieldItemName}-color-black`,
            labelText: 'Black',
            value: 'black',
          },
        ]}
      />
    )}
  />
)

export default injectSheet(styles)(PlacementColor)
