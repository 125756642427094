import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createTokens, TokensProvider } from '@instacart/cocktail'

import App from './App'
import AppContext from './modules/appContext'
import store from './store'
import theme from './styling/theme.cocktail'
import { setupSentry } from './external'

setupSentry()

const tokens = createTokens(theme)

ReactDOM.render(
  <Provider store={store}>
    <TokensProvider tokens={tokens}>
      <AppContext>
        <App />
      </AppContext>
    </TokensProvider>
  </Provider>,
  document.getElementById('root')
)

navigator.serviceWorker.getRegistrations().then(registrations => {
  for (const registration of registrations) {
    registration.unregister()
  }
})
