import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import injectSheet from 'react-jss'

import styles from './FormLabel.styles'

const FormLabel = ({ classes, hideLabel, htmlFor, labelText }) => {
  const labelClasses = classnames(classes.formLabel, {
    [classes.formLabelHidden]: hideLabel,
  })
  return (
    <label className={labelClasses} htmlFor={htmlFor}>
      {labelText}
    </label>
  )
}

FormLabel.propTypes = {
  children: PropTypes.node,
  hideLabel: PropTypes.bool,
  htmlFor: PropTypes.string,
  labelText: PropTypes.string.isRequired,
}

FormLabel.defaultProps = {
  hideLabel: false,
  labelText: '',
}

export default injectSheet(styles)(FormLabel)
