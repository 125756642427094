import PropTypes from 'prop-types'
import React from 'react'
import injectSheet from 'react-jss'

import { Icon } from 'components'
import { isObject } from 'utils'

import styles from './FormRequirement.styles'

/**
 *  Displays input error on presence of the `invalidText` prop
 */
const FormRequirement = ({ classes, id, invalidText }) =>
  invalidText && !isObject(invalidText) ? (
    <div className={classes.formRequirement} id={id}>
      <Icon name="error" className={classes.iconError} />
      {invalidText}
    </div>
  ) : null

FormRequirement.propTypes = {
  id: PropTypes.string,
  invalidText: PropTypes.string,
}

export default injectSheet(styles)(FormRequirement)
