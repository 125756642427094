import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import injectSheet from 'react-jss'

import { ReadOnlyValue } from 'components'

const styles = theme => ({
  readOnlyValueDetailsInverse: {
    display: 'flex',
  },
})

const ReadOnlyValueDetails = ({ classes, detailValues, orientation, wideLabel }) => (
  <div
    className={cx({
      [classes.readOnlyValueDetailsInverse]: orientation === 'vertical',
    })}
  >
    {detailValues.map(({ useCustomValueEl, ...readOnlyValueProps }, index) => (
      <ReadOnlyValue
        key={index}
        orientation={orientation}
        removeTextAreaEl={!!useCustomValueEl}
        small
        wideLabel={wideLabel}
        {...readOnlyValueProps}
      />
    ))}
  </div>
)

ReadOnlyValueDetails.propTypes = {
  /**
   * The set of read-only value object to display.
   * (Each will correspond to/provide props for a <ReadOnlyValue> component.)
   */
  detailValues: PropTypes.array,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']).isRequired,
  wideLabel: PropTypes.bool,
}

ReadOnlyValueDetails.defaultProps = {
  orientation: 'horizontal',
  wideLabel: false,
}

export { ReadOnlyValueDetails as ReadOnlyValueDetailsUnStyled }
export default injectSheet(styles)(ReadOnlyValueDetails)
