import PropTypes from 'prop-types'
import React from 'react'
import injectSheet from 'react-jss'

const styles = theme => ({
  container: {
    display: 'flex',
    marginBottom: theme.spacing.lg,
  },
  headerTotal: {
    alignItems: 'center',
    display: 'flex',
    fontFamily: theme.fontFamilySansSerif,
    fontWeight: 600,
    marginRight: theme.spacing.lg,

    '&:last-child': {
      marginRight: 0,
    },
  },
  label: {
    fontSize: theme.fontSize.p,
    color: theme.text02,
    marginRight: theme.spacing.xs,
  },
  total: {
    fontSize: theme.fontSize.h3,
    color: theme.text01,
  },
})

const HeaderTotals = ({ classes, headers }) => (
  <div className={classes.container}>
    {headers.map(({ label, total }, index) => (
      <div className={classes.headerTotal} key={index}>
        <span className={classes.label}>{label}:</span>
        <span className={classes.total}>{total}</span>
      </div>
    ))}
  </div>
)

HeaderTotals.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ).isRequired,
}

export { HeaderTotals as HeaderTotalsUnStyled }
export default injectSheet(styles)(HeaderTotals)
