import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import injectSheet from 'react-jss'

import { ClickHandler } from 'utils'

const styles = theme => ({
  dropdownMenuContainer: {
    display: 'inline-block',
    position: 'relative',

    '&:focus': {
      outline: 'none',
    },
  },
  dropdownMenu: {
    zIndex: theme.zIndex.dropdown,
    backgroundColor: '#FFFFFF',
    boxShadow: theme.dropdownMenuBoxShadow,
    marginTop: 2,
    padding: `${theme.spacing.xs} 0`,
    position: 'absolute',
    whiteSpace: 'nowrap',
  },
  dropdownMenuAlignedLeft: {
    left: 0,
  },
  dropdownMenuAlignedRight: {
    right: 0,
  },
})

class DropdownMenu extends Component {
  static propTypes = {
    ariaLabel: PropTypes.string,
    /**
     * Each child must be wrapped in `DropdownMenuItem`
     */
    children: PropTypes.node,
    className: PropTypes.string,
    id: PropTypes.string,
    menuAlignment: PropTypes.oneOf(['left', 'right']),
    /**
     * Function for the consuming component to use in order to provide the DropdownMenu trigger
     */
    triggerRender: PropTypes.func.isRequired,
  }

  static defaultProps = {
    ariaLabel: 'list of options',
    menuAlignment: 'left',
  }

  state = {
    isOpen: false,
  }

  bindMenuEl = menuEl => {
    this.menuEl = menuEl
  }

  closeMenu = () => {
    this.setState({ isOpen: false })
  }

  handleClick = event => {
    this.setState({ isOpen: !this.state.isOpen })

    // Prevent click handlers from firing from parents of this element.
    if (event && event.stopPropagation) event.stopPropagation()
  }

  handleClickOutside = event => {
    this.closeMenu()
  }

  handleKeyDown = event => {
    if (event.which === 40) {
      this.setState({ isOpen: !this.state.isOpen })
    }
  }

  handleKeyPress = event => {
    // Only respond to key events when the menu is closed, so that menu items still respond to key events
    if (!this.state.isOpen) {
      const key = event.key || event.which

      if (key === 'Enter' || key === 13 || key === ' ' || key === 32) {
        this.setState({ isOpen: true })
      }
    }
  }

  render() {
    const {
      ariaLabel,
      children,
      classes,
      className,
      id,
      menuAlignment,
      theme,
      triggerRender,
      ...restProps
    } = this.props

    const { isOpen } = this.state

    const dropdownMenuClasses = cx(classes.dropdownMenu, {
      [classes.dropdownMenuAlignedLeft]: menuAlignment === 'left',
      [classes.dropdownMenuAlignedRight]: menuAlignment === 'right',
    })

    // Give each `DropdownMenuItem` child component the `closeMenu` function
    const childrenWithProps = React.Children.toArray(children).map(child =>
      React.cloneElement(child, {
        closeMenu: this.closeMenu,
      })
    )

    const menuBody = <ul className={dropdownMenuClasses}>{childrenWithProps}</ul>

    const triggerProps = {
      description: ariaLabel,
      isOpen,
      onClick: this.handleClick,
      onKeyDown: this.handleKeyDown,
    }

    return (
      <ClickHandler onClickOutside={this.handleClickOutside}>
        <div
          aria-expanded={isOpen}
          aria-haspopup
          aria-label={ariaLabel}
          className={classes.dropdownMenuContainer}
          id={id}
          onKeyDown={this.handleKeyPress}
          ref={this.bindMenuEl}
          role="button"
          tabIndex="0"
          {...restProps}
        >
          {triggerRender(triggerProps)}
          {isOpen && menuBody}
        </div>
      </ClickHandler>
    )
  }
}

export { DropdownMenu as DropdownMenuUnStyled }
export default injectSheet(styles)(DropdownMenu)
