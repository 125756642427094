import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import config, { fetchFrontendConfigScript, overrideConfig } from 'config'
import navbarLinks from 'pages/Dashboard/navbarLinks'
import { setConfig } from 'actions/general'
import { updateNavigationLinks } from 'actions/navigation'
import { KnowledgeOwl, Pendo } from 'external'
import { LoadingIndicator } from 'components'

import Main from './Main'

class Dashboard extends Component {
  state = {
    error: undefined,
    isConfigured: false,
  }

  componentDidMount() {
    const { user } = this.props
    // Initializer user visit in Pendo
    Pendo.initialize({ user })

    // Initialize Knowledge Owl Widget now that user is authenticated and trigger element is in the DOM
    KnowledgeOwl.initialize()

    // Fetch /frontend-configs.js when the user is authenticated and env values are parsed.
    ;(async () => {
      let value
      try {
        value = await fetchFrontendConfigScript()
      } catch (error) {
        this.setState({ error })
        return
      }
      overrideConfig(value)

      this.props.setConfig(config)
      this.props.updateNavigationLinks(navbarLinks())

      // eslint-disable-next-line no-console
      console.log('Config:', config)
      this.setState({ isConfigured: true })
    })()
  }

  render() {
    const { error } = this.state
    if (error) {
      throw error
    }

    if (!this.state.isConfigured) {
      return <LoadingIndicator.Dots absoluteCenter />
    }

    const { location } = this.props
    return <Main location={location} />
  }
}
const mapStateToProps = state => ({
  user: state.user.data,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setConfig,
      updateNavigationLinks,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
