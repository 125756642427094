import React from 'react'
import injectSheet from 'react-jss'

import { FormFieldItem, TextInput } from 'components'

import styles from './CollectionCreateOrEdit.styles'

const CollectionFormDetails = ({
  classes,
  formikProps: { setFieldValue },
  key,
  pageId,
  sectionName,
}) => {
  return (
    <div className={classes.createCollectionContainer} key={key}>
      <h5 className={classes.createCollectionTitle}>{sectionName}</h5>
      <div className={classes.formRow}>
        <FormFieldItem
          id={`${pageId}-name`}
          labelText="Name"
          name="name"
          render={({ id, isInvalid, name, value }) => (
            <TextInput
              autoFocus
              className={classes.formInput}
              id={id}
              inputDescription="Product collection name must be unique"
              isInvalid={isInvalid}
              name={name}
              onChange={({ target: { value: newValue } }) => {
                setFieldValue(name, newValue)
              }}
              placeholder="Enter product collection name"
              type="text"
              value={value}
            />
          )}
        />
      </div>
      <div>
        <FormFieldItem
          id={`${pageId}-display-name`}
          name="displayName"
          labelText="Display Title"
          render={({ id, isInvalid, name, value }) => (
            <TextInput
              className={classes.formInput}
              id={id}
              isInvalid={isInvalid}
              name={name}
              placeholder="Enter display title (optional)"
              onChange={({ target: { value: newValue } }) => {
                setFieldValue(name, newValue)
              }}
              type="text"
              value={value}
            />
          )}
        />
      </div>
    </div>
  )
}

export default injectSheet(styles)(CollectionFormDetails)
