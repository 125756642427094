import { vars } from 'styling'

export default theme => ({
  toastContainer: {
    zIndex: theme.zIndex.overlay,
    bottom: 56,
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    right: 40,
    width: 408,
  },
  toastNotification: {
    animation: `notificationFadeIn ${vars.transition.duration.base}`,
    background: '#FFFFFF',

    '& + &': {
      marginTop: theme.spacing.md,
    },
  },
  notificationFadeOut: {
    animation: `notificationFadeOut ${vars.transition.duration.base}`,
  },
  '@global @keyframes notificationFadeIn': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  '@global @keyframes notificationFadeOut': {
    from: {
      opacity: 1,
    },
    to: {
      opacity: 0,
    },
  },
})
