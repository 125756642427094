import { mixins, vars } from 'styling'

import treeExpand from './treeExpand.svg'

export const TREE_CLASS_PREFIX = 'unata-tree'
export const TREE_SWITCHER_CLASS = `${TREE_CLASS_PREFIX}-switcher`
export const TREE_SWITCHER_DISABLED_CLASS = `${TREE_SWITCHER_CLASS}-disabled`
export const TREE_SWITCHER_CLASS_NOOP = `${TREE_SWITCHER_CLASS}-noop`
export const TREE_CHECKBOX_CLASS = `${TREE_CLASS_PREFIX}-checkbox`
export const TREE_CONTENT_WRAPPER_CLASS = `${TREE_CLASS_PREFIX}-node-content-wrapper
`
export const TREE_CONTENT_TITLE_CLASS = `${TREE_CLASS_PREFIX}-title`

// NOTE: rc-tree doesn't make this very easy for us, so some of this is a little wonky

export default theme => {
  const checkbox = mixins.checkbox({ theme })
  return {
    tree: {
      padding: '4px 0', // Compensate for padding left of "switcher" icon

      // Add spacing between each tree node
      [`& .${TREE_CLASS_PREFIX}-treenode-switcher-open, & .${TREE_CLASS_PREFIX}-treenode-switcher-close`]: {
        marginBottom: theme.spacing.md,

        '&:last-of-type': {
          marginBottom: 0,
        },
      },
      // Disabled item styles go here
      [`& .${TREE_SWITCHER_DISABLED_CLASS}`]: {
        opacity: 0.5,
        [`& .${TREE_CONTENT_WRAPPER_CLASS}`]: {
          cursor: 'default',
        },
        [`& .${TREE_CHECKBOX_CLASS}`]: {
          ...checkbox.square.disabled,
        },
        [`& .${TREE_CONTENT_WRAPPER_CLASS}:hover, & .${TREE_CHECKBOX_CLASS}:hover + .${TREE_CONTENT_WRAPPER_CLASS}`]: {
          fontWeight: 400,
        },
        [`& .${TREE_CHECKBOX_CLASS}:hover`]: {
          ...checkbox.square.disabled,
        },
      },
      [`& .${TREE_CLASS_PREFIX}-child-tree`]: {
        marginTop: theme.spacing.md,
        // Add indent to child nodes
        marginLeft: theme.spacing.xxlg,
      },

      // Container for Tree chevron that triggers open/close of child nodes
      [`& .${TREE_SWITCHER_CLASS}`]: {
        cursor: 'pointer',
        display: 'inline-block',
        height: 16,
        marginRight: theme.spacing.xxs,
        padding: 4,
        verticalAlign: 'top',
        width: 16,

        // Tree chevron
        [`&:not(.${TREE_SWITCHER_CLASS_NOOP})`]: {
          content: `url(${treeExpand})`,
          transition: `transform ${vars.transition.duration.base} ${vars.transition.timingFunction.standardEasing}`,

          [`&:hover, &.${TREE_SWITCHER_CLASS}_open`]: {
            transform: 'rotate(90deg)',
          },
        },
      },

      // Checkbox square
      [`& .${TREE_CHECKBOX_CLASS}`]: {
        ...checkbox.square.default,
        cursor: 'pointer',
        display: 'inline-block',
        position: 'relative',
        verticalAlign: 'top',

        // Show hover style for checkbox square when the square itself is hovered
        '&:hover': {
          ...checkbox.square.hover,
        },

        // TODO: Change rc-tree markup so we can have checkbox square hover styles
        // when the label is hovered - right now we can't because the checkbox square is
        // a previous sibling and that's not really a supported thing in CSS yet :(

        // TODO: Allow rc-tree to focus elements of the tree
        // '&:focus': {
        //   ...checkbox.square.focus,
        // },

        // Checkmark
        '&::after': {
          ...checkbox.checkmark.default,
        },
        [`&.${TREE_CHECKBOX_CLASS}-checked`]: {
          ...checkbox.square.checked,

          '&::after': {
            ...checkbox.checkmark.checked,
            left: 2,
          },
        },
        [`&.${TREE_CHECKBOX_CLASS}-indeterminate`]: {
          ...checkbox.square.indeterminate,

          '&::after': {
            ...checkbox.checkmark.indeterminate,
            left: 3,
          },
        },
      },

      // Label Container
      [`& .${TREE_CONTENT_WRAPPER_CLASS}`]: {
        ...checkbox.label.default,
        display: 'inline-block',
        paddingLeft: theme.spacing.xs,
        verticalAlign: 'top',

        // Bold style on hover of label
        '&:hover': {
          ...checkbox.label.bold,
        },

        // Label
        [`&  > .${TREE_CONTENT_TITLE_CLASS}`]: {
          display: 'block',
          lineHeight: '16px',
        },
      },
      // Bold label on hover of adjacent checkbox square
      [`& .${TREE_CHECKBOX_CLASS}:hover + .${TREE_CONTENT_WRAPPER_CLASS}`]: {
        ...checkbox.label.bold,
      },
      // Bold Label when checkmark checked, or if node is filter result
      [`& .${TREE_CLASS_PREFIX}-treenode-checkbox-checked, & .filter-node`]: {
        [`& > .${TREE_CONTENT_WRAPPER_CLASS}`]: {
          ...checkbox.label.bold,
        },
      },
    },
    noResultsFoundMsg: {
      fontSize: theme.fontSize.p,
      color: theme.text01,
      padding: theme.spacing.xs,
    },
  }
}
