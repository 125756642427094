import { Formik } from 'formik'
import React, { Fragment, PureComponent } from 'react'
import injectSheet from 'react-jss'
import * as yup from 'yup'

import config from 'config'

import { createSegmentWithCSV } from 'api'
import {
  Button,
  FileSelect,
  FormFieldItem,
  Notification,
  RadioButtons,
  TextInput,
} from 'components'
import Styling, { ModalHeader } from 'styling/components'

const styles = theme => ({
  form: {
    width: 450,
  },
  nameInput: {
    width: 290,
  },
  fileProcessingExplanation: {
    fontSize: theme.fontSize.p,
    color: theme.text01,
    lineHeight: theme.baseLineHeight,
    marginTop: theme.spacing.md,
  },
})

class UploadSegmentCSVForm extends PureComponent {
  uploadSegmentCSV = ({ file, ...restValues }, form) => {
    const { closeModal } = this.props

    return createSegmentWithCSV(restValues, file)
      .then(() => {
        form.setSubmitting(false)
        closeModal({ requiresRefetch: true })
      })
      .catch(error => {
        form.setSubmitting(false)
        form.setErrors({ global: error.message })
      })
  }

  render() {
    const { classes, closeModal } = this.props
    const usesExternalCRM = config.dashboard.usesExternalCRM
    return (
      <div>
        <ModalHeader className={classes.header}>Upload a CSV</ModalHeader>
        <Formik
          initialValues={{
            name: '',
            type: null,
            file: null,
            polytype: 'csv',
          }}
          validationSchema={() =>
            yup.object().shape({
              name: yup
                .string()
                .trim()
                .label('Segment Name')
                .required(),
              type: yup
                .string()
                .label('CSV Content')
                .nullable()
                .required(),
              file: yup
                .mixed()
                .label('Upload File')
                .nullable()
                .required(),
            })
          }
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={this.uploadSegmentCSV}
          render={({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => {
            return (
              <form className={classes.form} onSubmit={handleSubmit}>
                <FormFieldItem
                  id="upload-segment-csv-name"
                  labelText="Segment Name"
                  name="name"
                  render={renderProps => (
                    <TextInput {...renderProps} autoFocus placeholder="Enter Segment Name" />
                  )}
                />
                <FormFieldItem
                  id="upload-segment-csv-type"
                  labelText="CSV Content"
                  name="type"
                  render={({ id, value }) => (
                    <RadioButtons
                      id={id}
                      onChange={newType => {
                        setFieldValue('type', newType)
                      }}
                      orientation="horizontal"
                      radioButtonProps={[
                        ...(!usesExternalCRM
                          ? [
                              {
                                id: 'email',
                                labelText: 'Email Address',
                                value: 'emails',
                              },
                            ]
                          : []),
                        {
                          id: 'loyalty',
                          labelText: 'Loyalty Number',
                          value: 'loyalty_card_numbers',
                        },
                        {
                          id: 'user-id',
                          labelText: 'External User ID',
                          value: 'user_ext_ids',
                        },
                        {
                          id: 'ic-user-id',
                          labelText: 'IC User ID',
                          value: 'ic_user_ids',
                        },
                        {
                          id: 'unata-user-id',
                          labelText: 'Unata User ID',
                          value: 'unata_user_ids',
                        },
                      ]}
                      selectedValue={value}
                    />
                  )}
                />
                <FormFieldItem
                  id="upload-segment-csv-file"
                  labelText="Upload File"
                  name="file"
                  render={({ id, value }) => (
                    <Fragment>
                      <FileSelect
                        id={id}
                        onFileSelect={newFile => {
                          setFieldValue('file', newFile)
                        }}
                      />
                      {value && (
                        <p className={classes.fileProcessingExplanation}>
                          CSV files may take some time to be processed. The # of matching users will
                          be displayed in the Customer User Segments table once the processing is
                          complete.
                        </p>
                      )}
                    </Fragment>
                  )}
                />

                {!isSubmitting && errors.global && (
                  <Notification kind="error" message={errors.global} />
                )}
                <Styling.LineOfButtons>
                  <Button disabled={isSubmitting} type="submit">
                    Save
                  </Button>
                  <Button kind="link" onClick={closeModal}>
                    Cancel
                  </Button>
                </Styling.LineOfButtons>
              </form>
            )
          }}
        />
      </div>
    )
  }
}

export default injectSheet(styles)(UploadSegmentCSVForm)
