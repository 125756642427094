import { capitalize } from '.'

export const getPaymentStatusIndicatorText = (paymentEvent = {}) => {
  switch (paymentEvent.status) {
    case 'createauth':
      return 'Create Auth'
    case 'setinstrument':
      return 'Set Instrument'
    case 'validateinstrument':
      return 'Validate Instrument'
    case 'executeauth':
      return 'Execute Auth'
    case 'voidauth':
      return 'Void Auth'
    case 'captureauth':
      return 'Capture Auth'
    case 'executepayment':
      return 'Execute Payment'
    case 'refund':
    case 'error':
      return capitalize({ phrase: paymentEvent.status })
    default:
      return paymentEvent.status
  }
}

export const getPaymentTypeText = paymentType => {
  switch (paymentType) {
    case 'auth_capture':
    case 'external_auth':
      return 'Credit Card'
    case 'paypal':
      return 'PayPal'
    default:
      return capitalize({ phrase: paymentType, separator: '_' })
  }
}

// Creates a new-line-delimited string for all of a product's selected configurations
export const getProductConfigOptionStrings = productConfig =>
  productConfig.options
    .map(selectedOption => {
      if (selectedOption.base) return `${selectedOption.optionName}: ${selectedOption.base.name}`
      if (selectedOption.item) return `${selectedOption.optionName}: ${selectedOption.item.name}`
      if (selectedOption.text) return `${selectedOption.optionName}: "${selectedOption.text}"`

      if (selectedOption.items) {
        const itemNames = selectedOption.items.map(item => item.name).join(', ')

        return `${selectedOption.optionName}: ${itemNames}`
      }

      if (selectedOption.file) return `${selectedOption.optionName}: ${selectedOption.file.name}`

      return null
    })
    .filter(configString => configString !== null)

const getStatusDefaultsFromKey = key => ({
  key,
  shouldUseEstimatedTotals: true,
  statusText: capitalize({ phrase: key }),
})

export const orderStatuses = [
  {
    ...getStatusDefaultsFromKey('new'),
    indicatorType: 'new',
  },
  {
    ...getStatusDefaultsFromKey('inprogress'),
    indicatorType: 'in-progress',
    statusText: 'In Progress',
  },
  {
    ...getStatusDefaultsFromKey('done'),
    indicatorType: 'in-progress',
    statusText: 'Picking Completed',
    shouldUseEstimatedTotals: false,
  },
  {
    ...getStatusDefaultsFromKey('shipped'),
    indicatorType: 'complete',
    shouldUseEstimatedTotals: false,
  },
  {
    ...getStatusDefaultsFromKey('cancelled'),
    indicatorType: 'cancelled',
  },
  {
    ...getStatusDefaultsFromKey('authrequest'),
    statusText: 'Auth Request',
  },
  {
    ...getStatusDefaultsFromKey('authapproved'),
    statusText: 'Auth Approved',
  },
  {
    ...getStatusDefaultsFromKey('autherror'),
    statusText: 'Auth Error',
  },
  {
    ...getStatusDefaultsFromKey('ordercancellationemail'),
    statusText: 'Sent Order Cancellation Email',
  },
  {
    ...getStatusDefaultsFromKey('sentorderconfirmationemail'),
    statusText: 'Sent Order Confirmation Email',
  },
  {
    ...getStatusDefaultsFromKey('orderreceiptemail'),
    statusText: 'Sent Order Receipt Email',
  },
  {
    ...getStatusDefaultsFromKey('sentorderreadyemail'),
    statusText: 'Sent Order Ready Email',
  },
  {
    ...getStatusDefaultsFromKey('sentreorderemail'),
    statusText: 'Sent Re-Order Email',
  },
]

/**
 * Get status by status key
 *
 * @param {string} statusKey
 * @param {object[]} statuses - Statuses to use instead of defaults
 * @returns {Object|null} Status object
 */
export const getOrderStatusByKey = (statusKey, statuses) => {
  const foundStatus = (statuses || orderStatuses).find(({ key }) => statusKey === key)

  if (foundStatus) return foundStatus

  return getStatusDefaultsFromKey(statusKey)
}

/**
 * Get the nth next (or previous) status based on the current status's key
 *
 * @param {string} currentStatusKey
 * @param {object[]} statuses - Statuses to use instead of defaults
 * @param {number} relativeIndex - Relative index of order status (eg. 1 is next, -2 is second previous)
 * @returns {Object|null} Next status if it exists, null otherwise
 */
export const getRelativeOrderStatus = (currentStatusKey, statuses, relativeIndex) => {
  const statusList = statuses || orderStatuses
  const statusIndex = statusList.findIndex(status => currentStatusKey === status.key)
  const nextStatus = statusList[statusIndex + relativeIndex]

  if (statusIndex === -1 || !nextStatus) return null

  return nextStatus
}

/**
 * Get the next status based on the current status's key
 *
 * @param {string} currentStatusKey
 * @param {object[]} statuses - Statuses to use instead of defaults
 * @returns {Object|null} Next status if it exists, null otherwise
 */
export const getNextOrderStatus = (currentStatusKey, statuses) =>
  getRelativeOrderStatus(currentStatusKey, statuses, 1)

/**
 * Get the previous status based on the current status's key
 *
 * @param {string} currentStatusKey
 * @param {object[]} statuses - Statuses to use instead of defaults
 * @returns {Object|null} Next status if it exists, null otherwise
 */
export const getPreviousOrderStatus = (currentStatusKey, statuses) =>
  getRelativeOrderStatus(currentStatusKey, statuses, -1)
