import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import injectSheet from 'react-jss'
import ReactModal from 'react-modal'

import { Icon } from 'components'
import { isNil } from 'utils'

import { ModalHeader } from 'styling/components'

import styles from './Modal.styles'

ReactModal.setAppElement('body')

class Modal extends Component {
  static propTypes = {
    /**
     * className is applied to the modal container
     */
    className: PropTypes.string,
    /**
     * Used by the consuming component to react to any user action made by closing the modal.
     * Is passed in any props provided to the call to `closeModal`.
     */
    handleCloseModal: PropTypes.func,
    /**
     * Sets the initial isOpen property in state, and removes the closeModal state handling
     */
    isAlwaysOpen: PropTypes.bool.isRequired,
    /**
     * A consuming component can trigger the opening/closing of a modal through the `isOpen` prop
     */
    isOpen: PropTypes.bool,
    modalHeading: PropTypes.string,
    /**
     * Renders everything inside the modal.
     * (Is passed the `closeModal` function which closes the modal.)
     */
    render: PropTypes.func,
    /**
     * If provided, will span the modal to its maximum height and allow the consuming
     * component to render whatever they'd like in the sticky footer.
     */
    renderStickyFooter: PropTypes.func,
    size: PropTypes.oneOf(['small', 'large']),
    /**
     * Renders the content which triggers the opening of the modal.
     * (Is passed the `openModal` function which opens the modal.)
     */
    triggerRender: PropTypes.func,
    /* Boolean indicating if the overlay should close the modal. Defaults to true. */
    shouldCloseOnOverlayClick: PropTypes.bool,
    /* Boolean indicating if pressing the esc key should close the modal. Defaults to true. */
    shouldCloseOnEsc: PropTypes.bool,
  }

  static defaultProps = {
    isAlwaysOpen: false,
    isOpen: false,
    size: 'large',
    showClose: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      isOpen: props.isAlwaysOpen,
      contentState: {}, // Modal content state which can be accessed throughout the modal if needed
    }
  }

  // If `isOpen` is used as a controlled prop, force the modal open
  static getDerivedStateFromProps(props, state) {
    // On initial render or any subsequent changes to `isOpen` prop
    if (
      !props.isAlwaysOpen &&
      (isNil(state.lastIsOpenProp) || props.isOpen !== state.lastIsOpenProp)
    ) {
      return {
        isOpen: props.isOpen,
        lastIsOpenProp: props.isOpen,
      }
    }
    return null
  }

  closeModal = closeModalProps => {
    const { handleCloseModal, isAlwaysOpen } = this.props

    if (handleCloseModal) {
      handleCloseModal(closeModalProps)
    }
    if (!isAlwaysOpen) {
      this.setState({ isOpen: false })
    }
  }

  openModal = initialContentState => {
    // contentState (must be an object!) can be initialized here (to avoid calling both openModal and setContentState)
    if (initialContentState && typeof initialContentState === 'object') {
      this.setState({
        isOpen: true,
        contentState: initialContentState,
      })
    } else {
      this.setState({
        isOpen: true,
      })
    }
  }

  setContentState = contentStateDelta => {
    // contentStateDelta must be an object!
    if (
      contentStateDelta &&
      typeof contentStateDelta === 'object' &&
      Object.keys(contentStateDelta).length > 0
    ) {
      this.setState(state => ({ contentState: { ...state.contentState, ...contentStateDelta } }))
    }
  }

  render() {
    const {
      classes,
      className,
      modalHeading,
      render,
      renderStickyFooter,
      showClose,
      shouldCloseOnOverlayClick,
      shouldCloseOnEsc,
      size,
      triggerRender,
    } = this.props

    const renderProps = {
      closeModal: this.closeModal,
      contentState: this.state.contentState,
      setContentState: this.setContentState,
    }

    return (
      <Fragment>
        {triggerRender && triggerRender({ openModal: this.openModal })}
        <ReactModal
          bodyOpenClassName={classes.modalOpenBody}
          className={classes.wrapper}
          htmlOpenClassName={classes.modalOpenHtml}
          isOpen={this.state.isOpen}
          shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
          shouldCloseOnEsc={shouldCloseOnEsc}
          onRequestClose={this.closeModal}
          overlayClassName={classes.overlay}
          portalClassName={cx({
            [classes.modalSmall]: size === 'small',
            [classes.modalLarge]: size === 'large',
            [classes.withStickyFooter]: !!renderStickyFooter,
          })}
        >
          <div className={cx(className, classes.modalContainer)}>
            <div className={classes.modalContentContainer}>
              <div className={classes.modalContent}>
                {showClose && (
                  <button className={classes.modalClose} onClick={this.closeModal} type="button">
                    <Icon
                      name="close"
                      className={classes.modalCloseIcon}
                      description="Close Modal"
                    />
                  </button>
                )}
                {modalHeading && (
                  <ModalHeader className={classes.modalHeading}>{modalHeading}</ModalHeader>
                )}
                {render(renderProps)}
              </div>
            </div>
            {renderStickyFooter && (
              <div className={classes.modalStickyFooter}>{renderStickyFooter(renderProps)}</div>
            )}
          </div>
        </ReactModal>
      </Fragment>
    )
  }
}

export { Modal as ModalUnStyled }
export default injectSheet(styles)(Modal)
