export default theme => ({
  pageContainer: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: 1136,
    margin: '0 auto',
  },
  addCategoryContainer: {
    backgroundColor: theme.bgGreyExtraLight,
    marginTop: theme.spacing.xs,
    maxWidth: theme.contentWidth,
    padding: theme.spacing.md,
  },
  addCollectionContainer: {
    maxWidth: 700,
  },
  colorSelector: {
    alignItems: 'center',
    display: 'flex',
    '& > *:first-child': {
      marginRight: theme.spacing.md,
    },
  },
  createPlacementContainer: {
    paddingBottom: theme.spacing.xxlg,

    '&:first-child': {
      paddingTop: theme.spacing.lg,
    },
  },
  createPlacementTitle: {
    color: theme.text01,
    fontSize: theme.fontSize.h2,
    fontFamily: theme.fontFamilySansSerif,
    marginBottom: theme.spacing.md,
  },
  formContainer: {
    width: '100%',
  },
  formInput: {
    maxWidth: theme.contentWidth,
    position: 'relative',
    width: '100%',
    marginBottom: theme.spacing.md,
  },
  formInlineCheckbox: {
    display: 'inline-block',
    marginRight: theme.spacing.xxs,
    position: 'relative',
    width: 100,
  },
  formRangeInput: {
    maxWidth: 220,
  },
  formRow: {
    marginBottom: theme.spacing.lg,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  formTooltip: {
    fontSize: 12,
    display: 'inline-block',
    maxWidth: theme.contentWidth,
    color: theme.text01,
  },
  footerContainer: {
    extend: 'pageContainer',
    alignItems: 'center',
    maxWidth: theme.containerWidth,
    margin: '0 auto',
    minHeight: 64,
    paddingBottom: theme.spacing.xs,
    paddingTop: theme.spacing.xs,
    position: 'relative',
  },
  footerErrors: {
    alignSelf: 'center',
    flex: 1,
    marginLeft: theme.spacing.xxlg,
  },
  formFooter: {
    background: '#FFFFFF',
    borderTop: `2px solid ${theme.bgGreyExtraLight}`,
    bottom: 0,
    left: 0,
    right: 0,
    padding: '0 115px',
    position: 'fixed',
  },
  imageUploadNarrowContainer: {
    width: 565,
  },
  inlineFormGroup: {
    display: 'flex',

    '& > *:not(:first-child)': {
      marginLeft: '1rem',
    },
  },
  inlineImageDestination: {
    flexGrow: 4,
  },
  tableImageThumbnail: {
    maxWidth: 81,
    maxheight: 81,
    borderRadius: 8,
  },
  tableImageThumbnailMini: {
    maxWidth: 49,
    maxheight: 49,
    borderRadius: 8,
  },
  imageItemDisplayContainer: {
    marginTop: 40,
  },
  contentId: {
    color: theme.text01,
    fontSize: theme.fontSize.body,
  },
  itemListImageUpload: {
    width: 250,
  },
  helpFormGroup: {
    '& input': {
      marginBottom: 4,
    },
  },
})
