import PropTypes from 'prop-types'
import React from 'react'
import injectSheet from 'react-jss'

import { Button } from 'components'
import { ModalHeader } from 'styling/components'

import Modal from './Modal'

const styles = theme => ({
  details: {
    fontSize: theme.fontSize.p,
    color: theme.text01,
    lineHeight: 1.5,
    margin: '0 auto',
    textAlign: 'left',
    width: 320,
  },
  buttonsContainer: {
    marginTop: theme.spacing.xxlg,
  },
  confirmButton: {
    marginRight: theme.spacing.lg,
  },
})

const ConfirmationModalContent = injectSheet(styles)(
  ({
    actionText,
    cancelButtonText,
    classes,
    closeModal,
    confirmButtonText,
    contentState,
    details,
    headerText,
  }) => (
    <div>
      <ModalHeader className={classes.header} small>
        {headerText || `Are you sure you want to ${actionText}?`}
      </ModalHeader>
      {details && <p className={classes.details}>{details}</p>}
      <div className={classes.buttonsContainer}>
        <Button
          className={classes.confirmButton}
          onClick={() => {
            // TODO: Remove the contentState spread here to remove synthetic event warning
            closeModal({ ...contentState, wasConfirmed: true })
          }}
        >
          {confirmButtonText}
        </Button>
        <Button kind="secondary" onClick={closeModal}>
          {cancelButtonText}
        </Button>
      </div>
    </div>
  )
)

ConfirmationModalContent.propTypes = {
  actionText: PropTypes.string,
  cancelButtonText: PropTypes.string.isRequired,
  /**
   * ({ wasConfirmed }) => {...}
   * Will be called with prop `wasConfirmed` set to `true` if the Confirm button was clicked
   */
  closeModal: PropTypes.func.isRequired,
  confirmButtonText: PropTypes.string.isRequired,
  details: PropTypes.node,
  headerText: PropTypes.string,
}

ConfirmationModalContent.defaultProps = {
  actionText: 'do this',
  cancelButtonText: 'Cancel',
  confirmButtonText: 'Yes',
}

const ConfirmationModal = ({ contentProps, ...modalProps }) => (
  <Modal
    {...modalProps}
    size="small"
    render={({ closeModal, contentState }) => (
      <ConfirmationModalContent
        closeModal={closeModal}
        contentState={contentState}
        {...contentProps}
      />
    )}
  />
)

export default ConfirmationModal
