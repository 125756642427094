export const updateDynamicNavigationLinks = (type, data) => (dispatch, getState) => {
  const {
    navigation: { dynamicLinks },
  } = getState()

  const newDynamicLinks = { ...dynamicLinks }

  newDynamicLinks[type] = data

  return dispatch({
    type: 'NAVIGATION_UPDATE',
    payload: {
      dynamicLinks: newDynamicLinks,
    },
  })
}

export const updateNavigationLinks = data => dispatch => {
  return dispatch({
    type: 'NAVIGATION_UPDATE',
    payload: {
      links: data,
    },
  })
}
