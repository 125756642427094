import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import injectSheet from 'react-jss'

import { mixins } from 'styling'

const styles = theme => {
  const inputField = mixins.inputField({ theme })
  return {
    textArea: {
      fontSize: theme.fontSize.body,
      backgroundColor: theme.field01,
      border: theme.inputBorder,
      borderRadius: theme.inputBorderRadius,
      color: theme.text01,
      display: 'block',
      fontFamily: theme.fontFamilySansSerif,
      fontWeight: theme.inputValueWeight,
      height: 64,
      minHeight: 64,
      padding: theme.spacing.xs,
      resize: 'vertical',
      width: '100%',
      '&::placeholder, &::-webkit-input-placeholder': {
        color: theme.text02,
        fontWeight: theme.inputPlaceholderWeight,
      },
      '&:focus': {
        ...mixins.focusOutline({ theme }),
      },
      '&:disabled': {
        background: theme.bgGreyExtraLight,
        border: theme.inputBorder,
        cursor: 'not-allowed',
        color: theme.text02,
      },
    },
    disableResize: {
      resize: 'none',
    },
    textAreaWithValue: inputField.withValue,
    textAreaInvalid: inputField.invalid,
    helpText: {
      fontSize: theme.fontSize.p,
      color: theme.text01,
      lineHeight: theme.baseLineHeight,
      marginTop: theme.spacing.xs,
    },
  }
}

const TextArea = ({
  className,
  classes,
  helpText,
  id,
  isInvalid,
  isResizable,
  maxLength,
  onBlur,
  onChange,
  placeholder,
  theme,
  ...restProps
}) => {
  const textAreaProps = {
    id,
    onChange: event => {
      if (!restProps.disabled) {
        onChange(event)
      }
    },
    onBlur: event => {
      if (!restProps.disabled) {
        onBlur(event)
      }
    },
    maxLength,
    placeholder,
  }

  const textAreaClasses = cx(className, classes.textArea, {
    [classes.disableResize]: !isResizable,
    [classes.textAreaWithValue]: !!restProps.value,
    [classes.textAreaInvalid]: isInvalid,
  })

  const textareaInput = isInvalid ? (
    <textarea
      {...restProps}
      {...textAreaProps}
      data-invalid
      aria-invalid
      aria-describedby={`${id}-error-msg`}
      className={textAreaClasses}
    />
  ) : (
    <textarea {...restProps} {...textAreaProps} className={textAreaClasses} />
  )

  const help = helpText ? <p className={classes.helpText}>{helpText}</p> : null

  return (
    <Fragment>
      {textareaInput}
      {help}
    </Fragment>
  )
}

TextArea.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  helpText: PropTypes.string,
  id: PropTypes.string.isRequired,
  /**
   * If `true`, will set aria-describedby to `${id}-error-msg`
   */
  isInvalid: PropTypes.bool,
  isResizable: PropTypes.bool.isRequired, // Only affects vertical resizing
  maxLength: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

TextArea.defaultProps = {
  disabled: false,
  isInvalid: false,
  isResizable: false,
  maxLength: null,
  onBlur: () => {},
  onChange: () => {},
}

export { TextArea as TextAreaUnStyled }
export default injectSheet(styles)(TextArea)
