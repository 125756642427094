import { useQuery } from '@instacart/enterprise-services-hooks'
import axios from 'axios'

/**
 * Hook to fetch a query with many items. Uses React Query under the hood.
 *
 * @param {string} queryKey - Key to use for query cache. Cache also takes params into account when generating the final cache key.
 * @param {func} fetchItem - Function to call to fetch, with a paramater to pass in config.
 * @param {Object} params - Paramaters to pass into the query. Also used to generate the cache key.
 *    Query will automatically refetch if a param changes.
 * @param {Object} options
 * @param {boolean} options.excludeParams - Whether params should be passed to Axios config.
 * @returns {Object} response
 * @returns {Object} response.items - The queried items
 * @returns {boolean} response.isFetchingItems - Whether the items are being fetched or refetched
 * @returns {boolean} response.isLoadingItems - Whether the items are being loaded
 * @returns {Object} response.error
 * @returns {func} response.refetch - To manually refetch the query
 * @returns {Object} response.otherData - Any other data from the response (excluding items).
 */
export const useItemsFetcher = (queryKey, fetchItem, params, options) => {
  const optionDefaults = {
    excludeParams: false,
    refetchOnWindowFocus: false,
  }

  const optionsWithDefaults = {
    ...options,
    ...optionDefaults,
  }

  const performFetch = () => {
    // Used to cancel the request if inactive (eg. on unmount)
    const cancelTokenSource = axios.CancelToken.source()

    const fetchPromise = fetchItem({
      cancelToken: cancelTokenSource.token,
      ...(!optionsWithDefaults.excludeParams && { params }),
    })

    fetchPromise.cancel = () => {
      cancelTokenSource.cancel('Pending fetch cancelled as is it no longer needed.')
    }

    return fetchPromise
  }

  const { data, isFetching: isFetchingItems, isLoading: isLoadingItems, error, refetch } = useQuery(
    params ? [queryKey, params] : queryKey,
    performFetch,
    {
      refetchOnWindowFocus: optionsWithDefaults.refetchOnWindowFocus,
      suspense: false,
    }
  )

  const { items, ...otherData } = data || { items: [] }

  return {
    items,
    error,
    refetch,
    isFetchingItems,
    isLoadingItems,
    otherData,
  }
}
