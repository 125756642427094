import React from 'react'

import { getRoutePathname } from 'routing'
import { renderV1 } from 'DashboardV1'

import Subscriptions from './Subscriptions/Subscriptions'
import CreateSubscription from './Subscriptions/CreateSubscription'
import EditSubscription from './Subscriptions/EditSubscription'

const routeDefinitions = [
  { path: getRoutePathname('subscriptions'), component: Subscriptions },
  { path: getRoutePathname('subscriptions.createSubscription'), component: CreateSubscription },
  {
    path: getRoutePathname('subscriptions.editSubscription', {
      id: ':subscriptionId',
    }),
    component: EditSubscription,
  },
  {
    path: getRoutePathname('blackoutDays'),
    render: renderV1,
  },
  {
    path: getRoutePathname('deliveryCompanies'),
    render: renderV1,
  },
  {
    path: getRoutePathname('storeDashboard'),
    render: renderV1,
  },
  {
    path: getRoutePathname('pluManagement'),
    render: renderV1,
  },
  {
    path: getRoutePathname('configurationManagement'),
    render: renderV1,
  },
]

export default routeDefinitions
