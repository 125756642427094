import React from 'react'

import { getRoutePathname } from 'routing'
import { renderV1 } from 'DashboardV1'

import MyProfile from './MyProfile/MyProfile'
import MyProfileEdit from './MyProfile/MyProfileEdit'
import ChangePassword from './ChangePassword/ChangePassword'

import Users from './Users/Users'
import { UserCreate, UserEdit } from './Users/UserCreateOrEdit'

import Roles from './Roles/Roles'
import Role from './Roles/Role'
import { RoleCreate, RoleEdit } from './Roles/RoleCreateOrEdit'

const routeDefinitions = [
  { path: getRoutePathname('myProfile'), component: MyProfile },
  { path: getRoutePathname('myProfile.editMyProfile'), component: MyProfileEdit },
  { path: getRoutePathname('changePassword'), component: ChangePassword },
  { path: getRoutePathname('users'), component: Users },
  { path: getRoutePathname('users.createUser'), component: UserCreate },
  { path: getRoutePathname('users.editUser', { id: ':userId' }), component: UserEdit },
  { path: getRoutePathname('roles'), component: Roles },
  { path: getRoutePathname('roles.createRole'), component: RoleCreate },
  { path: getRoutePathname('roles.role', { id: ':roleId' }), component: Role },
  { path: getRoutePathname('roles.editRole', { id: ':roleId' }), component: RoleEdit },
  {
    path: getRoutePathname('demo'),
    render: renderV1,
  },
]

export default routeDefinitions
