import React, { useCallback, useState, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { useTimeslotsQuery } from '@instacart/enterprise-services-hooks'

import { Dropdown, FormLabel, TextInput } from 'components'
import { formatTimeCustom } from 'utils'

const query = {
  user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  fulfillment_type: 'pickup',
  shopping_mode: 'catering',
}
const styles = theme => ({
  container: {
    display: 'flex',
    width: '100%',
  },
  item: {
    flex: 1,
    '&:not(:first-child):not(:last-child)': {
      padding: `0px ${theme.spacing.lg}`,
    },
  },
})

const useStyles = createUseStyles(styles)

/**
 * Helper function to get all days with timeslots
 */
const getUniqueTimeslotDay = timeslots => {
  return [...new Set(timeslots.map(timeslot => timeslot.fulfillmentDate))]
}

/**
 * Helper function to format timeslot times
 */
const formatTime = timeString => formatTimeCustom(timeString, 'HH:mm:ss')

/**
 * Helper funciton to get all timeslots on a day
 */
const getTimeslotsOnDay = (date, timeslots) => {
  const filteredTimeslots = date
    ? timeslots.filter(timeslot => timeslot.fulfillmentDate === date)
    : timeslots

  return filteredTimeslots.map(({ timeslot }) => ({
    id: timeslot.id,
    value: timeslot.id || timeslots.ext_id,
    label: `${formatTime(timeslot.startTime)} until ${formatTime(timeslot.endTime)}`,
  }))
}

const TimeslotRow = ({ storeId, setTimeslot, errors, isDisabled }) => {
  const classes = useStyles()

  // Get timeslots from the server
  const { data, isLoading, error } = useTimeslotsQuery(
    'catering',
    { ...query, store_id: storeId },
    { suspense: false, refetchOnWindowFocus: false, manual: isDisabled }
  )

  // Store some local state
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedTimeslotId, setSelectedTimeslotId] = useState(null)

  const setSelectedTimeslotIdWrapper = useCallback(
    timeslotId => {
      if (selectedTimeslotId !== timeslotId) {
        setTimeslot(data?.items.find(ts => ts.timeslot.id === timeslotId))
        setSelectedTimeslotId(timeslotId)
      }
    },
    [data, setTimeslot, selectedTimeslotId]
  )

  // Reset state when the selected store changes
  useEffect(() => {
    setSelectedDate(null)
    setSelectedTimeslotId(null)
  }, [storeId])

  // Display an error if the timeslot request fails
  if (!isDisabled && error) {
    return <p>Error fetching timeslots</p>
  }

  return (
    <div className={classes.container}>
      <div className={classes.item}>
        <FormLabel htmlFor="fulfillment-type" labelText="Fullfillment Type" />
        <TextInput disabled isInvalid id="fulfillment-type" value="In Store Pickup" />
      </div>
      <div className={classes.item}>
        <FormLabel htmlFor="pickup-date" labelText="Pickup Date" />
        {data && !data.items.length ? (
          <TextInput disabled isInvalid id="pickup-date" value="No timeslots available" />
        ) : (
          <Dropdown
            id="pickup-date"
            disabled={isDisabled}
            isLoading={isLoading}
            onChange={date => {
              setSelectedDate(date)
              setSelectedTimeslotIdWrapper(null)
            }}
            placeholder="Select Date"
            selectedValue={selectedDate}
            isInvalid={Boolean(errors.selectedTimeslot)}
            items={getUniqueTimeslotDay(data ? data.items : []).map(fulfillmentDate => ({
              id: fulfillmentDate,
              label: fulfillmentDate,
              value: fulfillmentDate,
            }))}
          />
        )}
      </div>
      <div className={classes.item}>
        <FormLabel htmlFor="pickup-time" labelText="Pickup Time" />
        <Dropdown
          id="pickup-time"
          isLoading={isLoading}
          disabled={!selectedDate || isDisabled}
          placeholder="Select Time"
          selectedValue={selectedTimeslotId}
          isInvalid={Boolean(errors.selectedTimeslot)}
          onChange={setSelectedTimeslotIdWrapper}
          items={getTimeslotsOnDay(selectedDate, data ? data.items : [])}
        />
      </div>
    </div>
  )
}

export default TimeslotRow
