import React, { Component } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { ScrollLink, Element as ScrollElement } from 'react-scroll'

import { PageHeader } from 'components'

import styles from './Scrollspy.styles'

const NavListItem = ScrollLink(props => {
  return (
    <li className={props.className} onClick={props.onClick}>
      <a href={`#${props.id}`}>{props.children}</a>
    </li>
  )
})

class Scrollspy extends Component {
  constructor(props) {
    super(props)

    this.isScrolling = null
  }

  render() {
    const { classes, name, render, sections, pageHeaderText } = this.props
    // Weird hack to target "previous" selector in css
    const navLinks = sections.slice(0).reverse()

    const wrappedSections = sections.map(({ component = 'div', ...restProps }, index) => (
      <ScrollElement key={index} name={restProps.name}>
        <Component {...restProps} />
      </ScrollElement>
    ))

    return (
      <ScrollElement name={name}>
        {pageHeaderText && (
          <div className={classes.headerContainer}>
            <PageHeader hasDivider noMargin headerTitle={pageHeaderText} />
          </div>
        )}
        <div className={classes.pageContainer}>
          <div className={classes.stickyMenu}>
            <ul className={classes.createCollectionMenu}>
              {navLinks.map(({ id, name: sectionName }, index) => (
                <NavListItem
                  activeClass="scroll-active"
                  duration={500}
                  id={id}
                  key={index}
                  spy
                  smooth
                  to={sectionName}
                >
                  {sectionName}
                </NavListItem>
              ))}
            </ul>
          </div>
          {render ? (
            render({ sections })
          ) : (
            <div className={classes.contentContainer}>{wrappedSections}</div>
          )}
        </div>
      </ScrollElement>
    )
  }
}

Scrollspy.defaultProps = {
  name: '',
  sections: [],
}

Scrollspy.propTypes = {
  sections: PropTypes.array.isRequired,
}

export default injectSheet(styles)(Scrollspy)
