import React, { Component } from 'react'
import injectSheet from 'react-jss'

import { Button, Notification } from 'components'

const styles = {
  container: {
    margin: '1em',
    width: 'auto !important',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    '& > :not(:last-child)': {
      marginRight: '0.5em',
    },
  },
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      return (
        <Notification
          kind="error"
          className={this.props.classes.container}
          message={
            <div className={this.props.classes.content}>
              <span>Something went wrong.</span>{' '}
              <Button
                kind="link"
                onClick={() => {
                  window.location.reload()
                }}
              >
                Reload
              </Button>
            </div>
          }
        />
      )
    }
    return this.props.children
  }
}

export default injectSheet(styles)(ErrorBoundary)
