import { mixins } from 'styling'

export default theme => ({
  navbar: {
    alignItems: 'center',
    background: theme.brand00,
    display: 'flex',
    height: 60,
    justifyContent: 'space-between',
    paddingRight: theme.spacing.lg,
  },
  logoContainer: {
    alignItems: 'center',
    display: 'flex',
    paddingRight: theme.spacing.xlg,
    position: 'relative',
  },
  hamburgerButton: {
    alignItems: 'center',
    margin: `0 ${theme.spacing.sm}`,
    height: '100%',
    background: 'none',
    border: '0',
    color: 'inherit',
    font: 'inherit',
    lineHeight: 'normal',
    overflow: 'visible',
    '&:focus': {
      outline: 'none',
    },
    cursor: 'pointer',
    display: 'flex',
  },
  hamburger: {
    height: theme.spacing.lg,
    width: theme.spacing.lg,
    fill: theme.inverse01,
  },
  logo: {
    fill: '#FFFFFF',
    height: 34,
    padding: '0 16px',
    width: 150,
  },
  statusBadge: {},
  links: {
    display: 'flex',
    height: '100%',
  },
  linksOnLeft: {
    flexGrow: 1,
  },
  linksOnRight: {},
  link: {
    ...mixins.resets.button,
    fontSize: theme.fontSize.p,
    alignItems: 'center',
    color: theme.inverse01,
    cursor: 'pointer',
    display: 'flex',
    fontFamily: theme.fontFamilySansSerif,
    fontWeight: 600,
    height: '100%',
    padding: `0 ${theme.spacing.md}`,
    textDecoration: 'none',

    '&:not($linkStyleLink)': {
      '&:hover, &:focus, &$activeLink': {
        background: theme.brand01,

        '& $linkIcon': {
          fill: theme.inverse01,
        },
      },
      '&:focus': {
        outline: 'none',
      },
    },
    '&$linkStyleLink:hover': {
      textDecoration: 'underline',
    },
  },
  activeLink: {},
  linkStyleLink: {},
  linkIcon: {
    fill: theme.brand02,
    flexShrink: 0,

    '& + $linkLabel': {
      marginLeft: 10,
    },
  },
  linkLabel: {},
})
