import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import injectSheet from 'react-jss'

import styles from './Checkbox.styles'

const Checkbox = ({
  classes,
  className,
  dropdownItemIsHighlighted,
  dropdownStyle,
  hideLabel,
  id,
  indeterminate,
  isInvalid,
  labelText,
  onChange,
  theme,
  ...restProps
}) => {
  const inputProps = {
    className: cx(classes.checkbox, { [classes.indeterminate]: indeterminate }),
    id,
    onChange,
    type: 'checkbox',
    ...restProps,
  }
  if (hideLabel) {
    inputProps['aria-label'] = labelText
  }

  const input = isInvalid ? (
    <input {...inputProps} data-invalid aria-invalid aria-describedby={`${id}-error-msg`} />
  ) : (
    <input {...inputProps} />
  )

  return (
    <div className={classes.checkboxWrapper}>
      {input}
      <label
        htmlFor={id}
        className={cx(className, classes.checkboxLabelContainer, {
          [classes.disabled]: inputProps.disabled,
          [classes.inDropdown]: dropdownStyle,
          [classes.hiddenLabel]: hideLabel,
          [classes.highlightedInDropdown]: dropdownItemIsHighlighted,
        })}
      >
        <span className={classes.checkboxSquare} />
        {!hideLabel && labelText}
      </label>
    </div>
  )
}

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  /**
   * Checkbox styling will differ slightly when inside of a dropdown menu
   */
  dropdownItemIsHighlighted: PropTypes.bool,
  dropdownStyle: PropTypes.bool,
  hideLabel: PropTypes.bool,
  id: PropTypes.string.isRequired,
  indeterminate: PropTypes.bool,
  /**
   * If `true`, will set aria-describedby to `${id}-error-msg`
   */
  isInvalid: PropTypes.bool,
  labelText: PropTypes.node.isRequired,
  onChange: PropTypes.func,
}

Checkbox.defaultProps = {
  checked: false,
  dropdownStyle: false,
  dropdownItemIsHighlighted: false,
  hideLabel: false,
  indeterminate: false,
}

Checkbox.displayName = 'Checkbox'

export { Checkbox as CheckboxUnStyled }
export default injectSheet(styles)(Checkbox)
