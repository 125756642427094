import memoize from 'memoize-one'
import React from 'react'
import injectSheet from 'react-jss'
import { Value } from 'react-values'

import { fetchStoreGroups, fetchEnabledStores } from 'api'
import {
  Button,
  DataTable,
  Dropdown,
  MultiSelectFilterable,
  PageHeader,
  SearchInput,
  ShowIfAuthorized,
  StatusIndicatorDot,
} from 'components'
import { generateBackButton, getRoutePathname } from 'routing'
import { formatDate, ItemsFetcher, ItemsFetcherWithParams } from 'utils'

const styles = theme => ({
  rightOfHeaderContainer: {
    display: 'flex',
    width: 790,

    '& > *': {
      marginBottom: 0,
      marginLeft: theme.spacing.md,
    },
  },
  // TODO: Create a new Styling component for this flex basis use case
  filterStatus: {
    flexBasis: '90px',
  },
  filterStores: {
    flexBasis: '200px',
  },
  filterSearch: {
    flexBasis: '320px',
  },
})

const PAGE_ID = 'store-groups'

const DEFAULT_ITEMS_LIMIT = 20

const tableParams = {
  paging: {
    type: 'multi',
    multiParams: { limit: { key: 'limit' }, offset: { key: 'offset' } },
  },
  search: { key: 'search' },
  sorting: {
    type: 'multi',
    multiParams: { direction: { key: 'direction' }, orderBy: { key: 'order_by' } },
  },
  status: { key: 'status' },
  includesStoreIds: { key: 'store_id', type: 'array' },
}

const filterOptions = {
  status: [
    { id: 'clear', label: 'Any Type', value: null },
    { id: 'active', label: 'Active', value: 'true' },
    { id: 'archived', label: 'Archived', value: 'false' },
  ],
}

const tableHeaders = [
  {
    key: 'status',
    disableSorting: true,
  },
  {
    key: 'name',
    header: 'Name',
  },
  {
    key: 'count',
    header: '# of Stores',
  },
  {
    key: 'created',
    header: 'Created',
  },
]

const formatTableRows = memoize((items, location) =>
  items.map(storeGroup => {
    const { id, name, count, created, status } = storeGroup

    return {
      id: `store-group-${id}`,
      rowLinkTo: {
        pathname: getRoutePathname('storeGroups.storeGroup', { id }),
        state: { backButton: generateBackButton('storeGroups', location) },
      },
      status: <StatusIndicatorDot compact isActive={status} />,
      name,
      count, // # of stores in store group
      created: formatDate(created),
    }
  })
)

const generateStoreMultiselectOptions = stores =>
  stores.map(store => ({
    id: `store-${store.id}`,
    label: store.name,
    value: `${store.id}`, // cast to string to ensure matches with store id param
  }))

const StoreGroups = ({ classes, history, location }) => (
  <ItemsFetcherWithParams
    queryKey="storeGroups"
    defaultItemsLimit={DEFAULT_ITEMS_LIMIT}
    fetchItems={fetchStoreGroups}
    routingParams={{
      location,
      history,
    }}
    paramDefinitions={tableParams}
    render={({
      params: tableParams,
      items: storeGroups,
      itemCount: storeGroupCount,
      isLoadingItems: isFetchingStoreGroups,
      error: errorFetchingStoreGroups,
    }) => {
      const tableRows = formatTableRows(storeGroups, location)

      // Paging props
      const resultsPerPage =
        parseInt(tableParams.paging.multiParams.limit.value, 10) || DEFAULT_ITEMS_LIMIT
      const pageNumber = (tableParams.paging.multiParams.offset.value || 0) / resultsPerPage + 1

      return (
        <div>
          <PageHeader headerTitle="Store Groups">
            <Value defaultValue={tableParams.search.value || ''}>
              {({ set: handleChange, value }) => (
                <div className={classes.rightOfHeaderContainer}>
                  <Dropdown
                    className={classes.filterStatus}
                    id={`${PAGE_ID}-filter-status`}
                    items={filterOptions.status}
                    onChange={tableParams.status.onChange}
                    placeholder="Status"
                    selectedValue={tableParams.status.value}
                  />
                  <ItemsFetcher
                    queryKey="enabledStores"
                    fetchItems={fetchEnabledStores}
                    render={({ items: stores, isLoadingItems: isFetchingStores }) => {
                      // TODO: Move this to MultiSelectFilterable in the form of another prop
                      const storeMultiselectOptions = generateStoreMultiselectOptions(stores)
                      return (
                        <MultiSelectFilterable
                          className={classes.filterStores}
                          id={`${PAGE_ID}-filter-stores`}
                          isLoading={isFetchingStores}
                          items={storeMultiselectOptions}
                          noItemsMsg="No stores found"
                          onChange={tableParams.includesStoreIds.onChange}
                          placeholder="Included Stores"
                          selectedValues={tableParams.includesStoreIds.value}
                        />
                      )
                    }}
                  />
                  <SearchInput
                    className={classes.filterSearch}
                    id={`${PAGE_ID}-filter-search`}
                    onClear={oldInputValue => {
                      // If the user clears the search input containing the current search param,
                      // reload the page with the search param cleared
                      if (oldInputValue === tableParams.search.value) {
                        tableParams.search.onChange(null)
                      }
                    }}
                    onChange={handleChange}
                    onSubmit={tableParams.search.onChange}
                    placeholder="Search by store group name"
                    value={value}
                  />
                  <ShowIfAuthorized requiredPermission="store_groups.create">
                    <Button
                      href={{
                        pathname: getRoutePathname('storeGroups.createStoreGroup'),
                        state: { backButton: generateBackButton('storeGroups', location) },
                      }}
                      id={`${PAGE_ID}-create`}
                    >
                      Create New
                    </Button>
                  </ShowIfAuthorized>
                </div>
              )}
            </Value>
          </PageHeader>
          <DataTable
            error={errorFetchingStoreGroups}
            headers={tableHeaders}
            id={`${PAGE_ID}-table`}
            sortHeaderKey={tableParams.sorting.multiParams.orderBy.value}
            sortDirection={tableParams.sorting.multiParams.direction.value}
            isLoadingNewRows={isFetchingStoreGroups}
            onSortBy={({ nextSortHeaderKey, nextSortDirection }) => {
              tableParams.sorting.onChange({
                orderBy: nextSortHeaderKey,
                direction: nextSortDirection,
              })
            }}
            rows={tableRows}
            pagingProps={{
              pageNumber,
              resultsPerPage,
              onPagingChange: ({ pageNumber, resultsPerPage }) => {
                tableParams.paging.onChange({
                  limit: resultsPerPage,
                  offset: (pageNumber - 1) * resultsPerPage || undefined,
                })
              },
              resultsTotal: storeGroupCount,
            }}
          />
        </div>
      )
    }}
  />
)

export default injectSheet(styles)(StoreGroups)
