import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import injectSheet from 'react-jss'

import { Icon } from 'components'

import styles from './ListBoxSelection.styles'

/**
 * `ListBoxSelection` is used to provide controls for clearing a selection, in
 * addition to conditionally rendering a badge if the control has more than one
 * selection.
 */
const ListBoxSelection = ({
  classes,
  className,
  clearSelection,
  description,
  isDisabled,
  selectionCount,
  setRef,
}) => {
  // We want to differentiate between when this is just an "X" to clear the filter
  // input text, or if this is a multi-select badge with the # of selected items
  const isMultiSelectBadge = selectionCount > 0

  const handleClick = event => {
    if (!isDisabled) {
      // If we have a multi-select badge, clicking it shouldn't open the menu back
      // up. However, if we have a clear badge then we want the click to have this
      // behavior.
      if (isMultiSelectBadge) {
        event.stopPropagation()
      }
      clearSelection(event)
    }
  }
  const handleKeyDown = event => {
    if (!isDisabled) {
      // When a user hits SPACE or ENTER, we'll clear the selection
      if (event.key === 'Enter') {
        clearSelection(event)
      } else if (event.key === ' ') {
        // Prevent Space from changing input value
        event.persist()
        event.preventDownshiftDefault = true
        clearSelection(event)
      }
    }
  }
  const descriptionToUse =
    description || (isMultiSelectBadge ? 'Clear all selected items' : 'Clear selected item')
  return (
    <div
      role="button"
      className={cx(className, classes.listBoxSelection, {
        [classes.listBoxSelectionDisabled]: isDisabled,
        [classes.listBoxSelectionMulti]: isMultiSelectBadge,
      })}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      {...(setRef ? { ref: setRef } : {})}
      tabIndex="0"
      title={descriptionToUse}
    >
      {isMultiSelectBadge && selectionCount}
      <Icon
        className={classes.listBoxSelectionClearIcon}
        description={descriptionToUse}
        focusable="false"
        name="close"
      />
    </div>
  )
}

ListBoxSelection.propTypes = {
  className: PropTypes.string,
  /**
   * Specify a function to be invoked when a user interacts with the clear
   * selection element.
   */
  clearSelection: PropTypes.func.isRequired,
  /**
   * Description provided by consumer to overwrite those used for ListBox.
   */
  description: PropTypes.string,
  isDisabled: PropTypes.bool,
  /**
   * Specify an optional `selectionCount` value that will be used to determine
   * whether the selection should display a badge with that count;
   * if the count is 0 or undefined, we just show a "clear" icon.
   */
  selectionCount: PropTypes.number,
  /**
   * Function passed in by consumer component to access ListBoxSelection ref
   */
  setRef: PropTypes.func,
}

ListBoxSelection.defaultProps = {
  selectionCount: 0,
}

ListBoxSelection.displayName = 'ListBoxSelection'

export default injectSheet(styles)(ListBoxSelection)
