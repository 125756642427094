import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Formik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'

import { fetchSubscriptions, createCustomerSubscription, updateCustomerSubscription } from 'api'
import { createToast } from 'modules/toasts'
import Styling, { ModalHeader } from 'styling/components'
import { useItemsFetcher } from 'utils'
import { FormFieldItem, Dropdown, Button, DropdownDate, Notification } from 'components'

const FORM_ID = 'subscription-form'

const styles = theme => ({
  globalNotification: {
    marginBottom: theme.spacing.lg,
  },
})

const validationSchema = yup.object().shape({
  enterpriseSubscriptionId: yup.number().required('Subscription Plan is a required field'),
  expiry: yup.string().required('Time period is required'),
})

const SubscriptionForm = ({
  classes,
  closeModal,
  createToast,
  mode,
  customerId,
  editingSubscription,
}) => {
  const {
    items: enterpriseSubscriptions,
    isLoadingItems: isFetchingEnterpriseSubscriptions,
  } = useItemsFetcher('enterprise_subscriptions', () => fetchSubscriptions())

  const handleSubmit = async (values, form) => {
    const { enterpriseSubscriptionId, expiry } = values
    const subscriptionAction =
      mode === 'create' ? createCustomerSubscription : updateCustomerSubscription
    try {
      await subscriptionAction(customerId, {
        enterprise_subscription_id: enterpriseSubscriptionId,
        expiry,
      })
      const plan = enterpriseSubscriptions.find(plan => plan.id === enterpriseSubscriptionId)
      createToast({
        kind: 'success',
        message: `Subscription for ${plan.name} has been successfully ${
          mode === 'create' ? 'created' : 'updated'
        }.`,
      })
      closeModal({ requiresRefetch: true })
    } catch (error) {
      form.setSubmitting(false)
      form.setErrors({ global: error.message })
    }
  }

  return (
    <Fragment>
      <ModalHeader>{mode === 'create' ? 'Create Subscription' : 'Edit Subscription'}</ModalHeader>
      <Formik
        initialValues={{
          enterpriseSubscriptionId: editingSubscription?.enterpriseSubscriptionId,
          expiry: editingSubscription?.expiry,
        }}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={validationSchema}
        render={({ handleSubmit, setFieldValue, isSubmitting, errors }) => (
          <form onSubmit={handleSubmit}>
            {errors.global && !isSubmitting && (
              <Notification
                className={classes.globalNotification}
                title="Error"
                kind="error"
                message={errors.global}
              />
            )}
            <Styling.LineOfItems>
              <FormFieldItem
                id={`${FORM_ID}-enterprise-subscription-id`}
                labelText="Subscription Plan"
                name="enterpriseSubscriptionId"
                render={({ value, ...renderProps }) => (
                  <Dropdown
                    {...renderProps}
                    disabled={isSubmitting}
                    isLoading={isFetchingEnterpriseSubscriptions}
                    items={enterpriseSubscriptions.map(plan => ({
                      id: `enterprise-subscription-plan-${plan.id}`,
                      label: plan.name,
                      value: plan.id,
                    }))}
                    onChange={value => {
                      setFieldValue('enterpriseSubscriptionId', value)
                      const plan = enterpriseSubscriptions.find(plan => plan.id === value)
                      if (plan.days) {
                        setFieldValue(
                          'expiry',
                          moment()
                            .add('days', plan.days + 1)
                            .toString()
                        )
                      }
                    }}
                    placeholder="Select Subscription"
                    selectedValue={value}
                  />
                )}
              />

              <FormFieldItem
                id={`${FORM_ID}-expiry`}
                labelText="Time Period"
                name="expiry"
                render={({ value, ...renderProps }) => (
                  <DropdownDate
                    {...renderProps}
                    disabled={isSubmitting}
                    fixedStartDate
                    returnDatetimeValues
                    defaultValue={{
                      from: new Date().toString(),
                      to: value,
                    }}
                    onChange={({ to }) => {
                      setFieldValue('expiry', to)
                    }}
                    placeholder="Select Time Period"
                  />
                )}
              />
            </Styling.LineOfItems>

            <Styling.LineOfButtons withoutTopMargin>
              <Button type="submit" disabled={isSubmitting}>
                {mode === 'create' ? 'Add Subscription' : 'Save Changes'}
              </Button>
              <Button kind="link" onClick={closeModal}>
                Cancel
              </Button>
            </Styling.LineOfButtons>
          </form>
        )}
      />
    </Fragment>
  )
}

SubscriptionForm.propTypes = {
  classes: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  createToast: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['create', 'edit']).isRequired,
  customerId: PropTypes.number.isRequired,
  editingSubscription: PropTypes.shape({
    enterpriseSubscriptionId: PropTypes.number,
    expiry: PropTypes.string,
  }),
}

const mapDispatchToProps = dispatch => bindActionCreators({ createToast }, dispatch)

export default injectSheet(styles)(connect(null, mapDispatchToProps)(SubscriptionForm))
