import React, { useState } from 'react'
import injectSheet from 'react-jss'

import { Button, FormFieldItem, FormLabel, ImageUpload, TextInput } from 'components'
import { uploadImage } from 'api'

import styles from '../PromotedPlacementsForm.styles'

const PlacementImageItem = ({
  classes,
  componentId,
  handleAdd = () => {},
  secondaryImageEnabled = false,
  width = 500,
  height = 500,
  secondaryWidth = 500,
  secondaryHeight = 500,
  imageLabel = 'Tile Image - Desktop',
  secondaryImageLabel = 'Tile Image - Mobile',
  setFieldValue,
  setErrors,
  setFieldError,
  values: { config },
}) => {
  const [label, setLabel] = useState('')
  const [url, setURL] = useState('')
  const [image, setImage] = useState(null)
  const [secondaryImage, setSecondaryImage] = useState(null)
  const [altText, setAltText] = useState('')
  // This is a hacky solution to reset ImageUpload's internal state
  // TODO: Refactor ImageUpload, ImageUploadAlt, etc. to support both controlled/uncontrolled
  const [uploadKey, setUploadKey] = useState(0)
  const id = `${componentId}-item-image`
  return (
    <>
      <FormLabel htmlFor={id} labelText={'Add Tile'} />
      <div className={classes.inlineFormGroup}>
        <FormFieldItem
          id={id}
          labelText={'Label'}
          name="image-item-label"
          orientation={'horizontal'}
          render={renderProps => (
            <TextInput
              {...renderProps}
              value={label}
              onChange={({ target: { value } }) => setLabel(value)}
              className={classes.formInput}
              placeholder={'Enter label'}
              type="text"
            />
          )}
        />

        <FormFieldItem
          id={`${componentId}-destination`}
          className={classes.inlineImageDestination}
          labelText={'Destination URL'}
          name="image-item-destination"
          orientation={'horizontal'}
          render={renderProps => (
            <TextInput
              {...renderProps}
              value={url}
              onChange={({ target: { value } }) => setURL(value)}
              className={classes.formInput}
              placeholder={'Link URL'}
              type="text"
            />
          )}
        />
      </div>
      <FormFieldItem
        id={`${componentId}-item-image`}
        className={classes.formRow}
        name="image-item-image"
        labelText={imageLabel}
        render={({ id, name }) => (
          <div className={classes.imageUploadNarrowContainer}>
            <ImageUpload
              key={`image-primary-${uploadKey}`}
              id={id}
              initialImage={image}
              width={width}
              height={height}
              validateSize={false}
              fileRequirementsText={`Recommended Dimensions - Width: ${width} x Height: ${height}`}
              onImageUpload={uploadImage}
              onImageUploadComplete={img => {
                setImage(img)
              }}
              onUploadError={error => {
                setFieldError(name, error)
              }}
            />
          </div>
        )}
      />
      {secondaryImageEnabled && (
        <FormFieldItem
          id={`${componentId}-item-image-secondary`}
          className={classes.formRow}
          name="image-item-image-secondary"
          labelText={secondaryImageLabel}
          render={({ id, name }) => (
            <div className={classes.imageUploadNarrowContainer}>
              <ImageUpload
                key={`image-secondary-${uploadKey}`}
                id={id}
                initialImage={secondaryImage}
                width={secondaryWidth}
                height={secondaryHeight}
                validateSize={false}
                fileRequirementsText={`Recommended Dimensions - Width: ${secondaryWidth} x Height: ${secondaryHeight}`}
                onImageUpload={uploadImage}
                onImageUploadComplete={img => {
                  setSecondaryImage(img)
                }}
                onUploadError={error => {
                  setFieldError(name, error)
                }}
              />
            </div>
          )}
        />
      )}
      <FormFieldItem
        id={`${componentId}-alt`}
        labelText="Alt Text"
        name="image-item-alt"
        render={renderProps => (
          <TextInput
            {...renderProps}
            value={altText}
            onChange={({ target: { value } }) => setAltText(value)}
            className={classes.formInput}
            placeholder={'Enter alt text'}
            type="text"
          />
        )}
      />
      <Button
        kind="secondary"
        onClick={() => {
          // Validate that we should add image
          setErrors({})
          if (!image) {
            setFieldError('image-item-image', 'Image is required')
            return
          }
          if (secondaryImageEnabled && !secondaryImage) {
            setFieldError('image-item-image-secondary', 'Image is required')
            return
          }
          if (!altText) {
            setFieldError('image-item-alt', 'Alt text is required')
            return
          }

          // use handler to reformat as desired, pass current state and save function
          handleAdd({ image, secondaryImage, label, altText, url }, config, setFieldValue)

          // Reset everything
          setImage(null)
          setSecondaryImage(null)
          setAltText('')
          setLabel('')
          setURL('')
          setUploadKey(uploadKey + 1)
        }}
      >
        Add
      </Button>
    </>
  )
}

export default injectSheet(styles)(PlacementImageItem)
