// The following was forked from https://github.com/react-component/tree-select

import PropTypes from 'prop-types'
import React, { Component } from 'react'
import injectSheet from 'react-jss'
import RcTree from 'rc-tree'

import styles, { TREE_CLASS_PREFIX } from './Tree.styles'

class Tree extends Component {
  static propTypes = {
    noResultsFoundString: PropTypes.string.isRequired,
    /**
     * Method used by Tree component to add a filtered class to TreeNode > li
     */
    filterTreeNode: PropTypes.func,
    checkedKeys: PropTypes.array.isRequired,
    halfCheckedKeys: PropTypes.array.isRequired,
    expandedKeys: PropTypes.array.isRequired,
    treeNodes: PropTypes.node,
    onTreeNodeCheck: PropTypes.func.isRequired,
    onTreeNodeExpand: PropTypes.func.isRequired,
  }

  static defaultProps = {
    noResultsFoundString: 'No results found',
  }

  renderNotFound = () => {
    const { classes, noResultsFoundString } = this.props

    return <span className={classes.noResultsFoundMsg}>{noResultsFoundString}</span>
  }

  render() {
    const {
      classes,
      checkedKeys,
      filterTreeNode,
      halfCheckedKeys,
      expandedKeys,
      treeNodes,
      onTreeNodeCheck,
      onTreeNodeExpand,
    } = this.props

    if (treeNodes && treeNodes.length > 0) {
      return (
        <RcTree
          className={classes.tree}
          prefixCls={TREE_CLASS_PREFIX}
          showIcon={false}
          showLine={false}
          selectable={false}
          checkable
          checkStrictly
          expandedKeys={expandedKeys}
          filterTreeNode={filterTreeNode}
          onCheck={onTreeNodeCheck}
          onExpand={onTreeNodeExpand}
          checkedKeys={{
            checked: checkedKeys,
            halfChecked: halfCheckedKeys,
          }}
        >
          {treeNodes}
        </RcTree>
      )
    }
    return this.renderNotFound()
  }
}

export default injectSheet(styles)(Tree)
