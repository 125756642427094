import { Formik } from 'formik'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as yup from 'yup'

import { createStoreGroup, updateStoreGroup, fetchStoreGroup } from 'api'
import { LoadingIndicator, Notification, PageHeader } from 'components'
import { createToast } from 'modules/toasts'
import { getBackButton, getRouteTo } from 'routing'
import Styling from 'styling/components'
import { get, ItemFetcher } from 'utils'

import StoreGroupForm from './StoreGroupForm'

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createToast,
    },
    dispatch
  )

const CreateOrEditStoreGroup = connect(
  null,
  mapDispatchToProps
)(
  ({
    backButton,
    createToast,
    headerTitle,
    initialValues = { name: '', stores: [] },
    history,
    onFormSubmit,
    successMessage,
  }) => (
    <div>
      <PageHeader backButton={backButton} hasDivider headerTitle={headerTitle} />
      <Formik
        initialValues={initialValues}
        validationSchema={() =>
          yup.object().shape({
            name: yup
              .string()
              .trim()
              .label('Store Group Name')
              .required(),
            stores: yup.array().label('Stores'),
          })
        }
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values, form) => {
          onFormSubmit(values)
            .then(storeGroup => {
              form.setSubmitting(false)
              createToast({ kind: 'success', message: successMessage })

              // Send the user to the edited or newly created store group page
              history.push(getRouteTo('storeGroups.storeGroup', { id: storeGroup.id }))
            })
            .catch(error => {
              form.setSubmitting(false)
              form.setErrors({ global: error.message })
            })
        }}
        render={renderProps => (
          <StoreGroupForm {...renderProps} cancelDestination={backButton.to} />
        )}
      />
    </div>
  )
)

export const CreateStoreGroup = ({ location, ...restProps }) => {
  /**
   * - `duplicateId` is passed in when we want to open the Create Store Group page but with it filled
   * with the data from the store group represented by that id
   */
  const duplicateId = get(location, 'state.duplicateId')

  const propsToPass = {
    backButton: get(location, 'state.backButton'),
    headerTitle: 'Create Store Group',
    onFormSubmit: formValues => createStoreGroup(formValues),
    successMessage: 'Successfully created store group.',
    ...restProps,
  }

  if (duplicateId) {
    return (
      <ItemFetcher
        queryKey="storeGroup"
        queryParams={{ duplicateId }}
        fetchItem={config => fetchStoreGroup(duplicateId, config)}
        render={({
          item: storeGroup,
          isLoadingItem: isFetchingStoreGroup,
          error: errorFetchingStoreGroup,
        }) => {
          if (isFetchingStoreGroup) {
            return <LoadingIndicator withTopMargin />
          }
          if (errorFetchingStoreGroup) {
            return (
              <Styling.Center maxWidth={500} withTopMargin>
                <Notification kind="error" message={errorFetchingStoreGroup.message} />
              </Styling.Center>
            )
          }
          if (storeGroup) {
            propsToPass.backButton =
              propsToPass.backButton ||
              getBackButton('storeGroups.storeGroup', { id: storeGroup.id, label: storeGroup.name })
            propsToPass.initialValues = { name: storeGroup.name, stores: storeGroup.stores || [] }

            return <CreateOrEditStoreGroup {...propsToPass} />
          }
        }}
      />
    )
  }
  propsToPass.backButton = propsToPass.backButton || getBackButton('storeGroups')

  return <CreateOrEditStoreGroup {...propsToPass} />
}

export const EditStoreGroup = ({
  location,
  match: {
    params: { storeGroupId },
  },
  ...restProps
}) => (
  <ItemFetcher
    queryKey="storeGroup"
    queryParams={{ id: storeGroupId }}
    fetchItem={config => fetchStoreGroup(storeGroupId, config)}
    render={({ item: storeGroup, isLoadingItem: isFetchingStoreGroup }) => {
      if (isFetchingStoreGroup) {
        return <LoadingIndicator withTopMargin />
      }
      if (!storeGroup) return null
      const propsToPass = {
        backButton:
          get(location, 'state.backButton') ||
          getBackButton('storeGroups.storeGroup', { id: storeGroup.id, label: storeGroup.name }),
        headerTitle: 'Edit Store Group',
        initialValues: { name: storeGroup.name, stores: storeGroup.stores || [] },
        onFormSubmit: formValues => updateStoreGroup(storeGroupId, formValues),
        successMessage: 'Successfully updated store group.',
        ...restProps,
      }

      return <CreateOrEditStoreGroup {...propsToPass} />
    }}
  />
)
