import defaultConfig from '@instacart/enterprise-configs/frontend/dist/defaults/config.json'
import { deepOverrideConfig, handleMeta } from '@instacart/enterprise-config-utils'
import { version as enterpriseConfigVersion } from '@instacart/enterprise-configs/package.json'

import { PRD_MODE, UAT_MODE, getAPIPath } from './parseOptions'

// Environment variables coming from kubernetes will be added through the window var
// If they don't exist, fallback to the process.env settings (vm/development)
// If not, use some defaults
// eslint-disable-next-line no-underscore-dangle
const windowEnv = window._env_ || {}

let client
let mode
let variant
let match
if (!match) {
  // Try to parse dashboardv2-uat-gar.unataops.com
  match = location.host.match(/^dashboardv2-(.+?)-(.+?)\./)
  if (match) {
    ;[, mode, client] = match
  }
}
if (!match) {
  // Try to parse uat-gar-platform-master-dashboard-v2.unataops.com
  match = location.host.match(/^(.+?)-(.+?)-platform-(.+?)-dashboard-/)
  if (match) {
    ;[, mode, client, variant] = match
  }
}

const dashboardConfigMode = windowEnv.mode || mode || process.env.REACT_APP_MODE || UAT_MODE
const dashboardConfigDefaultAPIPath = getAPIPath(dashboardConfigMode)
const dashboardConfig = {
  $enterpriseConfigVersion: enterpriseConfigVersion,
  client: windowEnv.client || client || process.env.REACT_APP_CLIENT || 'gar',
  deployTime: windowEnv.timestamp || process.env.REACT_APP_BUILD_TIMESTAMP,
  disableLegacyViews:
    (windowEnv.disableLegacyViews || process.env.REACT_APP_DISABLE_LEGACY_VIEWS) === 'true' ||
    false,
  env: {
    apiPath: windowEnv.apiPath || process.env.REACT_APP_API_PATH || dashboardConfigDefaultAPIPath,
    mode: dashboardConfigMode,
    variant: windowEnv.variant || variant || process.env.REACT_APP_VARIANT || 'master',
    webUrl:
      windowEnv.webUrl ||
      process.env.REACT_APP_WEB_URL ||
      'https://uat-gar-platform-master-web.unataops.com',
  },
  version: windowEnv.version || process.env.REACT_APP_RELEASE_VERSION || 'dev',
}

const config = {}

export const overrideConfig = overrides => {
  handleMeta(
    overrides,
    dashboardConfig.version,
    dashboardConfig.env.mode,
    dashboardConfig.client,
    dashboardConfig.env.variant
  )
  deepOverrideConfig(config, overrides)
}

overrideConfig(defaultConfig)
overrideConfig(dashboardConfig)

export default config

export const fetchFrontendConfigScript = () => {
  return new Promise((resolve, reject) => {
    const url = `${dashboardConfig.env.webUrl}/frontend-config.js`
    const error = new Error(`Failed to load ${url}`)

    const script = document.createElement('script')
    script.src = url
    script.addEventListener('error', () => {
      reject(error)
    })
    script.addEventListener('load', () => {
      const config =
        // eslint-disable-next-line no-underscore-dangle
        window.__UNATA_FRONTEND_CONFIG__
      if (config) {
        resolve(config)
      } else {
        reject(error)
      }
    })
    document.head.append(script)
  })
}

export const getEnvStatusBadgeTypeAndText = () => {
  const environment = config.env.mode

  const text = environment.toUpperCase()
  let type
  switch (environment) {
    case PRD_MODE:
      type = 'complete'
      break
    case UAT_MODE:
    case 'stg':
      type = 'in-progress'
      break
    case 'dev':
      type = 'cancelled'
      break
    default:
      break
  }
  return { type, text }
}
