import { vars } from 'styling'

export default theme => ({
  tableRow: {
    backgroundColor: '#FFFFFF',
    '&:hover $selectCheckbox': {
      opacity: 1,
    },
    '&:hover $dragColumnCellIcon': {
      opacity: 1,
    },
    '& > td': {
      position: 'relative',
    },
    '& > td > .row-separator': {
      position: 'absolute',
      top: '-17px',
      backgroundColor: '#fff',
      padding: '4px',
      margin: '4px',
    },
  },
  rowSelected: {
    fontWeight: 600,

    '&, &:hover': {
      backgroundColor: `${theme.bgSelected} !important`,
    },
  },
  rowDragging: {
    backgroundColor: '#F6F8F8 !important',
    boxShadow: '0 3px 6px 0 rgba(142,142,142,0.5)',
    display: 'table',
    fontWeight: 600,

    '& $dragColumnCellIcon': {
      opacity: 1,
    },
  },
  selectCheckbox: {},
  expandableRowParent: {
    '&$expandableRowParentHovered': {
      cursor: 'pointer',
    },

    '&$expandableRowParentHovered, &$expandableRowParentExpanded': {
      '& > td:not($expandableRowActionCol)': {
        color: theme.brand01,
      },
    },
  },
  expandableRowParentHovered: {},
  expandableRowParentExpanded: {
    fontWeight: 600,

    '& > td': {
      // Overwrite border bottom from Table styles
      borderBottom: '0 !important',
    },
  },
  expandableRowParentIconButton: {
    background: 'none',
    border: 0,
    cursor: 'pointer',
    padding: 0,
  },
  expandableRowParentIcon: {
    fill: theme.text01,
    height: 8,
    transition: `transform ${vars.transition.duration.base} ${vars.transition.timingFunction.standardEasing}`,
    width: 8,

    '$expandableRowParentHovered &, $expandableRowParentExpanded &': {
      fill: theme.brand01,
      transform: 'rotate(90deg)',
    },
  },
  expandableRowActionCol: {},
  expandableRowDetails: {
    '& > td': {
      background: theme.bgGreyExtraLight,
      borderBottom: '0 !important', // overwrite Table styles
      borderLeft: `1px solid ${theme.borderGreyLight}`,
      borderRight: `1px solid ${theme.borderGreyLight}`,
      padding: '0 !important', // overwrite Table styles
    },
    // Height of row will be shown through height of its content
    height: '0 !important', // overwrite Table styles
  },
  expandableSelectableRowDetails: {
    /**
     * Add extra left padding to align row details content past the expandColumnCell AND the selectColumnCell
     * (in line with the next cell's contents in the expandableRowParent)
     */
    '& > td': {
      paddingLeft: `${theme.spacing.lg} !important`,
    },
  },
  expandableRowDetailsContent: {
    overflow: 'hidden',
    /**
     * Add extra left (and matching right) padding to align row details content past the expandColumnCell
     * (in line with the next cell's contents in the expandableRowParent)
     */
    padding: `${theme.spacing.sm} ${theme.spacing.xxlg}`,
  },
  // Transitions for show/collapse of `expandableRowDetails`
  // Classes applied to `expandableRowDetailsContent`
  expandableRowOpen: {
    maxHeight: 0,
    opacity: 0,
    padding: `0 ${theme.spacing.xxlg}`,
  },
  expandableRowOpenActive: {
    maxHeight: '100%',
    opacity: 1,
    padding: `${theme.spacing.sm} ${theme.spacing.xxlg}`,
    transition: `padding 300ms ${vars.transition.timingFunction.easeIn}, max-height 300ms ${vars.transition.timingFunction.easeIn}, opacity 300ms ${vars.transition.timingFunction.easeIn} 300ms`,
  },
  expandableRowClose: {
    opacity: 1,
    padding: `${theme.spacing.sm} ${theme.spacing.xxlg}`,
  },
  expandableRowCloseActive: {
    opacity: 0,
    padding: `0 ${theme.spacing.xxlg}`,
    transition: `padding 200ms ${vars.transition.timingFunction.easeOut}, opacity 200ms ${vars.transition.timingFunction.easeOut}`,
  },

  linkRowHovered: {
    cursor: 'pointer',

    '& $linkRowLinkIcon': {
      opacity: 1,
    },
  },
  linkRowLinkIconContainer: {
    '&:focus > $linkRowLinkIcon': {
      opacity: 1,
    },
  },
  linkRowLinkIcon: {
    fill: theme.brand01,
    height: 8,
    opacity: 0,
    width: 8,
  },
  dragColumnCell: {
    boxSizing: 'content-box',
    paddingRight: `${theme.spacing.xxs} !important`,
    width: 16,

    '&:first-child': {
      paddingLeft: `${theme.spacing.sm} !important`,
    },
  },
  dragColumnCellIcon: {
    fill: '#4A4A4A',
    height: 16,
    opacity: 0,
    width: 12,
  },
  selectColumnCell: {
    boxSizing: 'content-box',
    paddingRight: `0 !important`,
    width: 16,

    '&:first-child': {
      paddingLeft: `${theme.spacing.sm} !important`,
    },
  },
  expandColumnCell: {
    boxSizing: 'content-box',
    paddingRight: '0 !important',
    textAlign: 'center !important',
    width: 17,

    '&:first-child': {
      paddingLeft: `${theme.spacing.sm} !important`,
    },

    '$selectColumnCell + &': {
      paddingLeft: `${theme.spacing.xxs} !important`,
    },
  },
  actionColumnCell: {
    boxSizing: 'content-box',
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
    textAlign: 'center !important',
    width: 32,

    '&:last-child': {
      paddingRight: `${theme.spacing.sm} !important`,
    },

    '$rowSelected > &, $expandableRowParentExpanded > &': {
      fontWeight: 400, // make sure any action dropdown items aren't in bold
    },
  },
  linkColumnCell: {
    boxSizing: 'content-box',
    textAlign: 'center !important',
    width: 24,

    '&:last-child': {
      paddingRight: `${theme.spacing.sm} !important`,

      '$actionColumnCell + &': {
        paddingLeft: `${theme.spacing.xxs} !important`,
      },
    },
  },
})
