import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { getUserPermissionKeys } from 'modules/user'

const ShowIfAuthorized = ({
  children,
  determineAuthorization,
  requiredPermission,
  unauthorizedFallback,
  userPermissions,
}) => {
  /**
   * If the consuming component does not provide either `determineAuthorization` or `requiredPermission`,
   * we simply assume they are authorized and show the children.
   */
  let isAuthorized = true

  if (determineAuthorization) {
    isAuthorized = determineAuthorization({ userPermissions })
  } else if (requiredPermission) {
    isAuthorized = Array.isArray(requiredPermission)
      ? requiredPermission.reduce(
          (isAuthorized, permission) => isAuthorized && userPermissions.includes(permission),
          true
        )
      : userPermissions.includes(requiredPermission)
  }

  return isAuthorized ? children : unauthorizedFallback || null
}

ShowIfAuthorized.propTypes = {
  children: PropTypes.node.isRequired,
  determineAuthorization: PropTypes.func,
  requiredPermission: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  unauthorizedFallback: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.number]),
}

const mapStateToProps = state => ({
  userPermissions: getUserPermissionKeys(state),
})

export default connect(mapStateToProps)(ShowIfAuthorized)
