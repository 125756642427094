import { Formik } from 'formik'
import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { cart } from '@instacart/enterprise-services'

import { Button, PageHeader } from 'components'
import Styling from 'styling/components'

import { createCateringUser, createCateringOrder } from 'api'
import { get, snakeToCamelCase } from 'utils'
import { getBackButton, getRouteTo } from 'routing'
import { createToast } from 'modules/toasts'

import Stores from './CreateOrderSections/Stores'
import Guest from './CreateOrderSections/Guest'
import Totals from './CreateOrderSections/Totals'
import Products from './CreateOrderSections/Products'

import styles from './CreateOrder.styles'

const useStyles = createUseStyles(styles)

const errorMap = {
  selectedStore: 'Plase select a store',
  customer: 'Please select an existing customer, or create a new one',
  newCustomerPhone: 'Please enter a customer phone number',
  newCustomerEmail: 'Please enter a valid customer email address',
  newCustomerFirstName: 'Please enter a customer first name',
  newCustomerLastName: 'Please enter a customer last name',
  selectedTimeslot: 'Please select a timeslot',
}

const CreateOrder = ({ createToast, history, location }) => {
  const classes = useStyles()

  // Get the backButton link - should contain query param state
  const backButton = get(location, 'state.backButton') || getBackButton('cateringOrders')

  const [totals, setTotals] = useState([])

  // Pop a toast with the error and add it to Formik state
  const setError = key => {
    createToast({ kind: 'error', message: errorMap[key] })
    return { [key]: errorMap[key] }
  }

  // Form validation handler
  const onValidate = values => {
    if (!values.selectedStore) {
      return setError('selectedStore')
    }
    if (!values.customer && !values.creatingCustomer) {
      return setError('customer')
    }
    if (values.creatingCustomer) {
      if (!values.newCustomerPhone) {
        return setError('newCustomerPhone')
      }
      if (!values.newCustomerEmail) {
        return setError('newCustomerEmail')
      }
      if (!values.newCustomerFirstName) {
        return setError('newCustomerFirstName')
      }
      if (!values.newCustomerLastName) {
        return setError('newCustomerLastName')
      }
    }
    if (!values.selectedTimeslot) {
      return setError('selectedTimeslot')
    }
  }

  // Form submit handler
  const onSubmit = async (values, actions) => {
    let newUser

    actions.setSubmitting(true)

    try {
      // Need to create a user
      if (!values.customer) {
        newUser = await createCateringUser({
          firstName: values.newCustomerFirstName,
          lastName: values.newCustomerLastName,
          phoneNumber: values.newCustomerPhone,
          email: values.newCustomerEmail,
        })

        // Set the customer on the form case the next call fails (creating user on subsequent
        // submits will be skipped unless the form is explciitly changed).
        actions.setValues({
          ...values,
          customer: {
            ...newUser,
            primaryNumberKey: 'phoneNumber',
          },
          creatingCustomer: false,
        })
      }

      const customerId = newUser ? newUser.userId : values.customer.id
      const firstName = newUser ? newUser.firstName : values.customer.firstName
      const lastName = newUser ? newUser.lastName : values.customer.lastName
      const phoneNumber = newUser
        ? newUser.phoneNumber
        : values.customer[snakeToCamelCase(values.customer.primaryNumberKey)]

      const order = await createCateringOrder({
        customer_id: customerId,
        store_id: values.selectedStore.id,
        fulfillment_type: 'pickup',
        items: cart.selectors.getItems(),
        timeslot: values.selectedTimeslot.timeslot,
        fulfillment_date: values.selectedTimeslot.fulfillmentDate,
        contact_info: {
          first_name: firstName,
          last_name: lastName,
          phone_number: phoneNumber,
        },
      })

      history.push(getRouteTo('orders.order', { id: order.id }))
      createToast({ kind: 'success', message: 'Successfully created order' })
    } catch (error) {
      createToast({ kind: 'error', message: error.message })
    }

    actions.setSubmitting(false)
  }

  return (
    <Formik
      initialValues={{
        followUpEmailCheckbox: false,
        marketingEmailCheckbox: false,
        createAccountCheckbox: false,
        creatingCustomer: false,
        customer: null,
        newCustomerPhone: null,
        newCustomerEmail: null,
        newCustomerFirstName: null,
        newCustomerLastName: null,
        selectedStore: null,
        selectedTimeslot: null,
        selectedCustomer: null,
      }}
      onSubmit={onSubmit}
      validate={onValidate}
      validateOnBlur={false}
      validateOnChange={false}
      render={formikProps => {
        return (
          <form onSubmit={formikProps.handleSubmit}>
            <PageHeader headerTitle="Create New Catering Order" />
            <Stores
              handleChange={formikProps.handleChange}
              selectedStore={formikProps.values.selectedStore}
            />
            <div className={classes.formContainer}>
              <Guest
                values={formikProps.values}
                errors={formikProps.errors}
                setValues={formikProps.setValues}
                handleChange={formikProps.handleChange}
                isDisabled={!formikProps.values.selectedStore}
              />

              <h2 className={classes.sectionHeader}>Product</h2>
              <Products
                isDisabled={!formikProps.values.selectedStore}
                selectedCustomer={formikProps.values.customer}
                selectedTimeslot={formikProps.values.selectedTimeslot}
                store_id={
                  formikProps.values.selectedStore ? formikProps.values.selectedStore.id : undefined
                }
                shopping_mode="catering"
                setTotals={setTotals}
              />
            </div>
            <Totals totals={totals} />

            <div className={classes.formFooter}>
              <div className={classes.footerContainer}>
                <Styling.LineOfButtons withoutTopMargin>
                  <Button
                    type="submit"
                    disabled={formikProps.isSubmitting || !formikProps.values.selectedStore}
                  >
                    Create Order
                  </Button>
                  <Button kind="link" href={backButton.to}>
                    Cancel
                  </Button>
                </Styling.LineOfButtons>
              </div>
            </div>
          </form>
        )
      }}
    />
  )
}

const mapDispatchToProps = dispatch => bindActionCreators({ createToast }, dispatch)

export default connect(null, mapDispatchToProps)(CreateOrder)
