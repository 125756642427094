import debounce from 'lodash/debounce'
import React, { Component } from 'react'
import injectSheet from 'react-jss'

import { fetchSegments } from 'api'
import { FormFieldItem, DropdownFilterable } from 'components'
import { get } from 'utils'

import styles from '../PromotedPlacementsForm.styles'

const findAndSetSelectedObject = ({
  filteredArray,
  formValue,
  originalArray,
  selectedSegment,
  setFieldValue,
}) => {
  let selectedObject = originalArray.find(item => item.id === selectedSegment)

  // If value doesn't exist in initial list, check the filtered items
  if (!selectedObject && filteredArray) {
    selectedObject = filteredArray.find(item => item.id === selectedSegment)
  }
  setFieldValue(formValue, selectedObject)
}

class PlacementUserSegment extends Component {
  state = {
    segments: [],
    isFetchingSegments: false,
    filteredSegments: null,
  }

  componentDidMount() {
    this.loadSegmentsDropdown()
  }

  formatDropdown(dropdownItems) {
    const formattedDropdownItems = dropdownItems.map(({ id, name, ...restProps }) => ({
      id,
      label: name,
      name,
      value: id,
      ...restProps,
    }))

    return formattedDropdownItems
  }

  getDebouncedStoreGroups = debounce(inputValue => {
    fetchSegments(`?status=true&search=${inputValue}`).then(({ items }) => {
      this.setState({ filteredSegments: items })
    })
  }, 250)

  loadSegmentsDropdown() {
    const {
      values: { customerSegment },
    } = this.props
    const existingCustomerSegmentQuery =
      customerSegment && customerSegment.name ? customerSegment.name : ''

    this.setState({ isFetchingSegments: true })

    fetchSegments(`?status=true&limit=50&search=${existingCustomerSegmentQuery}`)
      .then(({ items }) => {
        this.setState({
          segments: this.formatDropdown(items),
        })
      })
      .finally(() => {
        this.setState({ isFetchingSegments: false })
      })
  }

  render() {
    const { classes, componentId, labelText, placeholder, setFieldValue } = this.props
    const { filteredSegments, isFetchingSegments, segments } = this.state

    return (
      <FormFieldItem
        className={classes.formRow}
        id={componentId}
        labelText={labelText}
        name="customerSegment"
        orientation="horizontal"
        render={({ id, isInvalid, name, value }) => (
          <DropdownFilterable
            className={classes.formInput}
            id={id}
            initialValue={value}
            isLoading={isFetchingSegments}
            items={segments}
            filteredItems={filteredSegments ? this.formatDropdown(filteredSegments) : null}
            onChange={selectedSegment =>
              findAndSetSelectedObject({
                filteredArray: filteredSegments,
                formValue: name,
                originalArray: segments,
                selectedSegment,
                setFieldValue,
              })
            }
            onInputChange={value => this.getDebouncedStoreGroups(value)}
            placeholder={placeholder}
            selectedValue={get(value, 'id')}
          />
        )}
      />
    )
  }
}

export default injectSheet(styles)(PlacementUserSegment)
