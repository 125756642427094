import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import injectSheet from 'react-jss'

import { Icon } from 'components'

import styles from './ListBoxMenuIcon.styles'

/**
 * `ListBoxMenuIcon` is used to orient the icon up or down depending on the
 * state of the menu for a given `ListBox`
 */
const ListBoxMenuIcon = ({ classes, isDisabled, isOpen, theme, ...restProps }) => {
  const className = cx(classes.listBoxMenuIcon, {
    [classes.listBoxMenuIconDisabled]: isDisabled,
    [classes.listBoxMenuIconOpen]: isOpen,
  })
  const description = isOpen ? 'Close menu' : 'Open menu'
  return (
    <div className={className} {...restProps}>
      <Icon className={classes.listBoxMenuIconSvg} description={description} name="chevronDown" />
    </div>
  )
}

ListBoxMenuIcon.propTypes = {
  isDisabled: PropTypes.bool,
  /**
   * Whether the menu is currently open; will affect direction of the menu icon
   */
  isOpen: PropTypes.bool,
}

ListBoxMenuIcon.displayName = 'ListBoxMenuIcon'

export default injectSheet(styles)(ListBoxMenuIcon)
