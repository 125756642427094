import axios from 'axios'
import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { queryString } from 'utils'

import DataTable from './DataTable'
import { normalizeForDataTableWithState } from './state/normalizing'
import { sortStates } from './state/sorting'
import { reorder } from './utils/reorder'

/**
 * DataTableWithState allows for the handling of DataTable state (filtering, sorting, paging, selection
 * across multiple pages) for tables whose entire data is loaded at once (unlike other tables which have so
 * many rows that only a small subset from a given page can be fetched from the BE at one time;
 * in those cases the BE would then also manage things like the filtering and sorting of that data).
 */
export default class DataTableWithStateAsync extends Component {
  static propTypes = {
    /**
     * The function used to fetch the items from the back end that populate each page
     * of the table. (Must accept query parameters for paging (`limit`, `offset`),
     * sorting (`order_by`, `direction`) for columns enabled to do so, and `filtering`
     * (e.g. `search`).
     */
    fetchItems: PropTypes.func.isRequired,
    /**
     * Used to format the response from `fetchItems` into the `rows` passed into
     * `<DataTable>` (and whose keys correspond to those provided in `headers`).
     */
    formatTableRows: PropTypes.func.isRequired,
    /**
     * The `headers` prop represents the order in which the header columns should
     * appear in the table. We expect an array of objects to be passed in, where
     * `key` is the name of the key in a row object, and `header` is the name/label of
     * the header. The `headers` are required for normalizing data and filtering.
     */
    headers: PropTypes.array.isRequired,
    id: PropTypes.string.isRequired,
    initialQueryStateValues: PropTypes.shape({
      filterInputValue: PropTypes.string,
      pageNumber: PropTypes.number,
      resultsPerPage: PropTypes.number,
      sortDirection: PropTypes.oneOf(Object.values(sortStates)),
      sortHeaderKey: PropTypes.string,
    }),
    initialSelectedItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    /**
     * Used to notify the consuming component of changes to the selected state of the table rows.
     */
    onSelectedRowsChange: PropTypes.func,
    /**
     * The `render` function passed into DataTable
     */
    render: PropTypes.func,
    /**
     * Object of props which are accessible in the DataTable's render function
     */
    renderProps: PropTypes.object,
  }

  static defaultProps = {
    initialQueryStateValues: {},
    initialSelectedItems: [],
  }

  // Used to cancel the fetch call on unMount
  cancelTokenSource = axios.CancelToken.source()

  state = {
    // itemsOnPage,
    // totalItemCount,
    // errorFetchingItems

    isLoadingNewRows: false,
    rows: [],
    rowIds: [],
    unformattedRows: [],

    // Filtering
    filterInputValue: '',

    // Paging
    pageNumber: 1,
    resultsPerPage: 20,

    // Selection
    selectedItems: this.props.initialSelectedItems,
    selectedRowIdsOnPage: [], // on data fetch, determine these ids and pass them into <DataTable>

    // Sorting
    sortDirection: sortStates.NONE,
    sortHeaderKey: null,
  }

  componentDidMount() {
    this.fetchItemsAndUpdateQueryParamStateValues(this.props.initialQueryStateValues)
  }

  // On component unmounting, cancel any pending requests so that setState doesn't get called on an unmounted component
  componentWillUnmount() {
    this.cancelTokenSource.cancel('Pending fetch cancelled on unmount.')
  }

  /**
   * Fetches items from back end using `fetchItems` and passes in query parameters derived from
   * the filtering/paging/sorting values we store in state.
   *
   * `updatedValues` can be passed into this function to update the query params state values
   * currently stored in the state and to be included in the `fetchItems` call.
   *
   * Once the items are fetched, the data is formatted into table row data that's saved to the state.
   */
  fetchItemsAndUpdateQueryParamStateValues = updatedValues => {
    const {
      filterInputValue,
      pageNumber,
      resultsPerPage,
      sortDirection,
      sortHeaderKey,
    } = this.state

    this.setState({ isLoadingNewRows: true, ...updatedValues })

    const queryParamValues = {
      filterInputValue,
      pageNumber,
      resultsPerPage,
      sortDirection,
      sortHeaderKey,
      ...updatedValues,
    }

    const formattedQueryParams = queryString.stringify({
      search: queryParamValues.filterInputValue,
      limit: queryParamValues.resultsPerPage,
      offset: (queryParamValues.pageNumber - 1) * queryParamValues.resultsPerPage,
      direction: queryParamValues.sortDirection,
      order_by: queryParamValues.sortHeaderKey,
    })

    if (updatedValues.filterInputValue) {
      // Debounce calls to the API if the change involves the filter input value
      this.fetchItemsDebounced(formattedQueryParams)
    } else {
      this.fetchItems(formattedQueryParams)
    }
  }

  fetchItems = formattedQueryParams => {
    this.props
      .fetchItems(`?${formattedQueryParams}`, { cancelToken: this.cancelTokenSource.token })
      .then(({ items, itemCount }) => {
        const itemsById = items.reduce((itemsById, item) => {
          itemsById[item.id] = item
          return itemsById
        }, {})

        const { rowIds, rowsById } = normalizeForDataTableWithState(items, this.props.headers)

        this.setState(state => ({
          itemsOnPage: itemsById,
          totalItemCount: itemCount,
          isLoadingNewRows: false,
          rowIds,
          rowsById,
          rows: this.props.formatTableRows(items),
          unformattedRows: items,
          selectedRowIdsOnPage: state.selectedItems
            .filter(({ id }) => !!itemsById[id]) // check which `selectedItems` are in the items just returned from `fetchItems`
            .map(({ id }) => `${id}`),
        }))
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          this.setState({ errorFetchingItems: error, isLoadingNewRows: false })
        }
      })
  }

  fetchItemsDebounced = debounce(this.fetchItems, 200)

  /**
   * Handler for updating the rows when the filter value changes
   */
  handleFilterInputValueChange = newFilterInputValue => {
    this.fetchItemsAndUpdateQueryParamStateValues({ filterInputValue: newFilterInputValue })
  }

  /**
   * Handler for updating the rows when the paging state (# rows per page, current page number) changes
   */
  handlePagingChange = ({ pageNumber, resultsPerPage }) => {
    this.fetchItemsAndUpdateQueryParamStateValues({ pageNumber, resultsPerPage })
  }

  /**
   * On complete function for drag events
   */
  onDragEnd = data => {
    const originalRowIds = this.state.rowIds.slice(0)
    const originalRows = this.state.unformattedRows.slice(0)

    if (data.source && data.destination) {
      // Since a large dataset can span multiple pages, add an offset to support multiple pages
      const startIndex = data.source.index
      const endIndex = data.destination.index

      if (data.pageNumber > 1) {
        const offset = (data.pageNumber - 1) * data.resultsPerPage
        data.destination.index = endIndex + offset
        data.source.index = startIndex + offset
      }
      // Reorder row and remove any empty ones (not sure what causes this)
      const updatedRowIds = reorder(originalRowIds, startIndex, endIndex).filter(item => item)

      const updatedRows = updatedRowIds.map(id => ({
        ...this.state.rowsById[id],
      }))

      const newRows = this.props.formatTableRows(updatedRows)

      // Set new rowIds and fire callback
      this.setState(
        {
          rows: newRows,
          rowIds: updatedRowIds,
        },
        () =>
          this.props.onDragEnd({
            data,
            updatedRows,
            originalRows, // use the original data to compare ID (should include position data)
            rowAboveId: updatedRowIds[endIndex - 1],
            rowBelowId: updatedRowIds[endIndex + 1],
          })
      )
    }
  }

  /**
   * Handler for updating the internal selectedRowIds state as well as alerting any consuming component.
   * NOTE: `selectedRowIdsAfterSelection` will be all currently selected row ids that DataTable tracks,
   * whereas `allSelectedItemsAfterSelection` which we pass to the consuming component will include
   * selected items corresponding to all of the rows that `DataTableWithStateAsync` tracks (so across all
   * pages, and including those for rows that may be currently filtered out)
   */
  handleSelectedRowsChange = (selectedRowIdsAfterSelection, unselectedRowIds = []) => {
    const { onSelectedRowsChange } = this.props

    this.setState(state => {
      const { selectedItems } = state
      const selectedItemsRowIds = selectedItems.map(({ id }) => `${id}`)

      // Filter out current state's selectedItems by the items/rows unselected in DataTable
      const selectedItemsAfterUnselection =
        unselectedRowIds.length > 0
          ? selectedItems.filter(({ id }) => !unselectedRowIds.includes(`${id}`))
          : [...selectedItems]

      // Look at the selectedRowIdsAfterSelection (from DataTable) and the state's current selectedItems to
      // determine which newly selected items need to be added to the state
      const newlySelectedItemsAfterSelection = selectedRowIdsAfterSelection
        .filter(rowId => !selectedItemsRowIds.includes(rowId))
        .map(rowId => state.itemsOnPage[rowId])

      // Combine the two together to get our final list of *unique* selected items
      const allSelectedItemsAfterSelection = [
        ...selectedItemsAfterUnselection,
        ...newlySelectedItemsAfterSelection,
      ]

      if (onSelectedRowsChange) {
        onSelectedRowsChange(allSelectedItemsAfterSelection)
      }

      return { selectedItems: allSelectedItemsAfterSelection }
    })
  }

  /**
   * Handler for updating the rows when the sort state of the table changes
   */
  handleSortBy = ({ nextSortDirection, nextSortHeaderKey }) => {
    this.fetchItemsAndUpdateQueryParamStateValues({
      sortDirection: nextSortDirection,
      sortHeaderKey: nextSortHeaderKey,
    })
  }

  render() {
    const { headers, id, render, renderProps, ...restProps } = this.props

    const {
      errorFetchingItems,
      filterInputValue,
      isLoadingNewRows,
      pageNumber,
      resultsPerPage,
      rows,
      selectedRowIdsOnPage,
      sortDirection,
      sortHeaderKey,
      totalItemCount,
    } = this.state

    const dataTableProps = {
      ...restProps,

      // Data derived from props
      headers,
      id,

      // Data derived from state
      error: errorFetchingItems,
      isLoadingNewRows,
      rows,
      selectedRowIds: selectedRowIdsOnPage,
      sortDirection,
      sortHeaderKey,
      pagingProps: {
        pageNumber,
        resultsPerPage,
        resultsTotal: totalItemCount,
        onPagingChange: this.handlePagingChange,
      },

      // Event handlers for updating internal state
      onDragEnd: this.onDragEnd,
      onSelectedRowsChange: this.handleSelectedRowsChange,
      onSortBy: this.handleSortBy,

      // Custom render props
      render,
      renderProps: {
        filterInputValue,
        onFilterInputChange: this.handleFilterInputValueChange,
        ...renderProps,
      },
    }

    return <DataTable isSelectable {...dataTableProps} />
  }
}
