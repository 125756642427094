import { Formik } from 'formik'
import merge from 'lodash/merge'
import React from 'react'
import injectSheet from 'react-jss'
import moment from 'moment'
import { Element as ScrollElement } from 'react-scroll'
import { BooleanValue } from 'react-values'

import {
  createMerchandisedLandingPage,
  fetchMerchandisedLandingPageById,
  updateMerchandisedLandingPage,
} from 'api'
import { Button, Checkbox, FormFieldItem, Notification } from 'components'
import { ScrollSpyView } from 'layouts'
import { createToast } from 'modules/toasts'
import { getBackButton, getRouteTo } from 'routing'
import Styling from 'styling/components'

import { get, ItemFetcher } from 'utils'

import MerchandisedLandingPagesContentSelect from './MerchandisedLandingPagesContentSelect'
import MerchandisedLandingPagesImageUpload from './MerchandisedLandingPagesImageUpload'

import {
  daysOfWeekArray,
  mlpFormInitialValues,
  mlpFormValidationSchema,
} from './MerchandiseLandingPagesForm/MerchandisedLandingPagesFormSchema'

import styles from './MerchandiseLandingPagesForm/MerchandisedLandingPagesForm.styles'

import {
  PlacementActiveDays,
  PlacementActivityPeriod,
  PlacementStoreVisibility,
  PlacementTitle,
  PlacementUserSegment,
  PlacementUserVisibility,
} from '../../Marketing/PromotedPlacements/PromotedPlacementsForm/components'

const PAGE_ID = 'merchandised-landing-pages-form'

export const onMerchandisedLandingFormSubmit = (edit, formValues) => {
  const payload = { ...formValues }
  payload.startDate =
    formValues.activityPeriod && formValues.activityPeriod.from
      ? moment(formValues.activityPeriod.from).format()
      : null
  payload.endDate =
    formValues.activityPeriod && formValues.activityPeriod.to
      ? moment(formValues.activityPeriod.to).format()
      : null
  delete payload.activityPeriod

  // If user does not want to see MLP Marketing section
  if (!formValues.showMarketingSection) {
    payload.status = 'hidden'
  }

  payload.content = formValues.content.map(c => ({
    type: c.type,
    data: {
      id: c.data.id,
    },
  }))

  if (edit) {
    // As per v1, we need to send `action: 'edit'` in payload so the API knows to maintain search keywords as opposed to
    // ignoring them for a 'reorder' PUT (MERC-89)
    payload.action = 'edit'
    return updateMerchandisedLandingPage(formValues.id, payload)
  }

  return createMerchandisedLandingPage(payload)
}

const formSections = ({ classes, setFieldValue, showMarketingSection }) => {
  const defaultFormSections = [
    {
      name: 'Detail',
      components: [
        {
          component: PlacementTitle,
          props: {
            componentId: `${PAGE_ID}-title`,
            labelText: 'Landing Page Name',
            placeholder: 'Enter page name',
          },
        },
        {
          component: MerchandisedLandingPagesContentSelect,
          props: {
            componentId: `${PAGE_ID}-content`,
            componentName: 'content',
            labelText: 'Content',
          },
        },
      ],
    },
    {
      name: 'Marketing',
      components: [
        {
          component: () => (
            <FormFieldItem
              className={classes.formRow}
              id={`${PAGE_ID}-show-marketing`}
              labelText="Placement Option"
              name="name"
              render={renderProps => (
                <BooleanValue
                  defaultValue={showMarketingSection}
                  onChange={value => {
                    setFieldValue('showMarketingSection', value)
                  }}
                >
                  {({ toggle, value }) => (
                    <Checkbox
                      checked={value}
                      id={`${PAGE_ID}-show-marketing-checkbox`}
                      labelText="Show in Navigation"
                      onChange={toggle}
                    />
                  )}
                </BooleanValue>
              )}
            />
          ),
        },
      ],
    },
  ]

  const marketingFormSections = [
    {
      name: 'Department Tile Image',
      components: [
        {
          component: MerchandisedLandingPagesImageUpload,
          props: {
            componentId: `${PAGE_ID}-image-upload`,
          },
        },
      ],
    },
    {
      name: 'Segmentation',
      components: [
        {
          component: PlacementStoreVisibility,
          props: {
            componentId: `${PAGE_ID}-store-visibility`,
            labelText: 'Store Visibility',
            placeholder: 'Select Store Group',
          },
        },
        {
          component: PlacementUserSegment,
          props: {
            componentId: `${PAGE_ID}-user-segment`,
            labelText: 'Target User Segment',
            placeholder: 'Select User Segment',
          },
        },
        {
          component: PlacementUserVisibility,
          props: {
            componentId: `${PAGE_ID}-user-visibility`,
            fieldItemName: 'loyaltyVisibility',
            labelText: 'Dynamic User Visibility',
            radioButtonProps: [
              {
                id: 'loyalty-everyone',
                labelText: 'Everyone',
                value: null,
              },
              {
                id: 'loyalty-true',
                labelText: 'Loyalty Only',
                value: true,
              },
              {
                id: 'loyalty-false',
                labelText: 'Non Loyalty Only',
                value: false,
              },
            ],
          },
        },
      ],
    },
    {
      name: 'Schedule',
      components: [
        {
          component: PlacementActivityPeriod,
          props: {
            componentId: `${PAGE_ID}-activity-period`,
            labelText: 'Activity Period',
          },
        },
        {
          component: PlacementActiveDays,
          props: {
            componentId: `${PAGE_ID}-active-days`,
            labelText: 'Active Days',
            checkboxOptions: daysOfWeekArray,
          },
        },
      ],
    },
  ]

  if (showMarketingSection) {
    return [...defaultFormSections, ...marketingFormSections]
  }
  return defaultFormSections
}

const initializeFormProps = ({ existingMLP = {} }) => {
  const { mlp: mlpData = null } = existingMLP
  // Make a deep copy of the `formInitialValues` object to be used for merge with `mlpData`
  const formInitialValuesCopy = JSON.parse(JSON.stringify(mlpFormInitialValues))
  const formProps = merge(formInitialValuesCopy, mlpData)

  // if existing MLP (edit or dupe), set endDate to saved value (even if null), else use initial default values
  const initEndDate = mlpData ? get(mlpData, 'endDate') : mlpFormInitialValues.endDate

  // Initialize DatePicker component for PlacementActivityPeriod component
  formProps.activityPeriod = {
    from: get(mlpData, 'startDate') || mlpFormInitialValues.startDate,
    to: initEndDate,
  }

  // Always set status to 'draft' whenever user is creating or editing form
  formProps.status = 'draft'

  if (mlpData !== null) {
    formProps.showMarketingSection = mlpData.status !== 'hidden'
    formProps.content = existingMLP.mlp_content
  }

  return formProps
}

export const MerchandisedLandingPageCreateOrEdit = injectSheet(styles)(
  ({ backButton, classes, edit, existingMLP, history, successMessage }) => (
    <Formik
      initialValues={initializeFormProps({ existingMLP })}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={mlpFormValidationSchema}
      onSubmit={(values, form) =>
        onMerchandisedLandingFormSubmit(edit, values)
          .then(newMLP => {
            form.setSubmitting(false)
            createToast({ kind: 'success', message: successMessage })

            history.push(
              getRouteTo('merchandisedLandingPages.details', {
                backButton,
                id: newMLP.mlp.id,
              })
            )
          })
          .catch(error => {
            form.setSubmitting(false)
            form.setErrors({ global: error.message })
          })
      }
      render={formikProps => {
        const {
          setFieldValue,
          values: { showMarketingSection },
        } = formikProps

        return (
          <ScrollSpyView
            name="scroll-container"
            sections={formSections({
              classes,
              formikProps,
              setFieldValue,
              showMarketingSection,
            })}
            render={({ sections }) => (
              <div className={classes.formContainer}>
                {sections.map(({ components, hideComponent, name }, index) =>
                  !hideComponent ? (
                    <ScrollElement key={index} name={name} className={classes.createMLPContainer}>
                      <h5 className={classes.createMLPTitle}>{name}</h5>
                      {components.map((component, index) => {
                        const Component = component.component
                        const mergedProps = {
                          existingMLP,
                          ...formikProps,
                          ...component.props,
                        }

                        return <Component key={index} {...mergedProps} />
                      })}
                    </ScrollElement>
                  ) : null
                )}
                <div className={classes.formFooter}>
                  <div className={classes.footerContainer}>
                    <Styling.LineOfButtons withoutTopMargin>
                      <Button
                        disabled={formikProps.isSubmitting}
                        id={`${PAGE_ID}-save`}
                        onClick={formikProps.handleSubmit}
                      >
                        {edit ? 'Save' : 'Create'}
                      </Button>
                      <Button kind="link" href={backButton.to} id={`${PAGE_ID}-cancel`}>
                        Cancel
                      </Button>
                    </Styling.LineOfButtons>
                    <div className={classes.footerErrors}>
                      {!formikProps.isSubmitting && formikProps.errors.global && (
                        <Notification kind="error" message={formikProps.errors.global} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          />
        )
      }}
    />
  )
)

export const MerchandisedLandingPageCreate = ({ history, location, match }) => {
  /**
   * `duplicateId` is passed in when the user clicks on the "Duplicate" button from the MLP Details page
   */

  const duplicateId = get(location, 'state.duplicateId')
  const backButton = get(location, 'state.backButton') || getBackButton('merchandisedLandingPages')
  const mergedProps = { backButton, edit: false, history, match }

  if (duplicateId) {
    return (
      <ItemFetcher
        queryKey="mlpById"
        queryParams={{ duplicateId }}
        fetchItem={config => fetchMerchandisedLandingPageById(duplicateId, config)}
        render={({ item: existingMLP }) => {
          if (existingMLP) {
            mergedProps.existingMLP = {
              ...existingMLP,
              id: undefined,
              status: 'draft',
            }
            return <MerchandisedLandingPageCreateOrEdit {...mergedProps} />
          }
          return null
        }}
      />
    )
  }
  return <MerchandisedLandingPageCreateOrEdit {...mergedProps} />
}

export const MerchandisedLandingPageEdit = ({ history, match }) => {
  return (
    <ItemFetcher
      queryKey="mlpById"
      queryParams={{ id: match.params.id }}
      fetchItem={config => fetchMerchandisedLandingPageById(match.params.id, config)}
      render={({ item: existingMLP }) => {
        const { id } = match.params

        if (existingMLP) {
          const backButton = getBackButton('merchandisedLandingPages.details', { id })
          const mergedProps = {
            backButton,
            edit: true,
            existingMLP,
            history,
            match,
          }
          return <MerchandisedLandingPageCreateOrEdit {...mergedProps} />
        }
        return null
      }}
    />
  )
}
