import React from 'react'
import injectSheet from 'react-jss'
import { Formik } from 'formik'

import { FormItem, FormLabel, Button, TextInput, Notification, Checkbox } from 'components'
import Styling from 'styling/components'

const styles = theme => ({
  form: {
    width: 300,
  },
})

const subscriptionTypes = [
  { label: 'Pickup', value: 'pickup_fee' },
  { label: 'Delivery', value: 'delivery_fee' },
]

const SubscriptionForm = props => {
  const { classes, initialValues = {}, handleSubmit, backButton } = props

  // initialize null/undefined values to empty string
  for (const k in initialValues) {
    const v = initialValues[k]
    if (v === null || v === undefined) {
      initialValues[k] = ''
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange
      onSubmit={handleSubmit}
      validate={values => {
        const errors = {}

        if (values.waiving_fee_types.length === 0) {
          errors.waiving_fee_types = 'Please select at least one subscription type'
        }
        return errors
      }}
      render={({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <div className={classes.form}>
          <FormItem fieldId="subscription-name" errorMsg={errors.name}>
            <FormLabel htmlFor={'subscription-name'} labelText="Subscription Name" />
            <TextInput
              autoFocus
              id="subscription-name"
              isInvalid={!!errors.name}
              name="name"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Name"
              value={values.name}
            />
          </FormItem>
          <FormItem fieldId="subscription-description" errorMsg={errors.description}>
            <FormLabel htmlFor={'subscription-description'} labelText="Subscription Description" />
            <TextInput
              id="subscription-description"
              isInvalid={!!errors.description}
              name="description"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Description"
              value={values.description}
            />
          </FormItem>
          <FormItem fieldId="plu-number" errorMsg={errors.plu}>
            <FormLabel htmlFor={'plu-number'} labelText="PLU Number" />
            <TextInput
              id="plu-number"
              isInvalid={!!errors.plu}
              name="plu"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="PLU Number"
              value={values.plu}
            />
          </FormItem>
          <FormItem fieldId="time-period" errorMsg={errors.days}>
            <FormLabel htmlFor={'time-period'} labelText="Time Period (Days)" />
            <TextInput
              id="time-period"
              isInvalid={!!errors.days}
              name="days"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Time Period"
              value={values.days}
            />
          </FormItem>
          <FormItem fieldId="subcription-price" errorMsg={errors.price}>
            <FormLabel htmlFor={'subscription-price'} labelText="Price ($)" />
            <TextInput
              id="subscription-price"
              isInvalid={!!errors.price}
              name="price"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.price}
              placeholder="Price"
              type="number"
            />
          </FormItem>

          <FormItem fieldId="subscription-type" errorMsg={errors.waiving_fee_types}>
            <FormLabel labelText="Subscription type" />
            <Styling.LineOfItems>
              {subscriptionTypes.map(subscriptionType => (
                <Checkbox
                  key={subscriptionType.value}
                  id={`${subscriptionType.value}-subscription-type`}
                  labelText={subscriptionType.label}
                  checked={values.waiving_fee_types.includes(subscriptionType.value)}
                  onChange={event => {
                    if (event.target.checked) {
                      setFieldValue('waiving_fee_types', [
                        ...values.waiving_fee_types,
                        subscriptionType.value,
                      ])
                      return
                    }

                    setFieldValue(
                      'waiving_fee_types',
                      values.waiving_fee_types.filter(st => st !== subscriptionType.value)
                    )
                  }}
                />
              ))}
            </Styling.LineOfItems>
          </FormItem>

          {!isSubmitting && errors.global && <Notification kind="error" message={errors.global} />}

          <Styling.LineOfButtons>
            <Button disabled={isSubmitting} id={'subscription-save'} onClick={handleSubmit}>
              Save
            </Button>
            <Button kind="link" href={backButton.to} id={'subscription-cancel'}>
              Cancel
            </Button>
          </Styling.LineOfButtons>
        </div>
      )}
    />
  )
}

export default injectSheet(styles)(SubscriptionForm)
