import { Formik } from 'formik'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as yup from 'yup'

import { createRole, updateRole, fetchRole } from 'api'
import { LoadingIndicator, PageHeader } from 'components'
import { createToast } from 'modules/toasts'
import { getBackButton, getRouteTo } from 'routing'
import { get, ItemFetcher } from 'utils'

import RoleForm from './RoleForm'

const dispatchToProps = dispatch =>
  bindActionCreators(
    {
      createToast,
    },
    dispatch
  )

const RoleCreateOrEdit = connect(
  null,
  dispatchToProps
)(
  ({
    backButton,
    createToast,
    headerTitle,
    initialValues = { name: '', permissionGroups: [] },
    history,
    onFormSubmit,
    successMessage,
  }) => (
    <div>
      <PageHeader backButton={backButton} hasDivider headerTitle={headerTitle} />
      <Formik
        initialValues={initialValues}
        validationSchema={() =>
          yup.object().shape({
            name: yup
              .string()
              .trim()
              .label('Name')
              .required(),
            roles: yup.array().label('Permissions'),
          })
        }
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values, form) => {
          // Remove null and undefined values before submitting
          values.permissionGroups = values.permissionGroups.filter(p => p)
          onFormSubmit(values)
            .then(role => {
              form.setSubmitting(false)
              createToast({ kind: 'success', message: successMessage })

              // Send the user back to the list of roles
              history.push(getRouteTo('roles'))
            })
            .catch(error => {
              form.setSubmitting(false)
              form.setErrors({ global: error.message })
            })
        }}
        render={renderProps => (
          <RoleForm formikProps={renderProps} cancelDestination={backButton.to} />
        )}
      />
    </div>
  )
)

export const RoleCreate = ({ location, ...restProps }) => {
  const propsToPass = {
    backButton: get(location, 'state.backButton') || getBackButton('roles'),
    headerTitle: 'Create New Role',
    onFormSubmit: formValues => createRole(formValues),
    successMessage: 'Successfully created role.',
    ...restProps,
  }

  return <RoleCreateOrEdit {...propsToPass} />
}

export const RoleEdit = ({
  location,
  match: {
    params: { roleId },
  },
  ...restProps
}) => (
  <ItemFetcher
    queryKey="role"
    queryParams={{ roleId }}
    fetchItem={config => fetchRole(roleId, config)}
    render={({ item: role, isLoadingItem: isFetchingRole }) => {
      if (isFetchingRole || !role) {
        return <LoadingIndicator withTopMargin />
      }
      /*
          Last minute deserialization required here.
        */
      const permissionGroups = []
      if (role.permission_departments) {
        for (const dept of role.permission_departments) {
          for (const bundle of dept.permission_group_bundles) {
            permissionGroups[bundle.id] = { id: bundle.permission_groups[0] }
          }
        }
      }

      const propsToPass = {
        backButton: get(location, 'state.backButton') || getBackButton('roles'),
        headerTitle: 'Edit Role',
        initialValues: {
          name: role.name,
          permission_departments: role.permission_departments || [],
          permissionGroups,
        },
        onFormSubmit: formValues => updateRole(roleId, formValues),
        successMessage: 'Successfully updated role.',
        ...restProps,
      }

      return <RoleCreateOrEdit {...propsToPass} />
    }}
  />
)
