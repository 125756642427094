import React from 'react'
import injectSheet from 'react-jss'

import { Button, FormFieldItem, Notification, TextInput } from 'components'
import Styling from 'styling/components'

const styles = theme => ({
  formContainer: {
    width: 610,
  },
})

const BASE_ID = 'create-keyword-rule'

const KeywordRuleForm = ({ classes, errors, handleSubmit, handleCancel, isSubmitting }) => (
  <form className={classes.formContainer} onSubmit={handleSubmit}>
    <FormFieldItem
      id={`${BASE_ID}-name`}
      labelText="Name"
      name="name"
      render={renderProps => (
        <TextInput {...renderProps} autoFocus placeholder="Enter keyword" type="text" />
      )}
    />

    {!isSubmitting && errors.global && <Notification kind="error" message={errors.global} />}
    <Styling.LineOfButtons>
      <Button disabled={isSubmitting} id={`${BASE_ID}-save`} type="submit">
        Save
      </Button>
      <Button kind="link" onClick={handleCancel}>
        Cancel
      </Button>
    </Styling.LineOfButtons>
  </form>
)

export default injectSheet(styles)(KeywordRuleForm)
