export default theme => ({
  parentalSelectorContainer: {
    display: 'flex',
    marginBottom: theme.spacing.md,

    '& > *': {
      flex: 1,
    },
  },
  parentalSelectorDropdown: {
    flex: '0 1 auto',
    marginRight: theme.spacing.md,
    width: 240,
  },
  parentalSelectorButton: {
    flex: '0 1 auto',
    marginRight: theme.spacing.md,
    '& button': {
      height: '100%',
    },
  },
  parentalSelectorLoader: {
    marginRight: theme.spacing.md,
  },
})
