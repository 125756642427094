import React, { Component, createRef } from 'react'

class TableCell extends Component {
  constructor(props) {
    super(props)

    this.cellRef = createRef()
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (!this.cellRef) {
      return null
    }

    const isDragStarting = this.props.isDragOccurring && !prevProps.isDragOccurring

    if (!isDragStarting || !window.getComputedStyle) {
      return null
    }

    const { height, width } = getComputedStyle(this.cellRef)

    const snapshot = {
      height: parseInt(height, 10),
      width: parseInt(width, 10),
    }

    return snapshot
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const ref = this.cellRef

    if (!ref) {
      return
    }

    if (snapshot) {
      if (ref.style.height === snapshot.height || ref.style.width === snapshot.width) {
        return
      }

      // Keep track of the height of the cell when dragging
      const { height } = snapshot
      ref.style.height = `${height}px`

      // Keep track of the width of the cell when dragging
      const width = snapshot.width - ref.style.paddingLeft - ref.style.paddingRight
      ref.style.width = `${width}px`
      return
    }

    if (this.props.isDragOccurring) {
      return
    }

    // inline styles not applied
    if (ref.style.height === null || ref.style.width === null) {
      return
    }

    // no snapshot and drag is finished - clear the inline styles
    ref.style.removeProperty('height')
    ref.style.removeProperty('width')
  }

  render() {
    const tdProps = this.props
    const { children, isDragOccurring, ...formattedProps } = tdProps
    return (
      <td {...formattedProps} ref={ref => (this.cellRef = ref)}>
        {children}
      </td>
    )
  }
}

export default TableCell
