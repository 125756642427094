import memoize from 'memoize-one'
import React from 'react'
import injectSheet from 'react-jss'

import { bindActionCreators } from 'redux'

import { connect } from 'react-redux'

import { fetchKeywordRedirects, deleteKeywordRedirect, updateKeywordRedirect } from 'api'
import { TableView } from 'layouts'
import { generateBackButton, getRoutePathname } from 'routing'
import { formatDatetime, isNullOrUndefined } from 'utils'
import {
  Button,
  ButtonToggle,
  DropdownMenu,
  DropdownMenuItem,
  Modal,
  Notification,
} from 'components'

import { getUserPermissionKeys } from 'modules/user'
import { createToast } from 'modules/toasts'
import config from 'config'

import KeywordRedirectDetails from './KeywordRedirectDetails'

const styles = () => ({
  root: {
    margin: 0,
  },
  container: {
    paddingTop: '20px',
  },
  disableMouseInteraction: {
    pointerEvents: 'none',
  },
})

// Table Configurations
const PAGE_ID = 'keyword-redirects'
const PAGE_TITLE = 'Keyword Redirects'
const FETCH_FUNCTION = fetchKeywordRedirects

const tableParams = {
  paging: {
    type: 'multi',
    multiParams: { limit: { key: 'limit' }, offset: { key: 'offset' } },
  },
  search: { key: 'search', placeholder: 'Search by keyword' },
  sorting: {
    type: 'multi',
    multiParams: { direction: { key: 'direction' }, orderBy: { key: 'order_by' } },
  },
}

const tableHeaders = [
  {
    key: 'name',
    header: 'Name',
    disableSorting: true,
  },
  {
    key: 'keywords',
    header: '# of Keywords',
    disableSorting: true,
  },
  {
    key: 'redirectCategory',
    header: 'Category Name',
    disableSorting: true,
  },
  {
    key: 'url',
    header: 'Destination',
    disableSorting: true,
  },
  {
    key: 'modified',
    header: 'Last Updated',
    disableSorting: true,
  },
]

const generateKeywordRedirectActionDropdown = ({
  createToast,
  history,
  location,
  refetchItems,
  keywordRedirect,
  userPermissions,
}) => {
  return (
    <DropdownMenu
      ariaLabel="Actions"
      menuAlignment="right"
      triggerRender={({ description, isOpen, onClick }) => (
        <ButtonToggle
          icon="more"
          id={`keyword-redirect-${keywordRedirect.id}-action`}
          isToggled={isOpen}
          onClick={onClick}
          untoggledDescription={description}
        />
      )}
    >
      {userPermissions.includes('search_keywords_redirects.edit') && (
        <DropdownMenuItem
          icon="edit"
          kind="primary"
          onClick={() => {
            history.push({
              pathname: getRoutePathname('searchSettings.keywordRedirects.editKeywordRedirect', {
                id: keywordRedirect.id,
              }),
              state: {
                backButton: generateBackButton('searchSettings.keywordRedirects', location),
              },
            })
          }}
        >
          Edit
        </DropdownMenuItem>
      )}
      {userPermissions.includes('search_keywords_redirects.create') && (
        <DropdownMenuItem
          icon="copy"
          kind="primary"
          onClick={() => {
            const duplicateId = keywordRedirect.id
            history.push({
              pathname: getRoutePathname('searchSettings.keywordRedirects.createKeywordRedirect'),
              state: {
                backButton: generateBackButton('searchSettings.keywordRedirects', location),
                duplicateId,
              },
            })
          }}
        >
          Duplicate
        </DropdownMenuItem>
      )}
      {userPermissions.includes('search_keywords_redirects.edit') && (
        <DropdownMenuItem
          icon="delete"
          kind="danger"
          hasDivider
          renderButton={({ buttonProps, iconEl }) => {
            return (
              <Modal.Confirmation
                handleCloseModal={({ wasConfirmed }) => {
                  if (wasConfirmed) {
                    deleteKeywordRedirect(keywordRedirect.id)
                      .then(deletedKeywordRedirect => {
                        createToast({
                          kind: 'success',
                          message: 'Keyword Redirect successfully deleted.',
                        })
                        refetchItems()
                      })
                      .catch(({ message }) => {
                        createToast({ kind: 'error', message })
                      })
                  }
                }}
                triggerRender={({ openModal }) => (
                  <button
                    {...buttonProps}
                    // Overwrite buttonProps' `onClick`, we don't want to close the dropdown menu
                    // because the confirmation modal would be unmounted
                    onClick={() => {
                      openModal()
                    }}
                  >
                    {iconEl}
                    Delete
                  </button>
                )}
                contentProps={{
                  actionText: 'delete this keyword redirect',
                  confirmButtonText: 'Delete',
                }}
              />
            )
          }}
        />
      )}
    </DropdownMenu>
  )
}

const formatTableRows = memoize(({ items, location, refetchItems, history, userPermissions }) =>
  items.map(keywordRedirect => {
    const { id, name, keywords = [], redirectCategory, url, modified, position } = keywordRedirect

    return {
      id: `${PAGE_ID}-${id}`,
      rowAction: generateKeywordRedirectActionDropdown({
        createToast,
        history,
        location,
        refetchItems,
        keywordRedirect,
        userPermissions,
      }),
      rowDetails: <KeywordRedirectDetails keywordRedirectId={id} />,
      name,
      keywords: keywords.length,
      redirectCategory,
      url,
      position,
      modified: modified ? formatDatetime(modified) : '',
    }
  })
)

const formatTableActions = location => [
  {
    href: {
      pathname: getRoutePathname('searchSettings.keywordRedirects.createKeywordRedirect'),
      state: { backButton: generateBackButton('searchSettings.keywordRedirects', location) },
    },
    id: `${PAGE_ID}-create`,
    permission: 'search_keywords_redirects.create',
    content: 'Create New',
  },
]

const findRowById = (rowId, items) => {
  // Make sure this id matches the one passed to the table
  return items.find(item => `${PAGE_ID}-${item.id}` === rowId)
}

const onDragEnd = ({ createToast }) => ({ data, originalRows, rowAboveId, rowBelowId }) => {
  const item = findRowById(data.draggableId, originalRows)

  let newPosition = null

  if (item && item.id) {
    if (rowBelowId) {
      // Try to get the position of the row below
      const rowBelow = findRowById(rowBelowId, originalRows)

      if (rowBelow) {
        newPosition = rowBelow.position
      }
    } else if (rowAboveId) {
      // If that doesn't work (at the bottom), get the row above and add 1
      const rowAbove = findRowById(rowAboveId, originalRows)

      if (rowAbove) {
        newPosition = rowAbove.position + 1
      }
    }
  }

  // Position should be updated
  if (!isNullOrUndefined(newPosition)) {
    const updatedItem = { ...item, position: newPosition }

    updateKeywordRedirect(item.id, updatedItem)
      .then(() => {
        createToast({
          kind: 'success',
          message: 'Row was successfully updated.',
        })
      })
      .catch(({ message }) => {
        createToast({ kind: 'error', message })
      })
  } else {
    createToast({
      kind: 'error',
      message: 'There was a problem updating this placement.',
    })
  }
}

const KeywordRedirects = props => {
  const { classes, ...rest } = props
  const showIPPNotice = config.icAutosuggest.showEnticeDeprecationWarning ?? false
  return (
    <div className={showIPPNotice && classes.container}>
      {showIPPNotice && (
        <Notification
          kind="warning"
          message="Keyword redirect management has been moved to the Instacart Platform Portal under Storefront > Keyword Redirects. If you don't have access to the Portal, contact your Instacart representative."
          action={
            <Button
              kind="primary"
              onClick={() => (window.location.href = 'https://dashboard.instacart.com')}
            >
              Go to Portal
            </Button>
          }
        />
      )}
      <div className={showIPPNotice && classes.disableMouseInteraction}>
        <TableView
          {...rest}
          queryKey="keywordRedirects"
          fetchFunction={FETCH_FUNCTION}
          pageId={PAGE_ID}
          pageTitle={PAGE_TITLE}
          pageType={PAGE_ID}
          tableActions={formatTableActions(props.location)}
          tableHeaders={tableHeaders}
          tableParams={tableParams}
          tableRows={formatTableRows}
          onDragEnd={onDragEnd(props)}
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  userPermissions: getUserPermissionKeys(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createToast,
    },
    dispatch
  )

export default injectSheet(styles)(connect(mapStateToProps, mapDispatchToProps)(KeywordRedirects))
