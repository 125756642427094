export default theme => ({
  addCategoryContainer: {
    backgroundColor: theme.bgGreyExtraLight,
    marginTop: theme.spacing.xs,
    maxWidth: '100%',
    padding: theme.spacing.md,
  },
  formContainer: {
    width: '100%',
  },
  leftSide: {
    flexShrink: 0,
    marginRight: theme.spacing.xxlg,
    width: 272,
  },
  rightSide: {
    flexGrow: 1,
    flexShrink: 1,
  },
  underHeaderContainer: {
    display: 'flex',
  },
  notificationGuidanceList: {
    listStyle: 'circle',
    paddingLeft: '1.5em',
  },
  fullSelectionTip: {
    marginTop: theme.spacing.md,
    marginBottom: theme.spacing.md,
  },
  hintText: {
    color: theme.text01,
  },
  header: {
    color: theme.text01,
    fontSize: theme.fontSize.h3,
    marginBottom: theme.spacing.md,
    fontWeight: 600,
  },
  updateButtonContainer: {
    marginTop: theme.spacing.md,
  },
  contextExplainerText: {
    marginBottom: theme.spacing.md,
    color: theme.text01,
    fontSize: theme.fontSize.body,
  },
  contextExplainerIcon: {
    fill: theme.brand01,
    marginRight: theme.spacing.xs,
    height: theme.fontSize.body,
  },
})
