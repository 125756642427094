import { mixins } from 'styling'

export default theme => ({
  formLabel: {
    ...mixins.labelText({ theme }),
    display: 'inline-block',
    marginBottom: theme.spacing.xs,
    verticalAlign: 'baseline',
  },
  formLabelDisabled: {
    opacity: 0.5,
  },
  formLabelHidden: {
    ...mixins.hidden,
  },
})
