import React, { useState } from 'react'

import queryString from 'utils/queryString'

export const HIDE_NAVIGATION_QUERY_PARAM_KEY = 'hideNav'
export const SIDENAV_VISIBLE_ITEM_KEY = 'sidenav-visible'
export const FULL_NAV_VISIBLE_ITEM_KEY = 'full-nav-visible'

function calculateInitialNavState() {
  // If keys don't exist in storage (return null), we default visibility to true
  // Else, return existing boolean values (stored as strings in storage)
  const sidenavVisibleStorageState = localStorage.getItem(SIDENAV_VISIBLE_ITEM_KEY) || 'true'
  const fullnavVisibleStorageState = sessionStorage.getItem(FULL_NAV_VISIBLE_ITEM_KEY) || 'true'
  const { search } = window.location
  const { query: parsedQuery } = queryString.parseUrl(search)

  if (parsedQuery[HIDE_NAVIGATION_QUERY_PARAM_KEY]) {
    sessionStorage.setItem(FULL_NAV_VISIBLE_ITEM_KEY, false)
    return {
      topnavVisible: false,
      sidenavVisible: false,
    }
  }

  return {
    sidenavVisible: fullnavVisibleStorageState === 'true' && sidenavVisibleStorageState === 'true',
    topnavVisible: fullnavVisibleStorageState === 'true',
  }
}

export const Context = React.createContext()

const AppContext = ({ children }) => {
  const [navState, setNavState] = useState(calculateInitialNavState)

  const setSidenavVisibleWrapper = sidenavVisibility => {
    setNavState(state => ({
      ...state,
      sidenavVisible: sidenavVisibility,
    }))
    localStorage.setItem(SIDENAV_VISIBLE_ITEM_KEY, sidenavVisibility)
  }

  const data = {
    sidenavVisible: navState.sidenavVisible,
    topnavVisible: navState.topnavVisible,
    toggleSidenav: () => setSidenavVisibleWrapper(!navState.sidenavVisible),
  }

  return <Context.Provider value={data}>{children}</Context.Provider>
}

export default AppContext
