import cx from 'classnames'
import injectSheet from 'react-jss'
import PropTypes from 'prop-types'
import React from 'react'

import { FormLabel, ReadOnlyValue } from 'components'
import { isObject, snakeToCapitalized, snakeToKebab } from 'utils'

import styles from '../Fact.styles'

const combine = (start, end) => (start ? `${start}-${end}` : end)

const toReadable = val =>
  val === undefined ? '[undefined]' : val === '' ? '[empty]' : val.toString()

const FactDetails = ({
  classes = {},
  factKey = '', // string, property name
  factSchema = {}, // jsonschema, schema that the property's value must conform to
  factValue = {}, // any, property value
  idBase = '', // string, used for generating an id (id = idBase + factKey)
  hideLabel = false, // boolean, set to true to hide the header/label
  showBorder = false, // boolean, set to true to add a border
}) => {
  const id = combine(idBase, snakeToKebab(factKey))
  const wrapWithHeader = children => (
    <div
      key={id}
      className={cx(classes.text, classes.sandwichMargin, { [classes.border]: showBorder })}
    >
      {!hideLabel && <FormLabel labelText={snakeToCapitalized(factKey)} />}
      <div className={cx({ [classes.leftMargin]: !hideLabel })}>{children}</div>
    </div>
  )

  // if it's an object, display the value of each property
  if (factSchema.type === 'object') {
    const properties = Object.keys(factSchema.properties).map((k, idx) => (
      <FactDetails
        key={idx}
        classes={classes}
        factKey={k}
        factSchema={factSchema.properties[k]}
        factValue={isObject(factValue) ? factValue[k] : undefined}
        idBase={id}
      />
    ))
    return wrapWithHeader(properties)
  }

  // if it's nested arrays or an array of objects, wrap each item in a border
  if (
    factSchema.type === 'array' &&
    Array.isArray(factValue) &&
    (factSchema.items.type === 'object' || factSchema.items.type === 'array')
  ) {
    const items =
      factValue.length === 0
        ? '[empty array]'
        : factValue.map((val, idx) => (
            <div key={idx}>
              <FactDetails
                classes={classes}
                factKey={idx.toString()}
                factSchema={factSchema.items}
                factValue={val}
                idBase={id}
                hideLabel
                showBorder
              />
            </div>
          ))
    return wrapWithHeader(items)
  }

  // if we reach here, it's either a string/number/bool/etc. or an array of strings/numbers/bools/etc.
  const text = Array.isArray(factValue)
    ? factValue.length === 0
      ? '[empty array]'
      : factValue.map((val, idx) => toReadable(val)).join('\n')
    : toReadable(factValue)
  const labelText = hideLabel ? '' : snakeToCapitalized(factKey)
  return <ReadOnlyValue key={id} id={id} labelText={labelText} value={text} wideLabel />
}

FactDetails.propTypes = {
  classes: PropTypes.object,
  factKey: PropTypes.string,
  factSchema: PropTypes.object,
  factValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  idBase: PropTypes.string,
  hideLabel: PropTypes.bool,
  showBorder: PropTypes.bool,
}

export default injectSheet(styles)(FactDetails)
