import { Formik } from 'formik'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { createSegment, fetchSegment } from 'api'
import { LoadingIndicator, Notification, PageHeader } from 'components'
import { createToast } from 'modules/toasts'
import { getBackButton, getRouteTo } from 'routing'
import Styling from 'styling/components'
import { ItemFetcher } from 'utils'

import RuleBasedSegmentForm from './RuleBasedSegmentForm'
import { removeRuleIdsFromFormValues } from './utils'

const validateRule = (rule, ruleError) => {
  if (!rule.type) {
    ruleError.type = 'Please select a type of segment rule.'
  } else {
    if (rule.userAge && !rule.userAge.ageFrom && !rule.userAge.ageTo) {
      ruleError.userAge = { ageRange: 'Age Range is required' }
    } else if (rule.gender && !rule.gender.gender) {
      ruleError.gender = 'Gender is required'
    } else if (rule.loyalty) {
      if (rule.loyalty.joined !== true && rule.loyalty.joined !== false) {
        ruleError.loyalty = ruleError.loyalty || {}
        ruleError.loyalty.joined = 'Joined Status is required'
      }
      if (!rule.loyalty.dateFrom) {
        ruleError.loyalty = ruleError.loyalty || {}
        ruleError.loyalty.dateRange = 'Joined Date Range is required'
      }
    } else if (rule.onlineSpend || rule.totalOnlineSpend) {
      const ruleDetails = rule[rule.type] // rule.onlineSpend || rule.totalOnlineSpend

      if (!ruleDetails.spendFrom && !ruleDetails.spendTo) {
        ruleError[rule.type] = ruleError[rule.type] || {} // ruleError.onlineSpend || ruleError.totalOnlineSpend
        ruleError[rule.type].spendRange = 'Spending Range is required'
      }
      if (!ruleDetails.dateFrom && !ruleDetails.rolling_time_period) {
        ruleError[rule.type] = ruleError[rule.type] || {} // ruleError.onlineSpend || ruleError.totalOnlineSpend
        ruleError[rule.type].dateRange = 'Spending Period is required'
      }
    } else if (rule.onlineStoreActivity) {
      const ruleDetails = rule[rule.type] // rule.onlineStoreActivity

      if (ruleDetails.visited !== true && ruleDetails.visited !== false) {
        ruleError[rule.type] = ruleError[rule.type] || {} // ruleError.onlineStoreActivity
        ruleError[rule.type].visited = 'Visited Status is required'
      }
      if (!(ruleDetails.storeGroup && ruleDetails.storeGroup.id)) {
        ruleError[rule.type] = ruleError[rule.type] || {} // ruleError.onlineStoreActivity
        ruleError[rule.type].storeGroup = 'Store Group is required'
      }
      if (!ruleDetails.dateFrom) {
        ruleError[rule.type] = ruleError[rule.type] || {} // ruleError.onlineStoreActivity
        ruleError[rule.type].dateRange = 'Visit Period is required'
      }
    }
    if (rule.onlineProductActivity) {
      const ruleDetails = rule[rule.type] // rule.onlineProductActivity

      if (ruleDetails.have !== true && ruleDetails.have !== false) {
        ruleError[rule.type] = ruleError[rule.type] || {} // ruleError.onlineProductActivity
        ruleError[rule.type].have = 'Activity Status is required'
      }
      if (isEmpty(ruleDetails.activityTypes)) {
        ruleError[rule.type] = ruleError[rule.type] || {} // ruleError.onlineProductActivity
        ruleError[rule.type].activityTypes = 'Activities are required'
      }
      if (!ruleDetails.dateFrom && !ruleDetails.rolling_time_period) {
        ruleError[rule.type] = ruleError[rule.type] || {} // ruleError.onlineProductActivity
        ruleError[rule.type].dateRange = 'Activity Date Range is required'
      }
      if (ruleDetails.productGroup) {
        const pgDetails = ruleDetails.productGroup
        if (pgDetails.type !== 'any' && isEmpty(pgDetails[pgDetails.type])) {
          ruleError[rule.type] = ruleError[rule.type] || {} // ruleError.onlineProductActivity
          ruleError[rule.type].productGroup = true
        }
      }
    }
  }
}

const validateRules = (rules, errors) => {
  const ruleErrors = errors.rules.rules
  rules.forEach((rule, i) => {
    ruleErrors[i] = ruleErrors[i] || {} // If the ruleError remains an `{}`, it does not contain any errors
    validateRule(rule, ruleErrors[i])
  })
  return ruleErrors.filter(ruleError => !isEmpty(ruleError)).length > 0
}

const validationFunc = values => {
  const errors = { rules: { rules: [] } }
  if (!values.name || !values.name.trim()) {
    errors.name = 'Segment Name is a required field'
  }
  if (!values.rules.rulesOperator) {
    errors.rules.rulesOperator =
      'Select if the segment should match against "All" or "Any" rule(s) below'
  }
  const hasRulesErrors = validateRules(values.rules.rules, errors)
  // Formik will consider the form invalid if `errors` is not an empty object
  // So delete any `errors` keys which do not indicate an error is present
  if (!hasRulesErrors) {
    delete errors.rules.rules
    if (isEmpty(errors.rules)) {
      delete errors.rules
    }
  }
  return errors
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createToast,
    },
    dispatch
  )

const CreateRuleBasedSegment = connect(
  null,
  mapDispatchToProps
)(
  ({
    backButton,
    createToast,
    initialValues = {
      name: '',
      polytype: 'rules',
      rules: { rulesOperator: 'AND', rules: [{}] },
    },
    history,
  }) => (
    <div>
      <PageHeader backButton={backButton} hasDivider headerTitle="Create Rule-Based Segment" />
      <Formik
        initialValues={initialValues}
        validate={validationFunc}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values, form) => {
          /**
           * `id`s were added to each segment's rule so that they had unique keys for each
           * rule <tr>'s `key` prop in RuleBasedSegmentForm. We need to strip them out here.
           */
          createSegment(removeRuleIdsFromFormValues(values))
            .then(segment => {
              form.setSubmitting(false)
              createToast({ kind: 'success', message: 'Successfully created segment.' })

              // Send the user to the Segments table page where they can view their new segment
              history.push(getRouteTo('segments'))
            })
            .catch(error => {
              form.setSubmitting(false)
              form.setErrors({ global: error.message })
            })
        }}
        render={formikProps => (
          <RuleBasedSegmentForm
            formikProps={formikProps}
            cancelDestination={backButton.to}
            validationFunc={validationFunc}
          />
        )}
      />
    </div>
  )
)

/**
 * Since segments are static, rules with dates must have a set start and end date (or no dates).
 * If a start but no end date was saved on the segment rule (was possible in Dashboard v1), then
 * replace the segment rule's creation date (which should have been the end date saved upon creation)
 * when duplicating a pre-existing segment.
 *
 * (segment) => segmentRulesWithCorrectEndDates
 */
const replaceNullEndDatesInSegmentRules = ({
  created: createdDate,
  rules: { rulesOperator, rules: segmentRules },
}) => ({
  rulesOperator,
  rules: segmentRules.map(rule => {
    const { type } = rule
    if (rule[type].dateFrom) {
      rule[type].dateFrom = moment(rule[type].dateFrom).format('YYYY-MM-DD')
    }
    if (rule[type].dateTo) {
      rule[type].dateTo = moment(rule[type].dateTo).format('YYYY-MM-DD')
    }
    if (rule[type].dateFrom && !rule[type].dateTo) {
      rule[type].dateTo = moment(createdDate).format('YYYY-MM-DD')
    }
    return rule
  }),
})

export default ({
  /**
   * - `duplicateId` is passed in when we want to open the Create Store Group page but with it filled
   * with the data from the store group represented by that id
   */
  location: { state: locationState = {} },
  ...restProps
}) => {
  const backButton = locationState.backButton || getBackButton('segments')

  if (locationState.duplicateId) {
    return (
      <ItemFetcher
        queryKey="segment"
        queryParams={{ duplicateId: locationState.duplicateId }}
        fetchItem={config => fetchSegment(locationState.duplicateId, config)}
        render={({
          item: segment,
          isLoadingItem: isFetchingSegment,
          error: errorFetchingSegment,
        }) => {
          if (isFetchingSegment) {
            return <LoadingIndicator withTopMargin />
          }
          if (errorFetchingSegment) {
            return (
              <Styling.Center maxWidth={500} withTopMargin>
                <Notification kind="error" message={errorFetchingSegment.message} />
              </Styling.Center>
            )
          }
          if (segment) {
            return (
              <CreateRuleBasedSegment
                backButton={backButton}
                initialValues={{
                  name: segment.name,
                  polytype: 'rules',
                  rules: replaceNullEndDatesInSegmentRules(segment),
                }}
                {...restProps}
              />
            )
          }
        }}
      />
    )
  }
  return <CreateRuleBasedSegment backButton={backButton} {...restProps} />
}
