import memoize from 'memoize-one'
import React from 'react'

import { connect } from 'react-redux'

import { createToast } from 'modules/toasts'

import { fetchSynonyms, deleteSynonym } from 'api'
import { getUserPermissionKeys } from 'modules/user'

// Import layout
import { TableView } from 'layouts'
import { generateBackButton, getRoutePathname } from 'routing'
import { formatDatetime } from 'utils'
import { ButtonToggle, DropdownMenu, DropdownMenuItem, Modal } from 'components'

// Table Configurations
const PAGE_ID = 'search-synonyms'
const PAGE_TITLE = 'Search Synonyms'

const FETCH_FUNCTION = fetchSynonyms

const tableParams = {
  paging: {
    type: 'multi',
    multiParams: { limit: { key: 'limit' }, offset: { key: 'offset' } },
  },
  search: { key: 'search' },
  sorting: {
    type: 'multi',
    multiParams: { direction: { key: 'direction' }, orderBy: { key: 'order_by' } },
  },
}

// Synonymss table header keys must match value used after `sortBy` in `/synonyms` query
// (in addition to the corresponding row property)
const tableHeaders = [
  {
    key: 'synonyms',
    header: 'Synonyms',
    disableSorting: true,
  },
  {
    key: 'word',
    header: 'Product Name',
  },
  {
    key: 'oneway',
    header: 'Synonym Direction',
    disableSorting: true,
  },
  {
    key: 'modified',
    header: 'Last Edited',
  },
]

const generateSynonymActionDropdown = ({
  createToast,
  history,
  location,
  refetchItems,
  id,
  userPermissions,
}) => {
  return (
    <DropdownMenu
      ariaLabel="Actions"
      menuAlignment="right"
      triggerRender={({ description, isOpen, onClick }) => (
        <ButtonToggle
          icon="more"
          id={`synonyms-${id}-action`}
          isToggled={isOpen}
          onClick={onClick}
          untoggledDescription={description}
        />
      )}
    >
      {userPermissions.includes('synonyms.edit') && (
        <DropdownMenuItem
          icon="edit"
          kind="primary"
          onClick={() => {
            history.push({
              pathname: getRoutePathname('searchSettings.synonyms.editSynonym', { id }),
              state: { backButton: generateBackButton('searchSettings.synonyms', location) },
            })
          }}
        >
          Edit
        </DropdownMenuItem>
      )}
      {userPermissions.includes('synonyms.edit') && (
        <DropdownMenuItem
          icon="delete"
          kind="danger"
          hasDivider
          renderButton={({ buttonProps, iconEl }) => {
            return (
              <Modal.Confirmation
                handleCloseModal={({ wasConfirmed }) => {
                  if (wasConfirmed) {
                    deleteSynonym(id)
                      .then(() => {
                        createToast({
                          kind: 'success',
                          message: 'Synonym successfully deleted.',
                        })
                        refetchItems()
                      })
                      .catch(({ message }) => {
                        createToast({ kind: 'error', message })
                      })
                  }
                }}
                triggerRender={({ openModal }) => (
                  <button
                    {...buttonProps}
                    // Overwrite buttonProps' `onClick`, we don't want to close the dropdown menu
                    // because the confirmation modal would be unmounted
                    onClick={() => {
                      openModal()
                    }}
                  >
                    {iconEl}
                    Delete
                  </button>
                )}
                contentProps={{
                  actionText: 'delete this synonym',
                  confirmButtonText: 'Delete',
                }}
              />
            )
          }}
        />
      )}
    </DropdownMenu>
  )
}

const formatTableRows = memoize(
  ({ classes, items, location, history, refetchItems, userPermissions }) =>
    items.map(SearchSynonym => {
      const { id, synonyms, word, oneway, modified } = SearchSynonym
      const synonymDirection = oneway ? 'One Way' : 'Two Way'
      return {
        rowAction: generateSynonymActionDropdown({
          createToast,
          history,
          location,
          refetchItems,
          id,
          userPermissions,
        }),
        id: `${id}`,
        word: word.join(', '),
        synonyms: synonyms.join(', '),
        oneway: synonymDirection,
        modified: formatDatetime(modified),
      }
    })
)

const formatTableActions = location => [
  {
    href: {
      pathname: getRoutePathname('searchSettings.synonyms.createSynonym'),
      state: { backButton: generateBackButton('searchSettings.synonyms', location) },
    },
    id: `${PAGE_ID}-create`,
    permission: 'synonyms.create',
    content: 'Create New',
  },
]

const mapStateToProps = state => ({
  userPermissions: getUserPermissionKeys(state),
})

const SearchSynonyms = props => (
  <TableView
    {...props}
    queryKey="searchSynonyms"
    fetchFunction={FETCH_FUNCTION}
    pageId={PAGE_ID}
    pageTitle={PAGE_TITLE}
    pageType={PAGE_ID}
    tableActions={formatTableActions(props.location)}
    tableHeaders={tableHeaders}
    tableParams={tableParams}
    tableRows={formatTableRows}
  />
)

// export default SearchSynonyms
export default connect(mapStateToProps)(SearchSynonyms)
