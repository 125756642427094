import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import config from 'config'
import { updateDynamicNavigationLinks } from 'actions/navigation'
import { fetchPlacements } from 'api'
import {
  HERO_BANNERS,
  HOMEPAGE_BANNERS,
  HOMEPAGE_COLLECTIONS,
  HOMEPAGE_HERO,
  IMAGE_CAROUSELS,
  MOBILE_HOMEPAGE_HERO,
  QUICK_LINKS,
  RICH_TEXT_CONTAINERS,
  SLIM_BANNERS,
  SPONSORED_TOP_DEALS,
  TILE_CAROUSELS,
} from 'constants/placementTypes'

import PromotedPlacementsContent from './PromotedPlacementsContent'

export const generatePlacementRoutes = () => [
  {
    label: 'Homepage',
    slug: 'homepage',
    childrenKeys: [
      HERO_BANNERS,
      SLIM_BANNERS,
      HOMEPAGE_BANNERS,
      HOMEPAGE_COLLECTIONS,
      HOMEPAGE_HERO,
      MOBILE_HOMEPAGE_HERO,
      IMAGE_CAROUSELS,
      QUICK_LINKS,
      SPONSORED_TOP_DEALS,
      TILE_CAROUSELS,
      RICH_TEXT_CONTAINERS,
    ],
  },
  {
    label: 'Ways to Shop',
    slug: 'ways-to-shop',
    childrenKeys: ['catalog_banners', 'catalog_collections', 'shop_collections'],
  },
  {
    label: 'Catalog',
    slug: 'catalog',
    childrenKeys: ['catalog_placement', 'sponsored_product_suggestions'],
  },
  {
    label: 'Search',
    slug: 'search',
    childrenKeys: ['search_placement'],
  },
  {
    label: 'Checkout',
    slug: 'checkout',
    childrenKeys: [
      'manual_upsell',
      'sponsored_cart_suggestions',
      'order_confirmation',
      'mobile_order_confirmation',
    ],
  },
  {
    label: 'Site Announcement',
    slug: 'site-announcement',
    childrenKeys: [
      'header_banner',
      'mobile_header_banner',
      'free_delivery_header_banner',
      'free_delivery_mobile_header_banner',
    ],
  },
  {
    label: 'Flyer',
    slug: 'flyer',
    childrenKeys: ['flyer_pdf'],
  },
  {
    label: 'Collection Banner',
    slug: 'collection',
    childrenKeys: ['collection_banner'],
  },
  {
    label: 'Merchandised Landing Page',
    slug: 'mlp',
    childrenKeys: ['mlp_banner'],
  },
]

const navType = 'promotedPlacements'

class PromotedPlacements extends Component {
  constructor(props) {
    super(props)
    this.state = {
      placements: null,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.config !== this.props.config) {
      this.updateDynamicLinks()
    }
  }

  componentWillMount() {
    // Load placements if they don't exist globally
    if (!this.props.dynamicLinks[navType]) {
      fetchPlacements().then(({ items = [] }) => {
        this.setState(
          {
            placements: items,
          },
          this.updateDynamicLinks
        )
      })
    }
  }

  updateDynamicLinks() {
    const { placements } = this.state
    const clonedRoutes = generatePlacementRoutes()

    // Loop through each object and match to the routes defined above
    placements.forEach(item => {
      const routeIndex = clonedRoutes.findIndex(route => route.childrenKeys.includes(item.key))

      // Match the route and then add an array of placements to it
      if (routeIndex > -1) {
        if (!clonedRoutes[routeIndex].placements) {
          clonedRoutes[routeIndex].placements = []
        }

        clonedRoutes[routeIndex].placements.push(item)
      }
    })

    // Set the dynamic data globally
    this.props.updateDynamicNavigationLinks(navType, clonedRoutes)
  }

  getCurrentPlacements = () => {
    const { dynamicLinks, match } = this.props
    let currentPlacement = null

    // If a placement exists, return it
    if (dynamicLinks[navType]) {
      currentPlacement = dynamicLinks[navType].find(item => item.slug === match.params.slug)
    }

    return currentPlacement
  }

  render() {
    const currentPlacement = this.getCurrentPlacements()

    return currentPlacement && currentPlacement.placements ? (
      <PromotedPlacementsContent {...currentPlacement} {...this.props} />
    ) : null
  }
}

const mapStateToProps = state => ({
  config: state.general.config,
  dynamicLinks: state.navigation.dynamicLinks,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateDynamicNavigationLinks,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(PromotedPlacements)
