import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

const PADDING = 8

pdfMake.vfs = pdfFonts.pdfMake.vfs

pdfMake.tableLayouts = {
  cateringOrdersLayout: {
    hLineWidth(i, node) {
      return 1
    },
    vLineWidth(i, node) {
      return 1
    },
    hLineColor(i) {
      return '#aaa'
    },
    vLineColor(i) {
      return '#aaa'
    },
    paddingLeft(i) {
      return PADDING
    },
    paddingRight(i) {
      return PADDING
    },
    paddingTop(i) {
      return PADDING
    },
    paddingBottom(i) {
      return PADDING
    },
  },
}

export default function printPDF(docDefinition, fileName) {
  pdfMake.createPdf(docDefinition).download(fileName)
}
