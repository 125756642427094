import { useQuery } from '@instacart/enterprise-services-hooks'
import axios from 'axios'

/**
 * Hook to fetch a query with a single item. Uses React Query under the hood.
 *
 * @param {string} queryKey - Key to use for query cache. Cache also takes params into account when generating the final cache key.
 * @param {func} fetchItem - Function to call to fetch, with a paramater to pass in config.
 * @param {Object} params - Paramaters to pass into the query. Also used to generate the cache key.
 *    Query will automatically refetch if a param changes.
 * @param {Object} options
 * @param {boolean} options.excludeParams - Whether params should be passed to Axios config.
 * @returns {Object} response
 * @returns {Object} response.item - The queried item
 * @returns {boolean} response.isFetchingItem - Whether the item is being fetched or refetched
 * @returns {boolean} response.isLoadingItem - Whether the item is being loaded
 * @returns {Object} response.error
 * @returns {func} response.refetch - To manually refetch the query
 */
export const useItemFetcher = (queryKey, fetchItem, params, options) => {
  const optionDefaults = {
    excludeParams: false,
    refetchOnWindowFocus: false,
  }

  const optionsWithDefaults = {
    ...options,
    ...optionDefaults,
  }

  const performFetch = () => {
    // Used to cancel the request if inactive (eg. on unmount)
    const cancelTokenSource = axios.CancelToken.source()

    const fetchPromise = fetchItem({
      cancelToken: cancelTokenSource.token,
      ...(!optionsWithDefaults.excludeParams && { params }),
    })

    fetchPromise.cancel = () => {
      cancelTokenSource.cancel('Pending fetch cancelled as is it no longer needed.')
    }

    return fetchPromise
  }

  const {
    data: item,
    isFetching: isFetchingItem,
    isLoading: isLoadingItem,
    error,
    refetch,
  } = useQuery(params ? [queryKey, params] : queryKey, performFetch, {
    refetchOnWindowFocus: optionsWithDefaults.refetchOnWindowFocus,
    suspense: false,
  })

  return {
    item,
    error,
    refetch,
    isFetchingItem,
    isLoadingItem,
  }
}
