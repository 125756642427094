export default theme => ({
  tableHeaderSort: {
    alignItems: 'center',
    display: 'flex',
    overflowX: 'hidden',
    textAlign: 'left',

    // button inside the `th` tag which allows the table to be sorted by that column
    'button&': {
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      outlineOffset: 2,
      padding: 0,
    },

    '& > *': {
      verticalAlign: 'middle',
    },

    '& > $tableHeaderIcon': {
      opacity: 0,
      fill: theme.text01,
      flexShrink: 0,
      height: 12,
      marginRight: -4,
      width: 14,
    },
    '&$tableHeaderShowSortIconOnHover:hover, &$tableHeaderShowSortIconOnHover:focus, &$tableHeaderSortActive': {
      '& > $tableHeaderIcon': {
        opacity: 1,
      },
    },
  },
  tableHeaderSortActive: {
    '& > $tableHeaderLabel': {
      color: theme.brand01,
    },
    '& > $tableHeaderIcon': {
      fill: theme.brand01,
    },
  },
  tableHeaderShowSortIconOnHover: {},
  tableHeaderLabel: {
    fontSize: theme.fontSize.label,
    color: theme.text01,
    display: 'block',
    fontWeight: 700,
    letterSpacing: -0.2,
    textTransform: 'uppercase',
    lineHeight: '16px',
  },
  tableHeaderIcon: {},
  selectAllHeader: {
    boxSizing: 'content-box',
    paddingRight: `${theme.spacing.xxs} !important`,
    width: 16,

    '&:first-child': {
      paddingLeft: `${theme.spacing.sm} !important`,
    },
  },
  dragSpacerHeader: {
    boxSizing: 'content-box',
    paddingRight: '0 !important',
    width: 16,

    '&:first-child': {
      paddingLeft: `${theme.spacing.sm} !important`,
    },

    '$selectAllHeader + &': {
      paddingLeft: `${theme.spacing.xxs} !important`,
    },
  },
  expandSpacerHeader: {
    boxSizing: 'content-box',
    paddingRight: '0 !important',
    width: 16,

    '&:first-child': {
      paddingLeft: `${theme.spacing.sm} !important`,
    },

    '$selectAllHeader + &': {
      paddingLeft: `${theme.spacing.xxs} !important`,
    },
  },
  bulkActionHeader: {
    boxSizing: 'content-box',
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
    textAlign: 'center !important',
    width: 32,

    '&:last-child': {
      paddingRight: `${theme.spacing.sm} !important`,
    },
  },
  linkSpacerHeader: {
    boxSizing: 'content-box',
    textAlign: 'center !important',
    width: 24,

    '&:last-child': {
      paddingRight: `${theme.spacing.sm} !important`,

      '$bulkActionHeader + &': {
        paddingLeft: `${theme.spacing.xxs} !important`,
      },
    },
  },
})
