import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import injectSheet from 'react-jss'

import { LoadingIndicator } from 'components'

import styles from './Table.styles'

const Table = ({ children, className, classes, id, isLoading, kind }) => (
  <table
    className={cx(className, classes.table, {
      [classes.defaultStyle]: kind === 'default',
      [classes.lightStyle]: kind === 'light',
      [classes.lightTallRowStyle]: kind === 'light-tall-row',
      [classes.isLoading]: isLoading,
    })}
    id={id}
  >
    {isLoading ? (
      <tbody>
        <tr>
          <td>
            <LoadingIndicator />
          </td>
        </tr>
      </tbody>
    ) : (
      children
    )}
  </table>
)

Table.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
  /**
   * When `true`, will show a loading indicator in place of `children`
   */
  isLoading: PropTypes.bool,
  /**
   * Determines styling applied to table
   */
  kind: PropTypes.oneOf(['default', 'light', 'light-tall-row']),
}

Table.defaultProps = {
  isLoading: false,
  kind: 'default',
}

Table.displayName = 'Table'

export default injectSheet(styles)(Table)
