import React, { Fragment } from 'react'
import injectSheet from 'react-jss'

import { FormFieldItem, TextArea } from 'components'

import styles from '../PromotedPlacementsForm.styles'

const PlacementSearchKeywords = ({
  classes,
  componentId,
  labelText,
  placeholder,
  setFieldValue,
  tooltip,
}) => (
  <FormFieldItem
    className={classes.formRow}
    id={componentId}
    labelText={labelText}
    name="promotedSearchKeywords"
    render={renderProps => (
      <Fragment>
        <TextArea
          {...renderProps}
          className={classes.formInput}
          placeholder={placeholder}
          onChange={({ target: { value: newValue } }) => {
            setFieldValue(renderProps.name, newValue)
          }}
          type="text"
        />
        <span className={classes.formTooltip}>{tooltip}</span>
      </Fragment>
    )}
  />
)

export default injectSheet(styles)(PlacementSearchKeywords)
