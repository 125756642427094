import React, { Component } from 'react'
import injectSheet from 'react-jss'

import { SelectedItems, StatusIndicatorBadge } from 'components'

import styles from './CollectionProducts.styles'

const CollectionProductsRulesLayout = ({ actions, bulkAction, classes, items, pageId, id }) => null

class CollectionProductsRulesWithStateLayout extends Component {
  static statusTypes = ['new', 'in-progress', 'cancelled', 'complete', 'error']

  state = {
    slug: null,
    touched: false,
  }

  render() {
    const {
      dynamicCategories,
      dynamicAttributes,
      dynamicTags,
      onChange,
      onEdit,
      classes,
      items,
    } = this.props

    return (
      <SelectedItems
        className={classes.rulesBox}
        headerText={`Rules`}
        itemName={'rule'}
        items={items}
        onChange={onChange}
        onEdit={onEdit}
        noSelectedItemsMsg={'No rules have been added, the rules you add will show up here'}
        readOnly={!onChange}
        rowSeparator="OR"
        renderItem={({ item }) => {
          const { brand, category, attribute, tag, hasOffer, topSelling } = item
          const filters = []

          const getStatusType = index => {
            const { statusTypes } = this.constructor
            return statusTypes[index % statusTypes.length]
          }

          if (brand) {
            filters.push({
              label: 'Brand',
              statusType: getStatusType(0),
              value: brand,
            })
          }

          if (category) {
            const humanCategory =
              dynamicCategories?.find(cate => cate.id === category)?.name ?? null
            filters.push({
              label: 'Category',
              statusType: getStatusType(1),
              value: humanCategory || category,
            })
          }

          if (attribute) {
            const humanAttribute =
              dynamicAttributes?.find(attr => attr.id === attribute)?.name ?? null
            filters.push({
              label: 'Attribute',
              statusType: getStatusType(2),
              value: humanAttribute || attribute,
            })
          }

          if (tag) {
            const humanTag = dynamicTags?.find(attr => attr.id === tag)?.name ?? null
            filters.push({
              label: 'Tag',
              statusType: getStatusType(3),
              value: humanTag || tag,
            })
          }

          if (hasOffer) {
            filters.push({
              label: 'Offer Applied',
              statusType: getStatusType(4),
            })
          }

          if (topSelling) {
            filters.push({
              label: 'Top Selling',
              statusType: getStatusType(5),
            })
          }

          return (
            <div className={classes.ruleItem}>
              {filters.map((filter, index) => (
                <div className={classes.ruleWordWrap}>
                  {index !== 0 && <span className={classes.filterSeparator}>AND</span>}
                  <StatusIndicatorBadge
                    key={index}
                    type={filter.statusType}
                    text={
                      <>
                        <strong>
                          {filter.label}
                          {filter.value && ':'}
                        </strong>{' '}
                        {filter.value}
                      </>
                    }
                  />
                </div>
              ))}
            </div>
          )
        }}
      />
    )
  }
}

const collectionProductsRulesWithState = props => (
  <CollectionProductsRulesWithStateLayout {...props} />
)
const collectionProductsRules = props => <CollectionProductsRulesLayout {...props} />

export const CollectionProductsRules = injectSheet(styles)(collectionProductsRules)
export const CollectionProductsRulesWithState = injectSheet(styles)(
  collectionProductsRulesWithState
)
