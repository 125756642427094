import React, { useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'

import { uniqBy } from 'lodash'

import { DataTable, Modal, Button } from 'components'

import { toCurrency } from 'utils'

import NestedObjectItem from './NestedObjectItem'
import CartOfferModalContent from './CartOfferModalContent'

const cartHeaders = [
  { key: 'name', header: 'Name' },

  {
    key: 'ids',
    header: 'Related IDs',
  },
  { key: 'price', header: 'Price' },
  { key: 'offer_amount', header: 'Product Offers' },
]

const useStyles = createUseStyles(theme => ({
  nameColumn: {
    '& img': {
      width: '32px',
      marginRight: '8px',
    },
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    margin: '16px 0 8px 0',
    color: theme.text01,
  },
}))

const CartTable = props => {
  const { data } = props
  const styles = useStyles()
  const [showOfferModal, setShowOfferModal] = useState(false)
  const [modalOfferId, setModalOfferId] = useState()
  const [storeId, setStoreId] = useState(data.items[0].store_id)

  const rows = useMemo(() => {
    const productOfferRewards = {}
    data.offer_progress.forEach(offerProgress => {
      for (const productId of Object.keys(offerProgress.product_discounts)) {
        const offerId = offerProgress.offer.id
        const rewardsByOffer = productOfferRewards[productId] || {}
        let discountAmount = rewardsByOffer[offerId] || 0.0
        discountAmount += offerProgress.product_discounts[productId]
        rewardsByOffer[offerId] = discountAmount
        productOfferRewards[productId] = rewardsByOffer
      }
    })
    return data.items.map(item => {
      const offerList = item.store_product.offers || []
      const appliedOffers = offerList.filter(
        offer => offer.offer_type !== 'coupon' || offer.clipped === true
      )
      const unclippedOffers = offerList.filter(
        offer => offer.offer_type === 'coupon' && offer.clipped === false
      )

      return {
        id: item.id,
        name: (
          <div className={styles.nameColumn}>
            <img src={item.store_product.images.tile.small} alt="img" />
            <span>{item.store_product.name}</span>
          </div>
        ),
        ids: (
          <NestedObjectItem
            data={{
              'Unata Product Id': item.id,
              'IC Product Id': item.store_product.ic_product_id,
              'IC Item Id': item.store_product.ic_item_id,
              'Order by weight': item.store_product.order_by_weight ? 'true' : 'false',
              'Weighted Item': item.store_product.weighted_item ? 'true' : 'false',
            }}
          />
        ),
        price: (
          <NestedObjectItem
            data={{
              'Base Price': `${toCurrency(item.store_product.base_price)}`,
              'Unit Price': `${toCurrency(item.unit_price)}`,
              Quantity: item.quantity,
              Subtotal: `${toCurrency(item.subtotal)}`,
            }}
          />
        ),
        offer_amount: (
          <ul>
            {productOfferRewards[item.id] &&
              Object.keys(productOfferRewards[item.id]).map(offerId => {
                return (
                  <li>
                    <Button
                      onClick={() => {
                        setModalOfferId(offerId)
                        setShowOfferModal(true)
                      }}
                      kind="link"
                    >
                      Offer {offerId}
                    </Button>
                    : {toCurrency(productOfferRewards[item.id][offerId])}
                  </li>
                )
              })}
          </ul>
        ),
      }
    })
  }, [data, styles.nameColumn])

  return (
    <div>
      <DataTable headers={cartHeaders} id="cart-table" rows={rows} pagingProps={{}} />
      <Modal
        showClose
        modalHeading={`Offer Details for Offer ID: ${modalOfferId}`}
        isOpen={showOfferModal}
        handleCloseModal={() => {
          setShowOfferModal(false)
          setModalOfferId(undefined)
        }}
        render={() =>
          modalOfferId && <CartOfferModalContent storeId={storeId} offerId={modalOfferId} />
        }
      />
    </div>
  )
}

export default CartTable
