import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { createToast } from 'modules/toasts'
import { createFetchRequest, createDeleteRequest } from 'api'

import requestTypes from './requestTypes'
import CreateRequestForm from './CreateRequestForm'

const CreateRequest = ({ createToast, closeModal }) => {
  const handleSubmit = async (values, form) => {
    const {
      customer: { userId, userEmail },
      type,
      notes,
    } = values

    const service = type === requestTypes.delete.value ? createDeleteRequest : createFetchRequest
    try {
      await service({ userId, userEmail, notes })
      closeModal({ requiresRefetch: true })
      createToast({
        kind: 'success',
        message: `Privacy request for ${userEmail} has successfully been created.`,
      })
    } catch (error) {
      form.setSubmitting(false)
      form.setErrors({ global: error.message })
      form.setStatus({ ...form.status, showConfirmation: false })
    }
  }

  return <CreateRequestForm onSubmit={handleSubmit} onCancel={closeModal} />
}

const mapDispatchToProps = dispatch => bindActionCreators({ createToast }, dispatch)

export default connect(null, mapDispatchToProps)(CreateRequest)
