export const formatParentPath = allParentNames => {
  if (allParentNames.length > 0) {
    return `${allParentNames.join(' / ')} /`
  }
  return ''
}

// Get total count of `category`'s children (and grandchildren, etc.) in `categories`
export const getNumTotalChildren = (category, categories) => {
  if (category.children) {
    return category.children.reduce((childrenSum, child) => {
      // need to do since category.children don't each have `.children`
      const childCategory = categories.find(testCategory => testCategory.id === child.id)
      return childrenSum + getNumTotalChildren(childCategory, categories)
    }, category.children.length)
  }
  return 0
}

// Get all of `category`'s parents (and grandparents, etc.) in `categories`
export const findAllParentNames = (category, categories) => {
  if (category && category.parent) {
    const parent = categories.find(testCategory => testCategory.id === category.parent.id)
    return [...findAllParentNames(parent, categories), parent.name]
  }
  return []
}

export const formatChildCount = numTotalChildren => {
  if (numTotalChildren > 0) {
    return ` (${numTotalChildren})`
  }
  return ''
}

/**
 * To `categories`...
 *   - prepare data for the TreeSelect component in "See All Categories"
 *   - add child and parent data used in the Category Autocomplete dropdown
 *   - add fields and reduce
 * @param {Array} categories
 */
export const transformCategoryData = categories =>
  categories.map(category => ({
    name: category.name,
    // Used in `TreeSelect`
    id: category.id,
    title: category.name,
    // NOTE: The `value` is set to `id` so we can use <TreeSelect>'s `checkTreeNode`/`uncheckTreeNode` by passing in the category id
    value: category.id,
    pId: category.parent ? category.parent.id : null, // null will map to the `rootPId` for `TreeSelect`
    // Used in `Autocomplete`
    parentPath: formatParentPath(findAllParentNames(category, categories)),
    childCount: formatChildCount(getNumTotalChildren(category, categories)),
    // Include the level for use with search keyword rules
    level: category.level,
  }))
