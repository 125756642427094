import { useMemo, useEffect } from 'react'
import { useQuery } from '@instacart/enterprise-services-hooks'
import axios from 'axios'
import deepEqual from 'fast-deep-equal'

import { DEFAULT_TABLE_LIMIT } from 'defaults'
import { usePrevious } from 'utils/hooks'
import { queryString as queryStringUtil, filterUndefinedValues, isEmpty } from 'utils'

import {
  parseParams,
  generateNewParamsWithOnChangeHandlers,
  paramsToKeyValuePairs,
  getItemsFromData,
} from './itemsFetcherUtils'

/**
 * Hook to fetch a query with many items. Uses React Query under the hood.
 *
 * @param {string} queryKey - Key to use for query cache. Cache also takes params into account when generating the final cache key.
 * @param {func} fetchItems - Function to call to fetch. First paramater passed is query string, second paramater is config.
 * @param {Object} paramDefinitions - Object of param definitions, each should specify a `key` and optional `type`.
 *    Each param key (on the top-level object) will be the same key used in `paramValues` (used by the consumer in rendering).
 * @param {Object} routingParams - Routing params from React Router.
 * @param {Object} routingParams.location - Location data from React Router.
 * @param {Object} routingParams.history - History data from React Router.
 * @param {Object} options
 * @param {number} options.defaultItemsLimit - The amount of items fetched if no `limit` param (from location.search) is provided.
 * @param {Object} options.defaultQueryParams - Default query params if none are set in the current location.search.
 * @param {string} options.fetchOnMount - Whether the fetch should be called automatically on mount.
 * @param {string} options.fetchOnEmptyQuery - Whether the fetch should be called if the search params are empty.
 * @param {string} options.hasInfinitePagination - Whether infinite pagination is enabled (allows calling "load more" to append items to the list).
 * @returns {Object} response
 * @returns {Object} response.items - The queried items
 * @returns {boolean} response.isFetchingItems - Whether the items are being fetched or refetched
 * @returns {boolean} response.isLoadingItems - Whether the items are being loaded
 * @returns {Object} response.error
 * @returns {func} response.refetch - To manually refetch the query
 * @returns {Object} response.otherData - Any other data from the response (excluding items).
 */
export const useItemsFetcherWithParams = (
  queryKey,
  fetchItems,
  paramDefinitions,
  routingParams,
  options
) => {
  const optionDefaults = {
    refetchOnWindowFocus: false,
    fetchOnMount: true,
    defaultItemsLimit: DEFAULT_TABLE_LIMIT,
    fetchOnEmptyQuery: true,
    hasInfinitePagination: false,
    defaultQueryParams: {},
  }

  const optionsWithDefaults = {
    ...optionDefaults,
    ...filterUndefinedValues(options),
  }

  const parsedParams = useMemo(
    () =>
      parseParams({
        paramsToParse: paramDefinitions,
        routeQueryString: routingParams.location ? routingParams.location.search : null,
      }),
    [paramDefinitions, routingParams]
  )

  const queryParamsFromParams = useMemo(
    () => filterUndefinedValues(paramsToKeyValuePairs(parsedParams)),
    [parsedParams]
  )

  const paramsWithOnChangeHandlers = useMemo(
    () =>
      generateNewParamsWithOnChangeHandlers({
        params: parsedParams,
        history: routingParams.history,
      }),
    [routingParams, parsedParams]
  )

  const performFetch = ({ next = '' }) => {
    // Used to cancel the request if inactive (eg. on unmount)
    const cancelTokenSource = axios.CancelToken.source()

    const queryParamsFromParams = filterUndefinedValues(paramsToKeyValuePairs(parsedParams))

    // Use defaultQueryParams if queryParamsFromParams is empty
    const queryParams =
      queryParamsFromParams && !isEmpty(queryParamsFromParams)
        ? queryParamsFromParams
        : optionsWithDefaults.defaultQueryParams

    const queryStringFromParams = queryStringUtil.stringify({
      limit: optionsWithDefaults.defaultItemsLimit,
      ...queryParams,
      // Add pagination cursor to query params if enabled
      ...(optionsWithDefaults.hasInfinitePagination && next ? { next } : {}),
    })
    const queryString = `?${queryStringFromParams}`

    // Prevent fetch if query params are empty and fetchOnEmptyQuery disabled
    if (isEmpty(queryParams) && !optionsWithDefaults.fetchOnEmptyQuery) return Promise.resolve()

    // Previous usage expects fetchItems to take in a query string and query options
    // as the paramters, so this follows that format.
    const fetchPromise = fetchItems(queryString, {
      cancelToken: cancelTokenSource.token,
    })

    fetchPromise.cancel = () => {
      cancelTokenSource.cancel('Pending fetch cancelled as is it no longer needed.')
    }

    return fetchPromise
  }

  const {
    data,
    isFetching: isFetchingItems,
    isLoading: isLoadingItems,
    isFetchingMore,
    canFetchMore,
    error,
    refetch,
    fetchMore,
  } = useQuery([queryKey, parsedParams], performFetch, {
    refetchOnWindowFocus: optionsWithDefaults.refetchOnWindowFocus,
    manual: !optionsWithDefaults.fetchOnMount,
    suspense: false,
    paginated: optionsWithDefaults.hasInfinitePagination,
    // This will be used if infinite pagination is enabled
    getCanFetchMore: lastPage => !!lastPage.next,
  })

  const previousParsedParams = usePrevious(parsedParams)

  useEffect(() => {
    // Refetch if params change and fetchOnMount is false (AKA "manual" mode is enabled in React Query)
    if (!optionsWithDefaults.fetchOnMount && !deepEqual(parsedParams, previousParsedParams)) {
      refetch()
    }
  })

  const { items, otherData } = getItemsFromData(data, optionsWithDefaults.hasInfinitePagination)

  const loadMore = () => {
    const lastPage = data[data.length - 1]
    const { next } = lastPage || {}
    return fetchMore({ next })
  }

  return {
    params: paramsWithOnChangeHandlers,
    queryParams: queryParamsFromParams,
    items,
    error,
    refetch,
    isFetchingItems,
    isLoadingItems,
    isFetchingMore,
    canFetchMore,
    loadMore,
    otherData,
  }
}
