import React from 'react'
import injectSheet from 'react-jss'
import { connect } from 'react-redux'

import { Button, PageHeader, ReadOnlyValueCard } from 'components'
import { getRouteTo } from 'routing'

const styles = theme => ({
  readOnlyValuesContainer: {
    width: 464,
  },
})

const MyProfile = ({ classes, user }) => {
  const userRoles = user.roles.map(role => role.name)
  return (
    <div>
      <PageHeader headerTitle="My Profile">
        <Button href={getRouteTo('myProfile.editMyProfile')}>Update Profile</Button>
      </PageHeader>
      <div className={classes.readOnlyValuesContainer}>
        <ReadOnlyValueCard
          detailValues={[
            {
              id: 'myProfile-name',
              labelText: 'Name',
              value: `${user.firstName} ${user.lastName}`,
            },
            {
              id: 'myProfile-email',
              labelText: 'Email',
              value: user.email,
            },
            {
              id: 'myProfile-role',
              labelText: 'Role',
              value: userRoles,
              valueType: 'list',
            },
          ]}
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  user: state.user.data,
})

export default injectSheet(styles)(connect(mapStateToProps)(MyProfile))
