import React from 'react'
import injectSheet from 'react-jss'

import { FormFieldItem, RadioButtons } from 'components'

import styles from '../PromotedPlacementsForm.styles'

const PlacementUserVisibility = ({
  classes,
  componentId,
  fieldItemName = 'hasLoyalty',
  labelText,
  radioButtonProps,
  setFieldValue,
}) => (
  <FormFieldItem
    className={classes.formRow}
    id={componentId}
    labelText={labelText}
    name={fieldItemName}
    render={({ id, name, value }) => (
      <RadioButtons
        className={classes.formInput}
        id={id}
        name={name}
        onChange={newUserVisibility => {
          setFieldValue(name, newUserVisibility)
        }}
        orientation="horizontal"
        selectedValue={value}
        type="text"
        radioButtonProps={radioButtonProps}
      />
    )}
  />
)

export default injectSheet(styles)(PlacementUserVisibility)
