import isEmpty from 'lodash/isEmpty'
import React, { Component } from 'react'
import injectSheet from 'react-jss'

import { fetchCollection } from 'api'
import { AddCollections, FormFieldItem, LoadingIndicator } from 'components'

import { reorder } from 'components/DataTable/utils/reorder'

import styles from '../PromotedPlacementsForm.styles'

class PlacementCollections extends Component {
  state = {
    errorFetchingCollections: false,
    existingCollections: [],
    isFetchingCollections: false,
  }

  componentWillMount() {
    if (this.props.existingPlacement) {
      const {
        existingPlacement: {
          target: { collection_list },
        },
      } = this.props
      const loadCollections = async () => {
        this.setState({ isFetchingCollections: true })
        const collections = await Promise.all(
          collection_list.map(({ id }) =>
            fetchCollection(id).catch(e =>
              this.setState({ errorFetchingCollections: true, isFetchingCollections: false })
            )
          )
        )

        const existingCollections = {}
        collections.forEach(collection => {
          existingCollections[collection.id] = collection
        })

        this.setState({
          existingCollections,
          isFetchingCollections: false,
        })
      }

      loadCollections()
    }
  }

  render() {
    const { classes, componentId, componentName, labelText, setFieldValue } = this.props
    const { existingCollections, isFetchingCollections } = this.state

    return !isFetchingCollections ? (
      <FormFieldItem
        className={classes.addCollectionContainer}
        id={componentId}
        labelText={labelText}
        name={componentName}
        render={renderProps => {
          const { value: selectedCollections } = renderProps

          // Since we do not have product image data from the `/content` endpoint, we need to
          // use `/product_collections` data that we would have retrieved in state if this is an existing placement
          // to populate and pass it onto <AddCollections>... not great
          if (!isEmpty(selectedCollections) && !isEmpty(existingCollections)) {
            selectedCollections.map(selectedCollection => {
              const { id } = selectedCollection
              if (existingCollections[id]) {
                return (selectedCollection.images = existingCollections[id].products)
              }
              return null
            })
          }

          return (
            <AddCollections
              {...renderProps}
              onChange={newSelectedCollections => {
                setFieldValue(componentName, newSelectedCollections)
              }}
              onDragEnd={params => {
                const newCollectionsOrder = reorder(
                  selectedCollections,
                  params.source.index,
                  params.destination.index
                )
                setFieldValue(componentName, newCollectionsOrder)
              }}
              selectedCollections={selectedCollections}
            />
          )
        }}
      />
    ) : (
      <LoadingIndicator withTopMargin />
    )
  }
}

export default injectSheet(styles)(PlacementCollections)
