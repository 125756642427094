import { Formik } from 'formik'
import React from 'react'
import injectSheet from 'react-jss'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Button, FormFieldItem, Notification, PageHeader, TextInput } from 'components'
import { createToast } from 'modules/toasts'
import { changeUserPassword } from 'modules/user'
import { getRouteTo } from 'routing'
import Styling from 'styling/components'

const styles = theme => ({
  formContainer: {
    width: 368,
  },
})

const ChangePassword = ({ changeUserPassword, classes, createToast, history, user }) => (
  <div>
    <PageHeader hasDivider headerTitle="Change Password" />
    <div className={classes.formContainer}>
      <Formik
        initialValues={{
          oldPassword: '',
          newPassword: '',
          confirmNewPassword: '',
        }}
        validate={values => {
          const errors = {}
          if (!values.oldPassword) {
            errors.oldPassword = 'Current password required'
          } else if (!values.newPassword) {
            errors.newPassword = 'New password required'
          } else if (!values.confirmNewPassword) {
            errors.confirmNewPassword = 'Confirm password required'
          } else if (values.newPassword !== values.confirmNewPassword) {
            errors.global = '"New Password" and "Confirm Password" do not match'
          }
          return errors
        }}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values, form) => {
          const userId = user.id

          return changeUserPassword(userId, values)
            .then(() => {
              form.setSubmitting(false)
              createToast({ kind: 'success', message: 'User update succeeded.' })
              history.push(getRouteTo('myProfile'))
            })
            .catch(error => {
              form.setSubmitting(false)
              form.setErrors({ global: error.message })
            })
        }}
        render={({ errors, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <FormFieldItem
              id="change-password-current"
              labelText="Current Password"
              name="oldPassword"
              render={renderProps => (
                <TextInput
                  {...renderProps}
                  autoFocus
                  placeholder="Current Password"
                  type="password"
                />
              )}
            />
            <FormFieldItem
              id="change-password-new"
              labelText="New Password"
              name="newPassword"
              render={renderProps => (
                <TextInput {...renderProps} placeholder="New Password" type="password" />
              )}
            />
            <FormFieldItem
              id="change-password-confirm"
              labelText="Confirm Password"
              name="confirmNewPassword"
              render={renderProps => (
                <TextInput {...renderProps} placeholder="Confirm Password" type="password" />
              )}
            />

            {!isSubmitting && errors.global && (
              <Notification kind="error" message={errors.global} />
            )}
            <Styling.LineOfButtons>
              <Button className={classes.confirmButton} type="submit" disabled={isSubmitting}>
                Change Password
              </Button>
              <Button kind="link" href={getRouteTo('myProfile')}>
                Cancel
              </Button>
            </Styling.LineOfButtons>
          </form>
        )}
      />
    </div>
  </div>
)

const mapStateToProps = state => ({
  user: state.user.data,
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changeUserPassword,
      createToast,
    },
    dispatch
  )

export default injectSheet(styles)(connect(mapStateToProps, mapDispatchToProps)(ChangePassword))
