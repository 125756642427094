import React from 'react'
import injectSheet from 'react-jss'

import * as placementStyling from 'constants/placementStyles'

import { FormFieldItem, RadioButtons } from 'components'

import styles from '../PromotedPlacementsForm.styles'

const PlacementStyling = ({
  classes,
  componentId,
  fieldItemName = 'styling',
  labelText,
  setFieldValue,
}) => (
  <FormFieldItem
    className={classes.formRow}
    id={componentId}
    labelText={labelText}
    name={fieldItemName}
    render={({ id, name, value }) => (
      <RadioButtons
        className={classes.formInput}
        id={id}
        name={name}
        onChange={newTheme => {
          setFieldValue(name, newTheme)
        }}
        orientation="horizontal"
        selectedValue={value}
        type="text"
        radioButtonProps={[
          {
            id: `${fieldItemName}-styling-light`,
            labelText: 'Light',
            value: placementStyling.LIGHT,
          },
          {
            id: `${fieldItemName}-styling-dark`,
            labelText: 'Dark',
            value: placementStyling.DARK,
          },
        ]}
      />
    )}
  />
)

export default injectSheet(styles)(PlacementStyling)
