import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { cloneElement } from 'react'

import { Icon, Tooltip } from 'components'

import SelectedItemCell from './SelectedItemCell'

const SelectedItemRow = ({
  className,
  classes,
  children,
  isDraggable,
  hasRowSeparator,
  ...restProps
}) => {
  // Set up drag and drop functionality if it exists
  let dragRowProps = {}
  const { provided, snapshot } = restProps
  const isDraggableValid = !!(isDraggable && provided)

  if (isDraggableValid) {
    dragRowProps = {
      ref: provided.innerRef,
      ...provided.draggableProps,
      ...provided.dragHandleProps,
    }
  }

  const isDragOccurring = snapshot && snapshot.isDragging

  const wrappedChildren = React.Children.map(children, (child, index) => {
    return child && cloneElement(child, { key: index, isDragOccurring })
  })

  return (
    <li
      {...restProps}
      className={cx(classes.row, classes.itemRow, {
        [classes.itemRowWithSeparator]: hasRowSeparator,
        [classes.rowDragging]: isDragOccurring,
      })}
      {...dragRowProps}
    >
      <div className={cx(className, classes.itemRowInner)}>
        {isDraggableValid && (
          <SelectedItemCell className={classes.dragColumnCell} isDragOccurring={isDragOccurring}>
            <Tooltip text="Drag to sort">
              <Icon name="drag" className={classes.dragColumnCellIcon} />
            </Tooltip>
          </SelectedItemCell>
        )}
        {wrappedChildren}
      </div>
    </li>
  )
}

SelectedItemRow.propTypes = {
  /**
   * Add drag and drop to selected items.
   */
  isDraggable: PropTypes.bool,
  hasRowSeparator: PropTypes.bool,
}

export default SelectedItemRow
