import Downshift from 'downshift'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import injectSheet from 'react-jss'

import { DatePickerRange, Icon, ListBox, ShowCollapse } from 'components'

const styles = theme => ({
  dropdownDate: {
    maxHeight: 'none !important',
  },
  datePickerMenuItem: {
    height: 'auto !important',
  },
  datePickerShowCollapse: {
    width: '100%',
  },
  datePickerShowCollapseText: {
    fontSize: theme.fontSize.p,
    color: theme.text01,
    fontFamily: theme.fontFamilySansSerif,
  },
  datePickerInformation: {
    fontSize: theme.fontSize.p,
    color: theme.text01,
    fontFamily: theme.fontFamilySansSerif,
    display: 'flex',
    alignItems: 'start',
    marginBottom: 5,
  },
  icon: {
    height: 20,
    marginRight: 5,
  },
})

const defaultDateOptions = [
  { id: 'clear', label: 'Anytime' },
  {
    id: 'tdy',
    label: 'Today',
    todayDeltas: {
      from: 0,
      to: 0,
    },
  },
  {
    id: 'tmrw',
    label: 'Tomorrow',
    todayDeltas: {
      from: 1,
      to: 1,
    },
  },
  {
    id: 'nxtSev',
    label: 'Next 7 Days',
    todayDeltas: {
      from: 1,
      to: 7,
    },
  },
  {
    id: 'ystr',
    label: 'Yesterday',
    todayDeltas: {
      from: -1,
      to: -1,
    },
  },
  {
    id: 'lstSev',
    label: 'Last 7 Days',
    todayDeltas: {
      from: -7,
      to: -1,
    },
  },
  {
    id: 'lstThirty',
    label: 'Last 30 Days',
    todayDeltas: {
      from: -30,
      to: -1,
    },
  },
  {
    id: 'custom',
    label: 'Custom',
  },
]

const DATE_FORMAT = 'YYYY-MM-DD'

const generateItemDateRange = item => {
  // Item values can be provided through `todayDeltas` objects which have a `from` and `to` offset value
  // (Ex: if an item has a `todayDeltas` of `{ from: 2, to: 4 }`, it means the date range for that item
  //  is from today + 2 to today + 4. So if today is June 1st, then the date range is June 3rd to June 5th.)
  if (item && item.todayDeltas) {
    const { from: fromDelta, to: toDelta } = item.todayDeltas
    return {
      from: moment()
        .startOf('day')
        .add(fromDelta, 'days'),
      to: moment()
        .endOf('day')
        .add(toDelta, 'days'),
    }
  }
  return { from: null, to: null }
}

const getItemDateRanges = items =>
  items.map(item => ({ ...item, dateRange: generateItemDateRange(item) }))

/**
 * (from, to) => <item in items with corresponding dates>
 *
 * Expects `from` & `to` to be "moment"s
 */
const findCorrespondingItem = (from, to, items) => {
  if (!from || !to || !items) {
    return null // Do not select any items in the dropdown
  }

  const correspondingItem = items.find(
    item =>
      item.dateRange.from &&
      item.dateRange.from.isSame(from, 'day') &&
      item.dateRange.to &&
      item.dateRange.to.isSame(to, 'day')
  )
  // Return item with matching date range in list of items, or custom item
  return correspondingItem || items.find(item => item.id === 'custom')
}

/**
 * Given a `selectedValue`, and `items` returns the date values to save to
 * the state (the to/from moment values and the corresponding item in `items`).
 */
const getStateDateValues = (selectedValue, items) => {
  if (selectedValue) {
    const { from, to } = selectedValue
    const fromValue = from ? moment(from).startOf('day') : null
    const toValue = to ? moment(to).endOf('day') : null

    return {
      from: fromValue,
      to: toValue,
      selectedItem: findCorrespondingItem(fromValue, toValue, items),
    }
  }
}

class DropdownDate extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    /**
     * If enabled, user will be given a "No End Date" checkbox option in DatePickerRange where `null` will always be passed for the `to` value
     */
    enableNoEndDate: PropTypes.bool,
    /**
     * Determines if we should show options like "Anytime", "Tomorrow", "Next 7 Days", etc. or if we just show a DatePickerRange
     */
    hasDateOptions: PropTypes.bool,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    /**
     * Used to control the component/provide a date range that will set the selected item if applicable.
     */
    defaultValue: PropTypes.shape({
      from: PropTypes.string,
      to: PropTypes.string,
    }),
    /**
     * Determines whether or not the values we send to the consumer in `onChange` are in the datetime format (ISO_8601)
     * or the date format ('YYYY-MM-DD').
     */
    returnDatetimeValues: PropTypes.bool,
    /**
     * Only allow the user to select the `to` value of the date range
     * defaultValue.from MUST be provided
     */
    fixedStartDate: PropTypes.bool,
    /**
     * Allow users to send in custom date options for filtering based on `defaultDateOptions`
     */
    customDateOptions: PropTypes.array,
  }

  static defaultProps = {
    defaultValue: {
      from: null,
      to: null,
    },
    returnDatetimeValues: true,
  }

  constructor(props) {
    super(props)
    this.state = {}
  }

  static getDerivedStateFromProps(props) {
    let dateOptions = defaultDateOptions
    if (props.customDateOptions?.length) {
      dateOptions = props.customDateOptions
    }
    // Setup date range options ("Anytime", "Tomorrow", "Next 7 Days", etc.) if required
    const items = props.hasDateOptions ? getItemDateRanges(dateOptions) : null

    return {
      items,
      ...getStateDateValues(props.defaultValue, items),
    }
  }

  /**
   * (datePickerRangeDate, selectedItem) => {...}
   *
   * NOTE: `datePickerRangeDate` will always have `to`/`from` values that are formatted as "YYYY-MM-DD"
   */
  handleChange = ({ from, to }, selectedItem) => {
    const { returnDatetimeValues } = this.props

    // Update the state and pass up the new values to the consumer if `onChange` prop was provided
    const newFromValue = from ? moment(from).startOf('day') : null
    const newToValue = to ? moment(to).endOf('day') : null
    this.setState({ from: newFromValue, to: newToValue, selectedItem })

    if (this.props.onChange) {
      if (newFromValue && newToValue) {
        if (returnDatetimeValues) {
          this.props.onChange({
            from: newFromValue.format(moment.ISO_8601()),
            to: newToValue.format(moment.ISO_8601()),
          })
        } else {
          this.props.onChange({
            from: newFromValue.format(DATE_FORMAT),
            to: newToValue.format(DATE_FORMAT),
          })
        }
      } else {
        // When the from/to dates are null, send the null values back without attempting to format them
        this.props.onChange({ from: newFromValue, to: newToValue })
      }
    }
  }

  render() {
    const {
      classes,
      className: containerClassName,
      disabled: isDisabled,
      enableNoEndDate,
      fixedStartDate,
      id,
      isInvalid,
      placeholder,
    } = this.props

    const { from, to, selectedItem, items } = this.state

    return (
      <Downshift
        selectedItem={selectedItem}
        id={`${id}-ds`}
        itemToString={item => (typeof item === 'string' ? item : (item && item.label) || '')}
        onChange={selectedItem => {
          if (selectedItem && selectedItem.id !== 'custom' && selectedItem.dateRange) {
            // Date range option was selected ("Anytime", "Next 7 Days", etc.)
            this.handleChange(selectedItem.dateRange, selectedItem)
          }
        }}
      >
        {({
          clearSelection,
          getButtonProps,
          getItemProps,
          getLabelProps,
          getRootProps,
          highlightedIndex,
          isOpen,
          selectedItem,
          selectItem,
          toggleMenu,
        }) => {
          const isCustomDateItemSelected = selectedItem && selectedItem.id === 'custom'
          const fieldHasAValue = !!(from && to) || (from && !to && enableNoEndDate)

          const fromDisplay = from ? from.format(DATE_FORMAT) : null
          const toDisplay = to ? to.format(DATE_FORMAT) : null

          const datePickerRangeDate = {
            from: fromDisplay,
            to: toDisplay,
          }

          const labelString =
            from && (to || (!to && enableNoEndDate))
              ? `${fromDisplay} - ${toDisplay || 'Forever'}`
              : placeholder

          return (
            <ListBox
              className={containerClassName}
              id={id}
              isDisabled={isDisabled}
              {...getRootProps({ refKey: 'setRef' })}
            >
              <ListBox.Field
                isDisabled={isDisabled}
                isInvalid={isInvalid}
                isSelected={fieldHasAValue}
                {...getButtonProps({ disabled: isDisabled })}
              >
                <ListBox.Icon name="calendar" />
                <ListBox.Label
                  {...getLabelProps()}
                  isDisabled={isDisabled}
                  isSelectedItem={fieldHasAValue}
                  text={labelString}
                />
                <ListBox.MenuIcon isDisabled={isDisabled} isOpen={isOpen} />
              </ListBox.Field>
              {isOpen && (
                <ListBox.Menu className={classes.dropdownDate}>
                  {!items && (
                    <ListBox.MenuItem className={classes.datePickerMenuItem}>
                      <DatePickerRange
                        enableNoEndDate={enableNoEndDate}
                        fixedStartDate={fixedStartDate}
                        id={`${id}-date-picker`}
                        onChange={datePickerRangeDate => {
                          if (
                            datePickerRangeDate.from &&
                            (datePickerRangeDate.to ||
                              (!datePickerRangeDate.to && datePickerRangeDate.noEndDate))
                          ) {
                            toggleMenu()
                            this.handleChange(datePickerRangeDate)
                          }
                        }}
                        defaultValue={datePickerRangeDate}
                      />
                    </ListBox.MenuItem>
                  )}
                  {items &&
                    items.map((item, index) => {
                      const itemProps = getItemProps({ item, index })

                      return item.id !== 'custom' ? (
                        <ListBox.MenuItem
                          key={`${id}-${item.id}`}
                          isActive={selectedItem === item}
                          isHighlighted={highlightedIndex === index}
                          {...itemProps}
                        >
                          {item.label}
                        </ListBox.MenuItem>
                      ) : (
                        <ListBox.MenuItem
                          className={classes.datePickerMenuItem}
                          key={`${id}-${item.id}`}
                          onMouseMove={itemProps.onMouseMove}
                        >
                          <ShowCollapse
                            keepOpen={isCustomDateItemSelected}
                            contentTopPadding="md"
                            className={classes.datePickerShowCollapse}
                            trigger={
                              <span className={classes.datePickerShowCollapseText}>
                                {item.label}
                              </span>
                            }
                            content={
                              <>
                                {item.tooltip && (
                                  <span className={classes.datePickerInformation}>
                                    <Icon name="info" className={classes.icon} />
                                    {item.tooltip}
                                  </span>
                                )}
                                <DatePickerRange
                                  id={`${id}-date-picker`}
                                  hideEndDate={Boolean(item.hideEndDate)}
                                  onChange={datePickerRangeDate => {
                                    if (datePickerRangeDate.from && datePickerRangeDate.to) {
                                      selectItem(item)
                                      this.handleChange(datePickerRangeDate, item) // selectedItem will exist if DropdownDate has date options
                                    } else if (
                                      datePickerRangeDate.from &&
                                      !datePickerRangeDate.to &&
                                      item.hideEndDate
                                    ) {
                                      selectItem(item)
                                      this.handleChange(
                                        {
                                          from: datePickerRangeDate.from,
                                          to: datePickerRangeDate.from,
                                        },
                                        item
                                      )
                                    }
                                  }}
                                  defaultValue={datePickerRangeDate}
                                />
                              </>
                            }
                          />
                        </ListBox.MenuItem>
                      )
                    })}
                </ListBox.Menu>
              )}
            </ListBox>
          )
        }}
      </Downshift>
    )
  }
}

export { DropdownDate as DropdownDateUnStyled }
export default injectSheet(styles)(DropdownDate)
