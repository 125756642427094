export default theme => ({
  notification: {
    fontSize: theme.fontSize.body,
    alignItems: 'center',
    borderRadius: 3,
    color: theme.text01,
    display: 'flex',
    fontFamily: theme.fontFamilySansSerif,
    justifyContent: 'space-between',
    minHeight: 40,
    padding: `${theme.spacing.sm} ${theme.spacing.md}`,
    width: '100%',
  },
  notificationTextContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  notificationIcon: {
    flexShrink: 0,
  },
  notificationDetails: {
    alignItems: 'center',
    display: 'flex',
    flexShrink: 1,
    marginLeft: theme.spacing.sm,
    textAlign: 'left',
  },
  notificationTitle: {
    fontWeight: 600,
    marginRight: theme.spacing.md,
    textTransform: 'uppercase',
  },
  notificationAction: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    flexShrink: 0,
    marginLeft: theme.spacing.xs,
    padding: 0,
    font: 'inherit',
    fontWeight: 'bold',
    fontSize: theme.fontSize.p,
    color: theme.text01,
  },

  notificationError: {
    color: theme.support01,
    border: `1px solid ${theme.support01}`,

    '& $notificationIcon': {
      fill: theme.support01,
    },
    '& $notificationTitle': {
      color: theme.support01,
    },
  },

  notificationSuccess: {
    border: `1px solid ${theme.support02}`,

    '& $notificationIcon': {
      fill: theme.support02,
    },
    '& $notificationTitle': {
      color: theme.support02,
    },
  },

  notificationInfo: {
    border: `1px solid ${theme.brand01}`,

    '& $notificationIcon': {
      fill: theme.brand01,
    },
    '& $notificationTitle': {
      color: theme.brand01,
    },
  },

  notificationWarning: {
    border: `1px solid ${theme.support03}`,

    '& $notificationIcon': {
      fill: theme.support03,
    },
    '& $notificationTitle': {
      color: theme.support03,
    },
  },

  notificationNoBorder: {
    border: '1px solid transparent',
    fontSize: theme.fontSize.p,
    padding: `${theme.spacing.md} 0`,

    '& $notificationIcon': {
      height: 14,
      width: 14,
    },
  },
})
