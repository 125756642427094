export default theme => ({
  filtersBottom: {
    display: 'flex',
    flexGrow: 1,
    marginBottom: theme.spacing.lg,

    '& > *': {
      marginBottom: 0,
      maxWidth: 230,
      marginRight: theme.spacing.xs,
      minWidth: 100,

      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  actions: {
    alignItems: 'center',
    display: 'flex',
    marginLeft: theme.spacing.lg,
  },
  filtersAndActions: {
    display: 'flex',
    marginBottom: theme.spacing.lg,
  },
  rightOfHeaderContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    width: 790,

    '& > *': {
      marginBottom: 0,
      marginLeft: theme.spacing.md,
    },
  },
  searchInput: {
    width: 340,
  },
})
