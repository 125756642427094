import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import injectSheet from 'react-jss'

import { Icon, Tooltip } from 'components'

import styles from './Button.styles'

/*
 * TODO - refactor this file to use css rather than an icon.
 */

class ButtonSlideToggle extends Component {
  static propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    isToggled: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
    toggledDescription: PropTypes.string,
    untoggledDescription: PropTypes.string,
  }

  handleClick = () => {
    const { isToggled, onClick } = this.props

    if (onClick) {
      const newIsToggled = !isToggled
      onClick(newIsToggled)
    }
  }

  render() {
    const {
      classes,
      className,
      id,
      isToggled,
      toggledDescription,
      untoggledDescription,
    } = this.props

    const buttonClasses = cx(className, classes.button, classes.buttonSlideToggle, {
      [classes.buttonSlideToggleToggled]: isToggled,
    })

    const toggleDescription = isToggled ? toggledDescription : untoggledDescription

    const buttonIconState = isToggled ? 'toggleOn' : 'toggleOff'

    const buttonProps = {
      'aria-label': toggleDescription,
      'aria-pressed': isToggled,
      id,
      onClick: this.handleClick,
      type: 'button',
    }

    return (
      <Tooltip
        text={toggleDescription}
        tooltipRender={({ position, tooltip, triggerClassName, wrapperClassName }) => (
          <span className={wrapperClassName}>
            <button {...buttonProps} className={cx(buttonClasses, triggerClassName)}>
              {toggleDescription && tooltip}
              <Icon name={buttonIconState} />
            </button>
          </span>
        )}
      />
    )
  }
}

export { ButtonSlideToggle as ButtonSlideToggleUnStyled }
export default injectSheet(styles)(ButtonSlideToggle)
