import memoize from 'memoize-one'
import React from 'react'

import { fetchCustomers } from 'api'
import { StatusIndicatorDot } from 'components'
import { TableView } from 'layouts'
import { generateBackButton, getRoutePathname } from 'routing'
import { formatDate, snakeToCamelCase, queryString } from 'utils'

// Table Configurations
const PAGE_ID = 'customers'
const PAGE_TITLE = 'Guest Management'
const FETCH_FUNCTION = fetchCustomers
// This covers retailers who don't support the empty search query request.
// '@' acts like a wildcard, it matches Customers on the email field.
const INIT_SEARCH_QUERY_PARAMS = { search: '@' }

const tableHeaders = [
  {
    key: 'status',
    disableSorting: true,
  },
  {
    key: 'created',
    header: 'Joined Date',
  },
  {
    key: 'first_name',
    header: 'First Name',
  },
  {
    key: 'last_name',
    header: 'Last Name',
  },
  {
    key: 'email',
    header: 'Email',
  },
  {
    key: 'primaryPhone',
    header: 'Primary Phone #',
    disableSorting: true,
  },
]

const formatTableRows = memoize(({ items, location }) =>
  items.map(customer => {
    const {
      ic_user_id,
      id,
      created,
      firstName,
      lastName,
      email,
      primaryNumberKey,
      status,
    } = customer

    const icUserQueryParam = ic_user_id ? `?ic_user_id=${ic_user_id}` : ''

    return {
      id: `customer-${id}`,
      rowLinkTo: {
        pathname: `${getRoutePathname('customers.customer', { id })}${icUserQueryParam}`,
        state: { backButton: generateBackButton('customers', location) },
      },
      status: (
        <StatusIndicatorDot compact isActive={status} text={status ? 'Active' : 'Inactive'} />
      ),
      created: formatDate(created),
      first_name: firstName,
      last_name: lastName,
      email,
      primaryPhone: customer[snakeToCamelCase(primaryNumberKey)],
    }
  })
)

const Customers = props => (
  <TableView
    {...props}
    queryKey="customers"
    fetchFunction={FETCH_FUNCTION}
    pageId={PAGE_ID}
    pageTitle={PAGE_TITLE}
    pageType={PAGE_ID}
    tableHeaders={tableHeaders}
    tableRows={formatTableRows}
    defaultQueryParams={INIT_SEARCH_QUERY_PARAMS}
  />
)

export default Customers
