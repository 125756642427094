export default theme => ({
  headerOrderButtons: {
    alignItems: 'center',
    display: 'flex',

    '& > *': {
      marginRight: theme.spacing.xs,

      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  underHeaderContainer: {
    display: 'flex',
  },
  leftSide: {
    flexShrink: 0,
    marginRight: theme.spacing.xxlg,
    width: 272,
  },
  rightSide: {
    flexGrow: 1,
    flexShrink: 1,
  },

  orderItemRowSubstituted: {
    '& > td': {
      borderBottomColor: 'transparent !important',
    },
  },
  orderItemRowSubstitutedTo: {
    '& > td > $orderItemRowProductCell': {
      paddingLeft: theme.spacing.md,
    },
  },
  orderItemRowProductCell: {
    alignItems: 'center',
    display: 'flex',
    lineHeight: 1.5,
  },
  orderItemRowProductCellProductText: {
    '& > *': {
      marginBottom: theme.spacing.xxs,

      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  orderItemRowProductCellImg: {
    flexShrink: 0,
    height: 40,
    marginRight: theme.spacing.xs,
    width: 40,
  },
  orderItemRowProductCellImgItemRemoved: {
    opacity: 0.5,
  },
  orderItemRowProductCellIconContainer: {
    marginRight: theme.spacing.md,
  },
  orderItemRowProductCellIcon: {
    height: 16,
    width: 16,
  },
  orderItemRowProductCellIcon_clock: {
    fill: theme.text01,
  },
  orderItemRowProductCellIcon_check: {
    fill: theme.support02Hover,
  },
  orderItemRowProductCellIcon_plus: {
    fill: theme.support02Hover,
  },
  orderItemRowProductCellIcon_close: {
    fill: theme.support01,
    padding: '0 2px', // reduces width of icon to 12px
  },
  orderItemRowProductCellIcon_subFrom: {
    fill: theme.brand01,
  },
  orderItemRowProductCellIcon_subTo: {
    fill: theme.brand01,
  },
  tableContent: {
    lineHeight: 1.5,
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
  },
  statusButton: {
    width: 156,
  },
  tableActions: {
    alignItems: 'center',
    display: 'flex',

    '& > *': {
      marginRight: theme.spacing.xs,

      '&:last-child': {
        marginRight: 0,
      },
    },
  },
})
