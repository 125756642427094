export default theme => ({
  rightHeaderButtons: {
    alignItems: 'center',
    display: 'flex',

    '& > *': {
      marginRight: theme.spacing.xs,

      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  underHeaderContainer: {
    display: 'flex',
  },
  leftSide: {
    flexShrink: 0,
    marginRight: theme.spacing.xxlg,
    width: 272,
  },
  rightSide: {
    flexGrow: 1,
    flexShrink: 1,
  },
  detailImage: {
    backgroundColor: theme.bgGreyExtraLight,
    border: `1px solid ${theme.borderGreyLight}`,
    display: 'flex',
    justifyContent: 'center',
    height: 220,
    position: 'relative',

    '& > img': {
      height: 'auto',
      left: '50%',
      maxWidth: '100%',
      maxHeight: '100%',
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: 'auto',
    },

    '&:hover': {
      '& + $fixedActionContainer': {
        visibility: 'visible',
      },
    },
  },
  fixedActionContainer: {
    position: 'absolute',
    top: theme.spacing.md,
    right: theme.spacing.md,
    visibility: 'hidden',

    '&:hover': {
      visibility: 'visible',
    },
  },
  lineThrough: {
    textDecoration: 'line-through',
    marginRight: theme.spacing.md,
  },
  readOnlyValueDetailsMargin: {
    marginBottom: theme.spacing.md,
  },

  tabContainer: {
    '& > div': {
      padding: 0,
      marginTop: theme.spacing.md,
    },
  },

  filters: {
    display: 'flex',
    flexGrow: 1,
    marginBottom: theme.spacing.md,

    '& > *': {
      marginBottom: 0,
      maxWidth: 160,
      marginRight: theme.spacing.xs,

      '&:first-child': {
        maxWidth: 110,
      },

      '&:last-child': {
        marginRight: 0,
        flexGrow: 1,
        maxWidth: 'none',
      },
    },
  },
  customUpcElement: {
    display: 'block',
    paddingLeft: 70,
  },
  imageActionHover: {
    visibility: 'hidden',
    background: '#FFFFFF',
    border: `1px solid ${theme.brand01}`,
  },
  thumbnailContainer: {
    '&:hover $imageActionHover': {
      visibility: 'visible',
    },
  },
})
