import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import injectSheet from 'react-jss'

// TODO: Add ability to show Tooltips on texts that span too long (show ellipsis, and then add tooltip).
// https://docs.telerik.com/kendo-ui/controls/layout/tooltip/how-to/show-on-ellipsis

const styles = theme => ({
  tooltipWrapper: {
    position: 'relative',
  },
  tooltipTrigger: {
    '&:hover': {
      outline: 'none',

      '& $tooltip': {
        display: 'block',
      },
    },
  },
  tooltip: {
    fontSize: theme.fontSize.p,
    zIndex: theme.zIndex.dropdown,
    borderRadius: '3px',
    boxShadow: '1px 1px 4px 0 rgba(107,123,132,0.3)',
    alignItems: 'center',
    background: theme.bgModal,
    color: theme.inverse01,
    display: 'none',
    fontFamily: theme.fontFamilySansSerif,
    left: 0,
    marginLeft: '50%',
    padding: theme.spacing.sm,
    position: 'absolute',
    whiteSpace: 'nowrap',

    '& svg': {
      fill: theme.inverse01,
    },

    // Tooltip caret
    '&::after': {
      borderStyle: 'solid',
      content: '""',
      height: '0',
      left: '0',
      margin: '0 auto',
      position: 'absolute',
      right: '0',
      width: '0',
    },
  },
  tooltipOnTop: {
    marginTop: `-${theme.spacing.xs}`,
    top: 0,
    transform: 'translate(-50%, -100%)',

    // Tooltip caret
    '&::after': {
      borderColor: `${theme.bgModal} transparent transparent transparent`,
      borderWidth: '6px 4px 0 4px',
      bottom: -4,
    },
  },

  tooltipOnBottom: {
    bottom: 0,
    marginBottom: `-${theme.spacing.xs}`,
    transform: 'translate(-50%, 100%)',

    // Tooltip caret
    '&::after': {
      borderColor: `transparent transparent ${theme.bgModal} transparent`,
      borderWidth: '0 4px 6px 4px',
      top: -4,
    },
  },
  wrap: {
    lineHeight: 1.45,
    width: 240,
    whiteSpace: 'normal',
  },
})

const Tooltip = ({
  children,
  className: containerClassName,
  classes,
  position,
  text,
  theme,
  tooltipRender,
  wrap,
  ...restProps
}) => {
  const wrapperClassNames = cx(containerClassName, classes.tooltipWrapper)

  const tooltipClassNames = cx(classes.tooltip, {
    [classes.tooltipOnTop]: position === 'top',
    [classes.tooltipOnBottom]: position === 'bottom',
    [classes.wrap]: wrap,
  })
  const tooltip = <div className={tooltipClassNames}>{text}</div>

  return tooltipRender ? (
    tooltipRender({
      tooltip,
      triggerClassName: classes.tooltipTrigger,
      wrapperClassName: wrapperClassNames,
    })
  ) : (
    <span className={wrapperClassNames}>
      <span className={classes.tooltipTrigger} tabIndex="0" role="button" {...restProps}>
        {position === 'top' && tooltip}
        {children}
        {position === 'bottom' && tooltip}
      </span>
    </span>
  )
}

Tooltip.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  position: PropTypes.oneOf(['bottom', 'top']),
  text: PropTypes.string,
  /**
   * Consumer can provide its own render function to handle rendering.
   * (Useful where the focusable element also needs to be clickable.)
   */
  tooltipRender: PropTypes.func,
  wrap: PropTypes.bool,
}

Tooltip.defaultProps = {
  position: 'top',
}

export { Tooltip as TooltipUnStyled }
export default injectSheet(styles)(Tooltip)
