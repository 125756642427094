import debounce from 'lodash/debounce'
import React, { Component, Fragment, createElement } from 'react'
import injectSheet from 'react-jss'
import PropTypes from 'prop-types'

import {
  AddCategoriesSimple,
  Button,
  Dropdown,
  Notification,
  PageHeader,
  SearchInput,
  Tabs,
  Tab,
} from 'components'
import { generateBackButton, getRoutePathname } from 'routing'
import { createSlugFromName, queryString } from 'utils'

import PromotedPlacementsContentTab from './PromotedPlacementsContentTab'

import styles from './PromotedPlacementsContent.styles'

const pageId = 'promoted-placements'

// Keys for additional filter options
const filterCategoryId = 'category_id'
const filterKeyword = 'promoted_search_keyword'

const filterOptions = {
  expired: [
    { id: 'active', label: 'Active', value: 'false' },
    { id: 'expired', label: 'Expired', value: 'true' },
  ],
}

// TODO - Find a better way to map additional filters, alternatively, make filtering available for all?
const filterMap = {
  catalog_placement: 'catalog',
  sponsored_product_suggestions: 'catalog',
  search_placement: 'search',
}

const additionalFilters = {
  catalog: [
    {
      component: AddCategoriesSimple,
      props: {
        id: filterCategoryId,
        placeholder: 'Filter by category',
      },
      type: 'add-categories',
    },
  ],
  search: [
    {
      component: SearchInput,
      props: {
        debounce: true,
        id: filterKeyword,
        placeholder: 'Filter by keyword',
      },
      type: 'search',
    },
  ],
}

// TODO - refactor this as a layout maybe
class PromotedPlacementsContent extends Component {
  state = {
    queryParams: {},
    selectedTab: 0,
  }

  componentDidMount() {
    const queryParams = queryString.parse(this.props.location.search)
    const selectedTab = queryParams.tab || 0
    this.setState({ selectedTab, queryParams })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      const queryParams = queryString.parse(this.props.location.search)
      this.setState({ selectedTab: 0, queryParams })
    } else {
      const tab = new URLSearchParams(this.props.location.search).get('tab')
      if (new URLSearchParams(prevProps.location.search).get('tab') !== tab) {
        const queryParams = queryString.parse(this.props.location.search)
        this.setState({ selectedTab: tab, queryParams })
      }
    }
  }

  createPromotedPlacementContent = (placement, label) => {
    return (
      <PromotedPlacementsContentTab
        {...this.props}
        filters={{ ...this.state.queryParams }}
        placement={placement}
        label={label}
      />
    )
  }

  createPlacementContent = (placements, label) => {
    const { queryParams } = this.state

    // Show placements in tabs if there are more than 1
    switch (placements.length) {
      case 0:
        return null
      case 1:
        return this.createPromotedPlacementContent(placements[0], label)
      default:
        return (
          <Tabs
            noContentPadding={queryParams.expired !== 'true'}
            onSelectTab={this.selectTab}
            selectedTab={Number(this.state.selectedTab)}
          >
            {placements.map((placement, index) => (
              <Tab label={placement.name} key={index}>
                {queryParams.expired !== 'true' && (
                  <Notification
                    hideBorder
                    kind="info"
                    message="You are looking at the prioritized list of how the placement will display for a particular store group or user segment."
                  />
                )}
                {this.createPromotedPlacementContent(placement, label)}
              </Tab>
            ))}
          </Tabs>
        )
    }
  }

  setFilter = (type, value, opts) => {
    const { history } = this.props
    const { location } = history
    const currentPathname = location.pathname
    const newParams = {
      ...queryString.parse(location.search),
      [type]: value,
      tab: this.state.selectedTab,
    }

    // Reload page with new filters
    history.push({
      pathname: currentPathname,
      search: queryString.stringify(newParams),
    })

    this.setState({ queryParams: newParams })
  }

  selectTab = selectedTab => {
    const { history, location } = this.props
    const currentPathname = location.pathname

    // Save selected tab index and clear query params
    this.setState({ selectedTab }, () => {
      const newParams = {
        ...queryString.parse(location.search),
        tab: this.state.selectedTab,
      }

      history.push({
        pathname: currentPathname,
        search: queryString.stringify(newParams),
      })

      this.setState({ queryParams: newParams })
    })
  }

  render() {
    const { classes, label, location, placements } = this.props
    const { queryParams, selectedTab } = this.state
    const currentPlacementName = placements[selectedTab] ? placements[selectedTab].name : ''
    const currentPlacementId = placements[selectedTab] ? placements[selectedTab].id : ''
    const currentPlacementKey = placements[selectedTab] ? placements[selectedTab].key : ''

    // TODO - find a better way to map this with sections from the BE
    const additionalFiltersList = filterMap[currentPlacementKey]
      ? additionalFilters[filterMap[currentPlacementKey]]
      : []

    return (
      <Fragment>
        <PageHeader headerTitle={label}>
          <div className={classes.rightOfHeaderContainer}>
            <Dropdown
              id="promoted-placements-expired"
              items={filterOptions.expired}
              onChange={value => this.setFilter('expired', value)}
              placeholder="Status"
              selectedValue={queryParams.expired}
            />
            {additionalFiltersList.map(({ component, props = {}, type }) => {
              const additionalProps = {}

              // For dropdowns using the AddCategoriesSimple component, add additional props
              if (type === 'add-categories') {
                additionalProps.onItemSelect = value => this.setFilter(props.id, value.id)
                additionalProps.selectedItems = queryParams.category_id
                  ? [queryParams.category_id]
                  : []
              }

              const debounceTime = props.debounce ? 500 : 0

              return createElement(component, {
                ...props,
                ...additionalProps,
                id: `${pageId}-${props.id}`,
                key: `${pageId}-${props.id}`,
                onChange: debounce(value => this.setFilter(props.id, value), debounceTime),
              })
            })}
            <Button
              href={{
                pathname: getRoutePathname('promotedPlacements.create', {
                  slug: createSlugFromName(currentPlacementName),
                  id: currentPlacementId,
                }),
                state: {
                  backButton: generateBackButton('promotedPlacements', location),
                  currentPlacementName,
                },
              }}
            >
              Create New
            </Button>
          </div>
        </PageHeader>
        {this.createPlacementContent(placements, label)}
      </Fragment>
    )
  }
}

PromotedPlacementsContent.propTypes = {
  placements: PropTypes.array,
}

export default injectSheet(styles)(PromotedPlacementsContent)
