import React, { Component } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'

import { Icon } from 'components'

import styles from './PageHeaderBanner.styles'

class PageHeaderBannerUnstyled extends Component {
  static displayName = 'PageHeaderBanner'

  static propTypes = {
    kind: PropTypes.oneOf(['info']).isRequired,
    message: PropTypes.node.isRequired,
  }

  render() {
    const { className, classes, message, kind, action } = this.props

    const notificationClasses = cx(className, classes.headerBanner)

    return (
      <div role="alert" kind={kind} className={notificationClasses}>
        <div className={classes.headerBannerTextContainer}>
          <Icon className={classes.bannerIcon} name={kind} />
          <div className={classes.headerBannerDetails}>{message}</div>
        </div>
      </div>
    )
  }
}

const PageHeaderBanner = injectSheet(styles)(PageHeaderBannerUnstyled)

export { PageHeaderBanner, PageHeaderBannerUnstyled }

export default PageHeaderBanner
