import PropTypes from 'prop-types'
import { Component } from 'react'

import { denormalize, normalize } from './state/normalizing'
import { getNextSortDirection, sortStates } from './state/sorting'
import { defaultRender } from './utils/rendering'

/**
 * Data Tables are used to represent a collection of resources, displaying a
 * subset of their fields in columns, or headers.
 */
export default class DataTable extends Component {
  static propTypes = {
    /**
     * Allows an element to be inserted at the start of the table below the header
     */
    tableAction: PropTypes.node,
    /**
     * Just like a rowAction defined in a table row, is the node to render in the row
     * action column which should execute an action affecting all the rows in the table.
     */
    bulkAction: PropTypes.node,
    /**
     * Message shown when there are 0 rows displayed in DataTable body
     */
    emptyMsg: PropTypes.string,
    /**
     * The `headers` prop represents the order in which the header columns should
     * appear in the table. We expect an array of objects to be passed in, where
     * `key` is the name of the key in a row object, and `header` is the name/label of
     * the header. The following are not valid header keys: `id`, `rowActions`,
     * `rowDetails`, and `rowLinkTo`.
     */
    headers: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        header: PropTypes.string,
      })
    ).isRequired,
    id: PropTypes.string.isRequired,
    /**
     * Whether or not table rows can be dragged to re-order data in the table
     */
    isDraggable: PropTypes.bool,
    /**
     * Whether or not the old rows data is outdated and new rows data is on its way
     * (Useful if fetching row data asynchronously as a result of:
     *  initial load, or BE-defined filtering, sorting, pagination, etc.
     */
    isLoadingNewRows: PropTypes.bool,
    /**
     * Whether or not we want to allow the table rows to be selectable (for performing bulk actions).
     */
    isSelectable: PropTypes.bool,
    kind: PropTypes.oneOf(['default', 'light', 'light-tall-row']),
    /**
     * By default, select boxes are disabled in the data table until an item is
     * selected; this setting can override that behaviour to show all checkboxes
     * regardless of checked state
     */
    showSelectBoxes: PropTypes.bool,
    /**
     * Function used as a callback once a row has been dragged (and dropped) in a new position
     */
    onDragEnd: PropTypes.func,
    /**
     * Function used by consuming component for bulk actions with selected rows.
     * Is called when the selected rows have changed.
     * (selectedRowIdsAfterSelection, unselectedRowIds) => {...}
     */
    onSelectedRowsChange: PropTypes.func,
    /**
     * Function used by consuming component for sorting (who needs to supply new sorted rows on sort header key changes)
     */
    onSortBy: PropTypes.func,
    render: PropTypes.func.isRequired,
    /**
     * Props handed down by consumer to be used in the DataTableRendering
     */
    renderProps: PropTypes.object,
    pagingType: PropTypes.oneOf(['paginated', 'infinite']),
    /**
     * Props passed down to the paging component
     */
    pagingProps: (...args) => {
      const [props] = args

      return props.pagingType === 'infinite'
        ? PropTypes.exact({
            /**
             * Function called by consuming component when more content is requested
             */
            onLoadMore: PropTypes.func,
            /**
             * Whether there is more content available to load. If not, onLoadMore won't get called.
             */
            hasMore: PropTypes.bool,
            /**
             * Whether new content is loading.
             */
            isLoading: PropTypes.bool,
          }).apply(this, args)
        : PropTypes.exact({
            /**
             * Function used by consuming component for paging (who needs to supply new rows on page changes)
             */
            onPagingChange: PropTypes.func,
            pageNumber: PropTypes.number,
            /**
             * Total amount of results to show per page (sets value in TablePaging dropdown)
             */
            resultsPerPage: PropTypes.number,
            /**
             * Total amount of results (so not just rows shown, but all rows on all pages)
             */
            resultsTotal: PropTypes.number,
          }).apply(this, args)
    },
    /**
     * The `rows` prop is where you provide a list of all the rows that
     * you want to render in the table. The only hard requirement is that this
     * is an array of objects, and that each object has a unique `id` field
     * available on it. Rows can also have the following keys which alter the row's
     * functionality: `rowActions`, `rowDetails`, `rowLinkTo`.
     */
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      })
    ).isRequired,
    selectedRowIds: PropTypes.arrayOf(PropTypes.string),
    sortDirection: PropTypes.oneOf(Object.values(sortStates)),
    sortHeaderKey: PropTypes.string,
    /**
     * The `unformattedRows` prop is where you provide a list of all the rows data without any formatting
     */
    unformattedRows: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      })
    ),
  }

  static defaultProps = {
    emptyMsg: 'No results found',
    kind: 'default',
    render: defaultRender,
    unformattedRows: [],
  }

  state = {
    // rowIds,
    // rowsById,
    // cellsById,
    // hasSelectedRows,
    // lastRowsProp
  }

  static getDerivedStateFromProps(props, state) {
    // On initial render or any subsequent changes to `rows` prop
    if (!state.lastRowsProp || props.rows !== state.lastRowsProp) {
      const { rowIds, rowsById, cellsById } = normalize(props.rows, props.headers)

      let hasSelectedRows = false
      // Select all rows corresponding to selectedRowIds
      if (props.selectedRowIds) {
        props.selectedRowIds.forEach(selectedRowId => {
          // May include ids for rows not in props.rows, but select all that are
          if (rowsById[selectedRowId]) {
            hasSelectedRows = true
            rowsById[selectedRowId].isSelected = true
          }
        })
      }

      const newState = {
        rowIds,
        rowsById,
        cellsById,
        hasSelectedRows,
        lastRowsProp: props.rows,
      }

      return newState
    }
    return null
  }

  componentDidUpdate() {
    /**
     * If we're providing DataTable with paging props, and we detect that we're being asked to show
     * results on a page that comes after the max page number, change the page to max page number.
     * (Can happen if, for example, you're on the last page of a table, you delete the only row on
     * that page, and the page refreshes trying to render that last page which no longer exists.)
     */
    const {
      pagingType,
      pagingProps: { onPagingChange, pageNumber, resultsPerPage, resultsTotal },
    } = this.props

    if (
      pagingType === 'paginated' &&
      onPagingChange &&
      pageNumber &&
      resultsTotal > 0 &&
      resultsPerPage > 0
    ) {
      const maxPageNumber = Math.ceil(resultsTotal / resultsPerPage)

      if (pageNumber > maxPageNumber) {
        onPagingChange({ pageNumber: maxPageNumber, resultsPerPage })
      }
    }
  }

  /**
   * Get the props associated with the given header.
   *
   * @param {Object} header the header we want the props for
   * @returns {Object}
   */
  getHeaderProps = ({ header, ...restProps }) => {
    const { sortDirection, sortHeaderKey } = this.props

    const headerProps = {
      ...restProps,
      key: header.key,
      sortDirection,
      isActiveSortHeader: sortHeaderKey === header.key,
      disableSorting: header.disableSorting,
      minWidth: header.minWidth,
    }
    if (!header.disableSorting) {
      headerProps.onClick = this.generateOnSortBy(header.key)
    }

    return headerProps
  }

  /**
   * Get the props associated with the given row.
   *
   * @param {Object} row the row we want the props for
   * @returns {Object}
   */
  getRowProps = ({ row, ...restProps }) => {
    const ariaLabel = row.isExpanded ? 'Collapse current row' : 'Expand current row'

    const rowProps = {
      ...restProps,
      ariaLabel,
      className: row.rowClass,
      id: row.id,
      isExpanded: row.isExpanded,
      isSelected: row.isSelected,
      onExpand: this.generateOnExpandRow(row.id),
      resourceName: row.resourceName,
      rowAction: row.rowAction,
      rowDetails: row.rowDetails,
      rowLinkTo: row.rowLinkTo,
      rowMinHeight: row.rowMinHeight,
    }

    if (this.props.isSelectable) {
      // If the table is selectable
      const selectRowAriaLabel = row.isSelected ? 'Select row' : 'Unselect row'

      // The Checkbox props associated with selection for a given row.
      rowProps.rowSelectProps = {
        checked: row.isSelected,
        id: `${row.resourceName ? `${row.resourceName}-${row.id}` : row.id}_select`,
        labelText: selectRowAriaLabel,
        onChange: this.generateOnSelectRow(row.id),
      }
    }

    return rowProps
  }

  /**
   * Gets the Checkbox props associated with selection for all rows.
   *
   * @returns {Object}
   */
  getSelectAllProps = ({ ...restProps } = {}) => {
    const { id, showSelectBoxes } = this.props
    const { hasSelectedRows, rowIds } = this.state

    const numSelectedRows = this.getSelectedRowIds().length
    const isSelected = numSelectedRows === rowIds.length
    const isIndeterminate = !isSelected && hasSelectedRows
    const ariaLabel = isSelected ? 'Unselect all rows' : 'Select all rows'

    return {
      ...restProps,
      checked: isSelected,
      id: `${id}_select-all`,
      indeterminate: isIndeterminate,
      labelText: ariaLabel,
      onChange: this.handleSelectAll,
      showCheckbox: hasSelectedRows || showSelectBoxes,
    }
  }

  /**
   * Helper utility to get all the currently selected row ids
   *
   * @returns {Array<string>} the array of row ids that are selected
   */
  getSelectedRowIds = () => this.state.rowIds.filter(id => this.state.rowsById[id].isSelected)

  /**
   * Helper for toggling all selected items in a state.
   * Note: It does not call setState, so use it when setting state.
   *
   * @param {Object} initialState
   * @returns {Object} object to put into this.setState (use spread operator)
   */
  setAllSelectedState = (initialState, isSelected) => {
    const { rowIds } = initialState
    return {
      rowsById: rowIds.reduce(
        (rowsById, rowId) => ({
          ...rowsById,
          [rowId]: {
            ...initialState.rowsById[rowId],
            isSelected,
          },
        }),
        {}
      ),
    }
  }

  /**
   * Handler for toggling the selection state of all rows in the DataTable state
   */
  handleSelectAll = () => {
    const { onSelectedRowsChange } = this.props

    this.setState(state => {
      const { rowIds } = state
      const previousSelectedRowLength = this.getSelectedRowIds().length
      const wasSelected = previousSelectedRowLength === rowIds.length
      const isSelected = !wasSelected
      const hasSelectedRowsAfterSelection = isSelected // isSelected will be the same as if there are or are not ANY selected rows

      if (onSelectedRowsChange) {
        if (isSelected) {
          onSelectedRowsChange(rowIds, [])
        } else {
          onSelectedRowsChange([], rowIds)
        }
      }
      return {
        hasSelectedRows: hasSelectedRowsAfterSelection,
        ...this.setAllSelectedState(state, isSelected),
      }
    })
  }

  /**
   * Handler for toggling the selection state of a given row in the DataTable state
   *
   * @param {string} rowId
   * @returns {Function}
   */
  generateOnSelectRow = rowId => () => {
    const { onSelectedRowsChange } = this.props

    this.setState(state => {
      const { rowsById } = state
      const row = rowsById[rowId]
      const isRowSelectedAfterSelection = !row.isSelected

      const selectedRowIdsAfterSelection = isRowSelectedAfterSelection
        ? [...this.getSelectedRowIds(), row.id]
        : this.getSelectedRowIds().filter(id => id !== row.id)

      const numSelectedRowsAfterSelection = selectedRowIdsAfterSelection.length
      const hasSelectedRowsAfterSelection = numSelectedRowsAfterSelection > 0

      const unselectedRowIds = isRowSelectedAfterSelection ? [] : [rowId]

      if (onSelectedRowsChange) {
        onSelectedRowsChange(selectedRowIdsAfterSelection, unselectedRowIds)
      }

      return {
        hasSelectedRows: hasSelectedRowsAfterSelection,
        rowsById: {
          ...rowsById,
          [rowId]: {
            ...row,
            isSelected: isRowSelectedAfterSelection,
          },
        },
      }
    })
  }

  /**
   * Handler for toggling the expansion state of a given row.
   *
   * @param {string} rowId
   * @returns {Function}
   */
  generateOnExpandRow = rowId => () => {
    this.setState(state => {
      const row = state.rowsById[rowId]
      return {
        rowsById: {
          ...state.rowsById,
          [rowId]: {
            ...row,
            isExpanded: !row.isExpanded,
          },
        },
      }
    })
  }

  /**
   * Handler for transitioning to the next sort state of the table
   *
   * @param {string} headerKey the field for the header that we are sorting by
   * @returns {Function}
   */
  generateOnSortBy = headerKey => () => {
    const { onSortBy, sortDirection, sortHeaderKey } = this.props

    const nextSortDirection = getNextSortDirection(headerKey, sortHeaderKey, sortDirection)
    // Remove sort key if the next sort direction is `null` since you can't sort something by nothing
    const nextSortHeaderKey = nextSortDirection !== sortStates.NONE ? headerKey : null

    if (onSortBy) {
      onSortBy({ nextSortHeaderKey, nextSortDirection })
    }
  }

  render() {
    const {
      tableAction,
      bulkAction,
      emptyMsg,
      error,
      headers,
      id,
      isDraggable,
      isLoadingNewRows,
      isSelectable,
      kind,
      onDragEnd,
      pagingType,
      pagingProps,
      render,
      renderProps,
      showSelectBoxes,
    } = this.props

    const { cellsById, rowIds, rowsById, hasSelectedRows } = this.state

    // If `render` prop not provided, `defaultRender` function is used for `render` function
    return render({
      // Data derived from props
      tableAction,
      bulkAction,
      emptyMsg,
      error,
      headers,
      id,
      isDraggable,
      isLoadingNewRows,
      isSelectable,
      kind,
      pagingProps,
      pagingType,

      // Data derived from state
      hasSelectedRows,
      rows: denormalize(rowIds, rowsById, cellsById),
      selectedRows: denormalize(this.getSelectedRowIds(), rowsById, cellsById),

      // Expose internal state change actions
      onDragEnd,

      // Prop accessors/getters
      getHeaderProps: this.getHeaderProps, // sorting props are included here
      getRowProps: this.getRowProps,
      getSelectAllProps: this.getSelectAllProps,

      // Display props
      showSelectBoxes,

      // Custom render props
      ...renderProps,
    })
  }
}
