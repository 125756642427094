import { sortAllRows } from '../utils/sorting'

/**
 * We currently support the following sorting states for DataTable headers,
 * namely: `NONE` for no sorting being applied, and then `DESC` and `ASC` for
 * the corresponding direction of the sorting order.
 */
export const sortStates = {
  NONE: null,
  DESC: 'desc',
  ASC: 'asc',
}

/**
 * Utility used to get the next sort state given the following pieces of information:
 *
 * @param {string} headerKey
 * @param {string} prevHeaderKey
 * @param {string} prevSortDirection
 * @returns {string}
 */
export const getNextSortDirection = (headerKey, prevHeaderKey, prevSortDirection) => {
  // If the previous header is equivalent to the current header, we know that we
  // have to derive the next sort state from the previous sort state
  if (prevHeaderKey === headerKey) {
    // When transitioning, we know that the sequence of states is as follows:
    // NONE -> ASC -> DESC -> NONE
    if (prevSortDirection === sortStates.NONE) {
      return sortStates.ASC
    }
    if (prevSortDirection === sortStates.ASC) {
      return sortStates.DESC
    }
    if (prevSortDirection === sortStates.DESC) {
      return sortStates.NONE
    }
  } else {
    // Otherwise, we have selected a new header and need to start off by sorting
    // in ascending order by default
    return sortStates.ASC
  }
}

/**
 * Derive the set of sorted state fields from props and state for the given
 * header key and sortDirection.
 *
 * A `sortRows` function can be provided to customize the way the rows are sorted,
 * but otherwise defaultSortRows is used.
 */
export const getSortedState = ({ rowIds, cellsById, initialRowOrder, key, sortDirection }) => {
  const nextRowIds =
    sortDirection !== sortStates.NONE
      ? sortAllRows({
          rowIds,
          cellsById,
          key,
          sortDirection,
        })
      : initialRowOrder
  return {
    sortHeaderKey: key,
    sortDirection,
    rowIds: nextRowIds,
  }
}
