import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import injectSheet from 'react-jss'

import styles from './TextInput.styles'

const TextInput = ({
  className,
  classes,
  id,
  inputDescription,
  inputPrefixSymbol,
  inputMaxLengthCount,
  isInvalid,
  onBlur,
  onChange,
  placeholder,
  theme,
  type,
  ...restProps
}) => {
  const textInputProps = {
    id,
    onChange: event => {
      if (!restProps.disabled) {
        onChange(event)
      }
    },
    onBlur: event => {
      if (!restProps.disabled) {
        onBlur(event)
      }
    },
    placeholder,
    type,
  }

  const textInputClasses = cx(className, classes.textInput, {
    [classes.textInputInvalid]: isInvalid,
    [classes.textInputWithValue]: !!restProps.value,
    [classes.textInputWithPrefixSymbol]: !!inputPrefixSymbol,
    [classes.textInputWithMaxLengthCount]: inputMaxLengthCount && restProps.maxLength,
  })

  const input = isInvalid ? (
    <input
      {...restProps}
      {...textInputProps}
      data-invalid
      aria-invalid
      aria-describedby={`${id}-error-msg`}
      className={textInputClasses}
    />
  ) : (
    <input {...restProps} {...textInputProps} className={textInputClasses} />
  )

  return (
    <div className={classes.textInputContainer}>
      {inputPrefixSymbol && (
        <div
          className={cx(classes.textInputPrefixSymbol, {
            [classes.textInputWithValuePrefixSymbol]: !!restProps.value,
          })}
        >
          {inputPrefixSymbol}
        </div>
      )}
      {inputMaxLengthCount && restProps.maxLength && (
        <div className={cx(classes.textInputMaxLengthCount)}>
          {restProps.value ? restProps.value.length : 0} / {restProps.maxLength}
        </div>
      )}
      {input}
      {inputDescription &&
        inputDescription.split('\n').map((text, textIndex) => (
          <p className={classes.inputDescription} key={textIndex}>
            {text}
          </p>
        ))}
    </div>
  )
}

TextInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  inputDescription: PropTypes.string,
  inputPrefixSymbol: PropTypes.string,
  inputMaxLengthCount: PropTypes.bool,
  /**
   * If `true`, will set aria-describedby to `${id}-error-msg`
   */
  isInvalid: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxLength: PropTypes.number,
}

TextInput.defaultProps = {
  disabled: false,
  isInvalid: false,
  onBlur: () => {},
  onChange: () => {},
  type: 'text',
}

export { TextInput as TextInputUnStyled }
export default injectSheet(styles)(TextInput)
