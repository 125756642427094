import PropTypes from 'prop-types'

import { PureComponent } from 'react'

import icons from './icons'

class Icon extends PureComponent {
  render() {
    const { name } = this.props
    return icons[name] ? icons[name](this.props) : null
  }
}

Icon.propTypes = {
  className: PropTypes.string,
  /**
   * A description can be supplied to be displayed under `alt`, `aria-label`, and
   * inside the <svg>'s <title> tag.
   */
  description: PropTypes.string,
  /**
   * The name of the icon to be displayed.
   * This name corresponds to a prop in the object of stored svg icons in `Icon/icons.js`.
   */
  name: PropTypes.string.isRequired,
}

export default Icon
