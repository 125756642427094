import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import DayPicker, { DateUtils } from 'react-day-picker'
import defaultDayPickerClassNames from 'react-day-picker/lib/src/classNames'
import { formatDate, parseDate } from 'react-day-picker/moment'
import injectSheet from 'react-jss'
import { BooleanValue } from 'react-values'

import { Button, Checkbox, FormLabel, MultiInput, TextInput } from 'components'

import styles from './DatePickerRange.styles'

const DATE_FORMAT = 'YYYY-MM-DD'

const isSelectingFirstDay = (from, to, day) => {
  const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from)
  const isRangeSelected = from && to
  return !from || isBeforeFirstDay || isRangeSelected
}

class DatePickerRange extends Component {
  static displayName = 'DatePickerRange'

  static propTypes = {
    className: PropTypes.string,
    /**
     * The default value of the date to be provided to react-day-picker's DayPickerInput.
     * See docs for details: http://react-day-picker.js.org/docs/matching-days
     */
    defaultValue: PropTypes.shape({
      from: PropTypes.string, // Must be formatted like DATE_FORMAT
      to: PropTypes.string, // Must be formatted like DATE_FORMAT
    }),
    id: PropTypes.string.isRequired,
    /**
     * The month to display in the calendar at first render.
     * (If not provided, we'll use the `defaultValue.from` date,
     * then today's date if `defaultValue` is not provided.)
     */
    initialMonth: PropTypes.instanceOf(Date),
    /**
     * Function executed on every new date range selection.
     */
    onChange: PropTypes.func,
    /**
     * If enabled, user will be given a "No End Date" checkbox option where `null` will
     * always be passed for the `to` value
     */
    enableNoEndDate: PropTypes.bool,
    /**
     * If enabled, completely remove the end date field without giving the user an option
     */
    hideEndDate: PropTypes.bool,
    /**
     * Only allow the user to select the `to` value of the date range
     * defaultValue.from MUST be provided
     */
    fixedStartDate: PropTypes.bool,
  }

  static defaultProps = {
    defaultValue: {
      from: undefined,
      to: undefined,
    },
  }

  constructor(props) {
    super(props)
    const { from, to } = props.defaultValue

    this.state = {
      from: parseDate(from, DATE_FORMAT),
      to: parseDate(to, DATE_FORMAT),
      enteredTo: to ? parseDate(to, DATE_FORMAT) : undefined,
      fromInputValue: from || '',
      toInputValue: to || '',
      noEndDate: (from && !to && !props.fixedStartDate) || props.hideEndDate,
    }
  }

  handleDayClick = day => {
    const { from, to } = this.state
    if (from && to && day >= from && day <= to) {
      this.handleResetClick()
    } else if (isSelectingFirstDay(from, to, day)) {
      this.setState({
        from: day,
        to: undefined,
        enteredTo: undefined,
        fromInputValue: formatDate(day, DATE_FORMAT),
        toInputValue: '',
      })
    } else {
      this.setState({
        to: day,
        enteredTo: day,
        toInputValue: formatDate(day, DATE_FORMAT),
      })
    }
  }

  handleSelectFromDayClick = day => {
    this.setState({
      from: day,
      fromInputValue: formatDate(day, DATE_FORMAT),
    })
  }

  handleSelectToDayClick = day => {
    const { from } = this.state
    if (!DateUtils.isDayBefore(day, from)) {
      this.setState({
        to: day,
        enteredTo: day,
        toInputValue: formatDate(day, DATE_FORMAT),
      })
    } else {
      this.setState({
        to: undefined,
        enteredTo: undefined,
        toInputValue: '',
      })
    }
  }

  handleDayMouseEnter = day => {
    const { from, to } = this.state
    if (!isSelectingFirstDay(from, to, day)) {
      this.setState({
        enteredTo: day,
      })
    }
  }

  handleResetClick = () => {
    this.setState({
      from: undefined,
      to: undefined,
      enteredTo: undefined,
      fromInputValue: '',
      toInputValue: '',
    })
    if (this.props.onChange) {
      this.props.onChange({ from: '', to: '' })
    }
  }

  /**
   * On "apply" click, alert consumer component of the new date range
   */
  handleApplyClick = () => {
    const { from, to, noEndDate } = this.state
    const toDate = !noEndDate ? formatDate(to, DATE_FORMAT) : null
    if (this.props.onChange && from && (to || (!to && noEndDate))) {
      this.props.onChange({ from: formatDate(from, DATE_FORMAT), to: toDate, noEndDate })
    }
  }

  handleChangeFrom = event => {
    const { value: fromInputValue } = event.target
    // `parseDate` returns undefined if `fromInputValue` is not a valid date
    const from = parseDate(fromInputValue, DATE_FORMAT)
    const { to } = this.state

    if (to && from && DateUtils.isDayBefore(to, from)) {
      // User entered in a `from` date that happens after `to`, don't save it to state
      this.setState({ fromInputValue })
    } else {
      // User either entered a valid `from` date, or `from` is undefined
      this.setState({ from, fromInputValue })
    }
  }

  handleChangeTo = event => {
    const { value: toInputValue } = event.target
    // `parseDate` returns undefined if `toInputValue` is not a valid date
    const to = parseDate(toInputValue, DATE_FORMAT)
    const enteredTo = to

    const { from } = this.state
    if (to && from && DateUtils.isDayBefore(to, from)) {
      // User entered in a `to` date that happens before `from`, don't save it to state
      this.setState({ toInputValue })
    } else {
      // User either entered a valid `to` date, or `to` is undefined
      this.setState({ to, enteredTo, toInputValue })
    }
  }

  handleBlurFrom = event => {
    const { value: fromInputValue } = event.target
    // `parseDate` returns undefined if `fromInputValue` is not a valid date
    const from = parseDate(fromInputValue, DATE_FORMAT)
    if (!from) {
      // User entered in an invalid `from` date, clear the `from` input
      this.setState({ fromInputValue: '' })
    }
  }

  handleBlurTo = event => {
    const { value: toInputValue } = event.target
    // `parseDate` returns undefined if `toInputValue` is not a valid date
    const to = parseDate(toInputValue, DATE_FORMAT)

    const { from } = this.state
    if (!to || (to && from && DateUtils.isDayBefore(to, from))) {
      // User entered in an invalid `to` date, clear the `to` input
      this.setState({ toInputValue: '' })
    }
  }

  handleNoEndDate = event => {
    this.setState({
      noEndDate: event,
    })

    if (event) {
      this.setState({
        enteredTo: null,
        to: null,
        toInputValue: '',
      })
    }
  }

  render() {
    const {
      className,
      classes,
      id,
      initialMonth,
      fixedStartDate,
      enableNoEndDate,
      hideEndDate,
    } = this.props
    const {
      from,
      to,
      enteredTo,
      fromInputValue,
      toInputValue,
      noEndDate,
      updatedDisabledDays,
    } = this.state

    const modifiers = { start: from, enteredEnd: !to ? enteredTo : null, end: to }
    const disabledDays = updatedDisabledDays || { before: from }
    const selectedDays = [from, { from, to: enteredTo }]

    const dayPickerClasses = {
      ...defaultDayPickerClassNames,
      container: classes.datePicker,
      wrapper: classes.datePickerWrapper,
      month: classes.datePickerMonth,
      body: classes.datePickerBody,
      week: classes.datePickerWeek,
      caption: classes.datePickerCaption,
      day: classes.datePickerDay,
      selected: classes.datePickerDaySelected,
      today: '',
    }

    let handleDayClick
    if (fixedStartDate && noEndDate) {
      handleDayClick = undefined
    } else if (fixedStartDate) {
      handleDayClick = this.handleSelectToDayClick
    } else if (noEndDate) {
      handleDayClick = this.handleSelectFromDayClick
    } else {
      handleDayClick = this.handleDayClick
    }

    return (
      <div className={cx(className, classes.datePickerRange)}>
        <MultiInput errorId={`${id}-error`}>
          <div>
            <FormLabel
              htmlFor={`${id}-from`}
              labelText={hideEndDate ? 'Specific Date' : 'Start Date'}
            />
            <TextInput
              className={classes.datePickerInput}
              disabled={fixedStartDate}
              id={`${id}-from`}
              onBlur={this.handleBlurFrom}
              onChange={this.handleChangeFrom}
              placeholder={DATE_FORMAT}
              value={fromInputValue}
            />
          </div>
          {!hideEndDate && (
            <div>
              <FormLabel htmlFor={`${id}-to`} labelText="End Date" />
              <TextInput
                className={classes.datePickerInput}
                disabled={noEndDate}
                id={`${id}-to`}
                onBlur={this.handleBlurTo}
                onChange={this.handleChangeTo}
                placeholder={DATE_FORMAT}
                value={toInputValue}
              />
            </div>
          )}
        </MultiInput>

        <DayPicker
          classNames={dayPickerClasses}
          disabledDays={disabledDays}
          initialMonth={from || initialMonth}
          modifiers={modifiers}
          navbarElement={({ onPreviousClick, onNextClick }) => (
            <div className={classes.datePickerNav}>
              <Button
                icon="chevronLeft"
                kind="secondary"
                onClick={() => onPreviousClick()}
                size="x-small"
              />
              <Button
                icon="chevronRight"
                kind="secondary"
                onClick={() => onNextClick()}
                size="x-small"
              />
            </div>
          )}
          onDayClick={handleDayClick}
          onDayMouseEnter={noEndDate ? undefined : this.handleDayMouseEnter}
          selectedDays={selectedDays}
          showOutsideDays={!noEndDate}
          showWeekDays={false}
        />

        {enableNoEndDate && (
          <BooleanValue defaultValue={noEndDate || false} onChange={this.handleNoEndDate}>
            {({ toggle, value }) => (
              <Checkbox
                className={classes.buttonContainer}
                checked={value}
                id={`${id}-no-end-date`}
                labelText="No end date"
                onChange={toggle}
              />
            )}
          </BooleanValue>
        )}
        <div className={classes.buttonContainer}>
          {noEndDate}
          <Button onClick={this.handleApplyClick} disabled={!(from && to) && !noEndDate}>
            Apply
          </Button>
        </div>
      </div>
    )
  }
}

export { DatePickerRange as DatePickerRangeUnStyled }
export default injectSheet(styles)(DatePickerRange)
