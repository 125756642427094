const initialState = []

export default (state = initialState, action) => {
  let newToast

  switch (action.type) {
    case 'ADD_TOAST':
      newToast = action.payload
      return [...state, newToast]
    case 'TAG_TOAST_FOR_REMOVAL':
      return state.map(toast =>
        toast.id === action.payload.id ? { ...toast, isDismissed: true } : toast
      )
    case 'REMOVE_TOAST':
      return state.filter(toast => toast.id !== action.payload.id)
    default:
      return state
  }
}

const addToast = payload => ({
  type: 'ADD_TOAST',
  payload,
})

const tagToastForRemoval = payload => ({
  type: 'TAG_TOAST_FOR_REMOVAL',
  payload,
})

const removeToast = payload => ({
  type: 'REMOVE_TOAST',
  payload,
})

// Length of time the toast notification will show on the screen before disappearing
const TOAST_DISPLAY_TIME = 5000
const TOAST_DISMISS_ANIMATION_TIME = 200

const toastReducerModel = {
  nextId: 0,
}
const generateToastId = () => ++toastReducerModel.nextId

export const dismissToast = toastId => dispatch =>
  dispatch({
    type: 'DISMISS_TOAST',
    payload: () => {
      dispatch(tagToastForRemoval({ id: toastId }))

      setTimeout(() => {
        dispatch(removeToast({ id: toastId }))
      }, TOAST_DISMISS_ANIMATION_TIME)
    },
  })

export const createToast = newToastData => dispatch =>
  dispatch({
    type: 'CREATE_TOAST',
    payload: () => {
      const newToastId = generateToastId()
      dispatch(addToast({ id: newToastId, ...newToastData }))

      setTimeout(() => {
        dispatch(dismissToast(newToastId))
      }, TOAST_DISPLAY_TIME)
    },
  })
