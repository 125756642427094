import React from 'react'
import injectSheet from 'react-jss'

import { Icon } from 'components'

import { TableRow, TableCell } from '.'

const styles = theme => ({
  tableEmptyMsgRow: {
    '&:hover': {
      // Overwrite default table row hover behaviour (defined in Table styles)
      background: 'none !important',
    },
  },
  tableEmptyMsg: {
    fontSize: theme.fontSize.body,
    padding: theme.spacing.xlg,
    textAlign: 'center',
  },
  tableEmptyMsgIcon: {
    fill: theme.support03,
    marginRight: theme.spacing.xs,
    verticalAlign: 'middle',
  },
})

const TableEmptyMsg = ({ classes, colSpan, emptyMsg }) => (
  <TableRow className={classes.tableEmptyMsgRow}>
    <TableCell colSpan={colSpan}>
      <div className={classes.tableEmptyMsg}>
        <Icon className={classes.tableEmptyMsgIcon} name="warning" />
        {emptyMsg}
      </div>
    </TableCell>
  </TableRow>
)
export default injectSheet(styles)(TableEmptyMsg)
