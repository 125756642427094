import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import injectSheet from 'react-jss'

import { Button, Icon } from 'components'

const styles = theme => ({
  container: {
    alignItems: 'center',
    border: `1px solid ${theme.bgSelected}`,
    color: theme.text01,
    display: 'flex',
    fontFamily: theme.fontFamilySansSerif,
    justifyContent: 'space-between',
    lineHeight: theme.baseLineHeight,
    padding: theme.spacing.md,
    width: '100%',
  },
  selectedFile: {
    marginRight: theme.spacing.md,
  },
  selectedFileWithIconContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  selectedFileIcon: {
    fill: theme.text02,
    flexShrink: 0,
    height: 12,
    marginRight: theme.spacing.xs,
  },
  selectedFileText: {
    fontSize: theme.fontSize.body,
  },
  requirementsText: {
    fontSize: theme.fontSize.small,
    marginTop: theme.spacing.sm,
    wordBreak: 'break-word',
  },
  fileInput: {
    display: 'none',
  },
  selectFileButton: {
    flexShrink: 0,
  },
})

class FileSelect extends Component {
  static propTypes = {
    /**
     * `id` to be attached to the `file`-type input
     */
    id: PropTypes.string.isRequired,
    /**
     * String of a file URL that already exists or is passed-in
     */
    existingFile: PropTypes.string,
    /**
     * `fileRequirementsText` is displayed under the "No file selected" text placeholder.
     */
    fileRequirementsText: PropTypes.string,
    /**
     * Function to be called every time the user selects a new file to be uploaded.
     */
    onFileSelect: PropTypes.func.isRequired,
  }

  state = {
    // selectedFileName
  }

  handleFileSelect = event => {
    const { onFileSelect } = this.props
    const selectedFile = event.target.files[0]

    if (selectedFile) {
      this.setState(
        {
          selectedFileName: 'Uploading...',
        },
        () => {
          Promise.resolve()
            .then(() => {
              return onFileSelect(selectedFile)
            })
            .then(success => {
              // If they aren't explicitly returning anything then not implemented promise
              if (success || success === undefined) {
                this.setState({ selectedFileName: selectedFile.name })
              } else {
                this.fileInput.value = null
                this.setState({ selectedFileName: null })
              }
            })
        }
      )
    }
  }

  render() {
    const { classes, id, existingFileUrl, fileRequirementsText } = this.props
    const { selectedFileName } = this.state

    return (
      <div className={classes.container}>
        <div className={classes.selectedFile}>
          {selectedFileName ? (
            <div className={classes.selectedFileWithIconContainer}>
              <Icon className={classes.selectedFileIcon} name="file" />
              <p className={classes.selectedFileText}>{selectedFileName}</p>
            </div>
          ) : (
            <Fragment>
              <p className={classes.selectedFileText}>{existingFileUrl || 'No file selected'}</p>
              {fileRequirementsText && (
                <p className={classes.requirementsText}>{fileRequirementsText}</p>
              )}
            </Fragment>
          )}
        </div>
        <input
          className={classes.fileInput}
          id={id}
          type="file"
          onChange={this.handleFileSelect}
          ref={el => {
            this.fileInput = el
          }}
        />
        <Button
          className={classes.selectFileButton}
          kind="secondary"
          onClick={() => {
            this.fileInput.click()
          }}
        >
          Choose file
        </Button>
      </div>
    )
  }
}

export { FileSelect as FileSelectUnStyled }
export default injectSheet(styles)(FileSelect)
