import React from 'react'
import injectSheet from 'react-jss'

import { Checkbox, FormFieldItem } from 'components'

import styles from '../PromotedPlacementsForm.styles'

const PlacementActiveDays = ({ classes, componentId, checkboxOptions }) => (
  <FormFieldItem
    className={classes.formRow}
    id={componentId}
    labelText="Active Days"
    name="days"
    render={() => (
      <div className={classes.formInput}>
        {checkboxOptions.map((day, index) => (
          <FormFieldItem
            className={classes.formInlineCheckbox}
            id={day.id}
            key={index}
            name={`days.${day.name}`}
            render={renderProps => (
              <Checkbox {...renderProps} checked={renderProps.value} labelText={day.labelText} />
            )}
          />
        ))}
      </div>
    )}
  />
)

export default injectSheet(styles)(PlacementActiveDays)
