import isEmpty from 'lodash/isEmpty'
import memoize from 'memoize-one'
import React, { Component, Fragment } from 'react'
import injectSheet from 'react-jss'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { fetchProduct, updateProduct } from 'api'
import config from 'config'
import {
  Button,
  ButtonIconAction,
  ButtonToggle,
  ButtonSlideToggle,
  DataTableWithState,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  LoadingIndicator,
  Modal,
  Notification,
  NutritionTable,
  PageHeader,
  ReadOnlyValueCard,
  ReadOnlyValueDetails,
  SearchInput,
  ShowIfAuthorized,
  StatusIndicatorDot,
  Tab,
  Tabs,
} from 'components'

import { createToast } from 'modules/toasts'
import Styling from 'styling/components'
import { getBackButton } from 'routing'
import { formatDate, formatDatetime, get, ItemFetcher, lowercase, StateHolder } from 'utils'

import TemporaryEdit from './TemporaryEdit'

import styles from './Product.styles'

const PAGE_ID = 'product'

// Product Pricing Table Configurations
const productPricingStoreTableHeaders = [
  {
    key: 'status',
  },
  {
    key: 'storeId',
    header: 'Store Id',
  },
  {
    key: 'name',
    header: 'Store Name',
  },
  {
    key: 'pricing',
    header: 'Pricing',
  },
  {
    key: 'soldBy',
    header: 'Sold by',
  },
  {
    key: 'aisle',
    header: 'Aisle',
  },
]

const generateProductActionDropdown = ({ product, refetchProduct, toggleAllStores }) => {
  return (
    <DropdownMenu
      ariaLabel="Actions"
      menuAlignment="right"
      triggerRender={({ description, isOpen, onClick }) => (
        <ButtonToggle
          icon="more"
          id={`${PAGE_ID}-${product.id}-action`}
          isToggled={isOpen}
          onClick={onClick}
          untoggledDescription={description}
        />
      )}
    >
      <DropdownMenuItem
        kind="primary"
        onClick={() => toggleAllStores(product, true, refetchProduct)}
      >
        Enable All
      </DropdownMenuItem>
      <DropdownMenuItem
        kind="danger"
        onClick={() => toggleAllStores(product, false, refetchProduct)}
      >
        Disable All
      </DropdownMenuItem>
    </DropdownMenu>
  )
}

class Product extends Component {
  // Use state instead of a component in this case to preserve the current tab
  // TODO - refactor this to use a global state
  state = {
    selectedTab: 0,
    pricingTableInitialValue: {
      status: null,
      sale: null,
      aisle: null,
      nameId: '',
    },
  }

  selectTab = selectedTab => {
    this.setState({ selectedTab })
  }

  showPricingAvailability = (showOnSale = null) => {
    const { pricingTableInitialValue } = this.state

    pricingTableInitialValue.status = true
    pricingTableInitialValue.sale = showOnSale

    this.setState({ pricingTableInitialValue, selectedTab: 1 })
  }

  toggleAllStores = (product, status, callback = () => {}) => {
    const { currentPricing } = product

    currentPricing.forEach(store => {
      store.status = status
    })

    updateProduct(product.id, product).then(() => {
      const statusText = !status ? 'disabled' : 'enabled'

      this.props.createToast({
        kind: 'success',
        message: `Product has been ${statusText} for all stores.`,
      })

      callback()
    })
  }

  toggleSingleStore = (product, storeId, callback = () => {}) => {
    const { currentPricing } = product

    const currentStoreIndex = currentPricing.findIndex(({ store: currentStore }) =>
      currentStore.extId ? storeId === currentStore.extId : storeId === currentStore.id
    )

    currentPricing[currentStoreIndex].status = !currentPricing[currentStoreIndex].status

    updateProduct(product.id, product).then(() => {
      const statusText = !currentPricing[currentStoreIndex].status ? 'disabled' : 'enabled'

      this.props.createToast({
        kind: 'success',
        message: `Product has been ${statusText} in ${currentPricing[currentStoreIndex].store.name}.`,
      })

      callback()
    })
  }

  render() {
    const {
      classes,
      createToast,
      location,
      match: {
        params: { productId },
      },
    } = this.props

    const { pricingTableInitialValue } = this.state

    return productId ? (
      <ItemFetcher
        queryKey="product"
        queryParams={{ productId }}
        fetchItem={itemConfig => fetchProduct(productId, itemConfig)}
        render={({
          item: product,
          isLoadingItem: isFetchingProduct,
          error: errorFetchingProduct,
          refetch: refetchProduct,
        }) => {
          if (isFetchingProduct) {
            return <LoadingIndicator withTopMargin />
          }
          if (errorFetchingProduct) {
            return (
              <Styling.Center maxWidth={500} withTopMargin>
                <Notification kind="error" message={errorFetchingProduct.message} />
              </Styling.Center>
            )
          }
          if (product) {
            const {
              activeStores,
              brand,
              categories,
              currentPricing,
              description,
              glutenFree,
              id,
              imageUrl,
              ingredients,
              instruction,
              locallyGrown,
              modified,
              modifiedBy,
              name,
              nutrition,
              organic,
              privateLabel,
              saleStores,
              sku,
              soldByWeight,
              tags,
              uom,
              upcs,
              weight,
              rrc,
            } = product

            const size = weight ? `${weight} ${lowercase({ phrase: uom })}` : null
            const multipleUpcs = upcs.length > 1
            const displayUpcs = multipleUpcs ? `${upcs[0]} (${upcs.length})` : upcs[0]
            let displayMultipleUpcs = []

            if (multipleUpcs) {
              displayMultipleUpcs = upcs.map((upc, i) => ({
                id: `${PAGE_ID}-detail-upc-${i}`,
                labelText: '',
                useCustomValueEl: true,
                value: <span className={classes.customUpcElement}>{upc}</span>,
              }))
            }

            const displayCategory = categories.join(' / ')
            const tagsMap = {
              'Gluten Free': glutenFree,
              'Locally Grown': locallyGrown,
              Organic: organic,
              'Private Label': privateLabel,
            }
            const displayTags = Object.keys(tagsMap)
              .filter(tag => tagsMap[tag])
              .join(', ')

            const relatedFacet = tags.map(facet => facet.tag.group_display_name).join(', ')

            let openTemporaryEditModal = () => {}

            return (
              <div>
                <PageHeader
                  backButton={get(location, 'state.backButton') || getBackButton('products')}
                  headerTitle={name}
                >
                  <div className={classes.rightHeaderButtons}>
                    <ShowIfAuthorized requiredPermission="products.edit">
                      <Modal
                        handleCloseModal={({ requiresRefetch }) => {
                          if (requiresRefetch) {
                            createToast({
                              kind: 'success',
                              message: 'Product successfully updated.',
                            })
                            refetchProduct()
                          }
                        }}
                        size="large"
                        triggerRender={({ openModal }) => {
                          openTemporaryEditModal = openModal

                          return (
                            <Button
                              onClick={openModal}
                              icon="temp"
                              iconPosition="before"
                              kind="secondary"
                            >
                              Temporary Update Product Info
                            </Button>
                          )
                        }}
                        render={props => <TemporaryEdit productId={id} {...props} />}
                      />
                    </ShowIfAuthorized>
                  </div>
                </PageHeader>
                <div className={classes.underHeaderContainer}>
                  <div className={classes.leftSide}>
                    <ReadOnlyValueCard
                      detailValues={[
                        {
                          id: `${PAGE_ID}-detail-image`,
                          useCustomValueEl: true,
                          value: (
                            <div className={classes.thumbnailContainer}>
                              <div className={classes.detailImage}>
                                <img alt="" src={imageUrl} />
                              </div>
                              <div className={classes.fixedActionContainer}>
                                <ButtonIconAction
                                  className={classes.imageActionHover}
                                  description="Temporary Edit"
                                  onClick={() => openTemporaryEditModal()}
                                  icon="temp"
                                  kind="secondary"
                                />
                              </div>
                            </div>
                          ),
                        },
                        {
                          id: `${PAGE_ID}-detail-size`,
                          labelText: 'Size',
                          value: size || 'n/a',
                        },
                        {
                          id: `${PAGE_ID}-detail-sku`,
                          labelText: 'SKU',
                          value: sku || 'n/a',
                        },
                        {
                          id: `${PAGE_ID}-detail-upcs`,
                          labelText: 'UPC',
                          value: displayUpcs || 'n/a',
                          type: multipleUpcs ? 'withDetails' : null,
                          detailValues: displayMultipleUpcs,
                        },
                        {
                          id: `${PAGE_ID}-detail-rrc`,
                          labelText: 'RRC',
                          value: rrc || 'n/a',
                        },
                        {
                          id: `${PAGE_ID}-detail-url`,
                          labelText: 'URL',
                          useCustomValueEl: true,
                          value: (
                            <Button
                              href={`${config.env.webUrl}/product/${id}`}
                              icon="external"
                              iconPosition="inline"
                              kind="link"
                              linkIsExternal
                              linkIconShowOnHover
                              toolTipText="Product may not be available in all stores"
                            >
                              {`${config.env.webUrl}/product/${id}`}
                            </Button>
                          ),
                        },
                        {
                          id: `${PAGE_ID}-available-url`,
                          labelText: 'Available',
                          useCustomValueEl: true,
                          value: (
                            <Button kind="link" onClick={() => this.showPricingAvailability()}>
                              {activeStores ? activeStores.length.toString() : 'n/a'}
                            </Button>
                          ),
                        },
                        {
                          id: `${PAGE_ID}-sale-url`,
                          labelText: 'On Sale',
                          useCustomValueEl: true,
                          value: (
                            <Button kind="link" onClick={() => this.showPricingAvailability(true)}>
                              {saleStores ? saleStores.length.toString() : 'n/a'}
                            </Button>
                          ),
                        },
                        {
                          id: `${PAGE_ID}-detail-last-update`,
                          labelText: 'Last Update',
                          value: modified ? formatDatetime(modified) : 'n/a',
                        },
                        {
                          id: `${PAGE_ID}-detail-updated-by`,
                          labelText: 'Updated By',
                          value: modifiedBy
                            ? `${modifiedBy.firstName} ${modifiedBy.lastName}`
                            : 'n/a',
                        },
                      ]}
                      noPaddingTop
                    />
                  </div>
                  <div className={classes.rightSide}>
                    <Tabs
                      selectedTab={this.state.selectedTab}
                      onSelectTab={this.selectTab}
                      className={classes.tabContainer}
                    >
                      <Tab label="Detail">
                        <ReadOnlyValueCard
                          detailValues={[
                            {
                              action: (
                                <ButtonIconAction
                                  description="Temporary Edit"
                                  onClick={() => openTemporaryEditModal()}
                                  icon="temp"
                                  kind="secondary"
                                />
                              ),
                              id: `${PAGE_ID}-detail-name`,
                              labelText: 'Name',
                              value: name || 'n/a',
                            },
                            {
                              action: (
                                <ButtonIconAction
                                  description="Temporary Edit"
                                  onClick={() => openTemporaryEditModal()}
                                  icon="temp"
                                  kind="secondary"
                                />
                              ),
                              id: `${PAGE_ID}-detail-description`,
                              labelText: 'Description',
                              value: description || 'n/a',
                            },
                            {
                              id: `${PAGE_ID}-detail-tags`,
                              labelText: 'Tags',
                              value: displayTags || 'n/a',
                            },
                            {
                              id: `${PAGE_ID}-detail-category`,
                              labelText: 'Category',
                              value: displayCategory || 'n/a',
                            },
                            {
                              id: `${PAGE_ID}-related-facet`,
                              labelText: 'Related Facet',
                              value: relatedFacet || 'n/a',
                            },
                            {
                              id: `${PAGE_ID}-detail-brand`,
                              labelText: 'Brand',
                              value: brand || 'n/a',
                            },
                            {
                              id: `${PAGE_ID}-detail-ingredients`,
                              labelText: 'Ingredients',
                              value: ingredients || 'n/a',
                            },
                            {
                              id: `${PAGE_ID}-detail-instruction`,
                              labelText: 'Instruction',
                              value: instruction || 'n/a',
                            },
                            {
                              id: `${PAGE_ID}-nutrition`,
                              labelText: 'Nutrition',
                              useCustomValueEl: true,
                              value:
                                nutrition && nutrition.data && nutrition.data.fields.length > 0 ? (
                                  <NutritionTable
                                    rows={nutrition.data.fields}
                                    servingPerContainer={nutrition.data.per_container}
                                    servingSize={nutrition.data.amount}
                                  />
                                ) : (
                                  'n/a'
                                ),
                            },
                          ]}
                        />
                      </Tab>
                      <Tab label="Pricing and Store Info">
                        <StateHolder
                          debounce
                          debounceTime={500}
                          initialValue={pricingTableInitialValue}
                          render={({
                            handleChange: onFilterChange,
                            value: selectedFilterValue,
                          }) => {
                            const productPricingOptions = {
                              status: [
                                { id: `${PAGE_ID}-store-status-all`, label: 'All', value: null },
                                {
                                  id: `${PAGE_ID}-store-status-enabled`,
                                  label: 'Enabled',
                                  value: true,
                                },
                                {
                                  id: `${PAGE_ID}-store-status-disabled`,
                                  label: 'Disabled',
                                  value: false,
                                },
                              ],
                              salePricing: [
                                { id: `${PAGE_ID}-store-pricing-all`, label: 'All', value: null },
                                {
                                  id: `${PAGE_ID}-store-pricing-sale`,
                                  label: 'On Sale',
                                  value: true,
                                },
                                {
                                  id: `${PAGE_ID}-store-pricing-no-sale`,
                                  label: 'Not On Sale',
                                  value: false,
                                },
                              ],
                              aisle: [
                                { id: `${PAGE_ID}-store-aisle-all`, label: 'All', value: null },
                                {
                                  id: `${PAGE_ID}-store-aisle-info`,
                                  label: 'Has Aisle Info',
                                  value: true,
                                },
                                {
                                  id: `${PAGE_ID}-store-aisle-no-info`,
                                  label: 'No Aisle Info',
                                  value: false,
                                },
                              ],
                            }

                            const formatProductPricingTable = memoize(productPricing =>
                              productPricing.map(store => {
                                const {
                                  aisle,
                                  basePricing,
                                  salePricing,
                                  store: { extId, id, name },
                                  status,
                                } = store
                                const storeId = extId || id

                                const statusIndicatorType = status ? 'complete' : 'cancelled'
                                const statusIndicatorText = status ? 'Active' : 'Inactive'
                                const statusDescriptionText = status ? 'disable' : 'enable'

                                const displayPricing = () => {
                                  if (!isEmpty(basePricing) && !isEmpty(salePricing)) {
                                    return (
                                      <div>
                                        <span className={classes.lineThrough}>
                                          ${basePricing.unitPrice}
                                        </span>
                                        <span>${salePricing.unitPrice}</span>
                                      </div>
                                    )
                                  }
                                  if (!isEmpty(basePricing)) {
                                    return `$${basePricing.unitPrice}`
                                  }
                                  return `n/a`
                                }

                                return {
                                  aisle: aisle || `n/a`,
                                  id: `${PAGE_ID}-pricing-${storeId}`,
                                  name,
                                  pricing: displayPricing(),
                                  rowAction: (
                                    <ShowIfAuthorized requiredPermission="products.edit">
                                      <ButtonSlideToggle
                                        toggledDescription={`Click to ${statusDescriptionText}`}
                                        untoggledDescription={`Click to ${statusDescriptionText}`}
                                        isToggled={status}
                                        onClick={() =>
                                          this.toggleSingleStore(product, storeId, refetchProduct)
                                        }
                                      />
                                    </ShowIfAuthorized>
                                  ),
                                  rowDetails: (
                                    <Fragment>
                                      <div className={classes.readOnlyValueDetailsMargin}>
                                        <ReadOnlyValueDetails
                                          detailValues={[
                                            {
                                              id: `${PAGE_ID}-store-${storeId}-department`,
                                              labelText: 'Base Unit Price',
                                              value: `$${basePricing.unitPrice}` || 'n/a',
                                            },
                                            {
                                              id: `${PAGE_ID}-store-${storeId}-order-id`,
                                              labelText: 'Base Quantity',
                                              value: basePricing.quantity || 'n/a',
                                            },
                                            {
                                              id: `${PAGE_ID}-store-${storeId}-send-email`,
                                              labelText: 'Base Price',
                                              value: `$${basePricing.price}` || 'n/a',
                                            },
                                            {
                                              id: `${PAGE_ID}-store-${storeId}-notes-to-guest`,
                                              labelText: 'Sale Unit Price',
                                              value: salePricing.unitPrice
                                                ? `$${salePricing.unitPrice}`
                                                : 'n/a',
                                            },
                                            {
                                              id: `${PAGE_ID}-store-${storeId}-internatl-note`,
                                              labelText: 'Sales Type',
                                              value: salePricing.saleType || 'n/a',
                                            },
                                          ]}
                                          orientation="vertical"
                                        />
                                      </div>
                                      <div>
                                        <ReadOnlyValueDetails
                                          detailValues={[
                                            {
                                              id: `${PAGE_ID}-store-${storeId}-department`,
                                              labelText: 'Sale Start Date',
                                              value: formatDate(salePricing.startDate) || 'n/a',
                                            },
                                            {
                                              id: `${PAGE_ID}-store-${storeId}-order-id`,
                                              labelText: 'Sales End Date',
                                              value: formatDate(salePricing.endDate) || 'n/a',
                                            },
                                            {
                                              id: `${PAGE_ID}-store-${storeId}-send-email`,
                                              labelText: 'Loyalty Required?',
                                              value: salePricing.loyaltyRequired ? 'Yes' : 'No',
                                            },
                                            {
                                              id: `${PAGE_ID}-store-${storeId}-notes-to-guest`,
                                              labelText: 'Quantity Required',
                                              value: salePricing.quantityRequired || 'n/a',
                                            },
                                            {
                                              id: `${PAGE_ID}-store-${storeId}-internatl-note`,
                                              labelText: 'Limit',
                                              value: salePricing.limit || 'n/a',
                                            },
                                          ]}
                                          orientation="vertical"
                                        />
                                      </div>
                                    </Fragment>
                                  ),
                                  soldBy: soldByWeight ? `Weight` : `Each`,
                                  status: (
                                    <StatusIndicatorDot
                                      compact
                                      text={statusIndicatorText}
                                      type={statusIndicatorType}
                                    />
                                  ),
                                  storeId: extId || 'n/a',
                                }
                              })
                            )

                            const filteredProductPricing = currentPricing.filter(pricing => {
                              const matchNameOrId = () => {
                                if (selectedFilterValue.nameId.length > 0) {
                                  const storeName = String(pricing.store.name ?? '').toLowerCase()
                                  const storeId = pricing.store.id
                                  const storeExtId = String(pricing.store.extId ?? '').toLowerCase()

                                  const query = selectedFilterValue.nameId.toLowerCase()
                                  const parsedQuery = parseInt(query, 10)

                                  // match to the following properties of the store
                                  // name
                                  // extId - we display the extId in favour of id when available
                                  // id - for cases where we've displayed the id (i.e. extId wasn't available)
                                  return (
                                    storeName.includes(query) ||
                                    storeExtId.includes(query) ||
                                    (!storeExtId && !isNaN(parsedQuery) && storeId === parsedQuery)
                                  )
                                }
                                return true
                              }

                              const filterStatus =
                                selectedFilterValue.status !== null
                                  ? pricing.status === selectedFilterValue.status
                                  : true
                              const filterSale =
                                selectedFilterValue.sale !== null
                                  ? !isEmpty(pricing.salePricing) === selectedFilterValue.sale
                                  : true
                              const filterAisle =
                                selectedFilterValue.aisle !== null
                                  ? !isEmpty(pricing.aisle) === selectedFilterValue.aisle
                                  : true
                              const filterNameId = matchNameOrId()

                              return filterStatus && filterSale && filterAisle && filterNameId
                            })

                            const productPricingTableRows = formatProductPricingTable(
                              filteredProductPricing
                            )

                            return (
                              <Fragment>
                                <div className={classes.filters}>
                                  <Dropdown
                                    id={`${PAGE_ID}-pricing-store-table-status`}
                                    items={productPricingOptions.status}
                                    onChange={value =>
                                      onFilterChange({ ...selectedFilterValue, status: value })
                                    }
                                    placeholder="Status"
                                    selectedValue={selectedFilterValue.status}
                                  />
                                  <Dropdown
                                    id={`${PAGE_ID}--pricing-store-table-sale-pricing`}
                                    items={productPricingOptions.salePricing}
                                    onChange={value =>
                                      onFilterChange({ ...selectedFilterValue, sale: value })
                                    }
                                    placeholder="Sale Pricing"
                                    selectedValue={selectedFilterValue.sale}
                                  />
                                  <Dropdown
                                    id={`${PAGE_ID}-pricing-store-table-aisle`}
                                    items={productPricingOptions.aisle}
                                    onChange={value =>
                                      onFilterChange({ ...selectedFilterValue, aisle: value })
                                    }
                                    placeholder="Aisle"
                                    selectedValue={selectedFilterValue.aisle}
                                  />
                                  <SearchInput
                                    id={`${PAGE_ID}-pricing-store-table-search`}
                                    onChange={value =>
                                      onFilterChange({ ...selectedFilterValue, nameId: value })
                                    }
                                    placeholder="Search store ID or store name"
                                    value={selectedFilterValue.nameId}
                                  />
                                </div>
                                <DataTableWithState
                                  bulkAction={generateProductActionDropdown({
                                    toggleAllStores: this.toggleAllStores,
                                    refetchProduct,
                                    product,
                                  })}
                                  error={errorFetchingProduct}
                                  headers={productPricingStoreTableHeaders}
                                  id={`${PAGE_ID}-pricing-table`}
                                  isLoadingNewRows={isFetchingProduct}
                                  rows={productPricingTableRows}
                                />
                              </Fragment>
                            )
                          }}
                        />
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            )
          }
        }}
      />
    ) : null
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createToast,
    },
    dispatch
  )

export default injectSheet(styles)(connect(null, mapDispatchToProps)(Product))
