import { getRouteTo } from 'routing'
import { hasAnyOfPermissions } from 'utils/navigationUtils'
import { generatePlacementRoutes } from 'pages/Dashboard/Marketing/PromotedPlacements/PromotedPlacements'
import {
  HERO_BANNERS,
  HOMEPAGE_BANNERS,
  HOMEPAGE_COLLECTIONS,
  HOMEPAGE_HERO,
  IMAGE_CAROUSELS,
  MOBILE_HOMEPAGE_HERO,
  QUICK_LINKS,
  RICH_TEXT_CONTAINERS,
  SLIM_BANNERS,
  SPONSORED_TOP_DEALS,
  TILE_CAROUSELS,
} from 'constants/placementTypes'

const placementLabels = {
  [HERO_BANNERS]: 'Banner - Hero',
  [RICH_TEXT_CONTAINERS]: 'Banner - Text',
  [SLIM_BANNERS]: 'Banner - Slim',
  [IMAGE_CAROUSELS]: 'Carousel - Images',
  [QUICK_LINKS]: 'Carousel - Links',
  [TILE_CAROUSELS]: 'Carousel - Tiles',
  [HOMEPAGE_BANNERS]: 'Homepage Banners',
  [HOMEPAGE_COLLECTIONS]: 'Homepage Collections',
  [HOMEPAGE_HERO]: 'Homepage Hero',
  [MOBILE_HOMEPAGE_HERO]: 'Mobile Homepage Hero',
  [SPONSORED_TOP_DEALS]: 'Sponsored Top Deals',
}

export default () => ({
  left: [
    {
      determineAuthorization: hasAnyOfPermissions(['store_groups.view', 'segments.view']),
      icon: 'marketing',
      id: 'marketing',
      label: 'Marketing',
      legacy: false,
      links: [
        // {
        //   to: { pathname: '/marketing/content' }, // '/marketing/content/offers'?
        //   label: 'Content',
        //   links: [
        //     {
        //       label: 'Offers',
        //       to: { pathname: '/marketing/content/offers' },
        //     },
        //     {
        //       label: 'Subscriptions',
        //       to: { pathname: '/marketing/content/subscriptions' },
        //     },
        //     {
        //       label: 'External Pages',
        //       to: { pathname: '/marketing/content/external-pages' },
        //     },
        //     {
        //       label: 'Recipes',
        //       to: { pathname: '/marketing/content/recipes' },
        //     },
        //   ],
        // },
        {
          determineAuthorization: hasAnyOfPermissions(['store_groups.view', 'segments.view']),
          id: 'segments-and-store-groups',
          label: 'Segments & Store Groups',
          to: { pathname: '/marketing/segments-and-store-groups' },
          links: [
            {
              id: 'segments',
              label: 'User Segments',
              to: getRouteTo('segments'),
              requiredPermission: 'segments.view',
            },
            {
              id: 'store-groups',
              label: 'Store Groups',
              to: getRouteTo('storeGroups'),
              requiredPermission: 'store_groups.view',
            },
          ],
        },
        // {
        //   label: 'Site Design/Promoted Placements',
        //   id: 'site-design-and-promoted-placements',
        //   to: { pathname: '/marketing/site-design-and-promoted-placements' },
        //   links: [
        //     {
        //       label: 'Weekly Flyers',
        //       to: { pathname: '/marketing/site-design-and-promoted-placements/flyers' },
        //     },
        //     {
        //       label: 'Ways to Shop',
        //       to: { pathname: '/marketing/site-design-and-promoted-placements/ways-to-shop' },
        //     },
        //     {
        //       label: 'Homepage',
        //       to: { pathname: '/marketing/site-design-and-promoted-placements/homepage' },
        //     },
        //   ],
        // },
        // {
        //   label: 'Merchandising',
        //   id: 'merchandising',
        //   to: { pathname: '/marketing/merchandising' },
        //   links: [
        //     {
        //       label: 'Checkout Upsell',
        //       to: { pathname: '/marketing/merchandising/checkout-upsell' },
        //     },
        //     {
        //       label: 'Sponsored Product Suggestions',
        //       to: { pathname: '/marketing/merchandising/sponsored-products' },
        //     },
        //     {
        //       label: 'Sponsored Top Deals',
        //       to: { pathname: '/marketing/merchandising/sponsored-deals' },
        //     },
        //     {
        //       label: 'Sponsored Cart Suggestions',
        //       to: { pathname: '/marketing/merchandising/sponsored-cart' },
        //     },
        //   ],
        // },
        {
          label: 'Offers',
          id: 'offers',
          to: getRouteTo('offers'),
          requiredPermission: 'offers.view',
        },
        {
          label: 'Merchandising',
          id: 'promoted-placements',
          to: { pathname: '/marketing/promoted-placements' },
          links: [
            ...generatePlacementRoutes()
              .find(({ slug }) => slug === 'homepage')
              ?.childrenKeys.map(key => ({ id: key, label: placementLabels[key] }))
              .sort(({ label: a }, { label: b }) => a.localeCompare(b))
              .map((link, index) => ({
                ...link,
                to: getRouteTo('promotedPlacements.content', { slug: 'homepage', tab: index }),
              })),
            {
              label: 'Ways to Shop',
              to: getRouteTo('promotedPlacements.content', { slug: 'ways-to-shop' }),
            },
            {
              label: 'Catalog',
              to: getRouteTo('promotedPlacements.content', { slug: 'catalog' }),
            },
            {
              label: 'Search',
              to: getRouteTo('promotedPlacements.content', { slug: 'search' }),
            },
            {
              label: 'Checkout',
              to: getRouteTo('promotedPlacements.content', { slug: 'checkout' }),
            },
            {
              label: 'Site Announcement',
              to: getRouteTo('promotedPlacements.content', { slug: 'site-announcement' }),
            },
            {
              label: 'Flyer',
              to: getRouteTo('promotedPlacements.content', { slug: 'flyer' }),
              requiredConfig: config => !config.disableWeeklyAd,
            },
            {
              label: 'Collection Banner',
              to: getRouteTo('promotedPlacements.content', { slug: 'collection' }),
            },
            {
              label: 'Merchandised Landing Page',
              to: getRouteTo('promotedPlacements.content', { slug: 'mlp' }),
            },
          ],
          requiredPermission: 'content.view',
        },
        {
          label: 'Flyer Management',
          id: 'flyer-management',
          to: { pathname: '/marketing/flyer-management' },
          links: [
            {
              label: 'Flyer Management',
              to: getRouteTo('flyerManagement'),
            },
            {
              label: 'Flyer Preview Settings',
              to: getRouteTo('flyerPreviewSettings'),
              requiredPermission: 'content.edit',
            },
          ],
          requiredPermission: 'content.view',
          requiredConfig: config => !config.disableWeeklyAd,
        },
        {
          determineAuthorization: hasAnyOfPermissions([
            'search_rules.view',
            'recipe_search_rules.view',
            'synonyms.view',
            'search_keywords_redirects.view',
          ]),
          label: 'Search & Rankings',
          id: 'search',
          to: { pathname: '/marketing/search-settings' },
          links: [
            {
              label: 'Synonyms',
              to: { pathname: '/marketing/search-settings/synonyms' },
              requiredPermission: 'synonyms.view',
              requiredConfig: config => !config.enableICSearch,
            },
            {
              label: 'Keyword Redirects',
              to: { pathname: '/marketing/search-settings/keyword-redirects' },
              requiredPermission: 'search_keywords_redirects.view',
            },
            {
              label: 'Product Rules',
              requiredPermission: 'search_rules.view',
            },
            {
              label: 'Product Keyword Rules',
              to: { pathname: '/marketing/search-settings/keyword-rules' },
              requiredPermission: 'search_rules.view',
              requiredConfig: config => !config.enableICSearch,
            },
            {
              label: 'Product Global Settings',
              to: {
                pathname: '/marketing/search-settings/global-search-settings',
              },
              requiredPermission: 'search_rules.edit',
            },
            {
              label: 'Recipe Rules',
              requiredPermission: 'recipe_search_rules.view',
              requiredConfig: config => !config.disableRecipes,
            },
            {
              label: 'Recipe Keyword Rules',
              to: { pathname: '/marketing/search-settings/recipe-rules' },
              requiredPermission: 'recipe_search_rules.view',
              requiredConfig: config => !config.disableRecipes,
            },
            {
              label: 'Recipe Global Rules',
              to: {
                pathname: '/marketing/search-settings/global-recipe-search-settings',
              },
              requiredPermission: 'recipe_search_rules.edit',
              requiredConfig: config => !config.disableRecipes,
            },
          ],
        },
        {
          label: 'Email Templates',
          id: 'email-templates',
          to: getRouteTo('emailTemplates'),
          requiredPermission: 'email_templates.view',
        },
      ],
      routePath: '/marketing',
    },
    {
      determineAuthorization: hasAnyOfPermissions(['customers.view', 'orders.view']),
      icon: 'orders',
      id: 'customers-and-orders',
      label: 'Customers & Orders',
      legacy: false,
      links: [
        {
          id: 'customers',
          label: 'Guest Management',
          to: getRouteTo('customers'),
          requiredPermission: 'customers.view',
        },
        {
          id: 'orders',
          label: 'Order Management',
          to: getRouteTo('orders'),
          requiredPermission: 'orders.view',
        },
        {
          id: 'cateringOrders',
          label: 'Catering Orders',
          to: getRouteTo('cateringOrders'),
          requiredPermission: 'catering.view',
        },
        {
          id: 'ccpaRequests',
          label: 'Privacy Requests',
          to: getRouteTo('ccpaRequests'),
          requiredPermission: 'ccpa.view',
        },
        {
          id: 'offersExplain',
          label: 'Offers Explain',
          to: getRouteTo('offersExplain'),
          requiredPermission: 'offers.explain',
        },
      ],
      routePath: '/customers-and-orders',
    },
    {
      determineAuthorization: hasAnyOfPermissions(['products.view', 'collections.view']),
      icon: 'products',
      id: 'products-and-collections',
      label: 'Products & Collections',
      legacy: false,
      links: [
        {
          id: 'products',
          label: 'Product Lookup',
          to: getRouteTo('products'),
          requiredPermission: 'products.view',
        },
        {
          id: 'collections',
          label: 'Collections',
          to: getRouteTo('collections'),
          requiredPermission: 'collections.view',
        },
        {
          id: 'merchandised-landing-pages',
          label: 'Merchandised Landing Pages',
          to: getRouteTo('merchandisedLandingPages'),
          requiredPermission: 'collections.view',
        },
        {
          id: 'recipe-collections',
          label: 'Recipe Collections',
          to: getRouteTo('recipeCollections'),
          requiredPermission: 'collections.view',
          requiredConfig: config => !config.disableRecipes,
        },
      ],
      routePath: '/products-and-collections',
    },
    {
      determineAuthorization: hasAnyOfPermissions([
        'blackouts.view',
        'delivery_fees.view',
        'subscriptions.view',
        'stores.view',
        'plu.view',
      ]),
      icon: 'settings',
      id: 'settings',
      label: 'Platform Settings',
      legacy: false,
      links: [
        {
          label: 'Blackout Days',
          to: getRouteTo('blackoutDays'),
          requiredPermission: 'blackouts.view',
        },
        {
          label: 'Delivery Companies',
          to: getRouteTo('deliveryCompanies'),
          requiredPermission: 'delivery_companies.view',
        },
        {
          id: 'subscriptions',
          label: 'Subscriptions',
          to: getRouteTo('subscriptions'),
          requiredPermission: 'fees.view',
        },
        {
          label: 'Store Dashboard',
          to: getRouteTo('storeDashboard'),
          requiredPermission: 'stores.view',
        },
        {
          label: 'PLU Management',
          to: getRouteTo('pluManagement'),
          requiredPermission: 'plu.view',
        },
        {
          label: 'Configuration Management - Deprecated',
          to: getRouteTo('configurationManagement'),
          requiredPermission: 'facts.view',
        },
      ],
      routePath: '/settings',
    },
    {
      determineAuthorization: hasAnyOfPermissions(['facts.view', 'facts.edit']),
      id: 'facts',
      icon: 'edit', // TODO: get better icon
      label: 'Facts - Deprecated',
      legacy: false,
      routePath: 'facts',
      links: [
        {
          id: 'coffee-editing',
          label: 'Use Coffee to Edit Facts',
          to: getRouteTo('facts', { key: 'coffee-editing' }),
          requiredPermission: 'facts.view',
        },
      ],
    },
  ],
  right: [
    {
      id: 'help',
      label: 'Help',
      legacy: false,
      links: [],
      externalUrl: 'https://partner-docs.instacart.com/storefront_pro/operations_guide/overview',
    },
    {
      hideLabel: true,
      icon: 'profile',
      id: 'account',
      label: 'Account',
      legacy: false,
      links: [
        {
          label: 'My Profile',
          to: getRouteTo('myProfile'),
        },
        {
          label: 'Change Password',
          to: getRouteTo('changePassword'),
        },
        {
          label: 'User Management',
          to: getRouteTo('users'),
          requiredPermission: 'users.view_all',
        },
        {
          label: 'Roles & Permissions',
          to: getRouteTo('roles'),
          requiredPermission: 'roles.view_all',
        },
        {
          label: 'Demo',
          to: getRouteTo('demo'),
          requiredPermission: 'misc.demo',
        },
        {
          hideFromSidenav: true,
          label: 'Logout',
          to: { pathname: '/logout' },
        },
      ],
      routePath: '/account',
    },
  ],
})
