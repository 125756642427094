import color from 'color'

const focusOutline = outlineColor => ({
  '&:focus:not([disabled])': {
    outline: 0,
    // Create a thin white box shadow around the button (to mimic something like an outline offset),
    // and then surround that by a solid box shadow (with width accounting for white box shadow covering it)
    boxShadow: `0 0 0 1px #FFFFFF, 0 0 0 3px ${outlineColor}`,
  },
})

export default theme => {
  const themeBrand00Faded = color(theme.brand00)
    .fade(0.4)
    .string()

  const themeSupport01Faded = color(theme.support01)
    .fade(0.4)
    .string()

  return {
    button: {
      alignItems: 'center',
      borderRadius: theme.buttonBorderRadius,
      cursor: 'pointer',
      display: 'inline-flex',
      flexShrink: '0',
      fontFamily: theme.fontFamilySansSerif,
      fontSize: theme.buttonFontSize,
      fontWeight: theme.buttonFontWeight,
      height: theme.buttonHeight,
      justifyContent: 'center',
      minWidth: 80,
      padding: `0 ${theme.spacing.md}`,
      textAlign: 'center',
      textDecoration: 'none',
      whiteSpace: 'nowrap',

      '&:disabled': {
        cursor: 'not-allowed',
        opacity: '0.6',
      },

      'button&': {
        // <button> elements cannot be used as flex containers
        display: 'inline-block',
        lineHeight: '16px',
        verticalAlign: 'top',

        '&$buttonXs': {
          lineHeight: '7px',
        },

        // Reset intrisic padding in Firefox (see #731)
        '&::-moz-focus-inner': {
          padding: '0',
          border: '0',
        },

        // Vertically center icon and text/content inside <button>
        '& $buttonIcon': {
          position: 'relative',
          verticalAlign: 'middle',

          // Targets IE10+ browsers: Reset <svg> position for vertical centering
          '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
            top: '0',
          },
        },
        '& $buttonIconSibling': {
          display: 'inline-block',
          verticalAlign: 'middle',
        },
      },
    },
    buttonPrimary: {
      ...buttonBaseStyles({
        bgColor: theme.brand00,
        fontColor: theme.inverse01,
        disabledBgColor: theme.text02,
        disabledFontColor: theme.inverse01,
        hoverFocusBgColor: theme.brand01,
        focusOutlineColor: themeBrand00Faded,
        theme,
      }),
    },
    buttonSecondary: {
      ...buttonBaseStyles({
        borderColor: theme.brand00,
        fontColor: theme.brand00,
        disabledBorderColor: theme.text02,
        disabledFontColor: theme.text02,
        hoverFocusBgColor: theme.brand00,
        hoverFocusColor: theme.inverse01,
        focusOutlineColor: themeBrand00Faded,
        theme,
      }),
    },
    buttonDanger: {
      ...buttonBaseStyles({
        bgColor: theme.support01,
        fontColor: theme.inverse01,
        disabledBgColor: theme.text02,
        disabledFontColor: theme.text02,
        hoverFocusBgColor: theme.support01Hover,
        hoverFocusColor: theme.inverse01,
        focusOutlineColor: themeSupport01Faded,
        theme,
      }),
    },
    buttonConfirmation: {
      ...buttonBaseStyles({
        bgColor: theme.support02,
        fontColor: theme.inverse01,
        disabledBgColor: theme.text02,
        disabledFontColor: theme.inverse01,
        hoverFocusBgColor: theme.support02Hover,
        hoverFocusColor: theme.inverse01,
        focusOutlineColor: themeSupport01Faded,
        theme,
      }),
    },
    buttonConfirmationSecondary: {
      ...buttonBaseStyles({
        borderColor: theme.support02,
        fontColor: theme.support02,
        disabledBorderColor: theme.text02,
        disabledFontColor: theme.text02,
        hoverFocusBgColor: theme.support02,
        hoverFocusColor: theme.inverse01,
        focusOutlineColor: themeBrand00Faded,
        theme,
      }),
    },
    buttonToggle: {
      ...buttonBaseStyles({
        fontColor: theme.brand00,
        focusOutlineColor: themeBrand00Faded,
        theme,
      }),

      height: 32,
      minWidth: 32,
      padding: `0 ${theme.spacing.xxs}`,

      '&:hover, &:focus': {
        borderColor: theme.brand00,
      },
      '&$buttonToggleToggled': {
        backgroundColor: theme.brand00,
        borderColor: 'transparent',
        color: theme.inverse01,

        '& $buttonIcon': {
          fill: theme.inverse01,
        },
      },
    },
    buttonSlideToggle: {
      ...buttonBaseStyles({
        fontColor: theme.brand00,
        focusOutlineColor: 'none',
        theme,
      }),

      borderColor: 'transparent',
      height: 32,
      minWidth: 32,
      padding: `0 ${theme.spacing.xxs}`,

      '&:hover, &:focus': {
        // borderColor: theme.brand00,
        borderColor: 'transparent',
        boxShadow: 'none',
      },

      '&:hover': {
        '& svg path': {
          stroke: '#FFFFFF',
        },
      },

      '&$buttonSlideToggleToggled': {
        '&:hover': {
          '& svg path': {
            stroke: '#66A12E',
          },
        },
      },
    },
    buttonToggleToggled: {},
    buttonSlideToggleToggled: {},
    buttonLink: {
      height: 'auto',
      minWidth: 'auto',
      padding: 0,
      textAlign: 'left',
      whiteSpace: 'initial',

      '&:hover, &:focus': {
        outline: 'none',

        '&, & $buttonIconSibling': {
          textDecoration: 'underline',
        },
      },
    },
    buttonLinkPrimary: {
      ...buttonBaseStyles({
        fontColor: theme.brand00,
        theme,
      }),
      '& $buttonLinkIconShowOnHover': {
        fill: 'none',
      },
      '&:hover, &:focus': {
        '& $buttonLinkIconShowOnHover': {
          fill: theme.brand00,
        },
      },
    },
    buttonLinkDanger: {
      ...buttonBaseStyles({
        fontColor: theme.support01,
        theme,
      }),
      '& $buttonLinkIconShowOnHover': {
        fill: 'none',
      },
      '&:hover, &:focus': {
        '& $buttonLinkIconShowOnHover': {
          fill: theme.support01,
        },
      },
    },
    buttonLinkIconShowOnHover: {},
    buttonXs: {
      height: 24,
      minWidth: 'auto',
      padding: 0,
      width: 24,

      '& > $buttonIcon': {
        height: 8,
        width: 8,
      },
    },
    buttonLg: {
      height: 40,
    },
    buttonIcon: {
      width: 14,
      height: '100%',

      '$buttonLink > &, $buttonToggle > &': {
        width: 16,
      },
    },
    buttonIconBefore: {
      marginRight: theme.spacing.xs,

      '$buttonLink > &': {
        marginRight: theme.spacing.xxs,
      },
    },
    buttonIconAfter: {
      marginLeft: theme.spacing.xs,

      '$buttonLink > &': {
        marginLeft: theme.spacing.xxs,
      },
    },
    buttonIconInline: {
      marginLeft: theme.spacing.xs,

      '$buttonLink > &': {
        marginLeft: theme.spacing.xxs,
      },
    },
    buttonIconHasInlineIcon: {
      '& > *': {
        verticalAlign: 'middle',
      },
    },
    buttonIconSibling: {},
    buttonIconAction: {
      background: 'none',
      border: 0,
      height: theme.buttonIconActionSize, // overwrite buttonHeight
      minWidth: 'auto',
      padding: '0',
      width: theme.buttonIconActionSize,

      ...focusOutline(themeBrand00Faded),

      '&$buttonIconActionDelete, &$buttonIconActionClose': {
        ...focusOutline(themeSupport01Faded),
      },

      // Overwrite button<button> lineHeight defined above
      'button&': {
        lineHeight: 1,
      },

      // Set default fill for buttonIcon
      '& > svg': {
        fill: theme.brand00,
      },
    },
    buttonIconActionDelete: {
      '& > svg': {
        fill: theme.support01,
      },
    },
    buttonIconActionClose: {
      '& > svg': {
        fill: theme.support01,
      },
    },
    block: {
      width: '100%',
    },
  }
}

function buttonBaseStyles({
  bgColor = 'transparent',
  borderColor = 'transparent',
  fontColor,
  hoverFocusBgColor,
  hoverFocusColor,
  focusOutlineColor,
  disabledBgColor,
  disabledBorderColor,
  disabledFontColor,
  theme,
}) {
  return {
    backgroundColor: bgColor,
    borderColor,
    borderStyle: 'solid',
    borderWidth: theme.buttonBorderWidth,
    color: fontColor,

    '&:disabled': {
      backgroundColor: disabledBgColor || bgColor,
      borderColor: disabledBorderColor || borderColor,
      color: disabledFontColor || fontColor,
    },

    '&:hover:not([disabled]), &:focus:not([disabled])':
      hoverFocusBgColor || hoverFocusColor
        ? {
            backgroundColor: hoverFocusBgColor,
            color: hoverFocusColor,

            '& $buttonIcon': {
              fill: hoverFocusColor,
            },
          }
        : {},

    ...(focusOutlineColor ? focusOutline(focusOutlineColor) : {}),

    '& $buttonIcon': {
      fill: fontColor,
    },
  }
}
