import React from 'react'
import injectSheet from 'react-jss'

import { fetchRoles } from 'api'
import {
  AddItems,
  Button,
  FormFieldItem,
  FormItem,
  MultiInput,
  Notification,
  TextInput,
} from 'components'
import Styling from 'styling/components'

const styles = theme => ({
  formContainer: {
    width: 610,
  },
  nameInput: {
    width: 354,
  },
})

const BASE_ID = 'create-user'

const UserForm = ({
  cancelDestination,
  classes,
  values,
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  isSubmitting,
  setFieldValue,
}) => (
  <form className={classes.formContainer} onSubmit={handleSubmit}>
    <FormItem className={classes.nameInput}>
      <MultiInput
        errorId={`${BASE_ID}-name-error-msg`}
        invalidText={errors.firstName || errors.lastName}
        labelText="Name"
      >
        <TextInput
          autoFocus
          id={`${BASE_ID}-first-name`}
          isInvalid={!!errors.firstName}
          name="firstName"
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder="First Name"
          type="text"
          value={values.firstName}
        />
        <TextInput
          id={`${BASE_ID}-last-name`}
          isInvalid={!!errors.lastName}
          name="lastName"
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder="Last Name"
          type="text"
          value={values.lastName}
        />
      </MultiInput>
    </FormItem>

    <FormFieldItem
      id={`${BASE_ID}-email`}
      labelText="Email"
      name="email"
      render={renderProps => (
        <TextInput {...renderProps} className={classes.nameInput} placeholder="Email" type="text" />
      )}
    />

    <AddItems
      errorMsg={errors.roles}
      fetchItems={config => fetchRoles('', config)}
      id={`${BASE_ID}-roles`}
      itemName="role"
      labelText="Roles"
      onChange={newSelectedStores => {
        setFieldValue('roles', newSelectedStores)
      }}
      selectedItems={values.roles}
    />

    {!isSubmitting && errors.global && <Notification kind="error" message={errors.global} />}
    <Styling.LineOfButtons>
      <Button disabled={isSubmitting} id={`${BASE_ID}-save`} type="submit">
        Save
      </Button>
      <Button kind="link" href={cancelDestination}>
        Cancel
      </Button>
    </Styling.LineOfButtons>
  </form>
)

export default injectSheet(styles)(UserForm)
