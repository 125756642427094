import React, { memo } from 'react'
import { StepInput } from '@instacart/cocktail'
import { cart } from '@instacart/enterprise-services'
import { Price } from '@instacart/enterprise-ui-product-price'
import { ConfigurableProducts, utils } from '@instacart/enterprise-product-detail'
import { createUseStyles } from 'react-jss'

import Styling from 'styling/components'
import { TextInput, TextArea } from 'components'

import styles from '../CreateOrder.styles'

const useStyles = createUseStyles(styles)

const CreateOrderProductsModal = ({
  product,
  cartItem,
  onFormChange,
  onNoteChange,
  onQuantityChange,
  isPrepStyle,
  itemTotal = 0,
}) => {
  const initialQuantity = cartItem ? cartItem.quantity : product.ordering_parameters.min
  const initialItemNote = cartItem && cartItem.comment

  const styles = useStyles()
  const isConfigurableProduct = !!product.product_config
  const price = itemTotal || product.sale_price || product.base_price || 0

  let initialFormData = {}

  // To repopulate the selected options of the configurable products item
  if (
    isConfigurableProduct &&
    !isPrepStyle &&
    cartItem &&
    cartItem.product_config &&
    cartItem.product_config.options
  ) {
    initialFormData = utils.convertCartDataToForm(cartItem.product_config.options, product)
  }

  return (
    <div className={styles.modalContainer}>
      <h2 className={styles.modalHeading}>{product.name}</h2>
      <div className={styles.configurableProductSections}>
        <Price amount={price} />
      </div>
      {!isPrepStyle && (
        <div className={styles.configurableProductSections}>
          <Styling.H5 className={styles.modalFormTitles}>Quantity</Styling.H5>
          <StepInput
            defaultValue={initialQuantity}
            onChange={onQuantityChange}
            min={product.ordering_parameters.min}
            max={product.ordering_parameters.max}
          />
        </div>
      )}
      <div className={styles.configurableProductSections}>
        {isConfigurableProduct && (
          <ConfigurableProducts
            product={product}
            variant="button"
            onFormChange={!isPrepStyle ? onFormChange : undefined}
            initialFormData={initialFormData}
            addToCart={false}
          />
        )}
      </div>
      {/* TODO: Figure out a way to have notes for each item */}
      {!isPrepStyle && (
        <div className={styles.configurableProductSections}>
          <Styling.H5 className={styles.modalFormTitles}>Notes</Styling.H5>
          <TextArea
            placeholder="Start typing..."
            defaultValue={initialItemNote}
            onChange={e => onNoteChange(e.target.value)}
          />
        </div>
      )}
    </div>
  )
}

export default memo(CreateOrderProductsModal)
