import React from 'react'
import injectSheet from 'react-jss'

import { FormFieldItem, TextInput } from 'components'

import styles from '../PromotedPlacementsForm.styles'

const PlacementComponentTitle = ({
  classes,
  componentId,
  labelText = 'Title on Storefront',
  tooltip = 'This title will be visible on the Storefront.',
  required = false,
}) => (
  <FormFieldItem
    className={classes.formRow}
    id={`${componentId}-component-title`}
    labelText={`${labelText} ${required ? '' : '(Optional)'}`}
    name="config.title"
    render={renderProps => (
      <div className={classes.helpFormGroup}>
        <TextInput
          {...renderProps}
          className={classes.formInput}
          placeholder={'Enter title'}
          type="text"
        />
        <div className={classes.formTooltip}>{tooltip}</div>
      </div>
    )}
  />
)

export default injectSheet(styles)(PlacementComponentTitle)
