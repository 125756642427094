import memoize from 'memoize-one'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { DataTableWithStateAsync } from 'components'
import { renderWithFilter } from 'components/DataTable/utils/rendering'
import { ModalHeader } from 'styling/components'

import BrowseItemsModal from './BrowseItemsModal'

class BrowseItemsAsyncModalContent extends Component {
  static propTypes = {
    fetchItems: PropTypes.func.isRequired,
    /**
     * Will default to `Filter ${itemName}s` if not provided
     */
    filterInputPlaceholder: PropTypes.string,
    /**
     * Passed in if the modal table requires more than just one column
     */
    formatTableRows: PropTypes.func,
    /**
     * Will default to `Add ${itemName}s` if not provided
     */
    headerText: PropTypes.string,
    itemName: PropTypes.string.isRequired,
    initialSelectedItems: PropTypes.array.isRequired,
    /**
     * Will default to 1 column header with `key` equal to `name`, and `header` equal to "${itemName} Name"
     */
    tableHeaders: PropTypes.array,
    updateSelectedItems: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    const {
      filterInputPlaceholder,
      formatTableRows,
      headerText,
      itemName,
      tableHeaders,
    } = this.props

    this.itemsTableHeaders = tableHeaders || [
      {
        key: 'name',
        header: `${itemName} Name`,
      },
    ]

    // Set default values for `headerText`, `filterInputPlaceholder` and `formatTableRows`
    // which depend on the value of `itemName`
    this.formatTableRows = formatTableRows
      ? memoize(formatTableRows)
      : memoize(items =>
          items.map(({ id, name }) => ({
            id: `${id}`,
            resourceName: itemName,
            name,
          }))
        )
    this.headerText = headerText || `Add ${itemName}s`
    this.filterInputPlaceholder = filterInputPlaceholder || `Filter ${itemName}s`
  }

  render() {
    const {
      fetchItems,
      itemName,
      initialQueryStateValues,
      initialSelectedItems,
      updateSelectedItems,
    } = this.props

    return (
      <div>
        <ModalHeader positionAbsolutely>{this.headerText}</ModalHeader>
        <DataTableWithStateAsync
          fetchItems={fetchItems}
          formatTableRows={this.formatTableRows}
          headers={this.itemsTableHeaders}
          id={`${itemName}s-table`}
          initialQueryStateValues={initialQueryStateValues}
          initialSelectedItems={initialSelectedItems}
          onSelectedRowsChange={updateSelectedItems}
          render={renderWithFilter}
          renderProps={{
            filterInputId: `${itemName}s-filter`,
            filterInputPlaceholder: this.filterInputPlaceholder,
          }}
        />
      </div>
    )
  }
}

const BrowseItemsAsyncModal = props => (
  <BrowseItemsModal {...props} ModalContent={BrowseItemsAsyncModalContent} />
)

export default BrowseItemsAsyncModal
