import injectSheet from 'react-jss'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { ButtonIconAction, Dropdown, DropdownFilterable, LoadingIndicator } from 'components'

import styles from './ParentalSelector.styles'

class ParentalSelector extends Component {
  static propTypes = {
    /**
     * Tooltip text of text-less button
     */
    buttonDescription: PropTypes.string.isRequired,
    /**
     * Should button be disabled
     */
    buttonDisabled: PropTypes.bool,
    /**
     * Change the icon of the text-less button
     */
    buttonIcon: PropTypes.string,
    /**
     * Function called when button is clicked
     */
    buttonOnClick: PropTypes.func,
    /**
     * Prevents component from modifiable
     */
    disabled: PropTypes.bool,
    /**
     * Enable filtering (searching) on second dropdown (child)
     */
    enableFiltering: PropTypes.bool,
    /**
     * filtered child items for second dropdown that be passed down when filtering is enabled to show search results
     */
    filteredChildItems: PropTypes.array,
    /**
     * If filtering enabled, is filtering async (API); so show loader
     */
    isFilteringAsync: PropTypes.bool,
    /**
     * Child dropdown is invalid, button is implicitly disabled if true
     */
    isInvalid: PropTypes.bool,
    /**
     * Items (child) for second dropdown, will be passed directly to <Dropdown />
     */
    items: PropTypes.array,
    /**
     * Helper function for custom string rendering of items array, will be used by second dropdown, will be passed directly to <Dropdown />
     */
    itemToString: PropTypes.func,
    /**
     * Something is loading
     */
    loading: PropTypes.bool,
    /**
     * Function called with signature (value) => {...} for second dropdown (child items) on selection
     */
    onChange: PropTypes.func,
    /**
     * Function called with signature (value) => {...} for second dropdown (child items) on typing
     */
    onFilterInputChange: PropTypes.func,
    /**
     * Items (parent) for second dropdown, will be passed directly to <Dropdown />
     */
    parentItems: PropTypes.array.isRequired,
    /**
     * Helper function for custom string rendering of parent items array, will be used by first dropdown, will be passed directly to <Dropdown />
     */
    parentItemToString: PropTypes.func,
    /**
     * Function called with signature (value) => {...} for first dropdown (parent items)
     */
    parentOnChange: PropTypes.func,
    /**
     * Placeholder text for first (parent) dropdown
     */
    parentPlaceholder: PropTypes.string.isRequired,
    /**
     * Selected value of first dropdown (parent), will be passed directly to <Dropdown />
     */
    parentSelectedValue: PropTypes.any,
    /**
     * Placeholder text for second (child) dropdown
     */
    placeholder: PropTypes.string.isRequired,
    /**
     * Selected value of second dropdown (child), will be passed directly to <Dropdown />
     */
    selectedValue: PropTypes.any,
    /**
     * Should a button be shown
     */
    showButton: PropTypes.bool,
  }

  static defaultProps = {
    buttonDisabled: false,
    buttonIcon: 'plus',
    buttonOnClick: () => {},
    disabled: false,
    enableFiltering: false,
    isInvalid: false,
    isFilteringAsync: false,
    items: [],
    itemToString: item => (item && item.label) || '',
    loading: false,
    onChange: () => {},
    parentItemToString: item => (item && item.label) || '',
    parentOnChange: () => {},
    showButton: false,
  }

  render() {
    const {
      buttonDescription,
      buttonDisabled,
      buttonIcon,
      buttonOnClick,
      classes,
      disabled,
      enableFiltering,
      filteredChildItems,
      id,
      isFilteringAsync,
      isInvalid,
      items,
      loading,
      onChange,
      onFilterInputChange,
      parentItems,
      parentOnChange,
      parentPlaceholder,
      parentSelectedValue,
      placeholder,
      selectedValue,
      showButton,
    } = this.props

    const ChildDropdownComponent = enableFiltering ? DropdownFilterable : Dropdown

    return (
      <div>
        <div className={classes.parentalSelectorContainer}>
          <div className={classes.parentalSelectorDropdown}>
            <Dropdown
              id={`${id}-parent`}
              disabled={disabled}
              items={parentItems}
              placeholder={parentPlaceholder}
              selectedValue={parentSelectedValue}
              onChange={parentOnChange}
            />
          </div>
          <div className={classes.parentalSelectorDropdown}>
            <ChildDropdownComponent
              id={`${id}-child`}
              items={items}
              placeholder={placeholder}
              selectedValue={selectedValue}
              onChange={onChange}
              isInvalid={isInvalid}
              disabled={
                parentSelectedValue === undefined ||
                parentSelectedValue === null ||
                disabled ||
                loading
              }
              filteredItems={enableFiltering && filteredChildItems ? filteredChildItems : null}
              onInputChange={enableFiltering ? onFilterInputChange : undefined}
              isLoadingAsync={enableFiltering ? isFilteringAsync : undefined}
            />
          </div>
          {!!showButton && (
            <div className={`${classes.parentalSelectorButton}`}>
              <ButtonIconAction
                description={buttonDescription}
                icon={buttonIcon}
                onClick={e => buttonOnClick(parentSelectedValue, selectedValue, e)}
                disabled={buttonDisabled || disabled || isInvalid}
              />
            </div>
          )}
          {!!loading && (
            <div className={`${classes.parentalSelectorLoader}`}>
              <LoadingIndicator.Dots />
            </div>
          )}
        </div>
      </div>
    )
  }
}
export { ParentalSelector as ParentalSelectorUnStyled }
export default injectSheet(styles)(ParentalSelector)
