import { Formik } from 'formik'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as yup from 'yup'

import isEmpty from 'lodash/isEmpty'

import { createSynonym, updateSynonym, fetchSynonym } from 'api'
import { LoadingIndicator, PageHeader } from 'components'
import { createToast } from 'modules/toasts'
import { getBackButton, getRouteTo } from 'routing'
import { get, ItemFetcher } from 'utils'

import SynonymForm from './SynonymForm'

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createToast,
    },
    dispatch
  )

const CreateOrEditSynonym = connect(
  null,
  mapDispatchToProps
)(
  ({
    backButton,
    createToast,
    headerTitle,
    initialValues = { synonyms: [], word: [], oneway: true },
    history,
    onFormSubmit,
    successMessage,
  }) => (
    <div>
      <PageHeader backButton={backButton} hasDivider headerTitle={headerTitle} />
      <Formik
        initialValues={initialValues}
        validationSchema={() =>
          yup.object().shape({
            synonyms: yup
              .array()
              .min(1)
              .label('Synonyms')
              .required(),
            word: yup
              .array()
              .min(1)
              .label('Search Term')
              .required(),
            oneway: yup
              .boolean()
              .label('Synonym Direction')
              .required(),
          })
        }
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values, form) => {
          // Normalize synonyms and words to not have and leading/trailing spaces
          values.synonyms = values.synonyms.map(str => str.trim())
          values.word = values.word.map(str => str.trim())

          onFormSubmit(values)
            .then(synonym => {
              form.setSubmitting(false)
              createToast({ kind: 'success', message: successMessage })
              history.push(getRouteTo('searchSettings.synonyms'))
            })
            .catch(error => {
              form.setSubmitting(false)
              form.setErrors({ global: error.message })
            })
        }}
        render={renderProps => <SynonymForm {...renderProps} cancelDestination={backButton.to} />}
      />
    </div>
  )
)

export const CreateSynonym = ({ location, ...restProps }) => {
  const propsToPass = {
    backButton: get(location, 'state.backButton'),
    headerTitle: 'Create Synonym',
    onFormSubmit: formValues => createSynonym(formValues),
    successMessage: 'Successfully created synonym.',
    ...restProps,
  }
  propsToPass.backButton =
    propsToPass.backButton && !isEmpty(propsToPass.backButton)
      ? propsToPass.backButton
      : getBackButton('searchSettings.synonyms')
  return <CreateOrEditSynonym {...propsToPass} />
}

export const EditSynonym = ({
  location,
  match: {
    params: { id },
  },
  ...restProps
}) => (
  <ItemFetcher
    queryKey="synonym"
    queryParams={{ id }}
    fetchItem={config => fetchSynonym(id, config)}
    render={({ item: synonym, isLoadingItem: isFetchingSynonym }) => {
      if (isFetchingSynonym) {
        return <LoadingIndicator withTopMargin />
      }
      if (!synonym) return null
      const propsToPass = {
        backButton:
          get(location, 'state.backButton') ||
          getBackButton('searchSettings.synonyms.synonym', {
            label: synonym.word,
          }),
        headerTitle: 'Edit Synonym',
        initialValues: synonym,
        onFormSubmit: formValues => updateSynonym(id, formValues),
        successMessage: 'Successfully updated synonym.',
        ...restProps,
      }

      return <CreateOrEditSynonym {...propsToPass} />
    }}
  />
)
