import React from 'react'
import injectSheet from 'react-jss'

import { Button, Dropdown } from 'components'

const styles = theme => ({
  tablePaging: {
    fontSize: theme.fontSize.p,
    alignItems: 'center',
    display: 'flex',
    float: 'right',
    padding: `${theme.spacing.md} ${theme.spacing.md} ${theme.spacing.lg}`,
  },
  numResults: {
    alignItems: 'center',
    color: theme.text01,
    display: 'flex',
    marginRight: theme.spacing.lg,
  },
  numResultsDropdown: {
    marginRight: theme.spacing.xs,
    width: '60px !important',
  },
  pageButtonPrev: { marginRight: theme.spacing.xxs },
  pageButtonNext: { marginLeft: theme.spacing.xxs },
  pageLink: {
    color: theme.text01,
    height: '24px !important',
    lineHeight: '1 !important',
    margin: `0 ${theme.spacing.xxs} !important`,
  },
  currentPageLink: {
    color: theme.brand00,
    fontWeight: 600,
    margin: `0 ${theme.spacing.xxs}`,
  },
  ellipsis: {
    margin: `0 ${theme.spacing.xxs}`,
  },
})

const TablePaging = ({ classes, currentPage, onChange, resultsPerPage, resultsTotal, tableId }) => {
  const pageLinks = []

  const numPages = Math.ceil(resultsTotal / resultsPerPage)

  for (let i = 1; i <= numPages; i++) {
    const pageDifference = Math.abs(i - currentPage)
    if (i === currentPage) {
      // Current page
      pageLinks.push(
        <span className={classes.currentPageLink} key={i}>
          {i}
        </span>
      )
    } else if (i === 1 || i === numPages || pageDifference <= 2) {
      pageLinks.push(
        <Button
          className={classes.pageLink}
          key={i}
          kind="link"
          onClick={() => {
            onChange({ pageNumber: i, resultsPerPage })
          }}
        >
          {i}
        </Button>
      )
    } else if (pageDifference === 3) {
      pageLinks.push(
        <span className={classes.ellipsis} key={i}>
          …
        </span>
      )
    }
  }

  const numResultsDropdownOptions = [
    { id: 'results-per-page-20', label: '20', value: 20 },
    { id: 'results-per-page-50', label: '50', value: 50 },
    { id: 'results-per-page-100', label: '100', value: 100 },
    { id: 'results-per-page-250', label: '250', value: 250 },
  ]

  return (
    <div className={classes.tablePaging}>
      <div className={classes.numResults}>
        <Dropdown
          className={classes.numResultsDropdown}
          id={`${tableId}-page-dropdown`}
          items={numResultsDropdownOptions}
          onChange={selectedOption => {
            const newResultsPerPage = selectedOption
            onChange({ pageNumber: 1, resultsPerPage: newResultsPerPage })
          }}
          placeholder="# Results"
          selectedValue={resultsPerPage}
          size="small"
        />{' '}
        Per Page
      </div>
      <Button
        className={classes.pageButtonPrev}
        icon="chevronLeft"
        disabled={currentPage === 1}
        onClick={() => {
          onChange({ pageNumber: currentPage - 1, resultsPerPage })
        }}
        size="x-small"
      />
      {pageLinks}
      <Button
        className={classes.pageButtonNext}
        disabled={currentPage === numPages}
        icon="chevronRight"
        onClick={() => {
          onChange({ pageNumber: currentPage + 1, resultsPerPage })
        }}
        size="x-small"
      />
    </div>
  )
}
export default injectSheet(styles)(TablePaging)
