import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import injectSheet from 'react-jss'

import { mixins } from 'styling'

const styles = theme => ({
  listBoxLabel: {
    ...mixins.textOverflowEllipsis,
    fontSize: theme.fontSize.body,
    color: theme.text02,
    flexGrow: 1,
    fontWeight: theme.inputPlaceholderWeight,
    lineHeight: 1.5,
    userSelect: 'none',
  },
  listBoxLabelDisabled: {},
  // When the list box has a selected value (as opposed to the label placeholder)
  listBoxLabelSelected: {
    color: theme.text01,
    fontWeight: theme.inputValueWeight,

    '&$listBoxLabelDisabled': {
      color: theme.text02,
    },
  },
})

const ListBoxLabel = ({
  children,
  classes,
  isDisabled,
  isSelectedItem,
  text,
  theme,
  ...restProps
}) => (
  <span
    className={cx(classes.listBoxLabel, {
      [classes.listBoxLabelDisabled]: isDisabled,
      [classes.listBoxLabelSelected]: isSelectedItem,
    })}
    {...restProps}
  >
    {text}
  </span>
)

ListBoxLabel.propTypes = {
  isSelectedItem: PropTypes.bool,
  text: PropTypes.string.isRequired,
}

export default injectSheet(styles)(ListBoxLabel)
