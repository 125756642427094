import React, { Fragment } from 'react'
import injectSheet from 'react-jss'
import { Formik } from 'formik'
import * as yup from 'yup'

import { fetchCustomers } from 'api'
import Styling, { ModalHeader } from 'styling/components'
import { Notification, FormFieldItem, TextArea, Dropdown, Checkbox, Button } from 'components'

import requestTypes from './requestTypes'
import CustomerLookup from './CustomerLookup'
import styles from './CreateRequestForm.styles'

const BASE_ID = 'create-request-form'

const validationSchema = yup.object().shape({
  customer: yup.object().required(),
  type: yup.mixed().oneOf(
    Object.values(requestTypes).map(type => type.value),
    'You must select a request type'
  ),
  notes: yup.string(),
  acknowledgement: yup.boolean().oneOf([true], 'You must acknowledge this statement'),
})

const fetchCustomerByEmail = async email => {
  const response = await fetchCustomers(`?search=${email}&limit=1`)
  if (!response.itemCount) {
    throw new Error('There is no account associated with this email.')
  }
  const { id: userId, firstName, lastName, email: userEmail } = response.items[0]
  return { userId, userEmail, firstName, lastName }
}

const CreateRequestForm = ({ classes, onSubmit, onCancel }) => (
  <Formik
    initialValues={{
      email: '',
      customer: null,
      type: null,
      notes: '',
      acknowledgement: false,
    }}
    initialStatus={{ showConfirmation: false }}
    onSubmit={onSubmit}
    validateOnBlur={false}
    validateOnChange={false}
    validationSchema={validationSchema}
    render={({
      values,
      status,
      setStatus,
      errors,
      handleSubmit,
      isSubmitting,
      setFieldValue,
      setFieldError,
      validateForm,
    }) => (
      <form onSubmit={handleSubmit}>
        {!status.showConfirmation ? (
          <Fragment>
            <ModalHeader>Create Privacy Request</ModalHeader>

            {errors.global && !isSubmitting && (
              <Notification kind="error" className={classes.error} message={errors.global} />
            )}

            <CustomerLookup
              id={`${BASE_ID}-email`}
              name="email"
              customer={values.customer}
              setCustomer={customer => {
                setFieldValue('customer', customer)
              }}
              fetchCustomer={fetchCustomerByEmail}
              setEmailFieldError={error => {
                setFieldError('email', error)
              }}
            />

            {values.customer ? (
              <div className={classes.requestDetails}>
                <FormFieldItem
                  id={`${BASE_ID}-type`}
                  labelText="Request Type"
                  name="type"
                  render={({ value, ...renderProps }) => (
                    <Fragment>
                      <Dropdown
                        {...renderProps}
                        items={Object.values(requestTypes)}
                        className={classes.requestType}
                        placeholder="Select Request"
                        selectedValue={value}
                        onChange={newValue => {
                          setFieldValue('type', newValue)
                        }}
                      />
                    </Fragment>
                  )}
                />

                <FormFieldItem
                  id={`${BASE_ID}-notes`}
                  labelText="Additional Notes"
                  name="notes"
                  render={renderProps => (
                    <TextArea {...renderProps} placeholder="Optional notes about request" />
                  )}
                />

                <FormFieldItem
                  id={`${BASE_ID}-acknowledgement`}
                  name="acknowledgement"
                  render={({ value, ...renderProps }) => (
                    <Checkbox
                      {...renderProps}
                      checked={value}
                      labelText="I acknowledge that this is a verified request from the customer."
                    />
                  )}
                />

                <Styling.LineOfButtons>
                  {values.type === requestTypes.delete.value ? (
                    <Button
                      onClick={async () => {
                        const errors = await validateForm()
                        if (!Object.keys(errors).length) {
                          setStatus({ ...status, showConfirmation: true })
                        }
                      }}
                    >
                      Submit Request
                    </Button>
                  ) : (
                    <Button type="submit" disabled={isSubmitting}>
                      Submit Request
                    </Button>
                  )}
                  <Button kind="link" onClick={onCancel}>
                    Cancel
                  </Button>
                </Styling.LineOfButtons>
              </div>
            ) : (
              <Styling.LineOfButtons>
                <Button onClick={onCancel}>Cancel</Button>
              </Styling.LineOfButtons>
            )}
          </Fragment>
        ) : (
          <Fragment>
            <Button
              className={classes.backButton}
              icon="arrowLeft"
              iconPosition="before"
              kind="link"
              onClick={() => {
                setStatus({ ...status, showConfirmation: false })
              }}
            >
              Back to Privacy Request
            </Button>
            <ModalHeader>Delete Data and Account?</ModalHeader>

            <p className={classes.paragraph}>
              Are you sure you want to permanently delete the account and all associated data?
            </p>
            <p className={classes.paragraph}>
              In doing so, the customer will{' '}
              <span className={classes.bold}>NOT be able to retrieve this account's data</span> or
              any identifiable information associated with this account once the request is
              submitted.
            </p>
            <p className={classes.paragraph}>
              If you would like to continue, please click "Delete Data and Account".
            </p>

            <Styling.LineOfButtons>
              <Button kind="danger" type="submit" disabled={isSubmitting}>
                Delete Data and Account
              </Button>
              <Button kind="link" onClick={onCancel}>
                Cancel
              </Button>
            </Styling.LineOfButtons>
          </Fragment>
        )}
      </form>
    )}
  />
)

export default injectSheet(styles)(CreateRequestForm)
