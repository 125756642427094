import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import injectSheet from 'react-jss'

import { Autocomplete, Button, FormItem, Modal, SelectedItems } from 'components'
import { capitalize } from 'utils'

import { sortStates } from '../DataTable/state/sorting'

const styles = theme => ({
  addAutocompleteAndModalTrigger: {
    display: 'flex',
  },
  addAutocomplete: {
    flexGrow: 1,
  },
  addAutocompleteRestrictedWidth: {
    maxWidth: 400,
  },
  addModalTrigger: {
    marginLeft: theme.spacing.md,
  },
  selectedItems: {
    marginTop: theme.spacing.xs,
  },
  selectedItemsBoxed: {
    backgroundColor: theme.bgGreyExtraLight,
    padding: `${theme.spacing.xxs} ${theme.spacing.md} ${theme.spacing.md}`,
  },
})

/**
 * Combines Autocomplete, SelectedItems, and Modal.BrowseItems to allow users to
 * browse, add, and remove items from a large list.
 */
const AddItemsAsync = ({
  autocompleteLabelKey,
  autocompleteRenderMenuItem,
  classes,
  draggable,
  errorMsg,
  fetchItems,
  fetchItemsAutocomplete,
  id,
  itemName,
  labelText,
  modalProps,
  onChange,
  onDragEnd,
  readOnly,
  removeSelectedItemsFromBox,
  restrictAutocompleteWidth,
  selectedItems,
  selectedItemsRenderItem,
}) => {
  const capitalizedPluralItemName = `${capitalize({ phrase: itemName })}s`

  return (
    <FormItem fieldId={id} labelText={labelText} errorMsg={errorMsg}>
      <div className={classes.container}>
        {!readOnly && (
          <div className={classes.addAutocompleteAndModalTrigger}>
            <Autocomplete
              className={cx(classes.addAutocomplete, {
                [classes.addAutocompleteRestrictedWidth]: restrictAutocompleteWidth,
              })}
              id={id}
              labelKey={autocompleteLabelKey}
              loadItems={fetchItemsAutocomplete}
              onItemSelect={selectedItem => {
                const previouslySelectedItems = selectedItems
                onChange([...previouslySelectedItems, selectedItem])
              }}
              placeholder={`Add ${capitalizedPluralItemName}`}
              renderMenuItem={autocompleteRenderMenuItem}
              selectedItems={selectedItems}
            />
            <Modal.BrowseItemsAsync
              handleCloseModal={({ newSelectedItems }) => {
                if (newSelectedItems) {
                  onChange(newSelectedItems)
                }
              }}
              triggerRender={({ openModal }) => (
                <Button
                  className={classes.addModalTrigger}
                  id={`${id}-browse`}
                  kind="link"
                  onClick={() => {
                    openModal({
                      selectedItems,
                    })
                  }}
                >
                  See All {capitalizedPluralItemName}
                </Button>
              )}
              contentProps={{
                fetchItems,
                itemName,
                initialQueryStateValues: {
                  sortDirection: sortStates.ASC,
                  sortHeaderKey: 'firstName',
                },
                initialSelectedItems: selectedItems,
                ...modalProps,
              }}
            />
          </div>
        )}
        <SelectedItems
          className={cx(classes.selectedItems, {
            [classes.selectedItemsBoxed]: !removeSelectedItemsFromBox,
          })}
          draggable={draggable}
          headerText={`Selected ${capitalizedPluralItemName}`}
          itemName={itemName}
          items={selectedItems}
          onChange={onChange}
          onDragEnd={onDragEnd}
          readOnly={readOnly}
          renderItem={selectedItemsRenderItem}
        />
      </div>
    </FormItem>
  )
}

AddItemsAsync.propTypes = {
  autocompleteLabelKey: PropTypes.string,
  autocompleteRenderMenuItem: PropTypes.func,
  /**
   * Passes a boolean to <SelectedItems> component to make draggable
   */
  draggable: PropTypes.bool,
  /**
   * Displayed under the Autocomplete when passed in
   */
  errorMsg: PropTypes.string,
  fetchItems: PropTypes.func.isRequired,
  /**
   * Takes `inputvalue` as a parameter and must return a list of items to fill the
   * Autocomplete's results.
   */
  fetchItemsAutocomplete: PropTypes.func.isRequired,
  /**
   * Applied to the Autocomplete (and to the "See All" browse link as `${id}-browse`)
   */
  id: PropTypes.string.isRequired,
  itemName: PropTypes.string.isRequired,
  /**
   * Renders a form label above the Autocomplete when passed in
   */
  labelText: PropTypes.string,
  /**
   * Passed into the `Modal.BrowseItem`'s `contentProps`
   */
  modalProps: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  /**
   * Optional drag end handler to use with draggable boolean.
   */
  onDragEnd: PropTypes.func,
  /**
   * If set to `true`, will only show the selected items with no option for the user to
   * add or remove any.
   */
  readOnly: PropTypes.bool,
  /**
   * Alters the styling of the SelectedItems component and wraps it in a box
   */
  removeSelectedItemsFromBox: PropTypes.bool,
  restrictAutocompleteWidth: PropTypes.bool,
  selectedItems: PropTypes.array.isRequired,
  selectedItemsRenderItem: PropTypes.func,
}

AddItemsAsync.defaultProps = {
  readOnly: false,
  removeSelectedItemsFromBox: false,
  restrictAutocompleteWidth: false,
  selectedItems: [],
}

export default injectSheet(styles)(AddItemsAsync)
