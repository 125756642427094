import React, { useEffect } from 'react'
import injectSheet from 'react-jss'
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom'

import DashboardV1 from 'DashboardV1'
import { PageHeader } from 'components'
import { DefaultPage } from 'layouts'
import { getPathPrefix, getRoutePathname, getRoutePathnameByHash, getRouteTo } from 'routing'
import navbarLinks from 'pages/Dashboard/navbarLinks'

import accountRouteDefinitions from './Account'
import customersAndOrdersRouteDefinitions from './CustomersAndOrders'
import platformSettingsRouteDefinitions from './PlatformSettings'
import marketingRouteDefinitions from './Marketing'
import productsAndCollectionsRouteDefinitions from './ProductsAndCollections'
import factRouteDefinitions from './Facts'

const styles = theme => ({
  main: {
    display: 'flex',
    flexGrow: 1,
    flexShrink: 0,
  },
})

// Render default page layout by default, or use custom layout defined in route definition
let MainRoute = ({
  classes,
  component,
  layout: Layout = DefaultPage,
  layoutProps,
  location,
  render,
  ...restRouteProps
}) => (
  <Route
    {...restRouteProps}
    render={props => {
      const routeProps = {
        component,
        layoutProps,
        location,
        props,
        render,
      }

      return (
        <div className={classes.main}>
          <Layout {...routeProps} />
        </div>
      )
    }}
  />
)

MainRoute = injectSheet(styles)(MainRoute)

const routeDefinitions = [
  ...accountRouteDefinitions,
  ...customersAndOrdersRouteDefinitions,
  ...marketingRouteDefinitions,
  ...productsAndCollectionsRouteDefinitions,
  ...platformSettingsRouteDefinitions,
  ...factRouteDefinitions,
]

const getSublinks = ({ links }) => links.map(link => [link, ...(link.links || [])]).flat()

let prevPathname

const Main = () => {
  const history = useHistory()
  const location = useLocation()

  const onChange = ({ hash, pathname }) => {
    const { left, right } = navbarLinks()
    const links = [...left, ...right]
    const v1Pathname = getRoutePathname('v1', {})
    // The user navigates away from the /v1 page. Restore sidebar and navigation links.
    if (prevPathname === v1Pathname) {
      for (const link of links) {
        if (link.prevRoutePath) {
          link.routePath = link.prevRoutePath
        }
        const sublinks = getSublinks(link)
        for (const sublink of sublinks) {
          if (sublink.prevTo) {
            sublink.to = { ...sublink.prevTo }
          }
        }
      }
    }
    // The user navigates to the /v1 page. Patch navigation links to show the
    // corresponding sidebar links and highlight an active one.
    if (pathname === v1Pathname) {
      const routePath = getRoutePathnameByHash(hash)
      // Make the corresponding sidebar items visible. I.e. if /v1#/offers is active,
      // it corresponds to /marketing/offers, then make /marketing/* sidebar items visible.
      const routePathPrefix = getPathPrefix(routePath, 1)
      const filteredLinks = links.filter(({ routePath }) => routePathPrefix === routePath)
      for (const link of filteredLinks) {
        link.prevRoutePath = link.routePath
        link.routePath = v1Pathname

        // Modify the corresponding netsted sidebar link to show it as active.
        const sublinks = getSublinks(link)
        const sublink = sublinks.find(({ to }) => to && to.pathname === routePath)
        if (sublink && sublink.to) {
          sublink.prevTo = { ...sublink.to }
          sublink.to.pathname = v1Pathname
          sublink.to.hash = hash
        }
      }
    }
    prevPathname = pathname
  }
  onChange(location)

  useEffect(() => {
    // Catch the location change before it it was actually changed.
    const unblock = history.block(location => {
      onChange(location)
      return true
    })
    return () => {
      unblock()
    }
  }, [history])

  return (
    <Switch>
      <MainRoute exact key="v1" path={getRoutePathname('v1', {})} render={() => <DashboardV1 />} />
      {routeDefinitions.map(routeDefinition => (
        <MainRoute exact key={routeDefinition.path} {...routeDefinition} />
      ))}
      <Route exact path="/" render={() => <Redirect to={getRouteTo('orders')} />} />
      <MainRoute
        render={() => (
          // TODO: Add a 404 page here instead of "Coming Soon" since we link unimplemented features to Dashboard v1
          <PageHeader headerTitle="Coming Soon" />
        )}
      />
    </Switch>
  )
}

export default Main
