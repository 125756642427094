import React from 'react'
import injectSheet from 'react-jss'

import { Navbar, SidebarView } from 'layouts'
import navbarLinks from 'pages/Dashboard/navbarLinks'

const contentMargin = 52

const styles = theme => ({
  dashboard: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'absolute',
    width: '100%',
  },
  main: {
    display: 'flex',
    flexGrow: 1,
    flexShrink: 0,
  },
  navbar: {
    flexShrink: 0,
  },
  rightOfSidebar: {
    flexGrow: 1,
    minWidth: 0,
    margin: `0 ${contentMargin}px ${contentMargin}px`,
    '& > [ng-app]': {
      margin: `0 -${contentMargin}px`,
    },
  },
  '@global': {
    [`[ng-app]`]: {
      '& .sidebar': {
        display: 'none',
      },
      '& .three-quarters': {
        width: '100%',
      },
    },
  },
})

// Renders content with or without Sidebar (depending on `hideSidebar` prop in route definition)
const DefaultPage = ({ classes, component: Component, location, props, render }) => (
  <div className={classes.dashboard} data-hash={location.hash.replace(/^#/, '')}>
    <Navbar className={classes.navbar} links={navbarLinks()} location={location} />
    <div className={classes.main}>
      <SidebarView />
      <div className={classes.rightOfSidebar}>
        {render && render(props)}
        {Component && <Component {...props} />}
      </div>
    </div>
  </div>
)
export default injectSheet(styles)(DefaultPage)
