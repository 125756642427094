import { Formik } from 'formik'
import React, { Component } from 'react'
import injectSheet from 'react-jss'

import { createCustomerCredit, fetchCreditTypes } from 'api'
import {
  Button,
  Checkbox,
  Dropdown,
  FormFieldItem,
  Notification,
  TextArea,
  TextInput,
} from 'components'
import Styling, { ModalHeader } from 'styling/components'
import { ItemsFetcher } from 'utils'

const styles = theme => ({
  form: {
    width: 550,
  },
})

/**
 * "Credit Department" dropdown options depend on the selectedCreditType
 */
const generateCreditDepartmentOptions = (selectedCreditType, creditTypes) =>
  selectedCreditType
    ? creditTypes
        .find(creditType => creditType.name === selectedCreditType)
        .departments.map((department, index) => ({
          id: `credit-department-${index}`,
          label: department,
          value: department,
        }))
    : []

/**
 * "Credit Multiplier" dropdown options depend on the selectedCreditType
 */
const generateCreditMultiplierOptions = (selectedCreditType, creditTypes) =>
  selectedCreditType
    ? creditTypes
        .find(creditType => creditType.name === selectedCreditType)
        .multipliers.map((multiplier, index) => ({
          id: `credit-multiplier-${index}`,
          label: multiplier,
          value: multiplier,
        }))
    : []

class AddCreditForm extends Component {
  addCustomerCredit = (values, form) => {
    const { closeModal, customerId } = this.props

    return createCustomerCredit(customerId, values)
      .then(() => {
        form.setSubmitting(false)
        closeModal({ requiresRefetch: true })
      })
      .catch(error => {
        form.setSubmitting(false)
        form.setErrors({ global: error.message })
      })
  }

  render() {
    const { classes, closeModal } = this.props

    return (
      <div>
        <ModalHeader>Create Credit</ModalHeader>
        <ItemsFetcher
          queryKey="creditTypes"
          fetchItems={fetchCreditTypes}
          render={({ isLoadingItems: isFetchingCreditTypes, items: creditTypes }) => (
            <Formik
              initialValues={{
                amount: '',
                orderId: '',
                creditType: null, // creditTypes[0].name,
                department: null, // creditTypes[0].departments[0],
                multiplier: null, // creditTypes[0].multipliers[0],
                customerNote: '',
                sendEmail: false,
                internalNote: '',
              }}
              validate={values => {
                const errors = {}

                if (!values.amount) {
                  errors.amount = 'Credit Amount is a required field'
                }
                if (!values.creditType) {
                  errors.creditType = 'Credit Type is a required field'
                } else {
                  // If Credit Type is selected...
                  const creditDepartmentOptions = generateCreditDepartmentOptions(
                    values.creditType,
                    creditTypes
                  )
                  if (creditDepartmentOptions.length > 0 && !values.department) {
                    // Credit Department is required if that credit type is associated with a set of departments
                    errors.department =
                      'Credit Department is a required field for the selected Credit Type'
                  }
                }
                return errors
              }}
              validateOnBlur={false}
              validateOnChange={false}
              onSubmit={this.addCustomerCredit}
              render={({ values, errors, handleSubmit, isSubmitting, setFieldValue }) => {
                // Determine credit department and multiplier options depending on the selected credit type
                const creditDepartmentOptions = generateCreditDepartmentOptions(
                  values.creditType,
                  creditTypes
                )
                const creditMultiplierOptions = generateCreditMultiplierOptions(
                  values.creditType,
                  creditTypes
                )
                return (
                  <form className={classes.form} onSubmit={handleSubmit}>
                    <Styling.LineOfItems>
                      <FormFieldItem
                        id="add-credit-amount"
                        labelText="Credit Amount"
                        name="amount"
                        render={renderProps => (
                          <TextInput
                            {...renderProps}
                            autoFocus
                            placeholder="Credit Amount"
                            type="number"
                          />
                        )}
                      />
                      <FormFieldItem
                        id="add-credit-order-id"
                        labelText="Associated Order ID"
                        name="orderId"
                        render={renderProps => (
                          <TextInput
                            {...renderProps}
                            placeholder="Associated Order ID"
                            type="text"
                          />
                        )}
                      />
                    </Styling.LineOfItems>
                    <Styling.LineOfItems>
                      <FormFieldItem
                        id="credit-type"
                        labelText="Credit Type"
                        name="creditType"
                        render={({ id, isInvalid, value }) => (
                          <Dropdown
                            id={id}
                            isInvalid={isInvalid}
                            isLoading={isFetchingCreditTypes}
                            items={creditTypes.map((creditType, index) => ({
                              id: `credit-type-${index}`,
                              label: creditType.name,
                              value: creditType.name,
                            }))}
                            onChange={value => {
                              setFieldValue('creditType', value)
                              // Reset `department` and `multiplier` values
                              setFieldValue('department', null)
                              setFieldValue('multiplier', 'None')
                            }}
                            placeholder="Credit Type"
                            selectedValue={value}
                          />
                        )}
                      />
                      <FormFieldItem
                        id="credit-department"
                        labelText="Credit Department"
                        name="department"
                        render={({ id, value }) => (
                          <Dropdown
                            disabled={!values.creditType || creditDepartmentOptions.length === 0}
                            id={id}
                            isInvalid={!!(values.creditType && errors.department)}
                            isLoading={isFetchingCreditTypes}
                            items={creditDepartmentOptions}
                            onChange={value => {
                              setFieldValue('department', value)
                            }}
                            placeholder="Credit Department"
                            selectedValue={value}
                          />
                        )}
                      />
                      <FormFieldItem
                        id="credit-multiplier"
                        labelText="Credit Multiplier"
                        name="multiplier"
                        render={({ id, value }) => (
                          <Dropdown
                            disabled={!values.creditType || creditMultiplierOptions.length === 0}
                            id={id}
                            isInvalid={!!(values.creditType && errors.multiplier)}
                            isLoading={isFetchingCreditTypes}
                            items={creditMultiplierOptions}
                            onChange={value => {
                              setFieldValue('multiplier', value)
                            }}
                            placeholder="Credit Multiplier"
                            selectedValue={value || 'None'} // Autoselect "None" if value is null
                          />
                        )}
                      />
                    </Styling.LineOfItems>
                    <FormFieldItem
                      id="credit-send-email"
                      name="sendEmail"
                      render={({ onBlur, value, ...renderProps }) => (
                        <Checkbox
                          {...renderProps}
                          checked={value}
                          labelText="Send email to customer"
                        />
                      )}
                    />
                    <FormFieldItem
                      id="add-credit-customer-note"
                      labelText="Note to Guest"
                      name="customerNote"
                      render={renderProps => (
                        <TextArea {...renderProps} isResizable placeholder="Note to Guest" />
                      )}
                    />
                    <FormFieldItem
                      id="add-credit-internal-note"
                      labelText="Note to Picker"
                      name="internalNote"
                      render={renderProps => (
                        <TextArea {...renderProps} isResizable placeholder="Note to Picker" />
                      )}
                    />

                    {!isSubmitting && errors.global && (
                      <Notification kind="error" message={errors.global} />
                    )}
                    <Styling.LineOfButtons>
                      <Button disabled={isSubmitting} type="submit">
                        Add Credit
                      </Button>
                      <Button kind="link" onClick={closeModal}>
                        Cancel
                      </Button>
                    </Styling.LineOfButtons>
                  </form>
                )
              }}
            />
          )}
        />
      </div>
    )
  }
}

export default injectSheet(styles)(AddCreditForm)
