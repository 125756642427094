import PropTypes from 'prop-types'
import React from 'react'
import injectSheet from 'react-jss'

import { ReadOnlyValue } from 'components'

import ReadOnlyValueHeading from './ReadOnlyValueHeading'
import ReadOnlyValueWithDetails from './ReadOnlyValueWithDetails'

const styles = theme => ({
  readOnlyValueCard: {
    border: `1px solid ${theme.borderGreyLight}`,
    borderRadius: 2,
    paddingBottom: theme.spacing.md,
    paddingLeft: theme.spacing.lg,
    paddingTop: theme.spacing.md,
    paddingRight: theme.spacing.lg,
  },
})

const ReadOnlyValueCard = ({ classes, detailValues }) => (
  <div className={classes.readOnlyValueCard}>
    {detailValues.map(({ useCustomValueEl, ...readOnlyValueProps }, index) => {
      if (readOnlyValueProps.type === 'groupHeading') {
        const { labelText } = readOnlyValueProps

        return <ReadOnlyValueHeading key={index} labelText={labelText} />
      }
      if (readOnlyValueProps.type === 'withDetails') {
        const { detailValues, type, ...valueProps } = readOnlyValueProps

        return (
          <ReadOnlyValueWithDetails
            key={index}
            detailValues={detailValues}
            useCustomValueEl={useCustomValueEl}
            valueProps={valueProps}
          />
        )
      }
      if (!readOnlyValueProps.isHidden) {
        return (
          <ReadOnlyValue key={index} removeTextAreaEl={useCustomValueEl} {...readOnlyValueProps} />
        )
      }
      return null
    })}
  </div>
)

ReadOnlyValueCard.propTypes = {
  /**
   * The set of read-only value object to display.
   * (Each will correspond to/provide props for either a <ReadOnlyValue> or <ReadOnlyValueWithDetails> component.)
   */
  detailValues: PropTypes.array,
}

export { ReadOnlyValueCard as ReadOnlyValueCardUnStyled }
export default injectSheet(styles)(ReadOnlyValueCard)
