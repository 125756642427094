import memoize from 'memoize-one'
import React, { Fragment } from 'react'
import injectSheet from 'react-jss'
import { connect } from 'react-redux'
import { NumberValue, ObjectValue } from 'react-values'
import { bindActionCreators } from 'redux'

import {
  cancelCustomerCredit,
  fetchCustomer,
  fetchCustomerCredits,
  fetchCustomerTransactions,
  fetchOrders,
  resetCustomerPassword,
  updateCustomer,
  fetchCustomerSubscriptions,
} from 'api'
import {
  Button,
  ButtonIconAction,
  DataTableWithState,
  HeaderTotals,
  LoadingIndicator,
  Modal,
  Notification,
  PageHeader,
  ReadOnlyValueCard,
  ReadOnlyValueDetails,
  ShowIfAuthorized,
  StatusIndicatorDot,
  Tab,
  Tabs,
  Icon,
} from 'components'
import { getUserPermissionKeys } from 'modules/user'
import { createToast } from 'modules/toasts'
import { getBackButton, generateBackButton, getRoutePathname } from 'routing'

import Styling from 'styling/components'
import {
  capitalize,
  formatAddress,
  formatDate,
  formatDatetime,
  get,
  getPersonName,
  ItemFetcher,
  ItemsFetcher,
  openWebPlatformAsCustomer,
  queryString,
  snakeToCamelCase,
  toCurrency,
} from 'utils'

import AddCreditForm from './AddCreditForm'
import SubscriptionForm from './SubscriptionForm'
import SubscriptionCancel from './SubscriptionCancel'

const styles = theme => ({
  headerCustomerButtons: {
    alignItems: 'center',
    display: 'flex',

    '& > *': {
      marginRight: theme.spacing.xs,

      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  underHeaderContainer: {
    display: 'flex',
  },
  leftSide: {
    flexShrink: 0,
    marginRight: theme.spacing.xxlg,
    width: 272,
  },
  rightSide: {
    flexGrow: 1,
    flexShrink: 1,
  },
  actionCard: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${theme.spacing.md} ${theme.spacing.lg}`,
    marginBottom: theme.spacing.lg,
    border: `1px solid ${theme.bgSelected}`,
    fontSize: theme.fontSize.p,
    fontStyle: 'italic',
    color: theme.text01,
  },
  subscriptionModal: {
    overflow: 'visible',
  },
  infoIcon: {
    marginRight: 8,
    verticalAlign: 'middle',
    fill: theme.support03,
  },
})

const subscriptionTableHeaders = [
  {
    key: 'status',
    disableSorting: true,
  },
  {
    key: 'plan',
    header: 'Subscription',
  },
  {
    key: 'pluNumber',
    header: 'PLU Number',
  },
  {
    key: 'purchaseDate',
    header: 'Purchase Date',
  },
  {
    key: 'expiryDate',
    header: 'Expiry Date',
  },
  {
    key: 'cancel',
    header: 'Cancel',
    disableSorting: true,
  },
]
const orderTableHeaders = [
  {
    key: 'orderId',
    header: 'Order ID',
  },
  {
    key: 'fulfillmentDate',
    header: 'Fulfillment Date',
  },
  {
    key: 'fulfillmentType',
    header: 'Fulfillment Type',
  },
  {
    key: 'storeId',
    header: 'Store ID',
  },
  {
    key: 'orderTotal',
    header: 'Order Total',
  },
  {
    key: 'status',
    header: 'Status',
  },
]
const loyaltyTableHeaders = [
  {
    key: 'cardNumber',
    header: 'Card',
  },
  {
    key: 'pointsBalance',
    header: 'Balance',
  },
]
const transactionTableHeaders = [
  {
    key: 'date',
    header: 'Date',
  },
  {
    key: 'storeName',
    header: 'Store',
  },
  {
    key: 'totalItems',
    header: 'Items',
  },
  {
    key: 'totalSpend',
    header: 'Spend',
  },
]
const creditTableHeaders = [
  {
    key: 'created',
    header: 'Awarded On',
  },
  {
    key: 'creditType',
    header: 'Type',
  },
  {
    key: 'awardedBy',
    header: 'Awarded By',
  },
  {
    key: 'status',
    header: 'Status',
  },
  {
    key: 'amount',
    header: 'Amount',
  },
  {
    key: 'remaining',
    header: 'Remaining',
  },
]

const formatSubscriptionTableRows = memoize(
  (subscriptions, customerId, setEditingCustomerSubscription, refetchCustomerSubscriptions) =>
    subscriptions.map(subscription => {
      const {
        id,
        status,
        enterprise_subscription: enterpriseSubscription,
        products,
        created,
        expiry,
      } = subscription

      return {
        id: `subscription-${id}`,
        status: (
          <StatusIndicatorDot
            compact
            type={status ? 'complete' : 'error'}
            text={status ? 'Active' : 'Cancelled'}
          />
        ),
        plan: enterpriseSubscription.name,
        pluNumber: products.length ? products[0].product_code : '—',
        purchaseDate: formatDatetime(created),
        expiryDate: formatDatetime(expiry),
        cancel: status ? (
          // Prevent modal clicks from propagating up to the row button
          // (Yes, portals propagate through the React tree)
          <div onClick={event => event.stopPropagation()}>
            <Modal
              size="small"
              handleCloseModal={({ requiresRefetch }) => {
                if (requiresRefetch) {
                  refetchCustomerSubscriptions()
                }
              }}
              triggerRender={({ openModal }) => (
                <Button
                  kind="link"
                  onClick={event => {
                    event.stopPropagation()
                    openModal()
                  }}
                >
                  Cancel Subscription
                </Button>
              )}
              render={({ closeModal }) => (
                <SubscriptionCancel
                  closeModal={closeModal}
                  customerId={customerId}
                  subscription={subscription}
                />
              )}
            />
          </div>
        ) : (
          undefined
        ),
        rowLinkTo: status
          ? {
              onClick: () => {
                setEditingCustomerSubscription({
                  enterpriseSubscriptionId: enterpriseSubscription.id,
                  expiry,
                  isOpen: true,
                })
              },
            }
          : undefined,
      }
    })
)

const formatOrderTableRows = memoize((orders, location) =>
  orders.map(order => {
    const {
      id,
      ic_order_id,
      order_reference,
      estimatedTotals,
      finalTotals,
      fulfillmentDate,
      fulfillmentType,
      status,
      store: { extId: storeExtId },
    } = order

    let statusIndicatorType
    let statusIndicatorText = capitalize({ phrase: status })
    let orderTotalToUse = estimatedTotals
    switch (status) {
      case 'new':
      case 'available':
        statusIndicatorType = 'new'
        break
      case 'inprogress':
        statusIndicatorText = 'In Progress'
        statusIndicatorType = 'in-progress'
        break
      case 'done':
      case 'received':
      case 'shipped':
        statusIndicatorType = 'complete'
        orderTotalToUse = finalTotals
        break
      case 'cancelled':
        statusIndicatorType = 'cancelled'
        break
      default:
        break
    }

    const orderTotal = orderTotalToUse.total
    const orderTotalText = orderTotal ? toCurrency(orderTotalToUse.total) : ''

    return {
      id: `order-${id}`,
      rowLinkTo: {
        pathname: getRoutePathname('orders.order', { id: ic_order_id || id }),
        search: order_reference ? `?order_reference=${order_reference}` : '',
        state: { backButton: generateBackButton('orders', location) },
      },
      rowSortValues: {
        fulfillmentDate: new Date(fulfillmentDate),
        orderTotal,
        status: statusIndicatorText,
      },
      orderId: id,
      fulfillmentDate: formatDatetime(fulfillmentDate),
      fulfillmentType: capitalize({ phrase: fulfillmentType }),
      storeId: storeExtId,
      orderTotal: orderTotalText,
      status: <StatusIndicatorDot text={statusIndicatorText} type={statusIndicatorType} />,
    }
  })
)
const formatLoyaltyTableRows = memoize(loyaltyCards =>
  loyaltyCards.map((loyaltyCard, index) => ({
    id: `loyalty-card-${index}`,
    ...loyaltyCard,
  }))
)

// TODO: This is not currently supported by the BE, but hopefully will be in the future

// const TransactionLineItemsTable = ({ classes, lineItems }) => {
//   return (
//     <table>
//       <tbody>
//         {lineItems.map((lineItem, index) => (
//           <tr key={index}>
//             <td>
//               <img className={classes.productImage} alt="" src={lineItem.product.imageUrl} />
//             </td>
//             <td>{lineItem.product.name}</td>
//             <td>x</td>
//             <td>
//               {lineItem.product.weighted_item
//                 ? lineItem.quantity * lineItem.product.weight
//                 : lineItem.unitQuantity}
//             </td>
//             <td>{lineItem.sellingPrice}</td>
//             <td>{lineItem.pointsAwarded}</td>
//           </tr>
//         ))}
//       </tbody>
//     </table>
//   )
// }

const formatTransactionTableRows = memoize(transactions =>
  transactions.map(transaction => {
    const storeName = transaction.store?.name
    const {
      id,
      // lineItems,
      timestamp,
      totalItems,
      totalSpend,
    } = transaction

    return {
      id: `transaction-${id}`,
      // rowDetails: <TransactionLineItemsTable lineItems={lineItems} />,
      rowSortValues: {
        date: new Date(timestamp),
        totalSpend,
      },
      date: formatDatetime(timestamp),
      storeName,
      totalItems,
      totalSpend: toCurrency(totalSpend),
    }
  })
)
const formatCreditTableRows = memoize((credits, cancelCredit) =>
  credits.map((credit, index) => {
    const {
      id,
      created,
      createdBy,
      creditType,
      customerNote,
      department,
      internalNote,
      orderId,
      sendEmail,
      status,
      amount,
      remaining,
    } = credit

    let statusIndicatorType
    const statusIndicatorText = capitalize({ phrase: status })
    switch (status) {
      case 'pending':
        statusIndicatorType = 'in-progress'
        break
      case 'redeemed':
        statusIndicatorType = 'complete'
        break
      case 'cancelled':
      default:
        statusIndicatorType = 'cancelled'
        break
    }

    const creditId = `credit-${index}`

    let cancelCreditButton
    if (status !== 'cancelled') {
      cancelCreditButton = (
        <Modal.Confirmation
          handleCloseModal={({ wasConfirmed }) => {
            if (wasConfirmed) {
              cancelCredit(id)
            }
          }}
          triggerRender={({ openModal }) => (
            <ButtonIconAction description="Cancel Credit" icon="delete" onClick={openModal} />
          )}
          contentProps={{
            actionText: 'cancel this credit',
            confirmButtonText: 'Cancel Credit',
            cancelButtonText: 'Go Back',
          }}
        />
      )
    }

    return {
      id: creditId,
      rowAction: cancelCreditButton,
      rowDetails: (
        <ReadOnlyValueDetails
          detailValues={[
            {
              id: `${creditId}-department`,
              labelText: 'Department',
              value: department || 'N/A',
            },
            {
              id: `${creditId}-order-id`,
              labelText: 'Order ID',
              value: orderId || 'N/A',
            },
            {
              id: `${creditId}-send-email`,
              labelText: 'Send Email',
              value: sendEmail ? 'Yes' : 'No',
            },
            {
              id: `${creditId}-notes-to-guest`,
              labelText: 'Notes to Guest',
              value: customerNote || 'N/A',
            },
            {
              id: `${creditId}-internatl-note`,
              labelText: 'Internal Note',
              value: internalNote || 'N/A',
            },
          ]}
          orientation="vertical"
        />
      ),
      rowSortValues: {
        created: new Date(created),
        status: statusIndicatorText,
        amount,
        remaining,
      },
      created: formatDatetime(created),
      creditType,
      awardedBy: getPersonName({ person: createdBy }),
      status: <StatusIndicatorDot text={statusIndicatorText} type={statusIndicatorType} />,
      amount: toCurrency(amount),
      remaining: toCurrency(remaining),
    }
  })
)

const calculateCreditBalance = customerCredits =>
  customerCredits
    .reduce((sum, credit) => (credit.status !== 'cancelled' ? sum + credit.remaining : sum), 0)
    .toFixed(2, 10)

const Customer = ({
  classes,
  createToast,
  location,
  match: {
    params: { customerId },
  },
  userPermissions,
}) => (
  <ItemFetcher
    queryKey="customer"
    queryParams={{ customerId }}
    fetchItem={config => fetchCustomer(customerId, window.location.search, config)}
    render={({
      item: customer,
      isLoadingItem: isFetchingCustomer,
      error: errorFetchingCustomer,
      refetch: refetchCustomer,
    }) => {
      if (isFetchingCustomer) {
        return <LoadingIndicator withTopMargin />
      }
      if (errorFetchingCustomer) {
        return (
          <Styling.Center maxWidth={500} withTopMargin>
            <Notification kind="error" message={errorFetchingCustomer.message} />
          </Styling.Center>
        )
      }
      if (customer) {
        const customerName = getPersonName({
          person: customer,
          fallback: `Guest #: ${customer.id}`,
        })

        return (
          <ItemsFetcher
            queryKey="customerCredits"
            fetchItems={config => fetchCustomerCredits(customer.id, config)}
            render={({
              items: customerCredits,
              isLoadingItems: isFetchingCustomerCredits,
              refetch: refetchCustomerCredits,
              error: errorFetchingCustomerCredits,
            }) => {
              const cancelCredit = creditId =>
                cancelCustomerCredit(customer.id, creditId)
                  .then(() => {
                    createToast({
                      kind: 'success',
                      message: 'Customer credit successfully cancelled.',
                    })
                    refetchCustomerCredits()
                  })
                  .catch(({ message }) => {
                    createToast({ kind: 'error', message })
                  })

              const statusText = customer.status ? 'active' : 'inactive'
              const statusToggleText = customer.status ? 'inactive' : 'active'

              return (
                <div>
                  <PageHeader
                    backButton={get(location, 'state.backButton') || getBackButton('customers')}
                    headerTitle={customerName}
                    headerTitleMaxWidth={310}
                    statusIsActive={customer.status}
                    statusText={capitalize({ phrase: statusText })}
                  >
                    <div className={classes.headerCustomerButtons}>
                      <ShowIfAuthorized requiredPermission="misc.create_credits">
                        <Modal
                          handleCloseModal={({ requiresRefetch }) => {
                            if (requiresRefetch) {
                              createToast({
                                kind: 'success',
                                message: 'Credit successfully created.',
                              })
                              refetchCustomerCredits()
                            }
                          }}
                          size="large"
                          triggerRender={({ openModal }) => (
                            <Button onClick={openModal}>Add Credit</Button>
                          )}
                          render={props => <AddCreditForm customerId={customer.id} {...props} />}
                        />
                      </ShowIfAuthorized>
                      <ShowIfAuthorized requiredPermission="customers.edit">
                        <Fragment>
                          <Button
                            icon="external"
                            kind="secondary"
                            onClick={() => {
                              openWebPlatformAsCustomer(customer.id).catch(({ message }) => {
                                createToast({ kind: 'error', message })
                              })
                            }}
                          >
                            Log In As Guest
                          </Button>
                          <Button
                            icon="external"
                            kind="secondary"
                            onClick={() => {
                              openWebPlatformAsCustomer(customer.id, 'account/profile').catch(
                                ({ message }) => {
                                  createToast({ kind: 'error', message })
                                }
                              )
                            }}
                          >
                            Edit Details
                          </Button>
                        </Fragment>
                      </ShowIfAuthorized>
                      <ShowIfAuthorized requiredPermission="misc.password_reset">
                        <Button
                          icon="send"
                          iconPosition="before"
                          kind="secondary"
                          onClick={() => {
                            resetCustomerPassword(customer.id)
                              .then(() => {
                                createToast({
                                  kind: 'success',
                                  message: 'Reset Password email sent to customer.',
                                })
                              })
                              .catch(({ message }) => {
                                createToast({ kind: 'error', message })
                              })
                          }}
                        >
                          Reset Password
                        </Button>
                      </ShowIfAuthorized>
                      <ShowIfAuthorized requiredPermission="customers.edit">
                        <Modal.Confirmation
                          handleCloseModal={({ wasConfirmed }) => {
                            if (wasConfirmed) {
                              const newCustomer = { ...customer, status: !customer.status }
                              updateCustomer(customer.id, newCustomer)
                                .then(() => {
                                  createToast({
                                    kind: 'success',
                                    message: 'Customer status successfully updated.',
                                  })
                                  refetchCustomer()
                                })
                                .catch(({ message }) => {
                                  createToast({ kind: 'error', message })
                                })
                            }
                          }}
                          triggerRender={({ openModal }) => (
                            <Button
                              kind={customer.status ? 'danger' : 'primary'}
                              onClick={openModal}
                            >
                              {`Set ${capitalize({ phrase: statusToggleText })}`}
                            </Button>
                          )}
                          contentProps={{
                            actionText: `set this customer's status to ${statusToggleText}`,
                            confirmButtonText: `Set ${capitalize({ phrase: statusToggleText })}`,
                          }}
                        />
                      </ShowIfAuthorized>
                    </div>
                  </PageHeader>
                  <div className={classes.underHeaderContainer}>
                    <div className={classes.leftSide}>
                      <ReadOnlyValueCard
                        detailValues={[
                          {
                            id: 'customer-details-name',
                            labelText: 'Name',
                            value: customerName,
                          },
                          {
                            id: 'customer-details-store',
                            labelText: 'Store',
                            value: customer.store ? customer.store.name : 'N/A',
                          },
                          {
                            id: 'customer-details-email',
                            labelText: 'Email',
                            value: customer.email,
                          },
                          {
                            id: 'customer-details-phone',
                            labelText: 'Phone',
                            value:
                              customer[snakeToCamelCase(customer.primaryNumberKey)] ||
                              customer.homePhoneNumber ||
                              customer.mobilePhoneNumber ||
                              customer.otherPhoneNumber ||
                              'N/A',
                            type: 'withDetails',
                            detailValues: [
                              {
                                id: 'customer-details-phone-home',
                                labelText: 'Home',
                                value: `${customer.homePhoneNumber || 'N/A'}${
                                  customer.primaryNumberKey === 'home_phone_number'
                                    ? '\n(Primary)'
                                    : ''
                                }`,
                              },
                              {
                                id: 'customer-details-phone-mobile',
                                labelText: 'Mobile',
                                value: `${customer.mobilePhoneNumber || 'N/A'}${
                                  customer.primaryNumberKey === 'mobile_phone_number'
                                    ? '\n(Primary)'
                                    : ''
                                }`,
                              },
                              {
                                id: 'customer-details-phone-other',
                                labelText: 'Other',
                                value: `${customer.otherPhoneNumber || 'N/A'}${
                                  customer.primaryNumberKey === 'other_phone_number'
                                    ? '\n(Primary)'
                                    : ''
                                }`,
                              },
                            ],
                          },
                          {
                            id: 'customer-details-delivery-address',
                            labelText: 'Delivery',
                            value: formatAddress(customer.deliveryAddress) || 'N/A',
                          },
                          {
                            id: 'customer-details-gender',
                            labelText: 'Gender',
                            value: customer.gender
                              ? capitalize({ phrase: customer.gender })
                              : 'N/A',
                          },
                          {
                            id: 'customer-details-birthday',
                            labelText: 'Birthday',
                            value: formatDate(customer.birthday),
                          },
                          {
                            id: 'customer-details-joined',
                            labelText: 'Joined',
                            value: formatDate(customer.created),
                          },
                          {
                            id: 'customer-details-modified',
                            labelText: 'Modified',
                            value: formatDatetime(customer.modified),
                          },
                        ]}
                      />
                    </div>
                    <NumberValue defaultValue={0}>
                      {({ value: selectedTab, set: selectTab }) => (
                        <Tabs
                          className={classes.rightSide}
                          selectedTab={selectedTab}
                          onSelectTab={selectTab}
                        >
                          {userPermissions.includes('orders.view') && (
                            <Tab label="Online Orders">
                              {/* Customer Orders Table */}
                              <ItemsFetcher
                                queryKey="orders"
                                queryParams={{ customerId: customer.id, customer_id: customer.id }}
                                fetchItems={config => {
                                  const { ic_user_id } = queryString.parse(window.location.search)
                                  const qs = ic_user_id ? `?ic_user_id=${ic_user_id}` : ''
                                  return fetchOrders(qs, config)
                                }}
                                render={({
                                  items: customerOrders,
                                  isLoadingItems: isFetchingCustomerOrders,
                                  error: errorFetchingCustomerOrders,
                                }) => {
                                  /**
                                   * TODO(@henrique-m): This is a temporary fix fix and should be removed once
                                   * the IC orders timeout issue is fixed on the BE
                                   */
                                  if (errorFetchingCustomerOrders?.message?.includes('timeout')) {
                                    return (
                                      <div css={{ textAlign: 'center' }}>
                                        <Icon
                                          className={classes.infoIcon}
                                          css={classes.infoIcon}
                                          name="warning"
                                        />
                                        <span>We are currently unable to find any orders.</span>
                                      </div>
                                    )
                                  }

                                  return (
                                    <DataTableWithState
                                      error={errorFetchingCustomerOrders}
                                      headers={orderTableHeaders}
                                      id="customer-details-order-table"
                                      isLoadingNewRows={isFetchingCustomerOrders}
                                      kind="light-tall-row"
                                      rows={formatOrderTableRows(customerOrders, location)}
                                    />
                                  )
                                }}
                              />
                            </Tab>
                          )}
                          <Tab label="Loyalty">
                            {/* Customer Loyalty Table */}
                            <DataTableWithState
                              headers={loyaltyTableHeaders}
                              id="customer-details-loyalty-table"
                              kind="light-tall-row"
                              rows={formatLoyaltyTableRows(customer.loyaltyCards)}
                            />
                          </Tab>
                          <Tab label="Transactions">
                            {/* Customer Transactions Totals & Table */}
                            <ItemsFetcher
                              queryKey="customerTransactions"
                              queryParams={{ customerId: customer.id }}
                              fetchItems={config => fetchCustomerTransactions(customer.id, config)}
                              render={({
                                items: customerTransactions,
                                isLoadingItems: isFetchingCustomerTransactions,
                                error: errorFetchingCustomerTransactions,
                                totalSpend,
                                totalTransactions,
                              }) => (
                                <Fragment>
                                  {!isFetchingCustomerTransactions && (
                                    <HeaderTotals
                                      headers={[
                                        { label: 'Total Spend', total: toCurrency(totalSpend) },
                                        {
                                          label: 'Total Transactions',
                                          total: totalTransactions,
                                        },
                                      ]}
                                    />
                                  )}
                                  <DataTableWithState
                                    error={errorFetchingCustomerTransactions}
                                    headers={transactionTableHeaders}
                                    id="customer-details-transaction-table"
                                    isLoadingNewRows={isFetchingCustomerTransactions}
                                    kind="light"
                                    rows={formatTransactionTableRows(customerTransactions)}
                                  />
                                </Fragment>
                              )}
                            />
                          </Tab>
                          <Tab label="Credits">
                            {/* Customer Credits Table */}
                            {customerCredits && (
                              <HeaderTotals
                                headers={[
                                  {
                                    label: 'Credit Balance',
                                    total: `$${calculateCreditBalance(customerCredits)}`,
                                  },
                                ]}
                              />
                            )}
                            <DataTableWithState
                              error={errorFetchingCustomerCredits}
                              headers={creditTableHeaders}
                              id="customer-details-credit-table"
                              isLoadingNewRows={isFetchingCustomerCredits}
                              kind="light"
                              rows={formatCreditTableRows(customerCredits, cancelCredit)}
                            />
                          </Tab>
                          {userPermissions.includes('customers.edit') && (
                            <Tab label="Subscriptions">
                              <ItemsFetcher
                                queryKey="subscriptions"
                                queryParams={{ customerId: customer.id }}
                                fetchItems={config =>
                                  fetchCustomerSubscriptions(customer.id, config)
                                }
                                render={({
                                  items: customerSubscriptions,
                                  isLoadingItems: isFetchingCustomerSubscriptions,
                                  refetch: refetchCustomerSubscriptions,
                                  error: errorFetchingCustomerSubscriptions,
                                }) => (
                                  <Fragment>
                                    {!isFetchingCustomerSubscriptions &&
                                      !errorFetchingCustomerSubscriptions &&
                                      !customerSubscriptions.find(
                                        subscription => subscription.status
                                      ) && (
                                        <div className={classes.actionCard}>
                                          <span>
                                            There is no active subscription for this customer.
                                          </span>
                                          <Modal
                                            className={classes.subscriptionModal}
                                            handleCloseModal={({ requiresRefetch }) => {
                                              if (requiresRefetch) {
                                                refetchCustomerSubscriptions()
                                              }
                                            }}
                                            size="large"
                                            triggerRender={({ openModal }) => (
                                              <Button onClick={openModal}>Add Subscription</Button>
                                            )}
                                            render={({ closeModal }) => (
                                              <SubscriptionForm
                                                closeModal={closeModal}
                                                mode="create"
                                                customerId={customer.id}
                                              />
                                            )}
                                          />
                                        </div>
                                      )}
                                    <ObjectValue defaultValue={{ isOpen: false }}>
                                      {({
                                        value: editingCustomerSubscription,
                                        set: setEditingCustomerSubscription,
                                      }) =>
                                        customerSubscriptions.length > 0 && (
                                          <Fragment>
                                            <Modal
                                              className={classes.subscriptionModal}
                                              isOpen={editingCustomerSubscription.isOpen}
                                              handleCloseModal={({ requiresRefetch }) => {
                                                setEditingCustomerSubscription({ isOpen: false })
                                                if (requiresRefetch) {
                                                  refetchCustomerSubscriptions()
                                                }
                                              }}
                                              size="large"
                                              render={({ closeModal }) => (
                                                <SubscriptionForm
                                                  closeModal={closeModal}
                                                  mode="edit"
                                                  customerId={customer.id}
                                                  editingSubscription={editingCustomerSubscription}
                                                />
                                              )}
                                            />
                                            <DataTableWithState
                                              id="customer-details-subscriptions-table"
                                              headers={subscriptionTableHeaders}
                                              error={errorFetchingCustomerSubscriptions}
                                              isLoadingNewRows={isFetchingCustomerSubscriptions}
                                              kind="light"
                                              rows={formatSubscriptionTableRows(
                                                customerSubscriptions.sort(
                                                  (a, b) => b.status - a.status
                                                ),
                                                customer.id,
                                                setEditingCustomerSubscription,
                                                refetchCustomerSubscriptions
                                              )}
                                            />
                                          </Fragment>
                                        )
                                      }
                                    </ObjectValue>
                                  </Fragment>
                                )}
                              />
                            </Tab>
                          )}
                        </Tabs>
                      )}
                    </NumberValue>
                  </div>
                </div>
              )
            }}
          />
        )
      }
    }}
  />
)

const mapStateToProps = state => ({
  userPermissions: getUserPermissionKeys(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createToast,
    },
    dispatch
  )

export default injectSheet(styles)(connect(mapStateToProps, mapDispatchToProps)(Customer))
