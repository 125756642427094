/*
 * This is a simplified version of the AddCategories.js component
 * It contains a lot of duplicate functionality and should be refactored to share with the original component
 * It also should include an easy way to switch between fetching categories and keywords (when available)
 *
 */

import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import Highlighter from 'react-highlight-words'
import injectSheet from 'react-jss'

import { fetchCategories } from 'api'
import { Autocomplete } from 'components'
import { ItemsFetcher } from 'utils'

import styles from './AddCategoriesShared.styles'
import { transformCategoryData } from './utils/AddCategoriesHelpers'

/**
 * Combines Autocomplete, SelectedItems, and Modal.BrowseItems to allow users to
 * browse, add, and remove items from a large list.
 */
class AddCategoriesSimple extends Component {
  state = {
    categories: [],
  }

  // Get the current category. Convert URL param IDs to numbers
  getCategory = categoryId => this.state.categories.find(c => c.id === parseInt(categoryId, 10))

  render() {
    const { id, classes, onItemSelect, selectedItems } = this.props
    let formattedSelectedItems = []
    let defaultValue = ''

    // Format the selectedItems array to a useable name for the input value
    if (selectedItems.length > 0) {
      formattedSelectedItems = this.getCategory(selectedItems[0])
        ? [this.getCategory(selectedItems[0])]
        : []
      defaultValue = formattedSelectedItems[0] ? formattedSelectedItems[0].name : ''
    }

    return (
      <ItemsFetcher
        queryKey="categories"
        includeItemsById
        fetchItems={config => {
          return fetchCategories(config).then(({ items, itemCount }) => {
            this.setState({ categories: items })

            return {
              items: transformCategoryData(items),
              itemCount,
            }
          })
        }}
        render={({
          items: categories,
          itemsById: categoriesById,
          isLoadingItems: isFetchingCategories,
        }) => (
          <Fragment>
            {!isFetchingCategories && (
              <Fragment>
                <div className={classes.addAutocompleteAndModalTrigger}>
                  <Autocomplete
                    className={classes.addAutocomplete}
                    defaultValue={defaultValue}
                    hideClearInput={false}
                    key={defaultValue}
                    id={id}
                    items={categories}
                    menuItemHeight={55}
                    onClear={() => onItemSelect({})}
                    onItemSelect={item => onItemSelect(item)}
                    placeholder="Add Categories"
                    renderMenuItem={({ item: category, inputValue }) => (
                      <div className={classes.dropdownMenuItem}>
                        <div>
                          <Highlighter
                            searchWords={[inputValue]}
                            autoEscape
                            textToHighlight={category.name}
                          />
                          {category.childCount}
                        </div>
                        <div className={classes.dropdownMenuItemBottom}>{category.parentPath}</div>
                      </div>
                    )}
                    selectedItems={formattedSelectedItems}
                  />
                </div>
              </Fragment>
            )}
          </Fragment>
        )}
      />
    )
  }
}

AddCategoriesSimple.propTypes = {
  id: PropTypes.string.isRequired,
  onItemSelect: PropTypes.func.isRequired,
  selectedItems: PropTypes.array.isRequired,
}

AddCategoriesSimple.defaultProps = {
  selectedItems: [],
}

export default injectSheet(styles)(AddCategoriesSimple)
