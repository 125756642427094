import cx from 'classnames'
import Downshift from 'downshift'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import injectSheet from 'react-jss'

import { Button, FormItem, FormLabel, ListBox, MultiInput, TextInput } from 'components'

const styles = theme => ({
  minMaxDropdownMenu: {
    maxHeight: 'none !important',
    padding: `${theme.spacing.md} !important`,
    width: '212px !important',
  },
  minMaxInput: {
    minWidth: '0 !important',
  },
})

const hasNumericValue = value => !isNaN(parseFloat(value, 10))

const hasValue = (value, { type }) =>
  value !== '' && (type === 'number' || type === 'currency') && hasNumericValue(value)

const parseValue = (value, { type }) => {
  if (hasValue(value, { type })) {
    if (type === 'number') {
      return parseFloat(value, 10)
    }
    if (type === 'currency') {
      const parsedValue = parseFloat(value, 10)
      return parsedValue % 1 !== 0 ? parsedValue.toFixed(2) : parsedValue
    }
    return value
  }
  return ''
}

const isValidRange = ({ from, to, type }) =>
  // Either one or neither of `from` and `to` have values
  !(hasValue(from, { type }) && hasValue(to, { type })) ||
  // If `from` and `to` have values, and MinMax is of type `number` or `currency`,
  // ensure `from` is not greater than `to`
  parseInt(from, 10) <= parseInt(to, 10)

const rangeToString = ({ from, to, type }) => {
  const prefixSymbol = type === 'currency' ? '$' : ''

  if (hasValue(from, { type }) && hasValue(to, { type })) {
    return from === to ? `${prefixSymbol}${from}` : `${prefixSymbol}${from} - ${prefixSymbol}${to}`
  }
  if (hasValue(from, { type })) {
    return `> ${prefixSymbol}${from}`
  }
  if (hasValue(to, { type })) {
    return `< ${prefixSymbol}${to}`
  }
  return null
}

class MinMax extends Component {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    isInvalid: PropTypes.bool,
    onChange: PropTypes.func,
    placeholder: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['currency', 'number']),
    value: PropTypes.shape({
      from: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      to: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  }

  static defaultProps = {
    type: 'number',
    value: {
      from: '',
      to: '',
    },
  }

  state = {
    isOpen: false,
    // fromInputValue
    // toInputValue
  }

  // Set fromInputValue and toInputValue to the value prop provided
  static getDerivedStateFromProps(props, state) {
    // On initial render or any subsequent changes to `value` prop
    if (!state.lastValueProp || props.value !== state.lastValueProp) {
      return {
        fromInputValue: props.value.from || '',
        toInputValue: props.value.to || '',
        lastValueProp: props.value,
      }
    }
    return null
  }

  handleResetClick = () => {
    this.setState({
      fromInputValue: '',
      toInputValue: '',
    })
  }

  // Close menu on ESC and outside click and only trigger an onChange when the dropdown menu is closed
  handleDownshiftStateChange = (changes, { closeMenu, openMenu }) => {
    const { onChange, value } = this.props
    const { type } = changes
    if (
      type === Downshift.stateChangeTypes.mouseUp ||
      type === Downshift.stateChangeTypes.keyDownEscape
    ) {
      closeMenu()

      // If the input values differ from the `value` prop, trigger this.props.onChange
      const oldFrom = parseFloat(value.from, 10) || null
      const oldTo = parseFloat(value.to, 10) || null
      const newFrom = this.state.fromInputValue ? parseFloat(this.state.fromInputValue, 10) : null
      const newTo = this.state.toInputValue ? parseFloat(this.state.toInputValue, 10) : null

      if (onChange && (oldFrom !== newFrom || oldTo !== newTo)) {
        onChange({ from: newFrom, to: newTo })
      }
    } else if (type === Downshift.stateChangeTypes.blurButton) {
      openMenu()
    }
  }

  handleChangeFrom = event => {
    const { value: fromInputValue } = event.target
    this.setState({ fromInputValue })
  }

  handleChangeTo = event => {
    const { value: toInputValue } = event.target
    this.setState({ toInputValue })
  }

  handleBlurFrom = event => {
    const { value: fromInputValue } = event.target
    const { type } = this.props
    const { toInputValue } = this.state

    const newFromValue = parseValue(fromInputValue, { type })

    if (
      !hasValue(fromInputValue, { type }) ||
      !isValidRange({ from: fromInputValue, to: toInputValue, type })
    ) {
      // User entered an invalid `from` value, clear the `from` input
      this.setState({ fromInputValue: '' })
    } else {
      this.setState({ fromInputValue: newFromValue })
    }
  }

  handleBlurTo = event => {
    const { value: toInputValue } = event.target
    const { type } = this.props
    const { fromInputValue } = this.state

    const newToValue = parseValue(toInputValue, { type })

    if (
      !hasValue(toInputValue, { type }) ||
      !isValidRange({ from: fromInputValue, to: toInputValue, type })
    ) {
      // User entered an invalid `to` value, clear the `to` input
      this.setState({ toInputValue: '' })
    } else {
      this.setState({ toInputValue: newToValue })
    }
  }

  render() {
    const {
      classes,
      className: containerClassName,
      disabled: isDisabled,
      id,
      isInvalid,
      placeholder,
      type,
    } = this.props

    const { fromInputValue, toInputValue } = this.state
    const hasMinMaxRange = hasValue(fromInputValue, { type }) || hasValue(toInputValue, { type })

    const inputTypeProps = {}
    if (type === 'number' || type === 'currency') {
      inputTypeProps.type = 'number'
      inputTypeProps.pattern = 'd*'
    }
    if (type === 'currency') {
      inputTypeProps.inputPrefixSymbol = '$'
    }

    return (
      <Downshift onStateChange={this.handleDownshiftStateChange}>
        {({ getRootProps, getButtonProps, isOpen }) => (
          <ListBox
            className={cx(containerClassName, classes.minMax)}
            id={id}
            isDisabled={isDisabled}
            {...getRootProps({ refKey: 'setRef' })}
          >
            <ListBox.Field
              isDisabled={isDisabled}
              isInvalid={isInvalid}
              isSelected={hasMinMaxRange}
              {...getButtonProps({ disabled: isDisabled })}
            >
              <ListBox.Label
                isDisabled={isDisabled}
                isSelectedItem={hasMinMaxRange}
                text={
                  rangeToString({ from: fromInputValue, to: toInputValue, type }) || placeholder
                }
              />
              <ListBox.MenuIcon isDisabled={isDisabled} isOpen={isOpen} />
            </ListBox.Field>
            {isOpen && (
              <ListBox.Menu className={classes.minMaxDropdownMenu}>
                <MultiInput errorId={`${id}-error`}>
                  <div>
                    <FormLabel htmlFor={`${id}-min`} labelText="Min" />
                    <TextInput
                      className={classes.minMaxInput}
                      id={`${id}-min`}
                      onBlur={this.handleBlurFrom}
                      onChange={this.handleChangeFrom}
                      placeholder="Min"
                      value={fromInputValue}
                      {...inputTypeProps}
                    />
                  </div>
                  <div>
                    <FormLabel htmlFor={`${id}-max`} labelText="Max" />
                    <TextInput
                      className={classes.minMaxInput}
                      id={`${id}-max`}
                      onBlur={this.handleBlurTo}
                      onChange={this.handleChangeTo}
                      placeholder="Max"
                      value={toInputValue}
                      {...inputTypeProps}
                    />
                  </div>
                </MultiInput>
                <FormItem>
                  <Button kind="link" onClick={this.handleResetClick}>
                    Clear
                  </Button>
                </FormItem>
              </ListBox.Menu>
            )}
          </ListBox>
        )}
      </Downshift>
    )
  }
}

export { MinMax as MinMaxUnStyled }
export default injectSheet(styles)(MinMax)
