import React from 'react'
import injectSheet from 'react-jss'

import { DropdownDate, FormFieldItem } from 'components'

import styles from '../PromotedPlacementsForm.styles'

const PlacementActivityPeriod = ({
  classes,
  componentId,
  labelText,
  setFieldValue,
  values: { activityPeriod },
}) => (
  <FormFieldItem
    id={componentId}
    labelText={labelText}
    name="activityPeriod"
    render={({ name }) => (
      <DropdownDate
        className={classes.formRangeInput}
        enableNoEndDate
        defaultValue={activityPeriod}
        hasDateOptions={false}
        id="orders-filter-fulfillment-date"
        onChange={newDate => {
          setFieldValue(name, newDate)
        }}
        placeholder="Activity Period"
        returnDatetimeValues
      />
    )}
  />
)

export default injectSheet(styles)(PlacementActivityPeriod)
