import { Formik } from 'formik'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { getBackButton, getRouteTo } from 'routing'
import Styling from 'styling/components'
import * as api from 'api'
import {
  Button,
  LoadingIndicator,
  Modal,
  PageHeader,
  ShowIfAuthorized,
  StatusIndicatorBadge,
} from 'components'
import { ItemFetcher, openWebPlatform } from 'utils'

import { createToast } from 'modules/toasts'

import KeywordRuleDetailForm from './KeywordRuleDetailForm'

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createToast,
    },
    dispatch
  )

export const EditKeywordRuleDetail = connect(
  null,
  mapDispatchToProps
)(
  ({
    history,
    location,
    match: {
      params: { keywordRuleDetailId },
    },
    createToast,
    keywordType,
    ...restProps
  }) => (
    <ItemFetcher
      queryKey="keywordRuleAndGlobalRecipeSearchSetting"
      queryParams={{ keywordRuleDetailId }}
      fetchItem={config => {
        if (keywordType === 'recipe') {
          return Promise.all([
            api.fetchRecipeKeywordRule(keywordRuleDetailId, config),
            api.fetchGlobalRecipeSearchSetting(config),
          ])
        }

        return Promise.all([
          api.fetchKeywordRule(keywordRuleDetailId, config),
          api.fetchGlobalSearchSetting(config),
        ])
      }}
      render={({ item, isLoadingItem: isFetchingKeywordRuleDetail }) => {
        if (isFetchingKeywordRuleDetail) {
          return <LoadingIndicator withTopMargin />
        }

        if (!item) return null

        const [keywordRuleDetail, globalKeywordRule] = item

        const onFormSubmit = formValues => {
          const postData = { ...formValues }
          postData.boostCategories = postData.boostCategories.map(({ id, href, level, name }) => ({
            id,
            href,
            level,
            name,
          }))

          postData.excludeCategories = postData.excludeCategories.map(({ id }) => ({
            id: Number.parseInt(id, 10),
          }))

          if (keywordType === 'recipe') {
            return api.updateRecipeKeywordRule(keywordRuleDetailId, postData)
          }

          return api.updateKeywordRule(keywordRuleDetailId, postData)
        }

        const contextBadge =
          keywordType === 'recipe' ? (
            <StatusIndicatorBadge text="In Recipes" type="in-progress" />
          ) : (
            <StatusIndicatorBadge text="In Products" type="complete" />
          )

        const backRoute =
          keywordType === 'product'
            ? 'searchSettings.keywordRules'
            : 'searchSettings.recipeKeywordRules'

        const backLabel =
          keywordType === 'product' ? 'Product Search Keyword Rules' : 'Recipe Search Keyword Rules'

        const backButton = getBackButton(backRoute, {
          label: backLabel,
        })

        return (
          <div>
            <PageHeader
              backButton={backButton}
              headerTitle={
                <span>
                  {keywordRuleDetail.name} {contextBadge}
                </span>
              }
            >
              <Styling.LineOfItems alignCenter>
                <Button
                  kind="secondary"
                  onClick={() => {
                    openWebPlatform(`search?search_term=${keywordRuleDetail.name}`)
                  }}
                  toolTipText="Rule updates may take up to 20 minutes to appear"
                  icon="external"
                  toolTipWrap
                >
                  Preview
                </Button>
                <ShowIfAuthorized
                  requiredPermission={
                    keywordType === 'product' ? 'search_rules.edit' : 'recipe_search_rules.edit'
                  }
                >
                  <Modal.Confirmation
                    handleCloseModal={({ wasConfirmed }) => {
                      if (wasConfirmed) {
                        let deletionPromise
                        if (keywordType === 'recipe') {
                          deletionPromise = api.deleteRecipeKeywordRule(keywordRuleDetail.id)
                        } else {
                          deletionPromise = api.deleteKeywordRule(keywordRuleDetail.id)
                        }

                        deletionPromise
                          .then(() => {
                            createToast({
                              kind: 'success',
                              message: 'Rule successfully deleted.',
                            })

                            history.push(getRouteTo(backRoute))
                          })
                          .catch(({ message }) => {
                            createToast({ kind: 'error', message })
                          })
                      }
                    }}
                    triggerRender={({ openModal }) => (
                      <Button kind="danger" onClick={openModal}>
                        Delete
                      </Button>
                    )}
                    contentProps={{
                      actionText: 'delete this rule',
                      confirmButtonText: 'Delete',
                    }}
                  />
                </ShowIfAuthorized>
              </Styling.LineOfItems>
            </PageHeader>
            <Formik
              initialValues={keywordRuleDetail}
              validateOnBlur={false}
              validateOnChange={false}
              onSubmit={(values, form) => {
                onFormSubmit(values)
                  .then(() => {
                    createToast({ kind: 'success', message: 'Keyword rule update succeeded.' })
                    form.setSubmitting(false)
                  })
                  .catch(error => {
                    createToast({
                      kind: 'error',
                      message: `Error while updating keyword rule: ${error}`,
                    })
                    form.setSubmitting(false)
                    form.setErrors({ global: error.message })
                  })
              }}
              render={formikProps => {
                return (
                  <KeywordRuleDetailForm
                    keywordType={keywordType}
                    globalSettings={globalKeywordRule}
                    cancelDestination={backButton.to}
                    {...formikProps}
                  />
                )
              }}
            />
          </div>
        )
      }}
    />
  )
)

EditKeywordRuleDetail.propTypes = {
  keywordType: PropTypes.oneOf(['product', 'recipe']),
}
