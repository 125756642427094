import React, { Component } from 'react'
import injectSheet from 'react-jss'
import sortBy from 'lodash/sortBy'

import {
  searchProducts,
  fetchCollectionAttributes,
  fetchBrands,
  fetchCategories,
  fetchTags,
  fetchGlobalSearchSetting,
} from 'api'

import {
  Button,
  ButtonIconAction,
  ButtonToggle,
  Checkbox,
  Dropdown,
  DropdownFilterable,
  DropdownMenu,
  DropdownMenuItem,
  FormFieldItem,
  Modal,
  DismissibleNotification,
  Icon,
  SearchInput,
  RadioButtons,
  TextInput,
  Tooltip,
} from 'components'

import { reorder } from 'components/DataTable/utils/reorder'

import { CollectionProductsWithState } from './CollectionProducts'
import { CollectionProductsRulesWithState } from './CollectionProductsRules'
import CollectionFormRuleAccordion from './CollectionFormRuleAccordion'

import styles from './CollectionCreateOrEdit.styles'

/*
 * REGEX for splitting search for products input
 * Split string by space or comma
 */
const COPY_STRING_DELIMETER = /[ ,]+/

const UPC_PREFIX_DELIMITER = '_'

const ADD_PRODUCT_OPTION_VALUE_KEY = 'add-product-option-value'

const addProductOptions = [
  { id: 'add-product-upc', label: 'Add by UPC', value: 'upc' },
  { id: 'add-product-sku', label: 'Add by SKU', value: 'sku' },
  { id: 'add-product-rrc', label: 'Add by RRC', value: 'rrc' },
]

export const sortByItems = [
  { value: null, label: 'Default' },
  { value: 'popularity', label: 'Popularity' },
  { value: 'recently_added', label: 'Recently Added' },
  { value: 'recommended', label: 'Recommended' },
]

const humanizeAttribute = name =>
  ({
    PRODUCTS: 'All Products',
    PAST_PURCHASED: 'Purchased Before',
  }[name] || name)

export const fetchData = async () => {
  const [
    { brands: productBrands },
    { rules: collectionAttributes },
    { items: categories },
    { items: tags },
    { boostPastPurchases },
  ] = await Promise.all([
    fetchBrands(),
    fetchCollectionAttributes(),
    fetchCategories(),
    fetchTags(),
    fetchGlobalSearchSetting(),
  ])

  const dynamicBrands = productBrands.map((item, index) => ({
    id: index,
    value: item,
  }))
  const dynamicAttributes = collectionAttributes.map(rule => ({
    ...rule,
    key: rule.name,
    name: humanizeAttribute(rule.name),
    value: rule.id,
  }))
  const dynamicCategories = categories
    .filter(category => category.level === 1)
    .map(category => ({
      ...category,
      value: category.id,
    }))
  const dynamicTags = tags.map(({ id, tagDisplayName }) => ({
    id,
    name: tagDisplayName,
    value: id,
  }))
  return {
    boostPastPurchases,
    dynamicBrands,
    dynamicAttributes,
    dynamicCategories,
    dynamicTags,
  }
}

const findById = (items, id) => items?.find(({ id: itemId }) => itemId === id)

const findByValue = (items, value) => items?.find(({ value: itemValue }) => itemValue === value)

const getAttributeId = (attributes, key) =>
  attributes?.find(({ key: attributeKey }) => attributeKey === key)?.id

const getDefaultSortByLabel = ({ boostPastPurchases, dynamicAttributes, rules }) => {
  let label = 'None'
  if (boostPastPurchases) {
    label = 'Frequency'
  } else {
    const id = getAttributeId(dynamicAttributes, 'PAST_PURCHASED')
    if (rules?.some(({ attribute }) => attribute === id)) {
      label = 'Past Purchased'
    }
  }
  return label
}

export const getSortByItems = state =>
  sortByItems.map(({ label, value }) => ({
    label: value === null ? getDefaultSortByLabel(state) : label,
    value,
  }))

class CollectionFormProducts extends Component {
  static sortByItems = [
    { value: null, label: 'Default' },
    { value: 'popularity', label: 'Popularity' },
    { value: 'recently_added', label: 'Recently Added' },
    { value: 'recommended', label: 'Recommended' },
  ]

  defaultDynamicKeys = {
    editableRule: null,
    dynamicBrandKey: null,
    dynamicCategoryKey: null,
    dynamicAttributeKey: null,
    dynamicTagKey: null,
    brandRuleFilterEnabled: false,
    categoryRuleFilterEnabled: false,
    tagRuleFilterEnabled: false,
    hasOfferRuleFilterEnabled: false,
    topSellingRuleFilterEnabled: false,
  }

  state = {
    ...this.defaultDynamicKeys,
    error: null,
    products: [],
    orderOverride: '',
    searchInputValue: '',
    searchKey: null,
    warning: null,
    rules: [],
    boostPastPurchases: null,
    dynamicError: null,
    dynamicBrands: [],
    dynamicCategories: [],
    dynamicAttributes: [],
    dynamicTags: [],
    pastPurchasedFilterEnabled: false,
  }

  newRule = {
    brand: null,
    category: null,
    attribute: null,
    tag: null,
    hasOffer: null,
    topSelling: null,
  }

  async componentWillMount() {
    const addProductOptionValue = localStorage.getItem(ADD_PRODUCT_OPTION_VALUE_KEY)
    const searchKey =
      addProductOptions.find(({ value }) => value === addProductOptionValue) ?? addProductOptions[0]

    this.setState({
      products: this.props.formikProps.values.products,
      rules: this.props.formikProps.values.rules,
      orderOverride: this.props.formikProps.values.orderOverride,
      searchKey,
    })

    const data = await fetchData()
    const pastPurchasedAttributeId = getAttributeId(data.dynamicAttributes ?? [], 'PAST_PURCHASED')
    const pastPurchasedFilterEnabled = this.props.formikProps.values.rules?.some(
      ({ attribute }) => attribute === pastPurchasedAttributeId
    )
    this.setState({ ...data, pastPurchasedFilterEnabled })
  }

  createTableActions = (item, description, handleRemove) => {
    return (
      <ButtonIconAction
        description={description}
        icon="delete"
        onClick={() => handleRemove(item)}
      />
    )
  }

  createBulkTableActions = () => {
    return (
      <DropdownMenu
        ariaLabel="More user actions"
        menuAlignment="right"
        triggerRender={({ description, isOpen, onClick }) => (
          <ButtonToggle
            icon="more"
            id={`collection-products-remove-all-action`}
            isToggled={isOpen}
            onClick={onClick}
            untoggledDescription={description}
          />
        )}
      >
        <DropdownMenuItem
          icon="delete"
          kind="danger"
          renderButton={({ buttonProps, iconEl }) => {
            return (
              <Modal.Confirmation
                handleCloseModal={({ wasConfirmed }) => {
                  if (wasConfirmed) {
                    this.removeAllProducts()
                  }
                }}
                triggerRender={({ openModal }) => (
                  <button
                    {...buttonProps}
                    // Overwrite buttonProps' `onClick`, we don't want to close the dropdown menu
                    // because the confirmation modal would be unmounted
                    onClick={() => {
                      openModal()
                    }}
                  >
                    {iconEl}
                    Remove All
                  </button>
                )}
                contentProps={{
                  actionText: 'remove all products from collection',
                  confirmButtonText: 'Delete',
                }}
              />
            )
          }}
        />
      </DropdownMenu>
    )
  }

  removeProduct = item => {
    const updatedProducts = this.state.products.filter(product => product !== item)

    this.setProductValue(updatedProducts)
    this.setState({ products: updatedProducts })
  }

  reorderProducts = (sourceIndex, destinationIndex) => {
    const updatedProducts = reorder(this.state.products, sourceIndex, destinationIndex)
    this.setProductValue(updatedProducts)
    this.setState({ products: updatedProducts })
  }

  removeAllProducts = () => {
    const updatedProducts = []

    this.setProductValue(updatedProducts)
    this.setState({ products: updatedProducts })
  }

  searchForProducts = inputValue => {
    if (inputValue.length > 0) {
      const keyType = this.state.searchKey.value
      // Convert input value to array
      // Replace multiple spaces with a single space
      const inputArray = inputValue
        ? inputValue
            .replace(/\s\s+/g, ' ')
            .split(COPY_STRING_DELIMETER)
            .map(keyValue => ({ key: keyValue }))
            .filter(keyValue => keyValue.key !== '')
        : []

      this.setState({ isSearchingProducts: true, error: '', warning: '' })

      // Checks for duplicate items in the input
      const inputSet = new Set([])
      const duplicateSet = new Set(
        inputArray
          .filter(({ key }) => {
            if (!inputSet.has(key)) {
              inputSet.add(key)
              return false
            }
            return true
          })
          .map(({ key }) => key)
      )

      // Prevents search when duplicate items exist in the user input
      if (duplicateSet.size > 0) {
        this.setState({
          isSearchProducts: false,
          error: `Duplicate item: ${Array.from(duplicateSet).join(', ')}`,
        })
        return
      }

      searchProducts({
        keyType,
        items: inputArray,
      }).then(({ items: products }) => {
        // Create an array of products that already exist in the table
        // May be used the future if error messages need to be split
        const existingProducts = []

        // Get an array of products to add to the table
        const validProducts = products.filter(({ product }) => product)
        const invalidProducts = products
          .filter(({ product }) => product === null)
          .map(({ key }) => key)
        // Returns the UPC code without the prefix (#_)
        // Helper method used when comparing upc with and without prefix
        const parseUPC = upcWithPrefix => {
          const parts = upcWithPrefix.split(UPC_PREFIX_DELIMITER)
          return parts[parts.length - 1]
        }

        // Create an array of UPCs/SKUs/RRCs that don't have products associated with them
        inputArray
          .map(({ key }) => key)
          .forEach(key => {
            const isKeyInvalid = validProducts.every(({ product }) => {
              if (keyType === 'upc') {
                return false // Ignore cases where products have multiple UPCs
                // and the UPC that’s returned from multi_search is different from the one searched
              }
              return product[keyType] !== key
            })
            if (isKeyInvalid) {
              invalidProducts.push(key)
            }
          })

        // Stores valid and Non duplicate prducts
        const newProductsMap = new Map()

        if (validProducts.length > 0) {
          validProducts.forEach(({ key, product }) => {
            let alreadyExists = false
            if (keyType === 'upc') {
              alreadyExists = this.state.products.some(oldProduct =>
                oldProduct.upcs.some(upc => product.upcs.includes(upc))
              )
            } else {
              alreadyExists = this.state.products.some(
                oldProduct => oldProduct[keyType] === product[keyType]
              )
            }

            if (alreadyExists) {
              if (keyType === 'upc') {
                // the key should be the UPC in this case
                existingProducts.push(key)
              } else {
                existingProducts.push(product[keyType])
              }
              // Doesn't already exist
            } else if (keyType === 'upc') {
              newProductsMap.set(parseUPC(key), product)
            } else {
              newProductsMap.set(product[keyType], product)
            }
          })

          // Ensure order

          const newOrderedProducts = [...this.state.products]

          if (keyType === 'upc') {
            const newProducts = newProductsMap.values()
            newOrderedProducts.push(...newProducts)
          } else {
            inputArray.forEach(({ key }) => {
              const product = newProductsMap.get(key)
              if (product) {
                newOrderedProducts.push(product)
              }
            })
          }

          this.setProductValue(newOrderedProducts)

          this.setState({
            error: invalidProducts.length > 0 ? `Not found: ${invalidProducts.join(', ')}` : null,
            isSearchingProducts: false,
            products: newOrderedProducts,
            searchInputValue: '',
            warning:
              existingProducts.length > 0
                ? `Duplicate item already added: ${existingProducts.join(', ')}`
                : null,
          })
        } else {
          this.setState({
            error: `No products found based on the ${this.state.searchKey.value.toUpperCase()}s entered.`,
            searchInputValue: '',
            isSearchingProducts: false,
          })
        }
      })
    }
  }

  setProductValue = value => {
    this.props.formikProps.setFieldValue('products', value)
  }

  isNonEmptyRule = rule => {
    const { attribute, ...rest } = rule
    return Object.values(rest).some(value => value !== null)
  }

  setRulesValue = value => {
    const pastPurchasedAttributeId = getAttributeId(this.state.dynamicAttributes, 'PAST_PURCHASED')
    // preprocess to make sure all rules reflect the selection of past purchased filter
    if (this.state.pastPurchasedFilterEnabled && value.length === 0) {
      // There needs to be at least one rule that is past purchased, so add one if there are none
      value = [{ ...this.newRule, attribute: pastPurchasedAttributeId }]
    } else {
      value = value
        .map(rule => ({
          ...rule,
          attribute: this.state.pastPurchasedFilterEnabled ? pastPurchasedAttributeId : null,
        }))
        .filter(this.isNonEmptyRule)
    }

    this.setState({
      dynamicError: '',
      rules: value,
      ...this.defaultDynamicKeys,
    })

    this.props.formikProps.setFieldValue('rules', value)
  }

  setDynamicValue = value => {
    this.props.formikProps.setFieldValue('dynamic', value)
  }

  setSortByValue = value => {
    this.props.formikProps.setFieldValue('sortBy', value)
  }

  renderDefaultView = () => {
    const { error, warning, products, orderOverride } = this.state
    const { classes, pageId, formikProps } = this.props
    return (
      <div>
        <div className={classes.addProductsContainer}>
          <div className={classes.addProductsTypeContainer}>
            <Dropdown
              id={`${pageId}-product-collection-type`}
              items={addProductOptions}
              onChange={value => {
                const findOption = addProductOptions.find(type => type.value === value)
                this.setState({ searchKey: findOption })
                localStorage.setItem(ADD_PRODUCT_OPTION_VALUE_KEY, value)
              }}
              placeholder="Add product by"
              selectedValue={this.state.searchKey.value}
            />
          </div>
          <SearchInput
            icon="plus"
            id={`${pageId}-product-collection-add`}
            onChange={searchInputValue => {
              this.setState({ searchInputValue })
            }}
            onSubmit={searchValue => {
              this.searchForProducts(searchValue)
            }}
            placeholder={`Enter one or more ${this.state.searchKey.value.toUpperCase()}s separated by commas or space`}
            value={this.state.searchInputValue}
          />
        </div>

        {error ? (
          <div className={classes.productErrorContainer}>
            <DismissibleNotification
              kind="error"
              message={this.state.error}
              handleDismiss={() => this.setState({ error: '' })}
            />
          </div>
        ) : null}
        {warning ? (
          <div className={classes.productErrorContainer}>
            <DismissibleNotification
              kind="warning"
              message={this.state.warning}
              handleDismiss={() => this.setState({ warning: '' })}
            />
          </div>
        ) : null}
        <FormFieldItem
          id={`${pageId}-edit-products`}
          name="products"
          render={({ id, name, onBlur, value, ...renderProps }) => {
            return (
              <CollectionProductsWithState
                actions={item =>
                  this.createTableActions(item, 'Remove Product', this.removeProduct)
                }
                bulkAction={() => this.createBulkTableActions()}
                pageId={pageId}
                items={products}
                reorderProducts={this.reorderProducts}
                setFieldValue={formikProps.setFieldValue}
                orderOverride={orderOverride}
              />
            )
          }}
        />
      </div>
    )
  }

  addUpdateRule = () => {
    const {
      rules,
      editableRule,
      dynamicBrandKey,
      dynamicCategoryKey,
      dynamicAttributeKey,
      dynamicTagKey,
      brandRuleFilterEnabled,
      categoryRuleFilterEnabled,
      tagRuleFilterEnabled,
      hasOfferRuleFilterEnabled,
      topSellingRuleFilterEnabled,
    } = this.state

    if (
      !editableRule &&
      rules.some(
        rule =>
          rule.brand === (dynamicBrandKey?.value ?? null) &&
          rule.category === (dynamicCategoryKey?.value ?? null) &&
          rule.attribute === (dynamicAttributeKey?.id ?? null) &&
          rule.tag === (dynamicTagKey?.value ?? null) &&
          rule.hasOffer === (hasOfferRuleFilterEnabled || null) &&
          rule.topSelling === (topSellingRuleFilterEnabled || null)
      )
    ) {
      this.setState({ dynamicError: 'This rule has already been added' })
      return
    }

    const rule = {
      ...this.newRule,
      brand: brandRuleFilterEnabled ? dynamicBrandKey?.value ?? null : null,
      category: categoryRuleFilterEnabled ? dynamicCategoryKey?.value ?? null : null,
      attribute: categoryRuleFilterEnabled ? dynamicAttributeKey?.id ?? null : null,
      tag: tagRuleFilterEnabled ? dynamicTagKey?.id ?? null : null,
      hasOffer: hasOfferRuleFilterEnabled || null,
      topSelling: topSellingRuleFilterEnabled || null,
    }

    let newRules
    if (editableRule) {
      const index = rules.indexOf(editableRule)
      if (index === -1) {
        newRules = rules
      } else {
        newRules = [...rules.slice(0, index), rule, ...rules.slice(index + 1)]
      }
    } else {
      newRules = [...rules, rule]
    }

    this.setRulesValue(newRules)
  }

  getDefaultSortByLabel = () => {
    let label = 'None'
    if (this.state.boostPastPurchases) {
      label = 'Frequency'
    } else if (this.state.pastPurchasedFilterEnabled) {
      label = 'Past Purchased'
    }
    return `Default (${label})`
  }

  renderDynamicView = () => {
    const { pageId, classes } = this.props
    const {
      rules,
      editableRule,
      brandRuleFilterEnabled,
      categoryRuleFilterEnabled,
      tagRuleFilterEnabled,
      hasOfferRuleFilterEnabled,
      topSellingRuleFilterEnabled,
      dynamicAttributeKey,
      dynamicCategoryKey,
      dynamicBrandKey,
      dynamicTagKey,
      dynamicAttributes,
      dynamicBrands,
      dynamicCategories,
      dynamicTags,
      dynamicError,
      pastPurchasedFilterEnabled,
    } = this.state
    const sortByItems = getSortByItems(this.state)

    const pastPurchasedAttributeId = getAttributeId(dynamicAttributes, 'PAST_PURCHASED')
    const productsAttributeId = getAttributeId(dynamicAttributes, 'PRODUCTS')

    return (
      <div className={classes.dynamicRulesSection}>
        <h4 className={classes.createCollectionTitle}>Dynamic Rules</h4>
        <p className={classes.addRulesHelpText}>
          Add one or more rules to define how products should dynamically be added to your
          collection.
        </p>
        <br />
        <h5 className={classes.createCollectionSubtitle}>Product-Level Filters</h5>
        <p className={classes.addRulesHelpText}>Use these filters to create product-level rules.</p>
        <div className={classes.addRulesContainer}>
          <CollectionFormRuleAccordion
            heading={'Filter by Brand'}
            isFirst
            isOpen={brandRuleFilterEnabled}
            onChange={() => {
              this.setState(({ brandRuleFilterEnabled }) => ({
                brandRuleFilterEnabled: !brandRuleFilterEnabled,
                dynamicBrandKey: null,
              }))
            }}
          >
            <div className={classes.addProductsTypeContainer}>
              <DropdownFilterable
                id={`${pageId}-brand`}
                placeholder="Select a brand"
                items={sortBy(dynamicBrands, 'value').map(({ id, value }) => ({
                  id,
                  value,
                  label: value,
                }))}
                onChange={value => {
                  this.setState({ dynamicBrandKey: findByValue(dynamicBrands, value) })
                }}
                selectedValue={dynamicBrandKey?.value ?? null}
              />
            </div>
          </CollectionFormRuleAccordion>
          <CollectionFormRuleAccordion
            heading={'Filter by Category'}
            isOpen={categoryRuleFilterEnabled}
            onChange={() => {
              this.setState(({ categoryRuleFilterEnabled }) => ({
                categoryRuleFilterEnabled: !categoryRuleFilterEnabled,
                dynamicCategoryKey: null,
                dynamicAttributeKey: null,
              }))
            }}
          >
            <div className={classes.addProductsTypeContainer}>
              <DropdownFilterable
                id={`${pageId}-category`}
                placeholder="Select a category"
                items={sortBy(dynamicCategories, 'name').map(({ id, name, value }) => ({
                  id,
                  value,
                  label: name,
                }))}
                onChange={value => {
                  this.setState({
                    dynamicAttributeKey: productsAttributeId,
                    dynamicCategoryKey: findById(dynamicCategories, value),
                  })
                }}
                selectedValue={dynamicCategoryKey?.value ?? null}
              />
            </div>
          </CollectionFormRuleAccordion>
          <CollectionFormRuleAccordion
            heading={'Filter by Tag'}
            isOpen={tagRuleFilterEnabled}
            onChange={() => {
              this.setState(({ tagRuleFilterEnabled }) => ({
                tagRuleFilterEnabled: !tagRuleFilterEnabled,
                dynamicTagKey: null,
              }))
            }}
          >
            <div className={classes.addProductsTypeContainer}>
              <DropdownFilterable
                id={`${pageId}-tag`}
                placeholder="Select a tag"
                items={sortBy(dynamicTags, 'name').map(({ id, name, value }) => ({
                  id,
                  value,
                  label: name,
                }))}
                onChange={value => {
                  this.setState({ dynamicTagKey: findByValue(dynamicTags, value) })
                }}
                selectedValue={dynamicTagKey?.value ?? null}
              />
            </div>
          </CollectionFormRuleAccordion>
          <CollectionFormRuleAccordion
            heading={'Filter by Offer Applied'}
            isOpen={hasOfferRuleFilterEnabled}
            onChange={() => {
              this.setState(({ hasOfferRuleFilterEnabled }) => ({
                hasOfferRuleFilterEnabled: !hasOfferRuleFilterEnabled,
              }))
            }}
          />
          <div className={classes.dynamicRulesFooter}>
            <Button
              size="regular"
              icon={editableRule ? 'check' : 'plus'}
              disabled={
                (!dynamicCategoryKey &&
                  !dynamicAttributeKey &&
                  !dynamicBrandKey &&
                  !dynamicTagKey &&
                  !hasOfferRuleFilterEnabled &&
                  !topSellingRuleFilterEnabled) ||
                (!brandRuleFilterEnabled &&
                  !categoryRuleFilterEnabled &&
                  !tagRuleFilterEnabled &&
                  !hasOfferRuleFilterEnabled &&
                  !topSellingRuleFilterEnabled)
              }
              onClick={() => {
                this.addUpdateRule()
              }}
            >
              {editableRule ? 'Update Rule' : 'Add Rule'}
            </Button>
          </div>
        </div>
        {dynamicError ? (
          <div className={classes.productErrorContainer}>
            <DismissibleNotification kind="error" message={this.state.dynamicError} />
          </div>
        ) : null}
        <h5 className={classes.createCollectionSubtitle}>Preview Your Rules Created</h5>
        <p className={classes.addRulesHelpText}>Preview the set of rules for your collection.</p>
        <FormFieldItem
          id={`${pageId}-edit-productrules`}
          name="productrules"
          render={({ id, name, onBlur, value, ...renderProps }) => {
            return (
              <CollectionProductsRulesWithState
                onChange={updatedRules => {
                  if (editableRule) {
                    const removedRules = rules.filter(rule => !updatedRules.includes(rule))
                    if (removedRules.includes(editableRule)) {
                      this.setState(this.defaultDynamicKeys)
                    }
                  }

                  this.setRulesValue(updatedRules)
                }}
                onEdit={rule => {
                  this.setState({
                    editableRule: rule,
                    brandRuleFilterEnabled: rule.brand !== null,
                    dynamicBrandKey: findByValue(dynamicBrands, rule.brand),
                    categoryRuleFilterEnabled: rule.category !== null,
                    dynamicCategoryKey: findById(dynamicCategories, rule.category),
                    dynamicAttributeKey: findByValue(dynamicAttributes, rule.attribute),
                    tagRuleFilterEnabled: rule.tag !== null,
                    dynamicTagKey: findByValue(dynamicTags, rule.tag),
                    hasOfferRuleFilterEnabled: rule.hasOffer === true,
                  })
                }}
                pageId={pageId}
                items={rules.filter(this.isNonEmptyRule)}
                dynamicCategories={dynamicCategories}
                dynamicAttributes={dynamicAttributes}
                dynamicTags={dynamicTags}
              />
            )
          }}
        />
        <FormFieldItem
          className={classes.formInput}
          id={`${pageId}-product-collection-dynamic-order`}
          labelText="Sort By"
          name="sortBy"
          render={({ value, ...renderProps }) => (
            <Dropdown
              {...renderProps}
              items={sortByItems.map(({ label, value }) => ({
                id: value,
                label,
                value,
              }))}
              placeholder={sortByItems.find(({ value }) => !value)?.label}
              selectedValue={value}
              onChange={newValue => {
                this.setSortByValue(newValue)
              }}
            />
          )}
        />
        <h5 className={classes.createCollectionSubtitle}>Optional Filters</h5>
        <p className={classes.addRulesHelpText}>
          Set a maximum number of products for your collection, or only display products the user
          has purchased before.
        </p>
        <FormFieldItem
          className={classes.formInput}
          id={`${pageId}-product-collection-past-purchased`}
          labelText="User Personalization"
          name="pastPurchased"
          render={props => (
            <>
              <Tooltip text="If unchecked, the collection will display all types of products and filter by global user behavior.">
                <Icon name="info" className={classes.tooltipIcon} />
              </Tooltip>
              <Checkbox
                {...props}
                checked={pastPurchasedFilterEnabled}
                onChange={({ target }) => {
                  this.setState({ pastPurchasedFilterEnabled: target.checked }, () => {
                    // Update rules with new past purchased value
                    this.setRulesValue(rules)
                  })
                }}
                labelText="Display only products that the user has purchased in the past"
              />
            </>
          )}
        />
        <FormFieldItem
          className={classes.formInput}
          id={`${pageId}-product-collection-max-count`}
          labelText="Maximum Number of Products in Collection"
          name="maxCount"
          render={props => (
            <>
              <Tooltip text="If left blank, the collection will display the entire list of products available in the catalog.">
                <Icon name="info" className={classes.tooltipIcon} />
              </Tooltip>
              <TextInput {...props} placeholder="Unlimited" type="number" />
            </>
          )}
        />
      </div>
    )
  }

  render() {
    const { classes, key, pageId, sectionName, formikProps } = this.props
    return (
      <div className={classes.createCollectionContainer} key={key}>
        <h5 className={classes.createCollectionTitle}>{sectionName}</h5>
        <div className={classes.formRowWithMarginBottom}>
          <FormFieldItem
            className={classes.formInput}
            id={`${pageId}-product-collection-dynamic-selection`}
            labelText="Collection Type"
            name="dynamic"
            render={({ id, value }) => {
              return (
                <RadioButtons
                  id={id}
                  onChange={newDynamic => {
                    this.setDynamicValue(newDynamic)
                  }}
                  orientation="horizontal"
                  radioButtonProps={[
                    {
                      id: 'not-dynamic',
                      labelText: 'Default',
                      value: false,
                    },
                    {
                      id: 'is-dynamic',
                      labelText: 'Dynamic',
                      hasTooltip: true,
                      tooltipText:
                        'Dynamic collections are based on the active user, and so there will not be a preview or list of items and images',
                      value: true,
                    },
                  ]}
                  selectedValue={value}
                />
              )
            }}
          />
        </div>
        {formikProps.values.dynamic ? this.renderDynamicView() : this.renderDefaultView()}
      </div>
    )
  }
}

export default injectSheet(styles)(CollectionFormProducts)
