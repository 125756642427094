import { Formik } from 'formik'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { getRouteTo } from 'routing'
import { fetchGlobalSearchSetting, updateGlobalSearchSetting } from 'api'
import { LoadingIndicator, PageHeader } from 'components'
import { PageHeaderBanner } from 'components/PageHeaderBanner/PageHeaderBanner'

import { createToast } from 'modules/toasts'

import { ItemFetcher } from 'utils'

import GlobalSettingsDetailForm from './GlobalSettingsDetailForm'

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createToast,
    },
    dispatch
  )

export const EditGlobalSearchSettings = connect(
  null,
  mapDispatchToProps
)(
  ({
    history,
    location,
    match: {
      params: { keywordRuleDetailId },
    },
    createToast,
    ...restProps
  }) => (
    <ItemFetcher
      queryKey="globalSearchSetting"
      fetchItem={config => fetchGlobalSearchSetting(config)}
      render={({
        item: globalSearchSettings,
        isLoadingItem: isFetchingKeywordRuleDetail,
        refetch,
      }) => {
        if (isFetchingKeywordRuleDetail) {
          return <LoadingIndicator withTopMargin />
        }

        const onFormSubmit = formValues => {
          return (
            updateGlobalSearchSetting(formValues)
              // TODO: Look at debouncing this somehow
              .then(() => createToast({ kind: 'success', message: 'Saved changes!' }))
              // TODO: Figure out the right messaging here
              .catch(err =>
                createToast({ kind: 'error', message: `Error while saving changes: ${err}` })
              )
          )
        }

        return (
          globalSearchSettings && (
            <Formik
              initialValues={globalSearchSettings}
              validateOnBlur={false}
              validateOnChange={false}
              onSubmit={(values, form) => {
                onFormSubmit(values)
                  .then(() => {
                    form.setSubmitting(false)
                  })
                  .catch(error => {
                    form.setSubmitting(false)
                    form.setErrors({ global: error.message })
                  })
              }}
              render={formikProps => {
                return (
                  <>
                    <PageHeaderBanner
                      kind="info"
                      message="Note: These settings only apply to browse and the legacy search experience. If
                      you are using OneSearch on your platform, these rules will not apply."
                    />
                    <PageHeader headerTitle="Global Search Settings" />
                    <GlobalSettingsDetailForm
                      {...formikProps}
                      onFormSubmit={onFormSubmit}
                      cancelDestination={getRouteTo('searchSettings.keywordRules')}
                    />
                  </>
                )
              }}
            />
          )
        )
      }}
    />
  )
)
