import React from 'react'

import { getRoutePathname } from 'routing'
import { snakeToKebab } from 'utils'

import Fact from './Fact'
import EditFact from './EditFact'

const FACT_TYPES = [
  'chain_notifications',
  'fees',
  'loyalty_cards',
  'stores',
  'timeslots',
  'session_throttling',
  'coffee-editing',
]

const routeDefinitions = []
FACT_TYPES.forEach(type => {
  const kebabType = snakeToKebab(type)
  routeDefinitions.push({
    path: getRoutePathname('facts', { key: kebabType }),
    component: () => <Fact factKey={type} />,
  })
  routeDefinitions.push({
    path: getRoutePathname('facts.editFact', { key: kebabType }),
    component: history => <EditFact factKey={type} history={history} />,
  })
})

export default routeDefinitions
