import PropTypes from 'prop-types'
import React from 'react'
import injectSheet from 'react-jss'

import { FormItem, FormLabel, FormRequirement } from 'components'
import { mixins } from 'styling'

const styles = theme => ({
  multiInputContainer: {
    ...mixins.multiField,
    '& > *': {
      ...mixins.multiFieldChild,
    },
  },
})

const MultiInput = ({ children, classes, errorId, invalidText, labelText }) => {
  const label = labelText ? <FormLabel labelText={labelText} /> : null

  return (
    <FormItem>
      {label}
      <div className={classes.multiInputContainer}>{children}</div>
      <FormRequirement id={errorId} invalidText={invalidText} />
    </FormItem>
  )
}

MultiInput.propTypes = {
  errorId: PropTypes.string.isRequired,
  invalidText: PropTypes.string,
  labelText: PropTypes.string,
}

export default injectSheet(styles)(MultiInput)
