import React from 'react'
import injectSheet from 'react-jss'

import { mixins } from 'styling'

const styles = theme => ({
  readOnlyValueHeading: {
    ...mixins.labelText({ theme }),
    cursor: 'default',
    marginBottom: theme.spacing.sm,

    '&:not(:first-child)': {
      marginTop: theme.spacing.xxlg,
    },
  },
})

const ReadOnlyValueHeading = ({ classes, labelText }) => (
  <div className={classes.readOnlyValueHeading}>{labelText}</div>
)

export default injectSheet(styles)(ReadOnlyValueHeading)
