export default theme => ({
  pageContainer: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: 1136,
    margin: '0 auto',
  },
  stickyMenu: {
    display: 'flex',
  },
  createCollectionMenu: {
    alignSelf: 'flex-start',
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'flex-end',
    marginRight: theme.spacing.md,
    paddingTop: theme.spacing.lg,
    position: 'sticky',
    top: 90,
    width: 176,
    '& > li': {
      marginBottom: theme.spacing.md,
      position: 'relative',
      '&:before': {
        backgroundColor: theme.borderGreyLight,
        content: '""',
        height: 32,
        left: 1,
        position: 'absolute',
        bottom: 3,
        width: 2,
      },
      '& > a': {
        color: theme.text01,
        display: 'block',
        fontSize: theme.fontSize.p,
        fontFamily: theme.fontFamilySansSerif,
        paddingLeft: theme.spacing.md,
        textDecoration: 'none',
        '&:before': {
          backgroundColor: theme.borderGreyLight,
          borderRadius: 8,
          content: '""',
          height: 8,
          left: '-2px',
          position: 'absolute',
          top: 3,
          width: 8,
        },
        active: {
          color: theme.brand01,
        },
      },
      '&.scroll-active': {
        '& ~ li': {
          fontWeight: 600,
          '& > a': {
            color: theme.text01,
            fontWeight: 600,
            '&:before': {
              backgroundColor: theme.text01,
            },
          },
        },
        '& > a': {
          color: theme.brand00,
          fontWeight: 600,
          '&:before': {
            backgroundColor: theme.brand00,
          },
        },
      },
      '&:last-child': {
        '&:before': {
          height: 0,
        },
      },
    },
  },
  contentContainer: {
    width: '100%',
  },
})
