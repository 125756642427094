import navbarLinks from 'pages/Dashboard/navbarLinks'

const links = navbarLinks()

const initialState = {
  links,
  dynamicLinks: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'NAVIGATION_UPDATE':
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
