import React from 'react'
import injectSheet from 'react-jss'

import { Button, ReadOnlyValueCard } from 'components'

import { formatDatetime } from 'utils'
import { reorder } from 'components/DataTable/utils/reorder'

import EditGlobalRecipeSearchSettingsModal from './EditGlobalRecipeSearchSettingsModal'

import { KeywordTags, KeywordCategories, DisabledFeatureWarning } from '../KeywordRules/components'
import styles from '../KeywordRules/KeywordRuleDetail.styles'

const BASE_ID = 'edit-global-recipe-search-settings'

function formatToggleForDisplay(val, { enabledText = 'Enabled', disabledText = 'Disabled' }) {
  return val ? enabledText : disabledText
}

const GlobalSearchSettingsDetailForm = ({
  classes,
  handleSubmit,
  initialValues,
  setFieldValue,
  values,
  setValues,
  onFormSubmit,
}) => {
  return (
    <form className={classes.formContainer} onSubmit={handleSubmit}>
      <div className={classes.underHeaderContainer}>
        <div className={classes.leftSide}>
          <ReadOnlyValueCard
            detailValues={[
              {
                id: `${BASE_ID}-detail-boost-saved-recipes`,
                labelText: 'Saved Recipes',
                value:
                  values &&
                  formatToggleForDisplay(values.boostSavedRecipes, {
                    enabledText: 'Boosted',
                    disabledText: 'Not Boosted',
                  }),
                action: (
                  <EditGlobalRecipeSearchSettingsModal
                    handleSubmit={values => {
                      return onFormSubmit(values).then(() => setValues(values))
                    }}
                    triggerRender={({ openModal }) => (
                      <Button kind="primary" icon="edit" size="x-small" onClick={openModal} />
                    )}
                    values={values}
                  />
                ),
              },
              {
                id: `${BASE_ID}-detail-boosted-count`,
                labelText: 'Boosted Categories',
                value: values && values.boostCategories ? values.boostCategories.length : 0,
              },
              {
                id: `${BASE_ID}-detail-boosted-tags`,
                labelText: 'Boosted Tags',
                value: values && values.boostTags ? values.boostTags.length : 0,
              },
              {
                id: `${BASE_ID}-detail-updated-on`,
                labelText: 'Last Edited',
                value: formatDatetime(values.modified) || 'n/a',
              },
            ]}
            noPaddingTop
          />

          <div className={classes.updateButtonContainer}>
            <EditGlobalRecipeSearchSettingsModal
              handleSubmit={values => {
                return onFormSubmit(values).then(() => setValues(values))
              }}
              triggerRender={({ openModal }) => (
                <Button kind="primary" onClick={openModal}>
                  Update Settings
                </Button>
              )}
              values={values}
            />
          </div>
        </div>
        <div className={classes.rightSide}>
          <h3 className={classes.header}>
            Boosted Categories {!values.enableBoostCategories && <DisabledFeatureWarning />}
          </h3>
          <KeywordCategories
            componentId={`${BASE_ID}-boosted`}
            componentName="boostCategories"
            setFieldValue={setFieldValue}
            onChange={handleSubmit}
            draggable
            onDragEnd={({ source, destination }) => {
              if (!source || !destination) return
              const reordered = reorder(values.boostCategories, source.index, destination.index)
              setFieldValue('boostCategories', reordered)
              handleSubmit()
            }}
            confirmClearAll
            disabled={!values.enableBoostCategories}
            limit={3}
            modalTip="You can select up to 3 categories for boosting."
            keywordType="recipe"
          />
          <h3 className={classes.header}>
            Boosted Tags {!values.enableBoostTags && <DisabledFeatureWarning />}
          </h3>
          <KeywordTags
            componentId={`${BASE_ID}-boosted-tags`}
            componentName="boostTags"
            setFieldValue={setFieldValue}
            handleSubmit={handleSubmit}
            limit={3}
            modalTip="You can select up to 3 tags for boosting."
            values={values}
            disabled={!values.enableBoostTags}
            confirmClearAll
          />
        </div>
      </div>
    </form>
  )
}

export default injectSheet(styles)(GlobalSearchSettingsDetailForm)
