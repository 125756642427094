export default theme => ({
  listBoxMenu: {
    zIndex: theme.zIndex.dropdown,
    backgroundColor: '#FFFFFF',
    boxShadow: theme.dropdownMenuBoxShadow,
    maxHeight: 220,
    minWidth: '100%',
    overflowY: 'auto',
    padding: `${theme.spacing.xs} 0`,
    position: 'absolute',
    top: 32, // 32px input height
    margin: `${theme.spacing.xxs} 0`, // Spacing between ListBoxMenu and ListBoxField (or whatever used as trigger)
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
})
