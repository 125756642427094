export default tokens => ({
  filtersAndActions: {
    display: 'flex',
    marginBottom: tokens.size.spacing.large,
  },
  filters: {
    display: 'flex',
    flexGrow: 1,

    '& > *': {
      marginBottom: 0,
      maxWidth: 230,
      marginRight: tokens.size.spacing.xxSmall,

      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  actions: {
    alignItems: 'center',
    display: 'flex',
    marginLeft: tokens.size.spacing.large,

    '& > *': {
      marginRight: tokens.size.spacing.xSmall,

      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  tableActions: {
    alignItems: 'center',
    display: 'flex',

    '& > *': {
      marginRight: tokens.size.spacing.xSmall,

      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  tableContent: {
    lineHeight: tokens.size.lineHeight.body,
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
  },
  statusButton: {
    width: 156,
  },
  tabContainer: {
    paddingBottom: tokens.size.spacing.xSmall,
  },
})
