import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import injectSheet from 'react-jss'

import { Icon, Tooltip } from 'components'

import styles from './Button.styles'

const ButtonIconAction = ({
  classes,
  className,
  description,
  disabled: isDisabled,
  icon,
  id,
  onClick,
}) => {
  const buttonClasses = cx(className, classes.button, classes.buttonIconAction, {
    [classes.buttonIconActionClose]: icon === 'close',
    [classes.buttonIconActionDelete]: icon === 'delete',
  })

  return (
    <Tooltip
      text={description}
      tooltipRender={({ tooltip, triggerClassName, wrapperClassName }) => (
        <span className={wrapperClassName}>
          <button
            aria-label={description}
            className={cx(buttonClasses, triggerClassName)}
            disabled={isDisabled}
            id={id}
            onClick={onClick}
            type="button"
          >
            {tooltip}
            <Icon name={icon} className={cx(classes.buttonIcon)} />
          </button>
        </span>
      )}
    />
  )
}
ButtonIconAction.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  description: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export { ButtonIconAction as ButtonIconActionUnStyled }
export default injectSheet(styles)(ButtonIconAction)
