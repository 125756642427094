import PropTypes from 'prop-types'
import React from 'react'
import injectSheet from 'react-jss'

import { Icon } from 'components'

const styles = theme => ({
  listBoxIcon: {
    fill: theme.brand01,
    flexShrink: 0,
    height: 16,
    marginRight: theme.spacing.xs,
    width: 16,
  },
})

/**
 * `ListBoxIcon` is used to show an icon to the left of the ListBox
 * if `name` is defined
 */
const ListBoxIcon = ({ classes, name }) =>
  name ? <Icon className={classes.listBoxIcon} name={name} /> : null

ListBoxIcon.propTypes = {
  name: PropTypes.string,
}

export default injectSheet(styles)(ListBoxIcon)
