import React from 'react'
import injectSheet from 'react-jss'
import { Formik } from 'formik'
import { BooleanValue } from 'react-values'

import Styling from 'styling/components'
import { Modal, Button, Checkbox, FormGroup } from 'components'

const styles = theme => ({
  buttonRow: {},
  modalFormGroup: {
    marginBottom: theme.spacing.sm,
  },
})

const BASE_ID = 'edit-global-search-settings'

const EditGlobalSearchSettingsModalContent = ({
  initialValues: values,
  closeModal,
  handleSubmit,
  classes,
}) => {
  return (
    <Formik
      initialValues={values}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={(values, form) => {
        handleSubmit(values, form)
          .then(() => {
            form.setSubmitting(false)
            return closeModal()
          })
          .catch(error => {
            form.setSubmitting(false)
            form.setErrors({ global: error.message })
          })
      }}
      render={({ setFieldValue, values, submitForm }) => {
        return (
          <>
            <FormGroup
              label="Personalization"
              orientation="vertical"
              className={classes.modalFormGroup}
              items={[
                <BooleanValue
                  value={values.boostSavedRecipes}
                  onChange={value => setFieldValue('boostSavedRecipes', value)}
                >
                  {({ toggle, value }) => (
                    <Checkbox
                      checked={value}
                      id={`${BASE_ID}-boost-saved-recipes-checkbox`}
                      labelText="Boost Saved Recipes"
                      onChange={toggle}
                    />
                  )}
                </BooleanValue>,
              ]}
            />

            <FormGroup
              label="Feature Configuration"
              orientation="vertical"
              items={[
                <BooleanValue
                  value={values.enableBoostTags}
                  onChange={value => setFieldValue('enableBoostTags', value)}
                >
                  {({ toggle, value }) => (
                    <Checkbox
                      checked={value}
                      id={`${BASE_ID}-enable-boost-tags`}
                      labelText="Enable Tag Boosting"
                      onChange={toggle}
                    />
                  )}
                </BooleanValue>,
                <BooleanValue
                  value={values.enableBoostCategories}
                  onChange={value => setFieldValue('enableBoostCategories', value)}
                >
                  {({ toggle, value }) => (
                    <Checkbox
                      checked={value}
                      id={`${BASE_ID}-enable-boost-categories`}
                      labelText="Enable Category Boosting"
                      onChange={toggle}
                    />
                  )}
                </BooleanValue>,
              ]}
            />
            <Styling.LineOfButtons>
              <Button onClick={submitForm}>Save</Button>
              <Button kind="link" onClick={closeModal}>
                Cancel
              </Button>
            </Styling.LineOfButtons>
          </>
        )
      }}
    />
  )
}

const EditGlobalSearchSettingsModal = props => {
  return (
    <Modal
      {...props}
      size="large"
      modalHeading="Update Global Search Settings"
      render={renderProps => (
        <EditGlobalSearchSettingsModalContent
          initialValues={props.values}
          {...renderProps}
          {...props}
        />
      )}
    />
  )
}

export default injectSheet(styles)(EditGlobalSearchSettingsModal)
