import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider as ThemeProviderJSS } from 'react-jss'
import { connect } from 'react-redux'
import { BrowserRouter, Redirect, Route } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { ThemeProvider } from 'styled-components/macro'
import { config as configService, utils } from '@instacart/enterprise-services'

import { ErrorBoundary, Modal } from 'components'
import config from 'config'
import { fetchCurrentUser } from 'modules/user'
import { formatDatetime } from 'utils'

import { Dashboard, Login, Logout } from 'pages'
import { Toast } from 'layouts'

import theme from './styling/theme.styles'
import './styling/reset.css'
import './styling/global.css'

const PrivateRoute = ({ isAuthenticated, component: Component, path }) => (
  <Route
    path={path}
    render={({ location }) => {
      return !location.pathname.includes('/login') ? (
        isAuthenticated ? (
          <Component location={location} />
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: location } }} />
        )
      ) : null
    }}
  />
)

const defaultUnsavedChangesMessage =
  'You have unsaved changes on this page. These changes will not be saved if you navigate away from this page now.'

class App extends Component {
  state = {
    isLoading: true,
  }

  componentDidMount() {
    // Display deploy information in the console
    // eslint-disable-next-line no-console
    console.info(`Release Branch: %c${config.version}`, 'font-weight: bold;')
    // eslint-disable-next-line no-console
    console.info(
      `Deploy Time: %c${formatDatetime(config.deployTime, 'Unknown')}`,
      'font-weight: bold;'
    )

    // Set the API prefix for enterprise-services
    utils.setApiPrefix(config.env.apiPath)

    // Set the API prefix for enterprise-services
    configService.actions.setConfig({ authUseCookies: true })

    this.props
      .fetchCurrentUser()
      .catch(() => {
        // User was not already authenticated on app init
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  render() {
    if (!this.unsavedChangesNode) {
      this.unsavedChangesNode = document.createElement('div')
    }

    return (
      <BrowserRouter
        // On use of <Prompt>, displays a popup to alert user of unsaved changes which will
        // allow them to cancel or proceed with the page navigation.
        getUserConfirmation={(message, callback) => {
          document.body.appendChild(this.unsavedChangesNode)

          const cleanupAndCallback = ({ wasConfirmed: allowTransition }) => {
            ReactDOM.unmountComponentAtNode(this.unsavedChangesNode)
            document.body.removeChild(this.unsavedChangesNode)
            callback(allowTransition)
          }
          ReactDOM.render(
            <ThemeProviderJSS theme={theme}>
              <ThemeProvider theme={theme}>
                <Modal.Confirmation
                  handleCloseModal={cleanupAndCallback}
                  isAlwaysOpen
                  contentProps={{
                    actionText: 'leave this page',
                    confirmButtonText: 'Leave Now',
                    cancelButtonText: 'Back To Page',
                    details: message === 'default' ? defaultUnsavedChangesMessage : message,
                  }}
                />
              </ThemeProvider>
            </ThemeProviderJSS>,
            this.unsavedChangesNode
          )
        }}
      >
        <ThemeProviderJSS theme={theme}>
          <ThemeProvider theme={theme}>
            <ErrorBoundary>
              <div>
                {!this.state.isLoading && (
                  <div>
                    <PrivateRoute path="/" {...this.props} component={Dashboard} />
                    <Route path="/login" render={props => <Login {...props} {...this.props} />} />
                    <Route
                      path="/logout"
                      exact
                      render={props => <Logout {...props} {...this.props} />}
                    />
                    <Toast />
                  </div>
                )}
              </div>
            </ErrorBoundary>
          </ThemeProvider>
        </ThemeProviderJSS>
      </BrowserRouter>
    )
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.user.isAuthenticated,
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCurrentUser,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(App)
