// Taken from https://codepen.io/mattonit/pen/vLoddq

import cx from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'

const styles = theme => ({
  loadingIndicatorDots: {
    display: 'inline-block',
    '&$absoluteCenter': {
      position: 'absolute',
      right: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 1,
    },
    '&$centerBlock': {
      display: 'block',
      textAlign: 'center',
    },
  },
  absoluteCenter: {},
  centerBlock: {},
  loadingText: {
    color: theme.text01,
    display: 'inline-block',
    fontSize: 16,
    lineHeight: 1.5,
    marginRight: 3,
  },
  dotsContainer: {
    display: 'inline-block',
    height: 24,
    lineHeight: '24px',
    textAlign: 'center',
    width: 24,
  },
  dot: {
    display: 'inline-block',
    width: 4,
    height: 4,
    borderRadius: '50%',
    background: theme.text01,
    animation: 'wave 1.3s linear infinite',

    '&:nth-child(2)': {
      animationDelay: '-1.1s',
      marginLeft: 3,
    },

    '&:nth-child(3)': {
      animationDelay: '-0.9s',
      marginLeft: 3,
    },
  },
  '@global @keyframes wave': {
    '0%, 60%, 100%': {
      transform: 'initial',
    },
    '30%': {
      transform: 'translateY(-6px)',
    },
  },
})

const LoadingIndicatorDots = ({
  absoluteCenter = false,
  centerBlock = false,
  classes,
  className,
  withText = false,
}) => (
  <div
    className={cx(className, classes.loadingIndicatorDots, {
      [classes.absoluteCenter]: absoluteCenter,
      [classes.centerBlock]: centerBlock,
    })}
  >
    {withText && <span className={classes.loadingText}>Loading</span>}
    <div className={classes.dotsContainer}>
      <span className={classes.dot} />
      <span className={classes.dot} />
      <span className={classes.dot} />
    </div>
  </div>
)

LoadingIndicatorDots.propTypes = {
  /**
   * If `true`, make `<LoadingIndicator.Dots>` centered absolutely, both horizontally
   * and veritcally.
   */
  absoluteCenter: PropTypes.bool,
  /**
   * If `true`, will place the <LoadingIndicator.Dots> in a `display: block` and
   * will center the loading indicator in the block.
   */
  centerBlock: PropTypes.bool,
  /**
   * Whether or not to display "Loading" text before the loading indicator dots.
   */
  withText: PropTypes.bool,
}

export { LoadingIndicatorDots as LoadingIndicatorDotsUnStyled }
export default injectSheet(styles)(LoadingIndicatorDots)
