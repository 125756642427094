import cx from 'classnames'
import { Field, getIn } from 'formik'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import injectSheet from 'react-jss'

import { FormLabel, FormRequirement } from 'components'

import styles from './FormItem.styles'

/**
 * Displays a Formik field's label (optional) and error msg (if field is invalid) and passes along
 * useful props to the render method to pass into the field's component.
 */
const FormFieldItem = ({
  classes,
  className,
  id,
  isRangeField,
  name,
  labelText,
  orientation,
  render,
}) => {
  const classNames = cx(className, classes.formItem, {
    [classes.horizontal]: orientation === 'horizontal',
    [classes.vertical]: orientation === 'vertical',
  })

  const errorPath = isRangeField ? `${name}Range` : name

  return (
    <div className={classNames}>
      {labelText && <FormLabel htmlFor={id} labelText={labelText} />}
      <Field
        name={name}
        render={({ field, form: { errors, handleBlur, handleChange, values } }) => {
          const errorMsg = getIn(errors, errorPath)
          const renderProps = {
            id,
            isInvalid: !!errorMsg,
            onBlur: handleBlur,
            onChange: handleChange,
          }
          if (isRangeField) {
            renderProps.value = {
              from: getIn(values, `${name}From`),
              to: getIn(values, `${name}To`),
            }
          } else {
            renderProps.name = field.name
            renderProps.value = field.value
          }
          return (
            <Fragment>
              {render(renderProps)}
              {errorMsg && <FormRequirement id={`${id}-error-msg`} invalidText={errorMsg} />}
            </Fragment>
          )
        }}
      />
    </div>
  )
}

FormFieldItem.propTypes = {
  className: PropTypes.string,
  /**
   * If provided, a <FormRequirement> with id equal to `${fieldId}-error-msg`
   * displaying the error message will be shown.
   */
  errorMsg: PropTypes.string,
  /**
   * The `id` of the corresponding field component (for the error message and label to associate with).
   */
  id: PropTypes.string.isRequired,
  /**
   * If `true`, we need to handle the value and error paths slightly differently.
   * (e.g. "path.field" will become "path.fieldRange" for the error, and "path.fieldFrom"/"path.fieldTo" for
   * the range values)
   * This will also alter the render props passed into `render`.
   */
  isRangeField: PropTypes.bool,
  /**
   * If provided, a <FormLabel> will be shown displaying the label text.
   */
  labelText: PropTypes.string,
  /**
   * The path of the field in the related Formik form's values/errors
   */
  name: PropTypes.string.isRequired,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']).isRequired,
  render: PropTypes.func.isRequired,
}

FormFieldItem.defaultProps = {
  orientation: 'vertical',
}

export default injectSheet(styles)(FormFieldItem)
