import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'

import { refundCustomerSubscription, cancelCustomerSubscription } from 'api'
import { createToast } from 'modules/toasts'
import { getRoutePathname } from 'routing'
import Styling, { ModalHeader } from 'styling/components'
import { Button, Notification } from 'components'

const styles = theme => ({
  container: {
    textAlign: 'left',
  },
  daysRemaining: {
    fontSize: theme.fontSize.p,
    color: theme.text01,
  },
  numDaysRemaining: {
    fontWeight: 'bold',
  },
  success: {
    paddingTop: 0,
  },
  error: {
    marginBottom: theme.spacing.lg,
  },
  warning: {
    backgroundColor: theme.support01InputBackground,
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm,
    marginTop: theme.spacing.lg,
    lineHeight: theme.baseLineHeight,
  },
})

const SubscriptionCancel = ({ classes, closeModal, createToast, customerId, subscription }) => {
  const [processingCancellation, setProcessingCancellation] = useState(false)
  const [cancellationError, setCancellationError] = useState()
  const [showCartFollowup, setShowCartFollowup] = useState(false)

  const {
    enterprise_subscription: enterpriseSubscription,
    order_id: orderId,
    order_status: orderStatus,
    expiry,
  } = subscription

  const orderedFromCart = !orderStatus || orderStatus !== 'dummy'

  const cancelSubscription = async ({ withRefund }) => {
    const subscriptionAction = withRefund ? refundCustomerSubscription : cancelCustomerSubscription
    try {
      setCancellationError(null)
      setProcessingCancellation(true)
      await subscriptionAction(customerId, {
        enterprise_subscription_id: enterpriseSubscription.id,
      })
      return true
    } catch (error) {
      setCancellationError(error.message)
      return false
    } finally {
      setProcessingCancellation(false)
    }
  }

  const closeWithRefetch = () => closeModal({ requiresRefetch: true })

  const handleRefund = async () => {
    if (await cancelSubscription({ withRefund: true })) {
      createToast({
        kind: 'success',
        message: `Subscription for ${enterpriseSubscription.name} has been successfully refunded.`,
      })
      closeWithRefetch()
    }
  }

  const handleCancel = async () => {
    if (await cancelSubscription({ withRefund: false })) {
      if (orderedFromCart && orderId) {
        setShowCartFollowup(true)
      } else {
        createToast({
          kind: 'success',
          message: `Subscription for ${enterpriseSubscription.name} has been successfully cancelled without refund.`,
        })
        closeWithRefetch()
      }
    }
  }

  if (!showCartFollowup) {
    return (
      <div className={classes.container}>
        <ModalHeader small>Are you sure you want to cancel this subscription?</ModalHeader>
        {cancellationError && (
          <Notification
            className={classes.error}
            kind="error"
            title="Error"
            message={cancellationError}
          />
        )}
        <p className={classes.daysRemaining}>
          There are still{' '}
          <span className={classes.numDaysRemaining}>
            {moment(expiry).diff(moment(), 'days')} day(s)
          </span>{' '}
          left in this subscription
        </p>
        {orderedFromCart && (
          <Notification
            className={classes.warning}
            kind="warning"
            hideBorder
            message="This subscription may have been purchased within an order. If it was, to issue a refund, you must manually refund the subscription amount on the associated order in Order Management."
          />
        )}
        <Styling.LineOfButtons>
          {!orderedFromCart && (
            <Fragment>
              <Button disabled={processingCancellation} onClick={handleRefund}>
                Refund Subscription
              </Button>
              <Button kind="secondary" disabled={processingCancellation} onClick={handleCancel}>
                Cancel without Refund
              </Button>
            </Fragment>
          )}
          {orderedFromCart && (
            <Button disabled={processingCancellation} onClick={handleCancel}>
              Cancel Subscription
            </Button>
          )}
          <Button kind="link" onClick={closeModal}>
            Go Back
          </Button>
        </Styling.LineOfButtons>
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <Notification
        className={classes.success}
        kind="success"
        hideBorder
        message="Subscription has been successfully cancelled."
      />
      <ModalHeader small>Do you want to go to the order to issue a refund?</ModalHeader>
      <Styling.LineOfButtons>
        <Button href={{ pathname: getRoutePathname('orders.order', { id: orderId }) }}>
          Go to order
        </Button>
        <Button kind="link" onClick={closeWithRefetch}>
          No, thanks
        </Button>
      </Styling.LineOfButtons>
    </div>
  )
}

SubscriptionCancel.propTypes = {
  classes: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  createToast: PropTypes.func.isRequired,
  customerId: PropTypes.number.isRequired,
  subscription: PropTypes.shape({
    enterprise_subscription: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    expiry: PropTypes.string.isRequired,
    order_id: PropTypes.number,
  }).isRequired,
}

const mapDispatchToProps = dispatch => bindActionCreators({ createToast }, dispatch)

export default injectSheet(styles)(connect(null, mapDispatchToProps)(SubscriptionCancel))
