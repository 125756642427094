export default theme => {
  const actionCell = {
    flexShrink: 0,
    textAlign: 'center',
    width: 60,
  }

  return {
    itemRowLeave: {
      opacity: 1,
    },
    disabledContainer: {
      opacity: 0.7,
    },
    itemRowLeaveActive: {
      opacity: 0.01,
      transition: 'opacity 300ms ease-in',
    },
    selectedItems: {
      color: theme.text01,
      width: '100%',
    },
    headerText: {
      fontSize: theme.fontSize.label,
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    headerContainer: {
      alignItems: 'center',
      display: 'flex',
      height: 32,
      justifyContent: 'space-between',
    },
    headerClearAll: actionCell,
    itemContentCell: {
      flex: 1,
      padding: `0 ${theme.spacing.sm}`,
    },
    itemRow: {
      fontSize: theme.fontSize.p,
      backgroundColor: '#FFFFFF',
      borderBottom: `2px solid ${theme.bgSelected}`,
      lineHeight: theme.baseLineHeight,
      minHeight: 41,
      padding: `${theme.spacing.xs} ${theme.spacing.sm}`,

      '&:last-child': {
        minHeight: 39,
        borderBottom: 0,
      },
    },
    itemRowWithSeparator: {
      padding: `${theme.spacing.sm}`,
    },
    itemRowInner: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
    },
    itemRowEdit: actionCell,
    itemRowClear: actionCell,
    noSelectedItems: {
      backgroundColor: '#FFFFFF',
      fontSize: theme.fontSize.p,
      marginTop: theme.spacing.sm,
      padding: theme.spacing.md,
      textAlign: 'center',
    },
    noSelectedItemsIcon: {
      fill: theme.support03,
      height: 16,
      marginRight: theme.spacing.xs,
      verticalAlign: 'middle',
      width: 16,
    },
    noSelectedItemsText: {
      verticalAlign: 'middle',
    },
    dragColumnCell: {
      boxSizing: 'content-box',
      width: 16,

      '& + $itemContentCell': {
        paddingLeft: theme.spacing.xxs,
      },
    },
    dragColumnCellIcon: {
      fill: '#4A4A4A',
      height: 16,
      opacity: 0,
      width: 12,
    },
    row: {
      '&:hover $dragColumnCellIcon': {
        opacity: 1,
      },
    },
    rowDragging: {
      backgroundColor: '#F6F8F8 !important',
      boxShadow: '0 3px 6px 0 rgba(142,142,142,0.5)',
      display: 'table',
      fontWeight: 600,

      '& $dragColumnCellIcon': {
        opacity: 1,
      },
    },
    rowSeparator: {
      position: 'absolute',
      top: -23,
      backgroundColor: 'white',
      padding: 4,
      fontWeight: 900,
      fontSize: 9,
    },
    rowSeparatorWrapper: {
      position: 'relative',
    },
  }
}
