import { Formik } from 'formik'
import React from 'react'
import { connect } from 'react-redux'
import injectSheet from 'react-jss'
import { bindActionCreators } from 'redux'

import { getRouteTo } from 'routing'
import * as api from 'api'
import { LoadingIndicator, PageHeader, Icon } from 'components'

import { createToast } from 'modules/toasts'

import { ItemFetcher } from 'utils'

import GlobalRecipeSettingsDetailForm from './GlobalRecipeSettingsDetailForm'

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createToast,
    },
    dispatch
  )

export const EditRecipeGlobalSearchSettings = injectSheet(theme => ({
  contextExplainerText: {
    marginBottom: theme.spacing.md,
    color: theme.text01,
    fontSize: theme.fontSize.body,
  },
  contextExplainerIcon: {
    fill: theme.brand01,
    marginRight: theme.spacing.xs,
    height: theme.fontSize.body,
  },
}))(
  connect(
    null,
    mapDispatchToProps
  )(
    ({
      classes,
      history,
      location,
      match: {
        params: { keywordRuleDetailId },
      },
      createToast,
      ...restProps
    }) => (
      <ItemFetcher
        queryKey="globalRecipeSearchSetting"
        fetchItem={config => api.fetchGlobalRecipeSearchSetting(config)}
        render={({
          item: globalSearchSettings,
          isLoadingItem: isFetchingKeywordRuleDetail,
          refetch,
        }) => {
          if (isFetchingKeywordRuleDetail) {
            return <LoadingIndicator withTopMargin />
          }

          const onFormSubmit = formValues => {
            return api
              .updateGlobalRecipeSearchSetting(formValues)
              .then(() => createToast({ kind: 'success', message: 'Saved changes!' }))
              .catch(err =>
                createToast({ kind: 'error', message: `Error while saving changes: ${err}` })
              )
          }

          return (
            globalSearchSettings && (
              <Formik
                initialValues={globalSearchSettings}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={(values, form) => {
                  onFormSubmit(values)
                    .then(() => {
                      form.setSubmitting(false)
                    })
                    .catch(error => {
                      form.setSubmitting(false)
                      form.setErrors({ global: error.message })
                    })
                }}
                render={formikProps => {
                  return (
                    <>
                      <PageHeader headerTitle="Recipe Global Rules" />
                      <div className={classes.contextExplainerText}>
                        <Icon name="info" className={classes.contextExplainerIcon} />
                        These Recipe Rules apply recipe rankings on Recipe Search and Browse Recipe
                        page
                      </div>
                      <GlobalRecipeSettingsDetailForm
                        {...formikProps}
                        onFormSubmit={onFormSubmit}
                        cancelDestination={getRouteTo('searchSettings.recipeKeywordRules')}
                      />
                    </>
                  )
                }}
              />
            )
          )
        }}
      />
    )
  )
)
