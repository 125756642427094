import cx from 'classnames'
import Downshift from 'downshift'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import injectSheet from 'react-jss'

import { Checkbox, ListBox } from 'components'
import { removeAtIndex } from 'utils'

import ListBoxFieldStyles from '../ListBox/ListBoxField.styles'

const styles = theme => {
  const listBoxFieldStyles = ListBoxFieldStyles(theme)

  return {
    multiSelectFilterableInput: {
      border: 0,
      color: theme.text01,
      fontFamily: theme.fontFamilySansSerif,
      fontSize: theme.fontSize.body,
      fontWeight: theme.inputPlaceholderWeight,
      padding: 0,
      width: '100%',

      '&:focus': {
        outline: 'none',
      },

      '&::placeholder, &::-webkit-input-placeholder': {
        color: theme.text02,
        fontWeight: theme.inputPlaceholderWeight,
      },

      // When the multiselect has values selected, show the name
      // of the multiselect in bold unless the input is focused
      '&$multiSelectFilterableInputBoldPlaceholder': {
        '&::placeholder, &::-webkit-input-placeholder': {
          fontWeight: theme.inputValueWeight,
        },
        '&:focus': {
          '&::placeholder, &::-webkit-input-placeholder': {
            fontWeight: theme.inputPlaceholderWeight,
          },
        },
      },
    },
    multiSelectFilterableInputBoldPlaceholder: {},
    multiSelectFilterableInputDisabled: {
      // When the MultiSelect is disabled, make sure the input shows the same disabled background color as ListBox.Field
      background: listBoxFieldStyles.listBoxFieldDisabled.background,
    },
    multiSelectItemSelectedSeparator: {
      borderBottom: `2px solid ${theme.borderGreyLight}`,
      margin: `${theme.spacing.xxs} ${theme.spacing.xs}`,
    },
  }
}

class MultiSelectFilterable extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    /**
     * Id will be used as the id field for the text input
     */
    id: PropTypes.string.isRequired,
    isInvalid: PropTypes.bool,
    isLoading: PropTypes.bool,
    items: PropTypes.array.isRequired,
    /**
     * Message to show to users when the filtered list of items is empty
     */
    noItemsMsg: PropTypes.string.isRequired,
    /**
     * Will set `white-space: nowrap` on the ListBoxMenu
     */
    nowrap: PropTypes.bool,
    /**
     * `onChange` is a utility for this controlled component to communicate to a
     * consuming component what kind of internal state changes are occuring.
     */
    onChange: PropTypes.func,
    /**
     * Generic `placeholder` that will be used as the textual representation of
     * what this field is for inside of the text input
     */
    placeholder: PropTypes.string.isRequired,
    selectedValues: PropTypes.array,
  }

  static defaultProps = {
    noItemsMsg: 'No results found',
    selectedValues: [],
  }

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      isOpen: false,
      inputValue: '',
      selectedItems: [],
      selectedItemsHaveChanged: false,
    }
    if (props.selectedValues) {
      this.state.selectedItems = this.selectItemsFromValues(props.selectedValues)
    }
  }

  /**
   * TODO: `componentDidUpdate` should be replaced with `getDerivedStateFromProps` (like in <MinMax>)
   * or a better solution altogether
   */
  componentDidUpdate(prevProps, prevState) {
    const { isLoading: wasLoading, selectedValues: prevSelectedValues } = prevProps
    const { isLoading, onChange, selectedValues } = this.props
    const { isOpen: wasOpen } = prevState
    const { isOpen, selectedItemsHaveChanged } = this.state

    if (wasOpen && !isOpen && selectedItemsHaveChanged && onChange) {
      this.setState({
        selectedItemsOnLastClose: false, // reset flag
      })

      // Send update to consumer on dropdown menu close
      onChange(this.state.selectedItems.map(selectedItem => selectedItem.value))
    } else if (prevSelectedValues !== selectedValues || (wasLoading && !isLoading)) {
      // Update state.selectedItems on selectedValues props change from consumer or if finished loading
      this.setState({
        selectedItems: this.selectItemsFromValues(selectedValues),
      })
    }
  }

  selectItemsFromValues = initialValues => {
    const { items } = this.props

    return items.filter(item => initialValues.includes(item.value))
  }

  handleClearSelection = () => {
    this.setState({
      selectedItems: [],
      selectedItemsHaveChanged: true,
    })
    if (this.props.onChange) {
      this.props.onChange([])
    }
  }

  handleRemoveItem = index => {
    this.setState(state => ({
      selectedItems: removeAtIndex(state.selectedItems, index),
      selectedItemsHaveChanged: true,
    }))
  }

  handleSelectItem = item => {
    this.setState(state => ({
      selectedItems: state.selectedItems.concat(item),
      selectedItemsHaveChanged: true,
    }))
  }

  handleItemChange = item => {
    const { selectedItems } = this.state
    const selectedIndex = selectedItems.findIndex(selectedItem => selectedItem.id === item.id)

    if (selectedIndex === -1) {
      this.handleSelectItem(item)
    } else {
      this.handleRemoveItem(selectedIndex)
    }
  }

  handleStateChange = changes => {
    const { type } = changes
    switch (type) {
      case Downshift.stateChangeTypes.changeInput:
        this.setState({ inputValue: changes.inputValue })
        break
      case Downshift.stateChangeTypes.keyDownEscape:
      case Downshift.stateChangeTypes.mouseUp:
        this.setState({ isOpen: false })
        break
      // Opt-in to some cases where we should be toggling the menu based on
      // a given key press or mouse handler
      // Reference: https://github.com/paypal/downshift/issues/206
      case Downshift.stateChangeTypes.clickButton:
      case Downshift.stateChangeTypes.keyDownSpaceButton:
        this.setState(() => {
          let nextIsOpen = changes.isOpen
          if (changes.isOpen === false) {
            // If Downshift is trying to close the menu, but we know the input
            // is the active element in the document, then keep the menu open
            if (this.inputEl === document.activeElement) {
              nextIsOpen = true
            }
          }
          return {
            isOpen: nextIsOpen,
          }
        })
        break
      default:
    }
  }

  handleInputKeyDown = event => {
    event.stopPropagation()
  }

  handleInputValueChange = inputValue => {
    if (this.clearInputEl !== document.activeElement) {
      this.setState(() => {
        if (inputValue) {
          return {
            inputValue,
            isOpen: true,
          }
        }
        return {
          inputValue: '',
        }
      })
    }
  }

  clearInputValue = event => {
    event.stopPropagation()
    this.setState({ inputValue: '' })
    this.inputEl && this.inputEl.focus && this.inputEl.focus()
  }

  render() {
    const { isOpen, inputValue, selectedItems } = this.state
    const {
      classes,
      className: containerClassName,
      disabled,
      id,
      isInvalid,
      isLoading,
      items,
      noItemsMsg,
      nowrap,
      placeholder,
    } = this.props

    const isDisabled = disabled || isLoading

    const moveSelectedItemsToFront = (items, { selectedItems }) => {
      // selectedItems includes items that have not been filtered by filterItems
      const filteredSelectedItems = []
      const unselectedItems = []
      items.forEach(item => {
        selectedItems.findIndex(selectedItem => selectedItem.id === item.id) > -1
          ? filteredSelectedItems.push(item)
          : unselectedItems.push(item)
      })
      // Place divider in between selected and unselected items
      return filteredSelectedItems.length === 0
        ? unselectedItems
        : [...filteredSelectedItems, { isDivider: true }, ...unselectedItems]
    }

    const filterItems = (items, { itemToString, inputValue }) => {
      if (inputValue) {
        // If there is an inputValue, return all items which contain the inputValue ignoring case
        return items.filter(item =>
          itemToString(item)
            .toLowerCase()
            .includes(inputValue.toLowerCase())
        )
      }
      return items
    }
    const itemToString = item => (typeof item === 'string' ? item : (item && item.label) || '')

    return (
      <Downshift
        isOpen={isOpen}
        inputValue={inputValue}
        itemToString={itemToString}
        onChange={this.handleItemChange}
        onInputValueChange={this.handleInputValueChange}
        onStateChange={this.handleStateChange}
        selectedItem={selectedItems}
        render={({
          getButtonProps,
          getInputProps,
          getItemProps,
          getRootProps,
          highlightedIndex,
          isOpen,
          inputValue,
          selectedItem: selectedItems, // Downshift misnomer when there can be multiple selected items
        }) => {
          const itemsToShow = moveSelectedItemsToFront(
            filterItems(items, {
              itemToString,
              inputValue,
            }),
            {
              selectedItems,
            }
          )
          return (
            <ListBox
              className={containerClassName}
              id={id}
              isDisabled={isDisabled}
              {...getRootProps({ refKey: 'setRef' })}
            >
              <ListBox.Field
                isDisabled={isDisabled}
                isFocused={isOpen}
                isInvalid={isInvalid}
                isSelected={selectedItems.length > 0}
                {...getButtonProps({ disabled: isDisabled })}
              >
                {selectedItems.length > 0 && (
                  <ListBox.Selection
                    clearSelection={this.handleClearSelection}
                    isDisabled={isDisabled}
                    selectionCount={selectedItems.length}
                  />
                )}
                <ListBox.Icon name="search" />
                {isLoading && <ListBox.Label text="Loading..." />}
                {!isLoading && (
                  <input
                    className={cx(classes.multiSelectFilterableInput, {
                      [classes.multiSelectFilterableInputBoldPlaceholder]: selectedItems.length > 0,
                      [classes.multiSelectFilterableInputDisabled]: isDisabled,
                    })}
                    ref={el => (this.inputEl = el)}
                    {...getInputProps({
                      id,
                      disabled: isDisabled,
                      placeholder,
                      onKeyDown: this.handleInputKeyDown,
                    })}
                  />
                )}
                {inputValue && isOpen && (
                  <ListBox.Selection
                    clearSelection={this.clearInputValue}
                    setRef={el => (this.clearInputEl = el)}
                  />
                )}
                <ListBox.MenuIcon isDisabled={isDisabled} isOpen={isOpen} />
              </ListBox.Field>
              {isOpen && (
                <ListBox.Menu nowrap={nowrap}>
                  {itemsToShow.length === 0 ? (
                    <ListBox.MenuItem isNoItemsMsg>{noItemsMsg}</ListBox.MenuItem>
                  ) : (
                    itemsToShow.map((item, index) => {
                      if (item.isDivider) {
                        return (
                          <div
                            key={`${id}-selected-items-divider`}
                            className={classes.multiSelectItemSelectedSeparator}
                          />
                        )
                      }
                      const itemProps = getItemProps({ item })
                      const itemText = itemToString(item)
                      const isChecked =
                        selectedItems.findIndex(selectedItem => selectedItem.id === item.id) > -1
                      const dividerRendered = selectedItems.length > 0 && !isChecked
                      const isHighlighted = highlightedIndex === index - (dividerRendered ? 1 : 0)

                      return (
                        <ListBox.MenuItem
                          key={itemProps.id}
                          isActive={isChecked}
                          isHighlighted={isHighlighted}
                          {...itemProps}
                        >
                          <Checkbox
                            checked={isChecked}
                            dropdownItemIsHighlighted={isHighlighted}
                            dropdownStyle
                            id={itemProps.id}
                            labelText={itemText}
                            readOnly
                            tabIndex="-1"
                          />
                        </ListBox.MenuItem>
                      )
                    })
                  )}
                </ListBox.Menu>
              )}
            </ListBox>
          )
        }}
      />
    )
  }
}

export { MultiSelectFilterable as MultiSelectFilterableUnStyled }
export default injectSheet(styles)(MultiSelectFilterable)
