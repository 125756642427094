import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { Button, Modal, Tabs, Tab } from 'components'

const SPACES = 8

const RawLogModal = ({ classes, title, oldValue, newValue, oldSchema, newSchema, diff }) => {
  const [currentTab, selectTab] = useState(0)

  return (
    <Modal
      showClose
      modalHeading={title}
      render={() => {
        return (
          <Tabs className={classes.text} onSelectTab={selectTab} selectedTab={currentTab}>
            <Tab label="Old Value">{JSON.stringify(oldValue, null, SPACES)}</Tab>
            <Tab label="New Value">{JSON.stringify(newValue, null, SPACES)}</Tab>
            <Tab label="Old Schema">{JSON.stringify(oldSchema, null, SPACES)}</Tab>
            <Tab label="New Schema">{JSON.stringify(newSchema, null, SPACES)}</Tab>
            <Tab label="Diff">{diff}</Tab>
          </Tabs>
        )
      }}
      triggerRender={({ openModal }) => (
        <Button onClick={openModal} kind="link" type="button">
          Open
        </Button>
      )}
    />
  )
}

RawLogModal.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  oldValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  newValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  oldSchema: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  newSchema: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  diff: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export default RawLogModal
