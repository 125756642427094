import theme from './theme.styles'

export default {
  color: {
    brand: {
      primary: {
        regular: theme.brand00,
        light: theme.brand00,
        dark: theme.brand02,
      },
    },
    neutral: {
      grey4: theme.borderGreyLight,
    },
    text: {
      primary: theme.text01,
    },
  },
  border: {
    radius: {
      small: 2,
      regular: 2,
    },
  },
  font: {
    family: {
      primary: theme.fontFamilySansSerif,
    },
  },
  size: {
    font: {
      body: 14,
    },
  },
  overrides: {
    ConfigurableProducts: {
      dropdownItemLabel: {
        background: 'red',
        color: theme.text02,
        fontSize: theme.fontSize.p,
        fontWeight: 600,
      },
    },
    Price: {
      amount: {
        color: theme.brand00,
        fontSize: 18,
        fontWeight: 700,
      },
    },
    StepInput: {
      button: {
        borderRadius: 4,
      },
    },
  },
}
