export default {
  transition: {
    duration: {
      base: '250ms',
      expansion: '200ms',
    },
    timingFunction: {
      easeIn: 'cubic-bezier(0.25, 0, 1, 1)',
      easeOut: 'cubic-bezier(0, 0, 0.25, 1)',
      standardEasing: 'cubic-bezier(0.5,0,0.1,1)',
    },
  },
}
