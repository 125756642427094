import PropTypes from 'prop-types'
import React from 'react'
import injectSheet from 'react-jss'

import { FormItem, ReadOnlyValue, ReadOnlyValueDetails, ShowCollapse } from 'components'

import ReadOnlyValueStyles from './ReadOnlyValue.styles'

const styles = theme => {
  const readOnlyValueStyles = ReadOnlyValueStyles(theme)

  return {
    readOnlyValueWithDetails: {
      marginBottom: readOnlyValueStyles.readOnlyValueHorizontalContainer.marginBottom,
    },
    readOnlyValueDetailsContainer: {
      background: theme.bgGreyExtraLight,
      padding: theme.spacing.md,
    },
  }
}

const ReadOnlyValueWithDetails = ({
  classes,
  detailValues,
  useCustomValueEl = false,
  valueProps,
}) => {
  return (
    <FormItem className={classes.readOnlyValueWithDetails}>
      <ShowCollapse
        trigger={
          <ReadOnlyValue
            removeFormItemContainer
            {...valueProps}
            removeTextAreaEl={!!useCustomValueEl}
          />
        }
        content={
          <div className={classes.readOnlyValueDetailsContainer}>
            <ReadOnlyValueDetails detailValues={detailValues} />
          </div>
        }
        contentTopPadding="sm"
      />
    </FormItem>
  )
}

ReadOnlyValueWithDetails.propTypes = {
  /**
   * Passed directly into the <ReadOnlyValueDetails>.
   */
  detailValues: PropTypes.array.isRequired,
  /**
   * If `true`, the <ReadOnlyValue> will not use a <textarea> element.
   */
  useCustomValueEl: PropTypes.bool,
  /**
   * The props to pass into the <ReadOnlyValue>.
   */
  valueProps: PropTypes.object.isRequired,
}

export { ReadOnlyValueWithDetails as ReadOnlyValueWithDetailsUnStyled }
export default injectSheet(styles)(ReadOnlyValueWithDetails)
