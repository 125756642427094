import memoize from 'memoize-one'
import React, { Component, Fragment } from 'react'
import injectSheet from 'react-jss'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { fetchMerchandisedLandingPages, updateMerchandisedLandingPage } from 'api'
import {
  Button,
  DataTableWithStateAsync,
  Dropdown,
  Notification,
  PageHeader,
  StatusIndicatorDot,
  Tab,
  Tabs,
} from 'components'
import { generateBackButton, getRoutePathname } from 'routing'
import { createToast } from 'modules/toasts'
import {
  addValueToQueryString,
  capitalize,
  formatDate,
  formatDatetime,
  isNullOrUndefined,
  queryString,
} from 'utils'

import styles from './MerchandisedLandingPages.styles.js'

// Table Configurations
const PAGE_ID = 'merchandise-landing-pages'
const PAGE_TITLE = 'Merchandised Landing Pages'

const tableHeaders = [
  {
    key: 'nameWithImage',
    header: 'Name',
    disableSorting: true,
  },
  {
    key: 'customerSegment',
    header: 'Segment',
    disableSorting: true,
  },
  {
    key: 'storeGroup',
    header: 'Store Group',
    disableSorting: true,
  },
  {
    key: 'activePeriod',
    header: 'Active Period',
    disableSorting: true,
  },
  {
    key: 'lastEdited',
    header: 'Last Edited',
    disableSorting: true,
  },
  {
    key: 'status',
    header: 'Status',
    disableSorting: true,
  },
]

const filterOptions = {
  expired: [
    { id: 'active', label: 'Active', value: 'false' },
    { id: 'expired', label: 'Expired', value: 'true' },
  ],
}

const formatTableRows = memoize(({ classes, items, history, location, match }) =>
  items.map(placement => {
    const {
      createdBy,
      customerSegment,
      endDate,
      id,
      modified,
      name,
      position,
      promotedMedia = {},
      storeGroup,
      startDate,
      status: mlpStatus,
    } = placement

    const validStatuses = ['new', 'in-progress', 'complete', 'cancelled', 'canceled']
    const statusColors = {
      approved: 'completed',
      completed: 'canceled',
      draft: 'in-progress',
      pending: 'in-progress',
      rejected: 'canceled',
    }
    const status = mlpStatus
    let statusType = ''
    if (!validStatuses.includes(status)) {
      statusType = statusColors[status]
    }

    let imageUrl = null

    if (promotedMedia.desktop && promotedMedia.desktop.imageUrl) {
      imageUrl = promotedMedia.desktop.imageUrl
    } else if (promotedMedia.mobile && promotedMedia.mobile.imageUrl) {
      imageUrl = promotedMedia.mobile.imageUrl
    }

    const nameWithImage = (
      <div className={classes.nameWithImage}>
        <div className={classes.imageContainer}>
          {imageUrl && <img src={imageUrl} alt={name} />}
        </div>
        <span>{name}</span>
      </div>
    )

    return {
      activePeriod: `${formatDate(startDate)} - ${formatDate(endDate)}`,
      customerSegment: customerSegment && customerSegment.name ? customerSegment.name : `Everyone`,
      storeGroup: storeGroup && storeGroup.name ? storeGroup.name : `All Stores`,
      id: `${PAGE_ID}-${id}`,
      rowLinkTo: {
        pathname: getRoutePathname('merchandisedLandingPages.details', { id }),
        state: { backButton: generateBackButton('merchandisedLandingPages', location) },
      },
      nameWithImage,
      position,
      createdBy: createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : '',
      lastEdited: modified ? formatDatetime(modified) : '',
      status: <StatusIndicatorDot text={capitalize({ phrase: status })} type={statusType} />,
    }
  })
)

class MerchandisedLandingPages extends Component {
  state = {
    merchandisedLandingPages: null,
    isFetchingMLPs: false,
    queryParams: {},
    selectedTab: 0,
  }

  buildFetchFunction = (qs, config, additionalParams) => {
    additionalParams.forEach(item => {
      qs = addValueToQueryString(qs, item)
    })
    return fetchMerchandisedLandingPages(qs, config)
  }

  componentWillMount() {
    const queryParams = queryString.parse(this.props.location.search)
    const selectedTab = queryParams.tab || 0
    this.setState({ selectedTab, queryParams })

    const loadMLPs = async () => {
      this.setState({ isFetchingMLPs: true })
      const merchandisedLandingPages = await fetchMerchandisedLandingPages().catch(e =>
        this.setState({ errorFetchingMLPs: true, isFetchingMLPs: false })
      )
      this.setState({
        merchandisedLandingPages,
        isFetchingMLPs: false,
      })
    }
    loadMLPs()
  }

  findRowById = (rowId, items) => {
    // Make sure this id matches the one passed to the table
    return items.find(item => `${PAGE_ID}-${item.id}` === rowId)
  }

  onDragEnd = ({ data, originalRows, rowAboveId, rowBelowId }) => {
    // early exit...user may have dragged and then changed their mind...and kept the position the same
    if (data.source.index === data.destination.index) {
      return
    }

    const item = this.findRowById(data.draggableId, originalRows)

    let newPosition = null

    if (item && item.id) {
      if (rowBelowId) {
        // Try to get the position of the row below
        const rowBelow = this.findRowById(`${PAGE_ID}-${rowBelowId}`, originalRows)

        if (rowBelow) {
          newPosition = rowBelow.position
        }
      } else if (rowAboveId) {
        // If that doesn't work (at the bottom), get the row above and add 1
        const rowAbove = this.findRowById(`${PAGE_ID}-${rowAboveId}`, originalRows)

        if (rowAbove) {
          newPosition = rowAbove.position + 1
        }
      }
    }

    // Position should be updated
    if (!isNullOrUndefined(newPosition)) {
      const updatedItem = { ...item, position: newPosition }

      updateMerchandisedLandingPage(item.id, updatedItem)
        .then(() => {
          this.props.createToast({
            kind: 'success',
            message: 'Row was successfully updated.',
          })
        })
        .catch(message => {
          this.props.createToast({ kind: 'error', message })
        })
    } else {
      this.props.createToast({
        kind: 'error',
        message: 'There was a problem updating this landing page.',
      })
    }
  }

  selectTab = selectedTab => {
    this.setState({ selectedTab })
  }

  setFilter = (type, value) => {
    const { history } = this.props
    const { location } = history
    const currentPathname = location.pathname
    const newParams = {
      ...queryString.parse(location.search),
      [type]: value,
    }

    // Reload page with new filters
    history.push({
      pathname: currentPathname,
      search: queryString.stringify(newParams),
    })

    this.setState({ queryParams: newParams })
  }

  renderDataTable = isHiddenTab => {
    const { merchandisedLandingPages, queryParams } = this.state
    const additionalParams = [
      { key: 'order_by', value: 'position' },
      {
        key: 'placement_id',
        value: merchandisedLandingPages ? merchandisedLandingPages.id : null,
      },
    ]

    if (isHiddenTab) {
      additionalParams.push({ key: 'status', value: 'hidden' })
    } else {
      additionalParams.push(
        {
          key: 'expired',
          value: queryParams.expired,
        },
        {
          key: 'status',
          value: 'NONHIDDEN',
        }
      )
    }

    return (
      <DataTableWithStateAsync
        key={`${PAGE_ID}-${queryParams.expired}-${isHiddenTab}`}
        fetchItems={(qs, config) => {
          return this.buildFetchFunction(qs, config, additionalParams).then(
            merchandisedLandingPages => {
              if (!isHiddenTab) {
                merchandisedLandingPages.items = merchandisedLandingPages.items.filter(
                  ({ status }) => status !== 'hidden'
                )
              }
              return merchandisedLandingPages
            }
          )
        }}
        formatTableRows={items => formatTableRows({ ...this.props, items })}
        headers={tableHeaders}
        id={`${PAGE_ID}-shown-table`}
        isSelectable={false}
        onDragEnd={this.onDragEnd}
        renderProps={{
          isDraggable: !isHiddenTab && queryParams.expired === 'false',
        }}
      />
    )
  }

  render() {
    const { classes, location } = this.props
    const { merchandisedLandingPages, isFetchingMLPs, queryParams, selectedTab } = this.state

    return !isFetchingMLPs && merchandisedLandingPages ? (
      <Fragment>
        <PageHeader headerTitle={PAGE_TITLE}>
          <div className={classes.rightOfHeaderContainer}>
            {selectedTab === 0 && (
              <Dropdown
                id="mlps-expired"
                items={filterOptions.expired}
                onChange={value => this.setFilter('expired', value)}
                placeholder="Status"
                selectedValue={queryParams.expired}
              />
            )}
            <Button
              href={{
                pathname: getRoutePathname(
                  'merchandisedLandingPages.createMerchandisedLandingPage',
                  {
                    id: merchandisedLandingPages.id,
                  }
                ),
                state: {
                  backButton: generateBackButton('merchandisedLandingPages', location),
                },
              }}
            >
              Create New
            </Button>
          </div>
        </PageHeader>
        <Tabs noContentPadding onSelectTab={this.selectTab} selectedTab={selectedTab}>
          <Tab label="Show in Navigation">
            <Notification
              hideBorder
              kind="info"
              message="You are looking at the prioritized list of how the placement will display for a particular store group or user segment."
            />
            {this.renderDataTable(false)}
          </Tab>
          <Tab label="Hidden from Navigation">{this.renderDataTable(true)}</Tab>
        </Tabs>
      </Fragment>
    ) : null
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createToast,
    },
    dispatch
  )

export default injectSheet(styles)(connect(null, mapDispatchToProps)(MerchandisedLandingPages))
