import React from 'react'
import memoize from 'memoize-one'
import injectSheet from 'react-jss'

import { fetchRecipeKeywordRules, createRecipeKeywordRule } from 'api'
import { Modal } from 'components'
import { TableView } from 'layouts'
import { formatDatetime } from 'utils'

import { generateBackButton, getRoutePathname } from 'routing'

import { CreateKeywordRule } from './CreateKeywordRule'

// Table Configurations
const PAGE_ID = 'recipe_rules'
const PAGE_TITLE = 'Recipes Keyword Rules'
const FETCH_FUNCTION = fetchRecipeKeywordRules

const styles = theme => ({
  form: {
    width: 450,
  },
})

const tableParams = {
  paging: {
    type: 'multi',
    multiParams: { limit: { key: 'limit' }, offset: { key: 'offset' } },
  },
  search: { key: 'search_term' },
  sorting: {
    type: 'multi',
    multiParams: { direction: { key: 'direction' }, orderBy: { key: 'order_by' } },
  },
}

const tableHeaders = [
  {
    key: 'name',
    header: 'Keyword',
    disableSorting: true,
  },
  {
    key: 'excludeCategories',
    header: 'Excluded Categories',
    disableSorting: true,
  },
  {
    key: 'boostCategories',
    header: 'Boosted Categories',
    disableSorting: true,
  },
  {
    key: 'boostTags',
    header: 'Tags Boosted',
    disableSorting: true,
  },
  {
    key: 'modified',
    header: 'Last Edited',
  },
]

const formatTableRows = memoize(({ items, location }) =>
  items.map(keywordRules => {
    const { boostTags, boostCategories, excludeCategories, id, name, modified } = keywordRules
    return {
      id: `recipekeywordrule-${id}`,
      rowLinkTo: {
        pathname: getRoutePathname('searchSettings.recipeKeywordRules.editRecipeKeywordRule', {
          id,
        }),
        state: { backButton: generateBackButton('products', location) },
      },
      name,
      excludeCategories: excludeCategories.length,
      boostTags: boostTags.length,
      boostCategories: boostCategories.length,
      modified: formatDatetime(modified),
    }
  })
)

const formatTableActions = (history, location) => ({ refetchItems }) => [
  {
    id: `${PAGE_ID}-create`,
    content: 'Create Recipe Search Rule',
    action: 'modal',
    permission: 'recipe_search_rules.create',
    actionComponent: Modal,
    modalOptions: {
      handleCloseModal: returnValues => {
        // go to edit if name has been saved
        if (returnValues.name) {
          history.push({
            pathname: getRoutePathname('searchSettings.recipeKeywordRules.editKeywordRule', {
              id: returnValues.id,
            }),
            state: {
              backButton: generateBackButton('searchSettings.recipeKeywordRules', location),
            },
          })
        } else {
          history.push({
            pathname: getRoutePathname('searchSettings.recipeKeywordRules'),
          })
        }
      },
      render: ({ closeModal }) => (
        <CreateKeywordRule
          afterFormSubmit={returnValues => {
            closeModal(returnValues)
          }}
          onFormSubmit={formValues => createRecipeKeywordRule(formValues)}
          handleCancel={closeModal}
        />
      ),
    },
  },
]

const RecipeKeywordRules = props => (
  <TableView
    {...props}
    queryKey="recipeKeywordRules"
    fetchFunction={FETCH_FUNCTION}
    pageId={PAGE_ID}
    pageTitle={PAGE_TITLE}
    pageType={PAGE_ID}
    tableActions={formatTableActions(props.history, props.location)}
    tableHeaders={tableHeaders}
    tableParams={tableParams}
    tableRows={formatTableRows}
  />
)

export default injectSheet(styles)(RecipeKeywordRules)
