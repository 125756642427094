export default theme => ({
  pageContainer: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: 1136,
    margin: '0 auto',
  },
  filterSectionAnd: {
    backgroundColor: 'white',
    fontSize: 9,
    fontWeight: 700,
    textTransform: 'uppercase',
    color: theme.text01,
    position: 'absolute',
    top: 'calc(-1rem - 9px / 2 - 1px)',
  },
  filterSectionHeading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem',
  },
  filterSectionHeadingLeft: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  filterSectionHeadingRight: {
    color: theme.text01,
    fontSize: theme.fontSize.small,
  },
  filterSectionHeadingText: {
    fontSize: theme.fontSize.p,
    color: theme.text01,
    marginLeft: '1em',
    border: 'none',
    background: 'none',

    '&:hover': {
      cursor: 'pointer',
    },
  },
  filterSectionContent: {
    display: 'flex',
    padding: '0 1rem 1rem 3.5rem',
  },
  addProductsContainer: {
    display: 'flex',
    marginBottom: theme.spacing.lg,

    '& > *': {
      flex: 1,
    },
  },
  addProductsTypeContainer: {
    flex: '0 1 auto',
    marginRight: theme.spacing.md,
    flexBasis: '25%',
  },
  addRulesContainer: {
    marginBottom: theme.spacing.lg,
    backgroundColor: 'white',
    borderRadius: 5,
    border: theme.inputBorder,
    '& > :not(:first-child)': {
      borderTop: theme.inputBorder,
    },
  },
  dynamicRulesSection: {
    borderLeft: `3px solid ${theme.brand01}`,
    padding: '2rem',
    borderRadius: 4,
    backgroundColor: theme.bgGreyExtraLight,
  },
  dynamicRulesFooter: {
    padding: '1rem',
  },
  addRulesHeader: {
    padding: '1rem 1rem 0',
  },
  addRulesHelpText: {
    color: theme.text01,
    fontSize: theme.fontSize.small,
    marginBottom: 20,
    wordBreak: 'break-word',
  },
  addRulesTypeContainer: {
    flex: '0 1 auto',
    marginRight: theme.spacing.md,
    width: 240,
  },
  createCollectionMenu: {
    alignSelf: 'flex-start',
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'flex-end',
    marginRight: theme.spacing.md,
    paddingTop: theme.spacing.lg,
    position: 'sticky',
    top: 0,
    width: 176,
    '& > li': {
      marginBottom: theme.spacing.md,
      position: 'relative',
      '&:before': {
        backgroundColor: theme.borderGreyLight,
        content: '""',
        height: 32,
        left: 1,
        position: 'absolute',
        bottom: 3,
        width: 2,
      },
      '& > a': {
        color: theme.text01,
        display: 'block',
        fontSize: theme.fontSize.p,
        fontFamily: theme.fontFamilySansSerif,
        paddingLeft: theme.spacing.md,
        textDecoration: 'none',
        '&:before': {
          backgroundColor: theme.borderGreyLight,
          borderRadius: 8,
          content: '""',
          height: 8,
          left: '-2px',
          position: 'absolute',
          top: 3,
          width: 8,
        },
        active: {
          color: theme.brand01,
        },
      },
      '&.scroll-active': {
        '& ~ li': {
          fontWeight: 600,
          '& > a': {
            color: theme.text01,
            fontWeight: 600,
            '&:before': {
              backgroundColor: theme.text01,
            },
          },
        },
        '& > a': {
          color: theme.brand00,
          fontWeight: 600,
          '&:before': {
            backgroundColor: theme.brand00,
          },
        },
      },
      '&:last-child': {
        '&:before': {
          height: 0,
        },
      },
    },
  },
  createCollectionContainer: {
    paddingTop: theme.spacing.lg,
    paddingBottom: 46,
  },
  createCollectionTitle: {
    color: theme.text01,
    fontSize: theme.fontSize.h2,
    fontFamily: theme.fontFamilySansSerif,
    marginBottom: theme.spacing.md,

    '&:not(:first-child)': {
      paddingTop: theme.spacing.lg,
    },
  },
  createCollectionSubtitle: {
    color: theme.text01,
    fontSize: theme.fontSize.h3,
    fontFamily: theme.fontFamilySansSerif,
    marginBottom: theme.spacing.md,
  },
  collectionItemRowProductCellProductText: {
    '& > *': {
      marginBottom: theme.spacing.xxs,

      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  descriptionCount: {
    bottom: theme.spacing.xs,
    color: theme.text01,
    fontSize: theme.fontSize.p,
    right: theme.spacing.xs,
    position: 'absolute',
  },
  fieldHide: {
    display: 'none',
  },
  fieldShow: {
    display: 'block',
  },
  formContainer: {
    width: '100%',
  },
  footerContainer: {
    extend: 'pageContainer',
    alignItems: 'center',
    maxWidth: theme.containerWidth,
    margin: '0 auto',
    minHeight: 64,
    paddingBottom: theme.spacing.xs,
    paddingTop: theme.spacing.xs,
    position: 'relative',
  },
  footerErrors: {
    alignSelf: 'center',
    flex: 1,
    marginLeft: theme.spacing.xxlg,
  },
  formFooter: {
    background: '#FFFFFF',
    borderTop: `2px solid ${theme.bgGreyExtraLight}`,
    bottom: 0,
    left: 0,
    right: 0,
    padding: '0 115px',
    position: 'fixed',
  },
  formInput: {
    maxWidth: 528,
    position: 'relative',
    width: '100%',
  },
  formRow: {
    marginBottom: theme.spacing.lg,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  formRowWithMarginTop: {
    extend: 'formRow',
    marginTop: theme.spacing.lg,
  },
  formRowWithMarginBottom: {
    extend: 'formRow',
    marginBottom: theme.spacing.lg,
  },
  noProductsText: {
    color: theme.text01,
    fontSize: theme.fontSize.p,
    marginTop: theme.spacing.md,
  },
  productErrorContainer: {
    marginBottom: theme.spacing.xlg,
  },
  tooltipIcon: {
    marginBottom: '-0.375em',
    marginLeft: '0.5em',
    width: '0.75em',
  },
})
