export default theme => ({
  filtersBottom: {
    display: 'flex',
    flexGrow: 1,
    marginBottom: theme.spacing.lg,

    '& > *': {
      marginBottom: 0,
      maxWidth: 230,
      marginRight: theme.spacing.xs,
      minWidth: 100,

      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  rightOfHeaderContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',

    '& > *': {
      marginBottom: 0,
      marginLeft: theme.spacing.md,
      minWidth: 100,

      '&:first-child': {
        marginLeft: theme.spacing.xs,
      },
    },
  },
  nameWithImage: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    whiteSpace: 'no-wrap',
  },
  imageContainer: {
    backgroundColor: theme.bgGreyExtraLight,
    display: 'flex',
    height: 30,
    justifyContent: 'center',
    marginRight: theme.spacing.md,
    width: 30,
    '& > img': {
      maxHeight: 30,
      maxWidth: 30,
    },
  },
  searchInput: {
    maxWidth: 340,
    width: 340,
  },
})
