import { queryString } from 'utils'

// Function used to extract param values for each key defined in `paramsToParse` according to their type,
// and add onChange callbacks for each param. Each of these can then be used during the rendering of
// filters, page #, sorting, etc. for a table of items which need to update after a change to a filter, page #, etc.
export const parseParams = ({ paramsToParse, routeQueryString }) => {
  const parsedQuery = queryString.parse(routeQueryString)

  return Object.keys(paramsToParse).reduce((parsedParams, paramName) => {
    const param = paramsToParse[paramName]
    const parsedParam = { ...param }

    if (param.type === 'multi') {
      const { multiParams } = param
      const multiParamNames = Object.keys(multiParams)

      multiParamNames.forEach(multiParamName => {
        const multiParam = multiParams[multiParamName]
        const multiParamValue = parsedQuery[multiParam.key]
        parsedParam.multiParams[multiParamName] = {
          ...multiParam,
          value: multiParamValue,
        }
      })
    } else if (param.type === 'range') {
      const paramValueMin = parsedQuery[`${param.key}_lower`]
      const paramValueMax = parsedQuery[`${param.key}_upper`]

      parsedParam.value = { from: paramValueMin, to: paramValueMax }
    } else {
      const paramValue = parsedQuery[param.key]
      if (param.type === 'array') {
        // Ensure params of type arrays are always stored inside arrays
        // (queryString will parse them as an array if there are multiple of that query param,
        //  otherwise it will be parsed as a string. If that is the case, stuff it in an array.)
        const newParamValue = Array.isArray(paramValue)
          ? paramValue
          : paramValue
          ? [paramValue]
          : [] // Store as empty array if the param is not in the query (undefined)

        parsedParam.value = newParamValue
      } else {
        parsedParam.value = paramValue
      }
    }

    parsedParams[paramName] = parsedParam
    return parsedParams
  }, {})
}

/**
 * Take custom param oject with values and convert it to a key (param name) and value pair.
 *
 * @param {Object} params - Formatted params, using the same format outputted from parseParams.
 * @returns {Object} Key value pairs of params that can be used to format the query string.
 */
export const paramsToKeyValuePairs = params => {
  const paramKeyValuePairs = Object.keys(params).reduce((paramValuesByKey, paramName) => {
    const param = params[paramName]

    if (param.type === 'multi') {
      const { multiParams } = param
      Object.keys(multiParams).forEach(multiParamName => {
        const multiParam = multiParams[multiParamName]
        paramValuesByKey[multiParam.key] = multiParam.value
      })
    } else if (param.type === 'range') {
      paramValuesByKey[`${param.key}_lower`] = param.value.from
      paramValuesByKey[`${param.key}_upper`] = param.value.to
    } else {
      paramValuesByKey[param.key] = param.value
    }

    return paramValuesByKey
  }, {})

  return paramKeyValuePairs
}

// Function used to take param values and put them into a string react-router can use
// to update the URL and trigger a refetch
export const formatParams = params => {
  return queryString.stringify(paramsToKeyValuePairs(params))
}

export const reloadWithNewFilters = ({ newParams, history }) => {
  const { location } = history
  const currentPathname = location.pathname
  // Reload page with new filters
  history.push({
    pathname: currentPathname,
    search: formatParams(newParams),
  })
}

export const generateOnParamChange = ({ paramName, params, history }) => newValue => {
  const newParams = { ...params }
  newParams[paramName].value = newValue

  // Reset `paging` on filter change (navigate to 1st page)
  if (paramName !== 'paging' && paramName !== 'sorting') {
    delete newParams.paging
  }
  reloadWithNewFilters({ newParams, history })
}

export const generateOnMultiParamsChange = ({
  paramName,
  multiParamNames,
  params,
  history,
}) => newValues => {
  const newParams = { ...params }
  multiParamNames.forEach(multiParamName => {
    newParams[paramName].multiParams[multiParamName].value = newValues[multiParamName]
  })

  // Reset `paging` on filter change (navigate to 1st page)
  if (paramName !== 'paging' && paramName !== 'sorting') {
    delete newParams.paging
  }

  reloadWithNewFilters({ newParams, history })
}

// Add onChange callbacks to each param provided, return new params object
export const generateNewParamsWithOnChangeHandlers = ({ params, history }) =>
  Object.keys(params).reduce((newParamsObj, paramName) => {
    const param = params[paramName]

    let onChange
    if (param.type === 'multi') {
      const { multiParams } = param
      const multiParamNames = Object.keys(multiParams)

      onChange = generateOnMultiParamsChange({ paramName, multiParamNames, params, history })
    } else {
      onChange = generateOnParamChange({ paramName, params, history })
    }

    newParamsObj[paramName] = { ...param, onChange }

    return newParamsObj
  }, {})

/**
 * Takes the data response from React Query and returns the items in an array, as well as other data
 * from the requset.
 *
 * @param {array} data - `data` response from React Query
 * @param {*} hasInfinitePagination - Whether "load more" pagination is enabled in React Query
 */
export const getItemsFromData = (data, hasInfinitePagination) => {
  if (hasInfinitePagination) {
    return data.reduce(
      (reducedData, page) => {
        const { items, ...otherData } = page
        // otherData is always data from the last page
        return { items: [...reducedData.items, ...page.items], otherData }
      },
      { items: [], otherData: {} }
    )
  }

  const { items, ...otherData } = data || { items: [] }

  // This is a workaround to force items to always be an array
  // Seems like other data overwrites items in some cases which broke the search
  const updatedItems = items || []

  return { items: updatedItems, otherData }
}
