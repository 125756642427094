import get from 'lodash/get'
import moment from 'moment'

import { fetchCustomerSessionToken } from 'api'
import config from 'config'

import queryStringOriginal from './queryString'

// TODO: Make custom `get` where you can have null or undefined cause fallback
// (and not just undefined which is what lodash's get appears to do)
export { get }

export const queryString = {
  ...queryStringOriginal,
  stringify: params => {
    // Omit keys with null or empty string values (if the values are undefined queryString.stringify does not include them)
    const paramsToStringify = Object.keys(params).reduce((paramsToStringify, paramKey) => {
      if (params[paramKey] !== null && params[paramKey] !== '') {
        paramsToStringify[paramKey] = params[paramKey]
      }
      return paramsToStringify
    }, {})

    return queryStringOriginal.stringify(paramsToStringify)
  },
}

export { default as ClickHandler } from './ClickHandler'
export { default as ItemFetcher } from './ItemFetcher'
export { default as ItemsFetcher } from './ItemsFetcher'
export { default as ItemsFetcherWithParams } from './ItemsFetcherWithParams'
export { default as StateHolder } from './StateHolder'
export { default as wrapComponent } from './wrapComponent'
export { default as printPDF } from './printPDF'
export { useItemFetcher } from './itemFetcherHook'
export { useItemsFetcher } from './itemsFetcherHook'
export { useItemsFetcherWithParams } from './itemsFetcherWithParamsHook'
export { downloadImageAsBase64String } from './imageDownloader'

/* Returns a query string with limit param added if not already included in `locationSearchString` */
export const addLimitToQueryString = (locationSearchString, limit) => {
  const parsedQuery = queryString.parse(locationSearchString)
  const queryContainsLimit = parsedQuery && !!parsedQuery.limit

  if (queryContainsLimit) {
    return locationSearchString
  }
  return locationSearchString ? `${locationSearchString}&limit=${limit}` : `?limit=${limit}`
}

/* Add a generic property to a query string` */
export const addValueToQueryString = (string, keyValue = {}) => {
  const { key, value } = keyValue

  if (!key || !value) {
    return string
  }

  const parsedQuery = queryString.parse(string)
  const queryContainsLimit = parsedQuery && !!parsedQuery[key]

  if (queryContainsLimit) {
    return string
  }
  return string ? `${string}&${key}=${value}` : `?${key}=${value}`
}

export const capitalize = ({ phrase, separator = ' ' }) =>
  (separator !== ' ' ? phrase.split(separator).join(' ') : phrase).replace(/\b\w/g, firstLetter =>
    firstLetter.toUpperCase()
  )

export const lowercase = ({ phrase = '' }) => (phrase ? phrase.toLowerCase() : '')

/**
 * Generic utility to compose event handlers. The default heuristic here is to
 * iterate through the given functions until `preventDefault` is called on the
 * given event.
 *
 * @param {Array<Function>} fns array of functions to apply to the event
 * @returns {Function}
 */
export const composeEventHandlers = fns => (event, ...args) => {
  for (let i = 0; i < fns.length; i++) {
    if (event.defaultPrevented) {
      break
    }
    if (typeof fns[i] === 'function') {
      fns[i](event, ...args)
    }
  }
}

export const createSlugFromName = name =>
  name
    .toLowerCase()
    // this is to prevent 'a - b' from becoming 'a---b'
    .replace(/\s*-\s*/, '-')
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')

const getAddressLines = (fields, address, separator) =>
  fields
    .map(field => address[field])
    .filter(line => line !== null)
    .join(separator)

const addressFields = ['address1', 'address2', 'address3']
export const formatAddress = deliveryAddress =>
  [
    getAddressLines(['firstName', 'lastName'], deliveryAddress, ' '),
    getAddressLines(addressFields, deliveryAddress, '\n'),
    getAddressLines(['city', 'province'], deliveryAddress, ', '),
    getAddressLines(['postalCode', 'country'], deliveryAddress, '\n'),
  ]
    .filter(line => line !== '')
    .join('\n')

export const formatRange = ({
  from,
  to,
  whenFromAndToDifferent,
  whenFromAndToEqual,
  whenOnlyFrom,
  whenOnlyTo,
  formatString = string => string,
}) => {
  if (from && to) {
    const formattedFrom = formatString(from)
    const formattedTo = formatString(to)

    if (formattedFrom === formattedTo) {
      return whenFromAndToEqual
        ? whenFromAndToEqual({ formattedValue: formattedFrom })
        : formattedFrom
    }
    return whenFromAndToDifferent
      ? whenFromAndToDifferent({ formattedFrom, formattedTo })
      : `between ${formattedFrom} and ${formattedTo}`
  }
  if (from) {
    const formattedFrom = formatString(from)
    return whenOnlyFrom ? whenOnlyFrom({ formattedFrom }) : `${formattedFrom} and up`
  }
  if (to) {
    const formattedTo = formatString(to)
    return whenOnlyTo ? whenOnlyTo({ formattedTo }) : `up to ${formattedTo}`
  }
}

export const formatPhoneNumber = phoneNumber =>
  phoneNumber.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3')

export const formatDate = (date, fallback = 'N/A') =>
  date ? moment(date).format('MMM D YYYY') : fallback

export const formatTime = (datetime, fallback = 'N/A') =>
  datetime ? moment(datetime).format('h:mm A') : fallback

export const formatTimeCustom = (datetime, inputFormat, fallback = 'N/A') =>
  datetime ? moment(datetime, inputFormat).format('h:mm A') : fallback

export const formatDatetime = (datetime, fallback = 'N/A') =>
  datetime ? moment(datetime).format('MMM D YYYY h:mm A') : fallback

export const formatDateRange = ({ from, to, formatString = formatDate }) =>
  formatRange({
    from,
    to,
    whenFromAndToEqual: ({ formattedValue }) => `on ${formattedValue}`,
    whenOnlyFrom: ({ formattedFrom }) => `after ${formattedFrom}`,
    whenOnlyTo: ({ formattedTo }) => `before ${formattedTo}`,
    formatString,
  }) || 'N/A'

export const generateGetNewInstanceId = () => {
  let instanceId = 0
  return function getInstanceId() {
    return ++instanceId
  }
}

// Gets value in `object` specified in `path` and if it is defined and is not null, call `funcToApply` on the result
export const getAndApply = (object, path, funcToApply, fallback) => {
  const valueToGet = get(object, path)
  if (isNil(valueToGet)) {
    return fallback
  }
  return funcToApply(valueToGet)
}

export const getPersonName = ({ person, fallback = 'N/A' }) => {
  if (!person) return fallback

  return person.firstName || person.lastName
    ? `${person.firstName} ${person.lastName || ''}`.trim()
    : fallback
}

export function isNil(value) {
  return value == null
}

// TODO - replace isNil with isNullOrUndefined. will require some testing to make sure things don't break
export function isNullOrUndefined(value) {
  return value === null || value === undefined
}

export const isObject = val => typeof val === 'object' && val !== undefined

export const openWebPlatform = path => {
  let webPlatformUrl = config.env.webUrl
  if (!/^(https?):\/\//.test(webPlatformUrl)) {
    webPlatformUrl = `https://${webPlatformUrl}`
  }
  if (!webPlatformUrl.endsWith('/')) {
    webPlatformUrl = `${webPlatformUrl}/`
  }
  window.open(`${webPlatformUrl}${path}`, '_blank')
}

export const openWebPlatformAsCustomer = (customerId, path = '') =>
  fetchCustomerSessionToken(customerId).then(({ sessionToken }) => {
    openWebPlatform(`${path}?session-token=${sessionToken}&private=true`)
  })

export const openV1Dashboard = path => {
  const v2Origin = window.location.origin
  // Try to replace the old url style (dashboardv2-dev-dx1.unataops.com)
  let v1Origin = v2Origin.replace('dashboardv2-', 'dashboard-')
  // Try to replace the new url style (dev-dx1-dashboard-v2.unataops.com)
  v1Origin = v1Origin.replace('-dashboard-v2', '-dashboard')

  window.location.href = `${v1Origin}/#${path}`
}

// Generic utility for safely removing an element at a given index from an array.
export const removeAtIndex = (array, index) => {
  const result = array.slice()
  result.splice(index, 1)
  return result
}

export const snakeToCamelCase = string =>
  string ? string.replace(/(_\w)/g, matches => matches[1].toUpperCase()) : null

export const snakeToKebab = string => (string ? string.replace(/_/g, `-`) : null)

export const camelCaseToCapitalized = string =>
  string ? capitalize({ phrase: string.replace(/([A-Z])/g, match => ` ${match}`) }) : null

export const snakeToCapitalized = string => camelCaseToCapitalized(snakeToCamelCase(string))

/**
 * Converts a camel case string to kebab case
 * example: aCamelCaseString -> a-camel-case-string
 *
 * @param {string} string
 * @returns {String}
 */
export const camelCaseToKebab = string =>
  string ? string.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase() : ''

export const kebabToSnake = string => (string ? string.replace(/-/g, `_`) : null)

export const toCurrency = string => {
  const parsed = parseFloat(string, 10)
  return isNaN(parsed) ? null : `$${parsed.toFixed(2, 10)}`
}

export const toNegativeCurrency = string => `(${toCurrency(string)})`

export const absoluteUrl = string => {
  let fullUrl
  const httpProtocol = 'http://'
  const secureHttpProtocol = 'https://'

  if (string.indexOf(httpProtocol) === 0 || string.indexOf(secureHttpProtocol) === 0) {
    fullUrl = string
  } else {
    fullUrl = httpProtocol + string
  }

  return fullUrl
}

/**
 * Helper function to help preseve input order that gets destroyed by the TreeSelect
 * component (which is unaware of order concerns)
 * @param {{ id: number }[]} inputOrder The input array whose order should be respected
 * @param {(number | { id: number })[]} targetArray The newly-selected values that should be reordered
 * @returns {(number | { id: number })[]} a reordered targetArray
 */
export const matchInputOrder = (inputOrder, targetArray) => {
  inputOrder = inputOrder.map(({ id }) => id)
  const sorted = targetArray.slice().sort((a, b) => {
    const aKey = typeof a === 'object' ? a.id : a
    const bKey = typeof b === 'object' ? b.id : b

    const aIndex = inputOrder.indexOf(aKey)
    const bIndex = inputOrder.indexOf(bKey)

    // Sort new items into the end of the array
    if (aIndex === -1) {
      return 1
    }

    if (bIndex === -1) {
      return -1
    }

    return aIndex - bIndex
  })

  return sorted
}

/**
 * Filter to remove keys with undefined values.
 *
 * @param {Object} inputObject - Object to filter
 * @returns {Object} Filtered object
 */
export const filterUndefinedValues = inputObject =>
  Object.keys(inputObject).reduce((acc, key) => {
    if (inputObject[key] !== undefined) {
      return { ...acc, [key]: inputObject[key] }
    }
    return acc
  }, {})

/**
 * Checks whether or not an object is empty
 *
 * @param {Object} inputObject - Object to check
 * @returns {boolean} Whether object is empty
 */
export const isEmpty = inputObject =>
  Object.keys(inputObject).length === 0 && inputObject.constructor === Object

// Get new random id number?
// id = (this._inputId =
//   this._inputId ||
//   `search__input__id_${Math.random()
//     .toString(36)
//     .substr(2)}`),
