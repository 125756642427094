const focusOutline = ({ type = 'border', theme }) => {
  if (type === 'border') {
    return {
      outline: 'none',
      border: `1px solid ${theme.brand01}`,
      boxShadow: 'inset 0 1px 3px 0 rgba(107,123,132,0.3), 1px 1px 3px 0 rgba(2,147,204,0.2)',
    }
  }
  if (type === 'blurred') {
    return {
      outline: '-webkit-focus-ring-color auto 5px',
    }
  }
}

export default {
  checkbox: ({ theme }) => ({
    label: {
      default: {
        color: theme.text01,
        cursor: 'pointer',
        fontFamily: theme.fontFamilySansSerif,
        fontSize: theme.fontSize.p,
        minHeight: 16,
      },
      bold: {
        fontWeight: 600,
      },
      disabled: {
        color: theme.text02,
        cursor: 'not-allowed',
      },
    },
    square: {
      default: {
        backgroundColor: '#FFFFFF',
        border: `1px solid ${theme.text02}`,
        borderRadius: 2,
        boxSizing: 'border-box',
        content: '""',
        height: 16,
        width: 16,
      },
      hover: {
        border: `1px solid ${theme.brand00}`,
        boxShadow: `inset 0 0 1px 1px ${theme.brand01}`,
      },
      focus: {
        ...focusOutline({ type: 'blurred', theme }),
      },
      disabled: {
        backgroundColor: theme.bgGreyExtraLight,
        borderColor: theme.borderGreyLight,
      },
      checked: {
        backgroundColor: theme.brand01,
        borderColor: theme.brand01,
      },
      indeterminate: {
        backgroundColor: theme.bgSelected,
        borderColor: theme.bgSelected,
      },
    },
    checkmark: {
      default: {
        background: 'none',
        content: '""',
        height: '4px',
        marginTop: -4,
        position: 'absolute',
        top: '50%',
        transform: 'scale(0) rotate(-45deg)',
        width: 8,
      },
      disabled: {
        borderColor: theme.borderGreyLight,
      },
      checked: {
        borderBottom: `2px solid ${theme.inverse01}`,
        borderLeft: `2px solid ${theme.inverse01}`,
        left: 3,
        opacity: 1,
        transform: 'scale(1) rotate(-45deg)',
      },
      indeterminate: {
        borderBottom: `2px solid ${theme.text02}`,
        borderLeft: `0 solid ${theme.text02}`,
        left: 4,
        marginTop: -5,
        opacity: '1',
        transform: 'scale(1) rotate(0deg)',
      },
    },
  }),
  focusOutline,
  formItem: ({ theme }) => ({
    default: {
      fontFamily: theme.fontFamilySansSerif,
      flex: '1',
    },
    horizontal: {
      marginRight: theme.spacing.md,
      '&:last-of-type': {
        marginRight: 0,
      },
    },
    vertical: {
      marginBottom: theme.spacing.lg,
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
  }),
  hidden: {
    position: 'absolute',
    width: '1px',
    height: '1px',
    padding: '0',
    margin: '-1px',
    overflow: 'hidden',
    clip: 'rect(0, 0, 0, 0)',
    border: '0',
    visibility: 'visible',
    whiteSpace: 'nowrap',
  },
  inputField: ({ theme }) => ({
    backgroundColor: theme.field01,
    border: theme.inputBorder,
    borderRadius: theme.inputBorderRadius,
    color: theme.text01,
    display: 'block',
    fontFamily: theme.fontFamilySansSerif,
    fontSize: theme.fontSize.body,
    fontWeight: theme.inputValueWeight,
    height: 32,
    padding: '0',
    // Separate paddingLeft/Right so they can be used in calculations
    paddingLeft: 10,
    paddingRight: 10,
    textOverflow: 'ellipsis',
    width: '100%',

    '&::placeholder, &::-webkit-input-placeholder': {
      color: theme.text02,
      fontWeight: theme.inputPlaceholderWeight,
    },

    '&:focus': {
      ...focusOutline({ theme }),
    },

    '&:disabled': {
      background: theme.bgGreyExtraLight,
      border: theme.inputBorder,
      cursor: 'not-allowed',
      color: theme.text02,
    },

    invalid: {
      backgroundColor: theme.support01InputBackground,
      border: `1px solid ${theme.support01}`,
    },
    withValue: {
      borderColor: theme.text01,
    },
  }),
  labelText: ({ theme }) => ({
    color: theme.text01,
    fontFamily: theme.fontFamilySansSerif,
    fontSize: theme.fontSize.label,
    fontWeight: theme.inputLabelWeight,
    textTransform: 'uppercase',
  }),
  multiField: {
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'space-between',
  },
  multiFieldChild: {
    margin: 0,
    width: 'calc(50% - 9px)',
  },
  resets: {
    button: {
      appearance: 'none',
      background: 'none',
      border: '0',
      cursor: 'pointer',
      display: 'inline-block',
      width: '100%',

      '&::-moz-focus-inner': {
        border: '0',
      },
    },
  },
  textOverflowEllipsis: {
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}
