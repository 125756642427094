import React from 'react'
import injectSheet from 'react-jss'
import { Formik } from 'formik'
import { BooleanValue } from 'react-values'

import config from 'config'
import Styling from 'styling/components'
import { Modal, Button, Checkbox, FormGroup, FormFieldItem, RadioButtons } from 'components'

const styles = theme => ({
  buttonRow: {},
  modalFormGroup: {
    marginBottom: theme.spacing.sm,
  },
})

const BASE_ID = 'edit-global-search-settings'

const EditGlobalSearchSettingsModalContent = ({
  initialValues: values,
  closeModal,
  handleSubmit,
  classes,
}) => {
  return (
    <Formik
      initialValues={values}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={(values, form) => {
        handleSubmit(values, form)
          .then(() => {
            form.setSubmitting(false)
            return closeModal()
          })
          .catch(error => {
            form.setSubmitting(false)
            form.setErrors({ global: error.message })
          })
      }}
      render={({ setFieldValue, values, submitForm }) => {
        // TODO: Call the parent-level form submission handler
        return (
          <>
            <FormGroup
              label="Personalization"
              orientation="vertical"
              className={classes.modalFormGroup}
              items={[
                <BooleanValue
                  value={values.boostPastPurchases}
                  onChange={value => setFieldValue('boostPastPurchases', value)}
                >
                  {({ toggle, value }) => (
                    <Checkbox
                      checked={value}
                      id={`${BASE_ID}-boost-past-purchases-checkbox`}
                      labelText="Prioritize Past Purchase"
                      onChange={toggle}
                    />
                  )}
                </BooleanValue>,
              ]}
            />

            <FormFieldItem
              id={`${BASE_ID}-featured`}
              labelText="Category Display Order"
              name="sortCategoriesBy"
              render={({ id, value }) => {
                return (
                  <RadioButtons
                    id={id}
                    onChange={category_order => {
                      setFieldValue('sortCategoriesBy', category_order)
                    }}
                    orientation="horizontal"
                    radioButtonProps={[
                      {
                        id: 'alpha',
                        labelText: 'Alphabetical',
                        value: 'alpha',
                      },
                      {
                        id: 'count',
                        labelText: 'Result count (descending)',
                        value: 'count',
                      },
                    ]}
                    selectedValue={value}
                  />
                )
              }}
            />

            <FormGroup
              label="Feature Configuration"
              orientation="vertical"
              items={[
                ...(config.enableICSearch
                  ? []
                  : [
                      <BooleanValue
                        value={values.enableBoostBrands}
                        onChange={value => setFieldValue('enableBoostBrands', value)}
                      >
                        {({ toggle, value }) => (
                          <Checkbox
                            checked={value}
                            id={`${BASE_ID}-enable-boost-brands`}
                            labelText="Enable Brand Boosting"
                            onChange={toggle}
                          />
                        )}
                      </BooleanValue>,
                      <BooleanValue
                        value={values.enableBoostCategories}
                        onChange={value => setFieldValue('enableBoostCategories', value)}
                      >
                        {({ toggle, value }) => (
                          <Checkbox
                            checked={value}
                            id={`${BASE_ID}-enable-boost-categories`}
                            labelText="Enable Category Boosting"
                            onChange={toggle}
                          />
                        )}
                      </BooleanValue>,
                    ]),
                <BooleanValue
                  value={values.searchByUpc}
                  onChange={value => setFieldValue('searchByUpc', value)}
                >
                  {({ toggle, value }) => (
                    <Checkbox
                      checked={value}
                      id={`${BASE_ID}-search-by-upc-checkbox`}
                      labelText="Enable Search by UPC"
                      onChange={toggle}
                    />
                  )}
                </BooleanValue>,
              ]}
            />
            <Styling.LineOfButtons>
              <Button onClick={submitForm}>Save</Button>
              <Button kind="link" onClick={closeModal}>
                Cancel
              </Button>
            </Styling.LineOfButtons>
          </>
        )
      }}
    />
  )
}

const EditGlobalSearchSettingsModal = props => {
  return (
    <Modal
      {...props}
      size="large"
      modalHeading="Update Global Search Settings"
      render={renderProps => (
        <EditGlobalSearchSettingsModalContent
          initialValues={props.values}
          {...renderProps}
          {...props}
        />
      )}
    />
  )
}

export default injectSheet(styles)(EditGlobalSearchSettingsModal)
