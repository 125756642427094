import React from 'react'
import injectSheet from 'react-jss'

import { FormFieldItem, AddCategories } from 'components'

import styles from '../PromotedPlacementsForm.styles'

const CATEGORIES_API_PATH = '/categories/'

const formatCategoryArrayObjects = categories => {
  categories.forEach(category => {
    category.href = `${CATEGORIES_API_PATH}${category.id}`
  })

  return categories
}

const PlacementCategories = ({ classes, componentId, componentName, labelText, setFieldValue }) => (
  <FormFieldItem
    className={classes.addCategoryContainer}
    id={componentId}
    labelText={labelText}
    name={componentName}
    render={renderProps => (
      <AddCategories
        {...renderProps}
        onChange={newSelectedCategories => {
          // Backend API expects this value for `target.categories[]` in the payload
          // V1 added this step via an API inteceptor
          newSelectedCategories = formatCategoryArrayObjects(newSelectedCategories)
          setFieldValue(componentName, newSelectedCategories)
        }}
        selectedCategories={renderProps.value}
      />
    )}
  />
)

export default injectSheet(styles)(PlacementCategories)
