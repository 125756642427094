import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import styles from '../CreateOrder.styles'

const useStyles = createUseStyles(styles)

// TODO - replace this with real data
const DEFAULT_TOTALS = [
  { label: 'Subtotal', value: 'n/a' },
  { label: 'Tax', value: 'n/a' },
  { label: 'Total', value: 'n/a' },
]

const Totals = ({ totals = [] }) => {
  const classes = useStyles()

  const formattedTotals = totals.length ? totals : DEFAULT_TOTALS

  return (
    <div className={classes.priceTotalContainer}>
      {formattedTotals.map((total, index) => (
        <div key={`price-row-${index}`} className={classes.priceTotalRow}>
          <div className={cx(classes.priceTotalColumn, classes.priceTotalColumnLabel)}>
            {total.label}
          </div>
          <div className={classes.priceTotalColumn}>{total.value}</div>
        </div>
      ))}
    </div>
  )
}

Totals.propTypes = {
  totals: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
}

export default Totals
