import {
  CATALOG_PLACEMENT,
  SEARCH_PLACEMENT,
  COLLECTION_BANNER,
  MLP_BANNER,
  HERO_BANNERS,
  SLIM_BANNERS,
} from 'constants/placementTypes'

// Default cell-width-based upload dimensions supporting Desktop and Mobile
export const defaultPromotedMediaDimensions = {
  desktop: {
    dimensions: [
      {
        name: '1 Cell',
        span: 1,
        size: { width: 198, height: 198 },
        fullSize: { width: 198, height: 338 },
      },
      {
        name: '2 Cells',
        span: 2,
        size: { width: 428, height: 198 },
        fullSize: { width: 428, height: 338 },
      },
      {
        name: '3 Cells',
        span: 3,
        size: { width: 658, height: 198 },
        fullSize: { width: 658, height: 338 },
      },
      {
        name: '4 Cells',
        span: 4,
        size: { width: 888, height: 198 },
        fullSize: { width: 888, height: 338 },
      },
    ],
    radioButtonProps: [
      {
        id: 'oneCell',
        labelText: '1 Cell',
        value: 1,
      },
      {
        id: 'twoCells',
        labelText: '2 Cells',
        value: 2,
      },
      {
        id: 'threeCells',
        labelText: '3 Cells',
        value: 3,
      },
      {
        id: 'fourCells',
        labelText: '4 Cells',
        value: 4,
      },
    ],
  },
  mobile: {
    dimensions: [
      {
        name: '1 Cell',
        span: 1,
        size: { width: 198, height: 198 },
        fullSize: { width: 198, height: 338 },
      },
      {
        name: '2 Cells',
        span: 2,
        size: { width: 428, height: 198 },
        fullSize: { width: 428, height: 338 },
      },
    ],
    radioButtonProps: [
      {
        id: 'oneCell',
        labelText: '1 Cell',
        value: 1,
      },
      {
        id: 'twoCells',
        labelText: '2 Cells',
        value: 2,
      },
    ],
  },
}

// TODO: temporary hack to have MLP banner upload dimensions to support combined Desktop/Mobile uploads
// This needs to be revisited soon, since currently the DB does not support combined Desktop/Mobile dimensions for singular placements
// unless the placement is using the cell-width-based dimensions
export const mlpBannerUploadDimensions = {
  desktop: {
    height: 338,
    width: 1130,
  },
  mobile: {
    height: 338,
    width: 428,
  },
}

export const heroBannerUploadDimensions = {
  desktop: {
    height: 500,
    width: 1600,
  },
  mobile: {
    height: 500,
    width: 500,
  },
}

// Placements that use the combined Desktop and Mobile uploader based on cell widths
export const useCellWidthDesktopMobileUpload = [
  CATALOG_PLACEMENT,
  SEARCH_PLACEMENT,
  COLLECTION_BANNER,
  MLP_BANNER,
  HERO_BANNERS,
  SLIM_BANNERS,
]
