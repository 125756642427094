import { mixins } from 'styling'

export default theme => {
  const inputField = mixins.inputField({ theme })
  const searchButtonWidth = 32
  const searchButtonHeight = searchButtonWidth

  return {
    container: {
      alignItems: 'center',
      display: 'flex',
      position: 'relative',
    },
    searchInput: {
      ...inputField,
      paddingRight: 25, // Make room for searchInputClear
    },
    searchInputWithSubmit: {
      borderBottomRightRadius: 0,
      // Ensure right border stays 0 so that on focus, $searchButton doesn't get pushed over
      borderRight: '0 !important',
      borderTopRightRadius: 0,
    },
    searchInputWithoutSubmit: {
      paddingLeft: 34, // Make room for searchInputIcon
    },
    searchInputIcon: {
      fill: theme.brand01,
      left: inputField.paddingLeft + 1, // + 1 for input border
      position: 'absolute',
      width: 16,
    },
    searchInputInvalid: {
      ...inputField.invalid,
    },
    searchInputWithValue: {
      '&:not($searchInputInvalid)': inputField.withValue,
    },
    searchInputClear: {
      background: '#FFFFFF',
      height: inputField.height - 10, // don't fill height so we don't obscure input drop shadow
      justifyContent: 'center',
      outlineOffset: -5,
      padding: 0,
      position: 'absolute',
      top: 5, // height is deducted by 10, therefore top = 5
      width: inputField.height - 10, // match height
    },
    searchInputInvalidClear: {
      background: theme.support01InputBackground,
    },
    searchInputWithSubmitClear: {
      right: searchButtonWidth + 4,
    },
    searchInputWithoutSubmitClear: {
      right: 4,
    },
    searchButton: {
      background: theme.brand00,
      border: 0,
      borderBottomRightRadius: inputField.borderRadius,
      borderTopRightRadius: inputField.borderRadius,
      borderRadius: 0,
      cursor: 'pointer',
      flexShrink: 0,
      height: searchButtonHeight,
      overflow: 'hidden',
      padding: 0,
      width: searchButtonWidth,
    },
    searchButtonIcon: {
      fill: theme.inverse01,
      height: '100%',
      width: 16,
    },
  }
}
