import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import injectSheet from 'react-jss'

import { Icon } from 'components'

import styles from './DropdownMenuItem.styles'

const DropdownMenuItem = ({
  children,
  classes,
  className,
  closeMenu,
  disabled: isDisabled,
  icon,
  id,
  kind,
  hasDivider,
  onClick,
  renderButton,
  wrapperClassName,
}) => {
  const handleClick = event => {
    if (onClick) {
      onClick(event)
    }
    closeMenu()
  }

  const buttonClasses = cx(className, classes.dropdownMenuItem, {
    [classes.dropdownMenuItemPrimary]: kind === 'primary',
    [classes.dropdownMenuItemDanger]: kind === 'danger',
    [classes.dropdownMenuItemDisabled]: isDisabled,
  })

  const buttonProps = {
    className: buttonClasses,
    disabled: isDisabled,
    id,
    onClick: handleClick,
    tabIndex: isDisabled ? -1 : 0,
    type: 'button',
  }

  const iconEl = icon ? <Icon className={classes.dropdownMenuItemIcon} name={icon} /> : undefined

  return (
    <li className={wrapperClassName} role="menuitem">
      {hasDivider && <div className={classes.dropdownMenuItemDivider} />}
      {renderButton ? (
        renderButton({ buttonProps, iconEl })
      ) : (
        <button {...buttonProps}>
          {iconEl}
          {children}
        </button>
      )}
    </li>
  )
}

DropdownMenuItem.propTypes = {
  children: PropTypes.node,
  /**
   * Placed on the button element
   */
  className: PropTypes.string,
  /**
   * Callback to alert the parent menu component to close the menu
   */
  closeMenu: PropTypes.func,
  /**
   * Whether to include a divider on top of the menu item
   */
  hasDivider: PropTypes.bool,
  /**
   * Name of icon (optional) to prepend to the DropdownMenuItem content (`children`)
   */
  icon: PropTypes.string,
  /**
   * Whether to style the menu item in a "primary" or "danger" style (colors similar to those in <Button>)
   * (If not specified, default plain styling is used)
   */
  kind: PropTypes.oneOf(['primary', 'danger']),
  onClick: PropTypes.func,
  onMouseUp: PropTypes.func,
  /**
   * ({ buttonProps: { onClick, ... }, iconEl }) => node
   * If provided, allows user to overwrite rendering of button element in the DropdownMenuItem.
   * Otherwise, the user should provide `children` to fill the contents.
   */
  renderButton: PropTypes.func,
  /**
   * Placed on the wrapper list item element
   */
  wrapperClassName: PropTypes.string,
}

DropdownMenuItem.defaultProps = {
  hasDivider: false,
}

DropdownMenuItem.displayName = 'DropdownMenuItem'

export { DropdownMenuItem as DropdownMenuItemUnStyled }
export default injectSheet(styles)(DropdownMenuItem)
