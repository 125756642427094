import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { useInView } from 'react-intersection-observer'

import { Button, LoadingIndicator } from 'components'

import { usePrevious } from 'utils/hooks'

const useStyles = createUseStyles(theme => ({
  tableInfiniteScroll: {
    display: 'flex',
    justifyContent: 'center',
    padding: `${theme.spacing.sm} 0`,
  },
}))

const TableInfiniteScroll = ({ tableId, onLoadMore, hasMore, isLoading }) => {
  const [ref, inView] = useInView({
    threshold: 0,
    rootMargin: '0px 0px 100px 0px',
  })
  const classes = useStyles()

  const previousInView = usePrevious(inView)

  useEffect(() => {
    // Only update if the previous inView was false, and the current inView is true
    // to prevent multiple calls in case other properties cause a rerender.
    const recentlyInView = inView && previousInView === false

    if (onLoadMore && hasMore && !isLoading && recentlyInView) {
      onLoadMore()
    }
  }, [inView, isLoading, onLoadMore, hasMore, previousInView])

  return (
    <div ref={ref} className={classes.tableInfiniteScroll}>
      {isLoading ? (
        <LoadingIndicator.Dots withText />
      ) : (
        <Button onClick={onLoadMore} kind="secondary">
          Load More
        </Button>
      )}
    </div>
  )
}

export default TableInfiniteScroll
