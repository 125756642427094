import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import injectSheet from 'react-jss'

import styles from './ListBoxMenu.styles'

/**
 * `ListBoxMenu` is a simple container node for ListBoxMenuItem children
 */
const ListBoxMenu = ({ children, classes, className, nowrap, setRef, theme, ...restProps }) => {
  return (
    <div
      className={cx(className, classes.listBoxMenu, {
        [classes.nowrap]: nowrap,
      })}
      {...(setRef ? { ref: setRef } : {})}
      {...restProps}
    >
      {children}
    </div>
  )
}

ListBoxMenu.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node, // children should be any of: ListBoxMenuItem
  /**
   * Will set `white-space: nowrap` on the ListBoxMenu
   */
  nowrap: PropTypes.bool.isRequired,
}

ListBoxMenu.defaultProps = {
  nowrap: false,
}

export default injectSheet(styles)(ListBoxMenu)
