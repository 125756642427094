import { mixins } from 'styling'

export default theme => {
  const formItem = mixins.formItem({ theme })
  return {
    listBox: {
      ...formItem.default,
      backgroundColor: '#FFFFFF',
      height: '32px',
      maxHeight: '32px',
      position: 'relative',
      width: '100%',
    },
    listBoxDisabled: {},
    small: {
      height: '24px',
      lineHeight: '20px',
    },
  }
}
