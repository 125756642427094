export const getStoresWithAlcoholStores = stores => {
  const storesWithAlcoholStores = {}
  stores.forEach(store => {
    storesWithAlcoholStores[store.retailer_store_id] = {
      id: store.id,
      ext_id: store.extId,
      name: store.name,
      banner: store.banner,
      retailer_store_id: store.retailer_store_id,
      warehouse_location_id: store.warehouse_location_id,
    }

    if (store.alcohol_retailer_store_id) {
      storesWithAlcoholStores[store.alcohol_retailer_store_id] = {
        id: store.id,
        ext_id: store.alcohol_store_number,
        name: store.alcohol_banner,
        banner: store.alcohol_banner,
        retailer_store_id: store.alcohol_retailer_store_id,
        warehouse_location_id: store.alcohol_store_number,
      }
    }
  })
  return Object.values(storesWithAlcoholStores)
}
