import { Formik } from 'formik'
import React, { Component } from 'react'
import injectSheet from 'react-jss'
import { connect } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as yup from 'yup'

import { Button, FormItem, Notification, StatusIndicatorBadge, TextInput } from 'components'
import { getEnvStatusBadgeTypeAndText } from 'config'
import {
  authenticateUser,
  fetchCurrentUser,
  resetPasswordWithToken,
  sendResetPasswordEmail,
} from 'modules/user'
import { createToast } from 'modules/toasts'
import { queryString } from 'utils'

import logo from 'logo.svg'

import styles from './Login.styles'

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // Redirect user if they are already authenticated
      redirectToReferrer: props.isAuthenticated,
    }
  }

  login = (values, form) => {
    const { authenticateUser, fetchCurrentUser } = this.props
    return authenticateUser(values)
      .then(() => {
        form.setSubmitting(false)
      })
      .then(fetchCurrentUser)
      .then(() => {
        this.setState({ redirectToReferrer: true })
      })
      .catch(error => {
        form.setSubmitting(false)
        form.setErrors({ global: error.message })
      })
  }

  sendResetPasswordEmail = (values, form) => {
    const { createToast, history, sendResetPasswordEmail } = this.props
    return sendResetPasswordEmail(values)
      .then(({ value: { message } }) => {
        form.setSubmitting(false)
        createToast({ kind: 'success', message })
        history.push('/login')
      })
      .catch(error => {
        form.setSubmitting(false)
        form.setErrors({ global: error.message })
      })
  }

  resetPasswordWithToken = (values, form, resetToken) => {
    const { createToast, history, resetPasswordWithToken } = this.props
    return resetPasswordWithToken(resetToken, values)
      .then(({ value: { message } }) => {
        form.setSubmitting(false)
        createToast({ kind: 'success', message })
        history.push('/login')
      })
      .catch(error => {
        form.setSubmitting(false)
        form.setErrors({ global: error.message })
      })
  }

  render() {
    const { classes, location } = this.props
    const from = (location.state && location.state.from) || { pathname: '/' }
    const { redirectToReferrer } = this.state

    if (redirectToReferrer) {
      if (from.pathname === '/login' || from.pathname === '/logout') {
        return <Redirect to="/" />
      }
      return <Redirect to={from} />
    }
    return (
      <div className={classes.pageContainer}>
        <div className={classes.formContainer}>
          <div className={classes.logoContainer}>
            <img src={logo} className={classes.logo} alt="Logo" />
            <StatusIndicatorBadge
              className={classes.statusBadge}
              {...getEnvStatusBadgeTypeAndText()}
            />
          </div>
          <Route
            path="/login"
            exact
            render={() => (
              <div>
                <Formik
                  initialValues={{
                    email: '',
                    password: '',
                  }}
                  validateOnBlur={false}
                  validateOnChange={false}
                  validationSchema={() =>
                    yup.object().shape({
                      email: yup
                        .string()
                        .email()
                        .label('Email')
                        .required(),
                      password: yup
                        .string()
                        .label('Password')
                        .required(),
                    })
                  }
                  onSubmit={this.login}
                  render={({
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      {!isSubmitting && errors.global && (
                        <Notification kind="error" message={errors.global} />
                      )}
                      <div className={classes.loginInputs}>
                        <FormItem fieldId="login-email" errorMsg={errors.email}>
                          <TextInput
                            autoFocus
                            id="login-email"
                            isInvalid={!!errors.email}
                            name="email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Email"
                            type="email"
                            value={values.email}
                          />
                        </FormItem>
                        <FormItem fieldId="login-password" errorMsg={errors.password}>
                          <TextInput
                            id="login-password"
                            isInvalid={!!errors.password}
                            name="password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Password"
                            type="password"
                            value={values.password}
                          />
                        </FormItem>
                      </div>
                      <Button type="submit" disabled={isSubmitting}>
                        Log In
                      </Button>
                    </form>
                  )}
                />
                <Button className={classes.linkButton} kind="link" href="/login/forgot_password">
                  Forgot Password?
                </Button>
              </div>
            )}
          />
          <Route
            path="/login/forgot_password"
            render={() => (
              <div>
                <h2 className={classes.header}>Reset Password</h2>
                <Formik
                  initialValues={{
                    email: '',
                  }}
                  validateOnBlur={false}
                  validateOnChange={false}
                  validationSchema={() =>
                    yup.object().shape({
                      email: yup
                        .string()
                        .email()
                        .label('Email')
                        .required(),
                    })
                  }
                  onSubmit={this.sendResetPasswordEmail}
                  render={({
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      {!isSubmitting && errors.global && (
                        <Notification
                          className={classes.resetPasswordError}
                          kind="error"
                          message={errors.global}
                        />
                      )}
                      <div className={classes.loginInputs}>
                        <FormItem fieldId="login-forgot-password-email" errorMsg={errors.email}>
                          <TextInput
                            autoFocus
                            id="login-forgot-password-email"
                            isInvalid={!!errors.email}
                            name="email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Email"
                            type="email"
                            value={values.email}
                          />
                        </FormItem>
                      </div>
                      <Button type="submit" disabled={isSubmitting}>
                        Reset
                      </Button>
                    </form>
                  )}
                />
                <Button className={classes.linkButton} kind="link" href="/login">
                  Cancel
                </Button>
              </div>
            )}
          />
          <Route
            path="/login/change-password"
            render={({ location }) => {
              const { resetToken } = queryString.parse(location.search)
              return (
                <div>
                  <h2 className={classes.header}>Create Password</h2>
                  <Formik
                    initialValues={{
                      newPassword: '',
                      confirmNewPassword: '',
                    }}
                    validateOnBlur={false}
                    validateOnChange={false}
                    validationSchema={() =>
                      yup.object().shape({
                        newPassword: yup
                          .string()
                          .label('New Password')
                          .required(),
                        confirmNewPassword: yup
                          .string()
                          .label('Confirm Password')
                          .required(),
                        global: yup
                          .mixed()
                          .test(
                            'equalPasswords',
                            '"New Password" and "Confirm Password" do not match',
                            function() {
                              if (this.parent) {
                                return this.parent.newPassword === this.parent.confirmNewPassword
                              }
                              return true
                            }
                          ),
                      })
                    }
                    onSubmit={(values, form) => {
                      this.resetPasswordWithToken(values, form, resetToken)
                    }}
                    render={({
                      values,
                      errors,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        {!isSubmitting && errors.global && (
                          <Notification
                            className={classes.resetPasswordError}
                            kind="error"
                            message={errors.global}
                          />
                        )}
                        <div className={classes.loginInputs}>
                          <FormItem
                            fieldId="login-reset-password-new"
                            errorMsg={errors.newPassword}
                          >
                            <TextInput
                              id="login-reset-password-new"
                              isInvalid={!!errors.newPassword}
                              name="newPassword"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder="New Password"
                              type="password"
                              value={values.newPassword}
                            />
                          </FormItem>
                          <FormItem
                            fieldId="login-reset-password-confirm"
                            errorMsg={errors.confirmNewPassword}
                          >
                            <TextInput
                              id="login-reset-password-confirm"
                              isInvalid={!!errors.confirmNewPassword}
                              name="confirmNewPassword"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder="Confirm Password"
                              type="password"
                              value={values.confirmNewPassword}
                            />
                          </FormItem>
                        </div>
                        <Button type="submit" disabled={isSubmitting}>
                          Create
                        </Button>
                      </form>
                    )}
                  />
                </div>
              )
            }}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.user.isAuthenticated,
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      authenticateUser,
      createToast,
      fetchCurrentUser,
      resetPasswordWithToken,
      sendResetPasswordEmail,
    },
    dispatch
  )

export default injectSheet(styles)(connect(mapStateToProps, mapDispatchToProps)(Login))
