import memoize from 'memoize-one'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { fetchPlacementContentByString, updatePlacement } from 'api'
import { DataTableWithStateAsync, StatusIndicatorDot } from 'components'
import { createToast } from 'modules/toasts'
import { generateBackButton, getRoutePathname } from 'routing'
import {
  addValueToQueryString,
  capitalize,
  formatDate,
  formatDatetime,
  isNullOrUndefined,
} from 'utils'

import getContentId from './getContentId'

const PAGE_ID = 'promoted-placements-tab'

const tableHeaders = [
  {
    key: 'nameWithImage',
    header: 'Name',
    disableSorting: true,
  },
  {
    key: 'contentId',
    header: 'Content ID',
    disableSorting: true,
  },
  {
    key: 'customerSegment',
    header: 'Segment',
    disableSorting: true,
  },
  {
    key: 'storeGroup',
    header: 'Store Group',
    disableSorting: true,
  },
  {
    key: 'activePeriod',
    header: 'Active Period',
    disableSorting: true,
  },
  {
    key: 'lastEdited',
    header: 'Last Edited',
    disableSorting: true,
  },
  {
    key: 'status',
    header: 'Status',
    disableSorting: true,
  },
]

const formatTableRows = memoize(({ classes, items, history, location, match, label }, { key }) =>
  items.map(placement => {
    const {
      createdBy,
      customerSegment,
      endDate,
      id,
      modified,
      name,
      position,
      promotedMedia = {},
      storeGroup,
      startDate,
      status: placementStatus,
    } = placement

    const validStatuses = ['new', 'in-progress', 'complete', 'cancelled', 'canceled']
    const status = placementStatus
    let statusType = 'new'
    if (!validStatuses.includes(status)) {
      if (status === 'approved') {
        statusType = 'new'
      } else if (status === 'draft' || status === 'pending') {
        statusType = 'in-progress'
      } else if (status === 'rejected') {
        statusType = 'cancelled'
      }
    }

    let imageUrl = null

    if (promotedMedia.desktop && promotedMedia.desktop.imageUrl) {
      imageUrl = promotedMedia.desktop.imageUrl
    } else if (promotedMedia.mobile && promotedMedia.mobile.imageUrl) {
      imageUrl = promotedMedia.mobile.imageUrl
    }

    const nameWithImage = (
      <div className={classes.nameWithImage}>
        <div className={classes.imageContainer}>
          {imageUrl && <img src={imageUrl} alt={name} />}
        </div>
        <span>{name}</span>
      </div>
    )

    return {
      activePeriod: `${formatDate(startDate)} - ${formatDate(endDate)}`,
      customerSegment: customerSegment && customerSegment.name ? customerSegment.name : `Everyone`,
      storeGroup: storeGroup && storeGroup.name ? storeGroup.name : `All Stores`,
      id: `${PAGE_ID}-${id}`,
      rowLinkTo: {
        pathname: getRoutePathname('promotedPlacements.details', {
          id,
          slug: match.params.slug,
        }),
        state: { backButton: generateBackButton('promotedPlacements', location, label) },
      },
      nameWithImage,
      contentId: getContentId(key, id),
      position,
      createdBy: createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : '',
      lastEdited: modified ? formatDatetime(modified) : '',
      status: <StatusIndicatorDot text={capitalize({ phrase: status })} type={statusType} />,
    }
  })
)

class PromotedPlacementsContentTab extends Component {
  state = {
    slug: null,
  }

  buildFetchFunction = (qs, config, additionalParams) => {
    additionalParams.forEach(item => {
      qs = addValueToQueryString(qs, item)
    })

    return fetchPlacementContentByString(qs, config)
  }

  componentWillReceiveProps(nextProps) {
    // This is a little bit of a hack to force the re-render (and refresh the async data table)
    if (nextProps.slug !== this.props.slug) {
      this.setState({ slug: nextProps.slug })
    }
  }

  findRowById = (rowId, items) => {
    // Make sure this id matches the one passed to the table
    return items.find(item => `${PAGE_ID}-${item.id}` === rowId)
  }

  onDragEnd = ({ data, updatedRows }) => {
    const item = this.findRowById(data.draggableId, updatedRows)
    let newPosition = null

    if (item && item.id) {
      newPosition = data.destination.index
    }

    // Position should be updated
    if (!isNullOrUndefined(newPosition)) {
      const updatedItem = { ...item, position: newPosition }

      updatePlacement(item.id, updatedItem)
        .then(() => {
          this.props.createToast({
            kind: 'success',
            message: 'Row was successfully updated.',
          })
        })
        .catch(({ message }) => {
          this.props.createToast({ kind: 'error', message })
        })
    } else {
      this.props.createToast({
        kind: 'error',
        message: 'There was a problem updating this placement.',
      })
    }
  }

  render() {
    const { filters, placement } = this.props

    const additionalParams = [
      { key: 'placement_id', value: placement.id },
      { key: 'order_by', value: 'position' },
    ]

    Object.keys(filters).forEach(key => {
      const value = filters[key]

      additionalParams.push({
        key,
        value,
      })
    })

    const isDraggable = !(filters.expired && filters.expired === 'true')

    return (
      <DataTableWithStateAsync
        // This is a hack to get data to refresh on prop change
        // TODO - update DataTableAsync with a refetch function/trigger
        // Also, don't move this to a variable, doesn't work for some reason
        key={`${this.state.slug}-${Object.values(additionalParams)
          .map(v => v.value)
          .join('-')}`}
        fetchItems={(qs, config) => this.buildFetchFunction(qs, config, additionalParams)}
        formatTableRows={items => formatTableRows({ ...this.props, items }, placement)}
        headers={tableHeaders}
        id={`${PAGE_ID}-table`}
        isSelectable={false}
        onDragEnd={this.onDragEnd}
        renderProps={{
          isDraggable,
        }}
      />
    )
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createToast,
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(PromotedPlacementsContentTab)
