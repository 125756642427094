import cx from 'classnames'
import Downshift from 'downshift'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import injectSheet from 'react-jss'

import { ListBox, LoadingIndicator } from 'components'
import { get } from 'utils'

import ListBoxFieldStyles from '../ListBox/ListBoxField.styles'

const styles = theme => {
  const listBoxFieldStyles = ListBoxFieldStyles(theme)
  return {
    dropdownFilterableInput: {
      fontSize: theme.fontSize.body,
      border: 0,
      color: theme.text01,
      fontFamily: theme.fontFamilySansSerif,
      fontWeight: 400,
      padding: 0,
      width: '100%',

      '&::placeholder, &::-webkit-input-placeholder': {
        color: theme.text02,
      },

      '&:focus': {
        outline: 'none',
      },
    },
    dropdownFilterableInputBoldText: {
      // When the Dropdown has a value selected, show the value in bold (unless the input is focused)
      fontWeight: theme.inputValueWeight,
      textOverflow: 'ellipsis',
    },
    dropdownFilterableInputDisabled: {
      // When the Dropdown is disabled, make sure the input shows the same disabled background color as ListBox.Field
      background: listBoxFieldStyles.listBoxFieldDisabled.background,
    },
    dropdownLoadingDots: {
      position: 'relative',
      top: -2,
    },
  }
}

class DropdownFilterable extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    // Allow a prefiltered set of items. Useful for async loading
    filteredItems: PropTypes.array,
    // Id will be used as the id field for the text input
    id: PropTypes.string.isRequired,
    initialValue: PropTypes.object,
    isInvalid: PropTypes.bool,
    isLoading: PropTypes.bool,
    isLoadingAsync: PropTypes.bool,
    items: PropTypes.array,
    /**
     * Helper function passed to downshift that allows the library to render a
     * given item to a string label. If not provided, will extract `label` field
     * from a given item to serve as the item label in the list.
     */
    itemToString: PropTypes.func,
    /**
     * Function called on each menu item to render content in a custom way (such
     * as to highlight certain text by wrapping it in an element). If not set,
     * itemToString will be used.
     */
    menuItemRender: PropTypes.func,
    /**
     * If provided will be used to call for DropdownFilterable results asynchronously
     */
    loadItems: PropTypes.func,
    /** Message to show to users when the filtered list of items is empty */
    noItemsMsg: PropTypes.string.isRequired,
    /**
     * Will set `white-space: nowrap` on the ListBoxMenu
     */
    nowrap: PropTypes.bool,
    /**
     * `onInputChange` is a utility for this controlled component to communicate to a
     * consuming component what kind of what the current value of the search input is.
     */
    onInputChange: PropTypes.func,
    /**
     * `onChange` is a utility for this controlled component to communicate to a
     * consuming component what kind of internal state changes are occuring.
     */
    onChange: PropTypes.func,
    /**
     * `onSelect` is a utility for this controlled component to communicate to a
     * consuming component what kind of internal state changes are occuring.
     */
    onSelect: PropTypes.func,
    /**
     * Generic `placeholder` that will be used as the textual representation of
     * what this field is for inside of the text input
     */
    placeholder: PropTypes.string.isRequired,
    selectedValue: PropTypes.any,
    onOpen: PropTypes.func,
  }

  static getDerivedStateFromProps(props, state) {
    if (props.selectedValue !== state.selectedValue) {
      return {
        selectedValue: props.selectedValue,
        // empty the input value (what user types to search/filter) when we have a value set but then we clear it
        inputValue: state.selectedValue != null && props.selectedValue == null ? '' : undefined,
      }
    }
    return {}
  }

  static defaultProps = {
    filteredItems: null,
    initialValue: null,
    isLoading: false,
    isLoadingAsync: false,
    itemToString: item => (typeof item === 'string' ? item : (item && item.label) || ''),
    menuItemRender: null,
    onInputChange: () => null,
    noItemsMsg: 'No results found',
  }

  state = {
    inputValue: get(this.props, 'initialValue.name') || '',
    isOpen: false,
    resetFilterItems: false,
    // Track 'selectedValue' prop
    selectedValue: this.props.selectedValue,
  }

  handleStateChange = changes => {
    const { type } = changes
    switch (type) {
      case Downshift.stateChangeTypes.keyDownEscape:
      case Downshift.stateChangeTypes.mouseUp:
        this.setState({ isOpen: false })
        if (!this.state.inputValue && this.props.onChange) {
          this.props.onChange(null)
        }
        break
      // Opt-in to some cases where we should be toggling the menu based on
      // a given key press or mouse handler
      // Reference: https://github.com/paypal/downshift/issues/206
      case Downshift.stateChangeTypes.clickButton:
      case Downshift.stateChangeTypes.keyDownSpaceButton:
        this.setState(() => {
          let nextIsOpen = changes.isOpen
          if (changes.isOpen === false) {
            // If Downshift is trying to close the menu, but we know the input
            // is the active element in the document, then keep the menu open
            if (this.inputEl === document.activeElement) {
              nextIsOpen = true
            }
          }
          if (nextIsOpen && this.props.onOpen) {
            this.props.onOpen()
          }
          return {
            isOpen: nextIsOpen,
          }
        })
        break
      default:
    }
  }

  selectItemFromValue = value => {
    const { items, filteredItems } = this.props

    if (value) {
      let valueObject = items ? items.find(item => item.value === value) : null

      // If value doesn't exist in initial list, check the filtered items
      if (!valueObject && filteredItems) {
        valueObject = filteredItems.find(item => item.value === value)
      }

      return valueObject || null
    }

    return null
  }

  // Only called on menu item selections of a new selected item (different than the previous)
  handleChange = selectedItem => {
    if (this.props.onChange) {
      this.props.onChange(selectedItem ? selectedItem.value : null)
    }
  }

  // Called on all menu item selections
  handleSelect = selectedItem => {
    const { itemToString } = this.props

    this.setState({
      inputValue: selectedItem.value ? itemToString(selectedItem) : '',
      isOpen: false,
    })

    this.inputEl.blur()

    if (this.props.onSelect) {
      this.props.onSelect(selectedItem ? selectedItem.value : null)
    }
  }

  handleInputValueChange = ({ target: { value: inputValue } }) => {
    if (this.clearInputEl !== document.activeElement && this.state.isOpen) {
      this.props.onInputChange(inputValue)
      this.setState({ inputValue, resetFilterItems: false })
    }
  }

  handleInputKeyDown = event => {
    event.stopPropagation()
  }

  clearInputValue = event => {
    event.stopPropagation()
    this.setState({ inputValue: '', resetFilterItems: true, selectedValue: null })
    this.inputEl && this.inputEl.focus && this.inputEl.focus()

    if (this.props.onChange) {
      this.props.onChange(null)
    } else if (this.props.onSelect) {
      this.props.onSelect(null)
    }
  }

  render() {
    const { inputValue, resetFilterItems, isOpen } = this.state
    const {
      classes,
      className: containerClassName,
      disabled,
      filteredItems,
      id,
      initialValue,
      isInvalid,
      isLoading,
      isLoadingAsync,
      items,
      itemToString,
      noItemsMsg,
      nowrap,
      placeholder,
      selectedValue,
      menuItemRender,
    } = this.props

    const isDisabled = disabled || isLoading

    const filterItems = (items, { itemToString, inputValue }) => {
      if (inputValue) {
        // If there is an inputValue, return all items which contain the inputValue ignoring case
        return items.filter(item =>
          itemToString(item)
            .toLowerCase()
            .includes(inputValue.toLowerCase())
        )
      }
      return items
    }

    const selectedItem = this.selectItemFromValue(selectedValue)
    const selectedItemLabel = itemToString(selectedItem)

    return (
      <Downshift
        inputValue={inputValue}
        initialInputValue={get(initialValue, 'name')}
        initialSelectedItem={initialValue}
        isOpen={isOpen}
        itemToString={itemToString}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        onStateChange={this.handleStateChange}
        selectedItem={selectedItem}
        render={({
          getButtonProps,
          getInputProps,
          getItemProps,
          getRootProps,
          highlightedIndex,
          isOpen,
          inputValue,
          selectedItem,
        }) => {
          const filteredItemsWithReset = resetFilterItems ? [] : filteredItems
          let itemsToShow
          if (filteredItems) {
            itemsToShow = filteredItemsWithReset
          } else {
            itemsToShow = selectedValue ? items : filterItems(items, { itemToString, inputValue })
          }
          const isSelected = !!selectedItem

          return (
            <ListBox
              className={containerClassName}
              id={id}
              isDisabled={isDisabled}
              {...getRootProps({ refKey: 'setRef' })}
            >
              <ListBox.Field
                isDisabled={isDisabled}
                isInvalid={isInvalid}
                isFocused={isOpen}
                isSelected={isSelected}
                {...getButtonProps({ disabled: isDisabled })}
              >
                <ListBox.Icon name="search" />
                {isLoading && <ListBox.Label text="Loading..." />}
                {!isLoading && (
                  <input
                    className={cx(classes.dropdownFilterableInput, {
                      [classes.dropdownFilterableInputBoldText]: isSelected && !isOpen,
                      [classes.dropdownFilterableInputDisabled]: isDisabled,
                    })}
                    ref={el => (this.inputEl = el)}
                    {...getInputProps({
                      id,
                      disabled: isDisabled,
                      onChange: this.handleInputValueChange,
                      onKeyDown: this.handleInputKeyDown,
                      placeholder,
                      // Show the currently selected value label when not open
                      ...(!isOpen && { value: isSelected ? selectedItemLabel : placeholder }),
                    })}
                  />
                )}
                {isLoadingAsync && (
                  <LoadingIndicator.Dots className={classes.dropdownLoadingDots} />
                )}
                {inputValue && isOpen && (
                  <ListBox.Selection
                    clearSelection={this.clearInputValue}
                    setRef={el => (this.clearInputEl = el)}
                  />
                )}
                <ListBox.MenuIcon isDisabled={isDisabled} isOpen={isOpen} />
              </ListBox.Field>
              {isOpen && !isLoadingAsync && (
                <ListBox.Menu nowrap={nowrap}>
                  {itemsToShow.length === 0 ? (
                    <ListBox.MenuItem isNoItemsMsg>{noItemsMsg}</ListBox.MenuItem>
                  ) : (
                    itemsToShow.map((item, index) => {
                      const itemProps = getItemProps({ item })
                      const itemContent = menuItemRender ? menuItemRender(item) : itemToString(item)
                      const isSelected = selectedItem ? selectedItem.id === item.id : false
                      const isHighlighted = highlightedIndex === index

                      return (
                        <ListBox.MenuItem
                          key={itemProps.id}
                          isActive={isSelected}
                          isHighlighted={isHighlighted}
                          {...itemProps}
                        >
                          {itemContent}
                        </ListBox.MenuItem>
                      )
                    })
                  )}
                </ListBox.Menu>
              )}

              {isOpen && isLoadingAsync && (
                <ListBox.Menu nowrap={nowrap}>
                  <ListBox.MenuItem isNoItemsMsg>Searching...</ListBox.MenuItem>
                </ListBox.Menu>
              )}
            </ListBox>
          )
        }}
      />
    )
  }
}

export { DropdownFilterable as DropdownFilterableUnStyled }
export default injectSheet(styles)(DropdownFilterable)
