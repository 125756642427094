import React, { useMemo, useCallback } from 'react'
import { createStyles } from '@instacart/cocktail-helpers'
import { useHistory, useLocation } from 'react-router-dom'
import { useQuery } from '@instacart/enterprise-services-hooks'
import { useDispatch } from 'react-redux'

import config from 'config'
import { createToast } from 'modules/toasts'
import {
  PageHeader,
  MultiSelectFilterable,
  DataTable,
  StatusIndicatorDot,
  DropdownDate,
  Button,
  ShowIfAuthorized,
  Modal,
  Tab,
  Tabs,
  DropdownMenu,
  ButtonIconAction,
  DropdownMenuItem,
} from 'components'
import { getRoutePathname, generateBackButton } from 'routing'
import {
  fetchCateringOrders,
  fetchEnabledStores,
  fetchCateringCategories,
  advanceCateringOrderStatus,
  fetchCateringOrderStatuses,
} from 'api'
import {
  useItemsFetcherWithParams,
  useItemFetcher,
  formatDate,
  formatTime,
  getPersonName,
  printPDF,
  queryString as queryStringUtil,
  downloadImageAsBase64String,
} from 'utils'
import {
  getProductConfigOptionStrings,
  getOrderStatusByKey,
  getNextOrderStatus,
  getPreviousOrderStatus,
} from 'utils/orderUtils'
import { useLoadingState, useUpdatedDataCache } from 'utils/hooks'

import cateringOrderPrintContent from './CateringOrdersPrint'

import styles from './CateringOrders.styles'

const useStyles = createStyles(styles)

const generateMultiselectOptions = (itemName, items, inclueIdInLabel) =>
  items.map(item => ({
    id: `${itemName}-${item.id}`,
    label: inclueIdInLabel ? `${item.name} - ${item.id}` : item.name,
    value: `${item.id}`, // cast to string to ensure matches with store id param
  }))

const generateItemLabelsById = items =>
  items.reduce((itemsById, item) => ({ ...itemsById, [item.id]: item.name }), {})

const generateStoreMultiselectOptions = stores => generateMultiselectOptions('store', stores, true)
const generateDepartmentMultiselectOptions = departments =>
  generateMultiselectOptions('department', departments)

const generateOrderItemNotes = ({ productConfig, customerComment }) => {
  const allNotes = []

  if (productConfig) {
    allNotes.push(getProductConfigOptionStrings(productConfig).join('\n'))
  }

  if (customerComment) allNotes.push(customerComment)

  return allNotes.length > 0 ? allNotes.join('\n\n') : ''
}

const tableParams = {
  pickupDateRange: { key: 'fulfillment_date', type: 'range' }, // parses `fulfillment_date_lower` & `fulfillment_date_upper` into `fulfillmentDateRange.value: { from, to }`
  sorting: {
    type: 'multi',
    multiParams: { direction: { key: 'order_by_dir' }, orderBy: { key: 'order_by' } },
  },
  departmentCategoryIds: { key: 'category_id', type: 'array' },
  storeIds: { key: 'store_id', type: 'array' },
  statuses: { key: 'internal_status', type: 'array' },
}

// Order table header keys must match value used after `sortBy` in `/orders` query
// (in addition to the corresponding row property)
const orderTableHeaders = [
  {
    // orderBy on the backend expects ready_at, not readyAt
    key: 'ready_at',
    header: 'Pickup Date & Time',
    minWidth: 100,
    print: true,
  },
  {
    key: 'confirmationId',
    header: 'Confirmation ID',
    disableSorting: true,
    print: true,
  },
  {
    key: 'orderItem',
    header: 'Order Item',
    disableSorting: true,
    minWidth: 200,
    print: true,
  },
  {
    key: 'itemNotes',
    header: 'Item Notes',
    disableSorting: true,
    print: true,
  },
  {
    key: 'guest',
    header: 'Guest',
    disableSorting: true,
    minWidth: 150,
    print: true,
  },
  {
    key: 'status',
    header: 'Status',
    disableSorting: true,
    print: false,
  },
]

const advanceOrderStatus = async (orderId, statusKey) => {
  const { orderItems = [] } = await advanceCateringOrderStatus(statusKey, {
    order_item_ids: [orderId],
  })

  return orderItems[0]
}

const print = (documentHeaders, orderTableHeaders, orderTableRows, fileName, logoImageBase64) => {
  const docDefinition = cateringOrderPrintContent(
    documentHeaders,
    orderTableHeaders,
    orderTableRows,
    logoImageBase64
  )
  printPDF(docDefinition, fileName)
}

const CateringOrders = () => {
  const styles = useStyles()
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const {
    setItemLoading: setStatusUpdateLoading,
    setItemResolved: setStatusUpdateResolved,
    isItemLoading: isStatusUpdateLoading,
  } = useLoadingState()
  const { setCacheItem, getItemsWithCache } = useUpdatedDataCache()

  const {
    params: orderParams,
    items: orders,
    isLoadingItems: isLoadingOrders,
    isFetchingMore,
    canFetchMore,
    loadMore,
    queryParams,
    otherData,
  } = useItemsFetcherWithParams(
    'CATERING_ORDERS',
    fetchCateringOrders,
    tableParams,
    {
      history,
      location,
    },
    {
      hasInfinitePagination: true,
      defaultItemsLimit: 15,
    }
  )

  const { item: cateringStatusDetails, isLoadingItem: isLoadingCateringStatuses } = useItemFetcher(
    'CATERING_ORDER_STATUSES',
    fetchCateringOrderStatuses
  )

  const { data: enabledStores, isLoading: enabledStoresLoading } = useQuery(
    'enabledStores',
    fetchEnabledStores,
    { suspense: false }
  )

  const {
    data: cateringDepartments,
    isLoading: cateringDepartmentsLoading,
  } = useQuery('cateringDepartments', fetchCateringCategories, { suspense: false })

  const storeMultiselectOptions = useMemo(
    () => generateStoreMultiselectOptions((enabledStores && enabledStores.items) || []),
    [enabledStores]
  )
  const departmentMultiselectOptions = useMemo(
    () =>
      generateDepartmentMultiselectOptions(
        (cateringDepartments && cateringDepartments.items) || []
      ),
    [cateringDepartments]
  )
  const storeLabelsById = useMemo(
    () => generateItemLabelsById((enabledStores && enabledStores.items) || []),
    [enabledStores]
  )
  const departmentLabelsById = useMemo(
    () => generateItemLabelsById((cateringDepartments && cateringDepartments.items) || []),
    [cateringDepartments]
  )
  const cateringStatusList = useMemo(
    () =>
      cateringStatusDetails && cateringStatusDetails.status_sequence
        ? cateringStatusDetails.status_sequence.map(status => ({
            statusText: status.label,
            key: status.status,
          }))
        : [],
    [cateringStatusDetails]
  )

  const orderTableRows = useMemo(
    () =>
      getItemsWithCache(orders).map(order => {
        const {
          customer,
          customerComment,
          readyAt,
          id,
          internalStatus,
          product,
          quantity,
          productConfig,
          orderId,
          lineNumber,
        } = order

        const currentStatus = getOrderStatusByKey(internalStatus, cateringStatusList)
        const { statusText } = currentStatus

        const nextStatus = getNextOrderStatus(internalStatus, cateringStatusList)
        const previousStatus = getPreviousOrderStatus(internalStatus, cateringStatusList)

        const handleClickChangeOrderStatus = (newStatus, isUndo) => async event => {
          if (!newStatus) return

          if (event) {
            // Prevent navigation on row click.
            event.stopPropagation()
          }

          setStatusUpdateLoading(id)
          try {
            const updatedItem = await advanceOrderStatus(id, newStatus.key)
            setCacheItem(updatedItem)

            dispatch(
              createToast({
                kind: 'success',
                message: isUndo
                  ? `Changed order item #${id} status back to ${newStatus.statusText}.`
                  : `Updated order item #${id} status to ${newStatus.statusText}.`,
                action: isUndo
                  ? undefined
                  : {
                      text: 'Undo',
                      onClick: handleClickChangeOrderStatus(currentStatus, true),
                    },
              })
            )
          } catch ({ message }) {
            dispatch(createToast({ kind: 'error', message }))
          }
          setStatusUpdateResolved(id)
        }

        const isItemStatusUpdateLoading = isStatusUpdateLoading(id)

        return {
          id: `order-${id}`,
          confirmationId: `#${orderId}-${lineNumber}`,
          ready_at: (
            <span css={styles.tableContent}>{`${formatDate(readyAt)}\n${formatTime(
              readyAt
            )}`}</span>
          ),
          orderItem: (
            <span css={styles.tableContent}>
              {!!quantity && quantity > 1 && `${quantity} X `}
              {product.name}
            </span>
          ),
          itemNotes: (
            <span css={styles.tableContent}>
              {generateOrderItemNotes({ productConfig, customerComment })}
            </span>
          ),
          guest: (
            <ShowIfAuthorized
              requiredPermission="customers.view"
              unauthorizedFallback={getPersonName({ person: customer, fallback: '' })}
            >
              <Button
                kind="link"
                href={{
                  pathname: getRoutePathname('customers.customer', { id: customer.id }),
                  state: { backButton: generateBackButton('cateringOrders', location) },
                }}
              >
                <div css={styles.tableContent}>
                  {getPersonName({ person: customer, fallback: '' })}
                  <br />
                  {customer.phoneNumber}
                </div>
              </Button>
            </ShowIfAuthorized>
          ),
          status: (
            <div css={styles.tableActions}>
              <Button
                disabled={isItemStatusUpdateLoading || !nextStatus}
                onClick={nextStatus ? handleClickChangeOrderStatus(nextStatus) : undefined}
                kind={
                  nextStatus && nextStatus.key === 'done' ? 'confirmation-secondary' : 'secondary'
                }
                css={styles.statusButton}
              >
                {nextStatus &&
                  (isItemStatusUpdateLoading ? 'Moving...' : `Move to ${nextStatus.statusText}`)}
                {!nextStatus && (isItemStatusUpdateLoading ? 'Moving...' : statusText)}
              </Button>
              {previousStatus && (
                <DropdownMenu
                  menuAlignment="right"
                  triggerRender={({ onClick }) => (
                    <ButtonIconAction description="Options" icon="more" onClick={onClick} />
                  )}
                >
                  <DropdownMenuItem
                    icon="arrowLeft"
                    onClick={handleClickChangeOrderStatus(previousStatus)}
                    disabled={isItemStatusUpdateLoading}
                  >
                    Move back to {previousStatus.statusText}
                  </DropdownMenuItem>
                </DropdownMenu>
              )}
            </div>
          ),
          rowMinHeight: 74,
          rowLinkTo: {
            pathname: getRoutePathname('orders.order', { id: orderId }),
            state: { backButton: generateBackButton('cateringOrders', location) },
          },
        }
      }),
    [
      orders,
      styles,
      setStatusUpdateLoading,
      setStatusUpdateResolved,
      isStatusUpdateLoading,
      dispatch,
      getItemsWithCache,
      setCacheItem,
      location,
      cateringStatusList,
    ]
  )

  const getPrintRows = printOrders =>
    printOrders.map(order => {
      const {
        customer,
        customerComment,
        readyAt,
        id,
        internalStatus,
        product,
        quantity,
        productConfig,
        orderId,
        lineNumber,
      } = order

      const { statusText } = getOrderStatusByKey(internalStatus, cateringStatusList)

      return {
        id: `order-${id}`,
        confirmationId: `#${orderId}-${lineNumber}`,
        ready_at: `${formatDate(readyAt)}\n${formatTime(readyAt)}`,
        orderItem: `${!!quantity && quantity > 1 ? `${quantity} X ` : ''}${product.name}`,
        itemNotes: generateOrderItemNotes({ productConfig, customerComment }),
        guest: getPersonName({ person: customer, fallback: '' }),
        status: statusText,
      }
    })

  const getPickupDateWithRangeSeparator = useCallback(
    separator => {
      if (orderParams.pickupDateRange.value.from !== orderParams.pickupDateRange.value.to) {
        return [orderParams.pickupDateRange.value.from, orderParams.pickupDateRange.value.to]
          .filter(date => !!date)
          .join(separator)
      }

      return orderParams.pickupDateRange.value.from
    },
    [orderParams]
  )

  const printHeaders = useMemo(
    () => [
      {
        title: 'Store',
        content: orderParams.storeIds.value.map(id => storeLabelsById[id]).join(', '),
      },
      {
        title: 'Department',
        content: orderParams.departmentCategoryIds.value
          .map(id => departmentLabelsById[id])
          .join(', '),
      },
      {
        title: 'Date',
        content: getPickupDateWithRangeSeparator(' to '),
      },
    ],
    [orderParams, departmentLabelsById, storeLabelsById, getPickupDateWithRangeSeparator]
  )

  const downloadBase64LogoWithGuard = async () => {
    try {
      return await downloadImageAsBase64String(`${config.env.webUrl}/images/email/logo.png`)
    } catch (error) {
      return null
    }
  }

  const printRows = async () => {
    // Remove limit for print
    const fetchQueryString = `?${queryStringUtil.stringify({
      ...queryParams,
      limit: undefined,
    })}`

    dispatch(
      createToast({
        kind: 'info',
        message: `Preparing document of orders to be printed...`,
      })
    )

    const imageBase64 = await downloadBase64LogoWithGuard()
    const printOrders = await fetchCateringOrders(fetchQueryString)
    const printRows = getPrintRows(printOrders.items)

    print(
      printHeaders,
      orderTableHeaders,
      printRows,
      `orders-${getPickupDateWithRangeSeparator('-to-')}.pdf`,
      imageBase64
    )

    dispatch(
      createToast({
        kind: 'success',
        message: `Print document saved.`,
      })
    )
  }

  const handleStatusTabClick = tabIndex => {
    if (!cateringStatusDetails) return
    orderParams.statuses.onChange(cateringStatusDetails.status_groups[tabIndex].statuses)
  }

  const selectedTabFromParams = useMemo(() => {
    if (!cateringStatusDetails || !orderParams.statuses.value) return 0

    // Find the existing search params in one of the possible status groups,
    // ignoring the order of the array.
    const foundTabIndex = cateringStatusDetails.status_groups.findIndex(statusGroup => {
      const statusesToCompare = statusGroup.statuses || []

      return (
        statusesToCompare.length === orderParams.statuses.value.length &&
        statusesToCompare.every(status => orderParams.statuses.value.includes(status))
      )
    })

    if (foundTabIndex === -1) return 0
    return foundTabIndex
  }, [orderParams, cateringStatusDetails])

  return (
    <div>
      <PageHeader headerTitle="Catering Orders" />
      <div css={styles.filtersAndActions}>
        <div css={styles.filters}>
          <MultiSelectFilterable
            id="orders-filter-stores"
            isLoading={enabledStoresLoading}
            items={storeMultiselectOptions}
            noItemsMsg="No stores found"
            nowrap
            onChange={orderParams.storeIds.onChange}
            placeholder="Store"
            selectedValues={orderParams.storeIds.value}
          />
          <MultiSelectFilterable
            id="orders-filter-departments"
            isLoading={cateringDepartmentsLoading}
            items={departmentMultiselectOptions}
            noItemsMsg="No departments found"
            nowrap
            onChange={orderParams.departmentCategoryIds.onChange}
            placeholder="Department"
            selectedValues={orderParams.departmentCategoryIds.value}
          />
          <DropdownDate
            id="orders-filter-pickup-date"
            hasDateOptions
            onChange={orderParams.pickupDateRange.onChange}
            placeholder="Pickup Date"
            returnDatetimeValues={false}
            defaultValue={orderParams.pickupDateRange.value}
          />
        </div>
        <div css={styles.actions}>
          <ShowIfAuthorized requiredPermission="catering.create">
            <Button
              href={{
                pathname: getRoutePathname('createOrder'),
                state: { backButton: generateBackButton('cateringOrders', location) },
              }}
            >
              Create New Order
            </Button>
          </ShowIfAuthorized>
          {orderParams.storeIds.value.length &&
          orderParams.pickupDateRange.value.from &&
          orderParams.departmentCategoryIds.value.length ? (
            <Modal.PrintOrdersModal
              handleCloseModal={({ wasConfirmed }) => {}}
              triggerRender={({ openModal }) => (
                <Button icon="export" iconPosition="before" kind="link" onClick={openModal}>
                  Print
                </Button>
              )}
              contentProps={{
                confirmButtonText: 'Print',
                cancelButtonText: 'Cancel',
                details: (
                  <span>
                    {printHeaders.map(header => (
                      <p key={header.title}>
                        {header.title}: {header.content}
                      </p>
                    ))}
                    <br />
                    <p>Number of orders: {otherData.itemCount}</p>
                  </span>
                ),
                printOrders: printRows,
              }}
            />
          ) : (
            <Modal.PrintOrdersModal
              handleCloseModal={({ wasConfirmed }) => {}}
              triggerRender={({ openModal }) => (
                <Button icon="export" iconPosition="before" kind="link" onClick={openModal}>
                  Print
                </Button>
              )}
              contentProps={{
                cancelButtonText: 'Go Back',
                details: (
                  <span>
                    Please make sure to select a <strong>store, department, time range</strong> in
                    order to be able to print out orders
                  </span>
                ),
              }}
            />
          )}
        </div>
      </div>
      {!isLoadingCateringStatuses && cateringStatusDetails && (
        <div css={styles.tabContainer}>
          <Tabs
            selectedTab={selectedTabFromParams}
            onSelectTab={handleStatusTabClick}
            noContentPadding
          >
            {cateringStatusDetails.status_groups.map(statusGroup => (
              <Tab key={statusGroup.label} label={statusGroup.label} />
            ))}
          </Tabs>
        </div>
      )}
      <DataTable
        error={false}
        headers={orderTableHeaders}
        id="orders-table"
        isLoadingNewRows={isLoadingOrders || isLoadingCateringStatuses}
        onSortBy={({ nextSortHeaderKey, nextSortDirection }) => {
          orderParams.sorting.onChange({
            orderBy: nextSortHeaderKey,
            direction: nextSortDirection,
          })
        }}
        rows={orderTableRows}
        sortHeaderKey={orderParams.sorting.multiParams.orderBy.value}
        sortDirection={orderParams.sorting.multiParams.direction.value}
        kind="light-tall-row"
        pagingType="infinite"
        pagingProps={{
          onLoadMore: loadMore,
          isLoading: isFetchingMore,
          hasMore: canFetchMore,
        }}
      />
    </div>
  )
}

export default CateringOrders
