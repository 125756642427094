import React, { useCallback, useState, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import debounce from 'lodash/debounce'
import cx from 'classnames'

import {
  Button,
  DropdownFilterable,
  ButtonIconAction,
  FormLabel,
  TextInput,
  Checkbox,
  DropdownMenu,
  DropdownMenuItem,
} from 'components'
import { fetchCustomers } from 'api'
import { queryString, formatPhoneNumber, snakeToCamelCase } from 'utils'

import TimeslotRow from './TimeslotRow'

import styles from '../CreateOrder.styles'

const useStyles = createUseStyles(styles)

const Guest = ({ setValues, values, errors, handleChange, isDisabled }) => {
  const classes = useStyles()
  const [customerSearchQuery, setCustomerSearchQuery] = useState()
  const [cateringCustomers, setCateringCustomers] = useState([])
  const [isLoadingCateringCustomers, setIsLoadingCateringCustomers] = useState(false)

  // Fetch catering customers using current search query
  useEffect(() => {
    if (customerSearchQuery) {
      setIsLoadingCateringCustomers(true)
      fetchCustomers(
        `?${queryString.stringify({ search: customerSearchQuery, limit: 10, catering: true })}`
      )
        .then(customers => setCateringCustomers(customers.items))
        .catch(() => setCateringCustomers([]))
        .finally(() => setIsLoadingCateringCustomers(false))
    }
  }, [customerSearchQuery])

  const handleUserSearch = searchQuery => {
    setCustomerSearchQuery(searchQuery)
  }

  const debouncedHandleUserSearch = debounce(handleUserSearch, 250)

  const handleSelectCustomer = customerId => {
    setValues({
      ...values,
      customer: customerId ? cateringCustomers.find(customer => customerId === customer.id) : null,
    })
  }

  const getCustomerPhoneNumber = customer =>
    customer[snakeToCamelCase(customer.primaryNumberKey)] ||
    customer.homePhoneNumber ||
    customer.mobilePhoneNumber ||
    customer.otherPhoneNumber

  const handleRemoveCustomer = () => {
    setValues({ ...values, customer: null })
  }

  const handleSetCreateNewUser = () => {
    setValues({ ...values, customer: null, creatingCustomer: true })
  }

  const handleSearchForExistingUser = () => {
    setValues({ ...values, customer: null, creatingCustomer: false })
  }

  const setTimeslot = useCallback(
    timeslot => {
      setValues({ ...values, selectedTimeslot: timeslot })
    },
    [values, setValues]
  )

  return (
    <>
      <h2 className={classes.sectionHeader}>Guest</h2>
      <div className={classes.customerContainer}>
        {!isDisabled && values.creatingCustomer ? (
          <div className={classes.newCustomerContainer}>
            <div className={classes.customerContainerSplitColumn}>
              <FormLabel labelText="Create Guest" />
              <Button kind="link-danger" onClick={handleSearchForExistingUser}>
                Cancel
              </Button>
            </div>
            <div className={classes.rowFlexContainer}>
              <div className={classes.fieldContainer}>
                <FormLabel htmlFor="newCustomerPhone" labelText="Phone Number" />
                <TextInput
                  type="tel"
                  id="newCustomerPhone"
                  onChange={handleChange}
                  isInvalid={Boolean(errors.newCustomerPhone)}
                />
              </div>
              <div className={classes.fieldContainer}>
                <FormLabel htmlFor="newCustomerEmail" labelText="Email" />
                <TextInput
                  type="email"
                  id="newCustomerEmail"
                  onChange={handleChange}
                  isInvalid={Boolean(errors.newCustomerEmail)}
                />
              </div>
              <div className={classes.fieldContainer}>
                <FormLabel htmlFor="newCustomerFirstName" labelText="First Name" />
                <TextInput
                  id="newCustomerFirstName"
                  onChange={handleChange}
                  isInvalid={Boolean(errors.newCustomerFirstName)}
                />
              </div>
              <div className={classes.fieldContainer}>
                <FormLabel htmlFor="newCustomerLastName" labelText="Last Name" />
                <TextInput
                  id="newCustomerLastName"
                  onChange={handleChange}
                  isInvalid={Boolean(errors.newCustomerLastName)}
                />
              </div>
            </div>
            <div className={classes.itemContainer}>
              <Checkbox
                onChange={handleChange}
                id="followUpEmailCheckbox"
                checked={values.followUpEmailCheckbox}
                labelText="Customer consented to follow up via email on this order"
              />
            </div>
            <div className={classes.itemContainer}>
              <Checkbox
                onChange={handleChange}
                id="marketingEmailCheckbox"
                checked={values.marketingEmailCheckbox}
                labelText="Customer consented to follow up with marketing emails"
              />
            </div>
            <div className={classes.itemContainer}>
              <Checkbox
                onChange={handleChange}
                id="createAccountCheckbox"
                checked={values.createAccountCheckbox}
                labelText="Customer consented to create account"
              />
            </div>
          </div>
        ) : values.customer ? (
          <div className={classes.flexContainer}>
            <div className={cx(classes.selectedCustomerContainer, classes.rowFlexContainer)}>
              <div className={cx(classes.contactCell, classes.contactCellPhone)}>
                {formatPhoneNumber(getCustomerPhoneNumber(values.customer) || '')}
              </div>
              <div className={cx(classes.contactCell, classes.contactCellEmail)}>
                {values.customer.email}
              </div>
              <div className={cx(classes.contactCell, classes.contactCellName)}>
                {values.customer.firstName} {values.customer.lastName}
              </div>
              <div>
                <ButtonIconAction
                  description="Remove User"
                  icon="close"
                  onClick={handleRemoveCustomer}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.formContainer}>
            <DropdownFilterable
              id="user-search"
              isInvalid={Boolean(errors.customer)}
              disabled={isDisabled}
              className={classes.searchInput}
              onInputChange={debouncedHandleUserSearch}
              onChange={handleSelectCustomer}
              filteredItems={(cateringCustomers || []).map(item => ({
                ...item,
                value: item.id,
              }))}
              itemToString={item =>
                item &&
                `${item.firstName} ${item.lastName} | ${item.email} | ${formatPhoneNumber(
                  item.mobilePhoneNumber || ''
                )}`
              }
              menuItemRender={item =>
                item && (
                  <div className={classes.flexContainer}>
                    <div className={cx(classes.contactCell, classes.contactCellPhone)}>
                      {formatPhoneNumber(getCustomerPhoneNumber(item) || '')}
                    </div>
                    <div className={cx(classes.contactCell, classes.contactCellEmail)}>
                      {item.email}
                    </div>
                    <div className={cx(classes.contactCell, classes.contactCellName)}>
                      {item.firstName} {item.lastName}
                    </div>
                  </div>
                )
              }
              isLoadingAsync={isLoadingCateringCustomers}
              placeholder="Search for existing user"
              selectedValue={values.customer ? values.customer.id : null}
            />
            <Button
              kind="secondary"
              className={classes.createUserButton}
              onClick={handleSetCreateNewUser}
              disabled={isDisabled}
            >
              Create New User
            </Button>
          </div>
        )}
      </div>

      <div className={classes.rowFlexContainer}>
        <TimeslotRow
          setTimeslot={setTimeslot}
          storeId={values.selectedStore ? values.selectedStore.id : undefined}
          errors={errors}
          isDisabled={isDisabled}
        />
      </div>
    </>
  )
}

export default Guest
