import React, { useEffect, useRef, useState } from 'react'
import injectSheet from 'react-jss'
import { Redirect, useHistory } from 'react-router-dom'

import { V1_DEV_PORT } from 'parseOptions'
import { LoadingIndicator, Notification } from 'components'
import { getRoutePathnameByHash, getRouteTo } from 'routing'

export const renderV1 = route => <Redirect to={getRouteTo('v1', route)} />

const baseURL =
  process.env.NODE_ENV === 'development'
    ? // Locally running Dashboard V1 can be embedded if it's run on the 2999 port:
      // Example: UNATA_DASHBOARD_DEV_SERVER_PORT=2999 npm start
      `//localhost:${V1_DEV_PORT}/`
    : '/legacy/'

const bootstrapDashboardV1 = () => {
  const timestamp = Date.now()

  const style = document.createElement('link')
  style.rel = 'stylesheet'
  style.href = `${baseURL}css/style.css?t=${timestamp}`
  document.head.append(style)

  const googleAnalytics = document.createElement('script')
  googleAnalytics.append(
    document.createTextNode(`
    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','//www.google-analytics.com/analytics.js','ga');

    // Send client side errors to Google Analytics
    window.onerror = function(message, url, linenumber) {
        ga("send", "event", "error", "message: " + message + ", url: " + url + ", line: " + linenumber);
    }
    `)
  )
  document.body.append(googleAnalytics)

  const pendoAnalytics = document.createElement('script')
  pendoAnalytics.append(
    document.createTextNode(`
    (function(apiKey){
      (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=[];
        v=['initialize','identify','updateOptions','pageLoad'];for(w=0,x=v.length;w<x;++w)(function(m){
        o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
        y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
        z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');

      pendo.initialize();
    })('86a59947-4997-4f11-50dd-d3f49a538386');
    `)
  )
  document.body.append(pendoAnalytics)

  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = `${baseURL}js/main.js?t=${timestamp}`
    script.addEventListener('error', error => {
      reject(error)
    })
    script.addEventListener('load', () => {
      resolve()
    })
    document.body.append(script)
  })
}

const styles = {
  error: {
    margin: '2em 0',
  },
}

const DashboardV1 = ({ classes }) => {
  const container = useRef()
  const history = useHistory()
  const [loading, setLoading] = useState()
  const [error, setError] = useState()

  const module = 'entice'

  useEffect(() => {
    // When the user clicks on the currently active sidebar link, force-redirect
    // them to the index page of the active V1 section because the V1 AngularJS
    // router can't handle redirects from outside correctly.
    const clickListener = () => {
      if (!location.hash) {
        return
      }

      const anchor = event.target.closest('a[href]')
      if (!anchor || !anchor.hash || !location.hash.startsWith(anchor.hash)) {
        return
      }

      const pathname = getRoutePathnameByHash(location.hash)
      history.push(pathname)
    }
    addEventListener('click', clickListener)

    // Wrapping with IEFE as useEffect can't be async.
    ;(async () => {
      if ('angular' in window) {
        window.angular.bootstrap(container.current, [module])
        return
      }

      setLoading(true)
      try {
        await bootstrapDashboardV1()
      } catch (error) {
        setError(error)
      }
      setLoading(false)
    })()

    return () => {
      removeEventListener('click', clickListener)
    }
  }, [history])

  // Below is a copy of the Dashboard V1 index page.
  // Source: https://github.com/instacart/unata-entice-frontend/blob/dev/app/index.html
  return (
    <>
      {loading && <LoadingIndicator.Dots absoluteCenter />}
      {error && (
        <Notification
          kind="error"
          className={classes.error}
          message={
            <>
              <p>Failed loading {error.target.src}.</p>
              {process.env.NODE_ENV === 'development' && (
                <p>
                  To run <code>unata-entice-frontend</code> locally on port {V1_DEV_PORT}:{' '}
                  <code>UNATA_DASHBOARD_DEV_SERVER_PORT={V1_DEV_PORT} npm start</code>
                </p>
              )}
            </>
          }
        />
      )}
      <div ref={container} ng-app={module} id="ng-app">
        <div ng-class="{'logged-in': authService.user}">
          <div ng-cloak="" ng-hide="!authService.user" className="main-wrapper">
            <article ui-view="" ng-class="{'view-loading': loading}" className="main" />
            <span className="clear" />
            <div className="toasts">
              <div
                ng-repeat="toast in toastService.toasts"
                ng-click="toastService.click(toast)"
                ng-bind-html="toast.message"
                className="toast {{toast.type}}"
              />
            </div>
          </div>
          <div ng-include="'templates/svg.html'" />
        </div>
      </div>
    </>
  )
}

export default injectSheet(styles)(DashboardV1)
