import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import injectSheet from 'react-jss'
import { Link, withRouter } from 'react-router-dom'
import { CSSTransitionGroup } from 'react-transition-group'

import { Icon, Tooltip } from 'components'

import styles from './TableRow.styles'

import { TableCell, TableSelect } from '.'

class TableRowExpandable extends Component {
  static propTypes = {
    ariaLabel: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    hasRightSpacerCol: PropTypes.bool,
    id: PropTypes.string,
    isExpanded: PropTypes.bool,
    onExpand: PropTypes.func,
    rowAction: PropTypes.node,
    rowDetails: PropTypes.node,
  }

  state = {
    isHovered: false,
  }

  // We put these event handlers on all the cells except action ones since we
  // don't want to show a hovered state for the whole row in that case
  handleMouseOver = () => {
    const { isHovered } = this.state
    if (!isHovered) {
      this.setState({ isHovered: true })
    }
  }

  handleMouseOut = () => {
    const { isHovered } = this.state
    if (isHovered) {
      this.setState({ isHovered: false })
    }
  }

  render() {
    const {
      ariaLabel,
      children,
      classes,
      className,
      dragRowProps,
      id,
      isExpanded,
      isDragging,
      isDraggableValid,
      isSelectable,
      isSelected,
      numColumns,
      onExpand,
      rowAction,
      rowDetails,
      rowSelectProps,
      showAllSelectBoxes,
      tableContainsActionRow,
      tableContainsLinkRow,
    } = this.props

    const { isHovered } = this.state

    const childrenWithMouseEventHandlers = React.Children.map(children, child =>
      React.cloneElement(child, {
        onMouseOver: this.handleMouseOver,
        onMouseOut: this.handleMouseOut,
      })
    )

    return (
      <Fragment>
        <tr
          className={cx(className, classes.tableRow, classes.expandableRowParent, {
            [classes.expandableRowParentExpanded]: isExpanded,
            [classes.expandableRowParentHovered]: isHovered,
            [classes.rowSelected]: isSelected,
          })}
          id={id}
          onClick={onExpand}
          {...dragRowProps}
        >
          {isDraggableValid && (
            <TableCell className={classes.dragColumnCell} isDragOccurring={isDragging}>
              <Tooltip text="Drag to sort">
                <Icon name="drag" className={classes.dragColumnCellIcon} />
              </Tooltip>
            </TableCell>
          )}
          {isSelectable && (
            <TableCell
              className={classes.selectColumnCell}
              onClick={event => {
                // Ensure row action button click doesn't expand the table row
                event.stopPropagation()
              }}
            >
              <TableSelect
                className={classes.selectCheckbox}
                showCheckbox={isSelected || showAllSelectBoxes}
                {...rowSelectProps}
              />
            </TableCell>
          )}
          <TableCell
            className={classes.expandColumnCell}
            onMouseOver={this.handleMouseOver}
            onMouseOut={this.handleMouseOut}
          >
            <button
              aria-label={ariaLabel}
              className={classes.expandableRowParentIconButton}
              onClick={event => {
                event.stopPropagation()
                onExpand(event)
              }}
              type="button"
            >
              <Icon className={classes.expandableRowParentIcon} name="chevronRight" />
            </button>
          </TableCell>
          {childrenWithMouseEventHandlers}
          {// Row may or may not have an action, in which case the following will just be an empty cell
          tableContainsActionRow && (
            <TableCell
              className={cx(classes.actionColumnCell, {
                [classes.expandableRowActionCol]: !!rowAction,
              })}
              onClick={event => {
                // Ensure row action button click doesn't expand the table row
                event.stopPropagation()
              }}
            >
              {rowAction}
            </TableCell>
          )}
          {// Add empty cell to fill link row icon column if necessary
          tableContainsLinkRow && <TableCell className={classes.linkColumnCell} />}
        </tr>
        <tr
          className={cx(classes.expandableRowDetails, {
            [classes.expandableSelectableRowDetails]: isSelectable,
          })}
          id={`${id}-details`}
        >
          <CSSTransitionGroup
            colSpan={numColumns}
            component={TableCell}
            transitionName={{
              enter: classes.expandableRowOpen,
              enterActive: classes.expandableRowOpenActive,
              leave: classes.expandableRowClose,
              leaveActive: classes.expandableRowCloseActive,
            }}
            transitionEnterTimeout={600}
            transitionLeaveTimeout={230}
          >
            {isExpanded && <div className={classes.expandableRowDetailsContent}>{rowDetails}</div>}
          </CSSTransitionGroup>
        </tr>
      </Fragment>
    )
  }
}

let TableRowLink = class TableRowLink extends Component {
  state = {
    isHovered: false,
  }

  // We put these event handlers on all the cells except the ones with links since we
  // don't want to show a hovered state for the whole row in that case
  handleMouseOver = () => {
    const { isHovered } = this.state
    if (!isHovered) {
      this.setState({ isHovered: true })
    }
  }

  handleMouseOut = () => {
    const { isHovered } = this.state
    if (isHovered) {
      this.setState({ isHovered: false })
    }
  }

  render() {
    const {
      children,
      classes,
      className,
      dragRowProps,
      history,
      id,
      isDragging,
      isDraggableValid,
      isSelectable,
      isSelected,
      rowAction,
      rowLinkTo,
      rowSelectProps,
      showAllSelectBoxes,
      tableContainsActionRow,
      tableContainsExpandableRow,
    } = this.props

    const { isHovered } = this.state

    // We handle hovering/hover styles via these events instead of just CSS so that we can
    // stop hover propagation on links inside table cells
    // We also only want these cells to trigger the link specified
    const childrenWithMouseEventHandlers = React.Children.map(children, child => {
      return React.cloneElement(child, {
        onClick: event => {
          // This allows a custom onClick function to take precedence over the history object
          // This can be used to trigger a modal
          if (rowLinkTo && typeof rowLinkTo.onClick === 'function') {
            rowLinkTo.onClick(event)
          } else {
            history.push(rowLinkTo)
          }
        },
        onMouseOver: this.handleMouseOver,
        onMouseOut: this.handleMouseOut,
      })
    })

    return (
      <tr
        className={cx(className, classes.tableRow, {
          [classes.linkRowHovered]: isHovered,
          [classes.rowSelected]: isSelected,
        })}
        id={id}
        {...dragRowProps}
      >
        {isDraggableValid && (
          <TableCell className={classes.dragColumnCell} isDragOccurring={isDragging}>
            <Tooltip text="Drag to sort">
              <Icon name="drag" className={classes.dragColumnCellIcon} />
            </Tooltip>
          </TableCell>
        )}
        {isSelectable && (
          <TableCell className={classes.selectColumnCell}>
            <TableSelect
              className={classes.selectCheckbox}
              showCheckbox={isSelected || showAllSelectBoxes}
              {...rowSelectProps}
            />
          </TableCell>
        )}
        {// Add empty cell to fill expandable row icon column if necessary
        tableContainsExpandableRow && <TableCell className={classes.expandColumnCell} />}
        {childrenWithMouseEventHandlers}
        {// Row may or may not have an action, in which case the following will just be an empty cell
        tableContainsActionRow && (
          <TableCell className={classes.actionColumnCell}>{rowAction}</TableCell>
        )}
        <TableCell
          className={classes.linkColumnCell}
          onClick={() => {
            history.push(rowLinkTo)
          }}
          onMouseOver={this.handleMouseOver}
          onMouseOut={this.handleMouseOut}
        >
          <Link
            className={classes.linkRowLinkIconContainer}
            onClick={event => {
              // So thtat the onClick handler for the wrapping <TableCell> isn't invoked
              event.stopPropagation()
            }}
            to={rowLinkTo}
          >
            <Icon className={classes.linkRowLinkIcon} name="chevronRight" />
          </Link>
        </TableCell>
      </tr>
    )
  }
}
TableRowLink = withRouter(TableRowLink)

class TableRow extends Component {
  render() {
    const {
      children,
      id: rowId,
      isDraggable,
      resourceName,
      provided,
      snapshot,
      ...restProps
    } = this.props

    const isExpandable = !!restProps.rowDetails
    const isLinkRow = !!restProps.rowLinkTo

    // Prepend the resource name to the row element's id attribute if provided
    const id = resourceName ? `${resourceName}-${rowId}` : rowId

    // Set up drag and drop functionality if it exists
    const isDraggableValid = isDraggable && provided
    let dragRowProps = {}

    if (isDraggableValid) {
      dragRowProps = {
        ref: provided.innerRef,
        ...provided.draggableProps,
        ...provided.dragHandleProps,
      }
    }

    let isDragging = false

    if (snapshot && snapshot.isDragging) {
      isDragging = snapshot.isDragging
    }

    if (isExpandable) {
      return (
        <TableRowExpandable
          id={id}
          {...restProps}
          isDragging={isDragging}
          isDraggableValid={isDraggableValid}
          dragRowProps={dragRowProps}
        >
          {children}
        </TableRowExpandable>
      )
    }
    if (isLinkRow) {
      return (
        <TableRowLink
          id={id}
          {...restProps}
          isDragging={isDragging}
          isDraggableValid={isDraggableValid}
          dragRowProps={dragRowProps}
        >
          {children}
        </TableRowLink>
      )
    }
    const {
      classes,
      className,
      isSelectable,
      isSelected,
      rowAction,
      rowSelectProps,
      showAllSelectBoxes,
      tableContainsActionRow,
      tableContainsExpandableRow,
      tableContainsLinkRow,
      rowMinHeight,
    } = restProps

    return (
      <tr
        className={cx(className, classes.tableRow, {
          [classes.rowSelected]: isSelected,
          [classes.rowDragging]: isDragging,
        })}
        id={id}
        style={{ height: rowMinHeight }}
        {...dragRowProps}
      >
        {isDraggableValid && (
          <TableCell className={classes.dragColumnCell} isDragOccurring={isDragging}>
            <Tooltip text="Drag to sort">
              <Icon name="drag" className={classes.dragColumnCellIcon} />
            </Tooltip>
          </TableCell>
        )}
        {isSelectable && (
          <TableCell className={classes.selectColumnCell} isDragOccurring={isDragging}>
            <TableSelect
              className={classes.selectCheckbox}
              showCheckbox={isSelected || showAllSelectBoxes}
              {...rowSelectProps}
            />
          </TableCell>
        )}
        {// Add empty cell to fill expandable row icon column if necessary
        tableContainsExpandableRow && (
          <TableCell className={classes.expandColumnCell} isDragOccurring={isDragging} />
        )}
        {children}
        {// Row may or may not have an action, in which case the following will just be an empty cell
        tableContainsActionRow && (
          <TableCell className={classes.actionColumnCell} isDragOccurring={isDragging}>
            {rowAction}
          </TableCell>
        )}
        {// Add empty cell to fill link row icon column if necessary
        tableContainsLinkRow && (
          <TableCell className={classes.linkColumnCell} isDragOccurring={isDragging} />
        )}
      </tr>
    )
  }
}

// TODO: Add proptypes and defaultProps

// TableRow.propTypes = {
// }

// TableRow.defaultProps = {
// }

TableRow.displayName = 'TableRow'

// export default TableRow
export default injectSheet(styles)(TableRow)
