// The following was forked from https://github.com/react-component/tree-select

import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { calcCheckStateConduct } from 'rc-tree/lib/util'

import Tree from './Tree'

import {
  calcUncheckConduct,
  convertDataToEntities,
  convertTreeToData,
  formatValueList,
  getFilterTreeAndData,
  parseSimpleTreeData,
} from './utils'

class TreeSelect extends Component {
  static propTypes = {
    children: PropTypes.node,
    /**
     * `defaultValue` determines the initial checked keys
     */
    defaultValue: PropTypes.array,
    disabled: PropTypes.bool,
    /**
     * `filterTreeNodeFn` should take the following form:
     * (filterValue, node, nodeFilterProp) => true|false (is a filter match or not)
     */
    filterTreeNodeFn: PropTypes.func.isRequired,
    filterValue: PropTypes.string,
    noResultsFoundString: PropTypes.string,
    onChange: PropTypes.func,
    /**
     * Determines the values passed into `onChange`
     */
    onChangeCheckedStrategy: PropTypes.oneOf(['SHOW_ALL', 'SHOW_PARENT', 'SHOW_CHILD']).isRequired,
    /**
     * If provided, allows consumer to render the tree data as they please and check/uncheck tree nodes.
     * `checkedTreeNodeValues` are provided given the `renderCheckedStrategy` which can differ
     * from `onChangeCheckedStrategy` (which controls the values passed to `onChange`).
     * (If not provided, we simply render <Tree>.)
     * ({ checkedTreeNodeValues, checkTreeNode, uncheckTreeNode }) => (...)
     */
    render: PropTypes.func,
    renderCheckedStrategy: PropTypes.oneOf(['SHOW_ALL', 'SHOW_PARENT', 'SHOW_CHILD']),
    treeData: PropTypes.array,
    treeDataSimpleMode: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    treeNodeFilterProp: PropTypes.string.isRequired,
    /**
     * If the consumer controls this component, `value` determines the checked keys
     */
    value: PropTypes.array,
    /**
     * If specified, allows for the disabling of certain keys within the tree;
     * takes the same data as the `value` property
     */
    disabledKeys: PropTypes.array,
  }

  static defaultProps = {
    filterTreeNodeFn: (filterValue, node, nodeFilterProp) => {
      if (filterValue) {
        // Filter ignoring case
        const nodeValue = String(node.props[nodeFilterProp]).toUpperCase()
        return nodeValue.indexOf(filterValue.toUpperCase()) !== -1
      }
      return false
    },
    treeDataSimpleMode: true,
    treeNodeFilterProp: 'title',
    onChangeCheckedStrategy: 'SHOW_PARENT',
    renderCheckedStrategy: 'SHOW_PARENT',
  }

  state = {
    init: true,
    valueEntities: {},
    keyEntities: {},
    isInFilterMode: false,
    filterValue: '',
    checkedKeys: [],
    halfCheckedKeys: [],
    filteredKeys: [],
    disabledKeys: [],
    expandedKeysUnfilteredMode: [],
    expandedKeysFilteredMode: [],
    filteredTreeNodes: [],
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { prevProps = {} } = prevState
    const { filterTreeNodeFn, treeDataSimpleMode, treeNodeFilterProp } = nextProps

    const newState = {
      prevProps: nextProps,
      init: false,
    }

    // Call `callback` with the new and old prop values if it changed
    function ifPropChangedThen(propName, callback) {
      if (prevProps[propName] !== nextProps[propName]) {
        callback(nextProps[propName], prevProps[propName])
        return true
      }
      return false
    }

    let valueRefresh = false

    // Tree Nodes
    let treeData
    let treeDataChanged = false
    ifPropChangedThen('treeData', propValue => {
      treeData = propValue
      treeDataChanged = true
    })

    // Parse tree data by `treeDataSimpleMode` if true/object
    if (treeDataSimpleMode && treeDataChanged) {
      const simpleMapper = {
        id: 'id',
        pId: 'pId',
        rootPId: null,
        ...(treeDataSimpleMode !== true ? treeDataSimpleMode : {}),
      }
      treeData = parseSimpleTreeData(nextProps.treeData, simpleMapper)
    }

    // If `treeData` is not provided, use children TreeNodes instead
    if (!nextProps.treeData) {
      ifPropChangedThen('children', propValue => {
        treeData = convertTreeToData(propValue)
      })
    }

    // Mark nodes as disabled if necessary
    ifPropChangedThen('disabledKeys', propValue => {
      newState.disabledKeys = propValue
    })

    // Convert `treeData` to entities
    if (treeData) {
      const { treeNodes, valueEntities, keyEntities } = convertDataToEntities(treeData, {
        disabledKeys: newState.disabledKeys,
      })
      newState.treeNodes = treeNodes
      newState.valueEntities = valueEntities
      newState.keyEntities = keyEntities
      valueRefresh = true
    }

    // Handle values provided by consumer
    if (prevState.init) {
      ifPropChangedThen('defaultValue', propValue => {
        newState.checkedKeys = propValue
        valueRefresh = true
      })
    }
    ifPropChangedThen('value', propValue => {
      newState.checkedKeys = propValue
      valueRefresh = true
    })

    // Determine/finalize values for `newState.checkedKeys` and `newState.halfCheckedKeys`
    const latestTreeNodes = newState.treeNodes || prevState.treeNodes
    if (valueRefresh) {
      const { checkedKeys, halfCheckedKeys } = calcCheckStateConduct(
        latestTreeNodes,
        newState.checkedKeys || prevState.checkedKeys || []
      )

      newState.checkedKeys = checkedKeys
      newState.halfCheckedKeys = halfCheckedKeys
    }

    // Determine filter state value
    ifPropChangedThen('filterValue', filterValue => {
      if (filterValue) {
        const { filteredTreeNodes, filteredKeys, keysToExpand } = getFilterTreeAndData(
          latestTreeNodes,
          filterValue,
          filterTreeNodeFn,
          treeNodeFilterProp
        )
        newState.isInFilterMode = true
        newState.filteredTreeNodes = filteredTreeNodes
        newState.filteredKeys = filteredKeys
        newState.expandedKeysFilteredMode = keysToExpand
      } else {
        newState.isInFilterMode = false
      }
    })

    return newState
  }

  // ==================== Render =====================
  // (Methods can be used by components in render method)

  checkTreeNode = checkedNodeValue => {
    this.updateCheckedKeys(checkedNodeValue, true)
  }

  uncheckTreeNode = uncheckedNodeValue => {
    this.updateCheckedKeys(uncheckedNodeValue, false)
  }

  // ===================== Tree ======================

  onTreeNodeCheck = (_ignore, nodeEventInfo) => {
    const isAdd = nodeEventInfo.checked
    const { node } = nodeEventInfo
    const { value } = node.props

    this.updateCheckedKeys(value, isAdd)
  }

  /**
   * Given `nodeValue` and `isChecked`, update all of the tree's checked/unchecked keys.
   * If `isChecked`, then the node corresponding to `nodeValue` has been checked.
   * Otherwise, if `isChecked` is `false`, then the node has been unchecked.
   */
  updateCheckedKeys = (nodeValue, isChecked) => {
    const { checkedKeys: currentCheckedKeys, keyEntities, valueEntities, treeNodes } = this.state
    const { disabled } = this.props

    if (!disabled) {
      let keysToValidate
      if (isChecked) {
        // User checked tree node
        const checkedKey = valueEntities[nodeValue].key

        keysToValidate = [...currentCheckedKeys, checkedKey]
      } else {
        // User unchecked tree node -> Properly calculate the newly checked keys (post un-check) as rc-tree doesn't do this for us
        const uncheckedKey = valueEntities[nodeValue].key
        keysToValidate = calcUncheckConduct(currentCheckedKeys, uncheckedKey, keyEntities)
      }

      // Given the new set of checked keys post-check or post-uncheck - determine which keys should be checked / half-checked
      const { checkedKeys, halfCheckedKeys } = calcCheckStateConduct(treeNodes, keysToValidate)

      this.handleChange(checkedKeys, halfCheckedKeys)
    }
  }

  handleChange = (checkedKeys, halfCheckedKeys) => {
    /**
     * 1. Update `checkedKeys` state
     * 2. Fire `onChange` event if provided by consumer
     */
    const { onChange, onChangeCheckedStrategy } = this.props
    const { keyEntities } = this.state

    if (!('value' in this.props)) {
      this.setState({
        checkedKeys,
        halfCheckedKeys,
      })
    }
    if (onChange) {
      // Only send back to onChange the keys relevant to the onChangeCheckedStrategy
      const onChangeValues = formatValueList(checkedKeys, onChangeCheckedStrategy, keyEntities)
      onChange(onChangeValues)
    }
  }

  onTreeNodeExpand = (newExpandedKeys, { node, expanded }) => {
    const { isInFilterMode } = this.state
    if (isInFilterMode) {
      const expandedKeysFilteredMode = newExpandedKeys
      this.setState({ expandedKeysFilteredMode })
    } else {
      let expandedKeysUnfilteredMode
      if (!expanded) {
        // If we collapsed an expanded node in non-filter mode, collapse all its children (by ensuring
        // that none of its children's keys are in the new expandedKeysUnfilteredMode)
        const collapsedNode = node
        function getChildKeys(childNodes) {
          if (childNodes && childNodes.length > 0) {
            return childNodes.reduce((resultChildKeys, childNode) => {
              // If childNode key is in newExpandedKeys, then dig further down
              if (newExpandedKeys.includes(childNode.key)) {
                const childKeys = getChildKeys(childNode.props.children)
                if (childKeys) {
                  // childKeys !== null (an array, either empty or full of child keys)
                  resultChildKeys.push(childNode.key)
                  if (childKeys.length > 0) {
                    resultChildKeys.push(...childKeys)
                  }
                }
              }
              return resultChildKeys
            }, [])
          }
          // Omit nodes with no children (leaves)
          return null
        }
        const keysToEnsureAreCollapsed = getChildKeys(collapsedNode.props.children)
        expandedKeysUnfilteredMode = newExpandedKeys.filter(
          key => !keysToEnsureAreCollapsed.includes(key)
        )
      } else {
        expandedKeysUnfilteredMode = newExpandedKeys
      }

      this.setState({ expandedKeysUnfilteredMode })
    }
  }

  // ===================== Render =====================

  render() {
    const {
      isInFilterMode,
      keyEntities,
      checkedKeys,
      halfCheckedKeys,
      filteredKeys,
      expandedKeysFilteredMode,
      expandedKeysUnfilteredMode,
      treeNodes,
      filteredTreeNodes,
    } = this.state
    const {
      filterTreeNodeFn,
      filterValue,
      noResultsFoundString,
      render,
      renderCheckedStrategy,
      treeNodeFilterProp,
    } = this.props

    const treeProps = {
      noResultsFoundString,

      filterTreeNode: treeNode => filterTreeNodeFn(filterValue, treeNode, treeNodeFilterProp),
      treeNodeFilterProp,
      filterValue,

      // Only pass in checked keys that are inside filteredTreeNodes
      checkedKeys: isInFilterMode
        ? checkedKeys.filter(checkedKey => filteredKeys.includes(checkedKey))
        : checkedKeys,
      halfCheckedKeys: isInFilterMode
        ? halfCheckedKeys.filter(halfCheckedKey => filteredKeys.includes(halfCheckedKey))
        : halfCheckedKeys,

      expandedKeys: isInFilterMode ? expandedKeysFilteredMode : expandedKeysUnfilteredMode,

      treeNodes: isInFilterMode ? filteredTreeNodes : treeNodes,
      onTreeNodeCheck: this.onTreeNodeCheck,
      onTreeNodeExpand: this.onTreeNodeExpand,
    }

    const renderProps = {
      checkedTreeNodeValues: formatValueList(checkedKeys, renderCheckedStrategy, keyEntities),
      checkTreeNode: this.checkTreeNode,
      uncheckTreeNode: this.uncheckTreeNode,
    }

    return render ? render(renderProps) : <Tree {...treeProps} />
  }
}

export default TreeSelect
