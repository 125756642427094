import React, { Component } from 'react'
import injectSheet from 'react-jss'
import debounce from 'lodash/debounce'

import { fetchCollections, fetchPlacementContentByString, fetchPlacements } from 'api'
import {
  Button,
  FormFieldItem,
  LoadingIndicator,
  ParentalSelector,
  SelectedItems,
  StatusIndicatorBadge,
} from 'components'

import { reorder } from 'components/DataTable/utils/reorder'
import { generateBackButton, getRoutePathname } from 'routing'
import { addValueToQueryString } from 'utils'

import styles from './MerchandisedLandingPages.styles'

import * as placementTypes from '../../../../constants/placementTypes'

const CONTENT_TYPES = {
  CONTENT_PLACEMENT: 'content_placement',
  COLLECTION: 'collection',
}

// Manually set a high number of collections since Dropdown does not currently support active searching the endpoint
// This ensures that most collections will always be available to select
const FETCH_COLLECTION_LIMIT = 500
const FETCH_BANNER_LIMIT = 500

class MerchandisedLandingPagesContentSelect extends Component {
  state = {
    banners: null,
    collections: null,
    filteredItems: null,
    selectedType: null,
    selectedItem: null,
    errorFetchingData: false,
    isFetchingData: false,
    isFetchingItemsAsync: false,
    mlpPlacementId: null,
  }

  componentWillMount() {
    const loadContents = async () => {
      this.setState({ isFetchingData: true })
      const [collectionsResponse, placementsResponse] = await Promise.all([
        fetchCollections(`?limit=${FETCH_COLLECTION_LIMIT}`),
        fetchPlacements(),
      ]).catch(e => {
        this.setState({ errorFetchingData: true, isFetchingData: false })
      })

      const { errorFetchingData } = this.state

      if (!errorFetchingData) {
        const mlpPlacementType = placementsResponse.items.find(
          i => i.key === placementTypes.MLP_BANNER
        )

        // Track this for later, different environments have different IDs and tracking saves an API call
        this.setState({
          mlpPlacementId: mlpPlacementType.id,
        })

        let queryString = ''
        queryString = addValueToQueryString(queryString, {
          key: 'placement_id',
          value: mlpPlacementType.id,
        })

        queryString = addValueToQueryString(queryString, {
          key: 'limit',
          value: FETCH_BANNER_LIMIT,
        })

        let placementContentResponse

        try {
          placementContentResponse = await fetchPlacementContentByString(queryString)
        } catch (err) {
          this.setState({ errorFetchingData: true, isFetchingData: false })
          return
        }

        const banners = this.defaultDropdownFormatter(placementContentResponse)
        const collections = this.defaultDropdownFormatter(collectionsResponse)
        this.setState({
          banners,
          collections,
          isFetchingData: false,
        })
      }
    }

    loadContents()
  }

  handleFilterInputChange = debounce(inputValue => {
    const { selectedType } = this.state
    this.setState({ isFetchingItemsAsync: true })

    Promise.resolve()
      .then(() => {
        const { mlpPlacementId } = this.state
        switch (selectedType) {
          case CONTENT_TYPES.CONTENT_PLACEMENT:
            return fetchPlacementContentByString(
              `?search=${inputValue}&placement_id=${mlpPlacementId}`
            )
          case CONTENT_TYPES.COLLECTION:
            return fetchCollections(`?search=${inputValue}`)
        }
      })
      .then(response => {
        this.setState({
          filter: inputValue,
          filteredItems: this.defaultDropdownFormatter(response),
        })
      })
      .finally(() => {
        this.setState({ isFetchingItemsAsync: false })
      })
  }, 250)

  defaultDropdownFormatter(response) {
    return response.items.map(item => ({
      id: item.id,
      label: item.name,
      value: item,
    }))
  }

  render() {
    const { classes, componentId, componentName, labelText, setFieldValue } = this.props
    const {
      banners,
      collections,
      filteredItems,
      isFetchingData,
      isFetchingItemsAsync,
      selectedItem,
      selectedType,
    } = this.state

    if (isFetchingData) return <LoadingIndicator withTopMargin />

    let items = []

    if (selectedType) {
      switch (selectedType) {
        case CONTENT_TYPES.CONTENT_PLACEMENT:
          items = banners
          break
        case CONTENT_TYPES.COLLECTION:
          items = collections
          break
      }
    }
    const parentType = !selectedType
      ? null
      : selectedType === CONTENT_TYPES.CONTENT_PLACEMENT
      ? 'Banner'
      : 'Collection'
    const path = !parentType
      ? null
      : parentType === 'Banner'
      ? {
          pathname: getRoutePathname('promotedPlacements.create', {
            slug: 'mlp-banner',
            id: this.state.mlpPlacementId,
          }),
          state: {
            backButton: generateBackButton('promotedPlacements', location),
            currentPlacementName: 'MLP Banner',
          },
        }
      : {
          pathname: getRoutePathname('collections.createCollection'),
          state: {
            backButton: generateBackButton('merchandisedLandingPages', location),
          },
        }

    return (
      <FormFieldItem
        className={classes.contentSelectContainer}
        id={componentId}
        labelText={labelText}
        name={componentName}
        render={renderProps => {
          const { value: selectedItems } = renderProps

          return (
            <div>
              <ParentalSelector
                id={`${componentId}-parental-select`}
                parentPlaceholder={'Select content type'}
                parentItems={[
                  {
                    id: 'content-placement',
                    label: 'Banner',
                    value: CONTENT_TYPES.CONTENT_PLACEMENT,
                  },
                  { id: 'collection', label: 'Collection', value: CONTENT_TYPES.COLLECTION },
                ]}
                parentSelectedValue={selectedType}
                parentOnChange={item => {
                  this.setState({
                    filteredItems: null,
                    selectedType: item,
                  })
                }}
                onChange={item => {
                  // This happens on clear of second dropdown
                  if (item === null) {
                    this.setState({
                      selectedItem: item,
                      filteredItems: null,
                    })
                    return
                  }
                  this.setState({
                    selectedItem: item,
                  })
                }}
                onFilterInputChange={value => {
                  if (value === '') {
                    this.setState({ filteredItems: null })
                  }
                  this.handleFilterInputChange(value)
                }}
                filteredChildItems={filteredItems}
                items={items}
                selectedValue={selectedItem}
                placeholder={'Select content'}
                buttonDescription={'Add content'}
                showButton
                loading={isFetchingData}
                isFilteringAsync={isFetchingItemsAsync}
                isInvalid={
                  selectedItem && selectedItems.some(item => item.data.id === selectedItem.id)
                }
                buttonDisabled={isFetchingData || selectedItem === null}
                enableFiltering
                buttonOnClick={(type, item) => {
                  setFieldValue(componentName, [
                    ...selectedItems,
                    {
                      type,
                      data: item,
                    },
                  ])
                  this.setState({
                    selectedType: null,
                    selectedItem: null,
                  })
                }}
              />
              {parentType && (
                <div className={classes.contentButton}>
                  <Button href={path}>Create a {parentType}</Button>
                </div>
              )}
              <SelectedItems
                className={classes.selectedItemsBoxed}
                draggable
                headerText={`Selected items`}
                itemName={'item'}
                items={selectedItems.map((i, index) => ({ ...i, id: index })) || []}
                onChange={newSelectedContent => {
                  setFieldValue(componentName, newSelectedContent)
                  this.setState({})
                }}
                onDragEnd={params => {
                  if (params.destination === null) return
                  const newContentOrder = reorder(
                    selectedItems,
                    params.source.index,
                    params.destination.index
                  )
                  setFieldValue(componentName, newContentOrder)
                }}
                renderItem={({ item }) => {
                  if (item.type === CONTENT_TYPES.CONTENT_PLACEMENT) {
                    return (
                      <div>
                        <span className={classes.contentTypeBadgeWrapper}>
                          <StatusIndicatorBadge type={'error'} text={'Banner'} />
                        </span>
                        {item.data.name}
                      </div>
                    )
                  }
                  return (
                    <div>
                      <span className={classes.contentTypeBadgeWrapper}>
                        <StatusIndicatorBadge type={'new'} text={'Collection'} />
                      </span>
                      {item.data.name}
                    </div>
                  )
                }}
              />
            </div>
          )
        }}
      />
    )
  }
}

export default injectSheet(styles)(MerchandisedLandingPagesContentSelect)
