import React from 'react'
import injectSheet from 'react-jss'

import { Button, FormFieldItem, Notification, TextInput, TextArea, RadioButtons } from 'components'
import Styling from 'styling/components'

const styles = theme => ({
  formContainer: {
    width: 610,
  },
  nameInput: {
    width: '100%',
  },
  formInput: {
    maxWidth: 528,
    position: 'relative',
    width: '100%',
  },
})

const BASE_ID = 'create-synonym'

const SynonymForm = ({
  cancelDestination,
  classes,
  values,
  errors,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  pageId,
}) => (
  <form className={classes.formContainer} onSubmit={handleSubmit}>
    <FormFieldItem
      id={`${BASE_ID}-synonyms`}
      labelText="Synonym(s)"
      name="synonyms"
      render={({ value, ...renderProps }) => (
        <TextArea
          {...renderProps}
          autoFocus
          helpText="The terms the customer may enter, separated by comma."
          className={classes.nameInput}
          placeholder="Enter synonyms"
          type="text"
          onChange={({ target: { value } }) => {
            setFieldValue('synonyms', value ? value.split(',') : [])
          }}
          value={value.join(',')}
        />
      )}
    />
    <FormFieldItem
      id={`${BASE_ID}-product-name`}
      labelText="Product Name"
      name="word"
      render={({ value, ...renderProps }) => (
        <TextInput
          {...renderProps}
          className={classes.nameInput}
          placeholder="Enter seach term"
          inputDescription="The name of the product you wish to appear in the search results."
          type="text"
          onChange={({ target: { value } }) => {
            setFieldValue('word', value ? value.split(',') : [])
          }}
          value={value.join(',')}
        />
      )}
    />
    <FormFieldItem
      className={classes.formInput}
      id={`${pageId}-featured`}
      labelText="Synonym Direction"
      name="oneway"
      render={({ id, value }) => {
        const oneWayValue = value === true
        return (
          <RadioButtons
            id={id}
            onChange={oneway => {
              setFieldValue('oneway', oneway)
            }}
            orientation="horizontal"
            radioButtonProps={[
              {
                id: 'oneway',
                labelText: 'One way',
                value: true,
              },
              {
                id: 'twoway',
                labelText: 'Two way',
                value: false,
              },
            ]}
            selectedValue={oneWayValue}
          />
        )
      }}
    />
    {!isSubmitting && errors.global && <Notification kind="error" message={errors.global} />}
    <Styling.LineOfButtons>
      <Button disabled={isSubmitting} id={`${BASE_ID}-save`} type="submit">
        Save
      </Button>
      <Button kind="link" href={cancelDestination}>
        Cancel
      </Button>
    </Styling.LineOfButtons>
  </form>
)

export default injectSheet(styles)(SynonymForm)
