import React from 'react'

import injectSheet from 'react-jss'

import { Button, Modal, PageHeader, LoadingIndicator } from 'components'
import { createToast } from 'modules/toasts'
import { ItemFetcher } from 'utils'
import { getBackButton, getRouteTo } from 'routing'
import { fetchSubscription, updateSubscription, deleteSubscription } from 'api'

import SubscriptionForm from './SubscriptionForm'

const styles = theme => ({
  divider: {
    margin: '32px 0',
  },
})

const EditSubscription = props => {
  const {
    classes,
    history,
    location: { state: locationState = {} },
    match: {
      params: { subscriptionId },
    },
  } = props

  const backButton = locationState.backButton || getBackButton('segments')

  const handleSubmit = (values, form) => {
    updateSubscription(subscriptionId, values)
      .then(subscription => {
        form.setSubmitting(false)
        createToast({ kind: 'success', message: 'Successfully updated subscription.' })
        // Send the user to the Subscriptions table page where they can view their updated subscription
        history.push(getRouteTo('subscriptions'))
      })
      .catch(error => {
        form.setSubmitting(false)
        form.setErrors({ global: error.message })
      })
  }

  const handleDelete = subscriptionId => {
    deleteSubscription(subscriptionId)
      .then(() => {
        createToast({ kind: 'success', message: 'Successfully deleted subscription.' })
        // Send the user to the Subscriptions table page
        history.push(getRouteTo('subscriptions'))
      })
      .catch(({ message }) => {
        createToast({ kind: 'error', message })
      })
  }

  return (
    <ItemFetcher
      queryKey="subscription"
      queryParams={{ subscriptionId }}
      fetchItem={() => fetchSubscription(subscriptionId)}
      render={({ item, isLoadingItem }) => {
        if (isLoadingItem) {
          return <LoadingIndicator withTopMargin />
        }

        if (!item) return null

        item.waiving_fee_types = item.waiving_fee_types || ['pickup_fee']

        return (
          <div>
            <PageHeader backButton={backButton} hasDivider headerTitle="Edit Subscription" />
            <SubscriptionForm
              handleSubmit={handleSubmit}
              initialValues={item}
              backButton={backButton}
            />

            <hr className={classes.divider} />

            <Modal.Confirmation
              handleCloseModal={({ wasConfirmed }) => {
                if (wasConfirmed) {
                  handleDelete(item.id)
                }
              }}
              triggerRender={({ openModal }) => (
                <Button kind="danger" onClick={openModal}>
                  Delete Subscription
                </Button>
              )}
              contentProps={{
                actionText: 'delete this subscription',
                cancelButtonText: 'Go Back',
                confirmButtonText: 'Delete Subscription',
              }}
            />
          </div>
        )
      }}
    />
  )
}

export default injectSheet(styles)(EditSubscription)
