import Form from 'react-jsonschema-form'
import PropTypes from 'prop-types'
import React from 'react'
import injectSheet from 'react-jss'

import { Button, ButtonSlideToggle, Dropdown, Notification, TextInput } from 'components'
import Styling from 'styling/components'

import styles from './Fact.styles'
import { ArrayField, Field, ObjectField } from './templates'

const EditFactForm = ({ classes, onSubmit, returnRoute, factKey = '', factSchema, factValue }) => {
  const ErrorList = () => (
    <Notification kind="error" message="Please fix the error(s) listed below!" />
  )

  const widgets = {
    TextWidget: ({ id, value, onChange, rawErrors }) => {
      return (
        <TextInput
          id={id}
          isInvalid={!!rawErrors}
          value={value || ''}
          onChange={event => onChange(event.target.value)}
        />
      )
    },
    CheckboxWidget: ({ id, value, onChange }) => (
      <ButtonSlideToggle id={id} isToggled={!!value} onClick={() => onChange(!value)} />
    ),
    SelectWidget: ({ id, value, onChange, rawErrors, options: { enumOptions } }) => {
      const items = enumOptions.map((option, idx) => ({
        id: idx,
        label: option.label,
        value: option.value,
      }))
      return (
        <Dropdown
          id={id}
          isInvalid={!!rawErrors}
          items={items}
          placeholder="select value"
          selectedValue={value}
          onChange={event => onChange(event)}
        />
      )
    },
  }

  return (
    <Form
      className={classes.form}
      schema={factSchema}
      formData={factValue}
      onSubmit={onSubmit}
      FieldTemplate={Field}
      ObjectFieldTemplate={ObjectField}
      ArrayFieldTemplate={ArrayField}
      ErrorList={ErrorList}
      uiSchema={classes}
      widgets={widgets}
      omitExtraData
    >
      <Styling.LineOfButtons>
        <Button type="submit">Save</Button>
        <Button kind="link" href={returnRoute}>
          Cancel
        </Button>
      </Styling.LineOfButtons>
    </Form>
  )
}

EditFactForm.propTypes = {
  classes: PropTypes.object,
  factKey: PropTypes.string,
  factSchema: PropTypes.object,
  factValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onSubmit: PropTypes.func.isRequired,
  returnRoute: PropTypes.func.isRequired,
}

export default injectSheet(styles)(EditFactForm)
