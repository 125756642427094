import React from 'react'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import { DropdownFilterable } from 'components'
import { fetchStores } from 'api'
import { useItemsFetcher } from 'utils/itemsFetcherHook'

import styles from '../CreateOrder.styles'

const useStyles = createUseStyles(styles)

const Stores = ({ handleChange, selectedStore }) => {
  const classes = useStyles()

  // Fetch the stores
  const { isLoadingItems, items } = useItemsFetcher('GET_STORES', () => fetchStores('', null))

  return (
    <>
      <h2 className={cx(classes.sectionHeader, classes.sectionHeaderTop)}>Store</h2>
      <DropdownFilterable
        id="selectedStore"
        placeholder="Select store"
        isLoading={isLoadingItems}
        selectedValue={selectedStore}
        className={classes.smallInput}
        items={items
          .map(store => ({
            id: store.id,
            value: store,
            label: `${store.name} - ${store.id}`,
          }))
          .sort((a, b) => a.label.localeCompare(b.label))}
        onChange={value => handleChange({ target: { value, id: 'selectedStore' } })}
      />
    </>
  )
}

export default Stores
