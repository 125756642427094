import React from 'react'
import injectSheet from 'react-jss'

import {
  Button,
  ButtonIconAction,
  DataTableWithState,
  FormFieldItem,
  ImageUpload,
  TextInput,
} from 'components'
import { reorder } from 'components/DataTable/utils/reorder'
import { uploadImage } from 'api'

import styles from '../PromotedPlacementsForm.styles'

const PlacementItemListConfig = ({
  classes,
  componentId,
  enableAltText,
  requirementsConfig,
  setFieldError,
  setFieldValue,
  values: { config },
}) => (
  <>
    <FormFieldItem
      id={`${componentId}-items`}
      labelText="Items"
      name="config.items"
      render={({ name }) => (
        <>
          <DataTableWithState
            id={`${componentId}-items-table`}
            kind="default"
            emptyMsg="No items"
            headers={[
              {
                key: 'image',
                header: 'Image',
              },
              {
                key: 'label',
                header: 'Label',
              },
              {
                key: 'url',
                header: 'URL',
              },
              enableAltText
                ? {
                    key: 'altText',
                    header: 'Alt Text',
                  }
                : undefined,
              {
                key: 'placeholder',
              },
            ].filter(Boolean)}
            rows={config.items.map(({ label }, index) => ({
              id: index.toString(),
              image: (
                <FormFieldItem
                  id={`${componentId}-item-image`}
                  name={`${name}.${index}.image`}
                  className={classes.itemListImageUpload}
                  render={({ id, name, value }) => (
                    <>
                      <ImageUpload
                        id={id}
                        name={name}
                        image={value}
                        compact
                        onImageUpload={uploadImage}
                        onImageUploadComplete={image => {
                          setFieldValue(name, image)
                        }}
                        onUploadError={error => {
                          setFieldError(name, error)
                        }}
                        validateSize={requirementsConfig?.validateSize}
                        fileRequirementsText={
                          requirementsConfig && !value
                            ? `Recommended Dimensions - ${requirementsConfig.width} x ${requirementsConfig.height}`
                            : null
                        }
                        snakeCase
                      />
                    </>
                  )}
                />
              ),
              label: (
                <FormFieldItem
                  id={`${componentId}-item-label`}
                  name={`${name}.${index}.label`}
                  render={props => <TextInput {...props} />}
                />
              ),
              url: (
                <FormFieldItem
                  id={`${componentId}-item-url`}
                  name={`${name}.${index}.url`}
                  render={props => <TextInput {...props} />}
                />
              ),
              altText: (
                <FormFieldItem
                  id={`${componentId}-item-alt`}
                  name={`${name}.${index}.altText`}
                  render={props => <TextInput {...props} />}
                />
              ),
              // The table doesn't render itself without at least one scalar cell value.
              placeholder: '',
              rowAction: (
                <ButtonIconAction
                  description="Remove"
                  icon="delete"
                  onClick={() => {
                    const items = [
                      ...config.items.slice(0, index),
                      ...config.items.slice(index + 1),
                    ]
                    setFieldValue(name, items)
                  }}
                />
              ),
            }))}
            isDraggable
            onDragEnd={({ data }) => {
              const { source, destination } = data
              if (!source || !destination) return

              const items = reorder(config.items, source.index, destination.index)
              setFieldValue(name, items)
            }}
          />
          <br />
          <Button
            kind="secondary"
            onClick={() => {
              const items = [
                ...config.items,
                {
                  altText: '',
                  image: null,
                  label: '',
                  url: '',
                },
              ]
              setFieldValue(name, items)
            }}
          >
            Add Item
          </Button>
        </>
      )}
    />
  </>
)

export default injectSheet(styles)(PlacementItemListConfig)
