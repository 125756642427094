import memoize from 'memoize-one'
import React, { Component } from 'react'
import injectSheet from 'react-jss'
import { Value } from 'react-values'

import { hasAnyOfPermissions } from 'utils/navigationUtils'
import { fetchOrders, fetchEnabledStores } from 'api'
import config from 'config'

import {
  Button,
  DataTable,
  Dropdown,
  DropdownDate,
  MinMax,
  MultiSelect,
  MultiSelectFilterable,
  LoadingIndicator,
  PageHeader,
  SearchInput,
  ShowIfAuthorized,
  StatusIndicatorDot,
} from 'components'
import { generateBackButton, getRoutePathname } from 'routing'
import {
  queryString,
  capitalize,
  formatDatetime,
  getPersonName,
  ItemsFetcherWithParams,
  toCurrency,
} from 'utils'

import { getOrderStatusByKey, getPaymentTypeText } from 'utils/orderUtils'
import { getStoresWithAlcoholStores } from 'utils/storeUtils'

const styles = theme => ({
  searchInput: {
    width: 340,
  },
  filtersAndActions: {
    display: 'flex',
    marginBottom: theme.spacing.lg,
  },
  filters: {
    display: 'flex',
    flexGrow: 1,

    '& > *': {
      marginBottom: 0,
      maxWidth: 230,
      marginRight: theme.spacing.xs,

      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  actions: {
    alignItems: 'center',
    display: 'flex',
    marginLeft: theme.spacing.lg,
  },
  dataLoader: {
    marginTop: theme.spacing.lg,
  },
  tableAction: {
    margin: theme.spacing.sm,
  },
})

const DEFAULT_ORDERS_LIMIT = 20
// 1000 records returns in ~3s anything higher would be too slow
const ORDER_CSV_LIMIT = 1000

const tableParams = {
  fulfillmentDateRange: { key: 'fulfillment_date', type: 'range' }, // parses `fulfillment_date_lower` & `fulfillment_date_upper` into `fulfillmentDateRange.value: { from, to }`
  fulfillmentType: { key: 'fulfillment_type' },
  orderStatuses: { key: 'status', type: 'array' },
  orderTotalRange: { key: 'total', type: 'range' }, // parses `order_total_lower` & `order_total_upper` into `orderTotalRange.value: { from, to }`
  paging: {
    type: 'multi',
    multiParams: { limit: { key: 'limit' }, offset: { key: 'offset' } },
  },
  paymentTypes: { key: 'payment_type', type: 'array' },
  search: { key: 'search' },
  sorting: {
    type: 'multi',
    multiParams: { direction: { key: 'direction' }, orderBy: { key: 'order_by' } },
  },
  storeIds: { key: 'store_id', type: 'array' },
  icRetailerLocationIds: { key: 'ic_retailer_location_id', type: 'array' },
}

const filterOptions = {
  fulfillmentType: [
    { id: 'clear', label: 'Any Type', value: null },
    { id: 'fulf-type-pickup', label: 'Pickup', value: 'pickup' },
    { id: 'fulf-type-delivery', label: 'Delivery', value: 'delivery' },
  ],
  paymentTypes: [
    { id: 'cash', label: 'Cash', value: 'cash' },
    { id: 'debit', label: 'Debit Card', value: 'debit' },
    { id: 'auth-capture', label: 'Credit Card', value: 'auth_capture' },
    { id: 'paypal', label: 'Paypal', value: 'paypal' },
    { id: 'house-account', label: 'House Account', value: 'house_account' },
    { id: 'credit', label: 'Credit', value: 'credit' },
  ],
  orderStatuses: [
    { id: 'orders-filter-status-new', label: 'New', value: 'new' },
    { id: 'orders-filter-status-inprogress', label: 'In Progress', value: 'inprogress' },
    { id: 'orders-filter-status-done', label: 'Picking Completed', value: 'done' },
    { id: 'orders-filter-status-shipped', label: 'Shipped', value: 'shipped' },
    { id: 'orders-filter-status-cancelled', label: 'Cancelled', value: 'cancelled' },
  ],
}

// Order table header keys must match value used after `sortBy` in `/orders` query
// (in addition to the corresponding row property)
const getOrderTableHeaders = (disableSorting, hidePaymentTypeColumn) => {
  let headers = [
    {
      key: 'orderId',
      header: 'Order Id',
      disableSorting,
    },
    {
      key: 'fulfillmentDate',
      header: 'Fulfillment Date',
      disableSorting,
    },
    {
      key: 'fulfillment_type',
      header: 'Fulfillment Type',
      disableSorting,
    },
    {
      key: 'store_id',
      header: 'Store Id',
      disableSorting,
    },
    {
      key: 'customerName',
      header: 'Guest',
      disableSorting: true,
    },
    {
      key: 'payment_type',
      header: 'Payment Method',
      disableSorting,
    },
    {
      key: 'total',
      header: 'Order Total',
      disableSorting,
    },
    {
      key: 'status',
      header: 'Status',
      disableSorting,
    },
  ]

  if (hidePaymentTypeColumn) {
    headers = headers.filter(header => header.key !== 'payment_type')
  }

  return headers
}

const customDateOptionsOneCart = [
  { id: 'clear', label: 'Anytime' },
  {
    id: 'tdy',
    label: 'Today',
    todayDeltas: {
      from: 0,
      to: 0,
    },
  },
  {
    id: 'tmrw',
    label: 'Tomorrow',
    todayDeltas: {
      from: 1,
      to: 1,
    },
  },
  {
    id: 'ystr',
    label: 'Yesterday',
    todayDeltas: {
      from: -1,
      to: -1,
    },
  },
  {
    id: 'custom',
    label: 'Custom',
    hideEndDate: true,
    tooltip: 'Selecting a date will display orders from that 24-hour cycle.',
  },
]

const formatOrderTableRows = memoize((orders, location, hidePaymentTypeColumn) =>
  orders.map((order, index) => {
    const {
      customer,
      estimatedTotals,
      finalTotals,
      fulfillmentDate,
      fulfillmentType,
      id,
      ic_order_id,
      ic_user_id,
      order_reference,
      paymentType,
      status,
      store,
    } = order
    const { indicatorType, shouldUseEstimatedTotals, statusText } = getOrderStatusByKey(status)
    const orderTotalToUse = shouldUseEstimatedTotals ? estimatedTotals : finalTotals
    const orderTotal = toCurrency(orderTotalToUse.total)
    const customerQueryParam = ic_user_id ? `?ic_user_id=${ic_user_id}` : ''

    const orderTableRow = {
      id: `order-${id}-${index}`,
      rowLinkTo: {
        pathname: getRoutePathname('orders.order', { id: ic_order_id || id }),
        search: order_reference ? `?order_reference=${order_reference}` : '',
        state: { backButton: generateBackButton('orders', location) },
      },
      orderId: id,
      fulfillmentDate: formatDatetime(fulfillmentDate),
      fulfillment_type: capitalize({ phrase: fulfillmentType }),
      store_id: store.extId,
      customerName: (
        <ShowIfAuthorized
          requiredPermission="customers.view"
          unauthorizedFallback={getPersonName({ person: customer, fallback: '' })}
        >
          <Button
            kind="link"
            href={`${getRoutePathname('customers.customer', {
              id: customer.id,
            })}${customerQueryParam}`}
          >
            {getPersonName({ person: customer, fallback: '' })}
          </Button>
        </ShowIfAuthorized>
      ),
      payment_type: getPaymentTypeText(paymentType),
      total: orderTotal,
      status: <StatusIndicatorDot text={statusText} type={indicatorType} />,
    }

    if (hidePaymentTypeColumn) {
      delete orderTableRow.payment_type
    }
    return orderTableRow
  })
)

const generateStoreMultiselectOptions = stores =>
  stores.map(store => ({
    id: `store-${store.id}`,
    label: `${store.name} - ${store.id}`,
    value: `${store.id}`, // cast to string to ensure matches with store id param
  }))

const generateStoreMultiselectOptionsPBI = stores =>
  stores.map(store => ({
    id: `store-${store.retailer_store_id}`,
    label: `${store.name} - ${store.retailer_store_id}`,
    value: `${store.warehouse_location_id}`,
  }))

const generateCSVExportLink = queryParams => {
  const { limit, offset, ...paramsWithoutPagination } = queryParams

  paramsWithoutPagination.limit = ORDER_CSV_LIMIT

  return `${config.env.apiPath}/orders/csv?${queryString.stringify(paramsWithoutPagination)}`
}

class Orders extends Component {
  constructor(props) {
    super(props)

    const queryParams = queryString.parse(this.props.location.search)

    this.state = {
      isFetchingOrders: false,
      isFetchingStores: false,
      orderSourcePBI: null,
      dataRows: [],
      hasInitialOffset: Boolean(queryParams.offset),
      stores: [],
      currentQuery: {},
    }
  }

  fetchItems(query, config) {
    this.setState({ isFetchingOrders: true })

    return fetchOrders(query, config).then(result => {
      const orderSourcePBI = Boolean(result.meta && 'end_cursor' in result.meta)
      const currentItems = this.state.dataRows
      const newItems = result.items || []

      // If the query has changed, for PBI orders, we want to refresh the list rather than add on
      // If the only changed parameter is the offset, we are infinte scrolling and don't need to reload the items
      const currentKeys = Object.keys(this.state.currentQuery).filter(key => key !== 'offset')
      const newKeys = Object.keys(queryString.parse(query)).filter(key => key !== 'offset')

      const keysMatch =
        currentKeys.every(item => newKeys.includes(item)) &&
        newKeys.every(item => currentKeys.includes(item))

      const valuesMatch = currentKeys.every(
        s => this.state.currentQuery[s] === queryString.parse(query)[s]
      )

      const sameQuery = valuesMatch && keysMatch

      this.setState({
        orderSourcePBI,
        isFetchingOrders: false,
        dataRows: orderSourcePBI && sameQuery ? [...currentItems, ...newItems] : newItems,
        currentQuery: queryString.parse(query),
      })

      return result
    })
  }

  componentDidMount() {
    this.getEnabledStores()
  }

  // TODO: Move this to MultiSelectFilterable in the form of another prop
  getEnabledStores() {
    this.setState({ isFetchingStores: true })

    fetchEnabledStores()
      .then(({ items }) => {
        this.setState({
          stores: items,
        })
      })
      .finally(() => {
        this.setState({ isFetchingStores: false })
      })
  }

  render() {
    const { classes, history, location } = this.props
    const {
      dataRows,
      isFetchingOrders,
      isFetchingStores,
      orderSourcePBI,
      hasInitialOffset,
      stores,
    } = this.state

    let storeMultiselectOptions
    if (orderSourcePBI) {
      const storesWithAlcoholStores = getStoresWithAlcoholStores(stores)
      storeMultiselectOptions = generateStoreMultiselectOptionsPBI(storesWithAlcoholStores)
    } else {
      storeMultiselectOptions = generateStoreMultiselectOptions(stores)
    }

    return (
      <ItemsFetcherWithParams
        queryKey="orders"
        defaultItemsLimit={DEFAULT_ORDERS_LIMIT}
        routingParams={{
          location,
          history,
        }}
        fetchItems={(...args) => this.fetchItems(...args)}
        paramDefinitions={tableParams}
        render={({
          queryParams,
          params: orderParams,
          itemCount: orderCount,
          error: errorFetchingOrders,
          meta,
        }) => {
          // Determine path for the link that will fetch an orders csv of the complete orders list
          const exportCSVHref = generateCSVExportLink(queryParams)
          const hidePaymentTypeColumn = hasAnyOfPermissions(['orders.hide_payment_type_column'])
          const orderTableHeaders = getOrderTableHeaders(
            orderSourcePBI !== false,
            hidePaymentTypeColumn
          )
          const orderTableRows = formatOrderTableRows(dataRows, location, hidePaymentTypeColumn)

          // Paging props
          const resultsPerPage =
            parseInt(orderParams.paging.multiParams.limit.value, 10) || DEFAULT_ORDERS_LIMIT
          const pageNumber = (orderParams.paging.multiParams.offset.value || 0) / resultsPerPage + 1

          const updatePagingState = offset => {
            orderParams.paging.onChange({ offset: offset || null, limit: resultsPerPage })
          }

          const resetDataState = search => {
            if (typeof search === 'string') {
              orderParams.search.onChange(search || null)
            }
            updatePagingState(null)
            this.setState({ dataRows: [], hasInitialOffset: false })
          }

          return (
            <div>
              <PageHeader headerTitle="Order Management">
                <Value defaultValue={orderParams.search.value || ''}>
                  {({ set: handleChange, value }) => (
                    <SearchInput
                      className={classes.searchInput}
                      id="orders-search"
                      onClear={oldVal => oldVal === orderParams.search.value && resetDataState('')}
                      onChange={handleChange}
                      onSubmit={() => resetDataState(value)}
                      placeholder={orderSourcePBI === false ? 'Search' : 'Search Order ID'}
                      value={value}
                    />
                  )}
                </Value>
              </PageHeader>
              <div className={classes.filtersAndActions}>
                <div className={classes.filters}>
                  <DropdownDate
                    hasDateOptions
                    customDateOptions={orderSourcePBI === false ? null : customDateOptionsOneCart}
                    id="orders-filter-fulfillment-date"
                    onChange={orderParams.fulfillmentDateRange.onChange}
                    placeholder="Fulfillment Date"
                    returnDatetimeValues={false}
                    defaultValue={orderParams.fulfillmentDateRange.value}
                  />
                  <Dropdown
                    id="orders-filter-fulfillment-type"
                    items={filterOptions.fulfillmentType}
                    onChange={orderParams.fulfillmentType.onChange}
                    placeholder="Fulfillment Type"
                    selectedValue={orderParams.fulfillmentType.value}
                  />
                  <MultiSelectFilterable
                    id="orders-filter-stores"
                    isLoading={isFetchingStores}
                    items={storeMultiselectOptions}
                    noItemsMsg="No stores found"
                    nowrap
                    onChange={
                      orderSourcePBI
                        ? orderParams.icRetailerLocationIds.onChange
                        : orderParams.storeIds.onChange
                    }
                    placeholder="Store"
                    selectedValues={
                      orderSourcePBI
                        ? orderParams.icRetailerLocationIds.value
                        : orderParams.storeIds.value
                    }
                  />
                  {orderSourcePBI === false ? (
                    <>
                      <MultiSelect
                        id="orders-filter-payment-method"
                        items={filterOptions.paymentTypes}
                        onChange={orderParams.paymentTypes.onChange}
                        placeholder="Payment Method"
                        selectedValues={orderParams.paymentTypes.value}
                      />
                      <MinMax
                        id="orders-filter-order-total"
                        onChange={orderParams.orderTotalRange.onChange}
                        placeholder="Order Total"
                        type="currency"
                        value={orderParams.orderTotalRange.value}
                      />
                    </>
                  ) : null}

                  <MultiSelect
                    id="orders-filter-status"
                    items={filterOptions.orderStatuses}
                    nowrap
                    onChange={orderParams.orderStatuses.onChange}
                    placeholder="Status"
                    selectedValues={orderParams.orderStatuses.value}
                  />
                </div>
                <div className={classes.actions}>
                  <Button
                    href={exportCSVHref}
                    icon="export"
                    iconPosition="before"
                    kind="link"
                    linkIsDownload
                  >
                    Export CSV
                  </Button>
                </div>
              </div>
              <DataTable
                error={errorFetchingOrders}
                headers={orderTableHeaders}
                id="orders-table"
                isLoadingNewRows={isFetchingOrders && orderTableRows.length === 0}
                onSortBy={({ nextSortHeaderKey, nextSortDirection }) => {
                  orderParams.sorting.onChange({
                    orderBy: nextSortHeaderKey,
                    direction: nextSortDirection,
                  })
                }}
                rows={orderTableRows}
                sortHeaderKey={orderParams.sorting.multiParams.orderBy.value}
                sortDirection={orderParams.sorting.multiParams.direction.value}
                pagingType={orderSourcePBI ? 'infinite' : 'paginated'}
                pagingProps={
                  orderSourcePBI
                    ? {
                        hasMore: Boolean(meta?.has_next_page) && !isFetchingOrders,
                        isLoading: isFetchingOrders,
                        onLoadMore: () => updatePagingState(meta?.end_cursor),
                      }
                    : {
                        pageNumber,
                        resultsPerPage,
                        onPagingChange: ({ pageNumber, resultsPerPage }) => {
                          updatePagingState((pageNumber - 1) * resultsPerPage)
                        },
                        resultsTotal: orderCount,
                      }
                }
                tableAction={
                  orderSourcePBI && hasInitialOffset && !isFetchingOrders ? (
                    <div className={classes.tableAction}>
                      <Button
                        onClick={() => resetDataState(null)}
                        kind="link"
                        toolTipText={`Currently this table starts mid-way through the list at
                          order: ${orderTableRows[0]?.orderId || '(No orders found)'}`}
                      >
                        Reload from start of the list
                      </Button>
                    </div>
                  ) : null
                }
              />
              {orderSourcePBI && isFetchingOrders && orderTableRows.length > 0 ? (
                <LoadingIndicator className={classes.dataLoader} />
              ) : null}
            </div>
          )
        }}
      />
    )
  }
}

export default injectSheet(styles)(Orders)
