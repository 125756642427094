import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import injectSheet from 'react-jss'

import { Icon } from 'components'

import styles from './ShowCollapse.styles'

class ShowCollapse extends Component {
  static propTypes = {
    className: PropTypes.string,
    content: PropTypes.node.isRequired,
    contentTopPadding: PropTypes.oneOf(['sm', 'md']),
    id: PropTypes.string,
    keepOpen: PropTypes.bool,
    openOnHover: PropTypes.bool,
    showIconOnLeft: PropTypes.bool,
    trigger: PropTypes.node.isRequired,
  }

  static defaultProps = {
    keepOpen: false,
    openOnHover: false,
    showIconOnLeft: false,
  }

  state = {
    isOpen: this.props.keepOpen,
  }

  componentWillReceiveProps(newProps) {
    if (newProps.keepOpen !== this.props.keepOpen) {
      this.setState({ isOpen: newProps.keepOpen })
    }
  }

  handleTriggerClick = event => {
    const isOpen = !this.state.isOpen
    this.setState({ isOpen })
  }

  handleKeyPress = event => {
    const isKeyPressTarget = event.target === event.currentTarget
    const isValidKeyPress = [13, 32].indexOf(event.which) !== -1

    if (isKeyPressTarget && isValidKeyPress) {
      this.handleTriggerClick(event)
    }
  }

  handleMouseEnter = () => {
    if (!this.props.keepOpen) {
      this.setState({ isOpen: true })
    }
  }

  handleMouseLeave = () => {
    if (!this.props.keepOpen) {
      this.setState({ isOpen: false })
    }
  }

  render() {
    const {
      classes,
      className,
      content,
      contentTopPadding,
      id,
      keepOpen,
      openOnHover,
      showIconOnLeft,
      trigger,
    } = this.props

    const containerProps = openOnHover
      ? {
          onMouseEnter: this.handleMouseEnter,
          onMouseLeave: this.handleMouseLeave,
        }
      : {}

    const triggerProps = openOnHover
      ? { onKeyPress: this.handleKeyPress }
      : { onClick: this.handleTriggerClick }

    const icon = (
      <Icon
        className={classes.showCollapseIcon}
        description="Expand/Collapse"
        name="chevronRight"
      />
    )

    return (
      <div
        className={cx(className, classes.showCollapse, {
          [classes.showCollapseOpen]: this.state.isOpen,
        })}
        role="presentation"
        {...containerProps}
      >
        <button
          className={cx(classes.showCollapseTrigger, {
            [classes.showCollapseAjarTrigger]: openOnHover ? keepOpen : this.state.isOpen,
          })}
          id={id}
          role="tab"
          type="button"
          {...triggerProps}
        >
          {showIconOnLeft && icon}
          {trigger}
          {!showIconOnLeft && icon}
        </button>
        <div
          className={cx(classes.showCollapseContent, {
            [classes.showCollapseContentWithTopPaddingSm]: contentTopPadding === 'sm',
            [classes.showCollapseContentWithTopPaddingMd]: contentTopPadding === 'md',
          })}
        >
          {content}
        </div>
      </div>
    )
  }
}
export default injectSheet(styles)(ShowCollapse)
