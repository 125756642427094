import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { LoadingIndicator, Notification, PageHeader } from 'components'
import { createToast } from 'modules/toasts'
import Styling from 'styling/components'
import { ItemFetcher, snakeToCapitalized, snakeToKebab } from 'utils'
import { fetchFactSchema, fetchFactValue, updateFactValue } from 'api'
import { getRouteTo } from 'routing'

import EditFactForm from './EditFactForm'

const EditFact = ({ history, factKey = '', createToast }) => {
  const returnRoute = getRouteTo('facts', { key: snakeToKebab(factKey) })

  const onSubmit = ({ formData }) =>
    updateFactValue(factKey, { key: factKey, value: formData })
      .then(() => {
        createToast({
          kind: 'success',
          message: `Successfully updated ${snakeToCapitalized(factKey)}.`,
        })
      })
      .catch(error => {
        createToast({
          kind: 'error',
          message: `Could not update ${snakeToCapitalized(factKey)}: ${error.message}`,
        })
      })

  return (
    <Fragment>
      <PageHeader headerTitle={`Edit ${snakeToCapitalized(factKey)}`} />
      <ItemFetcher
        queryKey="fact"
        queryParams={{ factKey }}
        fetchItem={() => Promise.all([fetchFactValue(factKey), fetchFactSchema(factKey)])}
        render={({ item: fact, isLoadingItem: isFetchingFact, error }) => {
          if (isFetchingFact) {
            return <LoadingIndicator withTopMargin />
          }
          if (error) {
            return (
              <Styling.Center maxWidth={500} withTopMargin>
                <Notification kind="error" message={error.message} />
              </Styling.Center>
            )
          }
          if (fact) {
            const [{ value: factValue }, factSchema] = fact
            return (
              <EditFactForm
                onSubmit={onSubmit}
                returnRoute={returnRoute}
                factKey={factKey}
                factSchema={factSchema}
                factValue={factValue}
              />
            )
          }
          return (
            <Styling.Center maxWidth={500} withTopMargin>
              <Notification
                kind="error"
                message={`Could not fetch ${snakeToCapitalized(factKey)}: ${error.message}`}
              />
            </Styling.Center>
          )
        }}
      />
    </Fragment>
  )
}

EditFact.propTypes = {
  history: PropTypes.func.isRequired,
  factKey: PropTypes.string,
  createToast: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => bindActionCreators({ createToast }, dispatch)

export default connect(null, mapDispatchToProps)(EditFact)
