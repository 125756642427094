import React, { Children, Component } from 'react'
import injectSheet from 'react-jss'

import Switch from 'react-switch'

import styles from './CollectionCreateOrEdit.styles'

class CollectionFormRuleAccordion extends Component {
  render() {
    const { children, classes, heading, helpText, isFirst, isOpen, onChange } = this.props

    return (
      <div aria-expanded={isOpen}>
        <div className={classes.filterSectionHeading}>
          <label className={classes.filterSectionHeadingLeft}>
            {!isFirst && <span className={classes.filterSectionAnd}>and</span>}
            <Switch checked={isOpen} width={28} height={14} onChange={onChange} />
            <span className={classes.filterSectionHeadingText}>{heading}</span>
          </label>
          {helpText && isOpen && (
            <div className={classes.filterSectionHeadingRight}>{helpText}</div>
          )}
        </div>
        {isOpen && Children.count(children) > 0 && (
          <div className={classes.filterSectionContent}>{children}</div>
        )}
      </div>
    )
  }
}

export default injectSheet(styles)(CollectionFormRuleAccordion)
