export default theme => ({
  table: {
    borderSpacing: '0',
    borderCollapse: 'separate',
    fontFamily: theme.fontFamilySansSerif,
    width: '100%',

    '& > thead > tr > th': {
      lineHeight: 1.25,
      textAlign: 'left',
      verticalAlign: 'top',
      whiteSpace: 'nowrap',
    },
    '&$defaultStyle': {
      '& > thead > tr > th': {
        padding: `${theme.spacing.md} ${theme.spacing.sm} ${theme.spacing.xs}`,
      },
    },
    '&$lightStyle, &$lightTallRowStyle': {
      '& > thead > tr > th': {
        padding: `${theme.spacing.xxs} ${theme.spacing.sm} ${theme.spacing.xs}`,
      },
    },

    '& > tbody > tr > td': {
      lineHeight: 1.25,
      textAlign: 'left',
      verticalAlign: 'middle',
    },

    '& > tbody': {
      fontSize: theme.fontSize.p,
      color: theme.text01,

      '& > tr': {
        '& > td': {
          padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
          borderBottom: `1px solid ${theme.borderGreyLight}`,
        },
        '&:hover': {
          backgroundColor: theme.bgGreyExtraLight,
        },
      },
    },

    '&$defaultStyle, &$lightStyle': {
      '& > tbody > tr': {
        height: 41, // 40px height + 1px border
      },

      '& > thead > tr > th, & > tbody > tr > td': {
        '&:first-of-type': {
          paddingLeft: theme.spacing.xlg,
        },
        '&:last-of-type': {
          paddingRight: theme.spacing.xlg,
        },
      },
    },
    '&$lightTallRowStyle': {
      '& > tbody > tr': {
        height: 57, // 56px height + 1px border
      },

      '&> thead > tr > th, & > tbody > tr > td': {
        '&:first-of-type': {
          paddingLeft: theme.spacing.xs,
        },
        '&:last-of-type': {
          paddingRight: theme.spacing.xs,
        },
      },
    },

    '$isLoading& > tbody > tr': {
      // Add padding to the loading indicator cell
      '& > td': {
        padding: `${theme.spacing.lg} !important`,
      },

      // Don't change cell background color on hover if showing loading table
      '&:hover': {
        background: 'none',
      },
    },
  },
  defaultStyle: {
    borderLeft: `1px solid ${theme.borderGreyLight}`,
    borderRight: `1px solid ${theme.borderGreyLight}`,
    borderTop: `1px solid ${theme.borderGreyLight}`,

    '& > tfoot > tr > td': {
      borderBottom: `1px solid ${theme.borderGreyLight}`,
    },
  },
  lightStyle: {},
  lightTallRowStyle: {},
  isLoading: {},
})
