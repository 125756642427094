import React from 'react'

const NestedObjectItem = ({ data }) => {
  return (
    <ul>
      {Object.entries(data).map(([key, val], i) => (
        <li>
          <strong>{key}</strong>
          {': '}
          {val}
        </li>
      ))}
    </ul>
  )
}

export default NestedObjectItem
