import React from 'react'
import { createUseStyles } from 'react-jss'

import { DataTable } from 'components'

import { formatDatetime } from 'utils'

const styles = theme => ({
  tabs: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  sectionField: {
    display: 'flex',
    'align-items': 'start',
    'padding-top': '5px',
  },
  sectionFieldValue: {
    'font-family': 'monospace',
    'font-weight': 'bold',
    'padding-left': '5px',
  },
  jsonValue: {
    display: 'inline-block',
  },
})

const useStyles = createUseStyles(styles)

const OfferDetailsTable = ({ data }) => {
  const classes = useStyles()

  const renderIdentitySection = (keys, data) => {
    return (
      <ul>
        {keys.map(key => {
          let value = data[key]
          if (['created', 'modified'].includes(key)) value = formatDatetime(value)
          return (
            <li className={classes.sectionField}>
              <strong>{key}</strong>: <span className={classes.sectionFieldValue}>{value}</span>
            </li>
          )
        })}
      </ul>
    )
  }
  const renderLimitSection = (keys, data) => {
    return (
      <ul>
        {keys.map(key => {
          let value = data[key]
          if (key === 'days')
            value = (
              <pre className={classes.jsonValue}>{JSON.stringify(data[key], undefined, 3)}</pre>
            )
          else if (['startDate', 'endDate'].includes(key)) value = formatDatetime(value)
          else if (key === 'storeGroup') value = JSON.stringify(value, undefined, 3)
          return (
            <li className={classes.sectionField}>
              <strong>{key}</strong>: <pre className={classes.sectionFieldValue}>{value}</pre>
            </li>
          )
        })}
      </ul>
    )
  }
  const renderCdnSection = (keys, data) => {
    return (
      <ul>
        {keys.map(key => {
          let value = ''
          if (key === 'type') value = data[key]
          else
            value = (
              <pre className={classes.jsonValue}>{JSON.stringify(data[key], undefined, 3)}</pre>
            )
          return (
            <li className={classes.sectionField}>
              <strong>{key}</strong>: <span className={classes.sectionFieldValue}>{value}</span>
            </li>
          )
        })}
      </ul>
    )
  }
  const renderRwdSection = (keys, data) => {
    return (
      <ul>
        {keys.map(key => {
          let value = ''
          if (key === 'type') value = data[key]
          else
            value = (
              <pre className={classes.jsonValue}>{JSON.stringify(data[key], undefined, 3)}</pre>
            )
          return (
            <li className={classes.sectionField}>
              <strong>{key}</strong>: <span className={classes.sectionFieldValue}>{value}</span>
            </li>
          )
        })}
      </ul>
    )
  }
  const renderOtherKeysSection = (keys, data) => {
    return (
      <ul>
        {keys.map(key => {
          let value = data[key]
          if (['createdBy', 'ext_data'].includes(key))
            value = (
              <pre className={classes.jsonValue}>{JSON.stringify(data[key], undefined, 3)}</pre>
            )
          return (
            <li className={classes.sectionField}>
              <strong>{key}</strong>: <span className={classes.sectionFieldValue}>{value}</span>
            </li>
          )
        })}
      </ul>
    )
  }

  const formatOfferData = data => {
    const identityKeys = [
      'id',
      'ext_id',
      'name',
      'description',
      'source',
      'polytype',
      'promoCode',
      'created',
      'modified',
    ]
    const limitKeys = [
      'status',
      'startDate',
      'endDate',
      'storeGroup',
      'limitBasket',
      'limitCustomer',
      'limitTotal',
      'ignore_order_by_weight',
      'online_only',
      'customerSegment',
      'days',
      'hasLoyalty',
    ]
    const cdnKeys = ['condition']
    const rwdKeys = ['reward']
    const otherKeys = Object.keys(data).filter(key => {
      return (
        !identityKeys.includes(key) &&
        !limitKeys.includes(key) &&
        !cdnKeys.includes(key) &&
        !rwdKeys.includes(key)
      )
    })

    return [
      {
        id: 'Identity',
        key: <strong>Identity</strong>,
        value: renderIdentitySection(identityKeys, data),
      },
      {
        id: 'Limits',
        key: <strong>Limits</strong>,
        value: renderLimitSection(limitKeys, data),
      },
      {
        id: 'Conditions',
        key: <strong>Conditions</strong>,
        value: renderCdnSection(Object.keys(data.condition), data.condition),
      },
      {
        id: 'Rewards',
        key: <strong>Rewards</strong>,
        value: renderRwdSection(Object.keys(data.reward), data.reward),
      },
      {
        id: 'Other',
        key: <strong>Other</strong>,
        value: renderOtherKeysSection(otherKeys, data),
      },
    ]
  }
  return (
    <DataTable
      pagingProps={{}}
      headers={[
        { key: 'key', header: '', print: true },
        { key: 'value', header: '', print: true },
      ]}
      rows={formatOfferData(data)}
      kind="light-tall-row"
    />
  )
}

export default OfferDetailsTable
