export default theme => ({
  '@global': {
    body: {
      minWidth: 'unset',
    },
  },
  pageContainer: {
    background: theme.loginPageBackground,
    height: '100%',
    position: 'absolute',
    textAlign: 'center',
    width: '100%',
  },
  formContainer: {
    background: theme.bgGreyExtraLight,
    borderRadius: 5,
    margin: '160px auto 0',
    padding: '48px 120px',
    textAlign: 'center',
    width: 560,
  },
  logo: {
    height: 50,
    marginBottom: 24,
    width: 145,
  },
  statusBadge: {
    position: 'absolute',
    margin: '-9px 0 0 -7px',
  },
  header: {
    fontSize: theme.fontSize.h3,
    color: theme.text01,
    fontFamily: theme.fontFamilySansSerif,
    fontWeight: 600,
    marginBottom: 8,
  },
  resetPasswordError: {
    marginTop: 16,
  },
  loginInputs: {
    paddingTop: 16,
    paddingBottom: 24,
  },
  linkButton: {
    marginTop: 16,
  },
})
