import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import injectSheet from 'react-jss'

import { Icon } from 'components'

import styles from './TableHeader.styles'

import { getNextSortDirection, sortStates } from '../state/sorting'

const getSortDirectionLabel = sortDirection =>
  sortDirection === sortStates.DESC
    ? 'descending'
    : sortDirection === sortStates.ASC
    ? 'ascending'
    : 'initial'

const getSortDescription = ({ isSortable, isActiveSortHeader, sortDirection }) => {
  if (isSortable) {
    const nextSortDirection = isActiveSortHeader
      ? getNextSortDirection('current', 'current', sortDirection) // send in fake header keys that match
      : getNextSortDirection('current', 'prev', sortDirection) // send in fake header keys that do not match

    return nextSortDirection !== sortStates.NONE
      ? `Sort rows by this header in ${getSortDirectionLabel(nextSortDirection)} order`
      : `Sort rows in initial default order`
  }
  return sortDirection !== sortStates.NONE
    ? `Rows are sorted by this header in ${getSortDirectionLabel(sortDirection)} order`
    : `Rows are sorted in initial default order`
}

const TableHeader = ({
  children,
  classes,
  className,
  disableSorting,
  minWidth,
  isActiveSortHeader,
  isBulkActionHeader,
  isDragSpacerHeader,
  isExpandSpacerHeader,
  isLinkSpacerHeader,
  isSelectAllHeader,
  onClick,
  scope,
  sortDirection,
}) => {
  const shouldShowSortIcon = !(
    isBulkActionHeader ||
    isDragSpacerHeader ||
    isExpandSpacerHeader ||
    isLinkSpacerHeader ||
    isSelectAllHeader
  )
  // Any of these ^ headers should never be considered sortable
  const isSortableHeader = !disableSorting && shouldShowSortIcon

  const cellClasses = cx(className, {
    [classes.bulkActionHeader]: isBulkActionHeader,
    [classes.dragSpacerHeader]: isDragSpacerHeader,
    [classes.expandSpacerHeader]: isExpandSpacerHeader,
    [classes.linkSpacerHeader]: isLinkSpacerHeader,
    [classes.selectAllHeader]: isSelectAllHeader,
  })

  const containerClasses = cx(className, {
    [classes.tableHeaderSort]: shouldShowSortIcon,
    [classes.tableHeaderShowSortIconOnHover]: isSortableHeader,
    [classes.tableHeaderSortActive]: isActiveSortHeader && sortDirection !== sortStates.NONE,
  })

  let sortIcon = 'sort'
  if (sortDirection === sortStates.ASC) {
    sortIcon = 'sortAsc'
  } else if (sortDirection === sortStates.DESC) {
    sortIcon = 'sortDesc'
  }

  const sortIconEl = (
    <Icon
      className={classes.tableHeaderIcon}
      name={sortIcon}
      description={getSortDescription({
        isSortable: isSortableHeader,
        sortDirection,
        isActiveSortHeader,
      })}
    />
  )

  return isSortableHeader ? (
    <th className={cellClasses} scope={scope} style={{ minWidth }}>
      <button className={containerClasses} onClick={onClick} type="button">
        <span className={classes.tableHeaderLabel}>{children}</span>
        {shouldShowSortIcon && sortIconEl}
      </button>
    </th>
  ) : (
    <th className={cellClasses} scope={scope} style={{ minWidth }}>
      <div className={containerClasses}>
        <span className={classes.tableHeaderLabel}>{children}</span>
        {shouldShowSortIcon && sortIconEl}
      </div>
    </th>
  )
}

TableHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  /**
   * Specifies whether this header is the header by which a table is being sorted by
   */
  isActiveSortHeader: PropTypes.bool,
  /**
   * The "Select All" header requires certain styling - it should also ensure the omission
   * of the sort functionality/icon.
   */
  isSelectAllHeader: PropTypes.bool,
  disableSorting: PropTypes.bool,
  /**
   * Minimum width for table column
   */
  minWidth: PropTypes.number,
  /**
   * Function that is invoked when the header is clicked
   */
  onClick: PropTypes.func,
  /**
   * Specifies the scope of this table header. See:
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/th#attr-scope
   */
  scope: PropTypes.string,
  /**
   * Specifies which direction we are currently sorting by, should be one of DESC,
   * NONE, or ASC.
   */
  sortDirection: PropTypes.oneOf(Object.values(sortStates)),
}

TableHeader.defaultProps = {
  isSelectAllHeader: false,
  scope: 'col',
}

TableHeader.displayName = 'TableHeader'

export default injectSheet(styles)(TableHeader)
