export default theme => ({
  header: {
    alignItems: 'center',
    display: 'flex',
    fontFamily: theme.fontFamilySansSerif,
    justifyContent: 'space-between',
    padding: '24px 0',
    position: 'relative',
  },
  headerWithBackButton: {
    paddingTop: 40,
  },
  headerWithDivider: {
    paddingBottom: 18,
    marginBottom: 24,

    '&::after': {
      borderBottom: `2px solid ${theme.borderGreyLight}`,
      content: '""',
      bottom: 0,
      left: -52,
      right: -52,
      position: 'absolute',
    },
  },
  headerNoMargin: {
    marginBottom: 0,
  },
  backButton: {
    fontSize: theme.fontSize.p,
    color: theme.brand01,
    position: 'absolute',
    textDecoration: 'none',
    top: 12,

    '&:hover, &:focus': {
      textDecoration: 'underline',
    },

    '& > *': {
      verticalAlign: 'middle',
    },
  },
  backButtonIcon: {
    height: 16,
    fill: theme.brand01,
    marginRight: theme.spacing.xs,
    width: 16,
  },
  headerLeft: {
    flexShrink: 0,
  },
  headerRight: {},
  title: {
    alignItems: 'flex-start',
    color: theme.text01,
    display: 'flex',
    fontSize: theme.fontSize.h1,
    lineHeight: 1.4,
    marginRight: theme.spacing.sm,
  },
  titleTextWithBadge: {
    marginRight: theme.spacing.xs,
  },
})
