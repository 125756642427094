import { Formik } from 'formik'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as yup from 'yup'

import { createUser, updateUser, fetchUser } from 'api'
import { LoadingIndicator, PageHeader } from 'components'
import { createToast } from 'modules/toasts'
import { getBackButton, getRouteTo } from 'routing'
import { get, ItemFetcher } from 'utils'

import UserForm from './UserForm'

const dispatchToProps = dispatch =>
  bindActionCreators(
    {
      createToast,
    },
    dispatch
  )

const UserCreateOrEdit = connect(
  null,
  dispatchToProps
)(
  ({
    backButton,
    createToast,
    headerTitle,
    initialValues = { firstName: '', lastName: '', email: '', roles: [] },
    history,
    onFormSubmit,
    successMessage,
  }) => (
    <div>
      <PageHeader backButton={backButton} hasDivider headerTitle={headerTitle} />
      <Formik
        initialValues={initialValues}
        validationSchema={() =>
          yup.object().shape({
            firstName: yup
              .string()
              .trim()
              .label('First Name')
              .required(),
            lastName: yup
              .string()
              .trim()
              .label('Last Name')
              .required(),
            email: yup
              .string()
              .email()
              .label('Email')
              .required(),
            roles: yup
              .array()
              .label('Roles')
              .required(),
          })
        }
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values, form) => {
          onFormSubmit(values)
            .then(user => {
              form.setSubmitting(false)
              createToast({ kind: 'success', message: successMessage })

              // Send the user back to the users list page
              history.push(getRouteTo('users'))
            })
            .catch(error => {
              form.setSubmitting(false)
              form.setErrors({ global: error.message })
            })
        }}
        render={renderProps => <UserForm {...renderProps} cancelDestination={backButton.to} />}
      />
    </div>
  )
)

export const UserCreate = ({ location, ...restProps }) => {
  const propsToPass = {
    backButton: get(location, 'state.backButton') || getBackButton('users'),
    headerTitle: 'Create New User',
    onFormSubmit: formValues => createUser(formValues),
    successMessage: 'Successfully created user.',
    ...restProps,
  }

  return <UserCreateOrEdit {...propsToPass} />
}

export const UserEdit = ({
  location,
  match: {
    params: { userId },
  },
  ...restProps
}) => (
  <ItemFetcher
    queryKey="user"
    queryParams={{ userId }}
    fetchItem={config => fetchUser(userId, config)}
    render={({ item: user, isLoadingItem: isFetchingUser }) => {
      if (isFetchingUser || !user) {
        return <LoadingIndicator withTopMargin />
      }
      const propsToPass = {
        backButton: get(location, 'state.backButton') || getBackButton('users'),
        headerTitle: 'Edit User',
        initialValues: {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          roles: user.roles || [],
        },
        onFormSubmit: formValues => updateUser(userId, formValues),
        successMessage: 'Successfully updated user.',
        ...restProps,
      }

      return <UserCreateOrEdit {...propsToPass} />
    }}
  />
)
