export default theme => ({
  collectionItemRowProductCell: {
    alignItems: 'center',
    display: 'flex',
    lineHeight: 1.5,
  },
  collectionItemRowProductCellProductText: {
    '& > *': {
      marginBottom: theme.spacing.xxs,

      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  collectionItemRowProductCellImg: {
    flexShrink: 0,
    height: 40,
    marginRight: theme.spacing.xs,
    width: 40,
  },
  filterSeparator: {
    fontSize: 9,
    backgroundColor: 'white',
    borderRadius: 10,
    padding: '3px 5px',
    marginLeft: 7,
    marginRight: 7,
    fontWeight: 900,
  },
  rulesBox: {
    '& > *:last-child': { border: '1px solid #DBDBDB' },
  },
  ruleItem: {
    lineHeight: 2,
  },
  ruleWordWrap: {
    display: 'inline-block',
  },
})
