import React from 'react'
import injectSheet from 'react-jss'

import { Checkbox, FormFieldItem } from 'components'

import styles from '../PromotedPlacementsForm.styles'

const UserShoppingContext = ({ classes, componentId, checkboxOptions }) => (
  <FormFieldItem
    className={classes.formRow}
    id={componentId}
    labelText="Shopping Context Visibility"
    name="userShoppingContext"
    render={() => (
      <div className={classes.formInput}>
        {checkboxOptions.map((userShoppingContext, index) => (
          <FormFieldItem
            className={classes.formInlineCheckbox}
            id={userShoppingContext.id}
            key={index}
            name={`userShoppingContext.${userShoppingContext.name}`}
            render={renderProps => (
              <Checkbox
                {...renderProps}
                checked={renderProps.value}
                labelText={userShoppingContext.labelText}
              />
            )}
          />
        ))}
      </div>
    )}
  />
)

export default injectSheet(styles)(UserShoppingContext)
