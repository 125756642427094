import React from 'react'
import injectSheet from 'react-jss'

import { Button, FormFieldItem, Notification, TextInput, TextArea, Checkbox } from 'components'
import Styling from 'styling/components'

const styles = theme => ({
  formContainer: {
    width: 610,
  },
})

const BASE_ID = 'create-keyword-redirect'

const KeywordRedirectForm = ({
  cancelDestination,
  classes,
  errors,
  handleSubmit,
  isSubmitting,
  setFieldValue,
}) => (
  <form className={classes.formContainer} onSubmit={handleSubmit}>
    <FormFieldItem
      id={`${BASE_ID}-name`}
      labelText="Name"
      name="name"
      render={renderProps => (
        <TextInput
          {...renderProps}
          autoFocus
          placeholder="Enter name"
          type="text"
          inputDescription="This will appear in the search dropdown when customers enter a keyword."
        />
      )}
    />

    <FormFieldItem
      id={`${BASE_ID}-keywords`}
      labelText="Keywords"
      name="keywords"
      render={({ value, ...renderProps }) => (
        <TextArea
          {...renderProps}
          placeholder="Enter the search keywords"
          isResizable
          helpText='Enter comma separated keywords (up to 10 per redirect). (Keywords can include spaces - e.g. "apple pie, apple" would count as 2 keywords: "apple pie" and "apple".)'
          onChange={({ target: { value } }) => {
            setFieldValue('keywords', value ? value.split(',') : [])
          }}
          value={value.join(',')}
        />
      )}
    />

    <FormFieldItem
      id={`${BASE_ID}-redirectCategory`}
      labelText="Category Name"
      name="redirectCategory"
      render={renderProps => (
        <TextInput
          {...renderProps}
          type="text"
          placeholder="Enter category name"
          inputDescription="This will appear in the search dropdown when customers enter a keyword."
          maxLength={40}
          inputMaxLengthCount
        />
      )}
    />

    <FormFieldItem
      id={`${BASE_ID}-url`}
      labelText="Redirect Destination"
      name="url"
      render={renderProps => <TextInput {...renderProps} placeholder="http://" type="text" />}
    />

    <FormFieldItem
      id={`${BASE_ID}-isExternalUrl`}
      name="isExternalUrl"
      render={renderProps => (
        <Checkbox
          {...renderProps}
          checked={renderProps.value}
          labelText="Show as an external page"
        />
      )}
    />

    {!isSubmitting && errors.global && <Notification kind="error" message={errors.global} />}
    <Styling.LineOfButtons>
      <Button disabled={isSubmitting} id={`${BASE_ID}-save`} type="submit">
        Save
      </Button>
      <Button kind="link" href={cancelDestination}>
        Cancel
      </Button>
    </Styling.LineOfButtons>
  </form>
)

export default injectSheet(styles)(KeywordRedirectForm)
