export default theme => ({
  text: {
    fontFamily: theme.fontFamilySansSerif,
    fontSize: theme.fontSize.p,
    color: theme.text01,
    lineHeight: theme.baseLineHeight,
    whiteSpace: 'pre-wrap',
  },
  border: {
    border: theme.inputBorder,
    borderRadius: theme.inputBorderRadius,
    padding: theme.spacing.md,
  },
  noBottomMargin: {
    marginBottom: 0,
  },
  bottomMargin: {
    marginBottom: theme.spacing.xs,
  },
  sandwichMargin: {
    marginTop: theme.spacing.xs,
    marginBottom: theme.spacing.xs,
  },
  leftMargin: {
    marginLeft: theme.spacing.md,
  },
  form: {
    width: 764,
  },
  flex: {
    flex: 1,
  },
})
