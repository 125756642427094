/**
 * Helper function to reorder an item in a list with a given start/index
 */
export const reorder = (list = [], startIndex = 0, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}
