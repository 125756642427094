import React from 'react'
import injectSheet from 'react-jss'

import { getIn } from 'formik'

import { Checkbox, FormFieldItem, TextInput } from 'components'

import styles from '../PromotedPlacementsForm.styles'

const PlacementViewAll = ({ classes, componentId, values: { config } }) => (
  <>
    <FormFieldItem
      className={classes.formRow}
      id={`${componentId}-hide-show-view-all`}
      name={'config.showViewAllTitle'}
      render={({ name, value, ...props }) => (
        <>
          <Checkbox
            {...props}
            name={name}
            className={classes.formInput}
            labelText={'Show "view all" link'}
            checked={value}
          />
        </>
      )}
    />
    <FormFieldItem
      className={classes.formRow}
      id={`${componentId}-view-all`}
      labelText={'Link title'}
      name={'config.viewAllTitle'}
      render={props => (
        <TextInput {...props} disabled={!config.showViewAllTitle} className={classes.formInput} />
      )}
    />
  </>
)

export default injectSheet(styles)(PlacementViewAll)
