import React from 'react'
import injectSheet from 'react-jss'

import { TILE_CAROUSELS } from 'constants/placementTypes'
import { ButtonIconAction, DataTableWithState, FormFieldItem } from 'components'
import { reorder } from 'components/DataTable/utils/reorder'

import styles from '../PromotedPlacementsForm.styles'

const PlacementImageItemDisplay = ({ classes, componentId, setFieldValue, values: { config } }) => (
  <div className={classes.imageItemDisplayContainer}>
    <FormFieldItem
      id={`${componentId}-display`}
      labelText="Carousel Order"
      name="config.items"
      render={({ name }) => (
        <>
          <DataTableWithState
            id={`${componentId}-display-table`}
            kind="default"
            emptyMsg="Add Carousel Tiles"
            headers={[
              {
                key: 'image',
                header: 'Image',
              },
              {
                key: 'imageMobile',
                header: 'Image - Mobile',
              },
              {
                key: 'label',
                header: 'Label',
              },
              {
                key: 'url',
                header: 'Destination URL',
              },
            ]}
            rows={config.items.map(({ label, url, image, imageMobile, altText }, index) => ({
              id: index.toString(),
              image: (
                <img className={classes.tableImageThumbnail} src={image?.imageUrl} alt={altText} />
              ),
              imageMobile: (
                <img
                  className={classes.tableImageThumbnailMini}
                  src={imageMobile?.imageUrl}
                  alt={altText}
                />
              ),
              label,
              url,
              rowAction: (
                <ButtonIconAction
                  description="Remove"
                  icon="delete"
                  onClick={() => {
                    const items = [
                      ...config.items.slice(0, index),
                      ...config.items.slice(index + 1),
                    ]
                    setFieldValue(name, items)
                  }}
                />
              ),
            }))}
            isDraggable
            onDragEnd={({ data }) => {
              const { source, destination } = data
              if (!source || !destination) return

              const items = reorder(config.items, source.index, destination.index)
              setFieldValue(name, items)
            }}
          />
        </>
      )}
    />
  </div>
)

export default injectSheet(styles)(PlacementImageItemDisplay)
