import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import injectSheet from 'react-jss'

import { Icon, Tooltip } from 'components'

import styles from './Button.styles'

class ButtonToggle extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    icon: PropTypes.string,
    id: PropTypes.string,
    isToggled: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
    /**
     * If `children` is provided, we assume the consumer will include the toggled/untoggled
     * descriptions (in `children`), and so the descriptions are not used in the component's tooltip.
     */
    toggledDescription: PropTypes.string,
    untoggledDescription: PropTypes.string,
  }

  handleClick = () => {
    const { isToggled, onClick } = this.props

    if (onClick) {
      const newIsToggled = !isToggled
      onClick(newIsToggled)
    }
  }

  render() {
    const {
      children,
      classes,
      className,
      icon,
      id,
      isToggled,
      toggledDescription,
      untoggledDescription,
    } = this.props

    const buttonClasses = cx(className, classes.button, classes.buttonToggle, {
      [classes.buttonToggleToggled]: isToggled,
    })

    const hasChildren = !!children

    const toggleDescription = hasChildren
      ? undefined
      : isToggled
      ? toggledDescription
      : untoggledDescription

    const buttonIcon = icon ? (
      <Icon
        name={icon}
        className={cx(classes.buttonIcon, {
          [classes.buttonIconBefore]: hasChildren,
        })}
      />
    ) : null

    const buttonProps = {
      'aria-label': toggleDescription,
      'aria-pressed': isToggled,
      id,
      onClick: this.handleClick,
      type: 'button',
    }

    return hasChildren ? (
      <button className={buttonClasses} {...buttonProps}>
        {buttonIcon}
        <span className={cx({ [classes.buttonIconSibling]: !!buttonIcon })}>{children}</span>
      </button>
    ) : (
      <Tooltip
        text={toggleDescription}
        tooltipRender={({ position, tooltip, triggerClassName, wrapperClassName }) => (
          <span className={wrapperClassName}>
            <button {...buttonProps} className={cx(buttonClasses, triggerClassName)}>
              {toggleDescription && tooltip}
              {buttonIcon}
            </button>
          </span>
        )}
      />
    )
  }
}

export { ButtonToggle as ButtonToggleUnStyled }
export default injectSheet(styles)(ButtonToggle)
