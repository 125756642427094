import React from 'react'
import { createUseStyles } from 'react-jss'

import OfferTable from './OfferTable'

export const offerAppliedHeaders = [
  { key: 'offerId', header: 'Id', print: true },
  { key: 'name', header: 'Name', print: true },
  { key: 'source', header: 'Source', print: true },
  { key: 'polytype', header: 'Type', print: true },
  { key: 'clipped', header: 'Clipped', print: true },
  { key: 'completionCount', header: 'Times Applied', print: true },
  { key: 'rewardAmount', header: 'Reward Amount', print: true },
  { key: 'offerTypes', header: 'Offer Types', print: true },
  { key: 'details', header: '', print: true },
]

const offerNotAppliedHeaders = [
  { key: 'offerId', header: 'Id', print: true },
  { key: 'name', header: 'Name', print: true },
  { key: 'source', header: 'Source', print: true },
  { key: 'polytype', header: 'Type', print: true },
  { key: 'clipped', header: 'Clipped', print: true },
  { key: 'offerTypes', header: 'Offer Types', print: true },
  { key: 'details', header: '', print: true },
]

const styles = theme => ({
  tabs: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
})

const useStyles = createUseStyles(styles)

const OffersProgressTable = ({ data, type, storeId }) => {
  const classes = useStyles()

  let headers
  let offerData
  let tableId
  if (type === 'applied') {
    headers = offerAppliedHeaders
    offerData = data.filter(item => item.rwd_applied === true)
    tableId = 'offers-applied-table'
  }
  if (type === 'not_applied') {
    headers = offerNotAppliedHeaders
    offerData = data.filter(item => item.rwd_applied !== true)
    tableId = 'offers-not-applied-table'
  }

  return (
    <div className={classes.tabs}>
      <OfferTable headers={headers} tableId={tableId} offers={offerData} storeId={storeId} />
    </div>
  )
}

export default OffersProgressTable
