// The following was forked from https://github.com/react-component/tree-select

import PropTypes from 'prop-types'
import React from 'react'
import { TreeNode } from 'rc-tree'

// The class names are hardcoded in rc-tree, so to keep the names consistent they
// get pulled directly from the primary styles file
import {
  TREE_CLASS_PREFIX,
  TREE_SWITCHER_CLASS,
  TREE_SWITCHER_DISABLED_CLASS,
  TREE_CHECKBOX_CLASS,
  TREE_CONTENT_WRAPPER_CLASS,
  TREE_CONTENT_TITLE_CLASS,
  TREE_SWITCHER_CLASS_NOOP,
} from './Tree.styles.js'

/**
 * SelectNode wraps the TreeNode component for clarity reasons
 * Also allows for the display of the total child count next to each parent node
 */

const SelectNode = ({ entity: { childCount, disabled }, title, ...restProps }) => {
  if (disabled) {
    return (
      <li
        className={`${TREE_CLASS_PREFIX}-treenode-switcher-close ${TREE_SWITCHER_DISABLED_CLASS}`}
      >
        <span className={`${TREE_SWITCHER_CLASS} ${TREE_SWITCHER_CLASS_NOOP}`} />
        <span className={TREE_CHECKBOX_CLASS} />
        <span className={`${TREE_CONTENT_WRAPPER_CLASS} ${TREE_CONTENT_WRAPPER_CLASS}-close`}>
          <span className={TREE_CONTENT_TITLE_CLASS}>
            {title}
            {childCount ? ` (${childCount})` : ''}
          </span>
        </span>
      </li>
    )
  }

  return <TreeNode title={`${title}${childCount ? ` (${childCount})` : ''}`} {...restProps} />
}

SelectNode.propTypes = {
  ...TreeNode.propTypes,
  value: PropTypes.any,
}

// Let Tree trade as TreeNode to reuse this for performance savings (?)
SelectNode.isTreeNode = 1

export default SelectNode
