import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import injectSheet from 'react-jss'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Notification } from 'components'
import { dismissToast } from 'modules/toasts'

import styles from './Toast.styles'

class Toast extends Component {
  static propTypes = {
    toasts: PropTypes.array.isRequired,
  }

  render() {
    const { classes, dismissToast, toasts } = this.props

    return (
      <div className={classes.toastContainer}>
        {toasts.map(({ id, isDismissed, ...restProps }) => (
          <Notification
            className={cx(classes.toastNotification, {
              [classes.notificationFadeOut]: isDismissed,
            })}
            key={id}
            {...restProps}
            action={{
              onClick: event => {
                if (restProps.action && restProps.action.onClick) {
                  restProps.action.onClick(event)
                }

                dismissToast(id)
              },
              text: (restProps.action && restProps.action.text) || 'Dismiss',
            }}
          />
        ))}
      </div>
    )
  }
}

// Connect toast component
const mapStateToProps = state => ({
  toasts: state.toasts,
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      dismissToast,
    },
    dispatch
  )

export default injectSheet(styles)(connect(mapStateToProps, mapDispatchToProps)(Toast))
