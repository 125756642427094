import React, { useState, useMemo } from 'react'

import { DataTable, Button, Modal } from 'components'

import { toCurrency } from 'utils'

import NestedObjectItem from './NestedObjectItem'
import CartOfferModalContent from './CartOfferModalContent'

const OfferTable = ({ offers, headers, tableId, storeId }) => {
  const [showOfferModal, setShowOfferModal] = useState(false)
  const [modalOfferId, setModalOfferId] = useState()

  const formatOfferRows = useMemo(
    () => offers => {
      return offers.map(item => ({
        id: `${item.offer.unata_id}-${item.offer.ext_id}`,
        offerId: (
          <NestedObjectItem
            data={{
              'Unata Id': item.offer.id,
              'Ext Id': item.offer.ext_id,
            }}
          />
        ),
        name: item.offer.name,
        source: item.offer.source,
        polytype: item.offer.polytype,
        clipped: item.clipped === null ? '' : String(item.offer.clipped),
        completionCount: item.completion_count,
        rewardAmount: `${item.discount_amount ? toCurrency(item.discount_amount) : '$0.00'}`,
        offerTypes: (
          <NestedObjectItem
            data={{
              'Condition Type': item.offer.cdn_type,
              'Reward Type': item.offer.rwd_type,
            }}
          />
        ),
        details: (
          <Button
            kind="link"
            onClick={() => {
              setModalOfferId(item.offer.id)
              setShowOfferModal(true)
            }}
          >
            Show offer details
          </Button>
        ),
      }))
    },
    []
  )

  const rows = formatOfferRows(offers)
  return (
    <div>
      <DataTable pagingProps={{}} headers={headers} id={tableId} rows={rows} />
      <Modal
        showClose
        modalHeading={`Offer Details for Offer ID: ${modalOfferId}`}
        isOpen={showOfferModal}
        handleCloseModal={() => {
          setShowOfferModal(false)
          setModalOfferId(undefined)
        }}
        render={() =>
          modalOfferId && <CartOfferModalContent storeId={storeId} offerId={modalOfferId} />
        }
      />
    </div>
  )
}

export default OfferTable
