import React from 'react'

import { fetchSegment } from 'api'
import { LoadingIndicator, Notification, ReadOnlyValueDetails, ReadOnlyValue } from 'components'
import Styling from 'styling/components'
import {
  camelCaseToCapitalized,
  capitalize,
  formatDateRange,
  formatRange,
  get,
  ItemFetcher,
  toCurrency,
} from 'utils'

const TAB = `    `

const getSegmentRuleDetails = (rules, { id: segmentId, created: segmentCreatedDate }) =>
  rules.map((rule, index) => {
    const ruleValues = rule[rule.type]
    let details
    let labelText = rule.type !== 'onlineProductActivity' ? camelCaseToCapitalized(rule.type) : ''
    switch (rule.type) {
      case 'userAge':
        details = formatRange({ from: ruleValues.ageFrom, to: ruleValues.ageTo })
        break
      case 'gender':
        details = capitalize({ phrase: ruleValues.gender })
        break
      case 'loyalty':
        details = `${ruleValues.joined ? 'Joined' : 'Did NOT join'}`
        break
      case 'onlineSpend':
      case 'totalOnlineSpend':
        details = `${formatRange({
          from: ruleValues.spendFrom,
          to: ruleValues.spendTo,
          formatString: toCurrency,
        })}`
        break
      case 'onlineStoreActivity':
        details = `${ruleValues.visited ? 'Visited' : 'Did NOT visit'} stores in "${get(
          ruleValues,
          'storeGroup.name'
        )}"`
        break
      case 'onlineProductActivity':
        // eslint-disable-next-line no-case-declarations
        const productGroupType = ruleValues.productGroup.type
        // eslint-disable-next-line no-case-declarations
        const productGroupValues = ruleValues.productGroup[productGroupType]

        details = `${ruleValues.have ? 'Have' : 'Have NOT'} ${ruleValues.activityTypes
          .map(activityType => activityType.type)
          .map(camelCaseToCapitalized)
          .join(', ')}`

        labelText = 'Online Product Activity '
        if (productGroupType === 'any') {
          labelText += '(Any Product)'
        } else if (productGroupType === 'products' || productGroupType === 'collections') {
          labelText += productGroupType === 'products' ? '(Specific Products)' : '(Collections)'
          details += `\n${productGroupValues
            .map(item => `(#${item.id}) ${item.name}`)
            .join(`,${TAB}`)}`
        } else if (productGroupType === 'categories' || productGroupType === 'brands') {
          labelText += productGroupType === 'categories' ? '(Categories)' : '(Brands)'
          details += `\n${productGroupValues.map(item => `${item.name}`).join(`,${TAB}`)}`
        }
        break
      default:
        break
    }

    const { dateFrom, dateTo } = ruleValues
    if (dateFrom || dateTo) {
      /**
       * Since segments are static, rules with dates must have a set start and end date (or no dates).
       * If a start but no end date was saved on the segment rule (was possible in Dashboard v1), then
       * replace the segment rule's creation date (which should have been the end date saved upon creation).
       */
      details += `\n${formatDateRange({ from: dateFrom, to: dateTo || segmentCreatedDate })}`
    }

    return {
      id: `segment-${segmentId}-rule-${index}`,
      labelText,
      value: details,
    }
  })

const SegmentRuleDetails = ({ segmentId }) => (
  <ItemFetcher
    queryKey="segment"
    queryParams={{ segmentId }}
    fetchItem={config => fetchSegment(segmentId, config)}
    render={({ item: segment, isLoadingItem: isFetchingSegment, error: errorFetchingSegment }) => {
      if (isFetchingSegment) {
        return <LoadingIndicator.Dots centerBlock withText />
      }
      if (errorFetchingSegment) {
        return (
          <Styling.Center maxWidth={500}>
            <Notification kind="error" message={errorFetchingSegment.message} />
          </Styling.Center>
        )
      }
      if (segment) {
        return (
          <div>
            {typeof segment.dynamic === 'boolean' && (
              <ReadOnlyValue labelText={'Dynamic'} wideLabel value={segment.dynamic.toString()} />
            )}
            <br />
            {typeof segment.rolling === 'boolean' && (
              <>
                <ReadOnlyValue labelText={'Rolling'} wideLabel value={segment.rolling.toString()} />
                <br />
              </>
            )}
            <p>
              Matching <strong>{segment.rules.rulesOperators === 'AND' ? 'ALL' : 'ANY'}</strong> of
              the following rule criteria:
            </p>
            <br />
            <ReadOnlyValueDetails
              detailValues={getSegmentRuleDetails(segment.rules.rules, segment)}
              wideLabel
            />
          </div>
        )
      }
    }}
  />
)

export default SegmentRuleDetails
