import { mixins, vars } from 'styling'

export default theme => ({
  showCollapse: {
    transition: `opacity ${vars.transition.duration.base} ${vars.transition.timingFunction.standardEasing}`,
  },
  showCollapseOpen: {},
  showCollapseTrigger: {
    ...mixins.resets.button,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0,

    '&, & *': {
      cursor: 'pointer',
    },

    // Remove pointer events for any label children inside the trigger
    // (otherwise it gives unexpected behaviour by attempting to focus
    //  or activate its associated input; which prevents the trigger from working)
    '& label': {
      pointerEvents: 'none',
    },

    '&:hover, &$showCollapseAjarTrigger': {
      // Target trigger element provided by parent component
      '& *': {
        color: theme.brand01,
      },
      '& $showCollapseIcon': {
        fill: theme.brand01,
      },
    },

    '&:focus': {
      outline: 'none',

      '& $showCollapseIcon': {
        ...mixins.focusOutline({ type: 'blurred', theme }),
        // overflow: 'visible', // safari fix
        // outlineOffset: '-.5px', // safari fix
      },
    },
  },
  // When the showCollapse content is revealed
  // (if the showCollapse opens on hover, we only deem it "ajar" if keepOpen is set to true)
  showCollapseAjarTrigger: {
    '& *': {
      fontWeight: 600,
    },
  },
  showCollapseIcon: {
    fill: theme.text01,
    height: 8,
    transition: `transform ${vars.transition.duration.base} ${vars.transition.timingFunction.standardEasing}`,
    width: 8,

    '$showCollapseTrigger:hover &, $showCollapseOpen &': {
      transform: 'rotate(90deg)',
    },
  },
  showCollapseContent: {
    height: 0,
    opacity: 0,
    overflow: 'hidden',
    padding: 0,
    transition: `all ${vars.transition.duration.expansion} ${vars.transition.timingFunction.easeOut}`,
    visibility: 'hidden',

    '$showCollapseOpen &': {
      height: 'auto',
      opacity: 1,
      overflow: 'visible',
      transition: `all ${vars.transition.duration.expansion} ${vars.transition.timingFunction.easeIn}`,
      visibility: 'visible',
    },
  },
  showCollapseContentWithTopPaddingSm: {
    '$showCollapseOpen &': {
      paddingTop: theme.spacing.sm,
    },
  },
  showCollapseContentWithTopPaddingMd: {
    '$showCollapseOpen &': {
      paddingTop: theme.spacing.md,
    },
  },
})
