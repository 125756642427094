import { Formik } from 'formik'
import merge from 'lodash/merge'
import React from 'react'
import injectSheet from 'react-jss'
import { Element as ScrollElement } from 'react-scroll'
import { BooleanValue } from 'react-values'

import { fetchPlacementContentById } from 'api'
import { Button, Checkbox, FormFieldItem, Notification } from 'components'
import { ScrollSpyView } from 'layouts'
import { createToast } from 'modules/toasts'
import { getBackButton, getRouteTo } from 'routing'
import Styling from 'styling/components'

import { get, ItemFetcher } from 'utils'

import {
  PlacementActiveDays,
  PlacementActivityPeriod,
  PlacementCollections,
  PlacementDisplayContent,
  PlacementStoreVisibility,
  PlacementTitle,
  PlacementUserSegment,
  PlacementUserVisibility,
} from '../../Marketing/PromotedPlacements/PromotedPlacementsForm/components'
import {
  daysOfWeekArray,
  placementFormInitialValues,
  placementFormValidationSchema,
} from '../../Marketing/PromotedPlacements/PromotedPlacementsForm/PromotedPlacementsFormSchema'
import { onPromotedPlacementsFormSubmit } from '../../Marketing/PromotedPlacements/PromotedPlacementsForm/PromotedPlacementsFormSectionsMap'

import styles from '../../Marketing/PromotedPlacements/PromotedPlacementsForm/PromotedPlacementsForm.styles'

const PAGE_ID = 'featured-department-form'

const formSections = ({
  classes,
  formikProps,
  featuredDepartmentsPlacement,
  setFieldValue,
  showMarketingSection,
}) => {
  const expandedMarketingSection = {
    component: PlacementDisplayContent,
    noOptions: true,
    props: {
      componentId: `${PAGE_ID}-display-content`,
      customUploadDimensions: { generic: featuredDepartmentsPlacement.specs.image.size },
      radioButtonProps: [
        {
          id: 'image',
          labelText: 'Image',
          value: 'image',
        },
        {
          id: 'none',
          labelText: 'None',
          value: 'none',
        },
      ],
    },
  }

  const formSectionsArray = [
    {
      name: 'Detail',
      components: [
        {
          component: PlacementTitle,
          props: {
            componentId: `${PAGE_ID}-title`,
            labelText: 'Landing Page Name',
            placeholder: 'Enter page name',
          },
        },
        {
          component: PlacementCollections,
          props: {
            componentId: `${PAGE_ID}-collections`,
            componentName: 'target.collection_list',
            labelText: 'Content',
          },
        },
      ],
    },
    {
      name: 'Marketing',
      components: [
        {
          component: () => (
            <FormFieldItem
              className={classes.formRow}
              id={`${PAGE_ID}-show-marketing`}
              labelText="Placement Option"
              name="name"
              render={renderProps => (
                <BooleanValue
                  defaultValue={showMarketingSection}
                  onChange={value => {
                    setFieldValue('showMarketingSection', value)
                    if (value) {
                      setFieldValue('media.type', 'image')
                    }
                  }}
                >
                  {({ toggle, value }) => (
                    <Checkbox
                      checked={value}
                      id={`${PAGE_ID}-show-marketing-checkbox`}
                      labelText="Show in Navigation"
                      onChange={toggle}
                    />
                  )}
                </BooleanValue>
              )}
            />
          ),
        },
      ],
    },
    {
      name: 'Segmentation',
      components: [
        {
          component: PlacementStoreVisibility,
          props: {
            componentId: `${PAGE_ID}-store-visibility`,
            labelText: 'Store Visibility',
            placeholder: 'Select Store Group',
          },
        },
        {
          component: PlacementUserSegment,
          props: {
            componentId: `${PAGE_ID}-user-segment`,
            labelText: 'Target User Segment',
            placeholder: 'Select User Segment',
          },
        },
        {
          component: PlacementUserVisibility,
          props: {
            componentId: `${PAGE_ID}-user-visibility`,
            labelText: 'Dynamic User Visiblity',
            radioButtonProps: [
              {
                id: 'loyalty-everyone',
                labelText: 'Everyone',
                value: null,
              },
              {
                id: 'loyalty-true',
                labelText: 'Loyalty Only',
                value: true,
              },
              {
                id: 'loyalty-false',
                labelText: 'Non Loyalty Only',
                value: false,
              },
            ],
          },
        },
      ],
    },
    {
      name: 'Schedule',
      components: [
        {
          component: PlacementActivityPeriod,
          props: {
            componentId: `${PAGE_ID}-activity-period`,
            labelText: 'Activity Period',
          },
        },
        {
          component: PlacementActiveDays,
          props: {
            componentId: `${PAGE_ID}-active-days`,
            labelText: 'Active Days',
            checkboxOptions: daysOfWeekArray,
          },
        },
      ],
    },
  ]

  if (showMarketingSection) {
    formSectionsArray[1].components.push(expandedMarketingSection)
    return formSectionsArray
  }
  return formSectionsArray.slice(0, 2)
}

const initializeFormProps = ({ existingPlacement, featuredDepartmentsPlacement }) => {
  // Make a deep copy of the `formInitialValues` object to be used for merge with `existingPlacement`
  const formInitialValuesCopy = JSON.parse(JSON.stringify(placementFormInitialValues))
  const formProps = merge(formInitialValuesCopy, existingPlacement)

  // Initialize DatePicker component for PlacementActivityPeriod
  formProps.activityPeriod = {
    from: get(existingPlacement, 'startDate') || placementFormInitialValues.startDate,
    to: get(existingPlacement, 'endDate') || placementFormInitialValues.endDate,
  }

  // Always set status to 'draft' whenever user is creating or editing form
  formProps.status = 'draft'

  // Initialize placement object from `existingPlacement` or `/placement` endpoint to pass into form values
  if (existingPlacement) {
    formProps.target.openIn = formProps.target.openIn === 'new_tab'
    formProps.showMarketingSection = existingPlacement.status !== 'hidden'
  } else {
    formProps.placement = featuredDepartmentsPlacement
    formProps.media.type = 'none'
    formProps.target.type = 'collection_list'
  }

  return formProps
}

export const FeaturedDepartmentCreateOrEdit = injectSheet(styles)(
  ({
    backButton,
    classes,
    edit,
    existingPlacement,
    featuredDepartmentsPlacement,
    history,
    match,
    successMessage,
  }) => (
    <Formik
      initialValues={initializeFormProps({ existingPlacement, featuredDepartmentsPlacement })}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={placementFormValidationSchema(featuredDepartmentsPlacement.key)}
      onSubmit={(values, form) =>
        onPromotedPlacementsFormSubmit(edit, values)
          .then(featuredDepartment => {
            form.setSubmitting(false)
            createToast({ kind: 'success', message: successMessage })

            history.push(
              getRouteTo('featuredDepartments.details', {
                id: featuredDepartment.id,
                backButton,
              })
            )
          })
          .catch(error => {
            form.setSubmitting(false)
            form.setErrors({ global: error.message })
          })
      }
      render={formikProps => {
        const {
          setFieldValue,
          values: { showMarketingSection },
        } = formikProps

        return (
          <ScrollSpyView
            name="scroll-container"
            sections={formSections({
              classes,
              formikProps,
              featuredDepartmentsPlacement,
              setFieldValue,
              showMarketingSection,
            })}
            render={({ sections }) => (
              <div className={classes.formContainer}>
                {sections.map(({ components, hideComponent, name }, index) =>
                  !hideComponent ? (
                    <ScrollElement
                      key={index}
                      name={name}
                      className={classes.createPlacementContainer}
                    >
                      <h5 className={classes.createPlacementTitle}>{name}</h5>
                      {components.map((component, index) => {
                        const Component = component.component
                        const mergedProps = {
                          existingPlacement,
                          ...formikProps,
                          ...component.props,
                        }

                        return <Component key={index} {...mergedProps} />
                      })}
                    </ScrollElement>
                  ) : null
                )}
                <div className={classes.formFooter}>
                  <div className={classes.footerContainer}>
                    <Styling.LineOfButtons withoutTopMargin>
                      <Button
                        disabled={formikProps.isSubmitting}
                        id={`${PAGE_ID}-save`}
                        onClick={formikProps.handleSubmit}
                      >
                        {edit ? 'Save' : 'Create'}
                      </Button>
                      <Button kind="link" href={backButton.to} id={`${PAGE_ID}-cancel`}>
                        Cancel
                      </Button>
                    </Styling.LineOfButtons>
                    <div className={classes.footerErrors}>
                      {!formikProps.isSubmitting && formikProps.errors.global && (
                        <Notification kind="error" message={formikProps.errors.global} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          />
        )
      }}
    />
  )
)

export const FeaturedDepartmentCreate = ({ history, location, match }) => {
  /**
   * `duplicateId` is passed in when the user clicks on the "Duplicate" button from the Promoted Placement Details page
   */
  const {
    state: { featuredDepartmentsPlacement },
  } = location
  const duplicateId = get(location, 'state.duplicateId')
  const backButton = get(location, 'state.backButton') || getBackButton('featuredDepartments')
  const mergedProps = { backButton, edit: false, history, featuredDepartmentsPlacement, match }

  if (duplicateId) {
    return (
      <ItemFetcher
        queryKey="placementContentById"
        queryParams={{ duplicateId }}
        fetchItem={config => fetchPlacementContentById(duplicateId, config)}
        render={({
          item: existingPlacement,
          isLoadingItem: isFetchingExistingPlacement,
          error: errorFetchingExistingPlacement,
          refetch: refetchExistingPlacement,
        }) => {
          if (existingPlacement) {
            mergedProps.existingPlacement = {
              ...existingPlacement,
              id: undefined,
              status: 'draft',
            }
            return <FeaturedDepartmentCreateOrEdit {...mergedProps} />
          }
          return null
        }}
      />
    )
  }
  return <FeaturedDepartmentCreateOrEdit {...mergedProps} />
}

export const FeaturedDepartmentEdit = ({ history, location, match }) => {
  const {
    state: { featuredDepartmentsPlacement },
  } = location

  return (
    <ItemFetcher
      queryKey="placementContentById"
      queryParams={{ id: match.params.id }}
      fetchItem={config => fetchPlacementContentById(match.params.id, config)}
      render={({
        item: existingPlacement,
        isLoadingItem: isFetchingExistingPlacement,
        error: errorFetchingExistingPlacement,
        refetch: refetchExistingPlacement,
      }) => {
        const { id } = match.params

        if (existingPlacement) {
          const backButton = getBackButton('featuredDepartments.details', { id })
          const mergedProps = {
            backButton,
            edit: true,
            existingPlacement,
            featuredDepartmentsPlacement,
            history,
            match,
          }
          return <FeaturedDepartmentCreateOrEdit {...mergedProps} />
        }
        return null
      }}
    />
  )
}
