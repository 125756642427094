import React, { useEffect, useState } from 'react'

import { LoadingIndicator } from 'components'

import { getOffer } from 'api'

import OfferDetailsTable from './OfferDetailsTable'

const CartOfferModalContent = ({ offerId, storeId }) => {
  const [offerData, setOfferData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(undefined)

  useEffect(() => {
    setLoading(true)
    setErrorMessage(undefined)
    getOffer({ offer_id: offerId, store_id: storeId })
      .then(resp => {
        setOfferData(resp)
        setLoading(false)
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.error(e)
        setErrorMessage(e.message)
        setLoading(false)
        setOfferData(null)
      })
  }, [offerId, storeId])

  return (
    <div>
      <div>
        {loading && <LoadingIndicator />}
        {errorMessage && <div>Error looking up offer: {errorMessage}</div>}
        {!loading && offerData && <OfferDetailsTable data={offerData} />}
      </div>
    </div>
  )
}

export default CartOfferModalContent
