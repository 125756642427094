import { get } from 'utils'

/**
 * These routes are sorted by Top Nav Menu ordering.
 *
 * As much as possible, try and name these after the resources they display and modify, rather than the
 * names (under `label`) attributed to the section of pages (e.g. "users" instead of "userManagement").
 */
const routes = {
  v1: {
    label: 'Dashboard V1',
    to: ({ location }) => ({
      pathname: '/v1',
      // eslint-disable-next-line no-use-before-define
      hash: location ? v1Paths[location.pathname] : undefined,
    }),
  },

  // Marketing
  promotedPlacements: {
    label: 'Merchandising',

    // TODO - redirect to first programatically
    to: { pathname: '/marketing/promoted-placements' },

    content: {
      to: ({ placements, slug, tab = 0 }) => ({
        pathname: `/marketing/promoted-placements/${slug}`,
        search: `?expired=false&tab=${tab}`,
        state: { placements },
      }),
    },

    create: {
      to: ({ id, slug }) => ({ pathname: `/marketing/promoted-placements/${slug}/${id}/create` }),
    },

    details: {
      to: ({ backButton, id, slug }) => ({
        pathname: `/marketing/promoted-placements/${slug}/${id}/details`,
        state: { backButton },
      }),
    },

    edit: {
      to: ({ id, slug }) => ({ pathname: `/marketing/promoted-placements/${slug}/${id}/edit` }),
    },
  },

  segments: {
    label: 'Customer User Segments',
    to: {
      pathname: '/marketing/segments-and-store-groups/segments',
      search: '?status=true&order_by=created&direction=desc',
    },

    createSegment: {
      to: { pathname: '/marketing/segments-and-store-groups/segments/create' },
    },
  },
  storeGroups: {
    label: 'Store Groups',
    to: {
      pathname: '/marketing/segments-and-store-groups/store-groups',
      search: '?status=true&order_by=created&direction=desc',
    },

    storeGroup: {
      to: ({ id }) => ({ pathname: `/marketing/segments-and-store-groups/store-groups/${id}` }),
    },
    createStoreGroup: {
      to: { pathname: '/marketing/segments-and-store-groups/store-groups/create' },
    },
    editStoreGroup: {
      to: ({ id }) => ({
        pathname: `/marketing/segments-and-store-groups/store-groups/edit/${id}`,
      }),
    },
  },
  offers: {
    label: 'Offers',
    to: {
      pathname: '/marketing/offers',
    },
  },
  flyerManagement: {
    label: 'Flyer Management',
    to: {
      pathname: '/marketing/flyer-management/flyer-management',
    },
  },
  flyerPreviewSettings: {
    label: 'Flyer Preview Settings',
    to: {
      pathname: '/marketing/flyer-management/flyer-preview-settings',
    },
  },
  emailTemplates: {
    label: 'Email Templates',
    to: {
      pathname: '/marketing/email-templates',
    },
  },
  recipeCollections: {
    label: 'Recipe Collections',
    to: {
      pathname: '/products-and-collections/recipe-collections',
    },
  },
  demo: {
    label: 'Demo',
    to: {
      pathname: '/account/demo',
    },
  },
  blackoutDays: {
    label: 'Blackout Days',
    to: {
      pathname: '/settings/blackout-days',
    },
  },
  deliveryCompanies: {
    label: 'Delivery Companies',
    to: {
      pathname: '/settings/delivery-companies',
    },
  },
  storeDashboard: {
    label: 'Store Dashboard',
    to: {
      pathname: '/settings/store-dashboard',
    },
  },
  pluManagement: {
    label: 'PLU Management',
    to: {
      pathname: '/settings/plu-management',
    },
  },
  configurationManagement: {
    label: 'Configuration Management',
    to: {
      pathname: '/settings/configuration-management',
    },
  },
  searchSettings: {
    label: 'Search Settings',
    globalSearchSettings: {
      label: 'Global Search Settings',
      to: {
        pathname: '/marketing/search-settings/global-search-settings',
      },
    },
    keywordRedirects: {
      label: 'Keyword Redirects',
      to: {
        pathname: '/marketing/search-settings/keyword-redirects',
      },

      createKeywordRedirect: {
        to: { pathname: '/marketing/search-settings/keyword-redirects/create' },
      },

      editKeywordRedirect: {
        to: ({ id }) => ({
          pathname: `/marketing/search-settings/keyword-redirects/edit/${id}`,
        }),
      },
    },
    keywordRules: {
      label: 'Keyword Rules',
      to: {
        pathname: '/marketing/search-settings/keyword-rules',
        search: '?order_by=created&direction=desc',
      },
      createKeywordRule: {
        to: { pathname: '/marketing/search-settings/keyword-rules/create' },
      },
      editKeywordRule: {
        to: ({ id }) => ({ pathname: `/marketing/search-settings/keyword-rules/edit/${id}` }),
      },
    },
    globalRecipeSearchSettings: {
      label: 'Global Recipe Settings',
      to: {
        pathname: '/marketing/search-settings/global-recipe-search-settings',
      },
    },
    recipeKeywordRules: {
      label: 'Recipe Rules',
      to: {
        pathname: '/marketing/search-settings/recipe-rules',
        search: '?order_by=created&direction=desc',
      },
      createRecipeKeywordRule: {
        to: { pathname: '/marketing/search-settings/recipe-rules/create' },
      },
      editRecipeKeywordRule: {
        to: ({ id }) => ({ pathname: `/marketing/search-settings/recipe-rules/edit/${id}` }),
      },
    },
    synonyms: {
      label: 'Search Synonyms',
      to: {
        pathname: '/marketing/search-settings/synonyms',
      },

      createSynonym: {
        to: { pathname: '/marketing/search-settings/synonyms/create' },
      },

      editSynonym: {
        to: ({ id }) => ({ pathname: `/marketing/search-settings/synonyms/synonym/${id}` }),
      },
    },
  },

  // Customers & Orders
  customers: {
    label: 'Guest Management',
    to: { pathname: '/customers-and-orders/customers' },

    customer: {
      to: ({ id }) => ({ pathname: `/customers-and-orders/customers/${id}` }),
    },
  },
  createOrder: {
    label: 'Create New Catering Order',
    to: { pathname: '/customers-and-orders/order/create' },
  },
  orders: {
    label: 'Order Management',
    to: { pathname: '/customers-and-orders/orders' },

    order: {
      to: ({ id }) => ({ pathname: `/customers-and-orders/orders/${id}` }),
    },
  },
  cateringOrders: {
    label: 'Catering Orders',
    to: {
      pathname: '/customers-and-orders/catering-orders',
      search: '?order_by=ready_at&order_by_dir=asc&internal_status=new&internal_status=inprogress',
    },
  },
  ccpaRequests: {
    label: 'Privacy Requests',
    to: { pathname: '/customers-and-orders/ccpa-requests' },
  },
  offersExplain: {
    label: 'Offers',
    to: { pathname: '/customers-and-orders/offers-explain' },
  },

  // Products & Collections
  products: {
    label: 'Product Lookup',
    to: { pathname: '/products-and-collections/products' },

    product: {
      to: ({ id }) => ({ pathname: `/products-and-collections/products/${id}` }),
    },
  },
  collections: {
    label: 'Collections',
    to: {
      pathname: '/products-and-collections/collections',
      search: '?order_by=created&direction=desc',
    },

    collection: {
      to: ({ id }) => ({ pathname: `/products-and-collections/collections/${id}` }),
    },

    createCollection: {
      to: { pathname: '/products-and-collections/collections/create' },
    },

    editCollection: {
      to: ({ id }) => ({ pathname: `/products-and-collections/collections/edit/${id}` }),
    },
  },
  featuredDepartments: {
    label: 'Merchandised Landing Pages',
    to: {
      pathname: '/products-and-collections/featured-departments',
      search: '?expired=false',
    },

    createFeaturedDepartment: {
      to: ({ backButton, featuredDepartmentsPlacement, id }) => ({
        pathname: `/products-and-collections/featured-departments/create/${id}`,
        state: { backButton, featuredDepartmentsPlacement },
      }),
    },

    details: {
      to: ({ id }) => ({
        pathname: `/products-and-collections/featured-departments/${id}/details`,
      }),
    },

    editFeaturedDepartment: {
      to: ({ backButton, featuredDepartmentsPlacement, id }) => ({
        pathname: `/products-and-collections/featured-departments/${id}/edit`,
        state: { backButton, featuredDepartmentsPlacement },
      }),
    },
  },
  merchandisedLandingPages: {
    label: 'Merchandised Landing Pages (V2)',
    to: {
      pathname: '/products-and-collections/merchandised-landing-pages',
      search: '?expired=false',
    },

    createMerchandisedLandingPage: {
      to: ({ backButton }) => ({
        pathname: `/products-and-collections/merchandised-landing-pages/create`,
        state: { backButton },
      }),
    },

    details: {
      label: 'Merchandised Landing Page Details',
      to: ({ id }) => ({
        pathname: `/products-and-collections/merchandised-landing-pages/${id}/details`,
      }),
    },

    editMerchandisedLandingPage: {
      to: ({ backButton, id }) => ({
        pathname: `/products-and-collections/merchandised-landing-pages/${id}/edit`,
        state: { backButton },
      }),
    },
  },

  // Account
  myProfile: {
    label: 'My Profile',
    to: { pathname: '/account/profile' },

    editMyProfile: {
      to: { pathname: '/account/profile/edit' },
    },
  },
  changePassword: {
    to: { pathname: '/account/change-password' },
  },
  users: {
    label: 'User Management',
    to: { pathname: '/account/users' },

    createUser: {
      to: { pathname: '/account/users/create' },
    },
    editUser: {
      to: ({ id }) => ({ pathname: `/account/users/edit/${id}` }),
    },
  },
  roles: {
    label: 'Roles & Permissions',
    to: { pathname: '/account/roles', search: '?order_by=name&direction=asc' },

    role: {
      to: ({ id }) => ({ pathname: `/account/roles/${id}` }),
    },
    createRole: {
      to: { pathname: '/account/roles/create' },
    },
    editRole: {
      to: ({ id }) => ({ pathname: `/account/roles/edit/${id}` }),
    },
  },

  // Subscriptions
  subscriptions: {
    label: 'Subscriptions',
    to: { pathname: '/settings/subscriptions' },

    createSubscription: {
      to: { pathname: '/settings/subscriptions/create' },
    },

    editSubscription: {
      to: ({ id }) => ({ pathname: `/settings/subscriptions/edit/${id}` }),
    },
  },

  // Facts
  facts: {
    label: 'Facts',
    to: ({ key }) => ({ pathname: `/facts/${key}` }),
    editFact: {
      to: ({ key }) => ({ pathname: `/facts/edit/${key}` }),
    },
  },
}

/**
 * Creates a back button with the corresponding route's label and the
 * `location` prop provided (which represents the current route data,
 * and not the route's default/fallback data).
 * @param {string} routePath
 * @param {object} location
 * @param {string=} label
 */
export const generateBackButton = (routePath, location, label) => {
  const route = get(routes, routePath)

  return route
    ? {
        label: typeof label === 'undefined' ? route.label : label,
        to: {
          pathname: location.pathname, // Current URL
          search: location.search, // Current routeQueryString
        },
      }
    : {}
}

/**
 * Returns a back button with the corresponding route's default/fallback
 * label and `to` route data: (`pathname`, `search` query string).
 * @param {string} routePath
 * For routes corresponding to a specific resource instance, we need
 * an id and label to generate a back button.
 * @param {string} routeProps.id
 * @param {string} routeProps.label
 *
 */
export const getBackButton = (routePath, { id, label, slug } = {}) => {
  const route = get(routes, routePath)
  return route
    ? {
        label: label || route.label,
        to: id || slug ? route.to({ id, slug }) : route.to,
      }
    : {}
}

export const getRouteTo = (routePath, routeState) => {
  const route = get(routes, routePath)
  return route ? (routeState ? route.to(routeState) : route.to) : {}
}

const generateGetRouteToProp = prop => (routePath, routeProps) => {
  const routeTo = getRouteTo(routePath, routeProps)
  return routeTo[prop] || ''
}
export const getRoutePathname = generateGetRouteToProp('pathname')
export const getRouteSearch = generateGetRouteToProp('search')

export const v1Paths = {
  [getRoutePathname('blackoutDays')]: '/settings/blackouts',
  [getRoutePathname('configurationManagement')]: '/configuration_management',
  [getRoutePathname('deliveryCompanies')]: '/settings/delivery_companies',
  [getRoutePathname('demo')]: '/demo',
  [getRoutePathname('emailTemplates')]: '/emailTemplates',
  [getRoutePathname('flyerManagement')]: '/flyerManagement/flyers/',
  [getRoutePathname('flyerPreviewSettings')]: '/flyerManagement/flyerPreviews/view',
  [getRoutePathname('offers')]: '/offers',
  [getRoutePathname('pluManagement')]: '/settings/pluItems',
  [getRoutePathname('recipeCollections')]: '/collections/recipe_collections',
  [getRoutePathname('salePricing')]: '/sale_pricing_groups',
  [getRoutePathname('storeDashboard')]: '/settings/stores',
}

export const getPathPrefix = (path, components) =>
  path
    .split('/')
    .slice(0, components + 1)
    .join('/')

export const getRoutePathnameByHash = hash => {
  const v1Path = hash.replace(/^#/, '')
  const filteredV1Paths = Object.entries(v1Paths).find(([, itemV1Path]) => {
    const prefix = getPathPrefix(itemV1Path, 2)
    return v1Path.startsWith(prefix)
  })
  return filteredV1Paths?.[0]
}
