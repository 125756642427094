import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'

import { ButtonIconAction } from 'components'
import { isObject } from 'utils'
import Styling from 'styling/components'

const ArrayField = React.memo(({ uiSchema, items, canAdd, onAddClick }) => {
  const formattedItems = items.map(({ key, children, hasRemove, index, onDropIndexClick }) => {
    return (
      <Styling.LineOfItems alignCenter key={key} className={uiSchema.bottomMargin}>
        <div
          className={cx(uiSchema.flex, {
            [uiSchema.border]: isObject(children.props.formData),
          })}
        >
          {children}
        </div>
        {hasRemove && (
          <ButtonIconAction icon="delete" description="Remove" onClick={onDropIndexClick(index)} />
        )}
      </Styling.LineOfItems>
    )
  })
  return (
    <Fragment>
      <div>{formattedItems}</div>
      {canAdd && (
        <div>
          <ButtonIconAction icon="plus" description="Add" onClick={onAddClick} />
        </div>
      )}
    </Fragment>
  )
})

ArrayField.propTypes = {
  uiSchema: PropTypes.object,
  items: PropTypes.array,
  canAdd: PropTypes.bool,
  onAddClick: PropTypes.func,
}

export default ArrayField
