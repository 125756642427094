import memoize from 'memoize-one'
import React, { Component } from 'react'
import injectSheet from 'react-jss'
import { connect } from 'react-redux'

import { fetchProducts, fetchStores } from 'api'
import { Dropdown, DropdownFilterable } from 'components'
import { TableView } from 'layouts'
import { getUserPermissionKeys } from 'modules/user'
import { generateBackButton, getRoutePathname } from 'routing'
import { formatDatetime, lowercase } from 'utils'

import styles from './Products.styles'

// Table Configurations
const PAGE_ID = 'products'
const PAGE_TITLE = 'Product Lookup'
const FETCH_FUNCTION = fetchProducts

const tableParams = {
  paging: {
    type: 'multi',
    multiParams: { limit: { key: 'limit' }, offset: { key: 'offset' } },
  },
  search: { key: 'search' },
  sorting: {
    type: 'multi',
    multiParams: { direction: { key: 'direction' }, orderBy: { key: 'order_by' } },
  },
  has_image: { key: 'has_image' },
  store_id: { key: 'store_id' },
}

const tableHeaders = (items = []) => {
  const hasRRC = items.some(({ rrc }) => rrc)
  return [
    {
      key: 'nameWithImage',
      header: 'Product Name',
    },
    {
      key: 'size',
      header: 'Size',
      disableSorting: true,
    },
    {
      key: 'sku',
      header: 'SKU',
      disableSorting: true,
    },
    {
      key: 'upc',
      header: 'UPC',
      disableSorting: true,
    },
    ...(hasRRC
      ? [
          {
            key: 'rrc',
            header: 'RRC',
            disableSorting: true,
          },
        ]
      : []),
    {
      key: 'activeStores',
      header: 'Active Stores',
      disableSorting: true,
    },
    {
      key: 'modified',
      header: 'Last Edited',
    },
  ]
}

const selectedTableActions = [
  {
    action: 'copy',
    content: 'SKU',
    id: `${PAGE_ID}-copy-sku`,
    permission: 'collections.create',
    type: 'sku',
  },
  {
    action: 'copy',
    content: 'UPC',
    id: `${PAGE_ID}-copy-upc`,
    permission: 'collections.create',
    type: 'upcs',
  },
  {
    content: 'Create Collection',
    id: `${PAGE_ID}-create-collection`,
    permission: 'collections.create',
    href: {
      pathname: getRoutePathname('collections.createCollection'),
    },
    persistState: true,
  },
]

const formatTableRows = memoize(({ items, location, classes }) => {
  return items.map(product => {
    const { activeStores, id, imageUrl, modified, name, sku, uom, upcs, rrc, weight } = product
    const size = weight && uom ? `${weight} ${lowercase({ phrase: uom })}` : 'n/a'
    const nameWithImage = (
      <div className={classes.nameWithImage}>
        <div className={classes.imageContainer}>
          <img src={imageUrl} alt={name} />
        </div>
        <span>{name}</span>
      </div>
    )

    return {
      id: `${id}`,
      rowLinkTo: {
        pathname: getRoutePathname('products.product', { id }),
        state: { backButton: generateBackButton('products', location) },
      },
      nameWithImage,
      size,
      sku,
      upc: `${upcs[0] || 'n/a'} ${upcs.length > 1 ? `(${upcs.length})` : ''}`,
      rrc,
      activeStores: activeStores ? activeStores.length : 'n/a',
      modified: modified ? formatDatetime(modified) : '',
    }
  })
})

const filterOptions = {
  has_image: [
    { id: 'anyImage', label: 'Any Image', value: null },
    { id: 'hasImage', label: 'With Image', value: 'true' },
    { id: 'noImage', label: 'No Image', value: 'false' },
  ],
}

const formatTableFilters = (params, stores, isFetchingStores) => {
  // Value should be a string for comparison to work
  // TODO - investigate why DropdownFilterable doesn't accept Numbers
  const storesFilterOptions = stores.map(({ id, name }) => ({
    id: `${id}`,
    label: name,
    value: `${id}`,
  }))

  return [
    {
      component: Dropdown,
      id: `${PAGE_ID}-filter-image`,
      items: filterOptions.has_image,
      onChange: params.has_image.onChange,
      placeholder: 'Image Status',
      selectedValue: params.has_image.value,
    },
    {
      component: DropdownFilterable,
      id: `${PAGE_ID}-filter-store`,
      isLoading: isFetchingStores,
      items: storesFilterOptions,
      onChange: params.store_id.onChange,
      placeholder: 'Store Availability',
      selectedValue: params.store_id.value,
    },
  ]
}

class Products extends Component {
  state = {
    stores: [],
    isFetchingStores: false,
    items: undefined,
  }

  componentDidMount() {
    this.loadStoresDropdown()
  }

  loadStoresDropdown() {
    this.setState({ isFetchingStores: true })

    fetchStores()
      .then(({ items }) => {
        this.setState({
          stores: items,
        })
      })
      .finally(() => {
        this.setState({ isFetchingStores: false })
      })
  }

  render() {
    const { stores, isFetchingStores, items } = this.state
    return (
      <TableView
        {...this.props}
        queryKey="products"
        fetchOnMount
        fetchOnEmptyQuery={false}
        fetchFunction={FETCH_FUNCTION}
        isSelectable={this.props.userPermissions.includes('products.edit')}
        pageId={PAGE_ID}
        pageTitle={PAGE_TITLE}
        pageType={PAGE_ID}
        selectedTableActions={selectedTableActions}
        tableFilters={params => formatTableFilters(params, stores, isFetchingStores)}
        tableHeaders={tableHeaders(items)}
        tableParams={tableParams}
        tableRows={props => {
          if (!items && props.items.length) {
            this.setState({ items: props.items })
          }
          return formatTableRows(props)
        }}
      />
    )
  }
}

const mapStateToProps = state => ({
  userPermissions: getUserPermissionKeys(state),
})

export default injectSheet(styles)(connect(mapStateToProps, null)(Products))
