import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import injectSheet from 'react-jss'

import styles from './ReadOnlyValue.styles'

class ReadOnlyValue extends Component {
  static displayName = 'ReadOnlyValue'

  static propTypes = {
    action: PropTypes.element,
    id: PropTypes.string,
    labelText: PropTypes.string,
    orientation: PropTypes.oneOf(['horizontal', 'vertical']),
    /**
     * Removes 'mixins.formItem' styling which sets margins between <ReadOnlyValue>s
     */
    removeFormItemContainer: PropTypes.bool,
    small: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
      PropTypes.node,
    ]).isRequired,
    valueType: PropTypes.oneOf(['text', 'list']),
    wideLabel: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    orientation: 'horizontal',
    removeFormItemContainer: false,
    small: false,
    valueType: 'text',
    wideLabel: false,
  }

  state = {
    height: 0,
  }

  componentDidMount() {
    const { removeTextAreaEl } = this.props

    if (!removeTextAreaEl) {
      this.setState({ height: this.textAreaEl.scrollHeight })
    }
  }

  render() {
    const {
      action,
      id,
      classes,
      labelText,
      orientation,
      removeFormItemContainer,
      removeTextAreaEl,
      small,
      value,
      valueType,
      wideLabel,
    } = this.props
    const { height } = this.state

    const valueElement = removeTextAreaEl ? (
      <span className={classes.readOnlyValue}>
        {valueType === 'list' ? value.join(', ') : value}
      </span>
    ) : (
      <textarea
        id={id}
        className={classes.readOnlyValue}
        value={valueType === 'list' ? value.join(', ') : value}
        readOnly
        ref={ref => (this.textAreaEl = ref)}
        style={{ height }}
      />
    )

    return (
      <div
        className={cx(classes.readOnlyValueContainer, {
          [classes.readOnlyValueFormItemContainer]: !removeFormItemContainer,
          [classes.readOnlyValueSmallContainer]: small,
          [classes.readOnlyValueHorizontalContainer]: orientation === 'horizontal',
          [classes.readOnlyValueVerticalContainer]: orientation === 'vertical',
          [classes.readOnlyValueActionContainer]: action,
        })}
      >
        {labelText && (
          <label
            className={cx(classes.readOnlyValueLabel, {
              [classes.readOnlyValueWideLabel]: wideLabel,
            })}
            htmlFor={id}
          >
            {labelText}
          </label>
        )}
        {valueElement}
        {action && <div className={classes.readOnlyValueActionContent}>{action}</div>}
      </div>
    )
  }
}

export { ReadOnlyValue as ReadOnlyValueUnStyled }
export default injectSheet(styles)(ReadOnlyValue)
