import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import PropTypes from 'prop-types'

const DraggableRow = ({ id, index, isDraggable, render }) => {
  if (!isDraggable) {
    return render({})
  }

  return (
    <Draggable draggableId={id} index={index}>
      {(draggableProvided, draggableSnapshot) => render({ draggableProvided, draggableSnapshot })}
    </Draggable>
  )
}

DraggableRow.defaultProps = {
  render: () => {},
}

DraggableRow.propTypes = {
  id: PropTypes.string,
  index: PropTypes.number,
  isDraggable: PropTypes.bool,
  render: PropTypes.func,
}

export default DraggableRow
