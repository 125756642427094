import PropTypes from 'prop-types'
import React from 'react'

import { Button, Modal } from 'components'

import FactDetails from './FactDetails'

const FactDetailsModal = ({ title, schema, value }) => (
  <Modal
    showClose
    modalHeading={title}
    render={() => <FactDetails factSchema={schema} factValue={value} />}
    triggerRender={({ openModal }) => (
      <Button onClick={openModal} kind="link" type="button">
        Open
      </Button>
    )}
  />
)

FactDetailsModal.propTypes = {
  schema: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export default FactDetailsModal
