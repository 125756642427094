import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'
import { Component } from 'react'

// TODO: Make this into just a debounce state holder using react-values

/**
 * Provides a render function which exposes `handleChange` and `value`.
 * `handleChange` can then be connected to an input or the like which will handle
 * the change events and debounce them and provide a `debouncedValue` if `debounce` is `true`.
 *
 * This component can be used to allow an controlled input/component to be uncontrolled by
 * handling the value state and its updates.
 */
class StateHolder extends Component {
  static propTypes = {
    debounce: PropTypes.bool.isRequired,
    /** `debounceTime` must be provided in milliseconds */
    debounceTime: PropTypes.number,
    initialValue: PropTypes.any,
    onChange: PropTypes.func,
    render: PropTypes.func.isRequired,
  }

  static defaultProps = {
    debounce: false,
    debounceTime: 200,
    initialValue: null,
  }

  state = {
    value: this.props.initialValue,
    debouncedValue: this.props.initialValue,
  }

  handleChange = newValue => {
    const { debounce, onChange } = this.props

    if (onChange) {
      onChange(newValue)
    }
    this.setState({ value: newValue })

    if (debounce) {
      this.triggerDebouncedValueUpdate(newValue)
    }
  }

  triggerDebouncedValueUpdate = debounce(newValue => {
    this.setState({ debouncedValue: newValue })
  }, this.props.debounceTime)

  render() {
    const { render } = this.props
    const { value, debouncedValue } = this.state
    return render({ handleChange: this.handleChange, value, debouncedValue })
  }
}

export default StateHolder
