export default theme => ({
  formRequirement: {
    fontSize: theme.fontSize.small,
    color: theme.support01,
    lineHeight: '1.5',
    margin: `${theme.spacing.xs} 0 0`,
    maxHeight: 200,
    overflow: 'hidden',
    textAlign: 'left',
  },
  iconError: {
    fill: theme.support01,
    height: 15,
    marginRight: theme.spacing.xxs,
    verticalAlign: 'top',
    width: 15,
  },
})
