import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import injectSheet from 'react-jss'

import { Icon, Button } from 'components'

import styles from './Notification.styles'

const NotificationAction = ({ classes, action }) => {
  if (React.isValidElement(action)) {
    return action
  }

  const { onClick, text } = action
  return (
    <button className={classes.notificationAction} onClick={onClick} type="button">
      {text.toUpperCase()}
    </button>
  )
}

const NotificationDetails = ({ classes, title, message }) => (
  <div className={classes.notificationDetails}>
    {title && <p className={classes.notificationTitle}>{title}</p>}
    <p>{message}</p>
  </div>
)

class Notification extends Component {
  static displayName = 'Notification'

  static propTypes = {
    hideBorder: PropTypes.bool,
    kind: PropTypes.oneOf(['error', 'success', 'info', 'warning']).isRequired,
    message: PropTypes.node.isRequired,
    title: PropTypes.string,
    action: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.exact({
        onClick: PropTypes.func,
        text: PropTypes.string,
      }),
    ]),
  }

  static defaultProps = {
    hideBorder: false,
  }

  render() {
    const { className, classes, hideBorder, message, title, kind, action } = this.props

    const notificationClasses = cx(className, classes.notification, {
      [classes.notificationError]: kind === 'error',
      [classes.notificationSuccess]: kind === 'success',
      [classes.notificationInfo]: kind === 'info',
      [classes.notificationWarning]: kind === 'warning',
      [classes.notificationNoBorder]: hideBorder,
    })

    return (
      <div role="alert" kind={kind} className={notificationClasses}>
        <div className={classes.notificationTextContainer}>
          <Icon className={classes.notificationIcon} name={kind} />
          <NotificationDetails classes={classes} title={title} message={message} />
        </div>
        {action && <NotificationAction classes={classes} action={action} />}
      </div>
    )
  }
}

export { Notification as NotificationUnStyled }
export default injectSheet(styles)(Notification)
