/**
 * Generic helper used to create a unique cell id.
 * The strategy is currently that a `cellId` is just the combination
 * of the row id and the header key used to access this field in a row.
 *
 * @param {string} rowId
 * @param {string} headerKey
 * @returns {string}
 */
export const getCellId = (rowId, headerKey) => `${rowId}:${headerKey}`
