import { mixins } from 'styling'

export default theme => {
  const inputField = mixins.inputField({ theme })
  return {
    inputDescription: {
      color: theme.text01,
      fontSize: theme.fontSize.small,
      marginTop: theme.spacing.xs,
      wordBreak: 'break-word',
    },
    textInputContainer: {
      position: 'relative',
    },
    textInput: {
      ...inputField,
      minWidth: 160,
    },
    textInputInvalid: {
      ...inputField.invalid,
    },
    textInputWithValue: {
      '&:not($textInputInvalid)': inputField.withValue,
    },
    textInputWithPrefixSymbol: {
      paddingLeft: 20,
    },
    textInputWithMaxLengthCount: {
      paddingRight: 60,
    },
    textInputPrefixSymbol: {
      color: theme.text02,
      fontSize: inputField.fontSize,
      position: 'absolute',
      height: inputField.height,
      left: 10,
      lineHeight: `${inputField.height + 2}px`,
    },
    textInputWithValuePrefixSymbol: {
      color: theme.text01,
      fontWeight: 600,
    },
    textInputMaxLengthCount: {
      color: theme.text02,
      fontSize: inputField.fontSize,
      position: 'absolute',
      height: inputField.height,
      right: 10,
      lineHeight: `${inputField.height + 2}px`,
    },
  }
}
