import PropTypes from 'prop-types'
import React from 'react'
// import injectSheet from 'react-jss'
import { createStyles } from '@instacart/cocktail-helpers'

import { Button } from 'components'
import { ModalHeader } from 'styling/components'

import Modal from './Modal'

const styles = tokens => ({
  details: {
    fontSize: tokens.size.font.body,
    color: tokens.color.text.primary,
    lineHeight: 1.5,
    margin: 0,
    textAlign: 'left',
    width: 'auto',
  },
  buttonsContainer: {
    marginTop: tokens.size.spacing.large,
    textAlign: 'left',
  },
  confirmButton: {
    marginRight: tokens.size.spacing.large,
  },
})

const useStyles = createStyles(styles)

const PrintOrdersModalContent = ({
  cancelButtonText,
  closeModal,
  confirmButtonText,
  contentState,
  details,
  printOrders,
  headerText,
}) => {
  const styles = useStyles()

  return (
    <div>
      <ModalHeader css={styles.header} small>
        {headerText || `Print Orders`}
      </ModalHeader>
      {details && <div css={styles.details}>{details}</div>}
      <div css={styles.buttonsContainer}>
        {confirmButtonText && (
          <Button
            css={styles.confirmButton}
            onClick={() => {
              // TODO: Remove the contentState spread here to remove synthetic event warning
              printOrders()
              closeModal({ ...contentState, wasConfirmed: true })
            }}
          >
            {confirmButtonText}
          </Button>
        )}
        <Button kind="secondary" onClick={closeModal}>
          {cancelButtonText}
        </Button>
      </div>
    </div>
  )
}

PrintOrdersModalContent.propTypes = {
  cancelButtonText: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  confirmButtonText: PropTypes.string,
  details: PropTypes.node,
  printOrders: PropTypes.func,
  headerText: PropTypes.string,
}

PrintOrdersModalContent.defaultProps = {
  cancelButtonText: 'Cancel',
}

const PrintOrdersModal = ({ contentProps, ...modalProps }) => (
  <Modal
    {...modalProps}
    size="small"
    render={({ closeModal, contentState }) => (
      <PrintOrdersModalContent
        closeModal={closeModal}
        contentState={contentState}
        {...contentProps}
      />
    )}
  />
)

export default PrintOrdersModal
