import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'

const ObjectField = React.memo(({ uiSchema, title, description, properties }) => {
  return (
    <Fragment>
      {description}
      <div className={cx({ [uiSchema.leftMargin]: title })}>
        {properties.map(element => element.content)}
      </div>
    </Fragment>
  )
})

ObjectField.propTypes = {
  uiSchema: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  properties: PropTypes.object,
}

export default ObjectField
