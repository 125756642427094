import config from 'config'

export default {
  initialize: ({ user }) => {
    window.pendo.initialize({
      visitor: {
        id: user.email,
        role: user.role.name,
        internalId: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
      },

      account: {
        id: `${config.client}-v2`,
        webUrl: config.env.webUrl,
        version: config.version,
      },
    })
  },
}
