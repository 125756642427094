import Downshift from 'downshift'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { removeAtIndex } from 'utils'
import { Checkbox, ListBox } from 'components'

class MultiSelect extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    /**
     * If defined, will show the icon with this name to the left of the MultiSelect
     */
    icon: PropTypes.string,
    id: PropTypes.string,
    isInvalid: PropTypes.bool,
    items: PropTypes.array.isRequired,
    /**
     * Will set `white-space: nowrap` on the ListBoxMenu
     */
    nowrap: PropTypes.bool,
    onChange: PropTypes.func,
    placeholder: PropTypes.string.isRequired,
    selectedValues: PropTypes.array,
  }

  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      selectedItems: [],
      selectedItemsHaveChanged: false,
    }
    if (props.selectedValues) {
      this.state.selectedItems = this.selectItemsFromValues(props.selectedValues)
    }
  }

  /**
   * TODO: `componentDidUpdate` should be replaced with `getDerivedStateFromProps` (like in <MinMax>)
   * or a better solution altogether
   */
  componentDidUpdate(prevProps, prevState) {
    const { selectedValues: prevSelectedValues } = prevProps
    const { onChange, selectedValues } = this.props
    const { isOpen: wasOpen } = prevState
    const { isOpen, selectedItems, selectedItemsHaveChanged } = this.state

    if (wasOpen && !isOpen && selectedItemsHaveChanged && onChange) {
      this.setState({
        selectedItemsOnLastClose: false, // reset flag
      })

      // Send update to consumer on dropdown menu close
      onChange(selectedItems.map(selectedItem => selectedItem.value))
    } else if (prevSelectedValues !== selectedValues) {
      // Update state.selectedItems on selectedValues props change from consumer
      this.setState({
        selectedItems: this.selectItemsFromValues(selectedValues),
      })
    }
  }

  selectItemsFromValues = values => {
    const { items } = this.props

    return items.filter(item => values.includes(item.value))
  }

  handleClearSelection = () => {
    this.setState({
      selectedItems: [],
      selectedItemsHaveChanged: true,
    })

    if (this.props.onChange) {
      this.props.onChange([])
    }
  }

  handleRemoveItem = index => {
    this.setState(state => ({
      selectedItems: removeAtIndex(state.selectedItems, index),
      selectedItemsHaveChanged: true,
    }))
  }

  handleSelectItem = item => {
    this.setState(state => ({
      selectedItems: state.selectedItems.concat(item),
      selectedItemsHaveChanged: true,
    }))
  }

  handleItemChange = item => {
    const { selectedItems } = this.state
    const selectedIndex = selectedItems.findIndex(selectedItem => selectedItem.id === item.id)

    if (selectedIndex === -1) {
      this.handleSelectItem(item)
    } else {
      this.handleRemoveItem(selectedIndex)
    }
  }

  handleToggleMenu = () => {
    this.setState(state => ({
      isOpen: !state.isOpen,
    }))
  }

  handleOuterClick = () => {
    this.setState({
      isOpen: false,
    })
  }

  handleStateChange = changes => {
    const { type } = changes
    switch (type) {
      case Downshift.stateChangeTypes.keyDownEscape:
      case Downshift.stateChangeTypes.mouseUp:
        this.setState({ isOpen: false })
        break
      // Opt-in to some cases where we should be toggling the menu based on
      // a given key press or mouse handler
      // Reference: https://github.com/paypal/downshift/issues/206
      case Downshift.stateChangeTypes.clickButton:
      case Downshift.stateChangeTypes.keyDownSpaceButton:
        this.handleToggleMenu()
        break
      default:
    }
  }

  render() {
    const { isOpen, selectedItems } = this.state
    const {
      className: containerClassName,
      disabled: isDisabled,
      icon,
      id,
      isInvalid,
      items,
      nowrap,
      placeholder,
    } = this.props

    const itemToString = item => (typeof item === 'string' ? item : (item && item.label) || '')
    const selectedItemsToLabel = selectedItems => selectedItems.map(itemToString).join(', ')

    return (
      <Downshift
        isOpen={isOpen}
        itemToString={itemToString}
        onChange={this.handleItemChange}
        onStateChange={this.handleStateChange}
        onOuterClick={this.handleOuterClick}
        selectedItem={selectedItems}
        render={({
          getButtonProps,
          getItemProps,
          getRootProps,
          highlightedIndex,
          isOpen,
          itemToString,
          selectedItem: selectedItems, // Downshift misnomer when there can be multiple selected items
        }) => (
          <ListBox
            className={containerClassName}
            id={id}
            isDisabled={isDisabled}
            {...getRootProps({ id, refKey: 'setRef' })}
          >
            <ListBox.Field
              isDisabled={isDisabled}
              isInvalid={isInvalid}
              isSelected={selectedItems.length > 0}
              {...getButtonProps({ disabled: isDisabled })}
            >
              {selectedItems.length > 0 && (
                <ListBox.Selection
                  clearSelection={this.handleClearSelection}
                  isDisabled={isDisabled}
                  selectionCount={selectedItems.length}
                />
              )}
              <ListBox.Icon name={icon} />
              <ListBox.Label
                isDisabled={isDisabled}
                isSelectedItem={selectedItems.length > 0}
                text={selectedItems.length > 0 ? selectedItemsToLabel(selectedItems) : placeholder}
              />
              <ListBox.MenuIcon isDisabled={isDisabled} isOpen={isOpen} />
            </ListBox.Field>
            {isOpen && (
              <ListBox.Menu nowrap={nowrap}>
                {items.map((item, index) => {
                  const itemProps = getItemProps({ item })
                  const itemText = itemToString(item)
                  const isChecked =
                    selectedItems.findIndex(selectedItem => selectedItem.id === item.id) !== -1
                  const isHighlighted = highlightedIndex === index
                  return (
                    <ListBox.MenuItem
                      key={itemProps.id}
                      isActive={isChecked}
                      isHighlighted={isHighlighted}
                      {...itemProps}
                    >
                      <Checkbox
                        checked={isChecked}
                        dropdownStyle
                        dropdownItemIsHighlighted={isHighlighted}
                        id={itemProps.id}
                        labelText={itemText}
                        readOnly
                        tabIndex="-1"
                      />
                    </ListBox.MenuItem>
                  )
                })}
              </ListBox.Menu>
            )}
          </ListBox>
        )}
      />
    )
  }
}

export default MultiSelect
