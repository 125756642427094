// The following was forked from https://github.com/react-component/tree-select

import React from 'react'

import SelectNode from './SelectNode'

// When treeNode is not provided `key`, use this instead.
const EMPTY_KEY_VALUE = 'TREE_SELECT_EMPTY_KEY'

export const toArray = data => {
  if (data) {
    return Array.isArray(data) ? data : [data]
  }
  return []
}

/**
 * Convert position list to hierarchy structure.
 * This is a little hacky since we use '-' to split the position.
 */
export function flatToHierarchy(positionList) {
  if (!positionList.length) {
    return []
  }

  const entrances = {}

  // Prepare the position map
  const posMap = {}
  const parsedList = positionList.slice().map(entity => {
    const clone = {
      ...entity,
      fields: entity.pos.split('-'),
    }
    delete clone.children
    return clone
  })

  parsedList.forEach(entity => {
    posMap[entity.pos] = entity
  })

  parsedList.sort((a, b) => {
    return a.fields.length - b.fields.length
  })

  // Create the hierarchy
  parsedList.forEach(entity => {
    const parentPos = entity.fields.slice(0, -1).join('-')
    const parentEntity = posMap[parentPos]

    if (!parentEntity) {
      entrances[entity.pos] = entity
    } else {
      parentEntity.children = parentEntity.children || []
      parentEntity.children.push(entity)
    }

    // Sometimes position list provides `key`, we don't need it (?)
    delete entity.key
    delete entity.fields
  })

  return Object.keys(entrances).map(key => entrances[key])
}

export function isLabelInValue(props) {
  const { treeCheckable, treeCheckStrictly, labelInValue } = props
  if (treeCheckable && treeCheckStrictly) {
    return true
  }
  return labelInValue || false
}

/**
 * Parses a flat input array into a structured tree
 * @param {any[]} treeData An array of tree values whose values should contain
 *                         the keys defined by the configuration passed as the
 *                         second param
 * @param {id: string, pId: string, rootPid?: string } param1 Configuration options for tree traversal
 */
export function parseSimpleTreeData(treeData, { id, pId, rootPId }) {
  const keyNodes = {}
  const rootNodeList = []

  // Fill in the map
  const nodeList = treeData.map(node => {
    const clone = { ...node }
    const key = clone[id]
    keyNodes[key] = clone
    return clone
  })

  // Connect tree
  nodeList.forEach(node => {
    const parentKey = node[pId]
    const parent = keyNodes[parentKey]

    // Fill parent
    if (parent) {
      parent.children = parent.children || []
      parent.children.push(node)
    }

    // Fill root tree node
    if (parentKey === rootPId || (!parent && rootPId === null)) {
      rootNodeList.push(node)
    }
  })

  return rootNodeList
}

export function convertTreeToData(treeNodes) {
  return React.Children.map(treeNodes || [], node => {
    if (!React.isValidElement(node) || !node.type || !node.type.isTreeNode) {
      return null
    }

    const { key, props } = node

    return {
      ...props,
      key,
      children: convertTreeToData(props.children),
    }
  }).filter(data => data)
}

/**
 * Convert `treeData` to TreeNode List and entity data.
 *
 * @export
 * @param {*} treeData The data for the currently-displaying tree (docs TODO)
 * @param {number[]} disabledKeys A list of disabled keys
 * @returns { treeNodes: SelectNode[], valueEntities: any[], keyEntities: [] }
 */
export function convertDataToEntities(treeData, { disabledKeys = [] }) {
  const list = toArray(treeData)

  const valueEntities = {}
  const keyEntities = {}
  const posEntities = {}

  function traverse(subTreeData, parentPos) {
    const subList = toArray(subTreeData)

    return subList.map(({ id, title, value, children, ...nodeProps }, index) => {
      const entityKey = !id && id !== 0 ? EMPTY_KEY_VALUE : `${id}` // store key as string
      const pos = `${parentPos}-${index}`
      const entity = { key: entityKey, value, pos }

      // Add entity to parent's children
      entity.parent = posEntities[parentPos]
      if (entity.parent) {
        entity.parent.children = entity.parent.children || []
        entity.parent.children.push(entity)

        function updateParentChildCount(parent) {
          // Child count includes all of an entity's children (including grandchildren, etc.)
          if (parent) {
            parent.childCount = parent.childCount || 0
            parent.childCount++
            updateParentChildCount(parent.parent)
          }
        }
        updateParentChildCount(entity.parent)

        // If the parent of this entity is disabled, this one should be also
        if (entity.parent.disabled) {
          entity.disabled = true
        }
      }

      entity.disabled = disabledKeys.includes(value)

      // Populate entity arrays
      keyEntities[entity.key] = entity
      valueEntities[value] = entity
      posEntities[pos] = entity

      const node = (
        <SelectNode
          {...nodeProps}
          key={entity.key}
          entity={entity}
          title={title}
          value={entity.value}
          disabled={entity.disabled}
        >
          {traverse(children, pos)}
        </SelectNode>
      )

      entity.node = node

      return node
    })
  }

  const treeNodes = traverse(list, '0')

  return {
    treeNodes,

    valueEntities,
    keyEntities,
    posEntities,
  }
}

/**
 * Get a filtered TreeNode list by provided treeNodes.
 */
export function getFilterTreeAndData(treeNodes, filterValue, filterFunc, nodeFilterProp) {
  if (filterValue) {
    const filteredKeys = []
    const keysToExpand = []

    // We pass in nodeKey becaues React.Children.map seems to mess with it
    function filterNodes(node, parentDidMatch) {
      if (node) {
        const isMatch = filterFunc(filterValue, node, nodeFilterProp)

        const { children, childrenContainsMatch } = node.props.children.reduce(
          (result, childNode) => {
            const { children, childrenContainsMatch } = filterNodes(childNode, isMatch)
            if (children) {
              result.children.push(children)
            }
            if (!result.childrenContainsMatch && childrenContainsMatch) {
              result.childrenContainsMatch = true
            }
            return result
          },
          { children: [], childrenContainsMatch: false }
        )

        if (children.length) {
          filteredKeys.push(node.key)

          if (childrenContainsMatch) {
            // We want to expand all the nodes up to a match (so expanded keys up to a match's parent node)
            // Therefore if the node has children...
            //  - and none of the children is a/contain matches, we DON'T want to expand that node
            //  - if one of the children is a/contains matches, we DO want to expand that node
            keysToExpand.push(node.key)
          }
          // Replace node's `children` prop with filtered children if the node's children contains filter matches
          return {
            children: React.cloneElement(node, { children }),
            childrenContainsMatch: childrenContainsMatch || isMatch,
          }
        }
        if (isMatch || parentDidMatch) {
          filteredKeys.push(node.key)
          // Keep node's children the same if that node is a match or if the parent is a filter match so we don't omit relevant node children
          return {
            children: node,
            childrenContainsMatch: isMatch,
          }
        }
        return {}
      }
      return {}
    }

    return {
      filteredTreeNodes: treeNodes
        .map(treeNode => filterNodes(treeNode))
        .reduce((result, { children }) => {
          if (children) {
            result.push(children)
          }
          return result
        }, []),
      filteredKeys,
      keysToExpand,
    }
  }
  return {
    filteredTreeNodes: [],
    filteredKeys: [],
    keysToExpand: [],
  }
}

/**
 * For checking a tree node, we can use the `rc-tree` `calcCheckStateConduct` function.
 * For unchecking, we need to calculate ourselves.
 */
export function calcUncheckConduct(keyList, uncheckedKey, keyEntities) {
  let myKeyList = keyList.slice()

  function conductUp(conductKey) {
    myKeyList = myKeyList.filter(key => key !== conductKey)

    // Check if we need to conduct up
    const parentEntity = keyEntities[conductKey].parent
    if (parentEntity && myKeyList.some(key => key === parentEntity.key)) {
      conductUp(parentEntity.key)
    }
  }

  function conductDown(conductKey) {
    myKeyList = myKeyList.filter(key => key !== conductKey)
    ;(keyEntities[conductKey].children || []).forEach(childEntity => {
      conductDown(childEntity.key)
    })
  }

  conductUp(uncheckedKey)
  conductDown(uncheckedKey)

  return myKeyList
}

/**
 * Determine what are the checked keys to send to the consumer component depending
 * on the chosen `showCheckedStrategy`
 */
export function formatValueList(checkedKeys, showCheckedStrategy, keyEntities) {
  const hierarchyList = flatToHierarchy(checkedKeys.map(key => keyEntities[key]))

  if (showCheckedStrategy === 'SHOW_PARENT') {
    // Only get the parent checked value
    return hierarchyList.map(
      ({
        node: {
          props: { value },
        },
      }) => value
    )
  }
  if (showCheckedStrategy === 'SHOW_CHILD') {
    // Only get the children checked value
    const targetValueList = []

    // Find the leaf children
    const traverse = ({
      node: {
        props: { value },
      },
      children,
    }) => {
      if (!children || children.length === 0) {
        targetValueList.push(value)
        return
      }

      children.forEach(entity => {
        traverse(entity)
      })
    }

    hierarchyList.forEach(entity => {
      traverse(entity)
    })

    return targetValueList
  }
  return checkedKeys
    .map(key => keyEntities[key])
    .map(
      ({
        node: {
          props: { value },
        },
      }) => value
    )
}
