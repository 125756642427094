import cx from 'classnames'
import { Formik } from 'formik'
import memoize from 'memoize-one'
import { cart as cartService } from '@instacart/enterprise-services'
import { utils as productDetailUtils } from '@instacart/enterprise-product-detail'
import React, { Fragment, memo, useCallback, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import debounce from 'lodash/debounce'

import {
  Button,
  DataTableWithState,
  DropdownFilterable,
  FormFieldItem,
  Modal,
  DropdownMenu,
  ButtonToggle,
  DropdownMenuItem,
  ButtonIconAction,
} from 'components'
import { getUserPermissionKeys } from 'modules/user'

import Styling from 'styling/components'
import { fetchProducts, validateCateringOrder } from 'api'
import { snakeToCamelCase } from 'utils'

import ProductsModal from './ProductsModal'

import styles from '../CreateOrder.styles'

const PAGE_ID = 'create-order-products-form'

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)
const mapStateToProps = state => ({
  userPermissions: getUserPermissionKeys(state),
})

const useStyles = createUseStyles(styles)

const productItemsTableHeaders = [
  {
    key: 'qty',
    header: 'QTY',
  },
  {
    key: 'name',
    header: 'Product Name',
  },
  {
    key: 'comments',
    header: 'Comments',
  },
]

const formatOrderRows = memoize(({ items, classes, openModal, onSelect, onRemove }) => {
  return items.map((item, index) => {
    const { id, quantity, comment, store_product, product_config } = item
    const productConfigOptions = product_config ? product_config.options : []
    const optionIds = productConfigOptions
      .map(option => {
        const { item, base, items, text, file } = option
        if (item) return item.id
        if (text) return text
        if (file) return file.id
        if (items && items.length) return items.map(item => item.id).join('')

        return base.id
      })
      .join('')

    const optionNames = productConfigOptions
      .map(option => {
        const { item, base, items, text, file } = option
        if (item) return item.name
        if (text) return text
        if (file) return file.name
        if (items && items.length) return items.map(item => item.name).join(', ')

        return base.name
      })
      .join(', ')

    return {
      id: `collection-product-${id}-${optionIds}`,
      rowAction: (
        <ButtonIconAction
          description="Remove Catering Item"
          icon="delete"
          onClick={() => onRemove(store_product, product_config)}
        />
      ),
      rowLinkTo: {
        onClick: () => onSelect(store_product, openModal, product_config),
      },
      isSelected: false,
      rowSortValues: {
        name: store_product.name,
        amountOfConfigurations: productConfigOptions.length,
      },
      qty: quantity,
      name: (
        <div className={classes.collectionItemRowProductCell}>
          <div className={classes.collectionItemRowProductCellProductText}>
            <p>
              {store_product.name} - {id}
            </p>
          </div>
        </div>
      ),
      comments: (
        <div className={classes.collectionItemRowProductCell}>
          <div className={classes.collectionItemRowProductCellProductText}>
            {!!productConfigOptions.length && (
              <>
                <strong>Configuration</strong>
                <p>{optionNames}</p>
              </>
            )}
            {!!comment && (
              <>
                {!!productConfigOptions.length && <br />}
                {!!productConfigOptions.length && <strong>Notes:</strong>}
                <p>{comment}</p>
              </>
            )}
          </div>
        </div>
      ),
    }
  })
})

const CreateOrderProduct = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  ({
    setTotals,
    selectedCustomer,
    selectedTimeslot,
    store_id,
    shopping_mode,
    userPermissions,
    isDisabled,
  }) => {
    const classes = useStyles()
    const [selectedItem, setSelectedItem] = useState({})
    const [productConfig, setProductConfig] = useState({})
    const [error, setError] = useState({})

    const [selectedProducts, setSelectedProducts] = useState([])
    const [products, setProducts] = useState([])

    const [formData, setformData] = useState(null)
    const [cartItem, setCartItem] = useState(null)
    const [itemNote, setItemNote] = useState(null)

    const [loading] = useState(false)

    const [itemTotal, setItemTotal] = useState(0)
    const [itemQuantity, setitemQuantity] = useState(0)

    const isPrepStyle =
      selectedItem.product_config && selectedItem.product_config.config_type === 'prep_style'

    const resetStates = () => {
      setitemQuantity(0)
      setItemTotal(0)

      setformData(null)
      setCartItem(null)
      setItemNote(null)

      setProductConfig({})
      setError({})
    }

    async function fetchSearchedProducts(term, storeId) {
      if (!term) return

      const params = {
        store_product: true,
        shopping_mode,
        store_id: storeId,
      }

      const response = await fetchProducts(`?search=${term}`, { params })
      const items = response && response.items ? response.items : []
      setProducts(items)
    }

    const getPhoneNumber = customer => {
      if (!customer) return

      const primaryKey = snakeToCamelCase(customer.primaryNumberKey)
      return customer[primaryKey]
    }

    const validateOrder = useCallback(() => {
      const customer = selectedCustomer || {}
      const timeslot = selectedTimeslot || {}
      const data = {
        customer_id: customer.id,
        store_id,
        fulfillment_type: 'pickup',
        items: cartService.selectors.getItems(),
        timeslot: timeslot.timeslot,
        fulfillment_date: timeslot.fulfillmentDate,
        contact_info: {
          first_name: customer.firstName,
          last_name: customer.lastName,
          phone_number: getPhoneNumber(selectedCustomer),
        },
      }

      return validateCateringOrder(data)
    }, [selectedCustomer, selectedTimeslot, store_id])

    const setCateringTotal = useCallback(
      data => {
        const { catering } = data.carts

        if (!catering) return setTotals([])

        return setTotals([
          { label: 'Subtotal', value: catering.pre_discount_product_total },
          { label: 'Tax', value: catering.tax_total },
          { label: 'Total', value: catering.total },
        ])
      },
      [setTotals]
    )

    const revalidateOrder = useCallback(
      () =>
        validateOrder()
          .then(response => {
            setCateringTotal(response)
          })
          .catch(error => {
            setTotals([])
          }),
      [setCateringTotal, validateOrder, setTotals]
    )

    useEffect(() => {
      revalidateOrder()
    }, [revalidateOrder])

    const debouncedSearchTerm = useCallback(
      debounce((term, storeId) => fetchSearchedProducts(term, storeId), 500),
      []
    )

    const onNoteChange = useCallback(
      debounce(note => setItemNote(note), 200), // TODO: Should this be used for onNoteChange?
      []
    )

    const refreshDataTable = () =>
      setSelectedProducts(cartService.selectors.getItems().filter(item => item.quantity))

    // Configurable Products function that fires on form change
    const onFormChange = useCallback(form => {
      setformData(form.data)
      setItemTotal(form.total)
      setError({ status: form.error, message: form.errorMessage })
    }, [])

    const onCreate = () => {
      // add item to cart
      productDetailUtils.addItemToCart(selectedItem, formData, itemQuantity)

      // Grab the updated item that has just been added to the cart
      const shoppingListItem = cartService.selectors.getItem(selectedItem, formData)

      cartService.actions.updateItemComment(shoppingListItem, itemNote)

      resetStates()
    }

    // Fire off a debounced search
    const onInputChange = term => {
      debouncedSearchTerm(term, store_id)
    }

    // Open Modal on select
    const onSelect = (selectedItem, openModal, productConfig = {}) => {
      const selectedCartItem = cartService.selectors.getItem(
        selectedItem,
        productConfig && productConfig.options
      )
      setSelectedItem(selectedItem)
      setCartItem(selectedCartItem)
      onNoteChange(selectedCartItem && selectedCartItem.comment)
      setitemQuantity(selectedCartItem ? selectedCartItem.quantity : 1)
      openModal()
    }

    // Open Modal on select
    const onRemove = (selectedItem, productConfig = {}) => {
      cartService.actions.updateItemQuantity(
        selectedItem,
        0,
        productConfig && productConfig.options
      )
      refreshDataTable()
      setSelectedItem({})
      setProductConfig({})
      revalidateOrder()
    }

    useEffect(() => {
      debouncedSearchTerm()
    }, [debouncedSearchTerm])

    useEffect(() => {
      cartService.actions.initializeCartLocal()
    }, [])

    return (
      <Modal
        size="small"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        render={() => (
          <ProductsModal
            onQuantityChange={setitemQuantity}
            onFormChange={onFormChange}
            onNoteChange={onNoteChange}
            product={selectedItem}
            cartItem={cartItem}
            itemTotal={itemTotal}
            isPrepStyle={isPrepStyle}
          />
        )}
        renderStickyFooter={({ closeModal }) => (
          <Styling.LineOfButtons withoutTopMargin>
            <Button
              disabled={error && error.status}
              onClick={() => {
                if (!isPrepStyle) onCreate()

                refreshDataTable()
                revalidateOrder()
                closeModal()
              }}
            >
              {isPrepStyle ? 'Update' : 'Add / Edit'}
            </Button>
            {!isPrepStyle && (
              <Button
                kind="link"
                onClick={() => {
                  resetStates()
                  revalidateOrder()
                  closeModal()
                }}
              >
                Cancel
              </Button>
            )}
          </Styling.LineOfButtons>
        )}
        triggerRender={({ openModal }) => (
          <Fragment>
            <DropdownFilterable
              className={classes.productInput}
              id="select-product"
              isLoading={loading}
              // Items can be empty by default since search is required
              items={[]}
              filteredItems={
                products
                  ? products.map(product => ({
                      id: product.id,
                      value: product,
                      label: product.name,
                    }))
                  : []
              }
              onInputChange={onInputChange}
              onSelect={selectedItem => onSelect(selectedItem, openModal)}
              placeholder="Start typing the product name"
              disabled={isDisabled}
            />
            <FormFieldItem
              className={classes.productInput}
              id={`${PAGE_ID}-edit-products`}
              name="products"
              render={({ id, name, onBlur, value, ...renderProps }) => {
                return (
                  <DataTableWithState
                    emptyMsg="No products added, the products you add will show up here"
                    headers={productItemsTableHeaders}
                    id={`${PAGE_ID}-table`}
                    kind="default"
                    rows={formatOrderRows({
                      items: selectedProducts,
                      classes,
                      openModal,
                      onSelect,
                      onRemove,
                    })}
                    renderProps={{
                      filterInputId: `${PAGE_ID}-filter`,
                      filterInputPlaceholder: 'Filter products by name',
                      items: selectedProducts,
                    }}
                  />
                )
              }}
            />
          </Fragment>
        )}
      />
    )
  }
)

export default memo(CreateOrderProduct)
