import { combineReducers } from 'redux'

import general from './general'
import navigation from './navigation'
import toasts from './toasts'
import user from './user'

export default combineReducers({
  general,
  navigation,
  toasts,
  user,
})
