import { mixins } from 'styling'

export default theme => ({
  listBoxSelection: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    height: 24,
    padding: theme.spacing.xxs,
    userSelect: 'none',
  },
  listBoxSelectionDisabled: {
    cursor: 'not-allowed',
  },
  listBoxSelectionMulti: {
    background: theme.brand01,
    borderRadius: 3,
    color: theme.inverse01,
    fontSize: 13,
    fontWeight: 600,
    marginRight: theme.spacing.xxs,

    '&$listBoxSelectionDisabled': {
      background: theme.text02,
    },

    '&:focus': {
      ...mixins.focusOutline({ type: 'blurred', theme }),
    },
    '&$listBoxSelectionDisabled:focus': {
      outline: 'none',
    },

    '& $listBoxSelectionClearIcon': {
      fill: theme.inverse01,
      marginLeft: theme.spacing.xs,
    },
  },
  listBoxSelectionClearIcon: {
    fill: theme.text02,
    height: 10,
    width: 10,
  },
})
