import React from 'react'

import { PageHeader } from 'components'
import { createToast } from 'modules/toasts'

import { getBackButton, getRouteTo } from 'routing'

import { createSubscription } from 'api'

import SubscriptionForm from './SubscriptionForm'

const CreateSubscription = props => {
  const {
    history,
    location: { state: locationState = {} },
  } = props

  const backButton = locationState.backButton || getBackButton('segments')

  const handleSubmit = (values, form) => {
    createSubscription(values)
      .then(() => {
        form.setSubmitting(false)
        createToast({ kind: 'success', message: 'Successfully created subscription.' })

        // Send the user to the Segments table page where they can view their new segment
        history.push(getRouteTo('subscriptions'))
      })
      .catch(error => {
        form.setSubmitting(false)
        form.setErrors({ global: error.message })
      })
  }

  return (
    <>
      <PageHeader backButton={backButton} hasDivider headerTitle="Create New Subscription" />
      <SubscriptionForm
        handleSubmit={handleSubmit}
        initialValues={{
          name: '',
          description: '',
          plu: '',
          days: 0,
          price: '',
          waiving_fee_types: ['pickup_fee'],
        }}
        backButton={backButton}
      />
    </>
  )
}

export default CreateSubscription
