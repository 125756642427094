import React from 'react'
import injectSheet from 'react-jss'
import { NumberValue } from 'react-values'
import { Link } from 'react-router-dom'

import { ReadOnlyValueCard, Tabs, Tab, Icon } from 'components'
import { reorder } from 'components/DataTable/utils/reorder'

import { formatDatetime } from 'utils'

import { KeywordBrands, KeywordCategories, KeywordTags, DisabledFeatureWarning } from './components'

import styles from './KeywordRuleDetail.styles'

const BASE_ID = 'create-keyword-rule'

const KeywordRuleDetailForm = ({
  classes,
  handleSubmit,
  initialValues,
  setFieldValue,
  values,
  keywordType,
  globalSettings,
}) => {
  const detailValues = [
    {
      id: `${BASE_ID}-detail-keyword`,
      labelText: 'Keyword',
      value: values.name || 'n/a',
    },
    {
      id: `${BASE_ID}-detail-type`,
      labelText: 'Rule',
      value: `${keywordType[0].toUpperCase() + keywordType.substring(1)} Search`,
    },
    {
      id: `${BASE_ID}-detail-excluded-count`,
      labelText: 'Excluded Categories',
      value: values && values.excludeCategories ? values.excludeCategories.length : 0,
    },
    {
      id: `${BASE_ID}-detail-boosted-count`,
      labelText: 'Boosted Categories',
      value: values && values.boostCategories ? values.boostCategories.length : 0,
    },
  ]

  // Depending on the keyword type, either display tag or brand boosting
  if (keywordType === 'recipe') {
    detailValues.push({
      id: `${BASE_ID}-detail-boosted-tags`,
      labelText: 'Boosted Tags',
      value: values && values.boostTags ? values.boostTags.length : 0,
    })
  } else {
    detailValues.push({
      id: `${BASE_ID}-detail-boosted-brands`,
      labelText: 'Boosted Brands',
      value: values && values.boostBrands ? values.boostBrands.length : 0,
    })
  }

  // Show metadata
  detailValues.push(
    {
      id: `${BASE_ID}-detail-created`,
      labelText: 'Created On',
      value: formatDatetime(values.created) || 'n/a',
    },
    {
      id: `${BASE_ID}-detail-updated-on`,
      labelText: 'Last Edited',
      value: formatDatetime(values.modified) || 'n/a',
    }
  )

  const globalSettingsPath =
    keywordType === 'recipe'
      ? '/marketing/search-settings/global-recipe-search-settings'
      : '/marketing/search-settings/global-search-settings'

  return (
    <form className={classes.formContainer} onSubmit={handleSubmit}>
      <div className={classes.contextExplainerText}>
        <Icon name="info" className={classes.contextExplainerIcon} />
        You are setting up {keywordType} search result rules for this keyword
      </div>
      <div className={classes.underHeaderContainer}>
        <div className={classes.leftSide}>
          <ReadOnlyValueCard detailValues={detailValues} noPaddingTop />
        </div>
        <div className={classes.rightSide}>
          <NumberValue defaultValue={0}>
            {({ value: selectedTab, set: selectTab }) => {
              const tabs = [
                <Tab label="Excluded Categories" key="excluded_categories">
                  <KeywordCategories
                    componentId={`${BASE_ID}-excluded`}
                    componentName="excludeCategories"
                    setFieldValue={setFieldValue}
                    onChange={handleSubmit}
                    disabledCategories={values.boostCategories.map(({ id }) => id)}
                    disabledCategoriesText="Boosted"
                    confirmClearAll
                    modalTip="You cannot exclude a 'Boosted' category."
                    keywordType={keywordType}
                  />
                </Tab>,
                <Tab label="Boosted Categories" key="boosted_categories">
                  {!globalSettings.enableBoostCategories && (
                    <div className={classes.header}>
                      <DisabledFeatureWarning
                        enablingLink={
                          <>
                            Please enable this feature in{' '}
                            <Link to={globalSettingsPath}>global settings.</Link>
                          </>
                        }
                      />
                    </div>
                  )}
                  <KeywordCategories
                    componentId={`${BASE_ID}-boosted`}
                    componentName="boostCategories"
                    setFieldValue={setFieldValue}
                    onChange={handleSubmit}
                    disabledCategories={values.excludeCategories.map(({ id }) => id)}
                    disabledCategoriesText="Excluded"
                    disabled={!globalSettings.enableBoostCategories}
                    draggable
                    onDragEnd={({ source, destination }) => {
                      if (!source || !destination) return
                      const reordered = reorder(
                        values.boostCategories,
                        source.index,
                        destination.index
                      )
                      setFieldValue('boostCategories', reordered)
                      handleSubmit()
                    }}
                    limit={3}
                    confirmClearAll
                    modalTip={
                      <ul className={classes.notificationGuidanceList}>
                        <li>You can select up to 3 categories for boosting.</li>
                        <li>You cannot boost an 'Excluded' category.</li>
                      </ul>
                    }
                    keywordType={keywordType}
                  />
                </Tab>,
              ]

              if (keywordType === 'recipe') {
                tabs.push(
                  <Tab label="Boosted Tags" key="boosted_tags">
                    {!globalSettings.enableBoostTags && (
                      <div className={classes.header}>
                        <DisabledFeatureWarning
                          enablingLink={
                            <>
                              Please enable this feature in{' '}
                              <Link to={globalSettingsPath}>global settings.</Link>
                            </>
                          }
                        />
                      </div>
                    )}
                    <KeywordTags
                      componentId={`${BASE_ID}-boosted-tags`}
                      componentName="boostTags"
                      disabled={!globalSettings.enableBoostTags}
                      setFieldValue={setFieldValue}
                      keyword={values.name}
                      handleSubmit={handleSubmit}
                      values={values}
                      limit={3}
                      modalTip="You can select up to 3 tags to boost"
                      draggable
                      confirmClearAll
                    />
                  </Tab>
                )
              } else {
                tabs.push(
                  <Tab label="Boosted Brands" key="boosted_brands">
                    {!globalSettings.enableBoostBrands && (
                      <div className={classes.header}>
                        <DisabledFeatureWarning
                          enablingLink={
                            <>
                              Please enable this feature in{' '}
                              <Link to={globalSettingsPath}>global settings.</Link>
                            </>
                          }
                        />
                      </div>
                    )}
                    <KeywordBrands
                      componentId={`${BASE_ID}-boosted-brands`}
                      componentName="boostBrands"
                      disabled={!globalSettings.enableBoostBrands}
                      setFieldValue={setFieldValue}
                      keyword={values.name}
                      handleSubmit={handleSubmit}
                      values={values}
                      limit={3}
                      modalTip="You can select up to 3 brands to boost"
                      draggable
                      confirmClearAll
                    />
                  </Tab>
                )
              }

              return (
                <Tabs
                  className={classes.rightSide}
                  selectedTab={selectedTab}
                  onSelectTab={selectTab}
                >
                  {tabs}
                </Tabs>
              )
            }}
          </NumberValue>
        </div>
      </div>
    </form>
  )
}

export default injectSheet(styles)(KeywordRuleDetailForm)
