import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

import { useItemFetcher } from './itemFetcherHook'

/**
 * Fetches an item on mount with function prop `fetchItem`, query key `queryKey`, query params
 * for caching `queryParams` and allows consuming component to access the following props
 * through the `render` function prop:
 * - `item`, `isLoadingItem` (fetched item data and fetch status)
 * - `error` (if the request results in an error response, the response is accessible under `error`)
 * - `refetch` (to trigger a new call to `fetchItem`)
 */
const ItemFetcher = ({ queryKey, queryParams, fetchItem, render }) => {
  const { item, error, refetch, isLoadingItem } = useItemFetcher(queryKey, fetchItem, queryParams, {
    excludeParams: true,
  })

  const toRender = useMemo(
    () =>
      render({
        error,
        isLoadingItem,
        item,
        refetch,
      }) || null,
    [render, error, isLoadingItem, item, refetch]
  )

  return toRender
}

ItemFetcher.propTypes = {
  queryKey: PropTypes.string.isRequired,
  queryParams: PropTypes.object,
  fetchItem: PropTypes.func.isRequired,
  render: PropTypes.func.isRequired,
}

export default ItemFetcher
