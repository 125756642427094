import { mixins } from 'styling'

export default theme => ({
  radioButtonWrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  radioButton: {
    ...mixins.hidden,
  },
  radioButtonLabelContainer: {
    fontSize: theme.fontSize.p,
    alignItems: 'center',
    color: theme.text01,
    cursor: 'pointer',
    display: 'flex',
    fontFamily: theme.fontFamilySansSerif,
    lineHeight: theme.baseLineHeight,
    minHeight: 16,

    // Style Radio Button circle when input is focused
    '$radioButton:focus + & > $radioButtonCircle': {
      boxShadow: `0 0 0 3px ${theme.brand03}`,
    },
    // Style Radio Button circle when input is checked
    '$radioButton:checked + & > $radioButtonCircle': {
      border: `5px solid ${theme.brand00}`,
    },
  },
  radioButtonDisabledLabelContainer: {
    color: theme.text02,
    cursor: 'not-allowed',

    '& > $radioButtonCircle': {
      '&, &:hover': {
        backgroundColor: theme.bgGreyExtraLight,
        borderColor: theme.borderGreyLight,
      },
      // Style Disabled Radio Button circle when input is checked (probably shouldn't happen but just in case)
      '$radioButton:checked + &': {
        borderColor: theme.borderGreyLight,
      },
    },
  },
  radioButtonCircle: {
    // Radio Button circle
    backgroundColor: '#FFFFFF',
    border: `1px solid ${theme.text02}`,
    borderRadius: '50%',
    flexShrink: 0,
    height: 16,
    marginRight: theme.spacing.xs,
    width: 16,

    // On button circle hover
    '$radioButtonLabelContainer:not($radioButtonDisabledLabelContainer):hover &': {
      boxShadow: `0 0 0 3px ${theme.brand03}`,
    },
  },
  radioButtonTooltipIcon: {
    height: 16,
    width: 12,
    marginLeft: 5,
    marginTop: 5,
  },
})
