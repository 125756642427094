import React from 'react'
import Highlighter from 'react-highlight-words'
import injectSheet from 'react-jss'

import { fetchEnabledStores } from 'api'
import { AddItems, Button, FormFieldItem, Notification, TextInput } from 'components'
import Styling from 'styling/components'

const styles = theme => ({
  formContainer: {
    width: 610,
  },
  nameInput: {
    width: 290,
  },
  dropdownMenuItem: {
    display: 'flex',
    lineHeight: 1.5,
    minHeight: 20,
    width: '100%',
  },
  dropdownMenuItemLeft: {
    flexBasis: 160,
    flexShrink: 0,
  },
  selectedStore: {
    alignItems: 'center',
    display: 'flex',
    width: '100%',
  },
  selectedStoreID: {
    flexBasis: '30%',
    flexShrink: 0,
    marginRight: theme.spacing.lg,
  },
  selectedStoreName: {
    flexBasis: '70%',
    flexShrink: 1,
  },
})

const BASE_ID = 'create-store-group'

const StoreGroupForm = ({
  cancelDestination,
  classes,
  values,
  errors,
  handleSubmit,
  isSubmitting,
  setFieldValue,
}) => (
  <form className={classes.formContainer} onSubmit={handleSubmit}>
    <FormFieldItem
      id={`${BASE_ID}-name`}
      labelText="Store Group Name"
      name="name"
      render={renderProps => (
        <TextInput
          {...renderProps}
          autoFocus
          className={classes.nameInput}
          placeholder="Enter Store Group Name"
          type="text"
        />
      )}
    />

    <AddItems
      autocompleteLabelKey="label"
      autocompleteRenderMenuItem={({ item: store, inputValue }) => (
        <div className={classes.dropdownMenuItem}>
          <Highlighter
            className={classes.dropdownMenuItemLeft}
            searchWords={[inputValue]}
            autoEscape
            textToHighlight={`Store #${store.extId}`}
          />
          <Highlighter searchWords={[inputValue]} autoEscape textToHighlight={store.name} />
        </div>
      )}
      errorMsg={errors.stores}
      fetchItems={config =>
        fetchEnabledStores(config).then(({ items: stores, itemCount }) => ({
          // Add `label` for Autocomplete to use for filtering
          items: stores.map(store => ({
            ...store,
            label: `Store #${store.extId} ${store.name}`,
          })),
          itemCount,
        }))
      }
      id={`${BASE_ID}-stores`}
      itemName="store"
      labelText="Stores"
      modalProps={{
        formatTableRows: stores =>
          stores.map(({ id, extId, name }) => ({
            id: `${id}`,
            resourceName: 'store',
            storeId: `Store #${extId}`,
            name,
          })),
        tableHeaders: [
          {
            key: 'storeId',
            header: 'Store Id',
          },
          {
            key: 'name',
            header: 'Store Name',
          },
        ],
      }}
      onChange={newSelectedStores => {
        setFieldValue('stores', newSelectedStores)
      }}
      selectedItems={values.stores}
      selectedItemsRenderItem={({ item: store }) => (
        <div className={classes.selectedStore}>
          <div className={classes.selectedStoreID}>Store #{store.extId}</div>
          <div className={classes.selectedStoreName}>{store.name}</div>
        </div>
      )}
    />

    {!isSubmitting && errors.global && <Notification kind="error" message={errors.global} />}
    <Styling.LineOfButtons>
      <Button disabled={isSubmitting} id={`${BASE_ID}-save`} type="submit">
        Save
      </Button>
      <Button kind="link" href={cancelDestination}>
        Cancel
      </Button>
    </Styling.LineOfButtons>
  </form>
)

export default injectSheet(styles)(StoreGroupForm)
