import { mixins } from 'styling'

export default theme => ({
  sideNav: {
    background: theme.bgGreyExtraLight,
    fontFamily: theme.fontFamilySansSerif,
    height: '100%', // TODO: use flex?
    padding: '32px 20px', // TODO: use flex?
    width: 200,
  },
  heading: {
    fontSize: theme.fontSize.small,
    color: theme.text01,
    fontWeight: 700,
    lineHeight: '20px',
    padding: `${theme.spacing.xs} 0`,
    textTransform: 'uppercase',
  },
  navItem: {},
  subheading: {
    textTransform: 'uppercase',
    fontWeight: 700,
    color: theme.text01,
    display: 'flex',
    textAlign: 'left',
    textDecoration: 'none',
    fontSize: theme.fontSize.p,
    lineHeight: '20px',
    padding: `${theme.spacing.xxs} ${theme.spacing.md}`,
  },
  link: {
    fontSize: theme.fontSize.body,
    color: theme.text01,
    display: 'flex',
    lineHeight: '20px',
    padding: `${theme.spacing.xs} 0`,
    textAlign: 'left',
    textDecoration: 'none',

    '&:hover': {
      color: theme.brand01,
      textDecoration: 'none',

      '& $linkIcon': {
        fill: theme.brand01,
      },
    },
  },
  linkForV1: {
    ...mixins.resets.button,
  },
  linkIcon: {
    fill: theme.text01,
  },
  linkActive: {
    color: theme.brand01,
    fontWeight: 600,

    '& $linkIcon': {
      fill: theme.brand01,
    },
  },
  childLink: {
    fontSize: theme.fontSize.p,
    lineHeight: '20px',
    padding: `${theme.spacing.xxs} ${theme.spacing.md}`,

    '&:hover': {
      background: '#FFFFFF',
    },
  },
})
