import { FieldArray } from 'formik'
import React, { Component, Fragment } from 'react'
import injectSheet from 'react-jss'

import { fetchPermissionDepartments } from 'api'
import {
  Button,
  Checkbox,
  FormItem,
  FormFieldItem,
  FormLabel,
  LoadingIndicator,
  Notification,
  RadioButtons,
  TextInput,
} from 'components'
import { Table, TableCell, TableRow } from 'components/DataTable/components'
import Styling from 'styling/components'
import { ItemsFetcher } from 'utils'
import config from 'config'

const isPermissionSectionHidden = section =>
  // Don't hide any permission sections yet. This function can be used to hide them in the future.
  false

const isPermissionGroupBundleHidden = (section, bundle) =>
  // If OneSearch is enabled, hide all 'Search' permission group bundles except for 'Search Keywords Redirects'.
  config.enableICSearch &&
  section.label === 'Search' &&
  !bundle.label.includes('Keywords Redirects')

const styles = theme => ({
  formContainer: {
    width: 794,
  },
  nameInput: {
    width: 354,
  },
  bundlePermissionContainer: {
    display: 'flex',
    borderTop: `1px solid ${theme.borderGreyExtraLight}`,
    borderBottom: `1px solid ${theme.borderGreyExtraLight}`,
    padding: theme.spacing.xs,
  },
  bundlePermissionLabel: {
    width: 270,
  },
  bundlePermissionRadioButtons: {
    width: 220,
  },
  sectionContainer: {
    display: 'flex',
  },
  sectionCheckbox: {
    width: 30,
  },
  sectionLabel: {},
})

const PAGE_ID = 'create-role'

const normalize = data => {
  const sectionsById = {}

  data.forEach(section => {
    const bundlesById = {}

    section.permission_group_bundles.forEach(bundle => {
      bundlesById[bundle.id] = bundle
    })

    const normalizedSection = { ...section, permission_group_bundles: bundlesById }
    sectionsById[section.id] = normalizedSection
  })

  return sectionsById
}

const getBundleIds = data => {
  const bundleIdsBySectionIds = {}

  data.forEach(section => {
    const bundleIds = section.permission_group_bundles.map(bundle => bundle.id)

    bundleIdsBySectionIds[section.id] = bundleIds
  })

  return bundleIdsBySectionIds
}

class PermissionsTable extends Component {
  state = {}

  static getDerivedStateFromProps(props, state) {
    const selectedBundleIdsBySectionId = {}

    const sections = props.formikProps.values.permission_departments
    if (sections) {
      for (const section of sections) {
        for (const bundle of section.permission_group_bundles) {
          if (selectedBundleIdsBySectionId.hasOwnProperty(section.id)) {
            selectedBundleIdsBySectionId[section.id].push(bundle.id)
          } else selectedBundleIdsBySectionId[section.id] = [bundle.id]
        }
      }
    }

    // On initial render or any subsequent changes to `data` prop
    if (!state.lastRowsProp || props.data !== state.lastRowsProp) {
      const sectionsById = props.data ? normalize(props.data) : {}
      const bundleIdsBySectionId = props.data ? getBundleIds(props.data) : {}

      const newState = {
        sectionsById,
        bundleIdsBySectionId,
        selectedBundleIdsBySectionId,
        lastRowsProp: props.data,
      }

      return newState
    }
    return null
  }

  generateOnExpandSection = sectionId => () => {
    this.setState(state => {
      const section = state.sectionsById[sectionId]
      return {
        sectionsById: {
          ...state.sectionsById,
          [sectionId]: {
            ...section,
            isExpanded: !section.isExpanded,
          },
        },
      }
    })
  }

  addToSelectedBundles = (state, sectionId, bundleId) => {
    const { selectedBundleIdsBySectionId } = this.state

    if (selectedBundleIdsBySectionId.hasOwnProperty(sectionId)) {
      return {
        selectedBundleIdsBySectionId: {
          ...state.selectedBundleIdsBySectionId,
          [sectionId]: [...state.selectedBundleIdsBySectionId[sectionId], bundleId],
        },
      }
    }
    return {
      selectedBundleIdsBySectionId: {
        ...state.selectedBundleIdsBySectionId,
        [sectionId]: [bundleId],
      },
    }
  }

  removeFromSelectedBundles = (state, sectionId, bundleId) => {
    return {
      selectedBundleIdsBySectionId: {
        ...state.selectedBundleIdsBySectionId,
        [sectionId]: state.selectedBundleIdsBySectionId[sectionId].filter(
          bundle => bundle !== bundleId
        ),
      },
    }
  }

  generateOnSelectPermissionBundle = (bundleId, sectionId) => () => {
    const { sectionsById, selectedBundleIdsBySectionId } = this.state
    const {
      fieldArrayProps: { replace: replaceBundle },
      formikProps: { values },
    } = this.props

    const foundIndex = selectedBundleIdsBySectionId[sectionId]
      ? selectedBundleIdsBySectionId[sectionId].indexOf(bundleId)
      : -1
    if (foundIndex === -1) {
      this.setState(state => {
        return {
          ...this.addToSelectedBundles(state, sectionId, bundleId),
        }
      })
    } else {
      this.setState(state => {
        return {
          ...this.removeFromSelectedBundles(state, sectionId, bundleId),
        }
      })
    }

    // Update Formik/form state
    const wasSelected = !!values.permissionGroups[bundleId]
    const isSelected = !wasSelected
    if (isSelected) {
      const permissionGroups =
        sectionsById[sectionId].permission_group_bundles[bundleId].permission_groups
      const defaultPermGroupId =
        permissionGroups.length > 0
          ? permissionGroups.filter(permGroup => permGroup.access === 'read_only')[0].id
          : null
      replaceBundle(bundleId, { id: defaultPermGroupId })
    } else {
      replaceBundle(bundleId, null)
    }
  }

  handleSelectAll = sectionId => async () => {
    const {
      fieldArrayProps: { replace: replaceBundle },
    } = this.props
    const { selectedBundleIdsBySectionId, bundleIdsBySectionId, sectionsById } = this.state

    const previousSelectedBundlesLength = selectedBundleIdsBySectionId.hasOwnProperty(sectionId)
      ? selectedBundleIdsBySectionId[sectionId].length
      : 0
    const wasSelected = previousSelectedBundlesLength === bundleIdsBySectionId[sectionId].length
    const isSelected = !wasSelected

    if (isSelected) {
      for (const bundleId of bundleIdsBySectionId[sectionId]) {
        const foundIndex = selectedBundleIdsBySectionId[sectionId]
          ? selectedBundleIdsBySectionId[sectionId].indexOf(bundleId)
          : -1
        if (foundIndex === -1) {
          await this.setState(state => {
            return {
              ...this.addToSelectedBundles(state, sectionId, bundleId),
            }
          })
        }

        // Update Formik/form state
        const permissionGroups =
          sectionsById[sectionId].permission_group_bundles[bundleId].permission_groups
        const defaultPermGroupId =
          permissionGroups.length > 0
            ? permissionGroups.filter(permGroup => permGroup.access === 'read_only')[0].id
            : null

        await replaceBundle(bundleId, { id: defaultPermGroupId })
      }
    } else {
      for (const bundleId of bundleIdsBySectionId[sectionId]) {
        const foundIndex = selectedBundleIdsBySectionId[sectionId].indexOf(bundleId)
        if (foundIndex > -1) {
          await this.setState(state => {
            return {
              ...this.removeFromSelectedBundles(state, sectionId, bundleId),
            }
          })
        }

        // Update Formik/form state
        await replaceBundle(bundleId, null)
      }
    }
  }

  render() {
    const { classes, data, formikProps } = this.props
    const { setFieldValue } = formikProps
    const { bundleIdsBySectionId, sectionsById, selectedBundleIdsBySectionId } = this.state

    // Spacer column for the expand arrow
    const numLeftSpacerCols = 1
    const numColumns = 1 + numLeftSpacerCols
    return (
      <Table>
        <tbody>
          <Fragment>
            {data ? (
              data.map(section => {
                if (isPermissionSectionHidden(section)) return

                const { isExpanded } = sectionsById[section.id]

                // Checkbox props
                const selectedBundleIds = selectedBundleIdsBySectionId[section.id]
                const bundleIds = bundleIdsBySectionId[section.id]
                let isChecked = false
                let isIndeterminate = false

                if (selectedBundleIdsBySectionId.hasOwnProperty(section.id)) {
                  isChecked = bundleIds.length === selectedBundleIds.length
                  isIndeterminate =
                    selectedBundleIds.length > 0 && selectedBundleIds.length < bundleIds.length
                }
                return (
                  <TableRow
                    isExpanded={isExpanded}
                    key={section.id}
                    numColumns={numColumns}
                    onExpand={this.generateOnExpandSection(section.id)}
                    tableContainsExpandableRow
                    rowDetails={section.permission_group_bundles.map(bundle => {
                      if (isPermissionGroupBundleHidden(section, bundle)) return

                      const radioButtonProps = bundle.permission_groups
                        .map(permission => {
                          const permissionsLabel = permission.permissions
                            .map(p => p.label)
                            .sort()
                            .join(', ')
                          const isDisabled = selectedBundleIdsBySectionId.hasOwnProperty(section.id)
                            ? selectedBundleIds.indexOf(bundle.id) === -1
                            : true
                          return {
                            id: permission.access,
                            value: permission.id,
                            labelText: permissionsLabel,
                            disabled: isDisabled,
                          }
                        })
                        .sort((a, b) => (a.access > b.access ? -1 : 1)) // ensure read only permission groups are shown first
                        .filter(x => x.labelText) // filter out permission groups where there are no permissions present

                      // Checkbox props
                      const isChecked = selectedBundleIdsBySectionId.hasOwnProperty(section.id)
                        ? selectedBundleIds.indexOf(bundle.id) > -1
                        : false

                      return (
                        <div
                          key={`section-${section.id}-bundle-${bundle.id}`}
                          className={classes.bundlePermissionContainer}
                        >
                          <label
                            // TODO: add inline label to FormFieldItem
                            className={classes.bundlePermissionLabel}
                          >
                            <Checkbox
                              id={`${PAGE_ID}-${bundle.label}`}
                              labelText={bundle.label}
                              checked={isChecked}
                              onChange={this.generateOnSelectPermissionBundle(
                                bundle.id,
                                section.id
                              )}
                            />
                          </label>
                          <FormFieldItem
                            id={`${PAGE_ID}-bundle-${bundle.id}-radio`}
                            className={classes.bundlePermissionRadioButtons}
                            name={`permissionGroups[${bundle.id}].id`}
                            render={({ id, value }) => (
                              <RadioButtons
                                id={id}
                                orientation="horizontal"
                                radioButtonProps={radioButtonProps}
                                onChange={newPermGroup => {
                                  setFieldValue(`permissionGroups[${bundle.id}].id`, newPermGroup)
                                }}
                                selectedValue={value}
                              />
                            )}
                          />
                        </div>
                      )
                    })}
                  >
                    <TableCell key={section.id}>
                      <div className={classes.sectionContainer}>
                        <span
                          className={classes.sectionCheckbox}
                          onClick={event => {
                            // Ensure checkbox click doesn't expand the section
                            event.stopPropagation()
                          }}
                        >
                          <Checkbox
                            id={`${PAGE_ID}-${section.label}_select-all`}
                            labelText={section.label}
                            hideLabel
                            indeterminate={isIndeterminate}
                            onChange={this.handleSelectAll(section.id)}
                            checked={isChecked}
                          />
                        </span>
                        <label className={classes.sectionLabel}>{section.label}</label>
                      </div>
                    </TableCell>
                  </TableRow>
                )
              })
            ) : (
              <div />
            )}
          </Fragment>
        </tbody>
      </Table>
    )
  }
}

const RoleForm = ({ cancelDestination, classes, formikProps }) => {
  const { isSubmitting, errors, handleSubmit } = formikProps

  return (
    <form className={classes.formContainer} onSubmit={handleSubmit}>
      <FormFieldItem
        id={`${PAGE_ID}-name`}
        labelText="Name"
        name="name"
        render={renderProps => (
          <TextInput
            autoFocus
            {...renderProps}
            className={classes.nameInput}
            placeholder="Role Name"
            type="text"
          />
        )}
      />
      <FormLabel htmlFor={`${PAGE_ID}-permissions-table`} labelText="Permissions" />
      <FormItem>
        <FieldArray
          name="permissionGroups"
          render={fieldArrayProps => {
            return (
              <ItemsFetcher
                queryKey="permissionDepartments"
                fetchItems={config => fetchPermissionDepartments(config)}
                render={({
                  items: permissionDepartments,
                  isLoadingItems: isFetchingPermissionDepartments,
                  error: errorFetchingPermissionDepartments,
                  refetch: refetchPermissionDepartments,
                }) => {
                  if (isFetchingPermissionDepartments) {
                    return <LoadingIndicator />
                  }
                  return (
                    <PermissionsTable
                      classes={classes}
                      data={permissionDepartments}
                      id={`${PAGE_ID}-permissions-table`}
                      fieldArrayProps={fieldArrayProps}
                      formikProps={formikProps}
                    />
                  )
                }}
              />
            )
          }}
          validateOnChange={false}
        />
      </FormItem>

      {!isSubmitting && errors.global && <Notification kind="error" message={errors.global} />}
      <Styling.LineOfButtons>
        <Button disabled={isSubmitting} id={`${PAGE_ID}-save`} type="submit">
          Save
        </Button>
        <Button kind="link" href={cancelDestination}>
          Cancel
        </Button>
      </Styling.LineOfButtons>
    </form>
  )
}

export default injectSheet(styles)(RoleForm)
