import axios from 'axios'

export const downloadImageAsBase64String = async imageUrl => {
  const reader = new FileReader()
  const response = await axios.get(imageUrl, { responseType: 'blob' })

  reader.readAsDataURL(response)

  return new Promise(resolve => {
    reader.onload = () => {
      resolve(reader.result)
    }
  })
}
