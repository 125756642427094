import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import injectSheet from 'react-jss'

import { Tooltip } from 'components'
import { capitalize, isNil } from 'utils'

import styles from './StatusIndicator.styles'

let StatusIndicator = ({
  isActive,
  classes,
  className,
  compact,
  id,
  indicatorStyle,
  text,
  type,
}) => {
  const indicatorClasses = classNames(className, {
    [classes.statusIndicatorBadge]: indicatorStyle === 'badge',
    [classes.statusIndicatorDot]: indicatorStyle === 'dot',
    [classes.compact]: compact,
    [classes.statusIndicatorNew]: type === 'new',
    [classes.statusIndicatorInProgress]: type === 'in-progress',
    [classes.statusIndicatorComplete]:
      isActive === true || type === 'complete' || type === 'completed',
    [classes.statusIndicatorCancelled]:
      isActive === false || type === 'cancelled' || type === 'canceled',
    [classes.statusIndicatorError]: type === 'error',
  })

  const textToDisplay =
    text ||
    (!isNil(isActive)
      ? isActive
        ? 'Active'
        : 'Archived'
      : capitalize({ phrase: type || 'Unknown', separator: '-' }))

  return compact ? (
    <Tooltip text={textToDisplay}>
      <span id={id} className={indicatorClasses} aria-label={textToDisplay} />
    </Tooltip>
  ) : (
    <div id={id} className={indicatorClasses} {...(compact ? { 'aria-label': textToDisplay } : {})}>
      <span className={classes.statusIndicatorText}>{textToDisplay}</span>
    </div>
  )
}

const commonPropTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  /**
   * Pass this in if the status indicator only needs to show an active/inactive status.
   * Otherwise you must pass in `type` (and `text` if you want it to differ from `type`).
   */
  isActive: PropTypes.bool,
  /**
   * User-provided text to show inside the indicator badge or next to the indicator dot.
   * If `text` is not provided, the `type` is used for the text.
   */
  text: PropTypes.node,
  type: PropTypes.oneOf([
    'new',
    'in-progress',
    'complete',
    'completed',
    'cancelled',
    'canceled',
    'error',
  ]),
}

StatusIndicator.propTypes = {
  ...commonPropTypes,
  indicatorStyle: PropTypes.string.isRequired,
}

StatusIndicator.displayName = 'StatusIndicator'

StatusIndicator = injectSheet(styles)(StatusIndicator)

const StatusIndicatorBadge = props => <StatusIndicator indicatorStyle="badge" {...props} />

StatusIndicatorBadge.propTypes = commonPropTypes

const StatusIndicatorDot = props => <StatusIndicator indicatorStyle="dot" {...props} />

StatusIndicatorDot.propTypes = {
  ...commonPropTypes,
  /**
   * A "compact" status indicator means the text next to the dot is not displayed.
   * (It does, however, appear in a Tooltip on dot hover.)
   */
  compact: PropTypes.bool,
}

export { StatusIndicatorBadge, StatusIndicatorDot }
