import memoize from 'memoize-one'
import React from 'react'
import injectSheet from 'react-jss'

import { fetchKeywordRules, createKeywordRule } from 'api'
import { Modal } from 'components'
import { TableView } from 'layouts'
import { formatDatetime } from 'utils'

import { generateBackButton, getRoutePathname } from 'routing'
import { PageHeaderBanner } from 'components/PageHeaderBanner/PageHeaderBanner'

import { CreateKeywordRule } from './CreateKeywordRule'

// Table Configurations
const PAGE_ID = 'keywords'
const PAGE_TITLE = 'Product Search Keyword Rules'

const FETCH_FUNCTION = fetchKeywordRules

const styles = theme => ({
  form: {
    width: 450,
  },
})

const tableParams = {
  paging: {
    type: 'multi',
    multiParams: { limit: { key: 'limit' }, offset: { key: 'offset' } },
  },
  search: { key: 'search_term' },
  sorting: {
    type: 'multi',
    multiParams: { direction: { key: 'direction' }, orderBy: { key: 'order_by' } },
  },
}

const tableHeaders = [
  {
    key: 'name',
    header: 'Keyword',
    disableSorting: true,
  },
  {
    key: 'excludeCategories',
    header: 'Excluded Categories',
    disableSorting: true,
  },
  {
    key: 'boostCategories',
    header: 'Boosted Categories',
    disableSorting: true,
  },
  {
    key: 'boostBrands',
    header: 'Brand Boosting',
    disableSorting: true,
  },
  {
    key: 'modified',
    header: 'Last Edited',
  },
]

const formatTableRows = memoize(({ items, location }) =>
  items.map(keywordRules => {
    const { boostBrands, boostCategories, excludeCategories, id, name, modified } = keywordRules
    return {
      id: `keywordrule-${id}`,
      rowLinkTo: {
        pathname: getRoutePathname('searchSettings.keywordRules.editKeywordRule', { id }),
        state: { backButton: generateBackButton('products', location) },
      },
      name,
      excludeCategories: excludeCategories.length,
      boostBrands: boostBrands.length,
      boostCategories: boostCategories.length,
      modified: formatDatetime(modified),
    }
  })
)

const formatTableActions = (history, location) => ({ refetchItems }) => [
  {
    id: `${PAGE_ID}-create`,
    content: 'Create Keyword',
    action: 'modal',
    permission: 'search_rules.create',
    actionComponent: Modal,
    modalOptions: {
      handleCloseModal: returnValues => {
        // go to edit if name has been saved
        if (returnValues.name) {
          history.push({
            pathname: getRoutePathname('searchSettings.keywordRules.editKeywordRule', {
              id: returnValues.id,
            }),
            state: { backButton: generateBackButton('searchSettings.keywordRules', location) },
          })
        } else {
          history.push({
            pathname: getRoutePathname('searchSettings.keywordRules'),
          })
        }
      },
      render: ({ closeModal }) => (
        <CreateKeywordRule
          afterFormSubmit={returnValues => {
            closeModal(returnValues)
          }}
          onFormSubmit={formValues => createKeywordRule(formValues)}
          handleCancel={closeModal}
        />
      ),
    },
  },
]

const KeywordRules = props => (
  <>
    <PageHeaderBanner
      kind="info"
      message="Note: If you are using OneSearch on your platform, these rules will not apply."
    />
    <TableView
      {...props}
      queryKey="keywordRules"
      fetchFunction={FETCH_FUNCTION}
      pageId={PAGE_ID}
      pageTitle={PAGE_TITLE}
      pageType={PAGE_ID}
      tableActions={formatTableActions(props.history, props.location)}
      tableHeaders={tableHeaders}
      tableParams={tableParams}
      tableRows={formatTableRows}
    />
  </>
)

export default injectSheet(styles)(KeywordRules)
