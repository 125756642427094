import React from 'react'
import injectSheet from 'react-jss'

import { Button, ReadOnlyValueCard, ShowIfConfigured } from 'components'

import config from 'config'
import { formatDatetime } from 'utils'
import { reorder } from 'components/DataTable/utils/reorder'

import EditGlobalSearchSettingsModal from './EditGlobalSearchSettingsModal'

import {
  KeywordBrands,
  KeywordCategories,
  DisabledFeatureWarning,
} from '../KeywordRules/components'
import styles from '../KeywordRules/KeywordRuleDetail.styles'

const BASE_ID = 'edit-global-search-settings'

function formatToggleForDisplay(val) {
  return val ? 'Enabled' : 'Disabled'
}

function formatOrderForDisplay(val) {
  switch (val) {
    case 'alpha':
      return 'Alphanumeric'
    case 'count':
      return 'Result count (descending)'
    default:
      // Note that this case should never occur since this is a radio toggle switch
      return 'Not set'
  }
}

const GlobalSearchSettingsDetailForm = ({
  classes,
  handleSubmit,
  initialValues,
  setFieldValue,
  values,
  setValues,
  onFormSubmit,
}) => {
  return (
    <form className={classes.formContainer} onSubmit={handleSubmit}>
      <div className={classes.underHeaderContainer}>
        <div className={classes.leftSide}>
          <ReadOnlyValueCard
            detailValues={[
              {
                id: `${BASE_ID}-detail-boost-past-purchases`,
                labelText: 'Boost Past Purchases',
                value: values && formatToggleForDisplay(values.boostPastPurchases),
                action: (
                  <EditGlobalSearchSettingsModal
                    handleSubmit={values => {
                      return onFormSubmit(values).then(() => setValues(values))
                    }}
                    triggerRender={({ openModal }) => (
                      <Button kind="primary" icon="edit" size="x-small" onClick={openModal} />
                    )}
                    values={values}
                  />
                ),
              },
              {
                id: `${BASE_ID}-search-by-upc`,
                labelText: 'Search By UPC',
                value: values && formatToggleForDisplay(values.searchByUpc),
                action: (
                  <EditGlobalSearchSettingsModal
                    handleSubmit={values => {
                      return onFormSubmit(values).then(() => setValues(values))
                    }}
                    triggerRender={({ openModal }) => (
                      <Button kind="primary" icon="edit" size="x-small" onClick={openModal} />
                    )}
                    values={values}
                  />
                ),
              },
              {
                id: `${BASE_ID}-detail-cat-order`,
                labelText: 'Category Order',
                value: values && formatOrderForDisplay(values.sortCategoriesBy),
                action: (
                  <EditGlobalSearchSettingsModal
                    handleSubmit={values => {
                      return onFormSubmit(values).then(() => setValues(values))
                    }}
                    triggerRender={({ openModal }) => (
                      <Button kind="primary" icon="edit" size="x-small" onClick={openModal} />
                    )}
                    values={values}
                  />
                ),
              },
              {
                id: `${BASE_ID}-detail-boosted-count`,
                labelText: 'Boosted Categories',
                value: values && values.boostCategories ? values.boostCategories.length : 0,
                isHidden: config.enableICSearch,
              },
              {
                id: `${BASE_ID}-detail-boosted-brands`,
                labelText: 'Boosted Brands',
                value: values && values.boostBrands ? values.boostBrands.length : 0,
                isHidden: config.enableICSearch,
              },
              {
                id: `${BASE_ID}-detail-updated-on`,
                labelText: 'Last Edited',
                value: formatDatetime(values.modified) || 'n/a',
              },
            ]}
            noPaddingTop
          />

          <div className={classes.updateButtonContainer}>
            <EditGlobalSearchSettingsModal
              handleSubmit={values => {
                return onFormSubmit(values).then(() => setValues(values))
              }}
              triggerRender={({ openModal }) => (
                <Button kind="primary" onClick={openModal}>
                  Update Settings
                </Button>
              )}
              values={values}
            />
          </div>
        </div>
        <ShowIfConfigured requiredConfig={config => !config.enableICSearch}>
          <div className={classes.rightSide}>
            <h3 className={classes.header}>
              Boosted Categories {!values.enableBoostCategories && <DisabledFeatureWarning />}
            </h3>
            <KeywordCategories
              componentId={`${BASE_ID}-boosted`}
              componentName="boostCategories"
              setFieldValue={setFieldValue}
              onChange={handleSubmit}
              draggable
              onDragEnd={({ source, destination }) => {
                if (!source || !destination) return
                const reordered = reorder(values.boostCategories, source.index, destination.index)
                setFieldValue('boostCategories', reordered)
                handleSubmit()
              }}
              confirmClearAll
              disabled={!values.enableBoostCategories}
              limit={3}
              modalTip="You can select up to 3 categories for boosting."
            />
            <h3 className={classes.header}>
              Boosted Brands {!values.enableBoostBrands && <DisabledFeatureWarning />}
            </h3>
            <KeywordBrands
              componentId={`${BASE_ID}-boosted-brands`}
              componentName="boostBrands"
              setFieldValue={setFieldValue}
              handleSubmit={handleSubmit}
              values={values}
              disabled={!values.enableBoostBrands}
              limit={3}
              modalTip="You can select up to 3 brands to boost"
              draggable
              confirmClearAll
            />
          </div>
        </ShowIfConfigured>
      </div>
    </form>
  )
}

export default injectSheet(styles)(GlobalSearchSettingsDetailForm)
