import React from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import PropTypes from 'prop-types'

export const DraggableContainer = ({ isDraggable, render, onDragEnd }) => {
  if (!isDraggable) {
    return render()
  }

  return <DragDropContext onDragEnd={data => onDragEnd(data)}>{render()}</DragDropContext>
}

DraggableContainer.defaultProps = {
  onDragEnd: () => {},
}

DraggableContainer.propTypes = {
  isDraggable: PropTypes.bool,
  onDragEnd: PropTypes.func,
  render: PropTypes.func,
}

export default DraggableContainer
