import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import injectSheet from 'react-jss'

import { Autocomplete, Button, FormItem, LoadingIndicator, Modal, SelectedItems } from 'components'
import { capitalize, ItemsFetcher } from 'utils'

const styles = theme => ({
  addAutocompleteAndModalTrigger: {
    display: 'flex',
  },
  addAutocomplete: {
    flexGrow: 1,
  },
  addAutocompleteRestrictedWidth: {
    maxWidth: 400,
  },
  addModalTrigger: {
    marginLeft: theme.spacing.md,
  },
  selectedItems: {
    marginTop: theme.spacing.xs,
  },
  selectedItemsBoxed: {
    backgroundColor: theme.bgGreyExtraLight,
    padding: `${theme.spacing.xxs} ${theme.spacing.md} ${theme.spacing.md}`,
  },
})

/**
 * Combines Autocomplete, SelectedItems, and Modal.BrowseItems to allow users to
 * browse, add, and remove items from a large list.
 */
const AddItems = ({
  autocompleteLabelKey,
  autocompleteRenderMenuItem,
  classes,
  disabled,
  errorMsg,
  fetchItems,
  getSelectedItems: selectedItemsFunc,
  formatTableRows,
  id,
  itemName,
  itemNamePlural: itemNamePluralProvided,
  labelText,
  limit,
  guidance: { modalInfo },
  modalProps,
  onChange,
  readOnly,
  removeSelectedItemsFromBox,
  restrictAutocompleteWidth,
  selectedItems: selectedItemsArray,
  selectedItemsRenderItem,
  confirmClearAll,
  draggable,
  onDragEnd,
}) => {
  const itemNamePlural = itemNamePluralProvided || `${itemName}s`
  const capitalizedPluralItemName = capitalize({ phrase: itemNamePlural })

  return (
    <ItemsFetcher
      includeItemsById
      queryKey="items"
      fetchItems={fetchItems}
      render={({ items, itemsById, isLoadingItems }) => {
        const selectedItems = selectedItemsFunc ? selectedItemsFunc({ items }) : selectedItemsArray
        const overSelectionLimit = typeof limit === 'number' && selectedItems.length >= limit

        if (isLoadingItems) {
          return <LoadingIndicator />
        }
        return (
          <FormItem fieldId={id} labelText={labelText} errorMsg={errorMsg}>
            <div className={classes.container}>
              {!readOnly && (
                <div className={classes.addAutocompleteAndModalTrigger}>
                  <Autocomplete
                    className={cx(classes.addAutocomplete, {
                      [classes.addAutocompleteRestrictedWidth]: restrictAutocompleteWidth,
                    })}
                    id={id}
                    isLoading={isLoadingItems}
                    items={items}
                    labelKey={autocompleteLabelKey}
                    onItemSelect={selectedItem => {
                      const previouslySelectedItems = selectedItems
                      onChange([...previouslySelectedItems, selectedItem])
                    }}
                    disabled={disabled || overSelectionLimit}
                    placeholder={`Add ${capitalizedPluralItemName}`}
                    renderMenuItem={autocompleteRenderMenuItem}
                    selectedItems={selectedItems}
                  />
                  {!isLoadingItems && (
                    <Modal.BrowseItems
                      handleCloseModal={({ newSelectedItems }) => {
                        if (newSelectedItems) {
                          onChange(newSelectedItems)
                        }
                      }}
                      triggerRender={({ openModal }) => (
                        <Button
                          className={classes.addModalTrigger}
                          id={`${id}-browse`}
                          kind="link"
                          disabled={disabled || overSelectionLimit}
                          onClick={() => {
                            openModal({
                              selectedItems,
                            })
                          }}
                        >
                          See All {capitalizedPluralItemName}
                        </Button>
                      )}
                      contentProps={{
                        itemName,
                        itemNamePlural,
                        items,
                        itemsById,
                        initialSelectedItems: selectedItems,
                        formatTableRows,
                        renderInfoBlock: () => modalInfo,
                        ...modalProps,
                      }}
                    />
                  )}
                </div>
              )}
              {!isLoadingItems && (
                <SelectedItems
                  className={cx(classes.selectedItems, {
                    [classes.selectedItemsBoxed]: !removeSelectedItemsFromBox,
                  })}
                  disabled={disabled}
                  draggable={draggable && !disabled}
                  headerText={`Selected ${capitalizedPluralItemName}`}
                  itemNamePlural={itemNamePlural}
                  items={selectedItems}
                  onChange={onChange}
                  readOnly={readOnly}
                  renderItem={selectedItemsRenderItem}
                  confirmClearAll={confirmClearAll}
                  onDragEnd={onDragEnd}
                />
              )}
            </div>
          </FormItem>
        )
      }}
    />
  )
}

AddItems.propTypes = {
  autocompleteLabelKey: PropTypes.string,
  autocompleteRenderMenuItem: PropTypes.func,
  /**
   * Whether or not this form control is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Displayed under the Autocomplete when passed in
   */
  errorMsg: PropTypes.string,
  fetchItems: PropTypes.func.isRequired,
  /**
   * Pass through a custom function for the Modal.BrowseItems component that
   * can format the items array to match the expected row format of { id, name }
   */
  formatTableRows: PropTypes.func.isRequired,
  /**
   * Can be used instead of `selectedItems`
   */
  getSelectedItems: PropTypes.func,
  /**
   * Applied to the Autocomplete (and to the "See All" browse link as `${id}-browse`)
   */
  id: PropTypes.string.isRequired,
  itemName: PropTypes.string.isRequired,
  /**
   * If not provided, the default used will be `${itemName}s`
   */
  itemNamePlural: PropTypes.string,
  /**
   * Renders a form label above the Autocomplete when passed in
   */
  labelText: PropTypes.string,
  /**
   * Passed into the `Modal.BrowseItem`'s `contentProps`
   */
  modalProps: PropTypes.object,
  /**
   * The maximum number of selectable items
   */
  limit: PropTypes.number,
  /**
   * Additional notification blocks to provide the user with; all are optional
   */
  guidance: PropTypes.shape({
    modalInfo: PropTypes.node,
  }),
  onChange: PropTypes.func.isRequired,
  /**
   * If set to `true`, will only show the selected items with no option for the user to
   * add or remove any.
   */
  readOnly: PropTypes.bool,
  /**
   * Alters the styling of the SelectedItems component and wraps it in a box
   */
  removeSelectedItemsFromBox: PropTypes.bool,
  restrictAutocompleteWidth: PropTypes.bool,
  selectedItems: PropTypes.array.isRequired,
  selectedItemsRenderItem: PropTypes.func,
  /**
   * Determines if the "clear all" button should trigger a confirmation before going through
   */
  confirmClearAll: PropTypes.bool,
}

AddItems.defaultProps = {
  disabled: false,
  readOnly: false,
  removeSelectedItemsFromBox: false,
  restrictAutocompleteWidth: false,
  selectedItems: [],
  guidance: {},
}

export default injectSheet(styles)(AddItems)
