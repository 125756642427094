import { getRoutePathname } from 'routing'

import Orders from './Orders/Orders'
import CateringOrders from './CateringOrders/CateringOrders'
import Order from './Orders/Order'
import CreateOrder from './Orders/CreateOrder'
import Customers from './Customers/Customers'
import Customer from './Customers/Customer'
import CCPARequests from './CCPARequests/CCPARequests'
import Offers from './Offers/Offers'

const routeDefinitions = [
  { path: getRoutePathname('orders'), component: Orders },
  { path: getRoutePathname('offersExplain'), component: Offers },
  { path: getRoutePathname('cateringOrders'), component: CateringOrders },
  { path: getRoutePathname('orders.order', { id: ':orderId' }), component: Order },
  { path: getRoutePathname('createOrder'), component: CreateOrder },
  { path: getRoutePathname('customers'), component: Customers },
  { path: getRoutePathname('customers.customer', { id: ':customerId' }), component: Customer },
  { path: getRoutePathname('ccpaRequests'), component: CCPARequests },
]

export default routeDefinitions
