import React, { createRef } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import PropTypes from 'prop-types'

const DropContainer = ({ id = 'datatable', isDraggable, render }) => {
  const defaultProps = {
    droppableProvided: {
      innerRef: createRef(),
    },
  }

  if (!isDraggable) {
    return render(defaultProps)
  }

  return (
    <Droppable droppableId={id}>
      {(droppableProvided, droppableSnapshot) => render({ droppableProvided, droppableSnapshot })}
    </Droppable>
  )
}

DropContainer.defaultProps = {
  render: () => {},
}

DropContainer.propTypes = {
  id: PropTypes.string,
  isDraggable: PropTypes.bool,
  render: PropTypes.func,
}

export default DropContainer
