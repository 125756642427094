import { getKnowledgeOwlOAuthToken } from 'api'

// Knowledge Owl (attaches onClick handler to a trigger element with id specified in the Knowledge Owl Widget settings (on app.knowledgeowl.com))

export default {
  initialize: () =>
    getKnowledgeOwlOAuthToken().then(({ access_token }) => {
      /* eslint-disable no-underscore-dangle */
      window._ko16_p = window._ko16_p || []
      window._ko16_p.push(['_setProject', '5a95887bec161c7e633ddc84-5a958898ad121c39731abead'])
      /** Oauth Token * */
      window._ko16_p.push(['_setToken', access_token])
      /* eslint-enable no-underscore-dangle */

      setTimeout(function() {
        const ko = document.createElement('script')
        ko.type = 'text/javascript'
        ko.async = true
        ko.src =
          '//unata.knowledgeowl.com/javascript/ko-index?__pc=5a95887bec161c7e633ddc84-5a958898ad121c39731abead'
        document.head.appendChild(ko)
      }, 250)
    }),
}
