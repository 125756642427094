import React, { Component } from 'react'
import injectSheet from 'react-jss'

import {
  DragAndDropContainer,
  DraggableRow,
  DropContainer,
  FormFieldItem,
  Icon,
  Modal,
  Notification,
  RadioButtons,
  SearchInput,
  Tooltip,
} from 'components'
import Styling from 'styling/components'

import {
  Table,
  TableCell,
  TableSelect,
  TableEmptyMsg,
  TableHeader,
  TableRow,
  TablePaging,
  TableInfiniteScroll,
} from '../components'

export const defaultRender = ({
  tableAction = null,
  bulkAction,
  emptyMsg,
  error,
  getHeaderProps,
  getRowProps,
  getSelectAllProps,
  hasSelectedRows,
  showSelectBoxes,
  headers,
  id,
  isDraggable,
  isLoadingNewRows,
  isSelectable,
  kind,
  onDragEnd,
  pagingType = 'paginated',
  pagingProps,
  rows,
  rowSeparator,
}) => {
  const tableContainsExpandableRow = rows.findIndex(row => !!row.rowDetails) !== -1
  const tableContainsLinkRow = rows.findIndex(row => !!row.rowLinkTo) !== -1
  const tableContainsActionRow = rows.findIndex(row => !!row.rowAction) !== -1

  const isPaginated = pagingType === 'paginated'
  const { pageNumber: currentPage, onPagingChange: onChange } = pagingProps || {}
  // eslint-disable-next-line prefer-object-spread
  const tablePagingProps = Object.assign(
    { ...pagingProps, tableId: id },
    isPaginated && { currentPage, onChange }
  )

  let tableIsDraggable = false

  // Add drag and drop logic to table rows
  if (isDraggable) {
    tableIsDraggable = true
  }
  const numLeftSpacerCols =
    (isSelectable ? 1 : 0) + (tableContainsExpandableRow ? 1 : 0) + (tableIsDraggable ? 1 : 0)
  const numRightSpacerCols = (tableContainsLinkRow ? 1 : 0) + (tableContainsActionRow ? 1 : 0)
  const numColumns = headers.length + numLeftSpacerCols + numRightSpacerCols

  return error ? (
    <Styling.Center maxWidth={500}>
      <Notification kind="error" message={error.message} />
    </Styling.Center>
  ) : (
    <DragAndDropContainer
      isDraggable={tableIsDraggable}
      onDragEnd={data => {
        data.pageNumber = pagingProps.pageNumber
        data.resultsPerPage = pagingProps.resultsPerPage
        return onDragEnd(data)
      }}
      render={() => (
        <Table id={id} isLoading={isLoadingNewRows} kind={kind}>
          <thead>
            <tr>
              {isDraggable && <TableHeader isExpandSpacerHeader />}
              {isSelectable && (
                <TableHeader isSelectAllHeader>
                  <TableSelect {...getSelectAllProps()} />
                </TableHeader>
              )}
              {// Add empty column for expandable row icon if such a row is present in the table
              tableContainsExpandableRow && <TableHeader isExpandSpacerHeader />}
              {headers.map(header => (
                <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
              ))}
              {(bulkAction && <TableHeader isBulkActionHeader>{bulkAction}</TableHeader>) ||
                // Add empty column for action row icon if such a row is present in the table, and there is no bulk action header
                (tableContainsActionRow && <TableHeader isBulkActionHeader />)}
              {// Add empty column for link row icon if such a row is present in the table
              tableContainsLinkRow && <TableHeader isLinkSpacerHeader />}
            </tr>
            {tableAction ? (
              <tr align="center">
                <td colSpan="100%">{tableAction}</td>
              </tr>
            ) : null}
          </thead>
          <DropContainer
            isDraggable={tableIsDraggable}
            render={({ droppableProvided }) => (
              <tbody ref={droppableProvided.innerRef}>
                {rows.length > 0 ? (
                  rows.map((row, rowIndex) => (
                    <DraggableRow
                      id={row.id}
                      index={rowIndex}
                      key={row.id}
                      isDraggable={tableIsDraggable}
                      render={({ draggableProvided, draggableSnapshot }) => (
                        <TableRow
                          isDraggable={tableIsDraggable}
                          isSelectable={isSelectable}
                          key={row.id}
                          provided={draggableProvided}
                          snapshot={draggableSnapshot}
                          tableContainsExpandableRow={tableContainsExpandableRow}
                          tableContainsLinkRow={tableContainsLinkRow}
                          tableContainsActionRow={tableContainsActionRow}
                          showAllSelectBoxes={hasSelectedRows || showSelectBoxes}
                          numColumns={numColumns}
                          {...getRowProps({ row })}
                        >
                          {row.cells.map((cell, cellIndex) => (
                            <TableCell
                              key={cell.id}
                              isDragOccurring={
                                draggableSnapshot && draggableSnapshot.isDragging
                                  ? draggableSnapshot.isDragging
                                  : false
                              }
                            >
                              {rowSeparator && rowIndex !== 0 && cellIndex === 0 && (
                                <span className="row-separator">{rowSeparator}</span>
                              )}
                              {cell.value}
                            </TableCell>
                          ))}
                        </TableRow>
                      )}
                    />
                  ))
                ) : (
                  <TableEmptyMsg colSpan={numColumns} emptyMsg={emptyMsg} />
                )}
              </tbody>
            )}
          />
          {pagingType === 'paginated' &&
            pagingProps &&
            pagingProps.resultsTotal > 20 &&
            rows.length > 0 && (
              <tfoot>
                <TableRow>
                  <TableCell colSpan={numColumns}>
                    <TablePaging {...tablePagingProps} />
                  </TableCell>
                </TableRow>
              </tfoot>
            )}
          {pagingType === 'infinite' && pagingProps && pagingProps.hasMore && (
            <tfoot>
              <TableRow>
                <TableCell colSpan={numColumns}>
                  <TableInfiniteScroll {...tablePagingProps} />
                </TableCell>
              </TableRow>
            </tfoot>
          )}
        </Table>
      )}
    />
  )
}

const WithFilterLayout = injectSheet(theme => ({
  filterContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing.xlg,
  },
  filterInput: {
    width: 230,
  },
  infoBlock: {
    marginBottom: theme.spacing.md,
  },
}))(
  ({
    classes,
    filterInputId,
    filterInputPlaceholder = 'Filter',
    filterInputValue,
    onFilterInputChange,
    renderInfoBlock,
    ...restProps
  }) => (
    <div>
      <div className={classes.filterContainer}>
        <SearchInput
          className={classes.filterInput}
          id={filterInputId}
          onChange={onFilterInputChange}
          placeholder={filterInputPlaceholder}
          value={filterInputValue}
        />
      </div>
      {renderInfoBlock && <div className={classes.infoBlock}>{renderInfoBlock()}</div>}
      {defaultRender(restProps)}
    </div>
  )
)

const PERSONALIZED_ORDER = 'none'
const SPECIFIED_ORDER = 'sequence'

class CollectionProductsWithOrdering extends Component {
  state = {
    currentOrdering: null,
    isDraggable: null,
    modalOpen: false,
  }

  componentWillMount() {
    this.setState({
      currentOrdering: this.props.orderOverride,
      isDraggable: this.props.orderOverride === SPECIFIED_ORDER,
    })
  }

  render() {
    const {
      classes,
      items,
      filterInputId,
      filterInputPlaceholder,
      filterInputValue,
      onFilterInputChange,
      setFieldValue,
      touched,
      orderOverride,
      pageId,
      ...restProps
    } = this.props

    return (
      <div>
        <Modal.Confirmation
          isOpen={this.state.modalOpen}
          size="small"
          contentProps={{
            headerText:
              'To switch to personalized order, any changes made to the list order will not be saved. Are you sure you want to proceed?',
            cancelButtonText: 'No, keep my order',
            confirmButtonText: 'Yes, Continue',
          }}
          handleCloseModal={({ wasConfirmed }) => {
            if (wasConfirmed) {
              this.setState({ currentOrdering: PERSONALIZED_ORDER })
              setFieldValue('orderOverride', PERSONALIZED_ORDER)
              this.setState({ isDraggable: false })
            }
            this.setState({ modalOpen: false })
          }}
        />
        <div className={classes.tableOptionsContainer}>
          <div className={classes.orderingContainer}>
            <FormFieldItem
              className={classes.formInput}
              id={`${pageId}-product-collection-table-options`}
              labelText="Display Header"
              name="featured"
              render={({ id, value }) => {
                return (
                  <RadioButtons
                    id={id}
                    onChange={newOrdering => {
                      if (newOrdering !== this.state.currentOrdering) {
                        // No need to show modal if the list ordering hasn't been touched
                        if (newOrdering === PERSONALIZED_ORDER && touched) {
                          this.setState({ modalOpen: true })
                        } else {
                          this.setState({ currentOrdering: newOrdering })
                          setFieldValue('orderOverride', newOrdering)
                          this.setState({ isDraggable: newOrdering === SPECIFIED_ORDER })
                        }
                      }
                    }}
                    orientation="horizontal"
                    radioButtonProps={[
                      {
                        id: PERSONALIZED_ORDER,
                        labelText: 'Personalized Order',
                        value: PERSONALIZED_ORDER,
                        hasTooltip: true,
                        tooltipText: 'Sort products based on our recommendation engine reco score',
                      },
                      {
                        id: SPECIFIED_ORDER,
                        labelText: 'Specified Order',
                        value: SPECIFIED_ORDER,
                      },
                    ]}
                    selectedValue={this.state.currentOrdering}
                  />
                )
              }}
            />
          </div>
          <div className={classes.filterContainer}>
            <SearchInput
              className={classes.filterInput}
              id={filterInputId}
              onChange={onFilterInputChange}
              placeholder={filterInputPlaceholder}
              value={filterInputValue}
            />
          </div>
        </div>
        {defaultRender(Object.assign(restProps, { isDraggable: this.state.isDraggable }))}
      </div>
    )
  }
}

const WithFilterAndOrderLayout = injectSheet(theme => ({
  filterContainer: {
    display: 'flex',
  },
  orderingContainer: {
    display: 'flex',
    width: 400,
  },
  tableOptionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing.xlg,
  },
  filterInput: {
    width: 230,
  },
}))(CollectionProductsWithOrdering)

export const renderWithFilter = props => <WithFilterLayout {...props} />
export const renderWithFilterAndOrder = props => <WithFilterAndOrderLayout {...props} />
