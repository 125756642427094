import memoize from 'memoize-one'
import React from 'react'

import { fetchSubscriptions } from 'api'
import { TableView } from 'layouts'
import { generateBackButton, getRoutePathname } from 'routing'
import { formatDate, toCurrency } from 'utils'

// Table Configurations
const PAGE_ID = 'subscriptions'
const PAGE_TITLE = 'Subscriptions'
const FETCH_FUNCTION = fetchSubscriptions

const tableHeaders = [
  {
    key: 'name',
    header: 'Name',
  },
  {
    key: 'description',
    header: 'Description',
  },
  {
    key: 'price',
    header: 'Price',
  },
  {
    key: 'plu',
    header: 'PLU',
  },
  {
    key: 'days',
    header: 'Time Period',
  },
  {
    key: 'created',
    header: 'Created',
  },
]

const formatTableRows = memoize(({ items, location }) =>
  items.map(subscription => {
    const { id, created, plu, name, description, price, days } = subscription
    return {
      rowLinkTo: {
        pathname: getRoutePathname('subscriptions.editSubscription', { id }),
        state: { backButton: generateBackButton('subscriptions', location) },
      },
      id: String(id),
      plu,
      created: formatDate(created),
      name,
      description,
      price: toCurrency(price),
      days: `${days} days`,
    }
  })
)

const formatTableActions = location => [
  {
    href: {
      pathname: getRoutePathname('subscriptions.createSubscription'),
      state: { backButton: generateBackButton('subscriptions', location) },
    },
    id: `${PAGE_ID}-create`,
    permission: 'subscriptions.create',
    content: 'Create New',
  },
]

const Subscriptions = props => {
  const { location } = props
  return (
    <TableView
      {...props}
      queryKey="subscriptions"
      fetchFunction={FETCH_FUNCTION}
      pageId={PAGE_ID}
      pageTitle={PAGE_TITLE}
      pageType={PAGE_ID}
      tableHeaders={tableHeaders}
      tableRows={formatTableRows}
      tableActions={formatTableActions(location)}
    />
  )
}

export default Subscriptions
