import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import injectSheet from 'react-jss'
import { Value } from 'react-values'

import { DEFAULT_TABLE_LIMIT } from 'defaults'
import { ItemsFetcherWithParams } from 'utils'

import { Button, DataTable, PageHeader, SearchInput, ShowIfAuthorized } from 'components'
import ArchiveButton from 'layouts/Views/ArchiveButton/ArchiveButton'
import CopyButton from 'layouts/Views/CopyButton/CopyButton'
import Styling from 'styling/components'

import styles from './TableView.styles'

const defaultParams = {
  paging: {
    type: 'multi',
    multiParams: { limit: { key: 'limit' }, offset: { key: 'offset' } },
  },
  search: { key: 'search' },
  sorting: {
    type: 'multi',
    multiParams: { direction: { key: 'direction' }, orderBy: { key: 'order_by' } },
  },
}

const actionComponents = {
  archive: ArchiveButton,
  copy: CopyButton,
}

// Build HTML for actions
const buildTableActions = (actions, options) => {
  const { itemsById, selectedRowIds = [] } = options

  const selectedRows = selectedRowIds
    .map(selectedRowId => itemsById[selectedRowId])
    .filter(selectedRow => !!selectedRow)

  return actions.map((props, i) => {
    const {
      action,
      actionComponent,
      content,
      href,
      id,
      modalOptions,
      permission,
      persistState,
    } = props

    if (href && persistState) {
      if (!href.state) {
        href.state = {}
      }

      // Add items/selected items to state
      // Useful for getting data on navigation
      href.state = {
        ...href.state,
        items: itemsById,
        selectedItems: selectedRows || [],
      }
    }

    let component = (
      <Button href={href} id={id}>
        {content}
      </Button>
    )

    if (action && actionComponents[action]) {
      const ActionComponent = actionComponents[action]
      const actionProps = {
        ...options,
        ...props,
        selectedRowItems: selectedRows,
      }

      component = <ActionComponent {...actionProps} />
    }

    if (action === 'modal' && actionComponent) {
      const ModalComponent = actionComponent
      component = (
        <ModalComponent
          key={id}
          triggerRender={({ openModal }) => <Button onClick={openModal}>{content}</Button>}
          {...modalOptions}
        />
      )
    }

    // Wrap in permission component if defined
    if (permission) {
      component = (
        <ShowIfAuthorized key={i} requiredPermission={permission}>
          {component}
        </ShowIfAuthorized>
      )
    }

    return component
  })
}

// Build HTML for filters
const buildTableFilters = filters =>
  filters().map((filter, i) => {
    const Component = filter.component

    return (
      <div key={i} className={filter.className}>
        <Component {...filter} />
      </div>
    )
  })

class TableView extends Component {
  static propTypes = {
    /**
     * Boolean which will display multi select checkboxes.
     */
    isSelectable: PropTypes.bool,
    /**
     * Main title to display at the top of the page
     */
    pageTitle: PropTypes.node,
    /**
     * Required ID to prepend to all element IDs
     */
    pageId: PropTypes.string.isRequired,
    /**
     * Array of actions (buttons) to display in the top right when an item is selected
     */
    selectedTableActions: PropTypes.array,
    /**
     * Array of actions (buttons) to display in the top right
     */
    tableActions: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
    /**
     * Array of filters to display at the top of the table
     */
    tableFilters: PropTypes.func,
    /**
     * Array of header columns to display at the top of the table
     */
    tableHeaders: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        header: PropTypes.string,
      })
    ).isRequired,
    /**
     * Array of preformatted table rows
     */
    tableRows: PropTypes.func.isRequired,
    /**
     * Object of table options (see defaults above as an example)
     */
    tableParams: PropTypes.object,
    /**
     * Whether or not table rows can be dragged to re-order data in the table
     */
    isDraggable: PropTypes.bool,
    /**
     * Callback when drag ends
     */
    onDragEnd: PropTypes.func,
    /**
     * Data table component to render
     */
    component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    /**
     * Default URL query string params to override the default empty query of ItemsFetcherWithParams
     * when populating the TableView with data
     */
    defaultQueryParams: PropTypes.object,
    /**
     * Unique key for query caching
     */
    queryKey: PropTypes.string.isRequired,
  }

  state = {
    selectedRowIds: [],
  }

  componentWillReceiveProps() {
    // Reset selected rows when a navigation link is clicked
    this.updateSelectedRowIds()
  }

  updateSelectedRowIds = (selectedRowIds = []) => {
    this.setState({ selectedRowIds })
  }

  render() {
    const {
      classes,
      fetchFunction,
      fetchOnMount,
      fetchOnEmptyQuery,
      history,
      isSelectable,
      location,
      pageId,
      selectedTableActions,
      tableActions,
      tableFilters,
      tableFiltersAlign,
      tableHeaders,
      tableRows,
      pageTitle,
      tableParams,
      isDraggable,
      onDragEnd,
      component,
      defaultQueryParams,
      queryKey,
      hideHeader,
    } = this.props

    const DataTableComponent = component

    return fetchFunction ? (
      <ItemsFetcherWithParams
        queryKey={queryKey}
        fetchOnMount={fetchOnMount}
        fetchOnEmptyQuery={fetchOnEmptyQuery}
        defaultQueryParams={defaultQueryParams}
        fetchItems={fetchFunction}
        routingParams={{
          location,
          history,
        }}
        includeItemsById
        paramDefinitions={tableParams}
        render={({
          params: itemParams,
          items: tableItems,
          itemsById: tableItemsById,
          itemCount: tableItemCount,
          isLoadingItems: isFetchingTableItems,
          refetch: refetchItems,
          error: errorFetchingTableItems,
        }) => {
          const { selectedRowIds } = this.state
          const createTableRows = tableRows({
            ...this.props,
            items: tableItems,
            refetchItems,
          })
          const createTableFilters = buildTableFilters(() => tableFilters(itemParams))

          // Paging props
          const resultsPerPage =
            parseInt(itemParams.paging.multiParams.limit.value, 10) || DEFAULT_TABLE_LIMIT
          const pageNumber = (itemParams.paging.multiParams.offset.value || 0) / resultsPerPage + 1

          const tableActionsProps = {
            itemsById: tableItemsById,
            state: this.state,
            refetchItems,
            updateSelectedRowIds: this.updateSelectedRowIds,
          }

          const selectedTableActionsProps = {
            ...tableActionsProps,
            selectedRowIds,
          }

          let formattedTableActions = tableActions

          if (typeof tableActions === 'function') {
            formattedTableActions = tableActions({ refetchItems })
          }

          return (
            <Fragment>
              {hideHeader || (
                <PageHeader headerTitle={pageTitle}>
                  <div className={classes.rightOfHeaderContainer}>
                    {isSelectable && selectedRowIds.length > 0 && selectedTableActions ? (
                      <Fragment>
                        <Styling.H5>{selectedRowIds.length} Selected</Styling.H5>
                        {buildTableActions(selectedTableActions, selectedTableActionsProps)}
                      </Fragment>
                    ) : (
                      <Value defaultValue={itemParams.search.value || ''}>
                        {({ set: handleChange, value }) => (
                          <Fragment>
                            {tableFiltersAlign === 'default' && createTableFilters}
                            <SearchInput
                              className={classes.searchInput}
                              id={`${pageId}-search`}
                              onClear={oldInputValue => {
                                // If the user clears the search input containing the current search param,
                                // reload the page with the search param cleared
                                if (oldInputValue === itemParams.search.value) {
                                  itemParams.search.onChange(null)
                                }
                              }}
                              onChange={handleChange}
                              onSubmit={itemParams.search.onChange}
                              placeholder={itemParams.search.placeholder || 'Search'}
                              value={value}
                            />
                          </Fragment>
                        )}
                      </Value>
                    )}
                    {buildTableActions(formattedTableActions, tableActionsProps)}
                  </div>
                </PageHeader>
              )}
              {tableFiltersAlign === 'below-header' && createTableFilters ? (
                <div className={classes.filtersBottom}>{createTableFilters}</div>
              ) : null}
              {tableItems && (
                <DataTableComponent
                  error={errorFetchingTableItems}
                  headers={tableHeaders}
                  id={`${pageId}-table`}
                  isLoadingNewRows={isFetchingTableItems}
                  isSelectable={isSelectable}
                  onSelectedRowsChange={selected => this.updateSelectedRowIds(selected)}
                  onSortBy={({ nextSortHeaderKey, nextSortDirection }) => {
                    this.updateSelectedRowIds([])
                    itemParams.sorting.onChange({
                      orderBy: nextSortHeaderKey,
                      direction: nextSortDirection,
                    })
                  }}
                  rows={createTableRows}
                  unformattedRows={tableItems}
                  selectedRowIds={this.state.selectedRowIds}
                  sortHeaderKey={itemParams.sorting.multiParams.orderBy.value}
                  sortDirection={itemParams.sorting.multiParams.direction.value}
                  isDraggable={isDraggable}
                  onDragEnd={onDragEnd}
                  pagingProps={{
                    pageNumber,
                    resultsPerPage,
                    onPagingChange: ({ pageNumber, resultsPerPage }) => {
                      this.updateSelectedRowIds()
                      itemParams.paging.onChange({
                        limit: resultsPerPage,
                        offset: (pageNumber - 1) * resultsPerPage || undefined,
                      })
                    },
                    resultsTotal: tableItemCount,
                  }}
                />
              )}
            </Fragment>
          )
        }}
      />
    ) : null
  }
}

TableView.defaultProps = {
  isSelectable: false,
  pageId: null,
  pageTitle: '',
  selectedTableActions: null,
  tableActions: [],
  tableFilters: () => [],
  tableFiltersAlign: 'default',
  tableHeaders: [],
  tableRows: () => [],
  tableParams: defaultParams,
  component: DataTable,
}

export default injectSheet(styles)(TableView)
