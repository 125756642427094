import React from 'react'
import injectSheet from 'react-jss'

import { FormFieldItem, TextInput } from 'components'

import styles from '../PromotedPlacementsForm.styles'

const PlacementCustomColor = ({
  classes,
  componentId,
  fieldItemName = 'color',
  labelText,
  setFieldValue,
}) => (
  <FormFieldItem
    className={classes.formRow}
    id={componentId}
    labelText={labelText}
    name={fieldItemName}
    render={({ id, name, value }) => (
      <div className={classes.colorSelector}>
        <input
          type="color"
          id={id}
          name={name}
          onChange={({ target }) => {
            setFieldValue(name, target.value)
          }}
          value={value}
        />
        <TextInput
          id={id}
          name={name}
          onChange={({ target }) => {
            setFieldValue(name, target.value)
          }}
          value={value}
        />
      </div>
    )}
  />
)

export default injectSheet(styles)(PlacementCustomColor)
