import debounce from 'lodash/debounce'
import React, { Component } from 'react'
import injectSheet from 'react-jss'

import config from 'config'
import { fetchStoreGroups } from 'api'
import { FormFieldItem, DropdownFilterable } from 'components'
import { get } from 'utils'

import styles from '../PromotedPlacementsForm.styles'

const findAndSetSelectedObject = ({
  filteredArray,
  formValue,
  originalArray,
  selectedStoreGroup,
  setFieldValue,
}) => {
  let selectedObject = originalArray.find(item => item.id === selectedStoreGroup)

  // If value doesn't exist in initial list, check the filtered items
  if (!selectedObject && filteredArray) {
    selectedObject = filteredArray.find(item => item.id === selectedStoreGroup)
  }
  setFieldValue(formValue, selectedObject)
}

class PlacementStoreVisibility extends Component {
  state = {
    storeGroups: [],
    isFetchingStoreGroups: false,
    filteredStoreGroups: null,
  }

  componentDidMount() {
    this.loadStoreGroupsDropdown()
  }

  formatDropdown(dropdownItems) {
    const formattedDropdownItems = dropdownItems.map(({ id, name, ...restProps }) => ({
      id,
      label: name,
      name,
      value: id,
      ...restProps,
    }))

    return formattedDropdownItems
  }

  getDebouncedStoreGroups = debounce(inputValue => {
    this.setState({ isFetchingStoreGroupsAsync: true })

    fetchStoreGroups(`?status=true&search=${inputValue}`)
      .then(({ items }) => {
        this.setState({ filteredStoreGroups: items })
      })
      .finally(() => {
        this.setState({ isFetchingStoreGroupsAsync: false })
      })
  }, 250)

  loadStoreGroupsDropdown() {
    const {
      edit,
      values: { storeGroup },
    } = this.props
    const existingStoreGroupsQuery = storeGroup && storeGroup.name ? storeGroup.name : ''

    this.setState({ isFetchingStoreGroups: true })

    fetchStoreGroups(`?status=true&limit=50&search=${existingStoreGroupsQuery}`)
      .then(({ items }) => {
        this.setState({
          storeGroups: this.formatDropdown(items),
        })

        const { defaultStoreGroupId } = config.dashboard

        // We only want to set the defaultStoreGroup if we don't already have one set and not editing
        if (!edit && !storeGroup && defaultStoreGroupId) {
          const item = items.find(({ id }) => id.toString() === defaultStoreGroupId.toString())
          this.props.setFieldValue('storeGroup', item)
        }
      })
      .finally(() => {
        this.setState({ isFetchingStoreGroups: false })
      })
  }

  render() {
    const { classes, componentId, labelText, placeholder, setFieldValue } = this.props
    const {
      filteredStoreGroups,
      isFetchingStoreGroups,
      isFetchingStoreGroupsAsync,
      storeGroups,
    } = this.state

    return (
      <FormFieldItem
        className={classes.formRow}
        id={componentId}
        labelText={labelText}
        name="storeGroup"
        orientation="horizontal"
        render={({ id, isInvalid, name, value }) => (
          <DropdownFilterable
            className={classes.formInput}
            id={id}
            initialValue={value}
            isLoading={isFetchingStoreGroups}
            isLoadingAsync={isFetchingStoreGroupsAsync}
            items={storeGroups}
            filteredItems={filteredStoreGroups ? this.formatDropdown(filteredStoreGroups) : null}
            onChange={selectedStoreGroup =>
              findAndSetSelectedObject({
                filteredArray: filteredStoreGroups,
                formValue: name,
                originalArray: storeGroups,
                selectedStoreGroup,
                setFieldValue,
              })
            }
            onInputChange={value => this.getDebouncedStoreGroups(value)}
            placeholder={placeholder}
            selectedValue={get(value, 'id')}
            onOpen={() => {
              this.props.setFieldValue(name)
            }}
          />
        )}
      />
    )
  }
}

export default injectSheet(styles)(PlacementStoreVisibility)
