import PropTypes from 'prop-types'
import React, { Component } from 'react'
import injectSheet from 'react-jss'

import { searchProducts } from 'api'
import { Dropdown, FormItem, SearchInput, SelectedItems } from 'components'
import { mixins } from 'styling'
import Styling from 'styling/components'

const styles = theme => ({
  searchKey: {
    maxWidth: 120,
  },
  productInput: {
    maxWidth: 470,
  },
  productsAdded: {
    marginTop: theme.spacing.xs,
  },
  selectedProduct: {
    alignItems: 'center',
    display: 'flex',
    overflow: 'hidden',
    width: '100%',
  },
  selectedProductImage: {
    flexShrink: 0,
    height: 32,
    marginRight: theme.spacing.md,
    width: 32,
  },
  selectedProductName: {
    ...mixins.textOverflowEllipsis,
    flexBasis: '60%',
    flexShrink: 1,
    marginRight: theme.spacing.md,
  },
  selectedProductSKU: {
    flexBasis: 60,
    marginRight: theme.spacing.xlg,
  },
  selectedProductUPCs: {
    flexBasis: 130,
    marginRight: theme.spacing.xlg,
  },
  selectedProductRRC: {
    flexBasis: 60,
  },
})

class AddProducts extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    selectedProducts: PropTypes.array.isRequired,
    onSelectedProductsChange: PropTypes.func,
  }

  static defaultProps = {
    selectedProducts: [],
  }

  state = {
    isSearchingProducts: false,
    error: '',
    searchKey: 'upc',
    searchInputValue: '',
  }

  selectProducts = newfoundProducts => {
    const currentlySelectedProducts = this.props.selectedProducts
    const currentlySelectedProductIds = currentlySelectedProducts.map(({ id }) => id)
    // Ensure we don't try to add a pre-existing product
    const productsToAdd = newfoundProducts.filter(
      product => !currentlySelectedProductIds.includes(product.id)
    )
    this.props.onSelectedProductsChange([...currentlySelectedProducts, ...productsToAdd])
  }

  searchForProducts = inputValue => {
    if (inputValue.length > 0) {
      this.setState({ isSearchingProducts: true, error: '' })
      searchProducts({
        keyType: this.state.searchKey,
        items: inputValue
          ? inputValue
              .replace(/\s/g, '')
              .split(',')
              .map(keyValue => ({ key: keyValue }))
          : [],
      }).then(({ items: products }) => {
        const nonEmptyProducts = products.map(({ product }) => product).filter(x => !!x)
        if (nonEmptyProducts.length > 0) {
          this.selectProducts(nonEmptyProducts)
          this.setState({ searchInputValue: '', isSearchingProducts: false })
        } else {
          this.setState({
            error: `No products found based on the ${this.state.searchKey.toUpperCase()}s entered.`,
            isSearchingProducts: false,
          })
        }
      })
    }
  }

  handleSearchKeyChange = newSearchKey => {
    this.setState({ searchKey: newSearchKey })
  }

  handleSearchInputValueChange = newSearchInputValue => {
    this.setState({ searchInputValue: newSearchInputValue })
  }

  render() {
    const { classes, id, selectedProducts } = this.props
    const { isSearchingProducts, error, searchKey, searchInputValue } = this.state

    return (
      <div>
        <Styling.LineOfItems>
          <Dropdown
            className={classes.searchKey}
            id={`${id}-add-by`}
            items={[
              {
                id: 'onlineProductActivity-products-addBy-upc',
                label: 'Add by UPC',
                value: 'upc',
              },
              {
                id: 'onlineProductActivity-products-addBy-sku',
                label: 'Add by SKU',
                value: 'sku',
              },
              {
                id: 'onlineProductActivity-products-addBy-rrc',
                label: 'Add by RRC',
                value: 'rrc',
              },
            ]}
            onChange={this.handleSearchKeyChange}
            placeholder="Add by..."
            selectedValue={searchKey}
          />
          <FormItem fieldId={`${id}-add`} errorMsg={error}>
            <SearchInput
              className={classes.productInput}
              disabled={isSearchingProducts}
              icon="plus"
              id={`${id}-add`}
              isInvalid={!!error}
              onChange={this.handleSearchInputValueChange}
              onSubmit={this.searchForProducts}
              placeholder={`Enter one or more ${searchKey.toUpperCase()}s separated by commas`}
              value={searchInputValue}
            />
          </FormItem>
        </Styling.LineOfItems>
        <SelectedItems
          className={classes.productsAdded}
          disabled={isSearchingProducts}
          headerText="Selected Products"
          items={selectedProducts}
          itemNamePlural="products"
          onChange={this.props.onSelectedProductsChange}
          renderItem={({ item: product }) => (
            <div className={classes.selectedProduct}>
              <img className={classes.selectedProductImage} src={product.imageUrl} alt="" />
              <div className={classes.selectedProductName}>{product.name}</div>
              <div className={classes.selectedProductSKU}>{product.sku}</div>
              <div className={classes.selectedProductUPCs}>
                {product.upcs[0]} {product.upcs.length > 1 && ` (${product.upcs.length})`}
              </div>
              <div className={classes.selectedProductRRC}>{product.rrc}</div>
            </div>
          )}
        />
      </div>
    )
  }
}
export default injectSheet(styles)(AddProducts)
