import moment from 'moment'
import * as yup from 'yup'

import {
  DESKTOP_ONLY,
  MOBILE_ONLY,
  QUICK_LINKS,
  REQUIRE_DESKTOP_MOBILE_IMAGES,
  HERO_BANNERS,
  SLIM_BANNERS,
  TILE_CAROUSELS,
  IMAGE_CAROUSELS,
  RICH_TEXT_CONTAINERS,
} from 'constants/placementTypes'

export const daysOfWeekArray = [
  {
    id: 'monday',
    labelText: 'Monday',
    name: 'monday',
    value: false,
  },
  {
    id: 'tuesday',
    labelText: 'Tuesday',
    name: 'tuesday',
    value: false,
  },
  {
    id: 'wednesday',
    labelText: 'Wednesday',
    name: 'wednesday',
    value: false,
  },
  {
    id: 'thursday',
    labelText: 'Thursday',
    name: 'thursday',
    value: false,
  },
  {
    id: 'friday',
    labelText: 'Friday',
    name: 'friday',
    value: false,
  },
  {
    id: 'saturday',
    labelText: 'Saturday',
    name: 'saturday',
    value: false,
  },
  {
    id: 'sunday',
    labelText: 'Sunday',
    name: 'sunday',
    value: false,
  },
]

export const userSessionTargeting = {
  userShoppingContext: [
    {
      id: 'delivery',
      labelText: 'Delivery',
      name: 'delivery',
      value: false,
    },
    {
      id: 'pickup',
      labelText: 'Pickup',
      name: 'pickup',
      value: false,
    },
    {
      id: 'instore',
      labelText: 'In-store',
      name: 'instore',
      value: false,
    },
    {
      id: 'catering',
      labelText: 'Catering',
      name: 'catering',
      value: false,
    },
  ],
  platform: [
    {
      id: 'desktop',
      labelText: 'Desktop',
      name: 'desktop',
      value: false,
      tag: DESKTOP_ONLY,
    },
    {
      id: 'ios_web',
      labelText: 'iOS Web',
      name: 'ios_web',
      value: false,
      tag: MOBILE_ONLY,
    },
    {
      id: 'ios_app',
      labelText: 'iOS App',
      name: 'ios_app',
      value: false,
      tag: MOBILE_ONLY,
    },
    {
      id: 'android_web',
      labelText: 'Android Web',
      name: 'android_web',
      value: false,
      tag: MOBILE_ONLY,
    },
    {
      id: 'android_app',
      labelText: 'Android App',
      name: 'android_app',
      value: false,
      tag: MOBILE_ONLY,
    },
  ],
}

export const placementFormInitialValues = {
  categories: [],
  collections: [],
  customerSegment: {},
  config: {},
  days: {
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    sunday: true,
  },
  userSessionTargeting: {},
  endDate: moment()
    .add(4, 'weeks')
    .endOf('day')
    .format(),
  hasLoyalty: null,
  media: {
    type: null,
    text: '',
    timeout: 0,
    range: 'immediate',
  },
  name: '',
  placement: {
    mediaTypes: [],
    targetTypes: [],
  },
  priority: 0,
  promotedMedia: {
    desktop: {},
    mobile: {},
  },
  promotedSearchKeywords: [],
  startDate: moment()
    .startOf('day')
    .format(),
  status: 'draft',
  target: {
    collection: null,
    collection_list: [],
    categories: [],
    image: {
      href: null,
      imageUrl: null,
      openIn: 'same_page',
    },
    offer: null,
    openIn: null,
    product: null,
    products: [],
    type: null,
    url: '',
  },
}

const baseCTASchema = yup.object().shape({
  enabled: yup.boolean(),
  text: yup
    .string()
    .label('Button Text')
    .when('enabled', {
      is: true,
      then: yup.string().required(),
    }),
  url: yup
    .string()
    .label('Button URL')
    .when('enabled', {
      is: true,
      then: yup.string().required(),
    }),
  style: yup
    .string()
    .label('Button Style')
    .when('enabled', {
      is: true,
      then: yup.string().required(),
    }),
})

export const placementFormValidationSchema = placementKey => () =>
  yup.object().shape({
    name: yup
      .string()
      .trim()
      .label('Placement Name')
      .required(),
    placement: yup.object().required(),
    categories: yup
      .array()
      .nullable()
      .label('Categories')
      .when('placement.key', {
        is: 'catalog_placement',
        then: yup.array().required(),
      }),
    collections: yup
      .array()
      .nullable()
      .label('Collections')
      .when('placement.key', {
        is: 'collection_banner',
        then: yup.array().required(),
      }),
    promotedMedia: yup.object().when(['media', 'placement'], {
      is: (media, placement) => {
        // Check if dynamic width placement image type, and if placement requires both desktop AND mobile images (plus alt text)
        return media.type === 'none' && REQUIRE_DESKTOP_MOBILE_IMAGES.includes(placement.key)
      },
      then: yup.object().shape(
        /* eslint-disable no-template-curly-in-string */
        {
          desktop: yup
            .object()
            .test(
              'Alt Text',
              'Desktop Alt Text is a required field',
              value => value && !!value.altText
            )
            .test(
              'Image',
              'Desktop image/video is required',
              value => value && (!!value.imageUrl || !!value.fileUrl)
            ),
          mobile: yup
            .object()
            .test(
              'Alt Text',
              'Mobile Alt Text is a required field',
              value => value && !!value.altText
            )
            .test(
              'Image',
              'Mobile image/video is required',
              value => value && (!!value.imageUrl || !!value.fileUrl)
            ),
        },
        // eslint-enable no-template-curly-in-string
        ['mobile', 'desktop']
      ),
    }),
    target: yup.object().shape({
      type: yup
        .string()
        .trim()
        .label('Link Destination Type')
        .required(),
      categories: yup
        .array()
        .nullable()
        .label('Categories')
        .when('type', {
          is: 'categories',
          then: yup.array().required(),
        }),
      collection: yup
        .object()
        .nullable()
        .label('Collection')
        .when('type', {
          is: 'collection',
          then: yup.object().required(),
        }),
      collection_list: yup
        .array()
        .nullable()
        .label('Collections')
        .when('type', {
          is: 'collection_list',
          then: yup.array().required(),
        }),
      product: yup
        .object()
        .nullable()
        .label('Product')
        .when('type', {
          is: 'product',
          then: yup.object().required(),
        }),
      products: yup
        .array()
        .nullable()
        .label('Products')
        .when('type', {
          is: 'products',
          then: yup.array().required(),
        }),
      url: yup
        .string()
        .trim()
        .label('URL')
        .when('type', {
          is: 'url',
          then: yup.string().required(),
        }),
    }),
    media: yup.object().shape({
      type: yup
        .string()
        .trim()
        .label('Display Content Type')
        .required(),
      collection: yup
        .object()
        .nullable()
        .label('Collection')
        .when('type', {
          is: 'collection',
          then: yup.object().required(),
        }),
      image: yup
        .object()
        .nullable()
        .label('Image')
        .when('type', {
          is: 'image',
          then: yup
            .object()
            .nullable()
            .test('Alt Text', 'Alt Text is a required field', value => value && !!value.altText)
            .test('Image', 'Image is required', value => value && !!value.imageUrl),
        }),
      product: yup
        .object()
        .nullable()
        .label('Product')
        .when('type', {
          is: 'product',
          then: yup.object().required(),
        }),
      text: yup
        .string()
        .trim()
        .label('Display Content Text')
        .when('type', {
          is: 'text',
          then: yup.string().required(),
        }),
    }),
    userShoppingContext: yup
      .object()
      .label('Shopping Context Visibility')
      .test('Shopping Context Visibility', 'Please select at least one context.', context =>
        Object.values(context).some(context => context === true)
      ),
    platform: yup
      .object()
      .label('Platform Visibility')
      .test('Platform Visibility', 'Please select at least one platform.', platform =>
        Object.values(platform).some(platform => platform === true)
      ),

    config: yup.object().shape({
      ...([HERO_BANNERS, RICH_TEXT_CONTAINERS].includes(placementKey) && {
        cta: baseCTASchema,
      }),
      ...([HERO_BANNERS].includes(placementKey) && {
        cta2: baseCTASchema,
      }),
      ...([HERO_BANNERS, IMAGE_CAROUSELS, RICH_TEXT_CONTAINERS].includes(placementKey) && {
        title: yup
          .string()
          .label('Title')
          .required(),
      }),
      ...([SLIM_BANNERS].includes(placementKey) && {
        backgroundColor: yup
          .string()
          .label('Background color')
          .matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, {
            excludeEmptyString: true,
            message: 'Must be a valid HEX color code.',
          }),
      }),
      showViewAllTitle: yup.boolean().label("Show 'View All' Title"),
      viewAllTitle: yup
        .string()
        .label("'View All' Title")
        .when('showViewAllTitle', {
          is: true,
          then: yup.string().required(),
        }),
      ...([IMAGE_CAROUSELS, QUICK_LINKS, TILE_CAROUSELS].includes(placementKey) && {
        items: yup.array().of(
          yup.object().shape({
            ...([QUICK_LINKS].includes(placementKey)
              ? {
                  image: yup
                    .object()
                    .nullable()
                    .label('Item Image'),
                }
              : {
                  image: yup
                    .object()
                    .nullable()
                    .label('Item Image')
                    .required(),
                }),
            ...(placementKey !== TILE_CAROUSELS
              ? {
                  label: yup
                    .string()
                    .label('Label')
                    .required(),
                }
              : undefined),
            url: yup
              .string()
              .label('URL')
              .required(),
            ...(![QUICK_LINKS, TILE_CAROUSELS].includes(placementKey)
              ? {
                  altText: yup
                    .string()
                    .label('Alt Text')
                    .required(),
                }
              : undefined),
          })
        ),
      }),
    }),
  })
