import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import injectSheet from 'react-jss'
import { Link } from 'react-router-dom'

import { Icon, StatusIndicatorBadge } from 'components'
import Styling from 'styling/components'
import { isNil } from 'utils'

import styles from './PageHeader.styles'

const PageHeader = ({
  backButton,
  children,
  classes,
  hasDivider,
  headerTitle,
  headerTitleMaxWidth,
  noMargin,
  statusIsActive,
  statusText,
  statusType,
}) => {
  const hasBadge = !isNil(statusIsActive) || statusType
  return (
    <header
      className={cx(classes.header, {
        [classes.headerWithBackButton]: !!backButton,
        [classes.headerWithDivider]: hasDivider,
        [classes.headerNoMargin]: noMargin,
      })}
    >
      <div className={classes.headerLeft}>
        {backButton && (
          <Link className={classes.backButton} to={backButton.to}>
            <Icon name="arrowLeft" className={classes.backButtonIcon} />
            <span>{backButton.label}</span>
          </Link>
        )}
        <h1 className={classes.title}>
          <Styling.TextOverflow
            className={cx({ [classes.titleTextWithBadge]: hasBadge })}
            maxWidth={headerTitleMaxWidth}
          >
            {headerTitle}
          </Styling.TextOverflow>
          {hasBadge && (
            <StatusIndicatorBadge isActive={statusIsActive} text={statusText} type={statusType} />
          )}
        </h1>
      </div>
      <div className={classes.headerRight}>{children}</div>
    </header>
  )
}

PageHeader.propTypes = {
  /**
   * If included, displays a back button above the page header which will
   * redirect the user to the page specified in `backButton.to`
   */
  backButton: PropTypes.shape({
    label: PropTypes.string,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
  /**
   * Anything provided as `children` will be rendered to the right of `headerTitle`.
   */
  children: PropTypes.node,
  hasDivider: PropTypes.bool,
  headerTitle: PropTypes.node,
  /**
   * If provided will restrict the width of the title with a text overflow ellipsis.
   */
  headerTitleMaxWidth: PropTypes.number,
  /**
   * If provided will include a <StatusIndicatorBadge> with that value as the `isActive` prop
   */
  statusIsActive: PropTypes.bool,
  /**
   * Include if you need to specify the <StatusIndicatorBadge> `text` prop
   */
  statusText: PropTypes.string,
  /**
   * If provided will include a <StatusIndicatorBadge> with that value as the `type` prop
   */
  statusType: PropTypes.string,
}

PageHeader.defaultProps = {
  headerTitleMaxWidth: 650,
}

export { PageHeader as PageHeaderUnStyled }
export default injectSheet(styles)(PageHeader)
