import memoize from 'memoize-one'
import React from 'react'
import injectSheet from 'react-jss'

import { fetchCollections } from 'api'
import { Dropdown, StatusIndicatorDot } from 'components'
import { TableView } from 'layouts'
import { generateBackButton, getRoutePathname } from 'routing'
import { formatDatetime, isNullOrUndefined } from 'utils'

// Table Configurations
const PAGE_ID = 'collections'
const PAGE_TITLE = 'Product Collections'
const FETCH_FUNCTION = fetchCollections

const styles = theme => ({
  productCount: {
    alignSelf: 'center',
    lineHeight: 1,
  },
  productImageThumbnailsContainer: {
    display: 'flex',
    justifyContent: 'left',
  },
  productImageThumbnails: {
    marginRight: theme.spacing.sm,
    width: 30,

    '& > img': {
      maxHeight: 30,
      maxWidth: 30,
    },
  },
  statusFilter: {
    flexBasis: '90px',
  },
})

const tableParams = {
  status: { key: 'status' },
  paging: {
    type: 'multi',
    multiParams: { limit: { key: 'limit' }, offset: { key: 'offset' } },
  },
  search: { key: 'search' },
  sorting: {
    type: 'multi',
    multiParams: { direction: { key: 'direction' }, orderBy: { key: 'order_by' } },
  },
}

// Collections table header keys must match value used after `sortBy` in `/product_collections` query
// (in addition to the corresponding row property)
const tableHeaders = [
  {
    key: 'status',
    disableSorting: true,
  },
  {
    key: 'name',
    header: 'Collection Name',
  },
  {
    key: 'images',
    header: 'Products',
    disableSorting: true,
  },
  {
    key: 'createdBy',
    header: 'Created By',
  },
  {
    key: 'modified',
    header: 'Last Edited',
  },
]

const formatTableRows = memoize(({ classes, items, location }) =>
  items.map(collection => {
    const {
      createdBy,
      id,
      images = [],
      modified,
      dynamic,
      name,
      productCount = 0,
      status: collectionStatus,
    } = collection

    // Fix to set existing collections to true by default instead of undefined
    // This can be removed once BE should updates this at the API level
    const status = isNullOrUndefined(collectionStatus) ? true : collectionStatus

    const statusIndicatorType = status ? 'complete' : 'cancelled'
    const statusIndicatorText = status ? 'Active' : 'Archived'

    return {
      id: `collection-${id}`,
      rowLinkTo: {
        pathname: getRoutePathname('collections.collection', { id }),
        state: { backButton: generateBackButton('collections', location) },
      },
      name,
      images: dynamic ? (
        <div>Dynamic Collection</div>
      ) : (
        <div className={classes.productImageThumbnailsContainer}>
          {images.map((image, i) => (
            <div className={classes.productImageThumbnails} key={i}>
              <img src={image.imageUrl} alt="" />
            </div>
          ))}
          <div className={classes.productCount}>{productCount === 0 ? 'n/a' : productCount}</div>
        </div>
      ),
      createdBy: createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : '',
      modified: modified ? formatDatetime(modified) : '',
      status: <StatusIndicatorDot compact text={statusIndicatorText} type={statusIndicatorType} />,
    }
  })
)

const formatTableActions = location => [
  {
    href: {
      pathname: getRoutePathname('collections.createCollection'),
      state: { backButton: generateBackButton('collections', location) },
    },
    id: `${PAGE_ID}-create`,
    permission: 'collections.create',
    content: 'Create New',
  },
]

const filterOptions = {
  status: [
    { id: 'clear', label: 'Any Type', value: null },
    { id: 'active', label: 'Active', value: 'true' },
    { id: 'archived', label: 'Archived', value: 'false' },
  ],
}

const formatTableFilters = (params, classes) => {
  return [
    {
      component: Dropdown,
      className: classes.statusFilter,
      id: `${PAGE_ID}-filter-status`,
      items: filterOptions.status,
      onChange: params.status.onChange,
      placeholder: 'Status',
      selectedValue: params.status.value,
    },
  ]
}

const Collections = props => (
  <TableView
    {...props}
    queryKey="collections"
    fetchFunction={FETCH_FUNCTION}
    pageId={PAGE_ID}
    pageTitle={PAGE_TITLE}
    pageType={PAGE_ID}
    tableActions={formatTableActions(props.location)}
    tableFilters={params => formatTableFilters(params, props.classes)}
    tableHeaders={tableHeaders}
    tableParams={tableParams}
    tableRows={formatTableRows}
  />
)

export default injectSheet(styles)(Collections)
