export default theme => ({
  container: {
    width: '100%',
  },
  tabs: {
    fontSize: theme.fontSize.p,
    fontFamily: theme.fontFamilySansSerif,
  },
  tabList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    position: 'relative',
  },
  tab: {
    padding: `0 ${theme.spacing.xxs}`,
    borderBottom: `2px solid ${theme.borderGreyLight}`,
  },
  tabSelected: {
    borderBottom: `2px solid ${theme.brand01}`,
    // Set z-index to 1 to ensure blue bottom border is rendered on top of grey bottom bar (see `tabList`)
    zIndex: 1,

    '& $tabLink': {
      color: theme.brand01,
      fontWeight: 600,
    },
  },
  tabLink: {
    color: theme.text01,
    display: 'block',
    height: 31,
    padding: theme.spacing.xs,
    textDecoration: 'none',
    whiteSpace: 'nowrap',

    '&:focus': {
      outlineOffset: -7,
    },

    '&:hover, &:focus': {
      color: theme.brand01,
    },
  },
  tabContent: {
    padding: `${theme.spacing.lg} 0`,
  },
  noTabContentPadding: {
    padding: 0,
  },
})
