import PropTypes from 'prop-types'
import { useMemo } from 'react'

import { useItemsFetcherWithParams } from './itemsFetcherWithParamsHook'

const getItemsById = itemArray =>
  itemArray.reduce(
    (itemsById, item) => ({
      ...itemsById,
      [item.id]: item,
    }),
    {}
  )

/**
 * Fetches new items on mount with function prop `fetchItems` and allows consuming component to
 * access the following props through the `render` function prop (along with the other props returned in the API response):
 * - `items`, `itemCount`, and `isFetchingItems` (fetched items data and fetch status)
 * - `error` (if the request results in an error response, the response is accessible under `error`)
 */
const ItemsFetcherWithParams = ({
  queryKey,
  paramDefinitions,
  routingParams,
  fetchItems,
  defaultQueryParams,
  fetchOnMount,
  includeItemsById,
  fetchOnEmptyQuery,
  render,
}) => {
  const {
    params,
    queryParams,
    items,
    error,
    refetch,
    isLoadingItems,
    otherData,
  } = useItemsFetcherWithParams(queryKey, fetchItems, paramDefinitions, routingParams, {
    defaultQueryParams,
    fetchOnMount,
    fetchOnEmptyQuery,
  })

  const itemsById = useMemo(() => (includeItemsById ? getItemsById(items) : {}), [
    items,
    includeItemsById,
  ])

  const toRender = useMemo(
    () =>
      render({
        params,
        queryParams,
        error,
        isLoadingItems,
        items,
        refetch,
        itemsById,
        ...otherData,
      }) || null,
    [render, error, isLoadingItems, items, refetch, otherData, itemsById, params, queryParams]
  )

  return toRender
}

ItemsFetcherWithParams.propTypes = {
  queryKey: PropTypes.string.isRequired,
  paramDefinitions: PropTypes.object.isRequired,
  routingParams: PropTypes.object.isRequired,
  defaultQueryParams: PropTypes.object,
  fetchOnMount: PropTypes.bool,
  fetchOnEmptyQuery: PropTypes.bool,
  fetchItems: PropTypes.func.isRequired,
  includeItemsById: PropTypes.bool,
  render: PropTypes.func.isRequired,
}

export default ItemsFetcherWithParams
