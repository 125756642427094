import axios from 'axios'

import appConfig from 'config'
import { get, addValueToQueryString } from 'utils'

axios.defaults.timeout = appConfig.dashboard?.apiTimeout || 30000
axios.defaults.baseURL = appConfig.env.apiPath
axios.interceptors.response.use(
  response => response.data,
  error => {
    if (axios.isCancel(error)) {
      // eslint-disable-next-line no-console
      console.log('API request cancelled:', error.message)
    }
    return Promise.reject(get(error, 'response.data', error))
  }
)

//
// Brands
//
export const fetchProductBrands = () => axios.get(`/products/brands`)

//
// Compliance
//
export const fetchRequests = (queryString, config) =>
  axios.get(`/compliance/request${queryString}`, config)

export const getDownloadLink = requestId =>
  `${appConfig.env.apiPath}/compliance/request/${requestId}/download`

export const createFetchRequest = payload => axios.post('/compliance/fetch-user-data', payload)

export const createDeleteRequest = payload => axios.post('/compliance/delete-user-data', payload)

//
// Collections
//
export const fetchCollections = (queryString, config) => {
  // Add with_external=false to exclude external (not editable) collections
  queryString = addValueToQueryString(queryString, { key: 'with_external', value: 'false' })
  return axios.get(`/product_collections${queryString}`, config)
}

export const fetchCollection = (collectionId, config) =>
  axios.get(`/product_collections/${collectionId}`, config)

export const createCollection = payload => axios.post('/product_collections', payload)

export const updateCollection = (collectionId, payload) =>
  axios.put(`/product_collections/${collectionId}`, payload)

export const fetchCollectionAttributes = () => axios.get('/product_collections/collection_rules')

//
// Customers
//
export const fetchCustomers = (queryString = '', config) =>
  axios.get(`/customers${queryString}`, config)

export const fetchCustomer = (customerId, queryString, config) =>
  axios.get(`/customers/${customerId}${queryString}`, config)

export const fetchCustomerCredits = (customerId, config) =>
  axios.get(`/customers/${customerId}/credits`, config)

export const createCustomerCredit = (customerId, payload) =>
  axios.post(`/customers/${customerId}/credits`, payload)

export const cancelCustomerCredit = (customerId, creditId) =>
  axios.delete(`/customers/${customerId}/credits/${creditId}`)

export const fetchCustomerTransactions = (customerId, config) =>
  axios.get(`/customers/${customerId}/transactions`, config)

export const fetchCustomerSessionToken = customerId =>
  axios.get(`/customers/${customerId}/session_token`)

export const updateCustomer = (customerId, payload) =>
  axios.put(`/customers/${customerId}`, payload)

export const resetCustomerPassword = customerId =>
  axios.put(`/customers/${customerId}/reset_password`)

export const fetchCustomerSubscriptions = (customerId, config) =>
  axios.get(`/customers/${customerId}/enterprise_subscriptions`, config)

export const createCustomerSubscription = (customerId, payload) =>
  axios.post(`/customers/${customerId}/enterprise_subscriptions`, payload)

export const updateCustomerSubscription = (customerId, payload) =>
  axios.put(`/customers/${customerId}/update_enterprise_subscription`, payload)

export const cancelCustomerSubscription = (customerId, payload) =>
  axios.post(`/customers/${customerId}/cancel_enterprise_subscription`, payload)

export const refundCustomerSubscription = (customerId, payload) =>
  axios.post(`/customers/${customerId}/refund_enterprise_subscription`, payload)

//
// Orders
//
export const fetchOrders = (queryString = '', config) => axios.get(`/orders${queryString}`, config)

export const fetchCateringOrders = (queryString = '', config) =>
  axios.get(`/orders/catering/items${queryString}`, config)

export const fetchCateringOrderStatuses = config =>
  axios.get(`/orders/catering/items/statuses`, config)

export const fetchOrder = (orderId, config) => axios.get(`/orders/${orderId}`, config)

export const cancelOrder = orderId => axios.delete(`/orders/${orderId}`)

export const createCateringOrder = payload => axios.post('/orders/catering', payload)

export const validateCateringOrder = payload => axios.post('/orders/catering/validate', payload)

export const advanceCateringOrderStatus = (statusKey, payload) =>
  axios.post(`/orders/catering/items/advance/${statusKey}`, payload)

//
// Synonyms
//
export const fetchSynonyms = (queryString = '', config) =>
  axios.get(`/synonyms${queryString}`, config)

export const fetchSynonym = (synonymId, config) => axios.get(`/synonyms/${synonymId}`, config)

export const deleteSynonym = synonymId => axios.delete(`/synonyms/${synonymId}`)

export const createSynonym = payload => axios.post(`/synonyms`, payload)

export const updateSynonym = (synonymId, payload) => axios.put(`/synonyms/${synonymId}`, payload)

//
// Merchandised Landing Pages (MLP)
//
export const fetchMerchandisedLandingPages = (queryString = '', config = {}) =>
  axios.get(`/merchandised_landing_page${queryString}`, { ...config })

export const fetchMerchandisedLandingPageById = (merchandisedLandingPageId, config) =>
  axios.get(`/merchandised_landing_page/${merchandisedLandingPageId}`, config)

export const createMerchandisedLandingPage = payload =>
  axios.post('/merchandised_landing_page', payload)

export const updateMerchandisedLandingPage = (merchandisedLandingPageId, payload) =>
  axios.put(`/merchandised_landing_page/${merchandisedLandingPageId}`, payload)

export const deleteMerchandisedLandingPage = (merchandisedLandingPageId, config) =>
  axios.delete(`/merchandised_landing_page/${merchandisedLandingPageId}`, config)

//
// Placements`
//
export const fetchPlacements = (queryString = '', config) =>
  axios.get(`/placements${queryString}`, config)

export const fetchPlacementContentByString = (queryString = '', config) =>
  axios.get(`/content${queryString}`, config)

export const fetchPlacementContentById = (placementId = '', config) =>
  axios.get(`/content/${placementId}`, config)

export const createPlacement = payload => axios.post('/content', payload)

export const updatePlacement = (placementId, payload) =>
  axios.put(`/content/${placementId}`, payload)

export const deletePlacement = (placementId = '', config) =>
  axios.delete(`/content/${placementId}`, config)

//
// Products
//
export const fetchProducts = (queryString = '', config) =>
  axios.get(`/products${queryString}`, config)

export const fetchProduct = (productId, config) => axios.get(`/products/${productId}`, config)

export const updateProduct = (productId, payload) => axios.put(`/products/${productId}`, payload)

//
// Subscriptions
//
export const fetchSubscriptions = () => axios.get('/enterprise_subscriptions')
export const fetchSubscription = subscriptionId =>
  axios.get(`/enterprise_subscriptions/${subscriptionId}`)
export const createSubscription = payload => axios.post('/enterprise_subscriptions', payload)
export const updateSubscription = (subscriptionId, payload) =>
  axios.put(`/enterprise_subscriptions/${subscriptionId}`, payload)
export const deleteSubscription = subscriptionId =>
  axios.delete(`/enterprise_subscriptions/${subscriptionId}`)

//
// Segments
//
export const fetchSegments = (queryString = '', config) =>
  axios.get(`/v2/customer_segments${queryString}`, config)

export const fetchSegment = (segmentId, config) =>
  axios.get(`/v2/customer_segments/${segmentId}`, config)

export const createSegment = payload => axios.post('/v2/customer_segments', payload)

export const createSegmentWithCSV = (payload, file) => {
  const formData = new FormData()
  Object.keys(payload).forEach(fieldKey => {
    const fieldValue = payload[fieldKey]
    formData.append(fieldKey, fieldValue)
  })
  formData.append('file', file, file.name)

  return axios.post('/customer_segments', formData)
}

export const getSegmentUserCountEstimate = payload =>
  axios.post('/v2/customer_segments/estimate', payload)

// Currently this can only be used to archive a list of segments by setting each of their respective status to false.
export const updateSegments = segmentsToUpdate =>
  axios.patch('/customer_segments', { items: segmentsToUpdate })

export const updateSegment = (segmentId, payload) =>
  axios.put(`/customer_segments/${segmentId}`, payload)

//
// Stores
//
export const fetchStores = (queryString = '', config) => axios.get(`/stores${queryString}`, config)

export const fetchEnabledStores = config => fetchStores('?show_ecommerce=true', config)

//
// Store Groups
//
export const fetchStoreGroups = (queryString = '', config) =>
  axios.get(`/store_groups${queryString}`, config)

export const fetchStoreGroup = (storeGroupId, config) =>
  axios.get(`/store_groups/${storeGroupId}`, config)

export const createStoreGroup = payload => axios.post('/store_groups', payload)

export const updateStoreGroup = (storeGroupId, payload) =>
  axios.put(`/store_groups/${storeGroupId}`, payload)

//
// Tags
//
export const fetchTags = () => axios.get(`/tags`)

//
// Keyword Redirects
//
export const fetchKeywordRedirects = (queryString = '', config) => {
  return axios.get(`/redirects${queryString}`, config)
}

export const fetchKeywordRedirect = (keywordRedirectId, config) => {
  return axios.get(`/redirects/${keywordRedirectId}`, config)
}

export const createKeywordRedirect = config => {
  return axios.post('/redirects', config)
}

export const updateKeywordRedirect = (keywordRedirectId, config) => {
  return axios.put(`/redirects/${keywordRedirectId}`, config)
}

export const deleteKeywordRedirect = (keywordRedirectId, config) => {
  return axios.delete(`/redirects/${keywordRedirectId}`, config)
}

//
// User (the current dashboard user)
//
export const fetchCurrentUser = () => axios.get('/user')

export const authenticateUser = payload => axios.post('/auth', payload)

export const unauthenticateUser = () => axios.delete('/auth')

export const changeUserPassword = (userId, payload) =>
  axios.put(`/users/${userId}/change_password`, payload)

export const sendResetPasswordEmail = payload => axios.post('/v2/users/password_reset', payload)

export const resetPasswordWithToken = (resetToken, payload) =>
  axios.put(`/users/password_reset/${resetToken}`, payload)

//
// Users (any user)
//
export const fetchUsers = (queryString = '', config) => axios.get(`/users${queryString}`, config)

export const fetchUser = (userId, config) => axios.get(`/users/${userId}`, config)

export const createUser = payload => axios.post('/users', payload)

export const createCateringUser = payload => axios.post('/users/catering', payload)

export const updateUser = (userId, payload) => axios.put(`/users/${userId}`, payload)

export const deleteUser = userId => axios.delete(`/users/${userId}`)

//
// Roles (for users)
//
export const fetchRoles = (queryString = '', config) => axios.get(`/v2/roles${queryString}`, config)

export const fetchRole = (roleId, config) => axios.get(`/v2/roles/${roleId}`, config)

export const createRole = payload => axios.post('/v2/roles', payload)

export const updateRole = (roleId, payload) => axios.put(`/v2/roles/${roleId}`, payload)

export const updateRoleUsers = (roleId, payload) => axios.put(`/v2/roles/${roleId}/users`, payload)

export const deleteRole = roleId => axios.delete(`/v2/roles/${roleId}`)

export const fetchRoleUsers = (roleId, config) => axios.get(`/v2/roles/${roleId}/users`, config)

export const fetchPermissionDepartments = config => axios.get(`/permission_departments`, config)

// Product Keyword Rules
export const fetchKeywordRules = (queryString = '', config) =>
  axios.get(`/search_rules/keywords${queryString}`, config)

export const fetchKeywordRule = (ruleId, config) =>
  axios.get(`/search_rules/keywords/${ruleId}`, config)

export const createKeywordRule = config => axios.post(`/search_rules/keywords`, config)

export const updateKeywordRule = (ruleId, payload) =>
  axios.put(`/search_rules/keywords/${ruleId}`, payload)

export const deleteKeywordRule = ruleId => axios.delete(`/search_rules/keywords/${ruleId}`)

// Product global search rules
export const fetchGlobalSearchSetting = config => axios.get('/search_rules/global', config)

export const updateGlobalSearchSetting = payload => axios.post('/search_rules/global', payload)

export const getExplainCart = cartId => axios.get(`/offers/explain/${cartId}`)
export const getOffer = payload => axios.post(`/offers/external`, payload)

// Recipe Keyword Rules
export const fetchRecipeKeywordRules = (queryString = '', config) =>
  axios.get(`/recipe_search_rules/keywords${queryString}`, config)

export const fetchRecipeKeywordRule = (ruleId, config) =>
  axios.get(`/recipe_search_rules/keywords/${ruleId}`, config)

export const createRecipeKeywordRule = config => axios.post(`/recipe_search_rules/keywords`, config)

export const updateRecipeKeywordRule = (ruleId, payload) =>
  axios.put(`/recipe_search_rules/keywords/${ruleId}`, payload)

export const deleteRecipeKeywordRule = ruleId =>
  axios.delete(`/recipe_search_rules/keywords/${ruleId}`)

// Recipe global search rules
export const fetchGlobalRecipeSearchSetting = config =>
  axios.get('/recipe_search_rules/global', config)

export const updateGlobalRecipeSearchSetting = payload =>
  axios.post('/recipe_search_rules/global', payload)

// Categories
export const fetchCategories = config => axios.get('/categories', config)

export const fetchCateringCategories = config =>
  axios.get('/categories/catering/departments', config)

export const fetchRecipeCategories = config => axios.get('/recipe_categories', config)

//
// Other
//

export const fetchBrands = config => axios.get('/brands', config)

export const fetchKeywordBrands = (keyword, config) =>
  axios.get(`/brands/search_term/${keyword}`, config)

export const fetchRecipeTags = config => axios.get('/recipe_tags', config)

export const fetchCreditTypes = config => axios.get('/credit_types', config)

export const searchProducts = payload => axios.post('/products/multi_search', payload)

export const uploadFile = file => {
  const formData = new FormData()
  formData.append('file', file, file.name)
  return axios.post('/upload/file', formData, { timeout: 600000 }) // Extend timeout to 1 minute for file uploads
}

export const uploadVideo = file => {
  const formData = new FormData()
  formData.append('file', file, file.name)
  return axios.post('/upload/video', formData, { timeout: 600000 }) // Extend timeout to 1 minute for file uploads
}

export const uploadImage = (image, config) => {
  const formData = new FormData()
  const queryString = Object.keys(config)
    .map(key => `${key}=${config[key]}`)
    .join('&')
  formData.append('file', image, image.name)
  return axios.post(`/upload/image?${queryString}`, formData, { timeout: 600000 }) // Extend timeout to 1 minute for file uploads
}

/*
 * TODO - consolidate this with the upload image endpoint
 * Currently uploading a product image hits a different endpoint than other image uploads
 * This endpoint returns { imageId: "...", imageUrl: "..." }
 * The imageId is then added to the PUT /product/:id payload
 */
export const uploadProductImage = (image, config, options) => {
  const { id: productId } = options
  const formData = new FormData()
  const queryString = Object.keys(config)
    .map(key => `${key}=${config[key]}`)
    .join('&')
  formData.append('file', image, image.name)
  return axios.post(`/products/${productId}/image?${queryString}`, formData)
}

//
// Knowledge Owl
//
export const getKnowledgeOwlOAuthToken = () => axios.get('/knowledge_owl/token')

//
// Facts
//

export const fetchFactValue = key => axios.get(`/facts/${key}`)

export const updateFactValue = (key, payload) => axios.put(`/facts/${key}`, payload)

export const fetchFactSchema = key => axios.get(`/facts/${key}/schema`)

export const fetchFactHistory = (key, queryString) =>
  axios.get(`/facts/${key}/history${queryString}`)
