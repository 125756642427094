import React from 'react'
import injectSheet from 'react-jss'

import { FormFieldItem, AddItems, Notification } from 'components'
import { reorder } from 'components/DataTable/utils/reorder'

import { fetchBrands, fetchKeywordBrands } from 'api'
import { get } from 'utils'

import styles from '../KeywordRuleDetail.styles'

/**
 * Since brands come back to us as just strings, we need to make them into
 * objects with constructed ids and their value under a `name` prop so we can
 * use them with the `DataTable` inside the "Browse Brands" modal.
 * We need to then supply the selected brands under this same scheme.
 */
const transformBrandStrings = brandStrings =>
  brandStrings.map((brandString, index) => ({
    id: `${index}`,
    name: brandString,
  }))

/**
 * Since selected brands need to take on the form of an object with just `name`, this helper
 * function finds these selected brands in the array of brand objects `brands`.
 */
const getSelectedBrands = (selectedBrands, brands) => {
  const brandsMap = brands.reduce((ret, brand) => {
    ret[brand.name] = brand
    return ret
  }, {})

  return selectedBrands.map(name => brandsMap[name]).filter(v => v !== undefined)
}

const BASE_ID = 'create-keyword-rule'

const KeywordBrands = ({
  classes,
  componentId,
  componentName,
  labelText,
  setFieldValue,
  handleSubmit,
  values,
  limit,
  modalTip,
  keyword,
  confirmClearAll,
  draggable,
}) => {
  return (
    <FormFieldItem
      id={componentId}
      labelText={labelText}
      name={componentName}
      render={renderProps => {
        let onDragEnd
        if (draggable) {
          onDragEnd = ({ source, destination }) => {
            if (!source || !destination) {
              // Bail out if the user didn't complete the drag/drop
              return
            }

            const initialArray = renderProps.value
            const reorderedBrands = reorder(initialArray, source.index, destination.index)
            setFieldValue(componentName, reorderedBrands)
            if (handleSubmit) {
              handleSubmit()
            }
          }
        }

        return (
          <>
            {limit && (
              <div className={classes.hintText}>
                {renderProps.value.length}/{limit} selected. You can select up to {limit} brands
              </div>
            )}
            <div className={classes.addCategoryContainer}>
              <AddItems
                id={`${BASE_ID}-boosted-brands`}
                confirmClearAll={confirmClearAll}
                limit={limit}
                guidance={{
                  modalInfo: modalTip
                    ? () => <Notification title="Tip" kind="info" message={modalTip} />
                    : null,
                }}
                fetchItems={config => {
                  const apiPromise = keyword
                    ? fetchKeywordBrands(keyword, config)
                    : fetchBrands(config)

                  // Transform `brands` to allow us to use them in the Autocomplete and Browse modal.
                  return apiPromise.then(({ brands: brandStrings }) => ({
                    items: transformBrandStrings(brandStrings),
                    itemCount: brandStrings.length,
                  }))
                }}
                itemName="brand"
                onChange={newSelectedBrands => {
                  newSelectedBrands = newSelectedBrands.map(a => a.name)
                  setFieldValue('boostBrands', newSelectedBrands)
                  handleSubmit()
                }}
                modalProps={{
                  renderInfoBlock: () => (
                    <Notification title="Tip" kind="info" message={modalTip} />
                  ),
                }}
                getSelectedItems={({ items: brands }) => {
                  if (brands.length) {
                    return getSelectedBrands(get(values, 'boostBrands'), brands)
                  }

                  return []
                }}
                removeSelectedItemsFromBox
                draggable={draggable}
                onDragEnd={onDragEnd}
              />
            </div>
          </>
        )
      }}
    />
  )
}

export default injectSheet(styles)(KeywordBrands)
