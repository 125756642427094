import { mixins } from 'styling'

export default theme => {
  const formItem = mixins.formItem({ theme })
  return {
    formItem: {
      ...formItem.default,
    },
    horizontal: {
      ...formItem.horizontal,
    },
    vertical: {
      ...formItem.vertical,
    },
  }
}
