import cx from 'classnames'
import React from 'react'
import injectSheet from 'react-jss'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Button, Modal } from 'components'
import { createToast } from 'modules/toasts'
import { capitalize } from 'utils'

const styles = theme => ({
  rightOfHeaderContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',

    '& > *': {
      marginBottom: 0,
      marginLeft: theme.spacing.md,

      '&:first-child': {
        marginLeft: theme.spacing.xs,
      },
    },
  },
  bulkActions: {
    '& > *': {
      marginLeft: theme.spacing.lg,
    },
  },
})

const ArchiveButton = ({
  classes,
  createToast,
  itemsById,
  itemType,
  refetchItems,
  selectedRowIds,
  selectedRowItems,
  updateSelectedRowIds,
  updateItems,
}) => {
  // Determine whether or not to show Archive/Restore for Bulk actions on selected items
  let shouldShowArchiveForBulkAction
  let hasArchivedItems

  const numSelectedRows = selectedRowItems.length
  if (numSelectedRows > 0) {
    // Show Archive bulk action unless ALL of the selected items are archived
    // (i.e. show Archive bulk action if even just one of the selected items is active)
    const numActiveItems = selectedRowItems.filter(selectedRow => selectedRow.status).length
    const hasActiveItems = numActiveItems > 0
    hasArchivedItems = numActiveItems < numSelectedRows
    shouldShowArchiveForBulkAction = hasActiveItems
  }

  return (
    <div className={cx(classes.rightOfHeaderContainer, classes.bulkActions)}>
      <Modal.Confirmation
        handleCloseModal={({ wasConfirmed }) => {
          if (wasConfirmed) {
            const newItems = selectedRowIds.map(id => ({
              id,
              // Set `status` to `false` to archive, `true` to restore all selected items
              status: !shouldShowArchiveForBulkAction,
            }))
            updateItems(newItems)
              .then(() => {
                createToast({
                  kind: 'success',
                  message: `${capitalize(itemType)} successfully ${
                    shouldShowArchiveForBulkAction ? 'archive' : 'restore'
                  }d.`,
                })
                refetchItems()
                updateSelectedRowIds([])
                // TODO: Ensure user isn't going to land on an empty last page
              })
              .catch(({ message }) => {
                createToast({ kind: 'error', message })
              })
          }
        }}
        triggerRender={({ openModal }) => (
          <Button kind={shouldShowArchiveForBulkAction ? 'danger' : 'primary'} onClick={openModal}>
            {shouldShowArchiveForBulkAction ? 'Archive' : 'Restore'}
          </Button>
        )}
        contentProps={{
          actionText: `${
            shouldShowArchiveForBulkAction ? 'archive' : 'restore'
          } the selected ${itemType}s`,
          details:
            shouldShowArchiveForBulkAction && hasArchivedItems
              ? `If any archived ${itemType}s were selected, they will simply remain archived.`
              : null,
          confirmButtonText: shouldShowArchiveForBulkAction ? 'Archive' : 'Restore',
        }}
      />
    </div>
  )
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createToast,
    },
    dispatch
  )

export default injectSheet(styles)(connect(null, mapDispatchToProps)(ArchiveButton))
