import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import injectSheet from 'react-jss'

import styles from './Tabs.styles'

class Tab extends Component {
  static propTypes = {
    className: PropTypes.string,
    handleTabAnchorFocus: PropTypes.func,
    handleTabClick: PropTypes.func,
    handleTabKeyDown: PropTypes.func,
    href: PropTypes.string.isRequired,
    index: PropTypes.number,
    label: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
    /*
     * An optional parameter to allow overriding the anchor rendering.
     * Useful for using Tab along with react-router.
     * */
    renderAnchor: PropTypes.func,
  }

  static defaultProps = {
    href: '#',
    selected: false,
  }

  setTabFocus(event) {
    const leftKey = 37
    const rightKey = 39
    if (event.which === leftKey) {
      this.props.handleTabAnchorFocus(this.props.index - 1)
    } else if (event.which === rightKey) {
      this.props.handleTabAnchorFocus(this.props.index + 1)
    }
  }

  render() {
    const {
      classes,
      className,
      handleTabClick,
      handleTabAnchorFocus,
      handleTabKeyDown,
      href,
      index,
      selected: isSelected,
      renderAnchor,
      render, // renders children
      label,
      theme,
      ...restProps
    } = this.props

    const anchorProps = {
      className: classes.tabLink,
      href,
      role: 'tab',
      tabIndex: 0,
      ref: e => {
        this.tabAnchor = e
      },
    }

    return (
      <li
        {...restProps}
        tabIndex={-1}
        className={cx(className, classes.tab, {
          [classes.tabSelected]: isSelected,
        })}
        onClick={event => {
          handleTabClick(index, event)
        }}
        onKeyDown={event => {
          this.setTabFocus(event)
          handleTabKeyDown(index, event)
        }}
        role="presentation"
        selected={isSelected}
      >
        {renderAnchor ? renderAnchor(anchorProps) : <a {...anchorProps}>{label}</a>}
      </li>
    )
  }
}

export default injectSheet(styles)(Tab)
