import debounce from 'lodash/debounce'
import React, { Component } from 'react'
import injectSheet from 'react-jss'

import { fetchCollections, fetchProducts, uploadFile } from 'api'
import {
  Checkbox,
  FileSelect,
  DropdownFilterable,
  FormFieldItem,
  RadioButtons,
  TextInput,
} from 'components'

import { get } from 'utils'

import AddProducts from '../../../Segments/AddProducts'

import styles from '../PromotedPlacementsForm.styles'

import { PlacementCategories } from '.'

const findAndSetSelectedObject = ({
  filteredArray,
  formValue,
  originalArray,
  selectedItem,
  setFieldValue,
}) => {
  let selectedObject = originalArray.find(item => item.id === selectedItem)

  // If value doesn't exist in initial list, check the filtered items
  if (!selectedObject && filteredArray) {
    selectedObject = filteredArray.find(item => item.id === selectedItem)
  }
  setFieldValue(formValue, selectedObject)
}

class PlacementLinkDestination extends Component {
  state = {
    collections: [],
    products: [],
    isFetchingCollections: false,
    isFetchingProducts: false,
    filteredCollections: null,
    filteredProducts: null,
  }

  componentDidMount() {
    this.loadCollectionsDropdown()
    this.loadProductsDropdown()
  }

  formatDropdown(dropdownItems) {
    const formattedDropdownItems = dropdownItems.map(({ id, name, ...restProps }) => ({
      id,
      label: name,
      name,
      value: id,
      ...restProps,
    }))

    return formattedDropdownItems
  }

  getDebouncedCollections = debounce(inputValue => {
    this.setState({ isFetchingCollectionsAsync: true })

    fetchCollections(`?status=true&search=${inputValue}`)
      .then(({ items }) => {
        this.setState({ filteredCollections: items })
      })
      .finally(() => {
        this.setState({ isFetchingCollectionsAsync: false })
      })
  }, 250)

  getDebouncedProducts = debounce(inputValue => {
    this.setState({ isFetchingProductsAsync: true })

    fetchProducts(`?status=true&search=${inputValue}`)
      .then(({ items }) => {
        this.setState({ filteredProducts: items })
      })
      .finally(() => {
        this.setState({ isFetchingProductsAsync: false })
      })
  }, 250)

  loadCollectionsDropdown() {
    const {
      values: {
        target: { collection },
      },
    } = this.props
    const existingCollectionQuery = collection && collection.name ? collection.name : ''

    this.setState({ isFetchingCollections: true })

    fetchCollections(`?status=true&search=${existingCollectionQuery}`)
      .then(({ items }) => {
        this.setState({
          collections: this.formatDropdown(items),
        })
      })
      .finally(() => {
        this.setState({ isFetchingCollections: false })
      })
  }

  loadProductsDropdown() {
    const {
      values: {
        target: { product },
      },
    } = this.props
    const existingProductQuery = product && product.name ? product.name : ''

    this.setState({ isFetchingProducts: true })

    fetchProducts(`?status=true&search=${existingProductQuery}`)
      .then(({ items }) => {
        this.setState({
          products: this.formatDropdown(items),
        })
      })
      .finally(() => {
        this.setState({ isFetchingProducts: false })
      })
  }

  render() {
    const {
      classes,
      componentId,
      radioButtonProps,
      setErrors,
      setFieldValue,
      setSubmitting,
      values: {
        placement,
        target: { url: existingFileUrl, ...existingTarget },
      },
    } = this.props
    const {
      collections,
      filteredCollections,
      filteredProducts,
      products,
      isFetchingCollections,
      isFetchingCollectionsAsync,
      isFetchingProducts,
      isFetchingProductsAsync,
    } = this.state

    return (
      <FormFieldItem
        className={classes.formRow}
        id={componentId}
        labelText={radioButtonProps.length > 1 ? 'Type' : ''}
        name="target.type"
        render={({ id, name, value }) => (
          <div>
            {radioButtonProps.length > 1 && (
              <RadioButtons
                className={classes.formInput}
                id={id}
                name={name}
                onChange={newType => {
                  setFieldValue(name, newType)
                }}
                orientation="horizontal"
                selectedValue={value}
                type="text"
                radioButtonProps={radioButtonProps}
              />
            )}

            {value === 'categories' && (
              <PlacementCategories
                componentId={`${componentId}-placement-categories`}
                componentName="target.categories"
                labelText="Select Categories"
                setFieldValue={setFieldValue}
              />
            )}

            {value === 'collection' && (
              <FormFieldItem
                id={`${componentId}-collection`}
                labelText="Collection"
                name="target.collection"
                render={collectionDropdownFilterProps => (
                  <DropdownFilterable
                    className={classes.formInput}
                    id={`${componentId}-collection`}
                    initialValue={collectionDropdownFilterProps.value}
                    isLoading={isFetchingCollections}
                    isLoadingAsync={isFetchingCollectionsAsync}
                    items={collections}
                    filteredItems={
                      filteredCollections ? this.formatDropdown(filteredCollections) : null
                    }
                    onChange={selectedItem =>
                      findAndSetSelectedObject({
                        filteredArray: filteredCollections,
                        formValue: `target.${value}`,
                        originalArray: collections,
                        selectedItem,
                        setFieldValue,
                      })
                    }
                    onInputChange={value => this.getDebouncedCollections(value)}
                    placeholder="Collections"
                    selectedValue={get(collectionDropdownFilterProps.value, 'id')}
                  />
                )}
              />
            )}

            {value === 'product' && (
              <FormFieldItem
                id={`${componentId}-product`}
                labelText="Product"
                name="target.product"
                render={productDropdownFilterProps => (
                  <DropdownFilterable
                    className={classes.formInput}
                    id={`${componentId}-product`}
                    initialValue={productDropdownFilterProps.value}
                    isLoading={isFetchingProducts}
                    isLoadingAsync={isFetchingProductsAsync}
                    items={products}
                    filteredItems={filteredProducts ? this.formatDropdown(filteredProducts) : null}
                    onChange={selectedItem =>
                      findAndSetSelectedObject({
                        filteredArray: filteredProducts,
                        formValue: `target.${value}`,
                        originalArray: products,
                        selectedItem,
                        setFieldValue,
                      })
                    }
                    onInputChange={value => this.getDebouncedProducts(value)}
                    placeholder="Products"
                    selectedValue={get(productDropdownFilterProps.value, 'id')}
                  />
                )}
              />
            )}

            {value === 'products' && (
              <FormFieldItem
                className={classes.addCategoryContainer}
                id={`${componentId}-products`}
                labelText="Select Products"
                name="target.products"
                render={({ name }) => (
                  <AddProducts
                    id={`${componentId}-products`}
                    selectedProducts={existingTarget.products}
                    onSelectedProductsChange={newSelectedProducts => {
                      setFieldValue(name, newSelectedProducts)
                    }}
                  />
                )}
              />
            )}

            {value === 'url' && placement.key !== 'flyer_pdf' && (
              <FormFieldItem
                id={`${componentId}-url`}
                labelText="Enter URL"
                name="target.url"
                render={urlTextInputRenderProps => (
                  <TextInput
                    {...urlTextInputRenderProps}
                    className={classes.formInput}
                    placeholder="Link URL"
                    type="text"
                  />
                )}
              />
            )}

            {value === 'url' && placement.key === 'flyer_pdf' && (
              <FormFieldItem
                className={classes.formInput}
                id={`${componentId}-pdf`}
                labelText="Upload PDF"
                name="target.url"
                render={({ id, name, value }) => (
                  <FileSelect
                    id={id}
                    existingFileUrl={existingFileUrl || null}
                    onFileSelect={newFile => {
                      return uploadFile(newFile)
                        .then(file => {
                          setSubmitting(false)
                          setFieldValue(name, file.fileUrl)
                          setErrors({})
                          return true
                        })
                        .catch(error => {
                          setSubmitting(false)
                          setFieldValue(name, null)
                          setErrors({ global: error.message ?? error })
                          return false
                        })
                    }}
                  />
                )}
              />
            )}

            {value === 'url' && (
              <FormFieldItem
                id={`${componentId}-url-open-in`}
                name="target.openIn"
                render={({ value, ...urlOpenInProps }) => (
                  <Checkbox
                    {...urlOpenInProps}
                    checked={value}
                    className={classes.formInput}
                    labelText="Open in new tab"
                  />
                )}
              />
            )}
          </div>
        )}
      />
    )
  }
}

export default injectSheet(styles)(PlacementLinkDestination)
