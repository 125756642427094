import PropTypes from 'prop-types'
import React, { Component } from 'react'

import DataTable from './DataTable'
import { normalizeForDataTableWithState } from './state/normalizing'
import { getSortedState, sortStates } from './state/sorting'
import { defaultFilterRows } from './utils/filtering'
import { reorder } from './utils/reorder'

/**
 * DataTableWithState allows for the handling of DataTable state (filtering, sorting, paging, selection
 * across multiple pages) for tables whose entire data is loaded at once (unlike other tables which have so
 * many rows that only a small subset from a given page can be fetched from the BE at one time;
 * in those cases the BE would then also manage things like the filtering and sorting of that data).
 */
export default class DataTableWithState extends Component {
  static propTypes = {
    /**
     * Just like a rowAction defined in a table row, is the node to render in the row
     * action column which should execute an action affecting all the rows in the table.
     */
    bulkAction: PropTypes.node,
    /**
     * Function to manually control filtering of the rows from the TableToolbarSearch
     * component - otherwise uses default filtering algorithm
     */
    filterRows: PropTypes.func,
    /**
     * The `headers` prop represents the order in which the header columns should
     * appear in the table. We expect an array of objects to be passed in, where
     * `key` is the name of the key in a row object, and `header` is the name/label of
     * the header. The `headers` are required for normalizing data and filtering.
     */
    headers: PropTypes.array.isRequired,
    id: PropTypes.string.isRequired,
    initialSelectedRowIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    /**
     * The `render` function passed into DataTable
     */
    render: PropTypes.func,
    /**
     * Object of props which are accessible in the DataTable's render function
     */
    renderProps: PropTypes.object,
    /**
     * The `rows` prop is where you provide a list of all the rows that
     * you want to render in the table. The only hard requirement is that this
     * is an array of objects, and that each object has a unique `id` field
     * available on it. Rows can also have the following keys which alter the row's
     * functionality or style: `rowAction`, `rowClass`, `rowDetails`, `rowLinkTo`.
     */
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      })
    ).isRequired,
    /**
     * The `unformattedRows` prop is where you provide a list of all the rows data without any formatting
     */
    unformattedRows: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      })
    ),
  }

  static defaultProps = {
    filterRows: defaultFilterRows,
    initialSelectedRowIds: [],
    unformattedRows: [],
  }

  state = {}

  static getDerivedStateFromProps(props, state) {
    // On initial render or any subsequent changes to `rows` prop
    if (!state.lastRowsProp || props.rows !== state.lastRowsProp) {
      const { rowIds, rowsById, cellsById } = normalizeForDataTableWithState(
        props.rows,
        props.headers
      )

      // Reset state
      const newState = {
        lastRowsProp: props.rows,

        rowIds,
        rowsById,
        cellsById,

        // Paging
        pageNumber: state.pageNumber || 1,
        resultsPerPage: state.resultsPerPage || 20,
        resultsTotal: props.rows.length,

        // Filtering
        filterInputValue: state.filterInputValue || '',

        // Sorting
        initialRowOrder: rowIds.slice(), // Copy over rowIds so the reference doesn't mutate the stored `initialRowOrder`
        sortDirection: state.sortDirection || sortStates.NONE,
        sortHeaderKey: state.sortHeaderKey || null,

        // Selection
        selectedRowIds: props.initialSelectedRowIds,
      }

      return newState
    }
    return null
  }

  /**
   * On complete function for drag events
   */
  onDragEnd = data => {
    const originalRowIds = this.state.rowIds.slice(0)
    const originalRows = this.props.unformattedRows.slice(0)

    if (data.source && data.destination) {
      // Since a large dataset can span multiple pages, add an offset to support multiple pages
      const pageOffset = (this.state.pageNumber - 1) * this.state.resultsPerPage
      const startIndex = data.source.index + pageOffset
      const endIndex = data.destination.index + pageOffset
      // we need to send the source and dest indices with the appropriate offset
      const updatedData = {
        ...data,
        source: {
          ...data.source,
          index: startIndex,
        },
        destination: {
          ...data.destination,
          index: endIndex,
        },
      }
      const updatedRowIds = reorder(originalRowIds, startIndex, endIndex)

      // Set new rowIds and fire callback
      this.setState(
        {
          rowIds: updatedRowIds,
        },
        () =>
          this.props.onDragEnd({
            data: updatedData,
            originalRows, // use the original data to compare ID (should include position data)
            rowAboveId: updatedRowIds[endIndex - 1],
            rowBelowId: updatedRowIds[endIndex + 1],
          })
      )
    }
  }

  /**
   * Handler for paging related changes (# rows per page, current page number)
   */
  handlePagingChange = ({ pageNumber, resultsPerPage }) => {
    this.setState({ pageNumber, resultsPerPage })
  }

  /**
   * Handler for transitioning to the next sort state of the table
   */
  handleSortBy = ({ nextSortHeaderKey, nextSortDirection }) => {
    this.setState(({ rowIds, cellsById, initialRowOrder }) =>
      getSortedState({
        rowIds,
        cellsById,
        initialRowOrder,
        key: nextSortHeaderKey,
        sortDirection: nextSortDirection,
      })
    )
  }

  /**
   * Handler for updating the filterInputValue
   */
  handleFilterInputValueChange = newFilterValue => {
    this.setState({ filterInputValue: newFilterValue, pageNumber: 1 })
  }

  /**
   * Handler for updating the internal selectedRowIds state as well as alerting
   * any consuming component.
   * NOTE: `selectedRowIdsAfterSelection` will be all currently selected row ids that DataTable tracks,
   * whereas `allSelectedRowIdsAfterSelection` which we pass to the consuming component will include
   * selected row ids for all of the rows that `DataTableWithState` tracks (so across all pages,
   * and including those that may be currently filtered out)
   */
  handleSelectedRowsChange = (selectedRowIdsAfterSelection, unselectedRowIds = []) => {
    const { onSelectedRowsChange } = this.props
    this.setState(state => {
      // Filter out current state's selectedRowIds by those unselected in DataTable
      const allSelectedRowIdsAfterUnselection =
        unselectedRowIds.length > 0
          ? state.selectedRowIds.filter(rowId => !unselectedRowIds.includes(rowId))
          : [...state.selectedRowIds]

      // Filter out the selectedRowIdsAfterSelection from DataTable by those we already have in our state's selectedRowIds
      const newSelectedRowIdsAfterSelection = selectedRowIdsAfterSelection.filter(
        rowId => !state.selectedRowIds.includes(rowId)
      )

      // Combine the two together to get our final list of *unique* selected row ids
      const allSelectedRowIdsAfterSelection = [
        ...allSelectedRowIdsAfterUnselection,
        ...newSelectedRowIdsAfterSelection,
      ]

      if (onSelectedRowsChange) {
        onSelectedRowsChange(allSelectedRowIdsAfterSelection)
      }

      return { selectedRowIds: allSelectedRowIdsAfterSelection }
    })
  }

  render() {
    const {
      bulkAction,
      component,
      filterRows,
      headers,
      onSelectedRowsChange,
      rows,
      renderProps,
      ...restProps
    } = this.props

    const {
      cellsById,
      filterInputValue,
      pageNumber,
      resultsPerPage,
      resultsTotal,
      rowIds,
      rowsById,
      sortDirection,
      sortHeaderKey,
      selectedRowIds,
    } = this.state

    const isFiltering = !!filterInputValue

    // Filter rows from all pages by `filterInputValue`
    const filteredRowIds = filterRows({
      rowIds,
      headers,
      cellsById,
      inputValue: filterInputValue,
    })

    // Determine which rows should be shown for the current page
    const startRowIndex = (pageNumber - 1) * resultsPerPage
    const endRowIndex = startRowIndex + resultsPerPage
    const rowIdsOnCurrentPage = filteredRowIds.slice(startRowIndex, endRowIndex)

    const dataTableProps = {
      ...restProps,

      bulkAction,

      // Data derived from props
      headers,

      // Data derived from state
      rows: rowIdsOnCurrentPage.map(id => ({
        ...rowsById[id],
      })),
      sortDirection,
      sortHeaderKey,
      selectedRowIds,
      pagingProps: {
        pageNumber,
        resultsPerPage,
        resultsTotal: isFiltering ? filteredRowIds.length : resultsTotal,
        onPagingChange: this.handlePagingChange,
      },

      // Event handlers for updating internal state
      onDragEnd: this.onDragEnd,
      onSelectedRowsChange: this.handleSelectedRowsChange,
      onSortBy: this.handleSortBy,

      // Custom render props
      renderProps: {
        filterInputValue,
        onFilterInputChange: this.handleFilterInputValueChange,
        ...renderProps,
      },
    }

    return <DataTable {...dataTableProps} />
  }
}
