import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import injectSheet from 'react-jss'

import RadioButton from './RadioButton'

const styles = theme => ({
  horizontalContainer: {
    display: 'flex',
  },
  endLabel: {
    fontSize: theme.fontSize.p,
    color: theme.text01,
    fontFamily: theme.fontFamilySansSerif,
    lineHeight: theme.baseLineHeight,
    minHeight: 16,
  },
})

class RadioButtons extends Component {
  static propTypes = {
    className: PropTypes.string,
    /**
     * A label that gets appended at the end of each radio button.
     * (Looks like a radio button without its button.)
     */
    endLabel: PropTypes.string,
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    orientation: PropTypes.oneOf(['horizontal', 'vertical']).isRequired,
    radioButtonProps: PropTypes.arrayOf(
      PropTypes.shape({
        className: PropTypes.string,
        disabled: PropTypes.bool,
        id: PropTypes.string.isRequired,
        labelText: PropTypes.node.isRequired,
        value: PropTypes.any,
        tooltipText: PropTypes.string,
        hasTooltip: PropTypes.bool,
      })
    ),
    /**
     * This value is checked against the value of each radio button in `radioButtonProps`.
     */
    selectedValue: PropTypes.any,
  }

  static defaultProps = {
    orientation: 'vertical',
  }

  onChange = checkedValue => {
    const { onChange } = this.props
    if (onChange) {
      onChange(checkedValue)
    }
  }

  render() {
    const {
      classes,
      className,
      endLabel,
      id,
      orientation,
      radioButtonProps,
      selectedValue,
    } = this.props

    return (
      <div
        className={cx(className, {
          [classes.horizontalContainer]: orientation === 'horizontal',
        })}
      >
        {radioButtonProps.map(
          ({
            className,
            disabled: isDisabled,
            id: radioButtonId,
            labelText,
            tooltip,
            value,
            ...otherProps
          }) => (
            <RadioButton
              className={className}
              checked={value === selectedValue}
              disabled={isDisabled}
              id={`${id}-${radioButtonId}`}
              key={radioButtonId}
              labelText={labelText}
              tooltip={tooltip}
              onChange={this.onChange}
              orientation={orientation}
              value={value}
              {...otherProps}
            />
          )
        )}
        {endLabel && <div className={classes.endLabel}>{endLabel}</div>}
      </div>
    )
  }
}

export { RadioButtons as RadioButtonsUnStyled }
export default injectSheet(styles)(RadioButtons)
