import { Formik } from 'formik'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as yup from 'yup'

import { createKeywordRedirect, updateKeywordRedirect, fetchKeywordRedirect } from 'api'
import { LoadingIndicator, Notification, PageHeader } from 'components'
import { createToast } from 'modules/toasts'
import { getBackButton, getRouteTo } from 'routing'
import Styling from 'styling/components'
import { get, ItemFetcher } from 'utils'

import KeywordRedirectForm from './KeywordRedirectForm'

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createToast,
    },
    dispatch
  )

const CreateOrEditKeywordRedirect = connect(
  null,
  mapDispatchToProps
)(
  ({
    backButton,
    createToast,
    headerTitle,
    initialValues = {
      name: '',
      keywords: [],
      redirectCategory: '',
      url: '',
      isExternalUrl: false,
    },
    history,
    onFormSubmit,
    successMessage,
  }) => (
    <div>
      <PageHeader backButton={backButton} hasDivider headerTitle={headerTitle} />
      <Formik
        initialValues={initialValues}
        validationSchema={() =>
          yup.object().shape({
            name: yup
              .string()
              .trim()
              .required()
              .label('Name'),
            keywords: yup
              .array()
              .min(1)
              .max(10)
              .required()
              .label('Keywords'),
            redirectCategory: yup
              .string()
              .max(40)
              .trim()
              .required()
              .label('Category Name'),
            url: yup
              .string()
              .trim()
              .required()
              .label('Redirect Destination'),
            isExternalUrl: yup.boolean().label('External'),
          })
        }
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values, form) => {
          onFormSubmit(values)
            .then(() => {
              form.setSubmitting(false)
              createToast({ kind: 'success', message: successMessage })
              history.push(getRouteTo('searchSettings.keywordRedirects'))
            })
            .catch(error => {
              form.setSubmitting(false)
              form.setErrors({ global: error.message })
            })
        }}
        render={renderProps => (
          <KeywordRedirectForm {...renderProps} cancelDestination={backButton.to} />
        )}
      />
    </div>
  )
)

export const CreateKeywordRedirect = ({ location, ...restProps }) => {
  /**
   * - `duplicateId` is passed in when we want to open the Create Keyword Redirect page but with it filled
   * with the data from the keyword redirect represented by that id
   */
  const duplicateId = get(location, 'state.duplicateId')

  const propsToPass = {
    backButton: get(location, 'state.backButton'),
    headerTitle: 'Create Keyword Redirect',
    onFormSubmit: formValues => createKeywordRedirect(formValues),
    successMessage: 'Successfully created keyword redirect.',
    ...restProps,
  }

  if (duplicateId) {
    return (
      <ItemFetcher
        queryKey="keywordRedirect"
        queryParams={{ duplicateId }}
        fetchItem={config => fetchKeywordRedirect(duplicateId, config)}
        render={({
          item: keywordRedirect,
          isLoadingItem: isFetchingKeywordRedirect,
          error: errorFetchingKeywordRedirect,
        }) => {
          if (isFetchingKeywordRedirect) {
            return <LoadingIndicator withTopMargin />
          }
          if (errorFetchingKeywordRedirect) {
            return (
              <Styling.Center maxWidth={500} withTopMargin>
                <Notification kind="error" message={errorFetchingKeywordRedirect.message} />
              </Styling.Center>
            )
          }
          if (keywordRedirect) {
            propsToPass.backButton =
              propsToPass.backButton ||
              getBackButton('searchSettings.keywordRedirects', {
                id: keywordRedirect.id,
                label: keywordRedirect.name,
              })
            propsToPass.initialValues = keywordRedirect

            return <CreateOrEditKeywordRedirect {...propsToPass} />
          }
        }}
      />
    )
  }
  propsToPass.backButton =
    propsToPass.backButton || getBackButton('searchSettings.keywordRedirects')

  return <CreateOrEditKeywordRedirect {...propsToPass} />
}

export const EditKeywordRedirect = ({
  location,
  match: {
    params: { keywordRedirectId },
  },
  ...restProps
}) => (
  <ItemFetcher
    queryKey="keywordRedirect"
    queryParams={{ id: keywordRedirectId }}
    fetchItem={config => fetchKeywordRedirect(keywordRedirectId, config)}
    render={({ item: keywordRedirect, isLoadingItem: isFetchingKeywordRedirect }) => {
      if (isFetchingKeywordRedirect) {
        return <LoadingIndicator withTopMargin />
      }
      if (!keywordRedirect) return null
      const propsToPass = {
        backButton:
          get(location, 'state.backButton') ||
          getBackButton('searchSettings.keywordRedirects', {
            id: keywordRedirect.id,
            label: keywordRedirect.name,
          }),
        headerTitle: 'Edit Keyword Redirect',
        initialValues: keywordRedirect,
        onFormSubmit: formValues => updateKeywordRedirect(keywordRedirectId, formValues),
        successMessage: 'Successfully updated keyword redirect.',
        ...restProps,
      }

      return <CreateOrEditKeywordRedirect {...propsToPass} />
    }}
  />
)
