import PropTypes from 'prop-types'
import React, { Component } from 'react'
import injectSheet from 'react-jss'

import { TextInput, ImageUpload } from 'components'

import FormLabel from '../Form/FormLabel'

const styles = theme => ({
  container: {
    marginTop: theme.spacing.xs,
    width: '100%',
  },
})

class ImageUploadWithAltText extends Component {
  static propTypes = {
    /**
     * `height` to validate image size
     */
    height: PropTypes.number,
    /**
     * `id` to be attached to the `file`-type input
     */
    id: PropTypes.string.isRequired,
    /**
     * `fileRequirementsText` is displayed under the "No file selected" text placeholder.
     */
    fileRequirementsText: PropTypes.string,
    /**
     * Promise which handles uploading the file to a server.
     */
    onImageUpload: PropTypes.func.isRequired,
    /**
     * Function to be called every time an image completes.
     */
    onImageUploadComplete: PropTypes.func.isRequired,
    /**
     * Function to be called if there is an error uploading the image.
     */
    onUploadError: PropTypes.func,
    /**
     * Configuration options to pass along to upload function
     */
    uploadOptions: PropTypes.object,
    /**
     * On Change function for alt text field
     */
    altTextOnChange: PropTypes.func,
    /**
     * Field ID
     */
    fieldId: PropTypes.string,
    /**
     * Name of the input
     */
    name: PropTypes.string,
    /**
     * Whether to allow video files as well.
     */
    allowVideo: PropTypes.bool,
    /**
     * Make it more compact.
     */
    compact: PropTypes.bool,
  }

  state = {
    file: null,
    image: null,
    touched: false,
  }

  render() {
    const { classes, name, fieldId, initialImage, altTextOnChange } = this.props
    const { file, image, touched } = this.state

    let imageUrl = null

    // Check if an initial value is provided, ignore after an edit has been made
    if (initialImage && (initialImage.imageUrl || initialImage.fileUrl) && !touched) {
      imageUrl = initialImage.imageUrl ?? initialImage.fileUrl
    } else if (image) {
      imageUrl = image.imageUrl
    } else if (file) {
      imageUrl = file.fileUrl
    }

    return (
      <>
        <ImageUpload {...this.props} />
        {imageUrl && (
          <div className={classes.container}>
            <FormLabel htmlFor={`${fieldId}-alt-image-text`} labelText="Alt Text (Required)" />
            <TextInput
              id={`${fieldId}-alt-image-text`}
              name={`${name}.altText`}
              value={initialImage ? initialImage.altText : ''}
              placeholder="Enter alt text"
              type="text"
              onChange={altTextOnChange}
            />
          </div>
        )}
      </>
    )
  }
}

ImageUploadWithAltText.defaultProps = {
  image: {},
  height: null,
  onImageUpload: new Promise(resolve => setTimeout(resolve, 200)),
  onUploadError: () => {},
  removable: true,
  uploadOptions: {},
  vertical: false,
  width: null,
  validateSize: true,
  allowVideo: false,
  compact: false,
}

export { ImageUploadWithAltText as ImageUploadWithAltTextUnStyled }
export default injectSheet(styles)(ImageUploadWithAltText)
