import React from 'react'
import injectSheet from 'react-jss'

const styles = theme => ({
  outdatedTotal: {
    marginRight: theme.spacing.xs,
    textDecoration: 'line-through',
  },
})

const OrderTotals = ({ classes, estimated, final }) => {
  if (final && final !== estimated) {
    return (
      <span>
        <span className={classes.outdatedTotal}>{estimated}</span>
        <span>{final}</span>
      </span>
    )
  }
  return <span>{estimated}</span>
}

export default injectSheet(styles)(OrderTotals)
