export default {
  fetch: {
    id: 'fetch',
    value: 'fetch',
    label: 'Data Access',
  },
  delete: {
    id: 'delete',
    value: 'delete',
    label: 'Data & Account Deletion',
  },
}
