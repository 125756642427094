import { BrowserOptions, init, setTag } from '@sentry/react'
import { CaptureConsole } from '@sentry/integrations'

import config from 'config'
import { UAT_MODE } from 'parseOptions'

// Customize sample rate per env:
// 100% of prd, 20% of dev events get sent.
const samplingRate = {
  default: 0.6,
  dev: 0.2,
  prd: 1,
  [UAT_MODE]: 1,
}

const setupSentry = () => {
  if (!config.sentryClientDSN) {
    return
  }

  setTag('enterprise-config-version', config.$enterpriseConfigVersion)
  setTag(
    'service-name',
    `${config.env.mode}-${config.client}-platform-${config.env.variant}.dashboard-v2`
  )
  setTag('variant', config.env.variant)

  const options: BrowserOptions = {
    dist: config.client,
    dsn: config.sentryClientDSN,
    environment: config.env.mode,
    integrations: [new CaptureConsole({ levels: ['error'] })],
    release: process.env.CI_TAG,
    tracesSampleRate: samplingRate[config.env.mode] || samplingRate.default,
  }
  init(options)
}

export default setupSentry
