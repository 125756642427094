import React from 'react'
import { Redirect } from 'react-router-dom'

import { getRoutePathname, getRouteTo } from 'routing'
import { FixedScrollSpyPage } from 'layouts'
import { renderV1 } from 'DashboardV1'

import PromotedPlacements from './PromotedPlacements/PromotedPlacements'
import {
  PromotedPlacementsCreate,
  PromotedPlacementsEdit,
} from './PromotedPlacements/PromotedPlacementsCreateOrEdit'
import PromotedPlacementsDetails from './PromotedPlacements/PromotedPlacementsDetails'

import SearchSynonyms from './SearchSettings/Synonyms/SearchSynonyms'
import { CreateSynonym, EditSynonym } from './SearchSettings/Synonyms/CreateOrEditSynonyms'

import Segments from './Segments/Segments'
import CreateRuleBasedSegment from './Segments/CreateRuleBasedSegment'

import StoreGroups from './StoreGroups/StoreGroups'
import StoreGroup from './StoreGroups/StoreGroup'
import { CreateStoreGroup, EditStoreGroup } from './StoreGroups/CreateOrEditStoreGroup'

import KeywordRedirects from './SearchSettings/KeywordRedirects/KeywordRedirects'
import {
  CreateKeywordRedirect,
  EditKeywordRedirect,
} from './SearchSettings/KeywordRedirects/CreateOrEditKeywordRedirect'

import KeywordRules from './SearchSettings/KeywordRules/KeywordRules'
import { EditKeywordRuleDetail } from './SearchSettings/KeywordRules/EditKeywordRuleDetail'

import RecipeKeywordRules from './SearchSettings/KeywordRules/RecipeKeywordRules'

import { EditGlobalSearchSettings } from './SearchSettings/GlobalSearchSettings/EditGlobalSearchSettings'
import { EditRecipeGlobalSearchSettings } from './SearchSettings/GlobalRecipeSearchSettings/EditGlobalRecipeSearchSettings'

const routeDefinitions = [
  {
    path: '/marketing/promoted-placements', // We don't use `getRoutePathname` here since this path gets redirected to a different route
    render: () => <Redirect to={getRouteTo('promotedPlacements.content', { slug: 'homepage' })} />,
  },
  {
    path: getRoutePathname('promotedPlacements.content', {
      id: ':promotedPlacementId',
      slug: ':slug',
    }),
    component: PromotedPlacements,
  },
  {
    path: getRoutePathname('promotedPlacements.details', {
      id: ':id',
      slug: ':slug',
    }),
    component: PromotedPlacementsDetails,
  },
  {
    path: getRoutePathname('promotedPlacements.create', {
      id: ':id',
      slug: ':slug',
    }),
    component: PromotedPlacementsCreate,
    layout: FixedScrollSpyPage,
    layoutProps: {
      dynamicTitle: true,
      prependTitle: 'Create',
    },
  },
  {
    path: getRoutePathname('promotedPlacements.edit', {
      id: ':id',
      slug: ':slug',
    }),
    component: PromotedPlacementsEdit,
    layout: FixedScrollSpyPage,
    layoutProps: {
      dynamicTitle: true,
      prependTitle: 'Edit',
    },
  },
  {
    path: '/marketing/segments-and-store-groups', // We don't use `getRoutePathname` here since this path gets redirected to a different route
    render: () => <Redirect to={getRouteTo('segments')} />,
  },

  { path: getRoutePathname('segments'), component: Segments },
  { path: getRoutePathname('segments.createSegment'), component: CreateRuleBasedSegment },

  {
    path: getRoutePathname('offers'),
    render: renderV1,
  },

  {
    path: '/marketing/flyer-management',
    render: () => <Redirect to={getRouteTo('flyerManagement')} />,
  },
  {
    path: getRoutePathname('flyerManagement'),
    render: renderV1,
  },
  {
    path: getRoutePathname('flyerPreviewSettings'),
    render: renderV1,
  },

  {
    path: getRoutePathname('emailTemplates'),
    render: renderV1,
  },

  { path: getRoutePathname('storeGroups'), component: StoreGroups },
  { path: getRoutePathname('storeGroups.createStoreGroup'), component: CreateStoreGroup },
  {
    path: getRoutePathname('storeGroups.editStoreGroup', { id: ':storeGroupId' }),
    component: EditStoreGroup,
  },
  {
    path: getRoutePathname('storeGroups.storeGroup', { id: ':storeGroupId' }),
    component: StoreGroup,
  },
  {
    path: '/marketing/search-settings', // We don't use `getRoutePathname` here since this path gets redirected to a different route
    render: () => <Redirect to={getRouteTo('searchSettings.keywordRules')} />,
  },
  {
    path: getRoutePathname('searchSettings.globalSearchSettings'),
    component: EditGlobalSearchSettings,
  },
  {
    path: getRoutePathname('searchSettings.globalRecipeSearchSettings'),
    component: EditRecipeGlobalSearchSettings,
  },
  {
    path: getRoutePathname('searchSettings.keywordRedirects'),
    component: KeywordRedirects,
  },
  {
    path: getRoutePathname('searchSettings.keywordRedirects.createKeywordRedirect'),
    component: CreateKeywordRedirect,
  },
  {
    path: getRoutePathname('searchSettings.keywordRedirects.editKeywordRedirect', {
      id: ':keywordRedirectId',
    }),
    component: EditKeywordRedirect,
  },
  { path: getRoutePathname('searchSettings.keywordRules'), component: KeywordRules },
  {
    path: getRoutePathname('searchSettings.keywordRules.editKeywordRule', {
      id: ':keywordRuleDetailId',
    }),
    render: props => <EditKeywordRuleDetail keywordType="product" {...props} />,
  },
  { path: getRoutePathname('searchSettings.recipeKeywordRules'), component: RecipeKeywordRules },
  {
    path: getRoutePathname('searchSettings.recipeKeywordRules.editRecipeKeywordRule', {
      id: ':keywordRuleDetailId',
    }),
    render: props => <EditKeywordRuleDetail keywordType="recipe" {...props} />,
  },
  { path: getRoutePathname('searchSettings.synonyms'), component: SearchSynonyms },
  { path: getRoutePathname('searchSettings.synonyms.createSynonym'), component: CreateSynonym },
  {
    path: getRoutePathname('searchSettings.synonyms.editSynonym', {
      id: ':id',
    }),
    component: EditSynonym,
  },
]

export default routeDefinitions
