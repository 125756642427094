import memoize from 'memoize-one'
import React, { Fragment } from 'react'
import injectSheet from 'react-jss'
import { connect } from 'react-redux'
import { Value } from 'react-values'
import { bindActionCreators } from 'redux'

import { deleteUser, fetchUsers } from 'api'
import {
  Button,
  ButtonToggle,
  DataTable,
  DropdownMenu,
  DropdownMenuItem,
  Modal,
  PageHeader,
  ReadOnlyValueDetails,
  SearchInput,
  ShowIfAuthorized,
} from 'components'
import { DEFAULT_TABLE_LIMIT } from 'defaults'
import { createToast } from 'modules/toasts'
import { getUserPermissionKeys } from 'modules/user'
import { generateBackButton, getRoutePathname, getRouteTo } from 'routing'
import { ItemsFetcherWithParams } from 'utils'

const PAGE_ID = 'users'

const styles = theme => ({
  rightOfHeaderContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: 500,

    '& > *': {
      marginBottom: 0,
      marginLeft: theme.spacing.md,
    },
  },
  // TODO: Create a new Styling component for this flex basis use case
  filterSearch: {
    flexBasis: '320px',
  },
  userRoleLink: {
    marginRight: theme.spacing.md,

    '&:last-child': {
      marginRight: 0,
    },
  },
})

const tableParams = {
  paging: {
    type: 'multi',
    multiParams: { limit: { key: 'limit' }, offset: { key: 'offset' } },
  },
  search: { key: 'search' },
  sorting: {
    type: 'multi',
    multiParams: { direction: { key: 'direction' }, orderBy: { key: 'order_by' } },
  },
}

const tableHeaders = [
  {
    key: 'firstName',
    header: 'First Name',
  },
  {
    key: 'lastName',
    header: 'Last Name',
  },
  {
    key: 'email',
    header: 'Email',
  },
  {
    key: 'roleCount',
    header: '# of Roles',
    disableSorting: true,
  },
]

const UserRoleDetails = ({ classes, user: { id: userId, roles } }) => (
  <ReadOnlyValueDetails
    detailValues={[
      {
        id: `user-${userId}-roles`,
        labelText: 'Assigned Roles',
        useCustomValueEl: true,
        value: (
          <Fragment>
            {roles.map(role => (
              <Button
                key={role.id}
                className={classes.userRoleLink}
                href={getRouteTo('roles.role', { id: role.id })}
                kind="link"
              >
                {role.name}
              </Button>
            ))}
          </Fragment>
        ),
      },
    ]}
  />
)

const generateUserActionDropdown = ({
  userPermissions,
  createToast,
  history,
  location,
  refetchUsers,
  user,
}) => {
  return (
    <DropdownMenu
      ariaLabel="More user actions"
      menuAlignment="right"
      triggerRender={({ description, isOpen, onClick }) => (
        <ButtonToggle
          icon="more"
          id={`user-${user.id}-action`}
          isToggled={isOpen}
          onClick={onClick}
          untoggledDescription={description}
        />
      )}
    >
      {userPermissions.includes('users.edit') && (
        <DropdownMenuItem
          icon="edit"
          kind="primary"
          onClick={() => {
            history.push({
              pathname: getRoutePathname('users.editUser', { id: user.id }),
              state: { backButton: generateBackButton('users', location) },
            })
          }}
        >
          Edit User
        </DropdownMenuItem>
      )}

      {userPermissions.includes('users.edit') && (
        <DropdownMenuItem
          icon="delete"
          kind="danger"
          hasDivider
          renderButton={({ buttonProps, iconEl }) => {
            return (
              <Modal.Confirmation
                handleCloseModal={({ wasConfirmed }) => {
                  if (wasConfirmed) {
                    deleteUser(user.id)
                      .then(() => {
                        createToast({
                          kind: 'success',
                          message: 'User successfully deleted.',
                        })
                        refetchUsers()
                        // TODO: Ensure user isn't going to land on an empty last page
                      })
                      .catch(({ message }) => {
                        createToast({ kind: 'error', message })
                      })
                  }
                }}
                triggerRender={({ openModal }) => (
                  <button
                    {...buttonProps}
                    // Overwrite buttonProps' `onClick`, we don't want to close the dropdown menu
                    // because the confirmation modal would be unmounted
                    onClick={() => {
                      openModal()
                    }}
                  >
                    {iconEl}
                    Delete User
                  </button>
                )}
                contentProps={{
                  actionText: 'delete this user',
                  confirmButtonText: 'Delete',
                }}
              />
            )
          }}
        />
      )}
    </DropdownMenu>
  )
}

class Users extends React.Component {
  formatUserRows = memoize((users, refetchUsers) => {
    const { classes, createToast, history, location, userPermissions } = this.props
    return users.map(user => {
      const { id, firstName, lastName, email, roleCount } = user

      return {
        id: `user-${id}`,
        firstName,
        lastName,
        email,
        roleCount,
        rowDetails: <UserRoleDetails classes={classes} user={user} />,
        rowAction: generateUserActionDropdown({
          userPermissions,
          createToast,
          history,
          location,
          refetchUsers,
          user,
        }),
      }
    })
  })

  render() {
    const { classes, history, location } = this.props
    return (
      <ItemsFetcherWithParams
        queryKey="users"
        fetchItems={fetchUsers}
        routingParams={{
          location,
          history,
        }}
        paramDefinitions={tableParams}
        render={({
          params: tableParams,
          items: users,
          itemCount: userCount,
          isLoadingItems: isFetchingUsers,
          error: errorFetchingUsers,
          refetch: refetchUsers,
        }) => {
          const tableRows = this.formatUserRows(users, refetchUsers)

          // Paging props
          const resultsPerPage =
            parseInt(tableParams.paging.multiParams.limit.value, 10) || DEFAULT_TABLE_LIMIT
          const pageNumber = (tableParams.paging.multiParams.offset.value || 0) / resultsPerPage + 1

          return (
            <div>
              <PageHeader headerTitle="User Management">
                <Value defaultValue={tableParams.search.value || ''}>
                  {({ set: handleChange, value }) => (
                    <div className={classes.rightOfHeaderContainer}>
                      <SearchInput
                        className={classes.filterSearch}
                        id={`${PAGE_ID}-filter-search`}
                        onClear={oldInputValue => {
                          // If the user clears the search input containing the current search param,
                          // reload the page with the search param cleared
                          if (oldInputValue === tableParams.search.value) {
                            tableParams.search.onChange(null)
                          }
                        }}
                        onChange={handleChange}
                        onSubmit={tableParams.search.onChange}
                        placeholder="Search by name, email"
                        value={value}
                      />
                      <ShowIfAuthorized requiredPermission="users.create">
                        <Button
                          href={{
                            pathname: getRoutePathname('users.createUser'),
                            state: { backButton: generateBackButton('users', location) },
                          }}
                          id={`${PAGE_ID}-create`}
                        >
                          Create New User
                        </Button>
                      </ShowIfAuthorized>
                    </div>
                  )}
                </Value>
              </PageHeader>

              <DataTable
                error={errorFetchingUsers}
                headers={tableHeaders}
                id={`${PAGE_ID}-table`}
                sortHeaderKey={tableParams.sorting.multiParams.orderBy.value}
                sortDirection={tableParams.sorting.multiParams.direction.value}
                isLoadingNewRows={isFetchingUsers}
                onSortBy={({ nextSortHeaderKey, nextSortDirection }) => {
                  tableParams.sorting.onChange({
                    orderBy: nextSortHeaderKey,
                    direction: nextSortDirection,
                  })
                }}
                rows={tableRows}
                pagingProps={{
                  pageNumber,
                  resultsPerPage,
                  onPagingChange: ({ pageNumber, resultsPerPage }) => {
                    tableParams.paging.onChange({
                      limit: resultsPerPage,
                      offset: (pageNumber - 1) * resultsPerPage || undefined,
                    })
                  },
                  resultsTotal: userCount,
                }}
              />
            </div>
          )
        }}
      />
    )
  }
}

const stateToProps = state => ({
  userPermissions: getUserPermissionKeys(state),
})

const dispatchToProps = dispatch => bindActionCreators({ createToast }, dispatch)

export default injectSheet(styles)(connect(stateToProps, dispatchToProps)(Users))
