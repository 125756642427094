import React, { Fragment, useEffect, useState } from 'react'
import injectSheet from 'react-jss'
import { connect } from 'react-redux'
import { NumberValue } from 'react-values'
import { bindActionCreators } from 'redux'

import config from 'config'
import { fetchCollection, updateCollection } from 'api'
import {
  Button,
  Icon,
  LoadingIndicator,
  Modal,
  Notification,
  PageHeader,
  ReadOnlyValueCard,
  ShowIfAuthorized,
  Tab,
  Tabs,
  Tooltip,
} from 'components'

import { createToast } from 'modules/toasts'
import Styling from 'styling/components'
import { getBackButton, getRoutePathname, getRouteTo } from 'routing'
import { formatDatetime, get, isNullOrUndefined, ItemFetcher } from 'utils'

import { CollectionProducts } from './CollectionProducts'
import styles from './Collection.styles'
import { CollectionProductsRulesWithState } from './CollectionProductsRules'
import { fetchData, getSortByItems } from './CollectionFormProducts'
import { mapCollectionRule } from './CollectionCreateOrEdit'

const PAGE_ID = 'collection'

const reprocessCollectionRules = rules => {
  const attribute_rule = rules.find(rule => rule.id != null)

  if (attribute_rule?.id === 1) {
    if (rules.length > 1) {
      rules.splice(rules.indexOf(attribute_rule), 1)
    }
    rules.forEach(rule => {
      rule.id = attribute_rule.id
    })
  }

  return rules
}

const Collection = ({
  classes,
  createToast,
  location,
  history,
  match: {
    params: { collectionId },
  },
}) => {
  const [
    { boostPastPurchases, dynamicCategories, dynamicAttributes, dynamicTags },
    setData,
  ] = useState({})

  useEffect(() => {
    fetchData().then(data => {
      setData(data)
    })
  }, [])

  if (!collectionId) {
    return null
  }

  return (
    <ItemFetcher
      queryKey="collection"
      queryParams={{ collectionId }}
      fetchItem={itemConfig => fetchCollection(collectionId, itemConfig)}
      render={({
        item: collection,
        isLoadingItem: isFetchingCollection,
        error: errorFetchingCollection,
        refetch: refetchCollection,
      }) => {
        if (isFetchingCollection) {
          return <LoadingIndicator withTopMargin />
        }
        if (errorFetchingCollection) {
          return (
            <Styling.Center maxWidth={500} withTopMargin>
              <Notification kind="error" message={errorFetchingCollection.message} />
            </Styling.Center>
          )
        }
        if (collection) {
          const {
            created,
            createdBy,
            description,
            displayName,
            id,
            images: { desktop, mobile },
            modified,
            modifiedBy,
            products,
            dynamic,
            status: collectionStatus,
          } = collection

          // make it an 'AND' clause to fulfill the operational need for 'Purchased Before'
          collection.rules = reprocessCollectionRules(collection.rules)

          // Fix to set existing collections to true by default instead of undefined
          // This can be removed once BE should updates this at the API level
          const status = isNullOrUndefined(collectionStatus) ? true : collectionStatus

          const sortByItems = getSortByItems({
            boostPastPurchases,
            dynamicAttributes,
            rules: collection.rule,
          })

          return (
            <Fragment>
              <PageHeader
                backButton={get(location, 'state.backButton') || getBackButton('collections')}
                headerTitle={collection.name}
                statusIsActive={status}
                statusText={status ? 'Active' : 'Inactive'}
                statusType={status ? 'complete' : 'cancelled'}
              >
                <div className={classes.headerCollectionButtons}>
                  {status && (
                    <ShowIfAuthorized requiredPermission="collections.edit">
                      <Button
                        kind="primary"
                        onClick={() =>
                          history.push(getRouteTo('collections.editCollection', { id }))
                        }
                      >
                        Edit
                      </Button>
                    </ShowIfAuthorized>
                  )}
                  <ShowIfAuthorized requiredPermission="collections.create">
                    <Button
                      href={{
                        pathname: getRoutePathname('collections.createCollection'),
                        state: { duplicateId: id },
                      }}
                      kind="secondary"
                    >
                      Duplicate
                    </Button>
                  </ShowIfAuthorized>
                  <ShowIfAuthorized requiredPermission="collections.edit">
                    <Modal.Confirmation
                      handleCloseModal={({ wasConfirmed }) => {
                        if (wasConfirmed) {
                          const newCollection = { ...collection, status: !status }
                          updateCollection(id, newCollection)
                            .then(() => {
                              createToast({
                                kind: 'success',
                                message: `Collection successfully ${
                                  newCollection.status ? 'restored' : 'archived'
                                }.`,
                              })
                              refetchCollection()
                            })
                            .catch(({ message }) => {
                              createToast({ kind: 'error', message })
                            })
                        }
                      }}
                      triggerRender={({ openModal }) => (
                        <Button kind={status ? 'danger' : 'primary'} onClick={openModal}>
                          {status ? 'Archive' : 'Restore'}
                        </Button>
                      )}
                      contentProps={{
                        actionText: `${status ? 'archive' : 'restore'} this collection`,
                        confirmButtonText: status ? 'Archive' : 'Restore',
                      }}
                    />
                  </ShowIfAuthorized>
                </div>
              </PageHeader>
              <div className={classes.underHeaderContainer}>
                <NumberValue defaultValue={0}>
                  {({ value: selectedTab, set: selectTab }) => (
                    <Tabs selectedTab={selectedTab} onSelectTab={selectTab}>
                      <Tab label="Detail">
                        <div className={classes.collectionTabContainer}>
                          <div className={classes.leftSide}>
                            <ReadOnlyValueCard
                              detailValues={[
                                {
                                  id: `${PAGE_ID}-detail-display-title`,
                                  labelText: 'Display Title',
                                  value: displayName || 'n/a',
                                },
                                {
                                  id: `${PAGE_ID}-detail-url`,
                                  labelText: 'URL',
                                  useCustomValueEl: true,
                                  value: (
                                    <Button
                                      href={`${config.env.webUrl}/shop/collections/${id}`}
                                      icon="external"
                                      iconPosition="inline"
                                      kind="link"
                                      linkIconShowOnHover
                                      linkIsExternal
                                      toolTipText="Product may not be available in all stores"
                                    >
                                      {`${config.env.webUrl}/shop/collections/${id}`}
                                    </Button>
                                  ),
                                },
                                {
                                  id: `${PAGE_ID}-detail-count`,
                                  labelText: 'Products',
                                  value: dynamic ? 'Dynamic' : products.length,
                                },
                                {
                                  id: `${PAGE_ID}-sort-by`,
                                  isHidden: !dynamic,
                                  labelText: 'Sort By',
                                  value: sortByItems.find(
                                    ({ value }) => value === collection.sortBy
                                  )?.label,
                                },
                                {
                                  id: `${PAGE_ID}-max-count`,
                                  isHidden: !dynamic,
                                  labelText: 'Maximum Number of Products in Collection',
                                  value: collection.maxCount || 'Unlimited',
                                },
                                {
                                  id: `${PAGE_ID}-detail-desktop-header`,
                                  labelText: 'Desktop Header',
                                  useCustomValueEl: true,
                                  value: desktop ? (
                                    <div className={classes.detailImage}>
                                      <img alt="" src={desktop.imageUrl} />
                                    </div>
                                  ) : (
                                    'n/a'
                                  ),
                                },
                                {
                                  id: `${PAGE_ID}-detail-desktop-header-alt-text`,
                                  labelText: 'Image Alt Text (Desktop)',
                                  value: desktop ? desktop.altText : 'n/a',
                                },
                                {
                                  id: `${PAGE_ID}-detail-mobile-header`,
                                  labelText: 'Mobile Header',
                                  useCustomValueEl: true,
                                  value: mobile ? (
                                    <div className={classes.detailImage}>
                                      <img alt="" src={mobile.imageUrl} />
                                    </div>
                                  ) : (
                                    'n/a'
                                  ),
                                },
                                {
                                  id: `${PAGE_ID}-detail-mobile-header-alt-text`,
                                  labelText: 'Image Alt Text (Mobile)',
                                  value: mobile ? mobile.altText : 'n/a',
                                },
                                {
                                  id: `${PAGE_ID}-detail-description`,
                                  labelText: 'Description',
                                  value: description || 'n/a',
                                },
                                {
                                  id: `${PAGE_ID}-detail-created`,
                                  labelText: 'Created',
                                  value: created ? formatDatetime(created) : 'n/a',
                                },
                                {
                                  id: `${PAGE_ID}-detail-created-by`,
                                  labelText: 'Created By',
                                  value: createdBy
                                    ? `${createdBy.firstName} ${createdBy.lastName}`
                                    : 'n/a',
                                },
                                {
                                  id: `${PAGE_ID}-detail-last-update`,
                                  labelText: 'Last Update',
                                  value: modified ? formatDatetime(modified) : 'n/a',
                                },
                                {
                                  id: `${PAGE_ID}-detail-updated-by`,
                                  labelText: 'Updated By',
                                  value: modifiedBy
                                    ? `${modifiedBy.firstName} ${modifiedBy.lastName}`
                                    : 'n/a',
                                },
                              ]}
                            />
                          </div>
                          <div className={classes.rightSide}>
                            <h2 className={classes.collectionTableTitle}>Included Products</h2>
                            {dynamic ? (
                              <>
                                <div>
                                  <span>Dynamic Collection</span>
                                  <Tooltip
                                    position="top"
                                    text="Products vary on individual user's past purchases."
                                    wrap={false}
                                  >
                                    <Icon
                                      name="info"
                                      className={classes.dynamicCollectionTooltip}
                                    />
                                  </Tooltip>
                                </div>
                                <br />
                                <CollectionProductsRulesWithState
                                  pageId={PAGE_ID}
                                  items={collection.rules.map(rule => mapCollectionRule(rule))}
                                  dynamicCategories={dynamicCategories}
                                  dynamicAttributes={dynamicAttributes}
                                  dynamicTags={dynamicTags}
                                />
                              </>
                            ) : (
                              <CollectionProducts
                                pageId={PAGE_ID}
                                items={collection.products || []}
                              />
                            )}
                          </div>
                        </div>
                      </Tab>
                    </Tabs>
                  )}
                </NumberValue>
              </div>
            </Fragment>
          )
        }
      }}
    />
  )
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createToast,
    },
    dispatch
  )

export default injectSheet(styles)(connect(null, mapDispatchToProps)(Collection))
