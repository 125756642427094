import memoize from 'memoize-one'
import React, { Component, Fragment } from 'react'
import injectSheet from 'react-jss'
import slugify from 'slugify'

import {
  fetchCollection,
  fetchMerchandisedLandingPageById,
  updateMerchandisedLandingPage,
} from 'api'
import {
  Button,
  DataTableWithState,
  LoadingIndicator,
  Modal,
  Notification,
  PageHeader,
  ReadOnlyValueCard,
  ShowIfAuthorized,
  StatusIndicatorBadge,
  Tab,
  Tabs,
} from 'components'
import config from 'config'
import { createToast } from 'modules/toasts'

import Styling, { ErrorText, ProductImagesRow } from 'styling/components'
import { generateBackButton, getBackButton, getRoutePathname } from 'routing'
import { capitalize, formatDate, ItemFetcher, openWebPlatform } from 'utils'

import { initializeMediaContent } from '../../Marketing/PromotedPlacements/PromotedPlacementsDetails'

const PAGE_ID = 'merchandise-landing-page'

const styles = theme => ({
  contentTableWrapper: {
    '& th:first-of-type': {
      width: '1%',
    },
  },
  leftSide: {
    flexShrink: 0,
    marginRight: theme.spacing.xxlg,
    width: 272,
  },
  rightSide: {
    flexGrow: 1,
    flexShrink: 1,
  },
  imageWidth: {
    verticalAlign: 'top',
  },
})

const statusMap = {
  readable: {
    approved: {
      confirm: 'approve',
      trigger: 'approve',
    },
    hidden: {
      confirm: 'hide',
      trigger: 'hide',
    },
    pending: {
      confirm: 'submit',
      trigger: 'submit',
    },
    rejected: {
      confirm: 'reject',
      trigger: 'reject',
    },
  },
  buttonStyle: {
    approved: 'primary',
    hidden: 'danger',
    pending: 'primary',
    rejected: 'secondary',
  },
  statusIndicator: {
    approved: 'completed',
    completed: 'canceled',
    draft: 'in-progress',
    pending: 'in-progress',
    rejected: 'canceled',
  },
}

const tableHeaders = [
  {
    key: 'formattedType',
    header: 'Type',
    disableSorting: true,
  },
  {
    key: 'title',
    header: 'Title',
    disableSorting: true,
  },
  {
    key: 'preview',
    header: '',
    disableSorting: true,
  },
]

const formatTableRows = memoize((content, location, collectionMap, collectionErrorMap) =>
  content.map(contentItem => {
    const { type, data } = contentItem
    const { id } = data

    let title
    let preview
    let formattedType

    if (type === 'content_placement') {
      title = (
        <Button
          href={{
            pathname: getRoutePathname('promotedPlacements.details', { id, slug: 'mlp-banner' }),
            state: { backButton: generateBackButton('merchandisedLandingPages.details', location) },
          }}
          kind="link"
        >
          {data.name || 'Unknown'}
        </Button>
      )
      formattedType = <StatusIndicatorBadge type={'error'} text={'Banner'} />
    } else if (type === 'collection') {
      const { name, display_name: displayName } = data

      formattedType = <StatusIndicatorBadge type={'new'} text={'Collection'} />

      if (collectionMap.has(id)) {
        preview = <ProductImagesRow products={collectionMap.get(id).products || []} />
      } else if (collectionErrorMap.has(id)) {
        preview = (
          <ErrorText
            label={
              <>
                <strong>Failed to get collection preview:</strong> {collectionErrorMap.get(id)}
              </>
            }
          />
        )
        collectionErrorMap.get(id)
      } else {
        preview = (
          <div>
            Loading <LoadingIndicator.Dots />
          </div>
        )
      }

      title = (
        <Button
          href={{
            pathname: getRoutePathname('collections.collection', { id }),
            state: { backButton: generateBackButton('merchandisedLandingPages.details', location) },
          }}
          kind="link"
        >
          {displayName || name || 'Unknown'}
        </Button>
      )
    }

    return {
      id: `${type}_${id}`,
      formattedType,
      preview,
      title,
    }
  })
)

class MerchandisedLandingPageDetailsFields extends Component {
  state = {
    collectionMap: new Map(),
    collectionErrorMap: new Map(),
    selectedTab: 0,
  }

  componentDidMount() {
    const {
      mlpResponse: { mlp_content: mlpContent },
    } = this.props
    const loadCollections = async () => {
      mlpContent
        .filter(c => c.type === 'collection')
        .map(c =>
          fetchCollection(c.data.id)
            .then(collection => {
              this.setState(prevState => {
                const newMap = new Map(prevState.collectionMap)
                newMap.set(collection.id, collection)
                return {
                  collectionMap: newMap,
                }
              })
            })
            .catch(err => {
              this.setState(prevState => {
                const errorMap = new Map(prevState.collectionErrorMap)
                errorMap.set(c.data.id, err.message)
                return {
                  collectionErrorMap: errorMap,
                }
              })
            })
        )
    }

    loadCollections()
  }

  formatModalConfirmationButton = buttonStatus => {
    const { mlpResponse } = this.props
    const { mlp, mlp_content: mlpContent } = mlpResponse
    const { id } = mlp

    const modalConfirmationProps = {
      handleCloseModal: ({ wasConfirmed }) => {
        const updatedMerchandisedLandingPage = {
          ...mlp,
          status: buttonStatus,
          content: mlpContent.map(c => ({
            ...c,
            data: {
              id: c.data.id,
            },
          })),
        }

        const successToast = () => {
          createToast({
            kind: 'success',
            message: `Merchandised Landing Page successfully ${updatedMerchandisedLandingPage.status}.`,
          })
        }

        if (wasConfirmed) {
          updateMerchandisedLandingPage(id, updatedMerchandisedLandingPage)
            .then(() => {
              successToast()
              this.props.refetchMerchandisedLandingPage()
            })
            .catch(({ message }) => {
              createToast({ kind: 'error', message })
            })
        }
      },
      triggerRender: ({ openModal }) => (
        <Button kind={statusMap.buttonStyle[buttonStatus]} onClick={openModal}>
          {capitalize({ phrase: statusMap.readable[buttonStatus].trigger })}
        </Button>
      ),
      contentProps: {
        actionText: `${statusMap.readable[buttonStatus].trigger} this Merchandised Landing Page`,
        confirmButtonText: capitalize({ phrase: statusMap.readable[buttonStatus].confirm }),
      },
    }

    return <Modal.Confirmation {...modalConfirmationProps} />
  }

  selectTab = selectedTab => {
    this.setState({ selectedTab })
  }

  render() {
    const { classes, location, mlpResponse } = this.props
    const { selectedTab, collectionMap, collectionErrorMap } = this.state

    const { mlp_content: content } = mlpResponse

    const {
      created,
      customerSegment,
      endDate,
      id,
      images,
      modified,
      name: merchandisedLandingPageName,
      placement,
      startDate,
      status,
      storeGroup,
    } = mlpResponse.mlp

    const contentArray = initializeMediaContent({
      classes,
      media: { type: 'image' },
      promotedMedia: images,
    })

    return (
      <Fragment>
        <PageHeader
          backButton={getBackButton('merchandisedLandingPages')}
          headerTitle={merchandisedLandingPageName}
          statusText={capitalize({ phrase: status })}
          statusType={statusMap.statusIndicator[status]}
        >
          <Styling.LineOfItems alignCenter>
            <ShowIfAuthorized requiredPermission="content.edit">
              {status === 'pending' ? this.formatModalConfirmationButton('rejected') : null}
              {status === 'pending' ? this.formatModalConfirmationButton('approved') : null}

              {status === 'draft' || status === 'rejected' || status === 'hidden' ? (
                <Button
                  href={{
                    pathname: getRoutePathname(
                      'merchandisedLandingPages.editMerchandisedLandingPage',
                      {
                        id,
                      }
                    ),
                    state: {
                      merchandisedLandingPagesPlacement: placement,
                      id,
                    },
                  }}
                  kind="secondary"
                >
                  Edit
                </Button>
              ) : null}
              {status === 'draft' ? this.formatModalConfirmationButton('pending') : null}
              <Button
                href={{
                  pathname: getRoutePathname(
                    'merchandisedLandingPages.createMerchandisedLandingPage',
                    {
                      id,
                    }
                  ),
                  state: {
                    merchandisedLandingPagesPlacement: placement,
                    duplicateId: id,
                  },
                }}
                kind="secondary"
              >
                Duplicate
              </Button>

              {status !== 'completed' && status !== 'hidden'
                ? this.formatModalConfirmationButton('hidden')
                : null}
            </ShowIfAuthorized>
          </Styling.LineOfItems>
        </PageHeader>
        <Styling.LineOfItems>
          <div className={classes.leftSide}>
            <ReadOnlyValueCard
              detailValues={[
                {
                  id: `${PAGE_ID}-name`,
                  labelText: 'Title',
                  value: merchandisedLandingPageName,
                },
                {
                  id: `${PAGE_ID}-created`,
                  labelText: 'Created',
                  value: formatDate(created),
                },
                {
                  id: `${PAGE_ID}-last-edit`,
                  labelText: 'Last Edit',
                  value: formatDate(modified),
                },
                {
                  id: `${PAGE_ID}-status`,
                  labelText: 'Status',
                  value: status,
                },
                {
                  id: `${PAGE_ID}-url`,
                  labelText: 'URL',
                  useCustomValueEl: true,
                  value: (
                    <Button
                      kind="link"
                      onClick={() => {
                        openWebPlatform(
                          `shop/featured/${id}/${slugify(merchandisedLandingPageName)}`
                        )
                      }}
                    >
                      {`${config.env.webUrl}/shop/featured/${id}/${slugify(
                        `shop/featured/${id}/${slugify(merchandisedLandingPageName)}`
                      )}`}
                    </Button>
                  ),
                },
              ]}
            />
          </div>
          <div className={classes.rightSide}>
            <Tabs onSelectTab={this.selectTab} selectedTab={selectedTab}>
              <Tab label="Content">
                <div className={classes.contentTableWrapper}>
                  <DataTableWithState
                    emptyMsg="No content added"
                    headers={tableHeaders}
                    id={`${PAGE_ID}-content-table`}
                    kind="light-tall-row"
                    filterRows={({ rowIds }) => rowIds}
                    rows={formatTableRows(content, location, collectionMap, collectionErrorMap)}
                  />
                </div>
              </Tab>
              <Tab label="Marketing">
                <ReadOnlyValueCard
                  detailValues={[
                    {
                      id: `${PAGE_ID}-display`,
                      labelText: 'Display',
                      value: status === 'hidden' ? `Hide from navigation` : `Show in navigation`,
                    },
                    {
                      id: `${PAGE_ID}-active-period`,
                      labelText: 'Active Period',
                      value: `${formatDate(startDate)} - ${formatDate(endDate)}`,
                    },
                    {
                      id: `${PAGE_ID}-store-group`,
                      labelText: 'Store Group',
                      useCustomValueEl: true,
                      value: storeGroup ? (
                        <Button
                          href={{
                            pathname: getRoutePathname('storeGroups.storeGroup', {
                              id: storeGroup.id,
                            }),
                          }}
                          kind="link"
                        >
                          {storeGroup.name}
                        </Button>
                      ) : (
                        'All Stores'
                      ),
                    },
                    {
                      id: `${PAGE_ID}-segment`,
                      labelText: 'User Segment',
                      value: customerSegment ? customerSegment.name : 'Everyone',
                    },
                    ...contentArray,
                  ]}
                />
              </Tab>
            </Tabs>
          </div>
        </Styling.LineOfItems>
      </Fragment>
    )
  }
}

const MerchandisedLandingPage = ({
  match: {
    params: { id },
  },
  ...restProps
}) => {
  return id ? (
    <ItemFetcher
      queryKey="mlpById"
      queryParams={{ id }}
      fetchItem={itemConfig => fetchMerchandisedLandingPageById(id, itemConfig)}
      render={({
        item: mlpResponse,
        isLoadingItem: isFetchingMerchandisedLandingPage,
        error: errorFetchingMerchandisedLandingPage,
        refetch: refetchMerchandisedLandingPage,
      }) => {
        if (isFetchingMerchandisedLandingPage) {
          return <LoadingIndicator withTopMargin />
        }
        if (errorFetchingMerchandisedLandingPage) {
          return (
            <Styling.Center maxWidth={500} withTopMargin>
              <Notification kind="error" message={errorFetchingMerchandisedLandingPage.message} />
            </Styling.Center>
          )
        }
        if (!mlpResponse) return null
        return (
          <MerchandisedLandingPageDetailsFields
            {...restProps}
            mlpResponse={mlpResponse}
            refetchMerchandisedLandingPage={refetchMerchandisedLandingPage}
          />
        )
      }}
    />
  ) : null
}

export default injectSheet(styles)(MerchandisedLandingPage)
