import { Formik } from 'formik'
import React from 'react'
import injectSheet from 'react-jss'
import { connect } from 'react-redux'
import { Element as ScrollElement } from 'react-scroll'
import { bindActionCreators } from 'redux'
import * as yup from 'yup'

import { createCollection, fetchCollection, updateCollection } from 'api'
import { Button, LoadingIndicator, Notification } from 'components'
import { ScrollSpyView } from 'layouts'
import { createToast } from 'modules/toasts'
import { getBackButton, getRouteTo } from 'routing'
import Styling from 'styling/components'
import { get, ItemFetcher } from 'utils'

import CollectionFormDetails from './CollectionFormDetails'
import CollectionFormHeader from './CollectionFormHeader'
import CollectionFormProducts from './CollectionFormProducts'

import styles from './CollectionCreateOrEdit.styles'

const formSections = [
  { name: 'Collection Detail', id: 'collection-detail', component: CollectionFormDetails },
  { name: 'Collection Header', id: 'collection-header', component: CollectionFormHeader },
  { name: 'Included Products', id: 'included-products', component: CollectionFormProducts },
]

const PAGE_ID = 'collection-form'

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createToast,
    },
    dispatch
  )

export const mapCollectionRule = rule => ({
  brand: rule.brand_name,
  category: rule.department_id,
  attribute: rule.id,
  tag: rule.tag_id,
  hasOffer: rule.has_offer,
  topSelling: rule.top_selling,
})

const formatCollectionPayload = payload => ({
  ...payload,
  rules: payload.rules.map(rule => ({
    brand_name: rule.brand,
    id: rule.attribute,
    department_id: rule.category,
    tag_id: rule.tag,
    has_offer: rule.hasOffer,
    top_selling: rule.topSelling,
  })),
})

export const CollectionCreateOrEdit = connect(
  null,
  mapDispatchToProps
)(
  injectSheet(styles)(
    ({
      backButton,
      classes,
      createToast,
      headerTitle,
      history,
      initialValues = {},
      onFormSubmit,
      successMessage,
    }) => (
      <ScrollSpyView
        name="scroll-container"
        sections={formSections}
        render={({ sections }) => (
          <Formik
            initialValues={initialValues}
            validationSchema={() =>
              yup.object().shape({
                name: yup
                  .string()
                  .trim()
                  .label('Name')
                  .required(),
                orderOverride: yup.string().trim(),
                products: yup.array().label('Products'),
                rules: yup.array().label('Rules'),
              })
            }
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={(values, form) => {
              onFormSubmit(values)
                .then(collection => {
                  form.setSubmitting(false)
                  createToast({ kind: 'success', message: successMessage })

                  // Send the user to the edited or newly created collection page
                  history.push(getRouteTo('collections.collection', { id: collection.id }))
                })
                .catch(error => {
                  form.setSubmitting(false)
                  form.setErrors({ global: error.message })
                })
            }}
            render={formikProps => (
              <div className={classes.formContainer}>
                {sections.map(({ component: FormComponent, id, name }, index) => (
                  <ScrollElement key={index} name={name}>
                    <FormComponent
                      id={id}
                      formikProps={formikProps}
                      pageId={PAGE_ID}
                      sectionName={name}
                    />
                  </ScrollElement>
                ))}
                <div className={classes.formFooter}>
                  <div className={classes.footerContainer}>
                    <Styling.LineOfButtons withoutTopMargin>
                      <Button
                        disabled={formikProps.isSubmitting}
                        id={`${PAGE_ID}-save`}
                        onClick={formikProps.handleSubmit}
                      >
                        Save
                      </Button>
                      <Button kind="link" href={backButton.to} id={`${PAGE_ID}-cancel`}>
                        Cancel
                      </Button>
                    </Styling.LineOfButtons>
                    <div className={classes.footerErrors}>
                      {!formikProps.isSubmitting && formikProps.errors.global && (
                        <Notification kind="error" message={formikProps.errors.global} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          />
        )}
      />
    )
  )
)

export const CollectionCreate = ({ location, ...restProps }) => {
  /**
   * - `duplicateId` is passed in when we want to open the Create Collection page but with it filled
   * with the data from the store group represented by that id
   */
  const duplicateId = get(location, 'state.duplicateId')

  const propsToPass = {
    backButton: get(location, 'state.backButton'),
    headerTitle: 'Create New Product Collection',
    initialValues: {
      description: '',
      featured: false,
      dynamic: false,
      sortBy: null,
      maxCount: null,
      images: {},
      name: '',
      products: location.state && location.state.selectedItems ? location.state.selectedItems : [],
      rules: [],
      showNameWithDetails: true,
    },
    onFormSubmit: formValues => createCollection(formatCollectionPayload(formValues)),
    successMessage: 'Successfully created collection.',
    ...restProps,
  }

  if (duplicateId) {
    return (
      <ItemFetcher
        queryKey="collection"
        queryParams={{ duplicateId }}
        fetchItem={config => fetchCollection(duplicateId, config)}
        render={({
          item: collection,
          isLoadingItem: isFetchingCollection,
          error: errorFetchingCollection,
        }) => {
          if (isFetchingCollection) {
            return <LoadingIndicator withTopMargin />
          }
          if (errorFetchingCollection) {
            return (
              <Styling.Center maxWidth={500} withTopMargin>
                <Notification kind="error" message={errorFetchingCollection.message} />
              </Styling.Center>
            )
          }
          if (collection) {
            propsToPass.backButton =
              propsToPass.backButton ||
              getBackButton('collections.collection', { id: collection.id, label: collection.name })
            propsToPass.initialValues = {
              description: collection.description || '',
              featured: collection.featured || false,
              images: collection.images || {},
              name: `Copy of ${collection.name}` || '',
              products: collection.products || [],
              dynamic: collection.dynamic || false,
              sortBy: collection.sortBy || null,
              maxCount: collection.maxCount || null,
              rules: collection.rules.map(mapCollectionRule) || [],
              orderOverride: collection.orderOverride,
              showNameWithDetails: collection.showNameWithDetails || false,
            }

            return <CollectionCreateOrEdit {...propsToPass} />
          }
        }}
      />
    )
  }
  propsToPass.backButton = propsToPass.backButton || getBackButton('collections')

  return <CollectionCreateOrEdit {...propsToPass} />
}

export const CollectionEdit = ({
  location,
  match: {
    params: { collectionId },
  },
  ...restProps
}) => (
  <ItemFetcher
    queryKey="collection"
    queryParams={{ collectionId }}
    fetchItem={config => fetchCollection(collectionId, config)}
    render={({ item: collection, isLoadingItem: isFetchingCollection }) => {
      if (isFetchingCollection) {
        return <LoadingIndicator withTopMargin />
      }
      if (!collection) return null

      const propsToPass = {
        backButton:
          get(location, 'state.backButton') ||
          getBackButton('collections.collection', { id: collectionId, label: collection.name }),
        headerTitle: 'Edit Collection',
        initialValues: {
          description: collection.description || '',
          displayName: collection.displayName || '',
          featured: collection.featured || false,
          dynamic: collection.dynamic || false,
          sortBy: collection.sortBy || null,
          maxCount: collection.maxCount || null,
          images: collection.images || {},
          name: collection.name || '',
          products: collection.products || [],
          rules: collection.rules.map(mapCollectionRule) || [],
          orderOverride: collection.orderOverride,
          showNameWithDetails: collection.showNameWithDetails || false,
        },
        onFormSubmit: formValues =>
          updateCollection(collectionId, formatCollectionPayload(formValues)),
        successMessage: 'Successfully updated collection.',
        ...restProps,
      }

      return <CollectionCreateOrEdit key={JSON.stringify(collection)} {...propsToPass} />
    }}
  />
)
