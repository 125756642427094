import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import injectSheet from 'react-jss'

const styles = theme => ({
  nutritionContainer: {
    fontSize: 13,
    maxWidth: 400,
    minWidth: 300,
    width: '100%',
  },
  table: {
    borderTop: '2px solid #000000',
    marginTop: theme.spacing.xs,
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'column',
  },
  tableRowParent: {
    borderBottom: '1px solid #000000',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 3,
    paddingTop: 3,
  },
  tableRowChild: {
    paddingLeft: 12,
  },
  nutritionTitle: {
    marginBottom: theme.spacing.xxs,
  },
  tableBoldedRow: {
    fontWeight: 'bold',
  },
})

const createRow = (row, i, options) => {
  const { amount, children, daily_value, name } = row
  const { classes, childRow } = options
  return (
    <div key={i} className={classes.tableRow}>
      <div
        className={cx(classes.tableRowParent, {
          [classes.tableRowChild]: childRow,
        })}
      >
        <div>
          <b className={cx({ [classes.tableBoldedRow]: !childRow })}>{name}</b> {amount}
        </div>
        <div>
          <b className={classes.tableBoldedRow}>{daily_value ? `${daily_value}%` : ''}</b>
        </div>
      </div>
      {children
        ? children.map((childRow, childIndex) =>
            createRow(childRow, childIndex, { classes, childRow: true })
          )
        : null}
    </div>
  )
}

const NutritionTable = ({ classes, rows, servingSize, servingPerContainer }) => {
  return rows.length > 0 ? (
    <div className={classes.nutritionContainer}>
      {servingSize && (
        <div className={classes.nutritionTitle}>
          <b className={classes.tableBoldedRow}>Servings Size</b> {servingSize}
        </div>
      )}
      {servingPerContainer && (
        <div className={classes.nutritionTitle}>
          <b className={classes.tableBoldedRow}>Servings Per Container</b> {servingPerContainer}
        </div>
      )}
      <div className={classes.table}>{rows.map((row, i) => createRow(row, i, { classes }))}</div>
    </div>
  ) : null
}

NutritionTable.defaultProps = {
  servingSize: null,
  servingPerContainer: null,
  rows: [],
}

NutritionTable.propTypes = {
  servingSize: PropTypes.string,
  servingPerContainer: PropTypes.string,
  rows: PropTypes.array.isRequired,
}

export { NutritionTable as NutritionTableUnStyled }
export default injectSheet(styles)(NutritionTable)
