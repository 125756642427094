import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'

import { NumberValue } from 'react-values'

import {
  TextInput,
  Tabs,
  Tab,
  DataTable,
  PageHeader,
  LoadingIndicator,
  FormLabel,
  Button,
  DropdownFilterable,
} from 'components'

import { fetchStores, getExplainCart, getOffer } from 'api'

import { toCurrency } from 'utils'

import OfferProgressTable from './OfferProgressTable'

import CartTable from './CartTable'
import OfferDetailsTable from './OfferDetailsTable'

const styles = theme => ({
  details: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  loading: {
    height: 30,
    marginLeft: -30,
  },
  lookupInput: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '16px',
  },
  title: {
    margin: '20px 0 8px 0',
    color: theme.text01,
    fontWeight: 800,
  },
  detailsTable: {
    marginTop: '-30px',
  },
  formSection: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: '10px',
  },
  formSectionInput: {
    display: 'inline-block',
  },
})

const useStyles = createUseStyles(styles)

const Offers = ({ history, location }) => {
  const [loading, setLoading] = useState(false)
  const [inputCartId, setInputCartId] = useState('')
  const [inputStoreId, setInputStoreId] = useState('')
  const [inputOfferId, setInputOfferId] = useState('')
  const [cartLookupErrorMessage, setCartLookupErrorMessage] = useState(undefined)
  const [offerLookupErrorMessage, setOfferLookupErrorMessage] = useState(undefined)

  const [storeSelectLoading, setStoreSelectLoading] = useState(true)
  const [storeSelectItems, setStoreSelectItems] = useState()

  const [cartData, setCartData] = useState(null)
  const [offerData, setOfferData] = useState(null)

  const classes = useStyles()

  useEffect(() => {
    fetchStores()
      .then(data => {
        const selectItems =
          data &&
          data.items
            .map(store => {
              return {
                id: store.id,
                label: `${store.id} - ${store.name}`,
                value: store.id,
              }
            })
            .sort((a, b) => {
              return a.id > b.id
            })
        setStoreSelectItems(selectItems)
      })
      .finally(() => {
        setStoreSelectLoading(false)
      })
  }, [])

  const fetchCartData = id => {
    if (!id) return
    setLoading(true)
    setCartLookupErrorMessage(undefined)
    getExplainCart(id)
      .then(resp => {
        setCartData(resp.cart)
        setLoading(false)
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.error(e)
        setCartLookupErrorMessage(e.message)
        setLoading(false)
      })
  }

  const fetchOfferData = (storeId, offerId) => {
    if (!storeId || !offerId) return
    setLoading(true)
    setOfferLookupErrorMessage(undefined)
    getOffer({ offer_id: offerId, store_id: storeId })
      .then(resp => {
        setOfferData(resp)
        setLoading(false)
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.error(e)
        setOfferLookupErrorMessage(e.message)
        setLoading(false)
      })
  }

  return (
    <div>
      <PageHeader headerTitle="Offers" />

      <NumberValue defaultValue={0}>
        {({ value: selectedTab, set: selectTab }) => (
          <Tabs selectedTab={selectedTab} onSelectTab={selectTab}>
            <Tab label="Cart Lookup">
              <div className={classes.formSection}>
                <div className={classes.formSectionInput}>
                  <FormLabel htmlFor={'inputCartId'} labelText={'Cart ID'} />
                  <TextInput
                    id={'inputCartId'}
                    value={inputCartId}
                    onChange={e => setInputCartId(e.target.value)}
                  />
                </div>
                <Button
                  onClick={() => {
                    fetchCartData(inputCartId)
                  }}
                >
                  Lookup Cart
                </Button>
              </div>
              {loading && <LoadingIndicator className={classes.loading} />}
              {cartLookupErrorMessage && <div>Error looking up cart: {cartLookupErrorMessage}</div>}
              {cartData && (
                <>
                  <h2 className={classes.title}>Details</h2>
                  <div className={classes.detailsTable}>
                    <DataTable
                      pagingProps={{}}
                      headers={[
                        { key: 'key', header: '', print: true },
                        { key: 'value', header: '', print: true },
                      ]}
                      rows={[
                        {
                          id: 'offer-quote-id',
                          key: <strong>Quote ID</strong>,
                          value: cartData.quote_id,
                        },
                        {
                          id: 'offer-store-id',
                          key: <strong>Store ID</strong>,
                          value: cartData.items[0].store_id,
                        },
                        {
                          id: 'pre-discount-product-total',
                          key: <strong>Pre-discount Product Total</strong>,
                          value: `${toCurrency(cartData.pre_discount_product_total)}`,
                        },
                        {
                          id: 'discount-total',
                          key: <strong>Discounts</strong>,
                          value: `${toCurrency(
                            parseFloat(cartData.pre_discount_product_total) -
                              parseFloat(cartData.product_total)
                          )}`,
                        },
                        {
                          id: 'product-total',
                          key: <strong>Product Total</strong>,
                          value: `${toCurrency(cartData.product_total)}`,
                        },
                      ]}
                      kind="light"
                    />
                  </div>
                  <>
                    <h2 className={classes.title}>
                      {'Cart Items '}
                      <span role="img" aria-label="shopping cart">
                        🛒
                      </span>
                    </h2>
                    <CartTable data={cartData} />
                    <h2 className={classes.title}>
                      {'Offers Applied '}
                      <span role="img" aria-label="checkmark">
                        ✅
                      </span>
                    </h2>
                    <OfferProgressTable
                      data={cartData.offer_progress}
                      type="applied"
                      storeId={cartData.items[0].store_id}
                    />
                    <h2 className={classes.title}>
                      {'Offers Not Applied '}
                      <span role="img" aria-label="X">
                        ❌
                      </span>
                    </h2>
                    <OfferProgressTable
                      data={cartData.offer_progress}
                      type="not_applied"
                      storeId={cartData.items[0].store_id}
                    />
                  </>
                </>
              )}
            </Tab>
            <Tab label="Offer Lookup">
              <div className={classes.lookupInput}>
                <div className={classes.formSection}>
                  <div className={classes.formSectionInput}>
                    <FormLabel htmlFor={'inputStoreId'} labelText={'Store ID'} />
                    {/* <TextInput
                      id={'inputStoreId'}
                      value={inputStoreId}
                      onChange={e => setInputStoreId(e.target.value)}
                    /> */}
                    <DropdownFilterable
                      id={'inputStoreId'}
                      isLoading={storeSelectLoading}
                      items={storeSelectItems}
                      onSelect={storeId => setInputStoreId(storeId)}
                      selectedValue={inputStoreId}
                    />
                  </div>
                  <div className={classes.formSectionInput}>
                    <FormLabel htmlFor={'inputOfferId'} labelText={'Offer ID'} />
                    <TextInput
                      id={'inputOfferId'}
                      value={inputOfferId}
                      onChange={e => setInputOfferId(e.target.value)}
                    />
                  </div>
                  <Button
                    onClick={() => {
                      fetchOfferData(inputStoreId, inputOfferId)
                    }}
                  >
                    Lookup Offer
                  </Button>
                </div>
              </div>
              {loading && <LoadingIndicator className={classes.loading} />}
              {offerLookupErrorMessage && (
                <div>Error looking up offer: {offerLookupErrorMessage}</div>
              )}
              <div className={classes.details}>
                {offerData && <OfferDetailsTable data={offerData} />}
              </div>
            </Tab>
          </Tabs>
        )}
      </NumberValue>
    </div>
  )
}

export default Offers
