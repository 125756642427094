import React from 'react'
import injectSheet from 'react-jss'

import * as placementStyling from 'constants/placementStyles'

import { Checkbox, Dropdown, FormFieldItem, TextInput } from 'components'

import styles from '../PromotedPlacementsForm.styles'

const PlacementCTA = ({
  classes,
  componentId,
  fieldItemParentKey = 'cta',
  setFieldValue,
  values: { config },
}) => (
  <>
    <FormFieldItem
      className={classes.formRow}
      id={`${componentId}-cta-enabled`}
      name={`config.${fieldItemParentKey}.enabled`}
      render={({ value, ...props }) => (
        <Checkbox
          {...props}
          checked={value}
          className={classes.formInput}
          labelText="Show button"
        />
      )}
    />
    <FormFieldItem
      className={classes.formRow}
      id={`${componentId}-cta-text`}
      labelText="Button title"
      name={`config.${fieldItemParentKey}.text`}
      render={props => (
        <TextInput
          {...props}
          disabled={!config[fieldItemParentKey]?.enabled}
          className={classes.formInput}
          placeholder="Enter button title"
        />
      )}
    />
    <FormFieldItem
      className={classes.formRow}
      id={`${componentId}-cta-url`}
      labelText="Enter URL"
      name={`config.${fieldItemParentKey}.url`}
      render={props => (
        <TextInput
          {...props}
          disabled={!config[fieldItemParentKey]?.enabled}
          className={classes.formInput}
          placeholder="Link URL"
        />
      )}
    />
    <FormFieldItem
      className={classes.formRow}
      id={`${componentId}-cta-styling`}
      labelText={`Button Styling`}
      name={`config.${fieldItemParentKey}.style`}
      render={({ name, value, ...otherProps }) => (
        <Dropdown
          {...otherProps}
          disabled={!config[fieldItemParentKey]?.enabled}
          className={classes.formInput}
          items={[
            {
              id: 'primary',
              label: 'Primary button',
              value: placementStyling.PRIMARY,
            },
            {
              id: 'secondary',
              label: 'Secondary button',
              value: placementStyling.SECONDARY,
            },
            {
              id: 'text',
              label: 'Text link',
              value: placementStyling.NONE,
            },
          ]}
          onChange={value => setFieldValue(name, value)}
          selectedValue={value}
          placeholder=""
        />
      )}
    />
  </>
)

export default injectSheet(styles)(PlacementCTA)
