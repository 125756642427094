import { Formik } from 'formik'
import compact from 'lodash/compact'
import React, { Fragment, PureComponent } from 'react'
import injectSheet from 'react-jss'
import * as yup from 'yup'

import config from 'config'

import { createSegment } from 'api'
import {
  Button,
  FormFieldItem,
  LoadingIndicator,
  Notification,
  RadioButtons,
  TextArea,
  TextInput,
} from 'components'
import Styling, { ModalHeader } from 'styling/components'

const styles = theme => ({
  form: {
    width: 450,
  },
  nameInput: {
    width: 290,
  },
  segmentItems: {
    height: 100,
  },
  helpText: {
    fontSize: theme.fontSize.p,
    color: theme.text01,
    lineHeight: theme.baseLineHeight,
    marginTop: theme.spacing.xxs,
  },
})

const maxLimitation = 25000

class CreateManualSegmentForm extends PureComponent {
  state = {
    isLoading: false,
  }

  createManualSegment = ({ items, ...restValues }, form) => {
    const { closeModal } = this.props
    this.setState({ isLoading: true })

    return createSegment({
      items: compact(items), // Sanitize segment items
      ...restValues,
    })
      .then(() => {
        form.setSubmitting(false)
        closeModal({ requiresRefetch: true })
      })
      .catch(error => {
        form.setSubmitting(false)
        form.setErrors({ global: error.message })
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  render() {
    const { classes, closeModal } = this.props
    const usesExternalCRM = config.dashboard.usesExternalCRM
    return (
      <div>
        <ModalHeader className={classes.header}>Create a Manual Segment</ModalHeader>
        <Formik
          initialValues={{
            name: '',
            type: null,
            polytype: 'manual',
            items: [],
          }}
          validationSchema={() =>
            yup.object().shape({
              name: yup
                .string()
                .trim()
                .label('Segment Name')
                .required(),
              type: yup
                .string()
                .label('Add By')
                .nullable()
                .required(),
              items: yup
                .array()
                .label('Segment Items')
                .required('You must provide a list of items corresponding to the "Add By" type.')
                .max(
                  maxLimitation,
                  `Exceeded the maximum limit: ${maxLimitation.toLocaleString()}`
                ),
            })
          }
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={this.createManualSegment}
          render={({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => {
            return (
              <form className={classes.form} onSubmit={handleSubmit}>
                <FormFieldItem
                  id="create-segment-manual-name"
                  labelText="Segment Name"
                  name="name"
                  render={renderProps => (
                    <TextInput
                      {...renderProps}
                      autoFocus
                      className={classes.nameInput}
                      placeholder="Enter Segment Name"
                    />
                  )}
                />
                <FormFieldItem
                  id="create-segment-manual-type"
                  labelText="Add By"
                  name="type"
                  render={({ id, value }) => (
                    <RadioButtons
                      id={id}
                      onChange={newType => {
                        setFieldValue('type', newType)
                      }}
                      orientation="horizontal"
                      radioButtonProps={[
                        ...(!usesExternalCRM
                          ? [
                              {
                                id: 'email',
                                labelText: 'Email Address',
                                value: 'emails',
                              },
                            ]
                          : []),
                        {
                          id: 'loyalty',
                          labelText: 'Loyalty Number',
                          value: 'loyalty_card_numbers',
                        },
                        {
                          id: 'user-id',
                          labelText: 'External User ID',
                          value: 'user_ext_ids',
                        },
                        {
                          id: 'ic-user-id',
                          labelText: 'IC User ID',
                          value: 'ic_user_ids',
                        },
                        {
                          id: 'unata-user-id',
                          labelText: 'Unata User ID',
                          value: 'unata_user_ids',
                        },
                      ]}
                      selectedValue={value}
                    />
                  )}
                />
                <FormFieldItem
                  id="create-segment-manual-items"
                  name="items"
                  render={({ id, isInvalid, name }) => (
                    <Fragment>
                      <TextArea
                        className={classes.segmentItems}
                        helpText={`Each entry should be entered on a separate line.`}
                        id={id}
                        isInvalid={isInvalid}
                        isResizable
                        onChange={({ target: { value: newItemsText } }) => {
                          const newItems = newItemsText.split('\n')
                          setFieldValue(name, newItems)
                        }}
                        placeholder="Each entry should be entered on a separate line"
                      />
                      <p className={classes.helpText}>
                        Maximum limit: {maxLimitation.toLocaleString()}. If you have more entries
                        than the maximum limit, please{' '}
                        <Button kind="link" onClick={() => closeModal({ openUploadCSV: true })}>
                          upload with a CSV
                        </Button>
                      </p>
                    </Fragment>
                  )}
                />

                {!isSubmitting && errors.global && (
                  <Notification kind="error" message={errors.global} />
                )}
                {this.state.isLoading && <LoadingIndicator.Dots absoluteCenter withText />}
                <Styling.LineOfButtons>
                  <Button disabled={isSubmitting} type="submit">
                    Save
                  </Button>
                  <Button kind="link" onClick={closeModal}>
                    Cancel
                  </Button>
                </Styling.LineOfButtons>
              </form>
            )
          }}
        />
      </div>
    )
  }
}

export default injectSheet(styles)(CreateManualSegmentForm)
