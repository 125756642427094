import React from 'react'
import injectSheet from 'react-jss'

import { Checkbox, FormFieldItem, TextInput } from 'components'

import styles from '../PromotedPlacementsForm.styles'

const PlacementTitle = ({ classes, componentId, labelText, placeholder }) => (
  <FormFieldItem
    className={classes.formRow}
    id={componentId}
    labelText={labelText}
    name="name"
    render={renderProps => (
      <TextInput
        {...renderProps}
        className={classes.formInput}
        placeholder={placeholder}
        type="text"
      />
    )}
  />
)

export default injectSheet(styles)(PlacementTitle)
