import React, { Fragment } from 'react'
import injectSheet from 'react-jss'

import { FormFieldItem, TextArea } from 'components'

import styles from '../PromotedPlacementsForm.styles'

const PlacementText = ({
  classes,
  componentId,
  fieldItemName = 'text',
  labelText,
  placeholder,
  tooltip,
}) => (
  <FormFieldItem
    className={classes.formRow}
    id={componentId}
    labelText={labelText}
    name={fieldItemName}
    render={renderProps => (
      <Fragment>
        <TextArea
          {...renderProps}
          className={classes.formInput}
          placeholder={placeholder}
          type="text"
        />
        {tooltip && <span className={classes.formTooltip}>{tooltip}</span>}
      </Fragment>
    )}
  />
)

export default injectSheet(styles)(PlacementText)
