import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'

import { FormFieldItem, AddCategories, Notification } from 'components'

import styles from '../KeywordRuleDetail.styles'

const CATEGORIES_API_PATH = '/categories/'

const formatCategoryArrayObjects = categories => {
  categories.forEach(category => {
    category.href = `${CATEGORIES_API_PATH}${category.id}`
  })

  return categories
}

const KeywordCategories = ({
  classes,
  componentId,
  componentName,
  labelText,
  onChange,
  setFieldValue,
  confirmClearAll,
  disabled,
  disabledCategories,
  disabledCategoriesText,
  draggable,
  onDragEnd,
  limit,
  modalTip,
  keywordType,
}) => {
  return (
    <FormFieldItem
      id={componentId}
      labelText={labelText}
      name={componentName}
      render={renderProps => {
        return (
          <>
            {limit && (
              <div className={classes.hintText}>
                {renderProps.value.length}/{limit} selected. You can select up to {limit} categories
              </div>
            )}
            <div className={classes.addCategoryContainer}>
              <AddCategories
                {...renderProps}
                categoryType={keywordType}
                confirmClearAll={confirmClearAll}
                onChangeCheckedStrategy="SHOW_PARENT"
                disabled={disabled}
                disabledCategories={disabledCategories}
                disabledCategoriesText={disabledCategoriesText}
                draggable={draggable}
                onDragEnd={onDragEnd}
                onChange={newSelectedCategories => {
                  // Backend API expects this value for `target.categories[]` in the payload
                  // V1 added this step via an API inteceptor
                  newSelectedCategories = formatCategoryArrayObjects(newSelectedCategories)
                  setFieldValue(componentName, newSelectedCategories)
                  if (onChange) {
                    onChange(newSelectedCategories)
                  }
                }}
                selectedCategories={renderProps.value}
                validateModalSave={({ newSelectedItems }) => {
                  if (limit && newSelectedItems.length > limit) {
                    return "Error: You cannot select more than 3 categories! Please remove any additional selected category and then click 'Save'. Thank you."
                  }

                  return null
                }}
                guidance={{
                  modalInfo: () => <Notification title="Tip" kind="info" message={modalTip} />,
                }}
                limit={limit}
              />
            </div>
          </>
        )
      }}
    />
  )
}

KeywordCategories.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  disabledCategories: PropTypes.array,
  disabledCategoriesText: PropTypes.string,
  /**
   * Whether this component should be disabled
   */
  disabled: PropTypes.bool,
  confirmClearAll: PropTypes.bool,
  draggable: PropTypes.bool,
  onDragEnd: PropTypes.func,
  limit: PropTypes.number,
  modalTip: PropTypes.node,
  keywordType: PropTypes.oneOf(['recipe', 'product']),
}

KeywordCategories.defaultProps = {
  disabledCategories: [],
  disabledCategoriesText: '',
  disabled: false,
  confirmClearAll: false,
  draggable: false,
  limit: null,
  modalTip: null,
  keywordType: 'product',
}

export default injectSheet(styles)(KeywordCategories)
