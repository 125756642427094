import memoize from 'memoize-one'
import React from 'react'
import injectSheet from 'react-jss'
import { Value } from 'react-values'

import { fetchRoles } from 'api'
import { Button, DataTable, PageHeader, SearchInput, ShowIfAuthorized } from 'components'
import { DEFAULT_TABLE_LIMIT } from 'defaults'
import { generateBackButton, getRoutePathname } from 'routing'
import { formatDate, ItemsFetcherWithParams } from 'utils'

const styles = theme => ({
  rightOfHeaderContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: 790,

    '& > *': {
      marginBottom: 0,
      marginLeft: theme.spacing.md,
    },
  },
  // TODO: Create a new Styling component for this flex basis use case
  filterSearch: {
    flexBasis: '295px',
  },
})

const PAGE_ID = 'roles'

const tableParams = {
  paging: {
    type: 'multi',
    multiParams: { limit: { key: 'limit' }, offset: { key: 'offset' } },
  },
  search: { key: 'name' },
  sorting: {
    type: 'multi',
    multiParams: { direction: { key: 'direction' }, orderBy: { key: 'order_by' } },
  },
}

const tableHeaders = [
  {
    key: 'name',
    header: 'Role Name',
  },
  {
    key: 'created',
    header: 'Created Date',
  },
  {
    key: 'userCount',
    header: '# of Assigned Users',
  },
]

const formatTableRows = memoize((roles, location) =>
  roles.map(({ id, created, name, userCount }) => ({
    id: `role-${id}`,
    rowLinkTo: {
      pathname: getRoutePathname('roles.role', { id }),
      state: { backButton: generateBackButton('roles', location) },
    },
    name,
    created: formatDate(created),
    userCount,
  }))
)

const Roles = ({ classes, history, location }) => (
  <ItemsFetcherWithParams
    queryKey="roles"
    fetchItems={fetchRoles}
    paramDefinitions={tableParams}
    routingParams={{
      location,
      history,
    }}
    render={({
      params: tableParams,
      items: roles,
      itemCount: roleCount,
      isLoadingItems: isFetchingRoles,
      error: errorFetchingRoles,
    }) => {
      const tableRows = formatTableRows(roles, location)

      // Paging props
      const resultsPerPage =
        parseInt(tableParams.paging.multiParams.limit.value, 10) || DEFAULT_TABLE_LIMIT
      const pageNumber = (tableParams.paging.multiParams.offset.value || 0) / resultsPerPage + 1

      return (
        <div>
          <PageHeader headerTitle="Roles & Permissions">
            <Value defaultValue={tableParams.search.value || ''}>
              {({ set: handleChange, value }) => (
                <div className={classes.rightOfHeaderContainer}>
                  <SearchInput
                    className={classes.filterSearch}
                    id={`${PAGE_ID}-filter-search`}
                    onClear={oldInputValue => {
                      // If the role clears the search input containing the current search param,
                      // reload the page with the search param cleared
                      if (oldInputValue === tableParams.search.value) {
                        tableParams.search.onChange(null)
                      }
                    }}
                    onChange={handleChange}
                    onSubmit={tableParams.search.onChange}
                    placeholder="Search by role name"
                    value={value}
                  />
                  <ShowIfAuthorized requiredPermission="roles.create">
                    <Button
                      href={{
                        pathname: getRoutePathname('roles.createRole'),
                        state: { backButton: generateBackButton('roles', location) },
                      }}
                      id={`${PAGE_ID}-create`}
                    >
                      Create New Role
                    </Button>
                  </ShowIfAuthorized>
                </div>
              )}
            </Value>
          </PageHeader>
          <DataTable
            error={errorFetchingRoles}
            headers={tableHeaders}
            id={`${PAGE_ID}-table`}
            sortHeaderKey={tableParams.sorting.multiParams.orderBy.value}
            sortDirection={tableParams.sorting.multiParams.direction.value}
            isLoadingNewRows={isFetchingRoles}
            onSortBy={({ nextSortHeaderKey, nextSortDirection }) => {
              tableParams.sorting.onChange({
                orderBy: nextSortHeaderKey,
                direction: nextSortDirection,
              })
            }}
            rows={tableRows}
            pagingProps={{
              pageNumber,
              resultsPerPage,
              onPagingChange: ({ pageNumber, resultsPerPage }) => {
                tableParams.paging.onChange({
                  limit: resultsPerPage,
                  offset: (pageNumber - 1) * resultsPerPage || undefined,
                })
              },
              resultsTotal: roleCount,
            }}
          />
        </div>
      )
    }}
  />
)

export default injectSheet(styles)(Roles)
