const STICKY_FOOTER_HEIGHT = 64

export default theme => ({
  // Prevent scrolling on content behind modal when open
  modalOpenHtml: {
    overflow: 'hidden',
  },
  modalOpenBody: {
    overflow: 'hidden',
  },
  overlay: {
    zIndex: theme.zIndex.overlay,
    backgroundColor: theme.bgModal,
    bottom: 0,
    left: 0,
    position: 'fixed',
    right: 0,
    top: 0,
  },
  modalSmall: {},
  modalLarge: {},
  withStickyFooter: {},
  wrapper: {
    zIndex: theme.zIndex.modal,
    bottom: 0,
    left: 0,
    margin: '40px auto 30px',
    position: 'absolute',
    right: 0,
    top: 0,

    '&:focus': {
      outline: 'none',
    },

    '$modalSmall &': {
      width: 570,
    },
    '$modalLarge &': {
      width: 900,
    },
  },
  modalContainer: {
    background: '#FFFFFF',
    border: `1px solid ${theme.borderGreyDark}`,
    borderRadius: theme.modalBorderRadius,

    maxHeight: '100%',
    overflow: 'auto',

    '$withStickyFooter &': {
      height: '100%',
      maxHeight: 'none',
      overflow: 'visible',
      paddingBottom: STICKY_FOOTER_HEIGHT,
    },
  },
  modalContentContainer: {
    '$withStickyFooter &': {
      height: '100%',
      overflow: 'auto',
    },
  },
  modalContent: {
    position: 'relative',

    '$modalSmall &': {
      padding: 56,
      textAlign: 'center',
    },
    '$modalLarge &': {
      padding: '32px 80px 56px',
    },
    '$modalLarge$withStickyFooter &': {
      paddingBottom: 0,
    },
  },
  modalStickyFooter: {
    alignItems: 'center',
    bottom: 0,
    display: 'flex',
    height: STICKY_FOOTER_HEIGHT,
    overflow: 'hidden',
    padding: '0 80px',
    position: 'absolute',
    width: '100%',
  },
  modalClose: {
    background: 'none',
    border: 0,
    cursor: 'pointer',
    padding: 0,
    position: 'absolute',
    right: theme.spacing.md,
    top: theme.spacing.md,
  },
  modalCloseIcon: {
    fill: theme.text01,
    height: 16,
    width: 16,
  },
})
