import cx from 'classnames'
import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Highlighter from 'react-highlight-words'
import injectSheet from 'react-jss'
import Select from 'react-virtualized-select'

import { ListBox, SearchInput } from 'components'

import ListBoxStyles from '../ListBox/ListBox.styles'
import ListBoxMenuStyles from '../ListBox/ListBoxMenu.styles'

const styles = theme => {
  const listBoxStyles = ListBoxStyles(theme)
  const listBoxMenuStyles = ListBoxMenuStyles(theme)

  return {
    autocomplete: {
      position: 'relative',

      '& > .Select-control > .Select-multi-value-wrapper': {
        height: listBoxStyles.listBox.height,

        '& > .Select-placeholder': {
          display: 'none',
        },
      },
      '& > .Select-menu-outer, & > .Select-noresults': {
        backgroundColor: listBoxMenuStyles.listBoxMenu.backgroundColor,
        boxShadow: listBoxMenuStyles.listBoxMenu.boxShadow,
        paddingTop: theme.spacing.xs,
        position: 'absolute',
        width: '100%',
        zIndex: listBoxMenuStyles.listBoxMenu.zIndex,
      },
    },
  }
}

/**
 * Helper function to place any items in `selectedItems` or `disabledItems` that
 * appear in the dropdown results (`items`) at the end of the list and mark them as
 * selected (where `selectedItems` and `disabledItems` are passed in as a prop
 * to the Autocomplete component).
 */
const moveSelectedItemsToBack = ({ items, selectedItems, disabledItems, disabledItemsText }) => {
  const selectedItemsToMoveToBack = []
  const disabledItemsToMoveToBack = []
  const unselectedItems = []
  items.forEach(item => {
    const selectItemIndex = selectedItems.findIndex(
      selectedItem => selectedItem.id === item.id || selectedItem === item.id
    )
    if (selectItemIndex > -1) {
      selectedItemsToMoveToBack.push({ ...item, isSelected: true })
      return
    }
    const disabledItemIndex = disabledItems.findIndex(
      disabledItem => disabledItem.id === item.id || disabledItem === item.id
    )
    if (disabledItemIndex > -1) {
      disabledItemsToMoveToBack.push({
        ...item,
        isDisabled: true,
        disabledText: disabledItemsText,
      })
      return
    }
    unselectedItems.push(item)
  })
  // Place any matched items that are in selectedItems at the end of the list
  return [...unselectedItems, ...selectedItemsToMoveToBack, ...disabledItemsToMoveToBack]
}

/**
 * Component that is used to add/select items from a large filterable list.
 */
class Autocomplete extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    /**
     * Input value to display
     */
    defaultValue: PropTypes.string,
    /**
     * Hide clear button
     */
    hideClearInput: PropTypes.bool,
    /**
     * If defined, will show the icon with this name to the left of the Autocomplete when loading
     */
    icon: PropTypes.string,
    /**
     * Id will be used as the id field for the text input
     */
    id: PropTypes.string.isRequired,
    /**
     * Used to show a disabled input with loading text (useful for initial item fetching)
     */
    isLoading: PropTypes.bool,
    /**
     * Required unless the containing component passes in loadItems
     */
    items: PropTypes.array,
    /**
     * Which item prop contains the label to display in the dropdown (defaults to 'name')
     */
    labelKey: PropTypes.string.isRequired,
    /**
     * If provided will be used to call for Autocomplete results asynchronously
     * NOTE: This will defer all filtering to the `loadItems` call, and unfortunately
     * the items in `selectedItems` cannot be marked as such in the Autocomplete results
     * (due to restrictions with the `react-select` library and how it works with `loadItems`).
     */
    loadItems: PropTypes.func,
    /**
     * Message to show to users when the filtered list of items is empty
     */
    noItemsMsg: PropTypes.string.isRequired,
    /**
     * `onClear` is a callback when an item is removed.
     */
    onClear: PropTypes.func,
    /**
     * `onItemSelect` is a way for a consuming component to be notified of new dropdown item selections.
     */
    onItemSelect: PropTypes.func,
    /**
     * `menuItemHeight` defines the height of the menu item. Can be a set number, or a function
     * which given the item, returns its height.
     */
    menuItemHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]).isRequired,
    /**
     * Generic `placeholder` that will be used as the textual representation of
     * what this field is for inside of the text input
     */
    placeholder: PropTypes.string.isRequired,
    /**
     * Consumers of Autocomplete often need to provide custom menu item layouts.
     * They can do so by utilizing `renderMenuItem` (if provided), which has access to `item` and `inputValue`.
     */
    renderMenuItem: PropTypes.func,
    /**
     * If defined, will show the icon with this name on left of the SearchInput
     */
    searchIcon: PropTypes.string,
    /**
     * Provide selectedItems to have them shown as such if they appear in the dropdown results as matches. This list
     * can be a list of ids, or a list of items that have an id field.
     * NOTE: State of selected items in the dropdown must be handled by consuming component.
     *       (when you select an item in Autocomplete, the selected state of that item is not changed by Autocomplete)
     */
    selectedItems: PropTypes.array,
    /**
     * Similar to selectedItems, provide a way to have an item disabled, but with a customized label.
     * The label is decided by the disabledItemsText attribute.
     */
    disabledItems: PropTypes.array,
    /**
     * The custom label text for the disabledItems.
     */
    disabledItemsText: PropTypes.string,
  }

  static defaultProps = {
    defaultValue: '',
    hideClearInput: true,
    icon: 'search',
    labelKey: 'name',
    menuItemHeight: 36,
    onClear: () => {},
    noItemsMsg: 'No results found',
    selectedItems: [],
    disabledItems: [],
    disabledItemsText: '',
  }

  constructor(props) {
    super(props)
    const { loadItems } = props

    this.optionProps = {}
    if (loadItems) {
      const debouncedLoadOptions = debounce((inputValue, callback) => {
        loadItems(inputValue)
          .then(({ items }) => ({
            options: items,
          }))
          .then(
            data => callback(null, data),
            error => callback(error)
          )
      }, 500)

      this.optionProps.async = true
      this.optionProps.loadOptions = debouncedLoadOptions

      // Do no filtering, just return all options since we defer filtering to `loadItems`
      this.optionProps.filterOptions = options => options
    }

    this.state = { inputValue: '' }
  }

  componentWillMount() {
    if (this.props.defaultValue) {
      this.setState({ defaultValue: this.props.defaultValue })
    }
  }

  // Listen to input value updates from React-Select to provide to `renderMenuItem`
  handleInputValueChange = newInputValue => {
    this.setState({ inputValue: newInputValue })
    return newInputValue
  }

  handleItemSelect = items => {
    const newSelectedItem = items[0]
    const { onItemSelect, selectedItems, disabledItems } = this.props
    const selectedIndex = selectedItems.findIndex(
      selectedItem => selectedItem.id === newSelectedItem.id || selectedItem === newSelectedItem.id
    )
    const disabledIndex = disabledItems.findIndex(
      disabledItem => disabledItem.id === newSelectedItem.id || disabledItem === newSelectedItem.id
    )

    // Ignore selected items that are already in the list of `selectedItems` given to the component as a prop
    if (selectedIndex === -1 && disabledIndex === -1) {
      if (onItemSelect) {
        onItemSelect(newSelectedItem)
      }
    }
  }

  render() {
    const {
      classes,
      className,
      disabled,
      hideClearInput,
      icon,
      id,
      isLoading,
      items,
      labelKey,
      loadItems,
      menuItemHeight,
      noItemsMsg,
      onClear,
      placeholder,
      renderMenuItem,
      searchIcon,
      selectedItems,
      disabledItems,
      disabledItemsText,
    } = this.props

    const { defaultValue, inputValue } = this.state

    const optionProps = { ...this.optionProps }
    if (!loadItems) {
      optionProps.options = moveSelectedItemsToBack({
        items,
        selectedItems,
        disabledItems,
        disabledItemsText,
      })
    }

    return (
      <Select
        {...optionProps}
        className={cx(classes.autocomplete, className)}
        disabled={disabled}
        id={id}
        instanceId={id}
        labelKey={labelKey}
        loadingPlaceholder={<ListBox.MenuItem isNoItemsMsg>Loading...</ListBox.MenuItem>}
        maxHeight={300}
        multi
        noResultsText={<ListBox.MenuItem isNoItemsMsg>{noItemsMsg}</ListBox.MenuItem>}
        onChange={this.handleItemSelect}
        onInputChange={this.handleInputValueChange}
        onSelectResetsInput
        optionHeight={menuItemHeight}
        showPlaceholder={false}
        valueKey="id" // Used to determine item equality, note the whole item gets passed to consumer on select
        arrowRenderer={() => null}
        inputRenderer={({ onChange, onFocus, ref, value, ...restInputProps }) =>
          isLoading ? (
            <ListBox.Field id={id} isDisabled>
              <ListBox.Icon name={icon} />
              <ListBox.Label text="Loading..." />
            </ListBox.Field>
          ) : (
            <SearchInput
              disabled={disabled}
              autoComplete="off"
              hideClearInput={hideClearInput} // TODO: Get this to work with <Select>
              icon={searchIcon}
              id={id}
              onChange={newInputValue => {
                // clear the default value if it exists to normalize the current value
                this.setState({ defaultValue: '' }, () =>
                  onChange({ target: { value: newInputValue } })
                )
              }}
              onClear={() => onClear()}
              onFocus={props => onFocus(props)}
              placeholder={placeholder}
              {...restInputProps}
              value={value || defaultValue || ''}
            />
          )
        }
        optionRenderer={({
          focusedOption: focusedItem,
          focusOption: focusItem,
          key,
          labelKey,
          option: item,
          selectValue,
          style,
        }) => (
          <ListBox.MenuItem
            hideOverflow
            key={key}
            onClick={() => {
              if (!item.isSelected && !item.isDisabled) {
                selectValue(item)
              }
            }}
            onMouseEnter={() => {
              focusItem(item)
            }}
            isHighlighted={item === focusedItem}
            isSelected={item.isSelected}
            isDisabled={item.isDisabled}
            disabledText={item.disabledText}
            style={style}
          >
            {renderMenuItem ? (
              renderMenuItem({ item, inputValue })
            ) : (
              <Highlighter searchWords={[inputValue]} autoEscape textToHighlight={item[labelKey]} />
            )}
          </ListBox.MenuItem>
        )}
      />
    )
  }
}

export { Autocomplete as AutocompleteUnStyled }
export default injectSheet(styles)(Autocomplete)
