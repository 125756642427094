import React from 'react'
import injectSheet from 'react-jss'

import { PageHeader } from 'components'
import { Navbar } from 'layouts'
import navbarLinks from 'pages/Dashboard/navbarLinks'

const styles = theme => ({
  dashboard: {
    width: '100%',
  },
  main: {
    display: 'flex',
    flexGrow: 1,
    flexShrink: 0,
  },
  content: {
    flexGrow: 1,
    maxWidth: theme.containerWidth,
    minWidth: 0,
    margin: '0 auto 52px',
    width: '100%',
  },
  pageHeaderContainer: {
    background: '#FFFFFF',
    borderBottom: `2px solid ${theme.borderGreyLight}`,
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  pageHeaderContainerInner: {
    maxWidth: theme.containerWidth,
    margin: '0 auto',
  },
})

// Renders content with or without Sidebar (depending on `hideSidebar` prop in route definition)
const FixedScrollSpyPage = ({
  classes,
  component: Component,
  layoutProps,
  location,
  props,
  render,
}) => {
  let pageTitle = layoutProps.pageTitle || ''

  if (layoutProps.dynamicTitle) {
    pageTitle = `${layoutProps.prependTitle} ${location.state?.currentPlacementName}`
  }

  return (
    <div className={classes.dashboard}>
      <Navbar className={classes.navbar} links={navbarLinks()} location={location} />
      <div className={classes.pageHeaderContainer}>
        <div className={classes.pageHeaderContainerInner}>
          <PageHeader noMargin headerTitle={pageTitle} />
        </div>
      </div>
      <div className={classes.content}>
        {render && render(props)}
        {Component && <Component {...props} />}
      </div>
    </div>
  )
}

export default injectSheet(styles)(FixedScrollSpyPage)
