import React, { Fragment, useState } from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import * as yup from 'yup'

import Styling from 'styling/components'
import { FormFieldItem, TextInput, Button, LoadingIndicator, Notification } from 'components'

const styles = theme => ({
  emailField: {
    marginBottom: theme.spacing.xs,
  },
  email: {
    width: 500,
  },
  lookupResult: {
    background: theme.bgGreyExtraLight,
    padding: `0 ${theme.spacing.md}`,
    marginBottom: theme.spacing.lg,
  },
  loadingIndicator: {
    padding: `${theme.spacing.md} 0`,
  },
  customerName: {
    fontSize: theme.fontSize.label,
    fontWeight: theme.inputValueWeight,
    marginRight: theme.spacing.md,
  },
  customerId: {
    fontSize: theme.fontSize.small,
  },
})

const validateEmail = email =>
  yup
    .string()
    .email('Please enter a valid email')
    .required('Please enter a valid email')
    .validateSync(email)

const CustomerLookup = ({
  classes,
  id,
  name,
  customer,
  setCustomer,
  fetchCustomer,
  setEmailFieldError,
}) => {
  const [showResult, setShowResult] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()

  const handleFetch = async email => {
    setError(null)
    setShowResult(true)
    setLoading(true)

    try {
      const encodedEmail = encodeURIComponent(email)
      return await fetchCustomer(encodedEmail)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const handleLookup = async email => {
    try {
      validateEmail(email)
      setEmailFieldError(null)
      setCustomer(await handleFetch(email))
    } catch (error) {
      setEmailFieldError(error.message)
    }
  }

  return (
    <Fragment>
      <FormFieldItem
        id={id}
        name={name}
        className={classes.emailField}
        labelText="Email Address"
        render={({ value: email, onChange, ...renderProps }) => {
          const handleChange = event => {
            setShowResult(false)
            setCustomer(null)
            onChange(event)
          }
          const handleKeyDown = event => {
            if (event.key === 'Enter') {
              event.preventDefault()
              handleLookup(email)
            }
          }
          const handleClick = () => {
            handleLookup(email)
          }

          return (
            <Styling.LineOfItems>
              <TextInput
                {...renderProps}
                autoFocus
                value={email}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                className={classes.email}
                placeholder="Email"
              />
              <Button kind="link" onClick={handleClick}>
                Lookup User
              </Button>
            </Styling.LineOfItems>
          )
        }}
      />
      {showResult && (
        <div className={classnames(classes.lookupResult, classes.email)}>
          {loading && <LoadingIndicator.Dots className={classes.loadingIndicator} centerBlock />}
          {error && (
            <Notification
              kind="error"
              hideBorder
              message={`Failed to get customer: ${error.message}`}
            />
          )}
          {!loading && customer && (
            <Notification
              kind="success"
              hideBorder
              message={
                <Fragment>
                  {customer.firstName && (
                    <span
                      className={classes.customerName}
                    >{`${customer.firstName} ${customer.lastName}`}</span>
                  )}
                  <span className={classes.customerId}>{`User ID: ${customer.userId}`}</span>
                </Fragment>
              }
            />
          )}
        </div>
      )}
    </Fragment>
  )
}

export default injectSheet(styles)(CustomerLookup)
