import React from 'react'

import { getRoutePathname } from 'routing'
import { FixedScrollSpyPage } from 'layouts'
import { renderV1 } from 'DashboardV1'

import Collections from './Collections/Collections'
import Collection from './Collections/Collection'
import { CollectionCreate, CollectionEdit } from './Collections/CollectionCreateOrEdit'
import Products from './Products/Products'
import Product from './Products/Product'
import FeaturedDepartments from './FeaturedDepartments/FeaturedDepartments'
import FeaturedDepartment from './FeaturedDepartments/FeaturedDepartment'
import {
  FeaturedDepartmentCreate,
  FeaturedDepartmentEdit,
} from './FeaturedDepartments/FeaturedDepartmentCreateOrEdit'
import MerchandisedLandingPage from './MerchandisedLandingPages/MerchandisedLandingPage'
import MerchandisedLandingPages from './MerchandisedLandingPages/MerchandisedLandingPages'
import {
  MerchandisedLandingPageCreate,
  MerchandisedLandingPageEdit,
} from './MerchandisedLandingPages/MerchandisedLandingPageCreateOrEdit'

const routeDefinitions = [
  { path: getRoutePathname('collections'), component: Collections },
  {
    path: getRoutePathname('collections.createCollection'),
    component: CollectionCreate,
    layout: FixedScrollSpyPage,
    layoutProps: {
      pageTitle: 'Create New Product Collection',
    },
  },
  {
    path: getRoutePathname('collections.editCollection', { id: ':collectionId' }),
    component: CollectionEdit,
    layout: FixedScrollSpyPage,
    layoutProps: {
      pageTitle: 'Edit Product Collection',
    },
  },
  {
    path: getRoutePathname('collections.collection', { id: ':collectionId' }),
    component: Collection,
  },
  { path: getRoutePathname('products'), component: Products },
  {
    path: getRoutePathname('products.product', { id: ':productId' }),
    component: Product,
  },
  { path: getRoutePathname('featuredDepartments'), component: FeaturedDepartments },
  {
    path: getRoutePathname('featuredDepartments.createFeaturedDepartment', {
      id: ':id',
    }),
    component: FeaturedDepartmentCreate,
    layout: FixedScrollSpyPage,
    layoutProps: {
      pageTitle: 'Create Merchandised Landing Page',
    },
  },
  {
    path: getRoutePathname('featuredDepartments.details', { id: ':featuredDepartmentId' }),
    component: FeaturedDepartment,
  },
  {
    path: getRoutePathname('featuredDepartments.editFeaturedDepartment', {
      id: ':id',
    }),
    component: FeaturedDepartmentEdit,
    layout: FixedScrollSpyPage,
    layoutProps: {
      pageTitle: 'Edit Merchandised Landing Page',
    },
  },
  { path: getRoutePathname('merchandisedLandingPages'), component: MerchandisedLandingPages },
  {
    path: getRoutePathname('merchandisedLandingPages.createMerchandisedLandingPage', {
      id: ':id',
    }),
    component: MerchandisedLandingPageCreate,
    layout: FixedScrollSpyPage,
    layoutProps: {
      pageTitle: 'Create Merchandised Landing Page',
    },
  },
  {
    path: getRoutePathname('merchandisedLandingPages.editMerchandisedLandingPage', {
      id: ':id',
    }),
    component: MerchandisedLandingPageEdit,
    layout: FixedScrollSpyPage,
    layoutProps: {
      pageTitle: 'Edit Merchandised Landing Page',
    },
  },
  {
    path: getRoutePathname('merchandisedLandingPages.details', { id: ':id' }),
    component: MerchandisedLandingPage,
  },

  {
    path: getRoutePathname('recipeCollections'),
    render: renderV1,
  },
]

export default routeDefinitions
