import { mixins } from 'styling'

export default theme => {
  const formItem = mixins.formItem({ theme })

  return {
    readOnlyValueContainer: {
      alignItems: 'baseline',
      display: 'flex',
      position: 'relative',

      '& $readOnlyValueWideLabel': {
        width: 190,
      },
    },
    readOnlyValueActionContainer: {
      marginBottom: 1,
      marginLeft: `-${theme.spacing.lg}`,
      paddingLeft: theme.spacing.lg,
      marginRight: `-${theme.spacing.lg}`,
      paddingRight: theme.spacing.lg,

      '&:hover': {
        backgroundColor: theme.bgGreyExtraLight,

        '& $readOnlyValueActionContent': {
          visibility: 'visible',
        },
      },
    },
    readOnlyValueActionContent: {
      visibility: 'hidden',
    },
    readOnlyValueFormItemContainer: formItem.default,
    readOnlyValueHorizontalContainer: {
      // Horizontal readOnlyValues are stacked one ON TOP OF the other (so formItem.vertical)
      paddingBottom: theme.spacing.xs,
      paddingTop: theme.spacing.xs,

      '&:last-of-type': {
        marginBottom: '0 !important',
      },
    },
    readOnlyValueVerticalContainer: {
      // Vertical readOnlyValues are stacked one NEXT TO the other (so formItem.horizontal)
      flexDirection: 'column',
      marginRight: theme.spacing.md,

      '&:last-of-type': {
        marginRight: '0 !important',
      },
    },
    readOnlyValueSmallContainer: {
      // Use smaller margins in a small container
      '&$readOnlyValueHorizontalContainer': {
        marginBottom: theme.spacing.sm,
      },
      '&$readOnlyValueVerticalContainer': {
        marginRight: theme.spacing.sm,
      },

      '& $readOnlyValue': {
        fontSize: theme.fontSize.small,
      },

      '& $readOnlyValueLabel': {
        fontSize: theme.fontSize.label,
        width: 88,

        '&$readOnlyValueWideLabel': {
          width: 135,
        },
      },
    },
    readOnlyValue: {
      fontSize: theme.fontSize.p,
      background: 'none',
      border: '0',
      color: theme.text01,
      fontFamily: theme.fontFamilySansSerif,
      lineHeight: 1.5,
      padding: '0',
      resize: 'none',
      width: '100%',
      wordBreak: 'break-word',

      '&:focus': {
        outline: 'none',
      },

      '& > a': {
        color: theme.brand01,
        textDecoration: 'none',
      },
    },
    readOnlyValueLabel: {
      fontSize: theme.fontSize.p,
      color: theme.text01,
      flexShrink: 0,
      fontFamily: theme.fontFamilySansSerif,
      fontWeight: theme.inputValueWeight,
      marginRight: theme.spacing.md,
      textAlign: 'left',
      width: 70,

      '$readOnlyValueHorizontalContainer > &': {
        marginRight: theme.spacing.md,
      },
      '$readOnlyValueVerticalContainer > &': {
        marginBottom: theme.spacing.xs,
      },
    },
    readOnlyValueWideLabel: {},
  }
}
