import PropTypes from 'prop-types'
import React, { useState } from 'react'

import {
  Button,
  LoadingIndicator,
  Notification,
  PageHeader,
  ShowIfAuthorized,
  Tab,
  Tabs,
} from 'components'
import Styling from 'styling/components'
import { ItemFetcher, snakeToCapitalized, snakeToKebab } from 'utils'
import { fetchFactSchema, fetchFactValue } from 'api'
import { getRouteTo } from 'routing'

import { FactDetails } from './FactDetails'
import { FactHistory } from './FactHistory'

const Fact = props => {
  const { factKey } = props
  const [currentTab, selectTab] = useState(0)

  if (factKey === 'coffee-editing') {
    return (
      <div>
        <PageHeader headerTitle={snakeToCapitalized(factKey)} />
        Retailer Dashboard is no longer used to edit Facts. Please use&nbsp;
        <a href="https://coffee-prd.unataops.com/">Coffee</a> to edit Facts.
      </div>
    )
  }
  return (
    <div>
      <PageHeader headerTitle={snakeToCapitalized(factKey)}>
        <ShowIfAuthorized requiredPermission="facts.edit">
          <Button
            kind="primary"
            href={getRouteTo('facts.editFact', { key: snakeToKebab(factKey) })}
          >
            Edit
          </Button>
        </ShowIfAuthorized>
      </PageHeader>
      <Tabs onSelectTab={selectTab} selectedTab={currentTab}>
        <Tab label="Details">
          <ItemFetcher
            queryKey="fact"
            queryParams={{ factKey }}
            fetchItem={() => Promise.all([fetchFactValue(factKey), fetchFactSchema(factKey)])}
            render={({ item: fact, isLoadingItem: isFetchingFact, error }) => {
              if (isFetchingFact) {
                return <LoadingIndicator withTopMargin />
              }
              if (error) {
                return (
                  <Styling.Center maxWidth={500} withTopMargin>
                    <Notification kind="error" message={error.message} />
                  </Styling.Center>
                )
              }
              if (fact) {
                const [{ value: factValue }, factSchema] = fact
                return 'properties' in factSchema
                  ? Object.keys(factSchema.properties).map((key, idx) => (
                      <FactDetails
                        key={idx}
                        factKey={key}
                        factSchema={factSchema.properties[key]}
                        factValue={factValue[key]}
                      />
                    ))
                  : ''
              }
              return (
                <Styling.Center maxWidth={500} withTopMargin>
                  <Notification
                    kind="error"
                    message={`Could not fetch ${snakeToCapitalized(factKey)}.`}
                  />
                </Styling.Center>
              )
            }}
          />
        </Tab>
        <Tab label="History">
          <FactHistory {...props} />
        </Tab>
      </Tabs>
    </div>
  )
}

Fact.propTypes = {
  factKey: PropTypes.string,
}

export default Fact
