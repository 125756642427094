export default theme => ({
  datePickerRange: {
    width: 240,
  },
  datePickerInput: {
    minWidth: '0 !important',
  },
  datePicker: {
    fontSize: theme.fontSize.label,
    fontFamily: theme.fontFamilySansSerif,
    fontWeight: 700,
  },
  datePickerWrapper: {
    position: 'relative',
    width: '100%',

    '&:focus': {
      outline: 'none',

      '& $datePickerBody': {
        outline: `1px solid ${theme.brand01}`,
      },
    },
  },
  datePickerMonth: {
    borderCollapse: 'collapse',
    borderSpacing: '0',
    display: 'table',
    tableLayout: 'fixed',
    userSelect: 'none',
    width: '100%',
  },
  datePickerCaption: {
    color: theme.text01,
    display: 'table-caption',
    height: 25,
    lineHeight: '25px',
    marginBottom: theme.spacing.xs,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  datePickerNav: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    width: '100%',
  },
  datePickerBody: {
    display: 'table-row-group',
  },
  datePickerWeek: {
    display: 'table-row',
  },
  datePickerDay: {
    border: `1px solid ${theme.borderGreyLight}`,
    color: theme.text01,
    display: 'table-cell',
    padding: '0.5rem',
    textAlign: 'center',
    cursor: 'pointer',
    verticalAlign: 'middle',

    '&:focus': {
      background: theme.brand00,
      borderColor: theme.brand00,
      color: theme.inverse01,
      outline: 'none',

      '&.outside': {
        color: theme.inverse01,
      },
    },

    '&.outside': {
      color: theme.text03,
    },
  },
  datePickerDaySelected: {
    background: theme.brand01,
    border: `1px solid ${theme.brand01}`,
    color: theme.inverse01,

    '&.outside': {
      color: theme.inverse01,
      background: theme.brand01op50,
      borderColor: theme.brand01op50,
    },

    '&.start, &.end': {
      background: theme.brand02,
      borderColor: theme.brand02,

      '&.outside': {
        background: theme.brand02op50,
        borderColor: theme.brand02op50,
      },
    },

    '&.enteredEnd': {
      background: theme.brand00,
      borderColor: theme.brand00,

      '&.outside': {
        background: theme.brand00op50,
        borderColor: theme.brand00op50,
      },
    },
  },
  buttonContainer: {
    marginTop: theme.spacing.md,
    textAlign: 'right',
  },
})
