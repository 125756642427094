export default theme => ({
  headerBanner: {
    fontSize: theme.fontSize.body,
    alignItems: 'center',
    color: theme.text01,
    display: 'flex',
    fontFamily: theme.fontFamilySansSerif,
    justifyContent: 'space-between',
    minHeight: 40,
    padding: theme.spacing.md,
    backgroundColor: theme.bgHeaderBanner,
    marginLeft: '-52px',
    marginRight: '-52px',
  },
  bannerIcon: {
    flexShrink: 0,
  },
  headerBannerTextContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  headerBannerDetails: {
    alignItems: 'center',
    display: 'flex',
    flexShrink: 1,
    marginLeft: theme.spacing.sm,
    textAlign: 'left',
  },
})
