import PropTypes from 'prop-types'

import config from 'config'

const ShowIfConfigured = ({ children, requiredConfig, unconfiguredFallback }) => {
  let isConfigured = true

  if (requiredConfig) {
    isConfigured = Boolean(requiredConfig(config))
  }

  return isConfigured ? children : unconfiguredFallback || null
}

ShowIfConfigured.propTypes = {
  children: PropTypes.node.isRequired,
  requiredConfig: PropTypes.func,
  unconfiguredFallback: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.number]),
}

export default ShowIfConfigured
