import { Formik } from 'formik'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import merge from 'lodash/merge'
import React from 'react'
import injectSheet from 'react-jss'
import { Element as ScrollElement } from 'react-scroll'

import * as placementAlignments from 'constants/placementAlignments'
import * as placementStyling from 'constants/placementStyles'
import {
  HERO_BANNERS,
  IMAGE_CAROUSELS,
  RICH_TEXT_CONTAINERS,
  SLIM_BANNERS,
  TILE_CAROUSELS,
  QUICK_LINKS,
  DESKTOP_ONLY_PLACEMENTS,
  MOBILE_ONLY_PLACEMENTS,
  MOBILE_ONLY,
  DESKTOP_ONLY,
  COMPOSABLE_STOREFRONT_PLACEMENTS,
} from 'constants/placementTypes'
import { fetchPlacements, fetchPlacementContentById } from 'api'
import { Button, Notification } from 'components'
import { ScrollSpyView } from 'layouts'
import { createToast } from 'modules/toasts'
import { getBackButton, getRouteTo } from 'routing'
import Styling from 'styling/components'

import { get, ItemFetcher, ItemsFetcher } from 'utils'

import { defaultPromotedMediaDimensions as promotedMediaSpans } from './PromotedPlacementsForm/components/utils/placementImageUploader'

import {
  onPromotedPlacementsFormSubmit,
  PromotedPlacementsFormSectionsMap,
} from './PromotedPlacementsForm/PromotedPlacementsFormSectionsMap'
import {
  placementFormInitialValues,
  placementFormValidationSchema,
  userSessionTargeting,
} from './PromotedPlacementsForm/PromotedPlacementsFormSchema'

import styles from './PromotedPlacementsForm/PromotedPlacementsForm.styles'

const PAGE_ID = 'promoted-placements-form'

const initializeFormProps = ({ existingPlacement, placementId, placements }) => {
  // Make a deep copy of the `formInitialValues` object to be used for merge with `existingPlacement`
  const formInitialValuesCopy = JSON.parse(JSON.stringify(placementFormInitialValues))
  const formProps = merge(formInitialValuesCopy, existingPlacement)

  // Initialize DatePicker component for PlacementActivityPeriod
  formProps.activityPeriod = {
    from: get(existingPlacement, 'startDate') || formProps.startDate,
    to: get(existingPlacement, 'endDate') || formProps.endDate,
  }

  formProps.status = existingPlacement?.status ?? 'draft'

  // Initialize placement object from `existingPlacement` or `/placement` endpoint to pass into form values
  if (existingPlacement) {
    formProps.promotedSearchKeywords =
      formProps.promotedSearchKeywords && formProps.promotedSearchKeywords.length
        ? formProps.promotedSearchKeywords.join(', ')
        : null

    formProps.target.openIn = formProps.target.openIn === 'new_tab'

    // initialize `span` value for cell-width radio buttons, if missing from existing placement
    if (formProps.media.type === 'none') {
      ;['desktop', 'mobile'].forEach(platform => {
        if (formProps.promotedMedia[platform]?.span === null) {
          const formPropTargetPlatform = formProps.promotedMedia[platform]
          formPropTargetPlatform.span = promotedMediaSpans[platform].dimensions.find(
            configuration =>
              configuration.size.width === formPropTargetPlatform.width &&
              configuration.size.height === formPropTargetPlatform.height
          )
        }
      })
    }
  } else {
    if (placementId) {
      formProps.placement = find(placements, { id: placementId })
    } else {
      formProps.placement = placements[0]
    }

    formProps.media.type = formProps.placement.mediaTypes[0]
    formProps.target.type = formProps.placement.targetTypes[0]
  }

  if (!existingPlacement || isEmpty(formProps.config)) {
    const { key } = placements.find(({ id }) => id === placementId) ?? {}

    if (COMPOSABLE_STOREFRONT_PLACEMENTS.includes(key)) {
      formProps.config = {
        title: '',
      }
    }

    const baseCTASchema = {
      enabled: true,
      style: placementStyling.PRIMARY,
      url: '',
      text: '',
    }

    if (key === HERO_BANNERS) {
      formProps.config = {
        ...formProps.config,
        bodyColor: 'black',
        body: '',
        titleColor: 'black',
        cta: { ...baseCTASchema },
        cta2: { ...baseCTASchema, enabled: false },
      }
    } else if (key === SLIM_BANNERS) {
      formProps.config = {
        ...formProps.config,
        backgroundColor: '#000000',
        bodyColor: 'white',
        body: '',
        titleColor: 'white',
      }
    } else if ([QUICK_LINKS, TILE_CAROUSELS].includes(key)) {
      formProps.config = {
        ...formProps.config,
        items: [
          {
            altText: '',
            image: null,
            label: '',
            url: '',
          },
        ],
        ...(key === TILE_CAROUSELS && {
          showViewAllTitle: true,
          viewAllTitle: 'View all',
        }),
      }
    } else if (key === IMAGE_CAROUSELS) {
      formProps.config = {
        ...formProps.config,
        titleAlignment: placementAlignments.LEFT,
        items: [
          {
            image: null,
            label: '',
            altText: '',
            url: '',
          },
        ],
      }
    } else if (key === RICH_TEXT_CONTAINERS) {
      formProps.config = {
        ...formProps.config,
        alignment: placementAlignments.LEFT,
        body: '',
        style: placementStyling.LIGHT,
        cta: { ...baseCTASchema },
      }
    }
  }

  formProps.userShoppingContext =
    existingPlacement?.userSessionTargeting?.userShoppingContext ??
    Object.fromEntries(userSessionTargeting.userShoppingContext.map(({ id }) => [id, true]))

  const mobile_only = MOBILE_ONLY_PLACEMENTS.includes(formProps.placement.key)
  const desktop_only = DESKTOP_ONLY_PLACEMENTS.includes(formProps.placement.key)
  const getPlatformsByType = type =>
    Object.fromEntries(
      userSessionTargeting.platform
        .filter(platform => platform.tag === type)
        .map(({ id }) => [id, true])
    )
  formProps.platform =
    existingPlacement?.userSessionTargeting?.platform ??
    (mobile_only || desktop_only
      ? getPlatformsByType(mobile_only ? MOBILE_ONLY : DESKTOP_ONLY)
      : Object.fromEntries(userSessionTargeting.platform.map(({ id }) => [id, true])))

  formProps.isTrialUser = existingPlacement?.userSessionTargeting?.isTrialUser ?? null
  formProps.hasExpressMembership =
    existingPlacement?.userSessionTargeting?.hasExpressMembership ?? null

  return formProps
}

export const PromotedPlacementsCreateOrEdit = injectSheet(styles)(
  ({ backButton, classes, edit, existingPlacement, history, match, successMessage }) => (
    <ItemsFetcher
      queryKey="placements"
      fetchItems={config => fetchPlacements('', config)}
      render={({
        items: placements,
        isLoadingItems: isFetchingPlacements,
        error: errorFetchingPlacements,
        refetch: refetchPlacements,
      }) => {
        if (placements && placements.length) {
          const initialValues = initializeFormProps({
            existingPlacement,
            placements,
            placementId: match.params.id,
          })

          const currentFormSection = PromotedPlacementsFormSectionsMap({
            slug: match.params.slug,
            placement: initialValues.placement,
          })

          const placementKey = initialValues.placement.key

          return (
            <ScrollSpyView
              name="scroll-container"
              sections={currentFormSection.sections}
              render={({ sections }) => {
                return (
                  <Formik
                    initialValues={initialValues}
                    validateOnBlur={false}
                    validateOnChange={false}
                    validationSchema={placementFormValidationSchema(placementKey)}
                    onSubmit={(values, form) => {
                      onPromotedPlacementsFormSubmit(edit, values)
                        .then(promotedPlacement => {
                          form.setSubmitting(false)
                          createToast({ kind: 'success', message: successMessage })

                          history.push(
                            getRouteTo('promotedPlacements.details', {
                              id: promotedPlacement.id,
                              slug: match.params.slug,
                              backButton,
                            })
                          )
                        })
                        .catch(error => {
                          form.setSubmitting(false)
                          form.setErrors({ global: error.message })
                        })
                    }}
                    render={formikProps => {
                      return (
                        <div className={classes.formContainer}>
                          {sections.map(({ components, id, hideComponent, name }, index) =>
                            !hideComponent ? (
                              <ScrollElement
                                key={index}
                                name={name}
                                className={classes.createPlacementContainer}
                              >
                                <h5 className={classes.createPlacementTitle}>{name}</h5>
                                {components.map((component, index) => {
                                  const Component = component.component
                                  const mergedProps = { ...formikProps, ...component.props, edit }

                                  return <Component key={index} {...mergedProps} />
                                })}
                              </ScrollElement>
                            ) : null
                          )}
                          <div className={classes.formFooter}>
                            <div className={classes.footerContainer}>
                              <Styling.LineOfButtons withoutTopMargin>
                                <Button
                                  disabled={formikProps.isSubmitting}
                                  id={`${PAGE_ID}-save`}
                                  onClick={formikProps.handleSubmit}
                                >
                                  {edit ? 'Save' : 'Create'}
                                </Button>
                                <Button kind="link" href={backButton.to} id={`${PAGE_ID}-cancel`}>
                                  Cancel
                                </Button>
                              </Styling.LineOfButtons>
                              <div className={classes.footerErrors}>
                                {!formikProps.isSubmitting && formikProps.errors.global && (
                                  <Notification kind="error" message={formikProps.errors.global} />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    }}
                  />
                )
              }}
            />
          )
        }
        return null
      }}
    />
  )
)

export const PromotedPlacementsCreate = ({ history, location, match }) => {
  /**
   * `duplicateId` is passed in when the user clicks on the "Duplicate" button from the Promoted Placement Details page
   */
  const duplicateId = get(location, 'state.duplicateId')
  const backButton = get(location, 'state.backButton') || getBackButton('promotedPlacements')
  const mergedProps = { backButton, edit: false, history, match }

  if (duplicateId) {
    return (
      <ItemFetcher
        queryKey="placementContentById"
        queryParams={{ duplicateId }}
        fetchItem={config => fetchPlacementContentById(duplicateId, config)}
        render={({
          item: existingPlacement,
          isLoadingItem: isFetchingExistingPlacement,
          error: errorFetchingExistingPlacement,
          refetch: refetchExistingPlacement,
        }) => {
          if (existingPlacement) {
            mergedProps.existingPlacement = {
              ...existingPlacement,
              id: undefined,
              status: 'draft',
            }
            return <PromotedPlacementsCreateOrEdit {...mergedProps} />
          }
          return null
        }}
      />
    )
  }
  return <PromotedPlacementsCreateOrEdit {...mergedProps} />
}

export const PromotedPlacementsEdit = ({ history, match }) => (
  <ItemFetcher
    queryKey="placementContentById"
    queryParams={{ id: match.params.id }}
    fetchItem={config => fetchPlacementContentById(match.params.id, config)}
    render={({
      item: existingPlacement,
      isLoadingItem: isFetchingExistingPlacement,
      error: errorFetchingExistingPlacement,
      refetch: refetchExistingPlacement,
    }) => {
      const { id, slug } = match.params

      if (existingPlacement) {
        const backButton = getBackButton('promotedPlacements.details', {
          id,
          slug,
        })
        const mergedProps = { backButton, edit: true, existingPlacement, history, match }
        return <PromotedPlacementsCreateOrEdit {...mergedProps} />
      }
      return null
    }}
  />
)
