import { mixins } from 'styling'

export default theme => {
  const inputField = mixins.inputField({ theme })
  return {
    listBoxField: {
      ...mixins.resets.button,
      alignItems: 'center',
      border: theme.inputBorder,
      borderRadius: theme.inputBorderRadius,
      cursor: 'pointer',
      display: 'flex',
      height: '100%',
      paddingLeft: inputField.paddingLeft,
      paddingRight: inputField.paddingRight,
      position: 'relative',
      width: '100%',

      '&:focus, &$listBoxFieldFocused': {
        ...mixins.focusOutline({ theme }),
      },
    },
    listBoxFieldDisabled: {
      background: theme.bgGreyExtraLight,

      '&, &:focus': {
        border: `${theme.inputBorder} !important`,
        boxShadow: 'none !important',
        cursor: 'not-allowed',
      },
    },
    listBoxFieldInvalid: {
      ...inputField.invalid,
      '& > input': {
        backgroundColor: inputField.invalid.backgroundColor,
      },
    },
    listBoxFieldFocused: {},
    listBoxFieldSelected: {
      '&:not($listBoxFieldInvalid)': {
        borderColor: theme.text01,
      },
    },
  }
}
