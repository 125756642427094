export default theme => ({
  dropdownMenuItem: {
    fontSize: theme.fontSize.p,
    alignItems: 'center',
    border: 'none',
    color: theme.text01,
    cursor: 'pointer',
    display: 'flex',
    fontFamily: theme.fontFamilySansSerif,
    height: '100%',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    textAlign: 'left',
    textDecoration: 'none',
    width: '100%',

    '& svg': {
      fill: theme.text01,
    },

    '&:focus': {
      outline: 'none',
    },

    '&:not($dropdownMenuItemDisabled)': {
      '&:hover, &:focus': {
        backgroundColor: theme.brand01,
        color: theme.inverse01,

        '& svg': {
          fill: theme.inverse01,
        },
      },
    },
  },
  dropdownMenuItemPrimary: {
    color: theme.brand01,

    '& svg': {
      fill: theme.brand01,
    },

    '&:not($dropdownMenuItemDisabled)': {
      '&:hover, &:focus': {
        backgroundColor: theme.brand01,
      },
    },
  },
  dropdownMenuItemDanger: {
    color: theme.support01,

    '& svg': {
      fill: theme.support01,
    },

    '&:not($dropdownMenuItemDisabled)': {
      '&:hover, &:focus': {
        backgroundColor: theme.support01,
      },
    },
  },
  dropdownMenuItemDisabled: {
    cursor: 'not-allowed',
    opacity: '0.6',
  },
  dropdownMenuItemDivider: {
    background: theme.borderGreyLight,
    height: 1,
    width: '100%',
    marginTop: theme.spacing.xs,
    marginBottom: 1,
  },
  dropdownMenuItemIcon: {
    flexShrink: 0,
    height: 16,
    marginRight: theme.spacing.xs,
    width: 16,
  },
})
