import { Formik } from 'formik'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as yup from 'yup'

import { ModalHeader } from 'styling/components'

import { createToast } from 'modules/toasts'

import KeywordRuleForm from './KeywordRuleForm'

const successMessage = 'Successfully created keyword rule.'
const headerTitle = 'Create Keyword Rule'

const dispatchToProps = dispatch =>
  bindActionCreators(
    {
      createToast,
    },
    dispatch
  )

export const CreateKeywordRule = connect(
  null,
  dispatchToProps
)(props => {
  const { afterFormSubmit, handleCancel, initialValues, onFormSubmit } = props
  return (
    <div>
      <ModalHeader>{headerTitle}</ModalHeader>
      <Formik
        initialValues={initialValues}
        validationSchema={() =>
          yup.object().shape({
            name: yup
              .string()
              .trim()
              .required()
              .label('Name'),
          })
        }
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values, form) => {
          onFormSubmit(values)
            .then(returnValues => {
              form.setSubmitting(false)
              createToast({ kind: `success`, message: successMessage })
              if (afterFormSubmit) {
                afterFormSubmit(returnValues)
              }
            })
            .catch(error => {
              form.setSubmitting(false)
              form.setErrors({ global: error.message })
            })
        }}
        render={renderProps => <KeywordRuleForm handleCancel={handleCancel} {...renderProps} />}
      />
    </div>
  )
})
