import React from 'react'
import injectSheet from 'react-jss'

import * as placementAlignments from 'constants/placementAlignments'

import { FormFieldItem, RadioButtons } from 'components'

import styles from '../PromotedPlacementsForm.styles'

const PlacementAlignment = ({
  classes,
  componentId,
  enableRightAlignment = false,
  fieldItemName = 'alignment',
  labelText,
  setFieldValue,
}) => (
  <FormFieldItem
    className={classes.formRow}
    id={componentId}
    labelText={labelText}
    name={fieldItemName}
    render={({ id, name, value }) => (
      <RadioButtons
        className={classes.formInput}
        id={id}
        name={name}
        onChange={newAlignment => {
          setFieldValue(name, newAlignment)
        }}
        orientation="horizontal"
        selectedValue={value}
        type="text"
        radioButtonProps={[
          {
            id: `${fieldItemName}-alignment-left`,
            labelText: 'Left',
            value: placementAlignments.LEFT,
          },
          {
            id: `${fieldItemName}-alignment-center`,
            labelText: 'Center',
            value: placementAlignments.CENTER,
          },
          enableRightAlignment
            ? {
                id: `${fieldItemName}-alignment-right`,
                labelText: 'Right',
                value: placementAlignments.RIGHT,
              }
            : undefined,
        ].filter(a => a)}
      />
    )}
  />
)

export default injectSheet(styles)(PlacementAlignment)
