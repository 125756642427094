import { vars } from 'styling'

const styles = theme => ({
  backButton: {
    marginBottom: theme.spacing.md,
  },
  paragraph: {
    fontSize: theme.fontSize.body,
    color: theme.text01,
    marginBottom: theme.spacing.lg,
  },
  bold: {
    fontWeight: theme.inputValueWeight,
  },
  error: {
    marginBottom: theme.spacing.lg,
  },
  requestType: {
    width: 260,
  },
  '@global @keyframes formFadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  requestDetails: {
    animation: `formFadeIn ${vars.transition.duration.base} forwards`,
  },
})

export default styles
