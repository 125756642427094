import React, { Component, Fragment } from 'react'
import injectSheet from 'react-jss'

import { uploadImage } from 'api'
import { Checkbox, FormFieldItem, RadioButtons, TextArea, ImageUploadWithAltText } from 'components'

import styles from './CollectionCreateOrEdit.styles'

class CollectionFormHeader extends Component {
  state = {
    descriptionCount: 0,
  }

  componentDidMount() {
    const { description } = this.props.formikProps.values
    this.setState({ descriptionCount: description.length || 0 })
  }

  render() {
    const {
      classes,
      formikProps: { setFieldError, setFieldValue, values },
      key,
      pageId,
      sectionName,
    } = this.props

    return (
      <div className={classes.createCollectionContainer} key={key}>
        <h5 className={classes.createCollectionTitle}>{sectionName}</h5>
        <div>
          <FormFieldItem
            className={classes.formInput}
            id={`${pageId}-featured`}
            labelText="Display Header"
            name="featured"
            render={({ id, value }) => {
              return (
                <RadioButtons
                  id={id}
                  onChange={newFeatured => {
                    setFieldValue('featured', newFeatured)
                  }}
                  orientation="horizontal"
                  radioButtonProps={[
                    {
                      id: 'not-featured',
                      labelText: 'Default Header',
                      value: false,
                    },
                    {
                      id: 'featured',
                      labelText: 'Custom Header',
                      value: true,
                    },
                  ]}
                  selectedValue={value}
                />
              )
            }}
          />
        </div>
        <div className={values.featured ? classes.fieldShow : classes.fieldHide}>
          <div className={classes.formRowWithMarginTop}>
            <FormFieldItem
              className={classes.formInput}
              id={`${pageId}-edit-desktop-image`}
              labelText="Custom Desktop Image"
              name="images.desktop"
              render={({ name, id, value, onChange: altTextOnChange }) => (
                <ImageUploadWithAltText
                  name={name}
                  fieldId={pageId}
                  fileRequirementsText="Dimensions - 2400x280"
                  height={280}
                  id={id}
                  initialImage={value}
                  image={value}
                  onImageUpload={uploadImage}
                  onImageUploadComplete={image => {
                    setFieldValue('images.desktop', image)
                  }}
                  onUploadError={error => {
                    setFieldError('images.desktop', error)
                  }}
                  width={2400}
                  altTextOnChange={altTextOnChange}
                />
              )}
            />
          </div>
          <div className={classes.formRow}>
            <FormFieldItem
              className={classes.formInput}
              id={`${pageId}-edit-mobile-image`}
              labelText="Custom Mobile Image"
              name="images.mobile"
              render={({ name, id, value, onChange: altTextOnChange }) => (
                <ImageUploadWithAltText
                  name={name}
                  fieldId={pageId}
                  fileRequirementsText="Dimensions - 800x280"
                  height={280}
                  id={id}
                  initialImage={value}
                  image={value}
                  onImageUpload={uploadImage}
                  onImageUploadComplete={image => {
                    setFieldValue('images.mobile', image)
                  }}
                  onUploadError={error => {
                    setFieldError('images.mobile', error)
                  }}
                  width={800}
                  altTextOnChange={altTextOnChange}
                />
              )}
            />
          </div>
          <div className={classes.formRow}>
            <FormFieldItem
              className={classes.formInput}
              id={`${pageId}-edit-show-name-with-details`}
              labelText="Text Display"
              name="showNameWithDetails"
              render={({ id, onBlur, value, ...renderProps }) => (
                <Checkbox
                  id={`${pageId}-text-display`}
                  {...renderProps}
                  checked={value}
                  labelText="Show Title"
                />
              )}
            />
          </div>
          <div className={classes.formRow}>
            <FormFieldItem
              className={classes.formInput}
              id={`${pageId}-edit-description`}
              labelText="Header Description"
              name="description"
              render={({ id, isInvalid, onBlur, value, ...renderProps }) => {
                const maxDescriptionLength = '140'
                return (
                  <Fragment>
                    <TextArea
                      className={classes.formInput}
                      id={id}
                      isInvalid={isInvalid}
                      isResizable
                      maxLength={maxDescriptionLength}
                      onChange={({ target: { value: newItemsText } }) => {
                        this.setState({ descriptionCount: newItemsText.length })
                        setFieldValue('description', newItemsText)
                      }}
                      placeholder="Enter collection detail (optional)"
                      value={value}
                    />
                    <div className={classes.descriptionCount}>
                      {this.state.descriptionCount} / {maxDescriptionLength}
                    </div>
                  </Fragment>
                )
              }}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default injectSheet(styles)(CollectionFormHeader)
