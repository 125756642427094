import { createSelector } from 'reselect'

import defaultConfig from '@instacart/enterprise-configs/frontend/dist/defaults/config.json'

const initialState = {
  config: defaultConfig,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CONFIG':
      return {
        ...state,
        config: { ...action.payload },
      }
    default:
      return state
  }
}

// Selectors
export const getConfigSelector = state => state.general.config

export const getConfig = createSelector(getConfigSelector, value => value)
