export default theme => ({
  statusIndicatorBadge: {
    fontSize: theme.fontSize.small,
    borderRadius: 10,
    display: 'inline-block',
    lineHeight: '21px',
    padding: '0 1rem',

    '& > $statusIndicatorText': {
      color: theme.inverse01,
    },
  },

  statusIndicatorDot: {
    '&:not($compact)': {
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'nowrap',
    },

    // StatusIndicator dot
    '&::before': {
      border: '4px solid',
      borderRadius: 4,
      content: '""',
      display: 'block',
      flexShrink: 0,
      height: 0,
    },

    '& > $statusIndicatorText': {
      fontSize: theme.fontSize.p,
      marginLeft: theme.spacing.xxs,
    },
  },

  compact: {
    display: 'inline-block !important',
  },

  statusIndicatorText: {
    fontFamily: theme.fontFamilySansSerif,
    fontWeight: 700,
  },

  statusIndicatorNew: {
    '&$statusIndicatorBadge': {
      background: theme.brand00,
    },
    '&$statusIndicatorDot': {
      borderColor: theme.brand00,
      color: theme.brand00,
    },
  },
  statusIndicatorInProgress: {
    '&$statusIndicatorBadge': {
      background: theme.support03,
    },
    '&$statusIndicatorDot': {
      borderColor: theme.support03,
      color: theme.support03,
    },
  },
  statusIndicatorComplete: {
    '&$statusIndicatorBadge': {
      background: theme.support02,
    },
    '&$statusIndicatorDot': {
      borderColor: theme.support02,
      color: theme.support02,
    },
  },
  statusIndicatorCancelled: {
    '&$statusIndicatorBadge': {
      background: theme.text01,
    },
    '&$statusIndicatorDot': {
      borderColor: theme.text01,
      color: theme.text01,
    },
  },
  statusIndicatorError: {
    '&$statusIndicatorBadge': {
      background: theme.support01,
    },
    '&$statusIndicatorDot': {
      borderColor: theme.support01,
      color: theme.support01,
    },
  },
})
