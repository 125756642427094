import React from 'react'
import injectSheet from 'react-jss'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Button } from 'components'
import { createToast } from 'modules/toasts'

const styles = theme => ({
  rightOfHeaderContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',

    '& > *': {
      marginBottom: 0,
      marginLeft: theme.spacing.md,

      '&:first-child': {
        marginLeft: theme.spacing.xs,
      },
    },
  },
  bulkActions: {
    '& > *': {
      marginLeft: theme.spacing.lg,
    },
  },
})

const copyItem = ({ classes, content, createToast, itemsById, selectedRowItems, type }) => {
  // Convert items to string
  const selectedRows = selectedRowItems.map(item => item[type].toString())
  const itemString = selectedRows.join(',')

  // Create fake input to copy from
  const fakeInput = document.createElement('input')
  document.body.appendChild(fakeInput)
  fakeInput.value = itemString
  fakeInput.focus()
  fakeInput.select()

  // Messaging
  let message = `A comma separated list of ${content}s has been copied to the clipboard.`
  let kind = 'success'

  try {
    document.execCommand('copy')
  } catch (err) {
    kind = 'error'
    message = `Failed to copy ${content}s.`
  }

  createToast({ kind, message })
  document.body.removeChild(fakeInput)
}

const CopyButton = props => (
  <Button kind="secondary" onClick={() => copyItem(props)}>
    Copy {props.content}
  </Button>
)

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createToast,
    },
    dispatch
  )

export default injectSheet(styles)(connect(null, mapDispatchToProps)(CopyButton))
