import moment from 'moment'
import * as yup from 'yup'

export const daysOfWeekArray = [
  {
    id: 'monday',
    labelText: 'Monday',
    name: 'monday',
    value: false,
  },
  {
    id: 'tuesday',
    labelText: 'Tuesday',
    name: 'tuesday',
    value: false,
  },
  {
    id: 'wednesday',
    labelText: 'Wednesday',
    name: 'wednesday',
    value: false,
  },
  {
    id: 'thursday',
    labelText: 'Thursday',
    name: 'thursday',
    value: false,
  },
  {
    id: 'friday',
    labelText: 'Friday',
    name: 'friday',
    value: false,
  },
  {
    id: 'saturday',
    labelText: 'Saturday',
    name: 'saturday',
    value: false,
  },
  {
    id: 'sunday',
    labelText: 'Sunday',
    name: 'sunday',
    value: false,
  },
]

export const mlpFormInitialValues = {
  content: [],
  days: {
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    sunday: true,
  },
  endDate: moment()
    .add(4, 'weeks')
    .endOf('day')
    .format(),
  hasLoyalty: null,
  name: '',
  images: {},
  startDate: moment()
    .startOf('day')
    .format(),
  status: 'draft',
}

export const mlpFormValidationSchema = () =>
  yup.object().shape({
    name: yup
      .string()
      .trim()
      .label('Name')
      .required(),
    content: yup
      .array()
      .label('Content')
      .required(),
  })
