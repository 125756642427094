import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import { snakeToCapitalized } from 'utils'
import { FormLabel } from 'components'

const Field = React.memo(
  ({
    uiSchema,
    label,
    required,
    description,
    children,
    errors: {
      props: { errors },
    },
  }) => {
    return (
      <div className={cx({ [uiSchema.bottomMargin]: label })}>
        {label && <FormLabel labelText={`${snakeToCapitalized(label)} ${required ? '*' : ''}`} />}
        <div>
          {description}
          {children}
          {errors && <div className={cx(uiSchema.text, uiSchema.error)}>{errors.join(', ')}</div>}
        </div>
      </div>
    )
  }
)

Field.propTypes = {
  uiSchema: PropTypes.object,
  label: PropTypes.string,
  description: PropTypes.string,
  required: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  errors: PropTypes.arrayOf(PropTypes.string),
}

export default Field
