export default theme => ({
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: theme.spacing.md,
    width: 30,
    '& > img': {
      maxHeight: 30,
      maxWidth: 30,
    },
  },
  nameWithImage: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  rightOfHeaderContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',

    '& > *': {
      marginBottom: 0,
      marginLeft: theme.spacing.md,
      minWidth: 100,

      '&:first-child': {
        marginLeft: theme.spacing.xs,
      },
    },
  },
  contentSelectContainer: {
    maxWidth: 700,
  },
  contentTypeBadgeWrapper: {
    marginRight: theme.spacing.md,
  },
  selectedItemsBoxed: {
    backgroundColor: theme.bgGreyExtraLight,
    padding: `${theme.spacing.xxs} ${theme.spacing.md} ${theme.spacing.md}`,
  },
  contentButton: {
    marginBottom: theme.spacing.md,
  },
})
