import PropTypes from 'prop-types'
import React from 'react'
import Highlighter from 'react-highlight-words'
import injectSheet from 'react-jss'

import { fetchCategories, fetchRecipeCategories } from 'api'
import {
  Autocomplete,
  Button,
  LoadingIndicator,
  Modal,
  SelectedItems,
  TreeSelect,
} from 'components'
import { ItemsFetcher, matchInputOrder } from 'utils'

import styles from './AddCategoriesShared.styles'
import { transformCategoryData } from './utils/AddCategoriesHelpers'

/**
 * Combines Autocomplete, SelectedItems, and Modal.BrowseItems to allow users to
 * browse, add, and remove items from a large list.
 */
const AddCategories = ({
  id,
  classes,
  onChange,
  selectedCategories,
  disabledCategories,
  disabledCategoriesText,
  onChangeCheckedStrategy,
  confirmClearAll,
  disabled,
  draggable,
  onDragEnd,
  excludedCategories,
  limit,
  categoryType,
  guidance: { modalInfo },
  validateModalSave,
}) => (
  <ItemsFetcher
    queryKey="categoriesOfType"
    includeItemsById
    fetchItems={config => {
      if (categoryType === 'recipe') {
        return fetchRecipeCategories(config).then(({ items, itemCount }) => ({
          items: transformCategoryData(items),
          itemCount,
        }))
      }

      return fetchCategories(config).then(({ items, itemCount }) => ({
        items: transformCategoryData(items),
        itemCount,
      }))
    }}
    render={({
      items: categories,
      itemsById: categoriesById,
      isLoadingItems: isFetchingCategories,
    }) => {
      const overSelectionLimit = typeof limit === 'number' && selectedCategories.length >= limit
      if (isFetchingCategories) {
        return <LoadingIndicator />
      }

      return (
        <>
          <TreeSelect
            value={selectedCategories.map(category => category.id)}
            onChange={selectedCategoryIds => {
              const newSelectedCategories = matchInputOrder(
                selectedCategories,
                selectedCategoryIds
              ).map(id => categoriesById[id])
              onChange(newSelectedCategories)
            }}
            onChangeCheckedStrategy={onChangeCheckedStrategy}
            treeData={categories}
            disabledKeys={disabledCategories}
            render={({
              checkedTreeNodeValues: selectedCategoryIds, // Parents only if all children categories are selected
              checkTreeNode: addCategoryById,
              uncheckTreeNode: removeCategoryById,
            }) => (
              <>
                <div className={classes.addAutocompleteAndModalTrigger}>
                  <Autocomplete
                    className={classes.addAutocomplete}
                    id={id}
                    items={categories}
                    menuItemHeight={55}
                    disabled={disabled || overSelectionLimit}
                    onItemSelect={({ id }) => {
                      addCategoryById(id)
                    }}
                    placeholder="Add Categories"
                    renderMenuItem={({ item: category, inputValue }) => (
                      <div className={classes.dropdownMenuItem}>
                        <div>
                          <Highlighter
                            searchWords={[inputValue]}
                            autoEscape
                            textToHighlight={category.name}
                          />
                          {category.childCount}
                        </div>
                        <div className={classes.dropdownMenuItemBottom}>{category.parentPath}</div>
                      </div>
                    )}
                    selectedItems={selectedCategories}
                    disabledItems={disabledCategories}
                    disabledItemsText={disabledCategoriesText}
                  />
                  <Modal.BrowseCategories
                    handleCloseModal={({ newSelectedItems: newSelectedCategories }) => {
                      if (newSelectedCategories) {
                        onChange(matchInputOrder(selectedCategories, newSelectedCategories))
                      }
                    }}
                    triggerRender={({ openModal }) => (
                      <Button
                        className={classes.addModalTrigger}
                        kind="link"
                        disabled={disabled || overSelectionLimit}
                        onClick={() => {
                          openModal({
                            selectedItems: selectedCategories,
                          })
                        }}
                      >
                        See All Categories
                      </Button>
                    )}
                    validateSelection={validateModalSave}
                    contentProps={{
                      disabledCategories,
                      categories,
                      categoriesById,
                      initialSelectedCategories: selectedCategories,
                      onChangeCheckedStrategy,
                      renderInfoBlock: modalInfo,
                    }}
                  />
                </div>
                <SelectedItems
                  className={classes.itemsAdded}
                  disabled={disabled}
                  headerText="Selected Categories"
                  items={matchInputOrder(selectedCategories, selectedCategoryIds).map(id => ({
                    id,
                  }))}
                  itemNamePlural="categories"
                  draggable={draggable}
                  onDragEnd={onDragEnd}
                  onRemove={({ id: removedCategoryId }) => {
                    removeCategoryById(removedCategoryId)
                  }}
                  onRemoveAll={() => onChange([])}
                  confirmClearAll={confirmClearAll}
                  renderItem={({ item: { id } }) => {
                    const category = categoriesById[id]
                    return (
                      <div className={classes.selectedCategory}>
                        <div className={classes.selectedCategoryName}>
                          {category.name}
                          {category.childCount}
                        </div>
                        <div className={classes.selectedCategoryParentPath}>
                          {category.parentPath}
                        </div>
                      </div>
                    )
                  }}
                />
              </>
            )}
          />{' '}
        </>
      )
    }}
  />
)

AddCategories.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  /**
   * The keys that should be returned to the consumer component when onChange is
   * called; either all keys, only parents, or only children
   */
  onChangeCheckedStrategy: PropTypes.oneOf(['SHOW_ALL', 'SHOW_PARENT', 'SHOW_CHILD']),
  /**
   * The currently-selected category items for the list
   */
  selectedCategories: PropTypes.array.isRequired,
  /**
   * Which type of category that this selector should use; currently only expects
   * products or recipes
   */
  categoryType: PropTypes.oneOf(['product', 'recipe']),
  /**
   * Used to try and disable categories that should appear as disabled in this
   * list
   */
  disabledCategories: PropTypes.array,
  /**
   * The label text for the disabled categories. E.g. "Excluded", "Boosted".
   */
  disabledCategoriesText: PropTypes.string,
  /**
   * Prompt the user to confirm their intent to clear all items
   */
  confirmClearAll: PropTypes.bool,
  /**
   * Allows drag-and-drop reordering for this component
   */
  draggable: PropTypes.bool,
  /**
   * If `draggable` is enabled, this callback fires when the user finishes reordering
   */
  onDragEnd: PropTypes.func,
  excludedCategories: PropTypes.array,
  /**
   * The maximum number of selectable categories
   */
  limit: PropTypes.number,
  /**
   * Additional notification blocks to provide the user with; all are optional
   */
  guidance: PropTypes.shape({
    modalInfo: PropTypes.func,
  }),
  /**
   * Validator function passed to the Add Categories modal
   */
  validateModalSave: PropTypes.func,
}

AddCategories.defaultProps = {
  categoryType: 'product',
  selectedCategories: [],
  disabledCategories: [],
  disabledCategoriesText: '',
  onChangeCheckedStrategy: 'SHOW_ALL',
  guidance: {},
}

export default injectSheet(styles)(AddCategories)
