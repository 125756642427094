import { mixins } from 'styling'

export default theme => ({
  addAutocompleteAndModalTrigger: {
    display: 'flex',
  },
  addAutocomplete: {
    flexGrow: 1,
  },
  addModalTrigger: {
    marginLeft: theme.spacing.md,
  },
  dropdownMenuItem: {
    width: '100%',
  },
  dropdownMenuItemBottom: {
    ...mixins.textOverflowEllipsis,
    fontSize: theme.fontSize.small,
    marginTop: theme.spacing.xxs,
  },
  itemsAdded: {
    marginTop: theme.spacing.xs,
  },
  selectedCategory: {
    alignItems: 'center',
    display: 'flex',
    overflow: 'hidden',
    width: '100%',
  },
  selectedCategoryName: {
    flexBasis: '40%',
    flexShrink: 1,
  },
  selectedCategoryParentPath: {
    flexBasis: '60%',
    flexShrink: 0,
    marginLeft: theme.spacing.lg,
  },
})
