import Downshift from 'downshift'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Manager, Popper, Reference } from 'react-popper'

import { ListBox } from 'components'
import { isNil } from 'utils'

class Dropdown extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    /**
     * If `true`, will show invalid styling on <ListBoxField>
     */
    isInvalid: PropTypes.bool,
    isLoading: PropTypes.bool,
    items: PropTypes.array.isRequired,
    /**
     * Helper function passed to downshift that allows the library to render a
     * given item to a string label. If not provided, will extract `label` field
     * from a given item to serve as the item label in the list.
     */
    itemToString: PropTypes.func,
    /**
     * ClassName applied to <ListBoxMenu>
     */
    menuClassName: PropTypes.string,
    /**
     * Will set `white-space: nowrap` on <ListBoxMenu>
     */
    nowrap: PropTypes.bool,
    onChange: PropTypes.func,
    placeholder: PropTypes.string.isRequired,
    selectedValue: PropTypes.any,
    /**
     * Style can differ by size provided
     */
    size: PropTypes.oneOf(['small', 'regular']),
  }

  static defaultProps = {
    isLoading: false,
    itemToString: item => (item && item.label) || '',
    size: 'regular',
  }

  selectItemFromValue = value => {
    const { items } = this.props

    return items.find(item => !isNil(item.value) && item.value === value) || null
    // Downshift requires selectedItem to be anything but `undefined` in order to control its selected item
  }

  handleChange = selectedItem => {
    if (this.props.onChange) {
      this.props.onChange(selectedItem ? selectedItem.value : null)
    }
  }

  render() {
    const {
      className: containerClassName,
      disabled,
      id,
      isInvalid,
      isLoading,
      itemToString,
      items,
      menuClassName,
      nowrap,
      placeholder,
      selectedValue,
      size,
    } = this.props

    const hasGroupHeadings = items.findIndex(item => item.isGroupHeading) > -1
    const isDisabled = disabled || isLoading

    const selectedItem = this.selectItemFromValue(selectedValue)

    return (
      <Manager>
        <Downshift
          id={`${id}-ds`}
          onChange={this.handleChange}
          itemToString={itemToString}
          selectedItem={selectedItem}
        >
          {({
            getButtonProps,
            getItemProps,
            getLabelProps,
            getRootProps,
            highlightedIndex,
            isOpen,
            itemToString,
            selectedItem,
          }) => (
            <ListBox
              className={containerClassName}
              id={id}
              isDisabled={isDisabled}
              size={size}
              {...getRootProps({ refKey: 'setRef' })}
            >
              <Reference>
                {({ ref }) => (
                  <ListBox.Field
                    isDisabled={isDisabled}
                    isInvalid={isInvalid}
                    isSelected={!!selectedItem}
                    setRef={ref}
                    {...getButtonProps({ disabled: isDisabled })}
                  >
                    {isLoading && <ListBox.Label text="Loading..." />}
                    {!isLoading && (
                      <ListBox.Label
                        {...getLabelProps()}
                        isDisabled={isDisabled}
                        isSelectedItem={!!selectedItem}
                        text={selectedItem ? itemToString(selectedItem) : placeholder}
                      />
                    )}
                    <ListBox.MenuIcon isDisabled={isDisabled} isOpen={isOpen} />
                  </ListBox.Field>
                )}
              </Reference>
              {isOpen && (
                // We use <Popper> to ensure the dropdown opens upwards when there is no space below
                <Popper placement="bottom-start" modifiers={{ parentOverflow: { padding: 30 } }}>
                  {({ ref, style }) => (
                    <ListBox.Menu
                      className={menuClassName}
                      nowrap={nowrap}
                      setRef={ref}
                      style={style}
                    >
                      {
                        // Use `reduce` to only increment itemIndex on non-'isGroupHeading' items
                        items.reduce(
                          ({ renderedItems, itemIndex }, item) => {
                            let menuItemProps
                            let newItemIndex = itemIndex
                            if (item.isGroupHeading) {
                              menuItemProps = { key: item.id, isGroupHeading: item.isGroupHeading }
                            } else {
                              menuItemProps = {
                                key: item.id,
                                isActive: selectedItem === item,
                                isGroupChild: hasGroupHeadings,
                                isHighlighted: highlightedIndex === itemIndex,
                                ...getItemProps({ item, index: itemIndex }),
                              }
                              newItemIndex++
                            }
                            renderedItems.push(
                              <ListBox.MenuItem {...menuItemProps}>
                                {itemToString(item)}
                              </ListBox.MenuItem>
                            )
                            return { renderedItems, itemIndex: newItemIndex }
                          },
                          { renderedItems: [], itemIndex: 0 }
                        ).renderedItems
                      }
                    </ListBox.Menu>
                  )}
                </Popper>
              )}
            </ListBox>
          )}
        </Downshift>
      </Manager>
    )
  }
}

export default Dropdown
