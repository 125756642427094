import cx from 'classnames'
import React from 'react'
import injectSheet from 'react-jss'
import styled from 'styled-components/macro'

import { Icon } from 'components'

import { mixins } from 'styling'

// Named Exports (`import { ComponentName } from 'styling/components'`)

export const ModalHeader = injectSheet(theme => ({
  modalHeader: {
    color: theme.text01,
    fontFamily: theme.fontFamilySansSerif,
  },
  small: {
    fontSize: theme.fontSize.h3,
    fontWeight: 600,
    marginBottom: theme.spacing.lg,
  },
  large: {
    fontSize: theme.fontSize.h1,
    marginBottom: theme.spacing.xlg,
  },
  positionAbsolutely: {
    position: 'absolute',
  },
}))(({ children, classes, positionAbsolutely = false, small = false }) => (
  <h2
    className={cx(classes.modalHeader, {
      [classes.positionAbsolutely]: positionAbsolutely,
      [classes.small]: small,
      [classes.large]: !small,
    })}
  >
    {children}
  </h2>
))

export const ProductImagesRow = injectSheet(theme => ({
  collectionImages: {
    width: 50,
    display: 'inline',
    marginRight: theme.spacing.xs,
  },
  overflowCount: {
    position: 'absolute',
  },
  small: {
    width: 24,
  },
  textMargin: {
    marginTop: 20,
  },
}))(({ classes, includeOverflowCount = true, products, numberOfVisibleProducts = 5, small }) => {
  const productImagesComponent = products
    .map(({ imageUrl, name }, index) => {
      const productImageComponent = (
        <img
          alt={name}
          key={index}
          src={imageUrl}
          className={cx(classes.collectionImages, {
            [classes.small]: small,
          })}
        />
      )
      return index < numberOfVisibleProducts ? productImageComponent : null
    })
    .filter(Boolean)

  const overflowCount = products.length - numberOfVisibleProducts
  const overflowCountComponent = includeOverflowCount ? (
    <span
      className={cx(classes.overflowCount, {
        [classes.textMargin]: !small,
      })}
    >
      {overflowCount > 0 && `+${overflowCount}`}
    </span>
  ) : null

  return [productImagesComponent, overflowCountComponent]
})

export const ErrorText = injectSheet(theme => ({
  errorText: {
    fontSize: theme.fontSize.small,
    color: theme.support01,
    lineHeight: '1.5',
    maxHeight: 200,
    overflow: 'hidden',
    textAlign: 'left',
  },
  iconError: {
    fill: theme.support01,
    height: 15,
    marginRight: theme.spacing.xs,
    verticalAlign: 'top',
    width: 15,
  },
}))(({ classes, label }) => {
  return (
    <div className={classes.errorText}>
      <Icon name="error" className={classes.iconError} />
      {label}
    </div>
  )
})

// <Styling.ComponentName>

// TODO: Remove this once storybook addon-info stops throwing errors for using just regular styled-components
const StyledComponents = {
  Center: styled.div`
    margin: 0 auto;
    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px;`};
    ${({ withTopMargin }) => withTopMargin && `margin-top: 50px;`};
  `,
  Divider: styled.hr`
    margin: ${({ theme }) => theme.spacing.lg} 0;
  `,
  H5: styled.div`
    color: ${({ theme }) => theme.text02};
    font-size: ${({ theme }) => theme.fontSize.p};
    font-weight: 600;
    margin-bottom: ${({ theme, withBtmMargin }) => (withBtmMargin ? theme.spacing.md : 0)};
  `,
  TextOverflow: styled.div(({ maxWidth }) => ({
    ...mixins.textOverflowEllipsis,
    maxWidth: `${maxWidth}px;`,
  })),
}

const wrapComponent = Component => props => <Component {...props} />

export default {
  ...Object.keys(StyledComponents).reduce((resultObj, componentKey) => {
    resultObj[componentKey] = wrapComponent(StyledComponents[componentKey])
    resultObj[componentKey].displayName = `Styling.${componentKey}`
    return resultObj
  }, {}),
  // ENDTODO
  LineOfButtons: injectSheet(theme => ({
    lineOfButtons: {
      display: 'flex',
      marginTop: theme.spacing.xlg,

      '& > *': {
        marginRight: theme.spacing.lg,

        '&:last-child': {
          marginRight: 0,
        },
      },
    },
    withoutTopMargin: {
      marginTop: 0,
    },
  }))(({ children, classes, withoutTopMargin }) => (
    <div className={cx(classes.lineOfButtons, { [classes.withoutTopMargin]: withoutTopMargin })}>
      {children}
    </div>
  )),
  LineOfItems: injectSheet(theme => ({
    lineOfItems: {
      display: 'flex',

      '& > *': {
        marginRight: theme.spacing.md,

        '&:last-child': {
          marginRight: 0,
        },
      },
    },
    alignCenter: {
      alignItems: 'center',
    },
  }))(({ alignCenter = false, children, classes, className }) => (
    <div
      className={cx(className, classes.lineOfItems, {
        [classes.alignCenter]: alignCenter,
      })}
    >
      {children}
    </div>
  )),
}
