import React from 'react'
import injectSheet from 'react-jss'

import { FormFieldItem, AddItems, Notification } from 'components'
import { reorder } from 'components/DataTable/utils/reorder'

import { fetchRecipeTags } from 'api'
import { get } from 'utils'

import styles from '../KeywordRuleDetail.styles'

const BASE_ID = 'create-keyword-rule'

// FIXME: Fix autocomplete box width
const KeywordTags = ({
  classes,
  disabled,
  componentId,
  componentName,
  labelText,
  setFieldValue,
  handleSubmit,
  limit,
  modalTip,
  values,
  keyword,
  confirmClearAll,
  draggable,
}) => {
  return (
    <FormFieldItem
      id={componentId}
      labelText={labelText}
      name={componentName}
      render={renderProps => {
        let onDragEnd
        if (draggable) {
          onDragEnd = ({ source, destination }) => {
            if (!source || !destination) {
              // Bail out if the user didn't complete the drag/drop
              return
            }

            const initialArray = renderProps.value
            const reorderedBrands = reorder(initialArray, source.index, destination.index)
            setFieldValue(componentName, reorderedBrands)
            if (handleSubmit) {
              handleSubmit()
            }
          }
        }

        return (
          <>
            {limit && (
              <div className={classes.hintText}>
                {renderProps.value.length}/{limit} selected. You can select up to {limit} tags
              </div>
            )}
            <div className={classes.addCategoryContainer}>
              <AddItems
                id={`${BASE_ID}-boosted-brands`}
                confirmClearAll={confirmClearAll}
                autocompleteLabelKey="tag_display_name"
                disabled={disabled}
                fetchItems={config =>
                  fetchRecipeTags(config).then(ret => {
                    ret.items.map(row => (ret.name = ret.tag_display_name))
                    return ret
                  })
                }
                limit={limit}
                itemName="Tag"
                itemNamePlural="Tags"
                onChange={newSelectedTags => {
                  setFieldValue(
                    `boostTags`,
                    newSelectedTags.map(v => v.tag_name)
                  )
                  handleSubmit()
                }}
                getSelectedItems={({ items: tags }) => {
                  if (tags.length) {
                    const selectedTags = get(values, 'boostTags')

                    return selectedTags
                      .map(name => tags.find(({ tag_name }) => name === tag_name))
                      .filter(v => !!v)
                  }

                  return []
                }}
                formatTableRows={items =>
                  items.map(({ id, tag_display_name }) => ({ id: `${id}`, name: tag_display_name }))
                }
                guidance={{
                  modalInfo: modalTip ? (
                    <Notification title="Tip" kind="info" message={modalTip} />
                  ) : null,
                }}
                selectedItemsRenderItem={({ item }) => <div>{item.tag_display_name}</div>}
                removeSelectedItemsFromBox
                draggable={draggable}
                onDragEnd={onDragEnd}
              />
            </div>
          </>
        )
      }}
    />
  )
}

export default injectSheet(styles)(KeywordTags)
