// Taken from https://codepen.io/jczimm/pen/vEBpoL

import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import injectSheet from 'react-jss'

const indicatorColours = {
  green: '#008744',
  blue: '#0057e7',
  red: '#d62d20',
  yellow: '#ffa700',
  white: '#eee',
}

const indicatorWidth = 100

const styles = theme => ({
  container: {
    position: 'relative',
    margin: '0 auto',
    width: indicatorWidth,

    '&::before': {
      content: '""',
      display: 'block',
      paddingTop: '100%',
    },
  },

  withTopMargin: {
    marginTop: 150,
  },

  circle: {
    animation: 'rotate 2s linear infinite',
    height: '100%',
    transformOrigin: 'center center',
    width: '100%',
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    margin: 'auto',
  },

  path: {
    strokeDasharray: '1, 200',
    strokeDashoffset: '0',
    animation: 'dash 1.5s ease-in-out infinite, colour 6s ease-in-out infinite',
    strokeLinecap: 'round',
  },

  '@global @keyframes rotate': {
    '100%': {
      transform: 'rotate(360deg)',
    },
  },

  '@global @keyframes dash': {
    '0%': {
      strokeDasharray: '1, 200',
      strokeDashoffset: '0',
    },
    '50%': {
      strokeDasharray: '89, 200',
      strokeDashoffset: '-35px',
    },
    '100%': {
      strokeDasharray: '89, 200',
      strokeDashoffset: '-124px',
    },
  },

  '@global @keyframes colour': {
    '0%, 100%': {
      stroke: indicatorColours.red,
    },
    '40%': {
      stroke: indicatorColours.blue,
    },
    '66%': {
      stroke: indicatorColours.green,
    },
    '80%, 90%': {
      stroke: indicatorColours.yellow,
    },
  },
})

const LoadingIndicator = ({ classes, className, withTopMargin = false }) => (
  <div
    className={cx(className, classes.container, {
      [classes.withTopMargin]: withTopMargin,
    })}
  >
    <svg className={classes.circle} viewBox="25 25 50 50">
      <circle
        className={classes.path}
        cx="50"
        cy="50"
        r="20"
        fill="none"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  </div>
)

LoadingIndicator.propTypes = {
  /**
   * If `true` will place a top margin above the loading indicator.
   * Useful in cases where the <LoadingIndicator> shows the loading state of an entire page.
   */
  withTopMargin: PropTypes.bool,
}

export { LoadingIndicator as LoadingIndicatorUnStyled }
export default injectSheet(styles)(LoadingIndicator)
