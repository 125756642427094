import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import filesize from 'filesize'
import injectSheet from 'react-jss'
import snakeCase from 'lodash/snakeCase'

import { Button } from 'components'

const styles = theme => ({
  container: {
    width: '100%',
  },
  isVertical: {
    flexDirection: 'column',
  },
  emptyContainer: {
    alignItems: 'center',
    border: `1px solid ${theme.bgSelected}`,
    color: theme.text01,
    display: 'flex',
    fontFamily: theme.fontFamilySansSerif,
    justifyContent: 'space-between',
    lineHeight: theme.baseLineHeight,
    padding: theme.spacing.md,
    width: '100%',
  },
  emptyContainerCompact: {
    border: 'none',
    padding: 0,
  },
  emptyContainerVertical: {
    paddingBottom: theme.spacing.xxlg,
    paddingTop: theme.spacing.xxlg,

    '& > $selectFileButton': {
      paddingBottom: theme.spacing.sm,
    },
  },
  uploadedImageContainer: {
    alignItems: 'center',
    display: 'flex',

    '& img': {
      display: 'block',
      width: '100%',
    },
    '& button': {
      color: theme.support01,
      fontSize: '13px',
      padding: theme.spacing.lg,
      textDecoration: 'none',
    },
  },
  uploadedImageContainerCompact: {
    '& img': {
      maxHeight: 100,
    },
    '& button': {
      padding: `0 0 0 ${theme.spacing.sm}`,
    },
  },
  uploadedImageThumbnailContainer: {
    border: `1px solid ${theme.bgSelected}`,
  },
  uploadedImageText: {
    fontSize: theme.fontSize.body,
  },
  requirementsText: {
    color: theme.text01,
    fontSize: theme.fontSize.small,
    wordBreak: 'break-word',
  },
  requirementsTextContainer: {
    '& > *': {
      marginTop: theme.spacing.sm,
    },
  },
  requirementsTextContainerVertical: {
    marginLeft: theme.spacing.sm,
    marginRight: theme.spacing.sm,
  },
  fileInput: {
    display: 'none',
  },
  selectFileButton: {
    flexShrink: 0,
  },
})

const snakeCaseKeys = object => {
  if (!object) {
    return null
  }
  return Object.fromEntries(Object.entries(object).map(([key, name]) => [snakeCase(key), name]))
}

class ImageUpload extends Component {
  static propTypes = {
    /**
     * `height` to validate image size
     */
    height: PropTypes.number,
    /**
     * `id` to be attached to the `file`-type input
     */
    id: PropTypes.string.isRequired,
    /**
     * `fileRequirementsText` is displayed under the "No file selected" text placeholder.
     */
    fileRequirementsText: PropTypes.string,
    /**
     * Promise which handles uploading the file to a server.
     */
    onImageUpload: PropTypes.func.isRequired,
    /**
     * Function to be called every time an image completes.
     */
    onImageUploadComplete: PropTypes.func.isRequired,
    /**
     * Function to be called if there is an error uploading the image.
     */
    onUploadError: PropTypes.func,
    /**
     * Configuration options to pass along to upload function
     */
    uploadOptions: PropTypes.object,
    /**
    // work around for the current product image async process.
    // As the FE and BE doesn't know when the image processing has been complete
    // we are going to use the encoded data from the upload for the preview rather
    // than the returned url for the BE as the file may not exist yet.
     */
    useEncodedforPreview: PropTypes.bool,
    /**
     * `width` to validate image size
     */
    width: PropTypes.number,
    /**
     * whether size should restrict upload
     */
    validateSize: PropTypes.bool,
    /**
     * Whether to allow video files as well.
     */
    allowVideo: PropTypes.bool,
    /**
     * Make it more compact.
     */
    compact: PropTypes.bool,
    /**
     * Convert file object keys to snake_case.
     */
    snakeCase: PropTypes.bool,
  }

  state = {
    initialImage: null,
    file: null,
    image: null,
    touched: false,
  }

  checkImageSize = file => {
    const reader = new FileReader()
    const readerPromise = new Promise(resolve => (reader.onload = resolve))
    reader.readAsDataURL(file)

    const imagePromise = readerPromise.then(e => {
      const url = e.target.result
      const loadImage = new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = () => resolve(img)
        img.onerror = reject
        img.src = url
      })

      return loadImage
    })

    return imagePromise
  }

  handleError = (message = 'There was an error uploading your image') => {
    this.props.onUploadError(message)
    this.setState({ file: null, image: null, touched: true })
  }

  handleImageUpload = event => {
    const file = event.target.files[0]
    if (file) {
      if (file.type.startsWith('video/') && this.props.allowVideo) {
        const maxSize = 10 * 1024 * 1024
        if (file.size > maxSize) {
          this.handleError(`The file is larger than ${filesize(file.size)}.`)
        } else {
          const { onImageUpload, onImageUploadComplete } = this.props
          onImageUpload(file)
            .then(res => {
              onImageUploadComplete(res)
              this.setState({ file: res, image: null, touched: true })
            })
            .catch(({ message }) => {
              this.handleError(message)
            })
        }
      } else if (file.type.startsWith('image/')) {
        this.checkImageSize(file)
          .then(img => {
            const {
              height,
              onImageUpload,
              onImageUploadComplete,
              uploadOptions,
              useEncodedforPreview,
              validateSize,
              width,
              snakeCase,
            } = this.props

            // Validate dimensions
            if (
              validateSize &&
              ((width && width !== img.width) || (height && height !== img.height))
            ) {
              this.handleError(`Image size needs to be ${width}x${height}px`)
              return
            }

            const config = {
              height: img.height || height,
              width: img.width || width,
            }

            onImageUpload(file, config, uploadOptions)
              .then(res => {
                const image = snakeCase ? snakeCaseKeys(res) : res
                onImageUploadComplete(image)
                if (useEncodedforPreview) {
                  this.setState({ file: null, image: { src: img.src }, touched: true })
                } else {
                  this.setState({ file: null, image, touched: true })
                }
              })
              .catch(({ message }) => this.handleError(message))
          })
          .catch(() => this.handleError())
      } else {
        this.handleError(`Unknown file type: ${file.type}`)
      }
    }
  }

  handleRemoveImage = e => {
    if (e) e.preventDefault()
    this.props.onImageUploadComplete()
    this.fileInput.value = ''
    this.setState({ file: null, image: null, touched: true })
  }

  render() {
    const {
      classes,
      fileRequirementsText,
      id,
      file: fileProp,
      image: imageProp,
      initialImage,
      removable,
      vertical,
      compact,
    } = this.props
    const { file: fileState, image: imageState, touched } = this.state
    const file = fileProp ?? fileState
    const image = imageProp ?? imageState

    let imageUrl = null

    // Check if an initial value is provided, ignore after an edit has been made
    if (
      initialImage &&
      (initialImage.imageUrl ||
        initialImage.image_url ||
        initialImage.fileUrl ||
        initialImage.file_url) &&
      !touched
    ) {
      imageUrl =
        initialImage.imageUrl ??
        initialImage.image_url ??
        initialImage.fileUrl ??
        initialImage.file_url
    } else if (image) {
      imageUrl = image.src || (image.imageUrl ?? image.image_url)
    } else if (file) {
      imageUrl = file.fileUrl ?? image.file_url
    }

    return (
      <div className={classes.container}>
        {imageUrl ? (
          <div
            className={cx(classes.uploadedImageContainer, {
              [classes.isVertical]: vertical,
              [classes.uploadedImageContainerCompact]: compact,
            })}
          >
            {imageUrl && (
              <div className={classes.uploadedImageThumbnailContainer}>
                <img src={imageUrl} alt="" />
              </div>
            )}
            {removable && (
              <Button kind="link" onClick={this.handleRemoveImage}>
                Remove
              </Button>
            )}
          </div>
        ) : (
          <div>
            <div
              className={cx(classes.emptyContainer, {
                [classes.isVertical]: vertical,
                [classes.emptyContainerVertical]: vertical,
                [classes.emptyContainerCompact]: compact,
              })}
            >
              <p className={classes.uploadedImageText}>No file selected</p>
              {!vertical && (
                <Button
                  className={classes.selectFileButton}
                  kind="secondary"
                  onClick={() => {
                    this.fileInput.click()
                  }}
                >
                  Choose file
                </Button>
              )}
            </div>
          </div>
        )}
        <div
          className={cx(classes.requirementsTextContainer, {
            [classes.requirementsTextContainerVertical]: vertical,
          })}
        >
          <input
            className={classes.fileInput}
            id={id}
            type="file"
            onChange={this.handleImageUpload}
            ref={el => {
              this.fileInput = el
            }}
          />
          {vertical && (
            <Button
              block
              className={classes.selectFileButton}
              kind="secondary"
              onClick={() => {
                this.fileInput.click()
              }}
            >
              Choose file
            </Button>
          )}
          {fileRequirementsText &&
            fileRequirementsText.split('\n').map((text, textIndex) => (
              <p className={classes.requirementsText} key={textIndex}>
                {text}
              </p>
            ))}
        </div>
      </div>
    )
  }
}

ImageUpload.defaultProps = {
  image: {},
  height: null,
  onImageUpload: new Promise(resolve => setTimeout(resolve, 200)),
  onUploadError: () => {},
  removable: true,
  uploadOptions: {},
  useEncodedforPreview: false,
  vertical: false,
  width: null,
  validateSize: true,
  allowVideo: false,
  compact: false,
  snakeCase: false,
}

export { ImageUpload as ImageUploadUnStyled }
export default injectSheet(styles)(ImageUpload)
