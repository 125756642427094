import { createSelector } from 'reselect'

import * as api from 'api'

const initialState = {
  isAuthenticated: false,
}

/**
 * redux-promise-middleware produces the following on Promise payloads:
 *
 * <ACTION>_PENDING
 * <ACTION>_FULFILLED
 * <ACTION>_REJECTED
 */

export default (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_CURRENT_USER_FULFILLED':
      return {
        ...state,
        data: action.payload,
        isAuthenticated: true,
      }
    case 'FETCH_CURRENT_USER_REJECTED':
      return {
        ...state,
        isAuthenticated: false,
      }
    case 'AUTHENTICATE_USER_FULFILLED':
      return {
        ...state,
        data: action.payload,
        isAuthenticated: true,
      }
    case 'UNAUTHENTICATE_USER_FULFILLED':
      return initialState
    default:
      return state
  }
}

export const fetchCurrentUser = () => ({
  type: 'FETCH_CURRENT_USER',
  payload: api.fetchCurrentUser(),
})

export const authenticateUser = payload => ({
  type: 'AUTHENTICATE_USER',
  payload: api.authenticateUser(payload),
})

export const unauthenticateUser = payload => ({
  type: 'UNAUTHENTICATE_USER',
  payload: api.unauthenticateUser(payload),
})

export const updateUser = (userId, payload) => ({
  type: 'UPDATE_USER',
  payload: api.updateUser(userId, payload),
})

export const changeUserPassword = (userId, payload) => ({
  type: 'CHANGE_USER_PASSWORD',
  payload: api.changeUserPassword(userId, payload),
})

export const sendResetPasswordEmail = payload => ({
  type: 'SEND_RESET_PASSWORD_EMAIL',
  payload: api.sendResetPasswordEmail(payload),
})

export const resetPasswordWithToken = (resetToken, payload) => ({
  type: 'RESET_PASSWORD_WITH_TOKEN',
  payload: api.resetPasswordWithToken(resetToken, payload),
})

// Selectors
export const getUserPermissions = state =>
  state.user.data.roles?.length
    ? state.user.data.roles
        .reduce((aggregatePermissions, role) => aggregatePermissions.concat(role.permissions), [])
        // Remove duplicate permissions
        .filter(
          (permission, index, permissions) =>
            permissions.findIndex(p => p.id === permission.id) === index
        )
    : state.user.data.role.permissions

export const getUserPermissionKeys = createSelector(getUserPermissions, permissions =>
  permissions.map(permission => permission.key)
)
