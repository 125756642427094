export default theme => ({
  headerCollectionButtons: {
    alignItems: 'center',
    display: 'flex',

    '& > *': {
      marginRight: theme.spacing.xs,

      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  underHeaderContainer: {
    display: 'flex',
  },
  collectionTabContainer: {
    alignItems: 'flex-start',
    display: 'flex',
  },
  collectionTableTitle: {
    color: theme.text01,
    fontSize: theme.fontSize.h2,
    paddingBottom: theme.spacing.sm,
  },
  detailImage: {
    display: 'inline-block',
    verticalAlign: 'middle',

    '& > img': {
      display: 'block',
      maxHeight: 16,
    },
  },
  leftSide: {
    flexShrink: 0,
    marginRight: theme.spacing.xxlg,
    width: 272,
  },
  rightSide: {
    flexGrow: 1,
    flexShrink: 1,
  },
  dynamicCollectionTooltip: {
    height: 16,
    width: 12,
    marginLeft: 5,
    marginTop: 5,
  },
})
