import memoize from 'memoize-one'
import React from 'react'
import injectSheet from 'react-jss'

import { fetchRequests, getDownloadLink } from 'api'
import { TableView } from 'layouts'
import { generateBackButton, getRoutePathname } from 'routing'
import { StatusIndicatorDot, Button, Modal, DropdownDate, Dropdown, MultiSelect } from 'components'
import { formatDatetime } from 'utils'

import requestTypes from './requestTypes'
import CreateRequest from './CreateRequest'

const styles = () => ({
  filterDropdown: {
    width: 166,
  },
})

// Table Configuration
const PAGE_ID = 'ccpaRequests'
const PAGE_TITLE = 'Privacy Requests'
const FETCH_FUNCTION = fetchRequests

const tableHeaders = [
  {
    key: 'request_date',
    header: 'Request Date',
  },
  {
    key: 'user_email',
    header: 'Guest Email',
  },
  {
    key: 'type',
    header: 'Request Type',
  },
  {
    key: 'status',
    header: 'Status',
  },
  {
    key: 'processed_date',
    header: 'Processed Date',
  },
  {
    key: 'download',
    header: 'Download',
    disableSorting: true,
  },
]

const tableParams = {
  requestDate: { key: 'request_date', type: 'range' },
  status: { key: 'status', type: 'array' },
  type: { key: 'type' },
  paging: {
    type: 'multi',
    multiParams: { limit: { key: 'limit' }, offset: { key: 'offset' } },
  },
  search: { key: 'search' },
  sorting: {
    type: 'multi',
    multiParams: { direction: { key: 'direction' }, orderBy: { key: 'order_by' } },
  },
}

const statusTypes = {
  success: {
    type: 'complete',
    text: 'Complete',
  },
  processing: {
    type: 'new',
    text: 'In Progress',
  },
  paused: {
    type: 'in-progress',
    text: 'Paused/Hold',
  },
  expired: {
    // don't apply a class, keep it default (gray)
    type: '',
    text: 'Expired',
  },
  error: {
    type: 'error',
    text: 'Failed',
  },
}

const statusFilters = [
  { id: `${PAGE_ID}-filter-status-success`, value: 'success', label: 'Complete' },
  { id: `${PAGE_ID}-filter-status-processing`, value: 'processing', label: 'In Progress' },
  { id: `${PAGE_ID}-filter-status-paused`, value: 'paused', label: 'Paused/Hold' },
  { id: `${PAGE_ID}-filter-status-expired`, value: 'expired', label: 'Expired' },
  { id: `${PAGE_ID}-filter-status-error`, value: 'error', label: 'Failed' },
]

const formatTableActions = ({ refetchItems }) => [
  {
    id: `${PAGE_ID}-create`,
    permission: 'ccpa.create',
    content: 'Create Request',
    action: 'modal',
    actionComponent: Modal,
    modalOptions: {
      render: ({ closeModal }) => (
        <CreateRequest
          closeModal={({ requiresRefetch }) => {
            if (requiresRefetch) {
              refetchItems()
            }
            closeModal()
          }}
        />
      ),
    },
  },
]

const formatTableRows = memoize(({ items, location }) =>
  items.map(request => {
    const { id, createdAt, userId, userEmail, type, status, updatedAt, downloadAvailable } = request

    return {
      id: `request-${id}`,
      rowLinkTo: {
        pathname: getRoutePathname('customers.customer', { id: userId }),
        state: { backButton: generateBackButton('ccpaRequests', location) },
      },
      request_date: formatDatetime(createdAt),
      user_email: userEmail,
      type: requestTypes[type].label,
      status: <StatusIndicatorDot {...statusTypes[status]} />,
      processed_date: formatDatetime(updatedAt),
      download: downloadAvailable ? (
        <Button
          kind="link"
          linkIsDownload
          icon="download"
          iconDescription="Download"
          href={getDownloadLink(id)}
        />
      ) : null,
    }
  })
)

const formatTableFilters = classes => params => [
  {
    component: DropdownDate,
    id: `${PAGE_ID}-filter-request-date`,
    className: classes.filterDropdown,
    hasDateOptions: false,
    returnDatetimeValues: false,
    placeholder: 'Request Date',
    onChange: params.requestDate.onChange,
    defaultValue: params.requestDate.value,
  },
  {
    component: Dropdown,
    id: `${PAGE_ID}-filter-type`,
    className: classes.filterDropdown,
    items: [{ id: 'clear', label: 'Any Type', value: null }, ...Object.values(requestTypes)],
    nowrap: true,
    placeholder: 'Request Type',
    onChange: params.type.onChange,
    selectedValue: params.type.value,
  },
  {
    component: MultiSelect,
    id: `${PAGE_ID}-filter-status`,
    className: classes.filterDropdown,
    items: statusFilters,
    nowrap: true,
    placeholder: 'Status',
    onChange: params.status.onChange,
    selectedValues: params.status.value,
  },
]

const CCPARequests = ({ classes, location, ...props }) => {
  return (
    <TableView
      {...props}
      queryKey="CCPARequests"
      location={location}
      pageId={PAGE_ID}
      pageTitle={PAGE_TITLE}
      pageType={PAGE_ID}
      fetchFunction={FETCH_FUNCTION}
      tableHeaders={tableHeaders}
      tableParams={tableParams}
      tableActions={formatTableActions}
      tableRows={formatTableRows}
      tableFilters={formatTableFilters(classes)}
      tableFiltersAlign="below-header"
    />
  )
}

export default injectSheet(styles)(CCPARequests)
