import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import injectSheet from 'react-jss'
import { CSSTransitionGroup } from 'react-transition-group'

import {
  Button,
  ButtonIconAction,
  DragAndDropContainer,
  DraggableRow,
  DropContainer,
  Icon,
  Modal,
} from 'components'
import { removeAtIndex } from 'utils'

import SelectedItemCell from './SelectedItemCell'
import SelectedItemRow from './SelectedItemRow'
import styles from './SelectedItems.styles'

// TODO: Refine transitions and create/utilize global transition timing functions, durations, etc.

const SelectedItems = ({
  classes,
  className,
  disabled: isDisabled,
  headerText,
  draggable,
  itemNamePlural,
  items,
  noSelectedItemsMsg,
  onChange,
  onDragEnd,
  onEdit,
  onRemove,
  onRemoveAll,
  readOnly,
  renderItem,
  rowSeparator,
  confirmClearAll,
}) => {
  const clearAll = () => {
    onChange([])
    if (onRemoveAll) {
      onRemoveAll()
    }
  }
  const clearAllButton = confirmClearAll ? (
    <Modal.Confirmation
      handleCloseModal={({ wasConfirmed }) => {
        if (wasConfirmed) {
          clearAll()
        }
      }}
      triggerRender={({ openModal }) => (
        <Button
          className={classes.headerClearAll}
          disabled={isDisabled}
          kind="link-danger"
          onClick={openModal}
        >
          Clear all
        </Button>
      )}
    />
  ) : (
    <Button
      className={classes.headerClearAll}
      disabled={isDisabled}
      kind="link-danger"
      onClick={clearAll}
    >
      Clear all
    </Button>
  )

  if (isDisabled) {
    draggable = false
  }

  return (
    <CSSTransitionGroup
      transitionName={{
        leave: classes.itemRowLeave,
        leaveActive: classes.itemRowLeaveActive,
      }}
      transitionEnter={false}
      transitionLeaveTimeout={420}
    >
      <div
        className={cx(className, classes.selectedItems, {
          [classes.disabledContainer]: isDisabled,
        })}
      >
        {items.length > 0 && (
          <Fragment>
            <div className={classes.headerContainer}>
              <span className={classes.headerText}>{headerText}</span>
              {!readOnly && clearAllButton}
            </div>
            <DragAndDropContainer
              isDraggable={draggable}
              onDragEnd={onDragEnd}
              render={() => (
                <DropContainer
                  id="selecteditem-drop"
                  isDraggable={draggable}
                  render={({ droppableProvided }) => (
                    <ul ref={droppableProvided.innerRef}>
                      <CSSTransitionGroup
                        transitionName={{
                          leave: classes.itemRowLeave,
                          leaveActive: classes.itemRowLeaveActive,
                        }}
                        transitionEnter={false}
                        transitionLeaveTimeout={420}
                      >
                        {items.map((item, index) => (
                          <DraggableRow
                            isDraggable={draggable}
                            id={`${item.id}`}
                            index={index}
                            key={item.id}
                            render={({ draggableProvided, draggableSnapshot }) => (
                              <SelectedItemRow
                                disabled={isDisabled}
                                isDraggable={draggable}
                                hasRowSeparator={rowSeparator !== null}
                                classes={classes}
                                provided={draggableProvided}
                                snapshot={draggableSnapshot}
                              >
                                <SelectedItemCell className={classes.itemContentCell}>
                                  {rowSeparator && index !== 0 && (
                                    <div className={classes.rowSeparatorWrapper}>
                                      <span className={classes.rowSeparator}>{rowSeparator}</span>
                                    </div>
                                  )}
                                  {renderItem({ item })}
                                </SelectedItemCell>
                                {!readOnly && (
                                  <>
                                    {onEdit && (
                                      <SelectedItemCell>
                                        <span className={classes.itemRowEdit}>
                                          <ButtonIconAction
                                            description="Edit item"
                                            disabled={isDisabled}
                                            icon="edit"
                                            onClick={() => {
                                              onEdit(items[index])
                                            }}
                                          />
                                        </span>
                                      </SelectedItemCell>
                                    )}
                                    <SelectedItemCell>
                                      <span className={classes.itemRowClear}>
                                        <ButtonIconAction
                                          description="Clear item"
                                          disabled={isDisabled}
                                          icon="close"
                                          onClick={() => {
                                            onChange(removeAtIndex(items, index))
                                            if (onRemove) {
                                              onRemove(item)
                                            }
                                          }}
                                        />
                                      </span>
                                    </SelectedItemCell>
                                  </>
                                )}
                              </SelectedItemRow>
                            )}
                          />
                        ))}
                      </CSSTransitionGroup>
                    </ul>
                  )}
                />
              )}
            />
          </Fragment>
        )}
        {items.length === 0 && (
          <div className={classes.noSelectedItems}>
            <Icon className={classes.noSelectedItemsIcon} name="warning" />
            <span className={classes.noSelectedItemsText}>
              {noSelectedItemsMsg || `No ${itemNamePlural} added`}
            </span>
          </div>
        )}
      </div>
    </CSSTransitionGroup>
  )
}

SelectedItems.propTypes = {
  /**
   * Will fully disable the component when set to `true`
   */
  disabled: PropTypes.bool,
  /**
   * Displayed above all the selected items and to the left of the 'Clear All' column.
   */
  headerText: PropTypes.string.isRequired,
  /**
   * Add drag and drop to selected items.
   */
  draggable: PropTypes.bool,
  /**
   * Used to generate the text displayed when no items have been selected yet
   * and when `noSelectedItemsMsg` has not been provided.
   */
  itemNamePlural: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    })
  ).isRequired,
  /**
   * The message displayed when no items have been selected yet.
   * (Otherwise defaults to "No ${itemNamePlural} added")
   */
  noSelectedItemsMsg: PropTypes.string,
  /**
   * (selectedItem) => {...}
   * Called when an item is triggered for editing.
   */
  onEdit: PropTypes.func.isRequired,
  /**
   * (newSelectedItems) => {...}
   * Should change the consuming component's state value for `items`.
   * Called when one or all items are triggered for removal.
   */
  onChange: PropTypes.func.isRequired,
  onChangeCheckedStrategy: PropTypes.string,
  /**
   * Optional drag end handler to use with draggable boolean.
   */
  onDragEnd: PropTypes.func,
  /**
   * Optional handlers the consuming component can pass in instead/in addition to `onChange`
   * to get as an argument the item that was removed (for `onRemove`).
   */
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  /**
   * If set to `true`, will hide the ability to remove items from the user.
   */
  readOnly: PropTypes.bool,
  /**
   * ({ item }) => {...}
   * Renders item except for the clear column (located on the very right).
   */
  renderItem: PropTypes.func.isRequired,
  /**
   * Determins if the "clear all" button should trigger a confirmation before going through
   */
  rowSeparator: PropTypes.node,
  confirmClearAll: PropTypes.bool,
}

SelectedItems.defaultProps = {
  draggable: false,
  itemNamePlural: 'items',
  onChange: () => {},
  onDragEnd: () => {},
  readOnly: false,
  renderItem: ({ item }) => <div>{item.name}</div>,
  rowSeparator: null,
}

export { SelectedItems as SelectedItemsUnStyled }
export default injectSheet(styles)(SelectedItems)
