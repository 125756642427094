import cx from 'classnames'
import React from 'react'
import injectSheet from 'react-jss'

import { Checkbox } from 'components'

const styles = theme => ({
  tableSelect: {
    opacity: 0,

    // Show on hover, if `showCheckbox` override prop is true, or if Checkbox input is focused
    '&$showCheckbox, &:hover, input:focus + &': {
      opacity: 1,
    },
  },
  showCheckbox: {},
})

const TableSelect = ({ classes, className, showCheckbox, ...restProps }) => (
  <Checkbox
    hideLabel
    className={cx(className, classes.tableSelect, {
      [classes.showCheckbox]: showCheckbox,
    })}
    {...restProps}
  />
)

export default injectSheet(styles)(TableSelect)
