import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import injectSheet from 'react-jss'

import { Icon, ListBox } from 'components'

import styles from './SearchInput.styles'

class SearchInput extends Component {
  static displayName = 'SearchInput'

  static propTypes = {
    className: PropTypes.string,
    hideClearInput: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    initialValue: PropTypes.string,
    /**
     * If `true`, will set aria-describedby to `${id}-error-msg`
     */
    isInvalid: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    /**
     * If provided, will display the "submit mode" (with the search icon
     * prefix removed and a search submit button attached to the right).
     */
    onSubmit: PropTypes.func,
    placeholder: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }

  static defaultProps = {
    hideClearInput: false,
    icon: 'search',
    placeholder: 'Search',
  }

  clearInput = event => {
    const { onChange, onClear, value: oldValue } = this.props

    if (onClear) {
      onClear(oldValue)
    }
    if (onChange) {
      onChange('')
    }

    event.preventDefault() // stop Space key used to clear input from then placing " " in input

    this.input.focus()
  }

  handleChange = ({ target: { value: newValue } }) => {
    const { onChange } = this.props

    if (onChange) {
      onChange(newValue)
    }
  }

  handleSubmit = event => {
    const { onSubmit, value } = this.props
    event.preventDefault()

    if (onSubmit) {
      onSubmit(value)
    }
  }

  render() {
    const {
      classes,
      className,
      hideClearInput,
      icon,
      id,
      initialValue,
      isInvalid,
      placeholder,
      onClear,
      onSubmit,
      theme,
      value,
      ...restProps
    } = this.props

    const errorProps = isInvalid
      ? {
          'data-invalid': true,
          'aria-invalid': true,
          'aria-describedby': `${id}-error-msg`,
        }
      : {}
    const hasValue = !!value
    const shouldShowSubmitButton = !!onSubmit

    const inputAndClear = (
      <Fragment>
        <input
          {...errorProps}
          {...restProps}
          className={cx(classes.searchInput, {
            [classes.searchInputInvalid]: isInvalid,
            [classes.searchInputWithValue]: hasValue,
            [classes.searchInputWithSubmit]: shouldShowSubmitButton,
            [classes.searchInputWithoutSubmit]: !shouldShowSubmitButton,
          })}
          defaultValue={initialValue}
          id={id}
          onChange={this.handleChange}
          placeholder={placeholder}
          ref={el => {
            this.input = el
          }}
          type="text"
          value={value}
        />
        {hasValue && !hideClearInput && (
          <ListBox.Selection
            className={cx(classes.searchInputClear, {
              [classes.searchInputInvalidClear]: isInvalid,
              [classes.searchInputWithSubmitClear]: shouldShowSubmitButton,
              [classes.searchInputWithoutSubmitClear]: !shouldShowSubmitButton,
            })}
            clearSelection={this.clearInput}
            description="Clear search term"
          />
        )}
      </Fragment>
    )

    return shouldShowSubmitButton ? (
      <form className={className} onSubmit={this.handleSubmit}>
        <div className={classes.container} role="search">
          {inputAndClear}
          <button
            aria-label="Submit search"
            className={cx(classes.searchButton, {
              [classes.searchButtonDisabled]: !hasValue,
            })}
            type="submit"
          >
            <Icon name={icon} className={classes.searchButtonIcon} />
          </button>
        </div>
      </form>
    ) : (
      <div className={cx(className, classes.container)} role="search">
        <Icon name={icon} className={classes.searchInputIcon} />
        {inputAndClear}
      </div>
    )
  }
}

export { SearchInput as SearchInputUnStyled }
export default injectSheet(styles)(SearchInput)
