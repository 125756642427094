import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import injectSheet from 'react-jss'

import { mixins } from 'styling'

const styles = theme => {
  const formItem = mixins.formItem({ theme })

  return {
    formGroupLabel: {
      ...mixins.labelText({ theme }),
    },
    formGroupContent: {
      display: 'flex',
      paddingTop: theme.spacing.sm,
      paddingBottom: theme.spacing.sm,
      alignItems: 'center',
    },
    formGroupContentVertical: {
      flexDirection: 'column',
      alignItems: 'baseline',
    },
    formGroupItem: {
      ...formItem.default,
    },
    horizontalFormGroupItem: {
      ...formItem.horizontal,
    },
    verticalFormGroupItem: {
      ...formItem.vertical,
    },
  }
}

const FormGroup = ({ className, classes, label, items, orientation }) => {
  const formGroupItemClass = cx(classes.formGroupItem, {
    [classes.horizontalFormGroupItem]: orientation === 'horizontal',
    [classes.verticalFormGroupItem]: orientation === 'vertical',
  })

  const containerClass = cx(classes.formGroupContent, {
    [classes.formGroupContentVertical]: orientation === 'vertical',
  })

  return (
    <div className={className}>
      <div className={classes.formGroupLabel}>{label}</div>
      <div className={containerClass}>
        {items.map((item, idx) => (
          <div className={formGroupItemClass} key={idx}>
            {item}
          </div>
        ))}
      </div>
    </div>
  )
}

FormGroup.propTypes = {
  className: PropTypes.string,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']).isRequired,
  label: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.element).isRequired,
}

export default injectSheet(styles)(FormGroup)
