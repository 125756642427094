import React from 'react'
import injectSheet from 'react-jss'

import { Checkbox, FormFieldItem } from 'components'

import styles from '../PromotedPlacementsForm.styles'

const Platform = ({ classes, componentId, checkboxOptions, disabled = false }) => (
  <FormFieldItem
    className={classes.formRow}
    id={componentId}
    labelText="Platform Visibility"
    name="platform"
    render={() => (
      <div className={classes.formInput}>
        {checkboxOptions.map((platform, index) => (
          <FormFieldItem
            className={classes.formInlineCheckbox}
            id={platform.id}
            key={index}
            name={`platform.${platform.name}`}
            render={renderProps => (
              <Checkbox
                {...renderProps}
                checked={renderProps.value}
                labelText={platform.labelText}
                disabled={disabled}
              />
            )}
          />
        ))}
      </div>
    )}
  />
)

export default injectSheet(styles)(Platform)
