import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import injectSheet from 'react-jss'

import styles from './Tabs.styles'

const isTab = el => el.type && el.type.displayName === 'Jss(Tab)'
export function tabPropType(props, propName, componentName) {
  let error
  const children = props[propName]

  React.Children.forEach(children, child => {
    if (!error && child && !isTab(child)) {
      error = new Error('Each `Tabs` child must be a `Tab` component.')
    }
  })

  return error
}

class Tabs extends Component {
  static displayName = 'Tabs'

  static propTypes = {
    children: tabPropType,
    className: PropTypes.string,
    noContentPadding: PropTypes.bool,
    /**
     * `onSelectTab` is called whenever the selected tab changes
     * (passes in the index of the selected tab)
     */
    onSelectTab: PropTypes.func,
    selectedTab: PropTypes.number,
  }

  static defaultProps = {
    noContentPadding: false,
    selectedTab: 0,
  }

  getTabs = () => React.Children.map(this.props.children, tab => tab)

  getTabAt = index => this[`tab-${index}-el`] || React.Children.toArray(this.props.children)[index]

  handleTabClick = (index, event) => {
    event.preventDefault()

    this.selectTabAt(index)
  }

  handleTabKeyDown = (index, event) => {
    const key = event.key || event.which

    if (key === 'Enter' || key === 13 || key === ' ' || key === 32) {
      this.selectTabAt(index)
    }
  }

  handleTabAnchorFocus = index => {
    const tabCount = React.Children.count(this.props.children) - 1

    let tabIndex
    if (index < 0) {
      tabIndex = tabCount
    } else if (index > tabCount) {
      tabIndex = 0
    } else {
      tabIndex = index
    }

    const tab = this.getTabAt(tabIndex)

    if (tab) {
      this.selectTabAt(tabIndex)
      if (tab.tabAnchor) {
        tab.tabAnchor.focus()
      }
    }
  }

  selectTabAt = index => {
    const { onSelectTab, selectedTab } = this.props

    if (selectedTab !== index && onSelectTab) {
      onSelectTab(index)
    }
  }

  render() {
    const {
      classes,
      className,
      noContentPadding,
      onSelectTab,
      selectedTab,
      theme,
      ...restProps
    } = this.props

    const tabsWithProps = this.getTabs().map((tab, index) =>
      React.cloneElement(tab, {
        handleTabAnchorFocus: this.handleTabAnchorFocus,
        handleTabClick: this.handleTabClick,
        handleTabKeyDown: this.handleTabKeyDown,
        index,
        selected: index === selectedTab,
        ref: el => {
          this[`tab-${index}-el`] = el
        },
      })
    )

    return (
      <div className={cx(classes.container, className)}>
        <nav className={classes.tabs} {...restProps}>
          <ul role="tablist" className={classes.tabList}>
            {tabsWithProps}
          </ul>
        </nav>
        {tabsWithProps.map(({ props: { children, render, selected: isSelected } }, index) =>
          isSelected ? (
            <div
              className={cx(classes.tabContent, {
                [classes.noTabContentPadding]: noContentPadding,
              })}
              key={index}
            >
              {render ? render() : children}
            </div>
          ) : null
        )}
      </div>
    )
  }
}

export { Tabs as TabsUnStyled }
export default injectSheet(styles)(Tabs)
