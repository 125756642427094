import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import injectSheet from 'react-jss'

import styles from './ListBoxField.styles'

/**
 * `ListBoxField` is responsible for creating the containing node for valid
 * elements inside of a field. It also provides a11y-related attributes like
 * `role` to make sure a user can focus the given field.
 */
const ListBoxField = ({
  children,
  classes,
  isDisabled,
  isInvalid,
  isFocused,
  isSelected,
  setRef,
  theme,
  ...restProps
}) => (
  <div
    role="button"
    className={cx(classes.listBoxField, {
      [classes.listBoxFieldDisabled]: isDisabled,
      [classes.listBoxFieldInvalid]: isInvalid,
      [classes.listBoxFieldFocused]: isFocused,
      [classes.listBoxFieldSelected]: isSelected,
    })}
    tabIndex="0"
    {...(setRef ? { ref: setRef } : {})}
    {...restProps}
  >
    {children}
  </div>
)

ListBoxField.propTypes = {
  children: PropTypes.node, // children should be any of: ListBoxMenuIcon, ListBoxSelection, 'span', 'input'
  isFocused: PropTypes.bool, // for MultiSelectFilterable, we need to pass this in because the focus is placed on the input inside the field
  isInvalid: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isSelected: PropTypes.bool,
}

export default injectSheet(styles)(ListBoxField)
