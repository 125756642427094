import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import injectSheet from 'react-jss'

import { Autocomplete, Button, FormItem, Modal, DataTable } from 'components'
import { capitalize } from 'utils'

import { DEFAULT_TABLE_LIMIT } from 'defaults'

import { sortStates } from '../DataTable/state/sorting'

const styles = theme => ({
  addAutocompleteAndModalTrigger: {
    display: 'flex',
    marginBottom: 20,
  },
  addAutocomplete: {
    flexGrow: 1,
  },
  addAutocompleteRestrictedWidth: {
    maxWidth: 400,
  },
  addModalTrigger: {
    marginLeft: theme.spacing.md,
  },
})

/**
 * Combines Autocomplete, DataTable, and Modal.BrowseItems to allow users to
 * browse, add, and remove items from a table.
 */
const AddItemsAsyncWithTable = ({
  autocompleteLabelKey,
  autocompleteRenderMenuItem,
  classes,
  errorFetchingSelectedItems,
  errorMsg,
  fetchItems,
  fetchItemsAutocomplete,
  id,
  isFetchingSelectedItems,
  itemName,
  labelText,
  modalProps,
  onChange,
  readOnly,
  removeSelectedItemsFromBox,
  restrictAutocompleteWidth,
  selectedItems,
  tableHeaders,
  tableItemsCount,
  tablePageId,
  tableParams,
  tableRows,
}) => {
  const capitalizedPluralItemName = `${capitalize({ phrase: itemName })}s`

  // Paging props
  const resultsPerPage =
    parseInt(tableParams.paging.multiParams.limit.value, 10) || DEFAULT_TABLE_LIMIT
  const pageNumber = (tableParams.paging.multiParams.offset.value || 0) / resultsPerPage + 1

  return (
    <FormItem fieldId={id} labelText={labelText} errorMsg={errorMsg}>
      <div className={classes.container}>
        {!readOnly && (
          <div className={classes.addAutocompleteAndModalTrigger}>
            <Autocomplete
              className={cx(classes.addAutocomplete, {
                [classes.addAutocompleteRestrictedWidth]: restrictAutocompleteWidth,
              })}
              id={id}
              labelKey={autocompleteLabelKey}
              loadItems={fetchItemsAutocomplete}
              onItemSelect={selectedItem => {
                const previouslySelectedItems = selectedItems
                onChange([...previouslySelectedItems, selectedItem])
              }}
              placeholder={`Add ${capitalizedPluralItemName}`}
              renderMenuItem={autocompleteRenderMenuItem}
              searchIcon="plus"
              selectedItems={selectedItems}
            />
            <Modal.BrowseItemsAsync
              handleCloseModal={({ newSelectedItems }) => {
                if (newSelectedItems) {
                  onChange(newSelectedItems)
                }
              }}
              triggerRender={({ openModal }) => (
                <Button
                  className={classes.addModalTrigger}
                  id={`${id}-browse`}
                  kind="link"
                  onClick={() => {
                    openModal({
                      selectedItems,
                    })
                  }}
                >
                  See All {capitalizedPluralItemName}
                </Button>
              )}
              contentProps={{
                fetchItems,
                itemName,
                initialQueryStateValues: {
                  sortDirection: sortStates.ASC,
                  sortHeaderKey: 'firstName',
                },
                initialSelectedItems: selectedItems,
                ...modalProps,
              }}
            />
          </div>
        )}

        <DataTable
          error={errorFetchingSelectedItems}
          headers={tableHeaders}
          id={`${tablePageId}-table`}
          kind="light"
          sortHeaderKey={tableParams.sorting.multiParams.orderBy.value}
          sortDirection={tableParams.sorting.multiParams.direction.value}
          isLoadingNewRows={isFetchingSelectedItems}
          onSortBy={({ nextSortHeaderKey, nextSortDirection }) => {
            tableParams.sorting.onChange({
              orderBy: nextSortHeaderKey,
              direction: nextSortDirection,
            })
          }}
          rows={tableRows}
          pagingProps={{
            pageNumber,
            resultsPerPage,
            onPagingChange: ({ pageNumber, resultsPerPage }) => {
              tableParams.paging.onChange({
                limit: resultsPerPage,
                offset: (pageNumber - 1) * resultsPerPage || undefined,
              })
            },
            resultsTotal: tableItemsCount,
          }}
        />
      </div>
    </FormItem>
  )
}

AddItemsAsyncWithTable.propTypes = {
  autocompleteLabelKey: PropTypes.string,
  autocompleteRenderMenuItem: PropTypes.func,
  /**
   * Displayed under the Autocomplete when passed in
   */
  errorMsg: PropTypes.string,
  fetchItems: PropTypes.func.isRequired,
  /**
   * Takes `inputvalue` as a parameter and must return a list of items to fill the
   * Autocomplete's results.
   */
  fetchItemsAutocomplete: PropTypes.func.isRequired,
  /**
   * Applied to the Autocomplete (and to the "See All" browse link as `${id}-browse`)
   */
  id: PropTypes.string.isRequired,
  isFetchingSelectedItems: PropTypes.bool,
  itemName: PropTypes.string.isRequired,
  /**
   * Renders a form label above the Autocomplete when passed in
   */
  labelText: PropTypes.string,
  /**
   * Passed into the `Modal.BrowseItem`'s `contentProps`
   */
  modalProps: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  /**
   * If set to `true`, will only show the selected items with no option for the user to
   * add or remove any.
   */
  readOnly: PropTypes.bool,
  /**
   * Alters the styling of the SelectedItems component and wraps it in a box
   */
  removeSelectedItemsFromBox: PropTypes.bool,
  restrictAutocompleteWidth: PropTypes.bool,
  selectedItemsCount: PropTypes.number,
  tableHeaders: PropTypes.array.isRequired,
  tableItemsCount: PropTypes.number.isRequired,
  tablePageId: PropTypes.string.isRequired,
  tableParams: PropTypes.object.isRequired,
  tableRows: PropTypes.array,
}

AddItemsAsyncWithTable.defaultProps = {
  readOnly: false,
  removeSelectedItemsFromBox: false,
  restrictAutocompleteWidth: false,
  tableHeaders: [],
  tableRows: [],
}

export default injectSheet(styles)(AddItemsAsyncWithTable)
