import memoize from 'memoize-one'
import React, { Component, Fragment } from 'react'
import injectSheet from 'react-jss'

import { fetchCollection, fetchPlacementContentById, updatePlacement } from 'api'
import {
  Button,
  DataTableWithState,
  LoadingIndicator,
  Modal,
  Notification,
  PageHeader,
  ReadOnlyValueCard,
  ShowIfAuthorized,
  Tab,
  Tabs,
} from 'components'
import config from 'config'
import { createToast } from 'modules/toasts'

import Styling, { ProductImagesRow } from 'styling/components'
import { getBackButton, getRoutePathname } from 'routing'
import { capitalize, formatDate, ItemFetcher, openWebPlatform } from 'utils'

import {
  initializeAltText,
  initializeMediaContent,
} from '../../Marketing/PromotedPlacements/PromotedPlacementsDetails'

const PAGE_ID = 'featured-department'

const styles = theme => ({
  collectionImages: {
    width: 50,
    display: 'inline',
  },
  imageWidth: {
    verticalAlign: 'top',
    maxWidth: 400,
  },
  leftSide: {
    flexShrink: 0,
    marginRight: theme.spacing.xxlg,
    width: 272,
  },
  rightSide: {
    flexGrow: 1,
    flexShrink: 1,
  },
  underHeaderContainer: {
    display: 'flex',
  },
})

const statusMap = {
  readable: {
    approved: {
      confirm: 'approve',
      trigger: 'approve',
    },
    hidden: {
      confirm: 'hide',
      trigger: 'hide',
    },
    pending: {
      confirm: 'submit',
      trigger: 'submit',
    },
    rejected: {
      confirm: 'reject',
      trigger: 'reject',
    },
  },
  buttonStyle: {
    approved: 'primary',
    hidden: 'danger',
    pending: 'primary',
    rejected: 'secondary',
  },
  statusIndicator: {
    approved: 'completed',
    completed: 'canceled',
    draft: 'in-progress',
    pending: 'in-progress',
    rejected: 'canceled',
  },
}

const tableHeaders = [
  {
    key: 'title',
    header: 'Title',
    disableSorting: true,
  },
  {
    key: 'linkedCollections',
    header: 'Linked Collections',
    disableSorting: true,
  },
  {
    key: 'productImages',
    header: '',
    disableSorting: true,
  },
]

const formatTableRows = memoize(({ classes, collections }) =>
  collections.map(({ displayName, id, name, products }) => {
    const productImages = <ProductImagesRow products={products} />
    const collectionLink = (
      <Button
        href={{
          pathname: getRoutePathname('collections.collection', { id }),
        }}
        kind="link"
      >
        {name}
      </Button>
    )

    return {
      id,
      linkedCollections: collectionLink,
      productImages,
      title: displayName || name,
    }
  })
)

class FeaturedDepartmentDetailsFields extends Component {
  state = {
    collections: [],
    errorFetchingCollections: false,
    isFetchingCollections: false,
    selectedTab: 0,
  }

  componentWillMount() {
    const {
      featuredDepartment: {
        target: { collection_list },
      },
    } = this.props
    const loadCollections = async () => {
      this.setState({ isFetchingCollections: true })
      const collections = await Promise.all(
        collection_list.map(({ id }) =>
          fetchCollection(id).catch(e =>
            this.setState({ errorFetchingCollections: true, isFetchingCollections: false })
          )
        )
      )

      this.setState({
        collections,
        isFetchingCollections: false,
      })
    }

    loadCollections()
  }

  formatModalConfirmationButton = buttonStatus => {
    const { featuredDepartment } = this.props
    const { id } = featuredDepartment

    const modalConfirmationProps = {
      handleCloseModal: ({ wasConfirmed }) => {
        const updatedFeaturedDepartment = { ...featuredDepartment, status: buttonStatus }

        const successToast = () => {
          createToast({
            kind: 'success',
            message: `Merchandised Landing Page successfully ${updatedFeaturedDepartment.status}.`,
          })
        }

        if (wasConfirmed) {
          updatePlacement(id, updatedFeaturedDepartment)
            .then(() => {
              successToast()
              this.props.refetchFeaturedDepartment()
            })
            .catch(({ message }) => {
              createToast({ kind: 'error', message })
            })
        }
      },
      triggerRender: ({ openModal }) => (
        <Button kind={statusMap.buttonStyle[buttonStatus]} onClick={openModal}>
          {capitalize({ phrase: statusMap.readable[buttonStatus].trigger })}
        </Button>
      ),
      contentProps: {
        actionText: `${statusMap.readable[buttonStatus].trigger} this Merchandised Landing Page`,
        confirmButtonText: capitalize({ phrase: statusMap.readable[buttonStatus].confirm }),
      },
    }

    return <Modal.Confirmation {...modalConfirmationProps} />
  }

  selectTab = selectedTab => {
    this.setState({ selectedTab })
  }

  render() {
    const { classes, featuredDepartment } = this.props
    const { collections, errorFetchingCollections, isFetchingCollections, selectedTab } = this.state

    const {
      created,
      customerSegment,
      endDate,
      id,
      media,
      modified,
      name: featuredDepartmentName,
      placement,
      promotedMedia,
      startDate,
      status,
      storeGroup,
    } = featuredDepartment

    const contentArray = initializeMediaContent({ classes, media, promotedMedia })
    const altTextArray = initializeAltText({ classes, media, promotedMedia })

    return (
      <Fragment>
        <PageHeader
          backButton={getBackButton('featuredDepartments')}
          headerTitle={featuredDepartmentName}
          statusText={capitalize({ phrase: status })}
          statusType={statusMap.statusIndicator[status]}
        >
          <Styling.LineOfItems alignCenter>
            <ShowIfAuthorized requiredPermission="content.edit">
              {status === 'pending' ? this.formatModalConfirmationButton('rejected') : null}
              {status === 'pending' ? this.formatModalConfirmationButton('approved') : null}

              {status === 'draft' || status === 'rejected' || status === 'hidden' ? (
                <Button
                  href={{
                    pathname: getRoutePathname('featuredDepartments.editFeaturedDepartment', {
                      id,
                    }),
                    state: {
                      featuredDepartmentsPlacement: placement,
                      id,
                    },
                  }}
                  kind="secondary"
                >
                  Edit
                </Button>
              ) : null}
              {status === 'draft' ? this.formatModalConfirmationButton('pending') : null}

              <Button
                href={{
                  pathname: getRoutePathname('featuredDepartments.createFeaturedDepartment', {
                    id,
                  }),
                  state: {
                    featuredDepartmentsPlacement: placement,
                    duplicateId: id,
                  },
                }}
                kind="secondary"
              >
                Duplicate
              </Button>

              {status !== 'completed' && status !== 'hidden'
                ? this.formatModalConfirmationButton('hidden')
                : null}
            </ShowIfAuthorized>
          </Styling.LineOfItems>
        </PageHeader>
        <Styling.LineOfItems>
          <div className={classes.leftSide}>
            <ReadOnlyValueCard
              detailValues={[
                {
                  id: `${PAGE_ID}-name`,
                  labelText: 'Title',
                  value: featuredDepartmentName,
                },
                {
                  id: `${PAGE_ID}-created`,
                  labelText: 'Created',
                  value: formatDate(created),
                },
                {
                  id: `${PAGE_ID}-last-edit`,
                  labelText: 'Last Edit',
                  value: formatDate(modified),
                },
                {
                  id: `${PAGE_ID}-status`,
                  labelText: 'Status',
                  value: status,
                },
                {
                  id: `${PAGE_ID}-url`,
                  labelText: 'URL',
                  useCustomValueEl: true,
                  value: (
                    <Button
                      kind="link"
                      onClick={() => {
                        openWebPlatform(`shop/featured/${id}`)
                      }}
                    >
                      {`${config.env.webUrl}/shop/featured/${id}`}
                    </Button>
                  ),
                },
              ]}
            />
          </div>
          <div className={classes.rightSide}>
            <Tabs onSelectTab={this.selectTab} selectedTab={selectedTab}>
              <Tab label="Content">
                {isFetchingCollections && <LoadingIndicator withTopMargin />}
                {errorFetchingCollections && (
                  <Styling.Center maxWidth={500} withTopMargin>
                    <Notification kind="error" message={errorFetchingCollections.message} />
                  </Styling.Center>
                )}
                {!isFetchingCollections && !errorFetchingCollections && collections && (
                  <DataTableWithState
                    emptyMsg="No collections added"
                    headers={tableHeaders}
                    id={`${PAGE_ID}-collections-table`}
                    kind="light-tall-row"
                    rows={formatTableRows({ classes, collections })}
                  />
                )}
              </Tab>
              <Tab label="Marketing">
                <ReadOnlyValueCard
                  detailValues={[
                    {
                      id: `${PAGE_ID}-display`,
                      labelText: 'Display',
                      value: status === 'hidden' ? `Hide from navigation` : `Show in navigation`,
                    },
                    ...contentArray,
                    ...altTextArray,
                    {
                      id: `${PAGE_ID}-active-period`,
                      labelText: 'Active Period',
                      value: `${formatDate(startDate)} - ${formatDate(endDate)}`,
                    },
                    {
                      id: `${PAGE_ID}-store-group`,
                      labelText: 'Store Group',
                      useCustomValueEl: true,
                      value: storeGroup ? (
                        <Button
                          href={{
                            pathname: getRoutePathname('storeGroups.storeGroup', {
                              id: storeGroup.id,
                            }),
                          }}
                          kind="link"
                        >
                          {storeGroup.name}
                        </Button>
                      ) : (
                        'All Stores'
                      ),
                    },
                    {
                      id: `${PAGE_ID}-segment`,
                      labelText: 'User Segment',
                      value: customerSegment ? customerSegment.name : 'Everyone',
                    },
                  ]}
                />
              </Tab>
            </Tabs>
          </div>
        </Styling.LineOfItems>
      </Fragment>
    )
  }
}

const FeaturedDepartment = ({
  match: {
    params: { featuredDepartmentId },
  },
  ...restProps
}) => {
  return featuredDepartmentId ? (
    <ItemFetcher
      queryKey="placementContentById"
      queryParams={{ id: featuredDepartmentId }}
      fetchItem={itemConfig => fetchPlacementContentById(featuredDepartmentId, itemConfig)}
      render={({
        item: featuredDepartment,
        isLoadingItem: isFetchingFeaturedDepartment,
        error: errorFetchingFeaturedDepartment,
        refetch: refetchFeaturedDepartment,
      }) => {
        if (isFetchingFeaturedDepartment) {
          return <LoadingIndicator withTopMargin />
        }
        if (errorFetchingFeaturedDepartment) {
          return (
            <Styling.Center maxWidth={500} withTopMargin>
              <Notification kind="error" message={errorFetchingFeaturedDepartment.message} />
            </Styling.Center>
          )
        }
        if (!featuredDepartment) return null
        return (
          <FeaturedDepartmentDetailsFields
            {...restProps}
            featuredDepartment={featuredDepartment}
            refetchFeaturedDepartment={refetchFeaturedDepartment}
          />
        )
      }}
    />
  ) : null
}

export default injectSheet(styles)(FeaturedDepartment)
