import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import injectSheet from 'react-jss'

import { FormItem, Tooltip, Icon } from 'components'

import styles from './RadioButton.styles'

const RadioButton = ({
  classes,
  className,
  id,
  labelText,
  hasTooltip,
  tooltipText,
  onChange,
  orientation,
  value,
  theme,
  ...restProps
}) => {
  const inputProps = {
    ...restProps,
    className: classes.radioButton,
    id,
    onChange: () => {
      onChange(value)
    },
    type: 'radio',
    value,
  }

  return (
    <FormItem className={classes.radioButtonWrapper} orientation={orientation}>
      <input {...inputProps} />
      <label
        htmlFor={id}
        className={cx(className, classes.radioButtonLabelContainer, {
          [classes.radioButtonDisabledLabelContainer]: inputProps.disabled,
        })}
      >
        <span className={classes.radioButtonCircle} id={`${id}-circle`} />
        {labelText}
      </label>
      {hasTooltip && (
        <Tooltip position="top" text={tooltipText} wrap={false}>
          <Icon className={classes.radioButtonTooltipIcon} name="info" />
        </Tooltip>
      )}
    </FormItem>
  )
}

RadioButton.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  labelText: PropTypes.node.isRequired,
  hasTooltip: PropTypes.bool,
  tooltipText: PropTypes.string,
  onChange: PropTypes.func,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']).isRequired,
  value: PropTypes.any,
}

RadioButton.defaultProps = {
  onChange: () => {},
  orientation: 'vertical',
}

RadioButton.displayName = 'RadioButton'

export default injectSheet(styles)(RadioButton)
