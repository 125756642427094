import React, { useState } from 'react'

import Notification from './Notification'

export const DismissibleNotification = props => {
  const [isOpen, setIsOpen] = useState(true)

  const handleClickDismiss = () => {
    setIsOpen(false)
    if (props.handleDismiss) {
      props.handleDismiss()
    }
  }

  if (!isOpen) return null

  return (
    <Notification
      {...props}
      action={{
        onClick: handleClickDismiss,
        text: 'Dismiss',
      }}
    />
  )
}
