import cx from 'classnames'
import React, { Component, Fragment, useContext } from 'react'
import injectSheet from 'react-jss'
import { Link } from 'react-router-dom'

import { Icon, ShowIfAuthorized, ShowIfConfigured, StatusIndicatorBadge } from 'components'
import config, { getEnvStatusBadgeTypeAndText } from 'config'
import { mixins } from 'styling'
import { Context } from 'modules/appContext'

import { capitalize, ClickHandler, openV1Dashboard } from 'utils'

import styles from './Navbar.styles'

const DropMenuStyles = theme => ({
  container: {
    height: '100%',
    position: 'relative',
  },
  menu: {
    zIndex: theme.zIndex.dropdown,
    background: '#FFFFFF',
    boxShadow: '1px 1px 5px 0 rgba(0,0,0,0.2)',
    display: 'none',
    left: 0,
    minWidth: 200,
    padding: `${theme.spacing.lg} 0`,
    position: 'absolute',
    whiteSpace: 'nowrap',

    '&$menuOpen': {
      display: 'block',
    },

    '&$menuOpenAlignRight': {
      // Align dropdown menu to the right of its trigger link
      left: 'initial',
      right: 0,
    },
  },
  menuOpenOnHover: {
    // Ensure we don't close the menu while the cursor is on it
    '&:hover': {
      display: 'block',
    },
  },
  menuItem: {
    fontSize: theme.fontSize.body,
    color: theme.text01,
    display: 'block',
    fontFamily: theme.fontFamilySansSerif,
    fontWeight: 600,
    lineHeight: '20px',
    padding: `${theme.spacing.xs} ${theme.spacing.lg} ${theme.spacing.xs} ${theme.spacing.xlg}`,
    textDecoration: 'none',

    '&:hover, &:focus': {
      background: theme.brand01,
      color: '#FFFFFF',
    },
  },
  menuItemButton: {
    ...mixins.resets.button,
    textAlign: 'left',
  },
  menuItemDiv: {
    cursor: 'pointer',
  },
  menuOpen: {},
  menuOpenAlignRight: {},
})

class DropMenuClass extends Component {
  state = {
    showMenu: false,
  }

  openMenu = () => {
    this.setState({ showMenu: true })
  }

  closeMenu = () => {
    this.setState({ showMenu: false })
  }

  toggleMenu = () => {
    this.setState(state => ({
      showMenu: !state.showMenu,
    }))
  }

  render() {
    const { alignRight, classes, links, openOnHover, renderTrigger } = this.props

    const containerProps = {
      className: classes.container,
    }
    const triggerProps = {
      isActive: this.state.showMenu,
    }
    if (openOnHover) {
      containerProps.onMouseEnter = this.openMenu
      containerProps.onMouseLeave = this.closeMenu
    } else {
      triggerProps.onClick = this.toggleMenu
    }

    const dropMenu = (
      <div {...containerProps}>
        {renderTrigger(triggerProps)}
        {links && (
          <ul
            className={cx(classes.menu, {
              [classes.menuOpenOnHover]: openOnHover,
              [classes.menuOpen]: this.state.showMenu,
              [classes.menuOpenAlignRight]: alignRight,
            })}
            onClick={this.closeMenu}
          >
            {links.map((link, i) => {
              const elementId = `top-nav-link-${link.id}`

              if (config.disableLegacyViews && link.legacy) return null

              return (
                <ShowIfAuthorized
                  key={i}
                  determineAuthorization={link.determineAuthorization}
                  requiredPermission={link.requiredPermission}
                >
                  <ShowIfConfigured requiredConfig={link.requiredConfig}>
                    <li>
                      {link.v1pathname ? (
                        <button
                          className={cx(classes.menuItem, classes.menuItemButton)}
                          id={elementId}
                          onClick={() => {
                            openV1Dashboard(link.v1pathname)
                          }}
                        >
                          {link.label}
                        </button>
                      ) : link.to ? (
                        <Link className={classes.menuItem} id={elementId} to={link.to}>
                          {link.label}
                        </Link>
                      ) : (
                        <button
                          className={cx(classes.menuItem, classes.menuItemButton)}
                          id={elementId}
                          onClick={link.onClick}
                        >
                          {link.label}
                        </button>
                      )}
                    </li>
                  </ShowIfConfigured>
                </ShowIfAuthorized>
              )
            })}
          </ul>
        )}
      </div>
    )

    // Wrap dropMenu with click handler for outside clicks if we open by click instead of hover
    return openOnHover ? (
      dropMenu
    ) : (
      <ClickHandler onClickOutside={this.closeMenu}>{dropMenu}</ClickHandler>
    )
  }
}
const DropMenu = injectSheet(DropMenuStyles)(DropMenuClass)

const navbarLinkIsActive = ({ link, location }) => location.pathname.includes(`${link.routePath}`) // change to `/${link.routePath}` once settings dropdown has links

const NavbarLinkItem = ({ classes, isActive, link, onClick }) => {
  const navBarLinkItemClasses = cx(classes.link, {
    [classes.activeLink]: isActive,
    [classes.linkStyleLink]: !link.links && !link.externalUrl,
  })

  const linkId = `top-nav-${link.id}`

  const contents = (
    <Fragment>
      {link.icon && <Icon name={link.icon} className={classes.linkIcon} />}
      {!link.hideLabel && <span className={classes.linkLabel}>{link.label}</span>}
    </Fragment>
  )
  const to = (link.links && link.links[0] && link.links[0].to) || link.routePath

  // If the dropdown menu opens on hover, clicking on it will go to the first link.
  // Otherwise, it opens on click and does not link anywhere
  return onClick ? (
    <button
      className={navBarLinkItemClasses}
      id={linkId}
      onClick={e => {
        if (link.externalUrl) {
          window.open(link.externalUrl, '_blank')
        } else {
          onClick(e)
        }
      }}
    >
      {contents}
    </button>
  ) : to ? (
    <Link id={linkId} to={to} className={navBarLinkItemClasses}>
      {contents}
    </Link>
  ) : link.externalUrl ? (
    <button
      style={{ textDecoration: 'unset' }}
      className={navBarLinkItemClasses}
      id={linkId}
      onClick={() => {
        window.open(link.externalUrl, '_blank')
      }}
    >
      {contents}
    </button>
  ) : (
    <span id={linkId} className={navBarLinkItemClasses}>
      {contents}
    </span>
  )
}

const NavbarLinkItems = ({ classes, direction, links = [], location }) => {
  return (
    <ul className={cx(classes.links, classes[`linksOn${capitalize({ phrase: direction })}`])}>
      {links[direction].map((link, i) => {
        const navBarLinkItemCommonProps = {
          classes,
          link,
        }

        if (config.disableLegacyViews && link.legacy) return null

        return (
          <ShowIfAuthorized
            key={i}
            determineAuthorization={link.determineAuthorization}
            requiredPermission={link.requiredPermission}
          >
            <ShowIfConfigured requiredConfig={link.requiredConfig}>
              <li>
                {link.links ? (
                  <DropMenu
                    alignRight={direction === 'right'}
                    links={link.links}
                    renderTrigger={({ isActive, onClick }) => (
                      <NavbarLinkItem
                        isActive={isActive || navbarLinkIsActive({ link, location })}
                        onClick={link.onClick || onClick}
                        {...navBarLinkItemCommonProps}
                      />
                    )}
                  />
                ) : (
                  <NavbarLinkItem {...navBarLinkItemCommonProps} />
                )}
              </li>
            </ShowIfConfigured>
          </ShowIfAuthorized>
        )
      })}
    </ul>
  )
}

const Navbar = props => {
  const { classes, className } = props

  const appContext = useContext(Context)

  if (!appContext.topnavVisible) return null

  return (
    <div className={cx(className, classes.navbar)}>
      <button className={classes.hamburgerButton} onClick={appContext.toggleSidenav}>
        <Icon name="hamburger" className={classes.hamburger} />
      </button>
      <div className={classes.logoContainer}>
        <Link to="/">
          <Icon name="logoAlt" className={classes.logo} />
        </Link>
        <StatusIndicatorBadge
          className={classes.statusBadge}
          id="top-nav-environment"
          {...getEnvStatusBadgeTypeAndText()}
        />
      </div>
      <NavbarLinkItems direction="left" {...props} />
      <NavbarLinkItems direction="right" {...props} />
    </div>
  )
}

export default injectSheet(styles)(Navbar)
