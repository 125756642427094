import memoize from 'memoize-one'
import cx from 'classnames'
import moment from 'moment'
import React, { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch } from 'react-redux'
import { NumberValue } from 'react-values'
import { useLocation, useParams } from 'react-router-dom'

import config from 'config'
import {
  advanceCateringOrderStatus,
  cancelOrder,
  fetchOrder,
  fetchCateringOrderStatuses,
} from 'api'
import {
  Button,
  DataTable,
  DataTableWithState,
  Icon,
  LoadingIndicator,
  Modal,
  Notification,
  PageHeader,
  ReadOnlyValueCard,
  ShowIfAuthorized,
  StatusIndicatorDot,
  Tab,
  Tabs,
  Tooltip,
  DropdownMenu,
  ButtonIconAction,
  DropdownMenuItem,
} from 'components'
import { createToast } from 'modules/toasts'
import Styling from 'styling/components'
import { getBackButton, getRoutePathname, getRouteTo, generateBackButton } from 'routing'
import {
  camelCaseToCapitalized,
  camelCaseToKebab,
  capitalize,
  formatAddress,
  formatDate,
  formatDatetime,
  get,
  getAndApply,
  getPersonName,
  useItemFetcher,
  lowercase,
  openWebPlatformAsCustomer,
  toCurrency,
  toNegativeCurrency,
  formatTime,
  printPDF,
  downloadImageAsBase64String,
  queryString,
} from 'utils'
import {
  getProductConfigOptionStrings,
  getOrderStatusByKey,
  getPaymentStatusIndicatorText,
  getPaymentTypeText,
  getNextOrderStatus,
  getPreviousOrderStatus,
} from 'utils/orderUtils'
import { useLoadingState } from 'utils/hooks'

import styles from './Order.styles'
import OrderTotals from './OrderTotals'
import cateringOrderPrintContent from './CateringOrderPrint'

const useStyles = createUseStyles(styles)

const generateOrderItemNotes = ({ productConfig, customerComment }) => {
  const allNotes = []

  if (productConfig) {
    allNotes.push(getProductConfigOptionStrings(productConfig).join('\n'))
  }

  if (customerComment) allNotes.push(customerComment)

  return allNotes.length > 0 ? allNotes.join('\n\n') : ''
}

const orderItemsTableHeaders = [
  {
    key: 'product',
    header: 'Product',
  },
  {
    key: 'allowSubstitution',
    header: 'Subs?',
    disableSorting: true,
  },
  {
    key: 'unitPrice',
    header: 'Unit',
  },
  {
    key: 'times',
    header: '',
    disableSorting: true,
  },
  {
    key: 'quantity',
    header: 'QTY',
    disableSorting: true,
  },
  {
    key: 'subTotal',
    header: 'Sub Total',
  },
]

const cateringOrderItemsTableHeaders = [
  {
    key: 'quantity',
    header: 'Qty',
    print: true,
  },
  {
    key: 'item',
    header: 'Order Item',
    disableSorting: true,
    minWidth: 200,
    print: true,
  },
  {
    key: 'notes',
    header: 'Notes + Comments',
    disableSorting: true,
    print: true,
  },
  {
    key: 'status',
    header: '',
  },
]

const orderHistoryTableHeaders = [
  {
    key: 'status',
    header: 'Status',
    disableSorting: true,
  },
  {
    key: 'message',
    header: 'Message',
    disableSorting: true,
  },
  {
    key: 'timestamp',
    header: 'Time',
    disableSorting: true,
  },
]

const advanceOrderStatus = async (orderId, statusKey) => {
  const { orderItems = [] } = await advanceCateringOrderStatus(statusKey, {
    order_item_ids: [orderId],
  })

  return orderItems[0]
}

const printCateringOrder = (
  documentHeaders,
  orderTableHeaders,
  orderTableRows,
  fileName,
  logoImageBase64,
  pageHeaderPrefix
) => {
  const docDefinition = cateringOrderPrintContent(
    documentHeaders,
    orderTableHeaders,
    orderTableRows,
    logoImageBase64,
    pageHeaderPrefix
  )
  printPDF(docDefinition, fileName)
}

const downloadBase64LogoWithGuard = async () => {
  try {
    return await downloadImageAsBase64String(`${config.env.webUrl}/images/email/logo.png`)
  } catch (error) {
    return null
  }
}

const getDetailValues = ({
  customer,
  store,
  fulfillmentType,
  fulfillmentDate,
  timeslot,
  deliveryAddress,
  paymentType,
  paymentEvent,
  receiptId,
  estimatedTotals,
  finalTotals,
  customerComment,
  adminComment,
}) => {
  const customerQueryParam = customer.ic_user_id ? `?ic_user_id=${customer.ic_user_id}` : ''
  const orderFees =
    estimatedTotals.fees && Object.values(estimatedTotals.fees).length > 0
      ? Object.keys(estimatedTotals.fees).map(feeKey => {
          return {
            id: `order-details-total-${camelCaseToKebab(feeKey)}`,
            labelText: camelCaseToCapitalized(feeKey),
            useCustomValueEl: true,
            value: (
              <OrderTotals
                estimated={getAndApply(estimatedTotals.fees, feeKey, toCurrency)}
                final={getAndApply(finalTotals.fees, feeKey, toCurrency)}
              />
            ),
          }
        })
      : [
          {
            id: 'order-details-total-pickup-fee',
            labelText: 'Pickup Fee',
            useCustomValueEl: true,
            value: (
              <OrderTotals
                estimated={getAndApply(estimatedTotals, 'pickupFee', toCurrency)}
                final={getAndApply(finalTotals, 'pickupFee', toCurrency)}
              />
            ),
          },
          {
            id: 'order-details-total-delivery-fee',
            labelText: 'Delivery Fee',
            useCustomValueEl: true,
            value: (
              <OrderTotals
                estimated={getAndApply(estimatedTotals, 'deliveryFee', toCurrency)}
                final={getAndApply(finalTotals, 'deliveryFee', toCurrency)}
              />
            ),
          },
        ]

  return [
    {
      id: 'order-details-customer',
      labelText: 'Guest',
      useCustomValueEl: true,
      value: (
        <ShowIfAuthorized
          requiredPermission="customers.view"
          unauthorizedFallback={getPersonName({ person: customer })}
        >
          <Button
            kind="link"
            href={`${getRoutePathname('customers.customer', {
              id: customer.id,
            })}${customerQueryParam}`}
          >
            {getPersonName({ person: customer })}
          </Button>
        </ShowIfAuthorized>
      ),
    },
    { id: 'order-details-store', labelText: 'Store ID', value: store.extId },
    {
      id: 'order-details-fulfillment',
      labelText: 'Fulfillment',
      value: capitalize({ phrase: fulfillmentType }),
    },
    {
      id: 'order-details-date',
      labelText: 'Date',
      value: formatDate(fulfillmentDate),
    },
    {
      id: 'order-details-timeslot',
      labelText: 'Timeslot',
      value: timeslot.label
        ? timeslot.label
        : `${moment(timeslot.startTime, 'HH:mm:ss').format('h:mm A')} - ${moment(
            timeslot.endTime,
            'HH:mm:ss'
          ).format('h:mm A')}`,
    },
    {
      id: 'order-details-delivery-address',
      isHidden: fulfillmentType !== 'delivery',
      labelText: 'Address',
      value: formatAddress(deliveryAddress) || 'N/A',
    },
    {
      id: 'order-details-payment',
      labelText: 'Payment',
      value: getPaymentTypeText(paymentType),
      // Only show payment details in `detailValues` if type is auth_capture or paypal
      // (since they would all be 'N/A' otherwise)
      type: (paymentType === 'auth_capture' || paymentType === 'paypal') && 'withDetails',
      detailValues: (paymentType === 'auth_capture' || paymentType === 'paypal') && [
        {
          id: 'order-details-payment-id',
          labelText: 'Payment ID',
          value: get(paymentEvent, 'paymentId') || 'N/A',
        },
        {
          id: 'order-details-payment-auth-id',
          labelText: 'Auth ID',
          value: get(paymentEvent, 'authId') || 'N/A',
        },
        {
          id: 'order-details-payment-capture-id',
          labelText: 'Capture ID',
          value: get(paymentEvent, 'captureId') || 'N/A',
        },
        {
          id: 'order-details-payment-receipt-num',
          labelText: 'Receipt Number',
          value: `${receiptId || 'N/A'}`,
        },
        {
          id: 'order-details-payment-status',
          labelText: 'Status',
          value: getPaymentStatusIndicatorText(paymentEvent) || 'N/A',
        },
        {
          id: 'order-details-payment-auth-amount',
          labelText: 'Auth Amount',
          value: getAndApply(paymentEvent, 'authAmount', toCurrency, 'N/A'),
        },
        {
          id: 'order-details-payment-capture-amount',
          labelText: 'Capture Amount',
          value: getAndApply(paymentEvent, 'captureAmount', toCurrency, 'N/A'),
        },
        {
          id: 'order-details-payment-attempt-count',
          labelText: 'Attempt Count',
          value: get(paymentEvent, 'attemptCount') || 'N/A',
        },
        {
          id: 'order-details-payment-message',
          labelText: 'Message',
          value: get(paymentEvent, 'message') || 'N/A',
        },
      ],
    },
    {
      id: 'order-details-total',
      labelText: 'Total',
      useCustomValueEl: true,
      value: (
        <OrderTotals
          estimated={toCurrency(estimatedTotals.total)}
          final={getAndApply(finalTotals, 'total', toCurrency)}
        />
      ),
      type: 'withDetails',
      detailValues: [
        {
          id: 'order-details-total-subtotal',
          labelText: 'Subtotal',
          useCustomValueEl: true,
          value: (
            <OrderTotals
              estimated={getAndApply(estimatedTotals, 'preDiscountProductTotal', toCurrency, 'N/A')}
              final={getAndApply(finalTotals, 'preDiscountProductTotal', toCurrency)}
            />
          ),
        },
        {
          id: 'order-details-total-offers',
          labelText: 'Offers',
          useCustomValueEl: true,
          value: (
            <OrderTotals
              estimated={getAndApply(estimatedTotals, 'offerTotal', toCurrency, 'N/A')}
              final={getAndApply(finalTotals, 'offerTotal', toCurrency)}
            />
          ),
        },
        {
          id: 'order-details-total-product',
          labelText: 'Product Total',
          useCustomValueEl: true,
          value: (
            <OrderTotals
              estimated={getAndApply(estimatedTotals, 'productTotal', toCurrency)}
              final={getAndApply(finalTotals, 'productTotal', toCurrency)}
            />
          ),
        },
        {
          id: 'order-details-total-subscription',
          labelText: 'Subscription',
          useCustomValueEl: true,
          value: (
            <OrderTotals
              estimated={getAndApply(estimatedTotals, 'subscriptionFee', toCurrency)}
              final={getAndApply(finalTotals, 'subscriptionFee', toCurrency)}
            />
          ),
        },
        {
          id: 'order-details-total-credits',
          labelText: 'Credits',
          useCustomValueEl: true,
          value: (
            <OrderTotals
              estimated={getAndApply(estimatedTotals, 'creditTotal', toNegativeCurrency)}
              final={getAndApply(finalTotals, 'creditTotal', toNegativeCurrency)}
            />
          ),
        },
        {
          id: 'order-details-total-tax',
          labelText: 'Tax Amount',
          useCustomValueEl: true,
          value: (
            <OrderTotals
              estimated={getAndApply(estimatedTotals, 'taxTotal', toCurrency)}
              final={getAndApply(finalTotals, 'taxTotal', toCurrency)}
            />
          ),
        },
        {
          id: 'order-details-total-refund',
          labelText: 'Total Refund',
          useCustomValueEl: true,
          value: (
            <OrderTotals
              estimated={
                // For OnePayment, display refundTotal from finalTotals and use ($0.00) as fallback
                paymentEvent === null
                  ? getAndApply(finalTotals, 'refundTotal', toNegativeCurrency, '($0.00)')
                  : getAndApply(paymentEvent, 'totalRefundAmount', toNegativeCurrency, '($0.00)')
              }
            />
          ),
        },
        ...orderFees,
        {
          id: 'order-details-total-tip',
          labelText: 'Tip total',
          useCustomValueEl: true,
          value: (
            <OrderTotals
              estimated={getAndApply(estimatedTotals, 'tipTotal', toCurrency, '$0.00')}
              final={getAndApply(finalTotals, 'tipTotal', toCurrency, '$0.00')}
            />
          ),
        },
        {
          id: 'order-details-total-grand',
          labelText: 'Grand Total',
          useCustomValueEl: true,
          value: (
            <OrderTotals
              estimated={getAndApply(estimatedTotals, 'total', toCurrency)}
              final={getAndApply(finalTotals, 'total', toCurrency)}
            />
          ),
        },
      ],
    },
    {
      id: 'order-details-customer-notes',
      labelText: 'Guest Notes',
      value: customerComment || 'N/A',
    },
    {
      id: 'order-details-customer-notes',
      labelText: 'Guest Services Notes',
      value: adminComment || 'N/A',
    },
  ]
}

const Order = () => {
  const classes = useStyles()
  const location = useLocation()
  const dispatch = useDispatch()
  const { orderId } = useParams()
  const { order_reference } = queryString.parse(window.location.search) || {}

  const isOneCartOrder = function() {
    return order_reference
  }

  const {
    setItemLoading: setStatusUpdateLoading,
    setItemResolved: setStatusUpdateResolved,
    isItemLoading: isStatusUpdateLoading,
  } = useLoadingState()
  const {
    item: order,
    isLoadingItem: isFetchingOrder,
    error: errorFetchingOrder,
    refetch: refetchOrder,
  } = useItemFetcher('order', config => fetchOrder(orderId, config), { orderId, order_reference })

  const { item: cateringStatusDetails, isLoadingItem: isLoadingCateringStatuses } = useItemFetcher(
    'CATERING_ORDER_STATUSES',
    fetchCateringOrderStatuses
  )

  const cateringStatusList = useMemo(
    () =>
      cateringStatusDetails && cateringStatusDetails.status_sequence
        ? cateringStatusDetails.status_sequence.map(status => ({
            statusText: status.label,
            key: status.status,
          }))
        : [],
    [cateringStatusDetails]
  )

  const formatOrderItemsTableRows = memoize((order, classes) =>
    order.orderItems
      .reduce((newOrderItems, orderItem) => {
        if (orderItem.childOrderItem) {
          // Item was substituted, add as 2 separate item rows
          newOrderItems.push(
            {
              ...orderItem,
              _hasChildOrderItem: true,
            },
            {
              ...orderItem.childOrderItem,
              _isChildOrderItem: true,
            }
          )
        } else {
          newOrderItems.push(orderItem)
        }
        return newOrderItems
      }, [])
      .map((orderItem, index) => {
        const {
          _hasChildOrderItem: hasChildOrderItem,
          _isChildOrderItem: isChildOrderItem,
          allowSubstitution,
          product,
          productConfig,
          quantity,
          status, // original, picked, removed, substituted,
          subTotal,
          unitPrice,
          uom,
        } = orderItem

        let statusIcon
        let statusText = capitalize({ phrase: status })
        if (hasChildOrderItem) {
          statusIcon = 'subFrom'
          statusText = 'Substituted'
        } else if (isChildOrderItem) {
          statusIcon = 'subTo'
          statusText = 'Substituted To'
        } else {
          const STATUS_ICON_MAP = {
            original: 'clock',
            picked: 'check',
            removed: 'close',
            new: 'plus',
          }

          statusIcon = STATUS_ICON_MAP[status]
        }
        const ProductCell = () => (
          <div className={classes.orderItemRowProductCell}>
            <Tooltip className={classes.orderItemRowProductCellIconContainer} text={statusText}>
              <Icon
                className={cx(
                  classes.orderItemRowProductCellIcon,
                  classes[`orderItemRowProductCellIcon_${statusIcon}`]
                )}
                name={statusIcon}
              />
            </Tooltip>
            <img
              className={cx(classes.orderItemRowProductCellImg, {
                [classes.orderItemRowProductCellImgItemRemoved]: status === 'removed',
              })}
              src={product.imageUrl}
              alt="Product"
            />
            <div className={classes.orderItemRowProductCellProductText}>
              <p>{product.name}</p>
              {product.upcs && product.upcs.length > 0 && (
                <p>
                  {product.upcs[0]}
                  {product.upcs.length > 1 && ` (${product.upcs.length})`}
                </p>
              )}
              {productConfig && (
                <div>
                  {getProductConfigOptionStrings(productConfig).map((optionString, index) => (
                    <p key={index}>{optionString}</p>
                  ))}
                </div>
              )}
            </div>
          </div>
        )

        const unitPriceText = `${toCurrency(unitPrice)}/${lowercase({ phrase: uom })}`
        const subTotalText = toCurrency(subTotal)

        return {
          id: `order-item-${index}`,
          rowClass: hasChildOrderItem
            ? classes.orderItemRowSubstituted
            : isChildOrderItem
            ? classes.orderItemRowSubstitutedTo
            : undefined,
          rowLinkTo: {
            pathname: getRoutePathname('products.product', { id: product.id }),
            state: {
              backButton: generateBackButton('orders.order', location, `Order #${order.id}`),
            },
          },
          rowSortValues: {
            product: product.name,
            unitPrice,
            subTotal,
          },
          product: <ProductCell />, // status icon + image + name + upcs + config string
          allowSubstitution: allowSubstitution ? 'Yes' : 'No',
          unitPrice: unitPriceText,
          times: 'x',
          quantity,
          subTotal: subTotalText,
        }
      })
  )

  const formatOrderHistoryTableRows = memoize(
    orderEvents =>
      orderEvents
        // `orderEvents` may come back from the BE in reverse-chronological order
        // In order to create accurate statuses for each event, we need them in chronological order
        .sort((eventA, eventB) => moment(eventA.timestamp).diff(moment(eventB.timestamp)))
        .reduce((newOrderEvents, orderEvent) => {
          const { status, timestamp } = orderEvent
          const { indicatorType, statusText } = getOrderStatusByKey(status)

          if (indicatorType) {
            newOrderEvents.push({
              status: <StatusIndicatorDot text={statusText} type={indicatorType} />,
              message: `Order status changed to "${statusText}"`,
              timestamp: formatDatetime(timestamp),
            })
          } else {
            const lastOrderEvent =
              newOrderEvents.length > 0 ? newOrderEvents[newOrderEvents.length - 1] : {}
            newOrderEvents.push({
              status: lastOrderEvent.status,
              message: statusText,
              timestamp: formatDatetime(timestamp),
            })
          }
          return newOrderEvents
        }, [])
        .map(({ statusText, ...orderEventProps }, index) => ({
          id: `order-event-${index}`,
          rowSortValues: { timestamp: new Date(orderEventProps.timestamp) },
          ...orderEventProps,
        }))
        .reverse() // set the `orderEvents` back to reverse-chronological order
  )

  const formatCateringOrderItemsTableRows = memoize((order, classes) =>
    order.orderItems.map((orderItem, index) => {
      const {
        product,
        productConfig,
        customerComment,
        quantity,
        status,
        internalStatus,
        isCatering,
      } = orderItem

      const currentStatus = getOrderStatusByKey(
        isCatering ? internalStatus : status,
        isCatering ? cateringStatusList : null
      )
      const { indicatorType, statusText } = currentStatus

      const nextStatus = getNextOrderStatus(
        isCatering ? internalStatus : status,
        isCatering ? cateringStatusList : null
      )
      const previousStatus = getPreviousOrderStatus(
        isCatering ? internalStatus : status,
        isCatering ? cateringStatusList : null
      )

      const handleClickChangeOrderStatus = (newStatus, isUndo) => async event => {
        if (!newStatus) return

        if (event) {
          // Prevent navigation on row click.
          event.stopPropagation()
        }

        setStatusUpdateLoading(orderItem.id)
        try {
          await advanceOrderStatus(orderItem.id, newStatus.key)
          await refetchOrder()

          dispatch(
            createToast({
              kind: 'success',
              message: isUndo
                ? `Changed order item #${orderItem.id} status back to ${newStatus.statusText}.`
                : `Updated order item #${orderItem.id} status to ${newStatus.statusText}.`,
              action: isUndo
                ? undefined
                : {
                    text: 'Undo',
                    onClick: handleClickChangeOrderStatus(currentStatus, true),
                  },
            })
          )
        } catch ({ message }) {
          dispatch(createToast({ kind: 'error', message }))
        }
        setStatusUpdateResolved(orderItem.id)
      }

      const isItemStatusUpdateLoading = isStatusUpdateLoading(orderItem.id)

      return {
        id: `order-item-${index}`,
        item: (
          <Button
            kind="link"
            href={{
              pathname: getRoutePathname('products.product', { id: product.id }),
              state: {
                backButton: generateBackButton('orders.order', location, `Order #${order.id}`),
              },
            }}
          >
            <span className={classes.tableContent}>{product.name}</span>
          </Button>
        ),
        notes: (
          <span className={classes.tableContent}>
            {generateOrderItemNotes({ productConfig, customerComment })}
          </span>
        ),
        quantity: `${quantity} x`,
        status: (
          <div className={classes.tableActions}>
            <Button
              disabled={isItemStatusUpdateLoading || !nextStatus}
              onClick={nextStatus ? handleClickChangeOrderStatus(nextStatus) : undefined}
              kind={
                nextStatus && nextStatus.key === 'done' ? 'confirmation-secondary' : 'secondary'
              }
              className={classes.statusButton}
            >
              {nextStatus &&
                (isItemStatusUpdateLoading ? 'Moving...' : `Move to ${nextStatus.statusText}`)}
              {!nextStatus && (isItemStatusUpdateLoading ? 'Moving...' : statusText)}
            </Button>
            {previousStatus && (
              <DropdownMenu
                menuAlignment="right"
                triggerRender={({ onClick }) => (
                  <ButtonIconAction description="Options" icon="more" onClick={onClick} />
                )}
              >
                <DropdownMenuItem
                  icon="arrowLeft"
                  onClick={handleClickChangeOrderStatus(previousStatus)}
                  disabled={isItemStatusUpdateLoading}
                >
                  Move back to {previousStatus.statusText}
                </DropdownMenuItem>
              </DropdownMenu>
            )}
          </div>
        ),
        rowMinHeight: 74,
      }
    })
  )

  const formatCateringOrderItemsPrintTableRows = memoize(order =>
    order.orderItems.map(orderItem => {
      const { id, product, productConfig, customerComment, quantity } = orderItem

      return {
        id: `order-${id}`,
        quantity: `${quantity} x`,
        item: product.name,
        notes: generateOrderItemNotes({ productConfig, customerComment }),
      }
    })
  )

  const getCancellationButtonWithModal = ({ order, refetchOrder }) => (
    <Modal.Confirmation
      handleCloseModal={({ wasConfirmed }) => {
        if (wasConfirmed) {
          cancelOrder(order.id)
            .then(() => {
              dispatch(
                createToast({
                  kind: 'success',
                  message: 'Order successfully cancelled.',
                })
              )
              refetchOrder()
            })
            .catch(({ message }) => {
              dispatch(createToast({ kind: 'error', message }))
            })
        }
      }}
      triggerRender={({ openModal }) => (
        <Button kind="danger" onClick={openModal}>
          Cancel Order
        </Button>
      )}
      contentProps={{
        actionText: 'cancel this order',
        cancelButtonText: 'Go Back',
        confirmButtonText: 'Cancel Order',
      }}
    />
  )

  const getOrderActions = ({ order, customer, refetchOrder }) => (
    <React.Fragment>
      {(!isOneCartOrder() || order.fulfillmentType === 'catering') && order.status !== 'cancelled' && (
        <ShowIfAuthorized requiredPermission={['orders.edit', 'customers.edit']}>
          <div className={classes.headerOrderButtons}>
            {order.status !== 'shipped' && (
              <Button
                icon="external"
                kind="secondary"
                onClick={() => {
                  openWebPlatformAsCustomer(
                    customer.id,
                    `account/order-history/order/${order.id}`
                  ).catch(({ message }) => {
                    dispatch(createToast({ kind: 'error', message }))
                  })
                }}
              >
                Modify Order
              </Button>
            )}
            {order.status !== 'shipped' && getCancellationButtonWithModal({ order, refetchOrder })}
          </div>
        </ShowIfAuthorized>
      )}
    </React.Fragment>
  )

  const getCateringOrderActions = ({ order, printOrder, refetchOrder }) => (
    <div className={classes.headerOrderButtons}>
      <ShowIfAuthorized requiredPermission={['orders.edit', 'customers.edit']}>
        <div className={classes.headerOrderButtons}>
          {order.status !== 'shipped' && getCancellationButtonWithModal({ order, refetchOrder })}
        </div>
      </ShowIfAuthorized>
      <Button icon="export" iconPosition="before" kind="link" onClick={printOrder}>
        Print
      </Button>
    </div>
  )

  if (isFetchingOrder || isLoadingCateringStatuses) {
    return <LoadingIndicator withTopMargin />
  }
  if (errorFetchingOrder) {
    return (
      <Styling.Center maxWidth={500} withTopMargin>
        <Notification kind="error" message={errorFetchingOrder.message} />
      </Styling.Center>
    )
  }
  if (order) {
    const {
      id,
      customer,
      fulfillmentType,
      paymentType,
      estimatedTotals,
      finalTotals,
      status,
    } = order
    // Assume that the order is a catering order if any of its items are catering items
    const hasCateringItems = order.orderItems.some(item => item.isCatering)
    const orderItemsTableRows = hasCateringItems
      ? formatCateringOrderItemsTableRows(order, classes)
      : formatOrderItemsTableRows(order, classes)
    const orderHistoryTableRows = formatOrderHistoryTableRows(order.history)
    const { indicatorType, statusText } = getOrderStatusByKey(status)

    const detailValues = getDetailValues(order)

    const printCateringRows = async () => {
      const imageBase64 = await downloadBase64LogoWithGuard()

      const printHeaders = [
        {
          title: 'Guest',
          content: getPersonName({ person: customer }),
        },
        {
          title: 'Confirmation ID',
          content: `#${id}`,
        },
        {
          title: 'Fulfillment',
          content: capitalize({ phrase: fulfillmentType }),
        },
        {
          title: 'Payment',
          content: getPaymentTypeText(paymentType),
        },
        {
          title: 'Total',
          content:
            getAndApply(finalTotals, 'total', toCurrency) ||
            getAndApply(estimatedTotals, 'total', toCurrency),
        },
      ]

      printCateringOrder(
        printHeaders,
        cateringOrderItemsTableHeaders,
        formatCateringOrderItemsPrintTableRows(order),
        `order-${id}.pdf`,
        imageBase64,
        `ORDER #${id}`
      )
    }

    return (
      <div>
        <PageHeader
          backButton={get(location, 'state.backButton') || getBackButton('orders')}
          headerTitle={`Order ${order.id}`}
          statusText={statusText}
          statusType={indicatorType}
        >
          {hasCateringItems
            ? getCateringOrderActions({ order, printOrder: printCateringRows, refetchOrder })
            : getOrderActions({ order, customer, refetchOrder })}
        </PageHeader>
        <div className={classes.underHeaderContainer}>
          <div className={classes.leftSide}>
            <ReadOnlyValueCard detailValues={detailValues} />
          </div>
          <NumberValue defaultValue={0}>
            {({ value: selectedTab, set: selectTab }) => (
              <Tabs className={classes.rightSide} selectedTab={selectedTab} onSelectTab={selectTab}>
                <Tab label="Order Details">
                  {/* Order Items Table */}
                  <DataTableWithState
                    headers={
                      hasCateringItems ? cateringOrderItemsTableHeaders : orderItemsTableHeaders
                    }
                    id="order-details-items-table"
                    kind="light-tall-row"
                    rows={orderItemsTableRows}
                  />
                </Tab>
                {order.history?.length > 0 ? (
                  <Tab label="History">
                    {/* Order History Table */}
                    <DataTable
                      headers={orderHistoryTableHeaders}
                      id="order-details-history-table"
                      kind="light-tall-row"
                      rows={orderHistoryTableRows}
                      sortHeaderKey="timestamp"
                      sortDirection="desc"
                    />
                  </Tab>
                ) : null}
              </Tabs>
            )}
          </NumberValue>
        </div>
      </div>
    )
  }

  return null
}

export default Order
