import { vars } from 'styling'

export default theme => ({
  listBoxMenuIcon: {
    bottom: 0,
    cursor: 'pointer',
    display: 'inline-block',
    fill: theme.brand01,
    height: '100%',
    marginLeft: theme.spacing.xxs,
    right: 0,
    top: 0,
    transition: `transform ${vars.transition.duration.expansion} ${vars.transition.timingFunction.standardEasing}`,
  },
  listBoxMenuIconSvg: {
    fill: theme.brand01,
    height: '100%',
    width: 9,
  },
  listBoxMenuIconDisabled: {
    cursor: 'not-allowed',

    '& $listBoxMenuIconSvg': {
      fill: theme.text01,
    },
  },
  listBoxMenuIconOpen: {
    transform: 'rotate(180deg)',
  },
})
