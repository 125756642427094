import { getCellId } from '../utils/cells'

/**
 * Normalize a collection of rows with the given headers.
 *
 * @param {Array<Object>} rows
 * @param {Array<Object>} headers
 * @returns {Object}
 */
export const normalize = (rows, headers) => {
  // const { rowsById: prevRowsById } = prevState
  const rowIds = new Array(rows.length)
  const rowsById = {}
  const cellsById = {}

  rows.forEach(
    (
      {
        id: rowId,
        resourceName,
        rowAction,
        rowClass,
        rowDetails,
        rowLinkTo,
        rowMinHeight,
        rowSortValues = {},
        ...rowCellValues
      },
      index
    ) => {
      rowIds[index] = rowId
      // Initialize the row info and state values, namely for selection and expansion
      rowsById[rowId] = {
        cells: new Array(headers.length),
        id: rowId,
        isSelected: false,
        isExpanded: false,
        resourceName,
        rowAction,
        rowClass,
        rowDetails,
        rowLinkTo,
        rowMinHeight,
      }

      // // If we have a previous state, and the row existed in that previous state,
      // // then we'll set the state values of the row to the previous state values.
      // if (prevRowsById && prevRowsById[rowId] !== undefined) {
      //   rowsById[rowId].isSelected = prevRowsById[rowId].isSelected
      //   rowsById[rowId].isExpanded = prevRowsById[rowId].isExpanded
      // }

      // Fill `row.cells` with the cell values defined in each header key found in `row`
      headers.forEach(({ key }, index) => {
        const cellId = getCellId(rowId, key)
        const value = rowCellValues[key]

        // Initialize the cell info and state values, namely for editing
        cellsById[cellId] = {
          id: cellId,
          value,
          sortValue: rowSortValues[key] || value,
        }

        rowsById[rowId].cells[index] = cellId
      })
    }
  )

  return {
    rowIds,
    rowsById,
    cellsById,
  }
}

/**
 * Normalize function but for DataTableWithState for which we do not want to unpack the cell values into rowsById.
 *
 * @param {Array<Object>} rows
 * @param {Array<Object>} headers
 * @returns {Object}
 */
export const normalizeForDataTableWithState = (rows, headers) => {
  const rowIds = new Array(rows.length)
  const rowsById = {}
  const cellsById = {}

  rows.forEach((row, index) => {
    rowIds[index] = row.id
    rowsById[row.id] = row

    headers.forEach(({ key }) => {
      const cellId = getCellId(row.id, key)
      const value = row[key]

      cellsById[cellId] = {
        id: cellId,
        value,
        sortValue: (row.rowSortValues && row.rowSortValues[key]) || value,
      }
    })
  })

  return {
    rowIds,
    rowsById,
    cellsById,
  }
}

/**
 * Counterpart to `normalize` for a collection of rows. This method unravels the
 * normalization step that we use to build the given parameters in order to
 * return a natural interface to working with rows for a consumer.
 *
 * The default heuristic here is to map through all the row ids and return the
 * value of the row for the given id, in addition to adding a `cells` key that
 * contains the results of mapping over the rows cells and getting individual
 * cell info.
 *
 * @param {Array<string>} rowIds array of row ids in the table
 * @param {Object} rowsById object containing lookups for rows by id
 * @param {Object} cellsById object containing lookups for cells by id
 */
export const denormalize = (rowIds, rowsById, cellsById) => {
  return rowIds.map(id => ({
    ...rowsById[id],
    cells: rowsById[id].cells.map(cellId => cellsById[cellId]),
  }))
}
