import React from 'react'
import injectSheet from 'react-jss'

import { fetchCollections } from 'api'
import { AddItemsAsync } from 'components'
import { ProductImagesRow } from 'styling/components'

const styles = {
  collectionImages: {
    width: 24,
    display: 'inline',
    marginRight: 6,
  },
  collectionInfoColumn: {
    marginRight: 6,
    width: '33%',
  },
  collectionRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
}

const AddCollections = ({ classes, PAGE_ID = '', onChange, onDragEnd, selectedCollections }) => (
  <AddItemsAsync
    autocompleteLabelKey="label"
    autocompleteRenderMenuItem={({ item: collection }) => <div>{collection.name}</div>}
    draggable
    onDragEnd={onDragEnd}
    fetchItems={(queryString, config) =>
      fetchCollections(queryString, config).then(({ items: collections }) => ({
        items: collections.map(collection => ({
          ...collection,
          label: collection.name,
        })),
      }))
    }
    fetchItemsAutocomplete={inputValue =>
      fetchCollections(`?search=${inputValue}&order_by=name&direction=asc`)
    }
    id={`${PAGE_ID}-add-collections`}
    itemName="collection"
    modalProps={{
      formatTableRows: collections =>
        collections.map(({ id, name }) => ({
          id: `${id}`,
          name,
        })),
      tableHeaders: [
        {
          key: 'name',
          header: 'Name',
        },
      ],
    }}
    onChange={onChange}
    restrictAutocompleteWidth
    selectedItems={selectedCollections}
    selectedItemsRenderItem={({ item: collection }) => {
      const { displayName, images, name } = collection
      const productImagesComponent = <ProductImagesRow products={[...images]} small />

      return (
        <div className={classes.collectionRow}>
          <span className={classes.collectionInfoColumn}>{displayName || name}</span>
          <span className={classes.collectionInfoColumn}>{name}</span>
          <span className={classes.collectionInfoColumn}>{productImagesComponent}</span>
        </div>
      )
    }}
  />
)

export default injectSheet(styles)(AddCollections)
