export default theme => ({
  pageContainer: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: 1136,
    margin: '0 auto',
  },
  createMLPContainer: {
    paddingBottom: theme.spacing.xxlg,

    '&:first-child': {
      paddingTop: theme.spacing.lg,
    },
  },
  createMLPTitle: {
    color: theme.text01,
    fontSize: theme.fontSize.h2,
    fontFamily: theme.fontFamilySansSerif,
    marginBottom: theme.spacing.md,
  },
  formContainer: {
    width: '100%',
  },
  formInput: {
    maxWidth: theme.contentWidth,
    position: 'relative',
    width: '100%',
    marginBottom: theme.spacing.md,
  },
  formRow: {
    marginBottom: theme.spacing.lg,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  footerContainer: {
    extend: 'pageContainer',
    alignItems: 'center',
    maxWidth: theme.containerWidth,
    margin: '0 auto',
    minHeight: 64,
    paddingBottom: theme.spacing.xs,
    paddingTop: theme.spacing.xs,
    position: 'relative',
  },
  footerErrors: {
    alignSelf: 'center',
    flex: 1,
    marginLeft: theme.spacing.xxlg,
  },
  formFooter: {
    background: '#FFFFFF',
    borderTop: `2px solid ${theme.bgGreyExtraLight}`,
    bottom: 0,
    left: 0,
    right: 0,
    padding: '0 115px',
    position: 'fixed',
  },
})
