import { mixins } from 'styling'

export default theme => ({
  listBoxMenuItem: {
    fontSize: theme.fontSize.p,
    color: theme.text01,
    cursor: 'pointer',
    display: 'table',
    lineHeight: theme.baseLineHeight,
    minHeight: 32,
    minWidth: '100%',
    tableLayout: 'fixed',
    userSelect: 'none',

    '&$listBoxMenuItemHighlighted': {
      backgroundColor: theme.brand01,
      color: '#FFFFFF',

      '&$listBoxMenuItemSelected': {
        backgroundColor: theme.borderGreyLight,
        color: theme.text01,

        '& > $itemContents': {
          paddingRight: 0,
        },
      },
    },
    '&$listBoxMenuItemSelected': {
      backgroundColor: theme.bgSelected,
      color: theme.text01,
    },
  },
  listBoxMenuItemActive: {
    backgroundColor: theme.bgSelected,
  },
  listBoxMenuItemHideOverflow: {
    overflowY: 'hidden',
  },
  listBoxMenuItemHighlighted: {},
  listBoxMenuItemSelected: {},
  itemContents: {
    display: 'table-cell',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    verticalAlign: 'middle',
    width: '100%',
  },
  selectedText: {
    display: 'table-cell',
    fontWeight: 600,
    marginLeft: theme.spacing.xlg,
    padding: `0 ${theme.spacing.md} 0 ${theme.spacing.xs}`,
    verticalAlign: 'middle',
    width: 80,
  },
  listBoxMenuItemNoItemsMsg: {
    cursor: 'default',
  },
  listBoxMenuItemGroupHeading: {
    ...mixins.labelText({ theme }),
    cursor: 'default',
    '& > $itemContents': {
      paddingBottom: theme.spacing.xxs,
    },
  },
  listBoxMenuItemGroupChild: {
    '& > $itemContents': {
      paddingLeft: theme.spacing.lg,
    },
  },
})
