import React, { Component } from 'react'
import injectSheet from 'react-jss'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { unauthenticateUser } from 'modules/user'
import { createToast } from 'modules/toasts'

import styles from './Login.styles'

class Logout extends Component {
  componentDidMount() {
    this.logout()
  }

  logout() {
    const { unauthenticateUser } = this.props
    return (
      unauthenticateUser()
        // On Success, redux state will show the user is no longer authenticated
        // and the user will be redirected to /login
        .catch(({ message }) => {
          // Create toast
          createToast({ kind: 'error', message })
        })
    )
  }

  render() {
    const { classes } = this.props
    return <div className={classes.pageContainer} />
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createToast,
      unauthenticateUser,
    },
    dispatch
  )

export default injectSheet(styles)(connect(null, mapDispatchToProps)(Logout))
