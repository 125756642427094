import React, { Component, Fragment } from 'react'
import injectSheet from 'react-jss'

import { fetchCollections, fetchProducts, uploadImage } from 'api'
import {
  DropdownFilterable,
  FormFieldItem,
  ImageUploadWithAltText,
  RadioButtons,
  TextInput,
} from 'components'

import { get } from 'utils'

import styles from './MerchandiseLandingPagesForm/MerchandisedLandingPagesForm.styles'

class MerchandisedLandingPagesImageUpload extends Component {
  render() {
    const { classes, componentId, index, setFieldError, setFieldValue } = this.props

    const shouldValidateImageSize = false

    return (
      <Fragment>
        <FormFieldItem
          className={classes.formInput}
          id={`${componentId}-image-upload`}
          key={'images.desktop'}
          labelText={'Desktop Image'}
          name={'images.desktop'}
          render={({ id, name, value, onChange: altTextOnChange }) => (
            <ImageUploadWithAltText
              name={'images.desktop'}
              fieldId={componentId}
              altText=""
              fileRequirementsText={'Recommended dimensions: 512px width x 336px height'}
              validateSize={shouldValidateImageSize}
              id={id}
              initialImage={value}
              onImageUpload={uploadImage}
              onImageUploadComplete={image => {
                const newImageValue = image || {}
                setFieldValue(name, newImageValue)
              }}
              onUploadError={error => {
                setFieldError(name, error)
              }}
              altTextOnChange={altTextOnChange}
            />
          )}
        />
        <FormFieldItem
          className={classes.formInput}
          id={`${componentId}-image-upload`}
          key={'images.mobile'}
          labelText={'Mobile Image'}
          name={'images.mobile'}
          render={({ id, name, value, onChange: altTextOnChange }) => (
            <ImageUploadWithAltText
              name={'images.mobile'}
              fieldId={componentId}
              altText=""
              fileRequirementsText={
                'Recommended dimensions: 512px width x 336px height (Safe Area: 336px x 336px)'
              }
              validateSize={shouldValidateImageSize}
              id={id}
              initialImage={value}
              onImageUpload={uploadImage}
              onImageUploadComplete={image => {
                const newImageValue = image || {}
                setFieldValue(name, newImageValue)
              }}
              onUploadError={error => {
                setFieldError(name, error)
              }}
              altTextOnChange={altTextOnChange}
            />
          )}
        />
      </Fragment>
    )
  }
}

export default injectSheet(styles)(MerchandisedLandingPagesImageUpload)
