import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { Fragment, useContext } from 'react'
import injectSheet from 'react-jss'
import { NavLink } from 'react-router-dom'

import config from 'config'
import { ShowCollapse, ShowIfAuthorized, ShowIfConfigured } from 'components'
import { openV1Dashboard } from 'utils'
import { Context } from 'modules/appContext'

import styles from './SideNav.styles'

const SideNavItem = ({ classes, isLinkActive, link }) => {
  if (link.links) {
    return (
      <ul role="tablist" aria-multiselectable="true">
        <ShowCollapse
          iconClassName={classes.linkIcon}
          id={`side-nav-trigger-${link.id}`}
          keepOpen={isLinkActive}
          trigger={<span className={classes.link}>{link.label}</span>}
          content={
            <Fragment>
              {link.links.map((link, i) => {
                let linkComponent =
                  link.to && !link.v1pathname ? (
                    <NavLink
                      activeClassName={classes.linkActive}
                      className={cx(classes.link, classes.childLink)}
                      id={`side-nav-link-${link.id}`}
                      to={{
                        ...link.to,
                        state:
                          link.options && link.options.persistLinkState ? link.to.state || {} : {}, // Reset state when you click on side nav links by default
                      }}
                      isActive={(match, { pathname, search }) => {
                        if (!match) {
                          return false
                        }
                        if (pathname.startsWith('/marketing/promoted-placements/homepage')) {
                          return (
                            new URLSearchParams(link.to.search).get('tab') ===
                            new URLSearchParams(search).get('tab')
                          )
                        }
                        return true
                      }}
                    >
                      {link.label}
                    </NavLink>
                  ) : link.v1pathname ? (
                    <button
                      key={i}
                      className={cx(classes.link, classes.childLink, classes.linkForV1)}
                      onClick={() => openV1Dashboard(link.v1pathname)}
                    >
                      {link.label}
                    </button>
                  ) : link.label ? (
                    <div key={i} className={cx(classes.subheading)}>
                      {link.label}
                    </div>
                  ) : null

                if (linkComponent) {
                  linkComponent = (
                    <ShowIfAuthorized
                      key={i}
                      determineAuthorization={link.determineAuthorization}
                      requiredPermission={link.requiredPermission}
                    >
                      <ShowIfConfigured requiredConfig={link.requiredConfig}>
                        {linkComponent}
                      </ShowIfConfigured>
                    </ShowIfAuthorized>
                  )
                }

                return linkComponent
              })}
            </Fragment>
          }
        />
      </ul>
    )
  }
  return (
    <NavLink
      activeClassName={classes.linkActive}
      className={classes.link}
      id={`side-nav-link-${link.id}`}
      to={link.to}
    >
      {link.label}
    </NavLink>
  )
}

const sideNavLinkIsActive = ({ link, location }) =>
  (link.links || []).some(({ to }) => to && location.pathname.startsWith(to.pathname))

const SideNav = ({ classes, heading, links, location }) => {
  const appContext = useContext(Context)

  if (!appContext.sidenavVisible) {
    return null
  }

  return (
    <nav className={classes.sideNav}>
      {heading && <h2 className={classes.heading}>{heading}</h2>}
      <ul>
        {links.map(
          (link, i) =>
            !link.hideFromSidenav &&
            (!config.disableLegacyViews || !link.legacy) && (
              <ShowIfAuthorized
                key={i}
                determineAuthorization={link.determineAuthorization}
                requiredPermission={link.requiredPermission}
              >
                <ShowIfConfigured requiredConfig={link.requiredConfig}>
                  <li className={classes.navItem}>
                    {link.to && !link.v1pathname && (
                      <SideNavItem
                        classes={classes}
                        isLinkActive={sideNavLinkIsActive({ link, location })}
                        link={link}
                      />
                    )}
                    {link.v1pathname && (
                      <button
                        className={cx(classes.link, classes.linkForV1)}
                        onClick={() => openV1Dashboard(link.v1pathname)}
                      >
                        {link.label}
                      </button>
                    )}
                  </li>
                </ShowIfConfigured>
              </ShowIfAuthorized>
            )
        )}
      </ul>
    </nav>
  )
}

SideNav.propTypes = {
  heading: PropTypes.string,
  links: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
}

export default injectSheet(styles)(SideNav)
