import PropTypes from 'prop-types'
import React, { Component } from 'react'

/**
 * Generic component used for reacting to a click event happening outside of a
 * given `children` element.
 */
export default class ClickListener extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    onClickOutside: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    // Manually bind handlers in this Component (instead of using class properties),
    // so that `handleRef` handler can be properly tested with enzyme.
    this.handleRef = this.handleRef.bind(this)
    this.handleDocumentClick = this.handleDocumentClick.bind(this)
  }

  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick)
  }

  handleDocumentClick(event) {
    if (this.element && this.element.contains && !this.element.contains(event.target)) {
      this.props.onClickOutside(event)
    }
  }

  handleRef(el) {
    const { children } = this.props
    this.element = el

    /**
     * One important note, `children.ref` corresponds to a `ref` prop passed in
     * directly to the child, not necessarily a `ref` defined in the component.
     * This means that the following `ref` location is targeted:
     *
     * <ClickListener onClickOutside={() => {}}>
     *   <Child ref={targetedRefHere} />
     * </ClickListener>
     */
    if (children.ref && typeof children.ref === 'function') {
      children.ref(el)
    }
  }

  render() {
    return React.cloneElement(this.props.children, {
      ref: this.handleRef,
    })
  }
}
