import PropTypes from 'prop-types'
import { useMemo } from 'react'

import { useItemsFetcher } from './itemsFetcherHook'

const getItemsById = itemArray =>
  itemArray.reduce(
    (itemsById, item) => ({
      ...itemsById,
      [item.id]: item,
    }),
    {}
  )

/**
 * Fetches new items on mount with function prop `fetchItems` and allows consuming component to
 * access the following props through the `render` function prop (along with the other props returned in the API response):
 * - `items`, `itemCount`, and `isFetchingItems` (fetched items data and fetch status)
 * - `error` (if the request results in an error response, the response is accessible under `error`)
 */
const ItemsFetcher = ({ queryKey, queryParams, fetchItems, includeItemsById, render }) => {
  const { items, error, refetch, isLoadingItems, otherData } = useItemsFetcher(
    queryKey,
    fetchItems,
    queryParams,
    {
      excludeParams: true,
    }
  )

  const itemsById = useMemo(() => (includeItemsById ? getItemsById(items) : {}), [
    items,
    includeItemsById,
  ])

  const toRender = useMemo(
    () =>
      render({
        error,
        isLoadingItems,
        items,
        refetch,
        itemsById,
        ...otherData,
      }) || null,
    [render, error, isLoadingItems, items, refetch, otherData, itemsById]
  )

  return toRender
}

ItemsFetcher.propTypes = {
  queryKey: PropTypes.string.isRequired,
  queryParams: PropTypes.object,
  fetchItems: PropTypes.func.isRequired,
  includeItemsById: PropTypes.bool,
  render: PropTypes.func.isRequired,
}

export default ItemsFetcher
