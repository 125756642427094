import memoize from 'memoize-one'
import React, { Component } from 'react'
import injectSheet from 'react-jss'

import { DataTableWithState } from 'components'
import { getRoutePathname } from 'routing'
import { renderWithFilterAndOrder, renderWithFilter } from 'components/DataTable/utils/rendering'

import styles from './CollectionProducts.styles'

const collectionItemsTableHeaders = items => {
  const hasRRC = items.some(({ rrc }) => rrc)
  return [
    {
      key: 'name',
      header: 'Product Name',
    },
    {
      key: 'sku',
      header: 'SKU',
    },
    {
      key: 'upc',
      header: 'UPC',
      disableSorting: true,
    },
    ...(hasRRC
      ? [
          {
            key: 'rrc',
            header: 'RRC',
          },
        ]
      : []),
  ]
}

const formatCollectionItemsTableRows = memoize((items, classes, actions) =>
  items.map(({ id, imageUrl, name, sku, upcs = [], rrc }, index) => ({
    id: `collection-product-${id}`,
    rowAction: actions ? actions(items[index]) : null,
    rowLinkTo: getRoutePathname('products.product', { id }),
    rowSortValues: {
      name,
    },
    name: (
      <div className={classes.collectionItemRowProductCell}>
        <img className={classes.collectionItemRowProductCellImg} src={imageUrl} alt="" />
        <div className={classes.collectionItemRowProductCellProductText}>
          <p>{name}</p>
        </div>
      </div>
    ),
    sku,
    upc: `${upcs[0] || 'n/a'} ${upcs.length > 1 ? `(${upcs.length})` : ''}`,
    rrc,
  }))
)

const CollectionProductsLayout = ({ actions, bulkAction, classes, items, pageId, id }) => (
  <DataTableWithState
    bulkAction={bulkAction ? bulkAction() : null}
    emptyMsg="No products added, the products you add will show up here"
    headers={collectionItemsTableHeaders(items)}
    id={`${pageId}-product-table`}
    kind="light-tall-row"
    rows={formatCollectionItemsTableRows(items, classes, actions)}
    render={renderWithFilter}
    renderProps={{
      filterInputId: `${pageId}-product-collection-table-filter`,
    }}
  />
)

class CollectionProductsWithStateLayout extends Component {
  state = {
    slug: null,
    touched: false,
  }

  onDragEnd = ({ data }) => {
    const { source, destination } = data
    if (!source || !destination) return
    this.setState({ touched: true })
    this.props.reorderProducts(source.index, destination.index)
  }

  forcePageNumber = deletedItemIndex => Math.ceil(deletedItemIndex / this.state.productsPerPage)

  updateParentPerPage = perPage => this.setState({ productsPerPage: perPage })

  render() {
    const {
      actions,
      bulkAction,
      classes,
      deletedItemIndex,
      items,
      orderOverride,
      setFieldValue,
      pageId,
    } = this.props

    return (
      <DataTableWithState
        bulkAction={bulkAction ? bulkAction() : null}
        emptyMsg="No products added, the products you add will show up here"
        headers={collectionItemsTableHeaders(items)}
        id={`${pageId}-product-collection-table`}
        kind="default"
        rows={formatCollectionItemsTableRows(items, classes, actions)}
        render={renderWithFilterAndOrder}
        renderProps={{
          filterInputId: `${pageId}-product-collection-table-filter`,
          filterInputPlaceholder: 'Filter products by name',
          items,
          orderOverride,
          setFieldValue,
          touched: this.state.touched,
          pageId,
        }}
        onDragEnd={this.onDragEnd}
        updateParentPerPage={this.updateParentPerPage}
      />
    )
  }
}

const collectionProductsWithState = props => <CollectionProductsWithStateLayout {...props} />
const collectionProducts = props => <CollectionProductsLayout {...props} />

export const CollectionProducts = injectSheet(styles)(collectionProducts)
export const CollectionProductsWithState = injectSheet(styles)(collectionProductsWithState)
